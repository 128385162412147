import React, { useCallback, useEffect } from 'react';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { RIDE_STATUSES } from '../../../constants/constants';
import { useWindowSize } from '../../../hooks/useWindowSIze';

import styles from './index.module.css';

export const BaseTable = ({ columns, onRowClick, data, isTCWorker, components, addHoverEffectToRowTable }) => {
  const [, innerHeight] = useWindowSize();
  const handleRowClassName = (record) =>
    (isTCWorker && record?.status === RIDE_STATUSES.PENDING && styles.newRequestRow) ||
    (record?.status === RIDE_STATUSES.CLAIMED && styles.claimedRow) ||
    (record?.status === RIDE_STATUSES.UNABLE_TO_FULFILL && styles.unableToFullfillRow);

  const onRow = useCallback((record) => (onRowClick ? { onClick: onRowClick(record) } : null), [onRowClick]);

  useEffect(() => {
    const tableBody = document.querySelector('.ant-table-body');

    if (data && tableBody) {
      tableBody.scrollTop = 0;
    }
    return;
  }, [data]);

  return (
    <Table
      className={classNames({
        [styles.baseTable]: true,
        [styles.hoverOnRow]: addHoverEffectToRowTable,
      })}
      pagination={false}
      columns={columns}
      dataSource={data}
      rowClassName={handleRowClassName}
      onRow={onRow}
      components={components}
      scroll={data?.length > 0 ? { x: 'max-content', y: innerHeight - 300 } : { x: 'max-content' }}
    />
  );
};

BaseTable.propTypes = {
  columns: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  renderTableHead: PropTypes.func,
  onRowClick: PropTypes.func,
  data: PropTypes.array,
  dataLoaded: PropTypes.bool,
  isTCWorker: PropTypes.bool,
};
