import { useCallback, useState } from 'react';

import { Button, Modal } from '../..';

import styles from './index.module.css';

export const useDeleteRestoreModal = ({ handleConfrim, title, description, confirmActionButtonText }) => {
  const [visibleModal, setModalVisible] = useState(false);

  const onCancel = useCallback(() => setModalVisible(false), [setModalVisible]);

  const onConfirm = useCallback(async () => {
    await handleConfrim();
    await setModalVisible(false);
  }, [handleConfrim]);

  return {
    Modal: (
      <Modal visible={visibleModal} onCancel={onCancel} closable width={670} title={title}>
        <div className={styles.modalContent}>
          <p className={styles.modalDescription}>{description}</p>
          <div className={styles.modalBtns}>
            <div>
              <Button myClassName={styles.navigationBtn} onClick={onCancel}>
                Cancel
              </Button>
            </div>
            <div>
              <Button myClassName={styles.navigationBtn} onClick={onConfirm}>
                {confirmActionButtonText}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    ),
    setDeleteRestoreModalVisible: setModalVisible,
    deleteRestoreModalVisible: visibleModal,
  };
};
