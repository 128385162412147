import React, { useCallback, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Rides } from '../..';
import { renderColumns } from './renderColumns';
import { renderTableHead } from './renderTableHead';
import { fetchAllTransportOrganizations } from '../../../store/transport_organizations';
import { fetchAllHealthOrganizations } from '../../../store/health_organizations';
import { setMyFilter, setFilteredStatus,getCustomUserFilter, saveCustomUserFilter } from '../../../store/rides';
import { RIDE_STATUSES } from '../../../constants/constants';

import styles from './index.module.css';

export const transofrmOrganizationsOptions = (organizations) =>
  organizations.map((h) => ({
    key: h.id,
    title: h.name,
    value: { id: h.id, name: h.name },
  }));

const MH_Rides = ({
  fetchAllTransportOrganizations,
  getCustomUserFilter, savedFilterParams,
  fetchAllHealthOrganizations,
  hasSavedFilterParams,
  transportOrganizations,
  healthOrganizations,
  setMyFilter,
  setFilteredStatus,
}) => {
  const setRequestsOnly = useCallback(() => {
    setMyFilter({ status: [RIDE_STATUSES.IN_PROGRESS] });
    setFilteredStatus(true);
  }, [setMyFilter, setFilteredStatus]);

  useEffect(() => {
    return () => setFilteredStatus(false);
  });

  useEffect(() => {
    fetchAllTransportOrganizations();
    fetchAllHealthOrganizations();
    
    return () => { };
  }, [fetchAllTransportOrganizations, fetchAllHealthOrganizations]);

  useEffect(() =>{ 
    getCustomUserFilter();

    return () => {};
  }, [getCustomUserFilter]);

  const ridesTable = useMemo(
    () => (
      <div className={styles.table}>
        <Rides
          setRequestsOnly={setRequestsOnly}
          savedFilterParams={savedFilterParams}
          hasSavedFilterParams={hasSavedFilterParams}
          columns={renderColumns()}
          renderTableHead={renderTableHead({
            transportOrganizations: transofrmOrganizationsOptions(transportOrganizations),
            healthOrganizations: transofrmOrganizationsOptions(healthOrganizations),
          })}
        />
      </div>
    ),
    [hasSavedFilterParams,transportOrganizations, healthOrganizations, setRequestsOnly,savedFilterParams],
  );
  return ridesTable;
};

const mapStateToProps = (state) => ({
  healthOrganizations: state.healthOrganizations.healthOrganizations,
  transportOrganizations: state.transportOrganizations.transportOrganizations,
  savedFilterParams: state.rides?.savedFilterParams,
  hasSavedFilterParams: state.rides?.savedFilterParams && Boolean(Object.keys(state.rides?.savedFilterParams)?.length),

});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchAllTransportOrganizations,
      setMyFilter,
      fetchAllHealthOrganizations,
      setFilteredStatus,
      getCustomUserFilter,
      saveCustomUserFilter,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(MH_Rides);
