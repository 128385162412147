import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { LodashHelper } from '.';
import { URLS } from '../constants/constants';

export const AuthorizedRoute =  ({ children, user}) => {
  const location = useLocation();
 
  const redirectTo =  location?.state?.from?.pathname || URLS.RIDES;
  const redirectPath = user?.role ===  location?.state?.role ? redirectTo : URLS.RIDES;
 
  if (!LodashHelper.isEmpty(user)) {
    return <Navigate to={ redirectPath } state={{from: location?.state?.from?.pathname}} />;
  } else return children;
   
};
