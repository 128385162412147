import { useState, useEffect, useRef, useCallback } from 'react';
import { LodashHelper } from '../utils';

import { history } from '../utils/history';
import {Navigation} from '../utils/navigation'

export const usePreventLeave = (initialValues, currentValues) => {
  const [visibleModal, setModalVisible] = useState(false);
  const [location, setLocation] = useState('');
  const [isEqualValues, setIsEqualValues] = useState(true);
const {go,location:mhLocation} = Navigation();
  const unblockHistory = useRef();

  const onBeforeUnload = useCallback((e) => {
    e.preventDefault();
    e.returnValue = '';
  }, []);

  const onPopState = useCallback(() => {
    window.location.href !== history.location.pathname &&
      window.history.pushState('MedHaul', 'MedHaul', mhLocation.pathname);
  }, []);

  useEffect(() => setIsEqualValues(LodashHelper.isEqual(initialValues, currentValues)), [initialValues, currentValues]);

  useEffect(() => {
    window.addEventListener('popstate', onPopState);
    return () => window.removeEventListener('popstate', onPopState);
  }, [onPopState]);

  useEffect(() => {
    !isEqualValues && window.addEventListener('beforeunload', onBeforeUnload);
    return () => window.removeEventListener('beforeunload', onBeforeUnload);
  }, [isEqualValues, onBeforeUnload]);

  useEffect(() => {
    if (!isEqualValues) {
      unblockHistory.current = history.block((location) => {
        setLocation(location);
        setModalVisible(true);
        return false;
      });
    }

    return () => {
      setModalVisible(false);
      unblockHistory.current && unblockHistory.current();
    };
  }, [isEqualValues]);

  return [visibleModal, setModalVisible, unblockHistory, location];
};
