import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import SetPassword from '../../common/SetPassword/SetPassword';

import { setAccount, checkInvitationKey } from '../../../store/authorization';
import { Loader } from '../../../components';
import { getLoading } from '../../../store/selectors/common';

const SetDriverAccount = ({ setAccount, loading, captchaLoaded, checkInvitationKey }) => {
  const [queryKey, setQueryKey] = useState('');

  useEffect(() => {
    if (window.location.search) {
      const urlParams = new URLSearchParams(window.location.search);
      const queryKey = urlParams.get('key');
      setQueryKey(queryKey);
    }
    return;
  }, []);

  useEffect(() => {
    queryKey && captchaLoaded && checkInvitationKey({ key: queryKey });
    return;
  }, [queryKey, captchaLoaded, checkInvitationKey]);

  return (
    <>
      <Loader loading={loading} />
      <SetPassword
        pageHeader='Create password'
        formLabel='Create Password'
        submitButtonText='Log in'
        handleSubmit={setAccount}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  loading: getLoading(state),
  captchaLoaded: state.common.captchaLoaded,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ setAccount, checkInvitationKey }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SetDriverAccount);
