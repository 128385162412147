import React from 'react';
import { Steps } from 'antd';
import PropTypes from 'prop-types';

import { useWindowSize } from '../../../hooks/useWindowSIze';
import { STEPPER_BREAKPOINT } from '../../../constants/constants';

import styles from './index.module.css';

// const { Step } = Steps;

export const Stepper = ({ steps, currentStep, handleNavigateThroughStepper }) => {
  const [width] = useWindowSize();
  const items = steps.map((step)=>({key: step?.title, title: step?.title, disabled: step?.disabled, status: step?.status}) );
  return (
    <Steps
      onChange={handleNavigateThroughStepper}
      current={currentStep}
      className={styles.stepper}
      direction={width > STEPPER_BREAKPOINT ? 'horizontal' : 'vertical'}
      items={items}>
      {/* {steps.map((step) => (
        <Step key={step.title} title={step.title} disabled={step.disabled} status={step.status} />
      ))} */}
    </Steps>
  );
};

Stepper.propTypes = {
  steps: PropTypes.array,
  currentStep: PropTypes.number,
};
