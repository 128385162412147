const st0= `
.st0{fill-rule:evenodd;clip-rule:evenodd;`

 
export const OverNightIcon = ({width,height}) => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width={width}
            height={height}
            viewBox='0 0 34 34'
           
            >

<path d="M13.589 21.659c-3.873 1.038-8.517-.545-10.98-3.632a1 1 0 0 1 .751-1.623c3.984-.118 6.662-1.485 8.17-4.098 1.51-2.613 1.354-5.616-.535-9.125a1 1 0 0 1 1.03-1.463c3.904.59 7.597 3.82 8.635 7.694 1.43 5.334-1.737 10.818-7.071 12.247z" fill="#de7f39"/>



{/* <g>

<path class={st0} d="M70.11,21.51c-0.52,1.99-1.38,3.87-2.54,5.54c-2.48,3.6-6.3,6.29-10.92,7.23c-4.76,0.98-9.46-0.06-13.22-2.54 c-3.76-2.47-6.57-6.38-7.55-11.14c-0.95-4.64,0.02-9.23,2.36-12.93c2.36-3.73,6.1-6.55,10.69-7.68c-2.73,2.73-4.42,6.51-4.42,10.67 c0,8.33,6.75,15.09,15.09,15.09C63.69,25.76,67.39,24.14,70.11,21.51L70.11,21.51z M122.86,80.12l0.02,0.37v30.49h-14.02V96.68 H14.02v13.66H0V35.71c0-1.85,1.51-3.35,3.36-3.35h7.3c1.85,0,3.36,1.55,3.36,3.35v43.77h37.32l10.65-37.44h41.85 c10.47,0,19.04,8.59,19.04,19.04v19.04H122.86L122.86,80.12z M35.44,42.72c8.62,0,15.61,6.99,15.61,15.61 c0,8.62-6.99,15.61-15.61,15.61c-8.62,0-15.61-6.99-15.61-15.61C19.83,49.71,26.81,42.72,35.44,42.72L35.44,42.72z"/> 

</g>*/}
       

        </svg>
    );
};
