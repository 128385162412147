import React from 'react';
import { RideDetails } from '../../../';
import { ButtonsPanel } from '../ButtonsPanel/ButtonsPanel';

import styles from './index.module.css';

export const StepFive = ({ handleSave, values, step, handlePreviousStep, handleSaveAndClose }) => {
  
  return (
    
    <>
      <h2 className={styles.headTitle}>Step 5: Review and Submit</h2>
      <p className={styles.summary}>
        Below is a summary of your ride (or transportation) request. To Submit the request, click "Send Request" at the
        bottom of the page.
      </p>
      <RideDetails values={values} showFullInfo />
      <p className={styles.sendDescription}>
        After pressing the "Send Request" button, this request will be sent to our network of transportation providers
      </p>
      <ButtonsPanel
        step={step}
        handlePreviousStep={handlePreviousStep}
        handleSaveAndClose={handleSaveAndClose}
        handleSubmit={handleSave}
        myClassName={styles.btns}
      />
    </>
  );
};
