import { getIn } from 'formik';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTwoFactorVerificationModal } from '../../../components';
import { defaultValues } from '../../../components/forms/Preferences/MHPreferences/partials/defaultValues';
import { changePasswordVerification } from '../../../store/authorization';
import {
  changeAdditionalRideOptionStatus,
  createAdditionalRideOption,
  getAdditionalRideOptions,
  getPlatformSettings,
  updatePlatformSettings,
} from '../../../store/preferences';
import { DateHelper, LodashHelper } from '../../../utils';

export const usePreferencesHandlers = ({ platformPreferences, rideOptions, user }) => {
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = useState({ ...platformPreferences } || defaultValues);

  const securityRef = useRef(null);
  const { TwoFactorAuthenticationModal, twoFactorModalVisible, setTwoFactorModalVisible } =
    useTwoFactorVerificationModal({
      handleConfirm: (token, confirmCallback) => {
        dispatch(changePasswordVerification({ token, newPassword: securityRef?.current?.values?.newPassword })).then(
          (res) => {
            if (res) {
              confirmCallback();
              securityRef?.current?.resetForm();
            }
          },
        );
      },
      email: user?.email,
    });
  const [archiveModalVisible, setArchiveModalVisible] = useState(false);
  const [unarchiveModalVisible, setUnarchiveModalVisible] = useState(false);
  const [selectedRideOption, setSelectedRideOption] = useState(null);

  const activeOptions = useMemo(() => rideOptions?.filter((option) => !option.disabled) || [], [rideOptions]);

  const archivedOptions = useMemo(() => rideOptions?.filter((option) => option.disabled) || [], [rideOptions]);

useEffect(() => {dispatch(getPlatformSettings()); return;}, [dispatch]);

  useEffect(() => {dispatch(getAdditionalRideOptions()); return;}, [dispatch]);

  useEffect(() => {
    if (platformPreferences) setInitialValues((prevState) => ({ ...prevState, ...platformPreferences }));
    return;
  }, [platformPreferences]);

  const handleCreateOption = (values) => {
    const newOption = { value: values.newOption, disabled: false };
    dispatch(createAdditionalRideOption(newOption));
  };

  const handleArchiveRideOption = (option) => {
    setArchiveModalVisible(true);
    setSelectedRideOption(option);
  };

  const handleUnarchiveRideOption = (option) => {
    setUnarchiveModalVisible(true);
    setSelectedRideOption(option);
  };

  const handleChangeRideOptionStatus = (status) => {
    dispatch(changeAdditionalRideOptionStatus({ id: selectedRideOption.id, status: { disabled: status } }));
    setArchiveModalVisible(false);
    setUnarchiveModalVisible(false);
    setSelectedRideOption(null);
  };

  const updatePlatformValues = async ({ setFieldValue, fieldName, values, updateReason, onSuccessCallback }) => {
    await setFieldValue(fieldName, getIn(values, fieldName));

    const updatedValues = LodashHelper.cloneDeep(LodashHelper.set(initialValues, fieldName, getIn(values, fieldName)));
    const { rideOptions, ...formValues } = updatedValues;

    setInitialValues(updatedValues);
    dispatch(
      updatePlatformSettings({
        payload: LodashHelper.removeEmpty({
          platformSettings: DateHelper.transformTime(formValues, DateHelper.convertTimeToMinutes),
          updateReason,
        }),
        onSuccessCallback,
      }),
    );
  };

  const handleSaveChanges =
    (setFieldValue) =>
    ({ values, fieldName }) =>
    async () => {
      updatePlatformValues({ setFieldValue, values, fieldName });
    };

  const handleCancelArchive = () => setArchiveModalVisible(false);
  const handleCancelUnarchive = () => setUnarchiveModalVisible(false);

  const allowScrollToPersonalInfo = useMemo(() => {
    return !LodashHelper.isEmpty(platformPreferences) && !LodashHelper.isEmpty(user);
  }, [platformPreferences, user]);

  return {
    allowScrollToPersonalInfo,
    handleCancelUnarchive,
    handleCancelArchive,
    handleSaveChanges,
    handleChangeRideOptionStatus,
    handleUnarchiveRideOption,
    handleArchiveRideOption,
    handleCreateOption,
    archivedOptions,
    activeOptions,
    unarchiveModalVisible,
    archiveModalVisible,
    TwoFactorAuthenticationModal,
    twoFactorModalVisible,
    setTwoFactorModalVisible,
    initialValues,
    securityRef,
    selectedRideOption,
    updatePlatformValues,
  };
};
