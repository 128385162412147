import { getIn } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import { RECURRING_FREQUENCY } from '../../../../constants/constants';
import { LodashHelper } from '../../../../utils';
import { getMonthPickerOptions } from '../../../../utils/helpers';

export const useRecurringInfo = ({
  values,
  validateForm,
  pickUpDate,
  startPointOfRecurring,
  disabledReccuringValues,
}) => {
  const [recurringType, setRecurringType] = useState('');

  const handleRecurringFrequency = async ({ value, setValues, values }) => {
    const unMutatedValues = LodashHelper.cloneDeep(values);
    setRecurringType(value);

    switch (value) {
      case RECURRING_FREQUENCY.DAILY:
      case RECURRING_FREQUENCY.MONTHLY:
      case RECURRING_FREQUENCY.WEEKDAYS:
        delete unMutatedValues?.recurrenceRule?.byDays;
        delete unMutatedValues?.recurrenceRule?.weekIndex;
        delete unMutatedValues?.recurrenceRule?.monthlyType;

        await setValues({
          ...values,
          recurrenceRule: {
            ...unMutatedValues?.recurrenceRule,
            frequency: value,
            isCustom: false,
            interval: 1,
          },
        });
        break;

      case RECURRING_FREQUENCY.WEEKLY:
        delete unMutatedValues?.recurrenceRule?.weekIndex;
        delete unMutatedValues?.recurrenceRule?.monthlyType;

        await setValues({
          ...values,
          recurrenceRule: {
            ...unMutatedValues?.recurrenceRule,
            frequency: value,
            isCustom: false,
            byDays: [],
            interval: 1,
          },
        });
        break;

      case RECURRING_FREQUENCY.CUSTOM:
        delete unMutatedValues?.recurrenceRule?.byDays;
        delete unMutatedValues?.recurrenceRule?.monthlyType;

        await setValues({
          ...values,
          recurrenceRule: {
            ...unMutatedValues?.recurrenceRule,
            frequency: RECURRING_FREQUENCY.DAILY,
            isCustom: true,
            interval: 1,
          },
        });
        break;

      default:
        break;
    }
    validateForm();
  };

  useEffect(() => {
    values?.isRecurring &&
      (values?.recurrenceRule?.isCustom
        ? setRecurringType(RECURRING_FREQUENCY.CUSTOM)
        : setRecurringType(values?.recurrenceRule?.frequency));

        return;
  }, [values]);

  const handleChangeEndsAfter = async ({ name, value, values, setFieldValue }) => {
    if (values?.recurrenceRule?.frequency === RECURRING_FREQUENCY.WEEKLY && value < getIn(values, name)) {
      await setFieldValue('recurrenceRule.byDays', []);
    }
    await setFieldValue(name, value);
    await validateForm();
  };

  const monthPickerOptions = useMemo(() => {
    if (!disabledReccuringValues && startPointOfRecurring) {
      return getMonthPickerOptions(startPointOfRecurring);
    } else {
      return pickUpDate && getMonthPickerOptions(pickUpDate);
    }
  }, [disabledReccuringValues, pickUpDate, startPointOfRecurring]);

  return {
    monthPickerOptions,
    handleChangeEndsAfter,
    handleRecurringFrequency,
    recurringType,
  };
};
