import { Formik } from 'formik';
import { Button, PersonalInfoPreferences } from '../../..';
import { personalInformationSchema } from '../../../../utils';

import styles from './index.module.css';

export const PersonalInfoSettings = ({ user, updatePersonalInformation, rideOptions, allowScrollToPersonalInfo }) => {
  return (
    <Formik
      enableReinitialize
      initialValues={{
        firstName: user.firstName || '',
        lastName: user.lastName || '',
        phoneNumber: user.phoneNumber || '',
        jobTitle: user.jobTitle || '',
      }}
      validationSchema={personalInformationSchema}
      onSubmit={(values) => updatePersonalInformation(values)}>
      {({ values, handleSubmit, ...rest }) => {
        return (
          <form onSubmit={handleSubmit} className={styles.personalInfoForm}>
            <PersonalInfoPreferences
              values={values}
              {...rest}
              hideTimezoneField
              rideOptions={rideOptions}
              allowScrollToPersonsalInfo={allowScrollToPersonalInfo}
            />
            <div className={styles.btnPanel}>
              <div className={styles.btnControl}>
                <Button disabled={!rest.isValid} type='submit'>
                  Save Changes
                </Button>
              </div>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};
