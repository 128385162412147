import React, {
  useCallback,
  useEffect,
  useMemo, 
  useState,
} from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Navigation } from "./../../../utils/navigation";
import { InfoOutlined, CloseOutlined } from "@ant-design/icons";
import classNames from "classnames";

import {
  fetchRides,
  setMyFilter,
  saveCustomUserFilter,
  applyMyFilter,
  setFilteredStatus,
  generateReport,
  setShowRefreshNotification,
  resetRides,
} from "../../../store/rides";

import { clearTCInfo } from "../../../store/transport_organizations";
import { clearHOInfo } from "../../../store/health_organizations";
import { useCurrentRole } from "../../../hooks/useCurrentRole";
import {
  Pagination,
  Button,
  Tooltip,
  useExportRidesModal,
  BaseTable,
} from "../../../components";
import { LodashHelper } from "../../../utils";
import { destroyNotification } from "../../../components/common/ConfirmPopUp/ConfirmPopUp";
import { URLS } from "../../../constants/constants";
import { usePagination } from "../../../hooks/usePagination";
import { redirectToPage } from "../../../utils/helpers";
import { checkForOwnFilters } from "../../../store/selectors/common";
import styles from "./index.module.css";
 

const transformData = (data) =>
  data?.length > 0 ? data?.map((d) => ({ ...d, key: d.seqId })) : [];

const Rides = ({
  fetchRides,
  rides,
  limit,
  currentPage,
  total,
  totalPages,
  role,
  columns,
  renderTableHead,
  dataLoaded,
  hasOwnFilter,
  setRequestsOnly,
  setNewRequestOnly,
  setMyFilter,
  savedFilterParams,
  myFilterParams,
  myFilterApplied,
  saveCustomUserFilter,
  applyMyFilter,
  setFilteredStatus,
  generateReport,
  hasSavedFilterParams,
  setShowRefreshNotification,
  clearTCInfo,
  clearHOInfo,

  resetRides,
}) => {
  const navigation = Navigation();
  const { location } = navigation;

  usePagination(navigation);
  const { isMHAdmin, isTCWorker } = useCurrentRole(role);

  const [myFilterTouched, setMyFilterTouched] = useState(false);

  const handleConfirmExport = useCallback(
    (payload, cb) => generateReport(payload, cb),
    [generateReport]
  );

  const { Modal, setExportModalVisible } = useExportRidesModal({
    onConfirm: handleConfirmExport,
  });

  const toggleExportModal = useCallback(
    (flag) => () => setExportModalVisible(flag),
    [setExportModalVisible]
  );

  const clearFilters = useCallback(() => {
    setMyFilter({});
    applyMyFilter(false);
    
  }, [applyMyFilter, setMyFilter]);

  useEffect(() => {
    location?.state?.rider &&
      setMyFilter({
        formattedRiderName: `${location?.state?.rider?.firstName} ${location?.state?.rider?.lastName}`,
        rider: location?.state?.rider?.id,
      });

    return () => {};
  }, [location, setMyFilter]);

  useEffect(() => { 
    fetchRides(false, navigation);

    return () => {};
  }, [fetchRides]);

  useEffect(() => {
    if (hasOwnFilter) setMyFilterTouched(true);

    return () => {};
  }, [hasOwnFilter]);

  useEffect(
    () =>   {
      if (!location.pathname.includes(URLS.RIDES)) {
        setMyFilter({});
      }
    },
    [setMyFilter, location?.pathname]
  );

  useEffect(
    () => () => {
      applyMyFilter(false);
      setFilteredStatus(false);
      setShowRefreshNotification(false);
      destroyNotification();
      clearTCInfo();
      clearHOInfo();
      resetRides();
    },
    [
      applyMyFilter,
      setFilteredStatus,
      setMyFilter,
      setShowRefreshNotification,
      clearTCInfo,
      clearHOInfo,
      resetRides,
    ]
  );

  useEffect(
    () =>  {
      if (
        savedFilterParams &&
        hasOwnFilter &&
        !LodashHelper.isEqual(myFilterParams, savedFilterParams)
      ) {
        applyMyFilter(hasOwnFilter);
      }

      return;
    },
    [hasOwnFilter, applyMyFilter, myFilterParams, savedFilterParams]
  );

  const handleChangePage = useCallback(
    async(page) => {

    
     // if (currentPage !== page) {
        redirectToPage(URLS.RIDES, page, navigation);
        fetchRides(false, navigation);
     // }
    },
    [fetchRides ]
  );
  const handleSaveMyFilter = useCallback(() => {
    saveCustomUserFilter(myFilterParams);
    setFilteredStatus(true);
  }, [myFilterParams, setFilteredStatus, saveCustomUserFilter]);

  const handleApplyMyFilter = useCallback(() => {
    setMyFilter(savedFilterParams);
    applyMyFilter(true);
    setFilteredStatus(true);
  }, [applyMyFilter, savedFilterParams, setFilteredStatus, setMyFilter]);

  const handleRowClick = useCallback(
    (record) => () => navigation?.push(`/rides/${record.id}`),
    [navigation]
  );

  const showTCFilterBtn = isTCWorker && !myFilterApplied;

  const showMHFilterBtn = isMHAdmin && !myFilterApplied;

  const showApplyFilterBtn =
    hasSavedFilterParams &&
    !myFilterApplied &&
    !LodashHelper.isEqual(savedFilterParams, myFilterParams);

  const showUpdateFilterBtn =
    hasSavedFilterParams &&
    myFilterTouched &&
    !LodashHelper.isEqual(savedFilterParams, myFilterParams);

  const components = useMemo(
    () => ({
      header: {
        wrapper: dataLoaded && renderTableHead,
      },
    }),
    [dataLoaded, renderTableHead]
  );

  const ridesTable = useMemo(
    () => (
      <BaseTable
        data={transformData(rides)}
        components={components}
        renderTableHead={renderTableHead}
        columns={dataLoaded && columns}
        onRowClick={handleRowClick}
        isTCWorker={isTCWorker}
        addHoverEffectToRowTable
      />
    ),
    [
      rides,
      dataLoaded,
      columns,
      renderTableHead,
      handleRowClick,
      components,
      isTCWorker,
    ]
  );

  return (
    <>
      <div className={styles.pageContent}>
        <div className={styles.header}>
          <h1 className={styles.pageTitle}>Rides</h1>

          <div className={styles.filterBtns}>
            {showTCFilterBtn && (
              <div className={styles.filterBtn}>
                <Button
                  myClassName={styles.navigationBtn}
                  onClick={setNewRequestOnly}
                >
                  View New Ride Requests
                </Button>
              </div>
            )}
            {showMHFilterBtn && (
              <div className={styles.filterBtn}>
                <Button
                  myClassName={styles.navigationBtn}
                  onClick={setRequestsOnly}
                >
                  View Rides - In Progress
                </Button>
              </div>
            )}

            {showApplyFilterBtn ? (
              <div className={styles.filterBtn}>
                <Button
                  myClassName={styles.navigationBtn}
                  onClick={handleApplyMyFilter}
                >
                  Apply Saved Filter
                </Button>
              </div>
            ) : (
              showUpdateFilterBtn && (
                <div className={styles.filterBtn}>
                  <Button
                    myClassName={styles.navigationBtn}
                    onClick={handleSaveMyFilter}
                  >
                    Update Filter Settings
                  </Button>
                </div>
              )
            )}

            {!hasSavedFilterParams && (
              <div className={styles.filterBtn}>
                <Button
                  myClassName={styles.navigationBtn}
                  disabled={!hasOwnFilter}
                  onClick={handleSaveMyFilter}
                >
                  Save my Filter
                </Button>
              </div>
            )}

            {hasOwnFilter && (
              <div className={styles.filterBtn}>
                <Button
                  myClassName={styles.navigationBtn}
                  onClick={clearFilters}
                >
                  <CloseOutlined /> Reset List
                </Button>
              </div>
            )}
          </div>
        </div>

        <div>{ridesTable}</div>
      </div>
      <div className={styles.tableFooter}>
        <div className={styles.export}>
          <Button
            myClassName={styles.exportBtn}
            disabled={!rides?.length}
            onClick={toggleExportModal(true)}
          >
            Export Report
          </Button>
          <Tooltip
            title={
              <span>
                To export all data, remove all filters. If filters are applied,
                the data export will only contain selected/filtered data.
              </span>
            }
          >
            <span
              className={classNames({
                [styles.tooltipInfo]: true,
                [styles.tooltipDisabled]: !rides?.length,
              })}
            >
              <InfoOutlined className={styles.infoIcon} />
            </span>
          </Tooltip>
        </div>

        {Modal}
        <div
          className={classNames({
            [styles.pagination]: true,
            [styles.gapForPagination]: rides?.length > 4,
          })}
        >
          <Pagination
            defaultCurrent={currentPage}
            total={total}
            totalPages={totalPages}
            onChange={handleChangePage}
            pageSize={limit}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  role: state.authorization.role,
  rides: state.rides.rides,
  limit: state.rides.limit,
  currentPage: state.rides.currentPage,
  total: state.rides.total,
  totalPages: state.rides.totalPages,
  dataLoaded: state.rides.dataLoaded,
  hasOwnFilter: checkForOwnFilters(state),
  myFilterParams: state.rides.myFilterParams,
  myFilterApplied: state.rides.myFilterApplied,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchRides,
      setMyFilter,
      saveCustomUserFilter,
      setFilteredStatus,
      applyMyFilter,
      setShowRefreshNotification,
      generateReport,
      clearTCInfo,
      clearHOInfo,
      resetRides,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Rides);
