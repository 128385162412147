import { Button } from '../../../../';

import styles from '../index.module.css';

export function renderSaveChangesButton(fieldName, handleSaveChanges, values, isDisabled) {
  return (
    <Button
      disabled={isDisabled}
      myClassName={styles.formControlBtn}
      onClick={handleSaveChanges({ values, fieldName })}>
      Save Changes
    </Button>
  );
}
