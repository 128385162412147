import { getIn } from 'formik';
import React from 'react';
import { RECCURING_CUSTOM_FREQUENCY_OPTIONS, RECURRING_FREQUENCY } from '../../../constants/constants';
import { CustomPicker } from '../../common/Recurring/CustomPicker/CustomPicker';
import { MonthPicker } from '../../common/Recurring/MonthPicker/MonthPicker';
import { WeekRangePicker } from '../../common/Recurring/WeekRangePicker/WeekRangePicker';

export const renderReccuringTypes = ({ recurringType, values, monthPickerOptions, showDisabledTooltip, disabled }) => {
  switch (recurringType) {
    case RECURRING_FREQUENCY.WEEKLY:
      return (
        <WeekRangePicker
          values={values}
          options={getIn(values, 'recurrenceRule.byDays')}
          weeks={1}
          name='recurrenceRule.byDays'
          disabled={!values?.pickUpDate?.datetime || !values?.recurrenceRule?.count || showDisabledTooltip || disabled}
          showDisabledTooltip={showDisabledTooltip}
        />
      );

    case RECURRING_FREQUENCY.MONTHLY:
      return (
        <MonthPicker
          options={monthPickerOptions}
          name='recurrenceRule.monthlyType'
          disabled={!values?.pickUpDate?.datetime || !values?.recurrenceRule?.count || showDisabledTooltip || disabled}
          showDisabledTooltip={showDisabledTooltip}
        />
      );

    case RECURRING_FREQUENCY.CUSTOM:
      return (
        <CustomPicker
          frequencyName='recurrenceRule.frequency'
          intervalName='recurrenceRule.interval'
          recurringFrequencyOpts={RECCURING_CUSTOM_FREQUENCY_OPTIONS}
          monthPickerOptions={monthPickerOptions}
          values={values}
          disabled={!values?.pickUpDate?.datetime || !values?.recurrenceRule?.count || showDisabledTooltip || disabled}
          showDisabledTooltip={showDisabledTooltip}
        />
      );

    default:
      return null;
  }
};
