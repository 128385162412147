import React, { useCallback, useState } from 'react';
import classnames from 'classnames';
import { FormikProvider, useFormik } from 'formik';
import { Button, Modal, Select, InputTextArea, Input } from '../..';
import { RIDE_STATUSES } from '../../../constants/constants';
import { rideReceiptChangeRequest } from '../../../utils/validations';

import styles from './index.module.css';
import { RIDE_REQUEST_CHANGE_CANCEL_OPTIONS, RIDE_REQUEST_CHANGE_STATUSES, allowedStatusesForRequestChangeOptions } from './changeRequestConstants';
import { renderTimeInputFields } from '../../forms/partials/renderTimeInputFields';


const initialValues = { rideStatus: "", cancellationReason: '', additionalComments: '', actMileage: 0, waitTime: 0, comments: '' };

export const ChangeRequestByTCModal = ({ id, onRequestChange }) => {


  const cancellationReasons = [RIDE_REQUEST_CHANGE_STATUSES.RIDER_NO_SHOW, RIDE_REQUEST_CHANGE_STATUSES.SAME_DAY_CANCELLATION]
  const [visibleModal, setVisibleModal] = useState(false);
  const [status, setStatus] = useState('');
  const [requestChangeStatus, setRequestChangeStatus] = useState('Completed');
  const handleRequestChange = useCallback(
    (values) => {
      onRequestChange({ "rideStatus": status, "rideMileage": values?.actMileage, "waitTime": values?.waitTime, "cancellationType": values?.cancellationReason, "additionalComments": values?.additionalComments, comments: values?.comments } );
    },
    [onRequestChange],
  );
  const formik = useFormik({
    initialValues,
    validationSchema: rideReceiptChangeRequest,
    validateOnMount: true,
    validateOnChange: true,
    onSubmit: handleRequestChange,
  });

  const { resetForm, validateForm, isValid, values, handleSubmit } = formik;

  const onCancel = useCallback(async () => {
    await resetForm(initialValues);
    await validateForm();
    await setVisibleModal(false);
  }, [setVisibleModal, validateForm, resetForm]);

  const updateSelectValue = useCallback(async ({ name, value, setFieldValue, values }) => {
    await setFieldValue(name, value);
    if (name === 'rideStatus') {
      if (cancellationReasons.includes(value)) {
        await setStatus(RIDE_STATUSES.CANCELED);
      } else {
        await setStatus(RIDE_STATUSES.COMPLETED);
      }
    }
  }, []);
  return {
    Modal: (
      <Modal
        visible={visibleModal}
        onCancel={onCancel}
        closable
        width={670}
        bodyStyle={{ height: '40vh' }}
        title={`Change Request For Ride – ID ${id} `}>
        <div className={styles.modalContent}>
          <FormikProvider value={formik}>
            <>
              <form >
                <div className={styles.container}>
                  <label className={styles.cancelRideLabel}>
                    <span className={styles.required}>Status</span>
                    <Select name='rideStatus' placement={"bottomLeft"}
                      onChange={updateSelectValue} options={allowedStatusesForRequestChangeOptions} placeholder='Pick an option' />
                  </label>

                  {status === RIDE_REQUEST_CHANGE_STATUSES.COMPLETED && (<>
                    <div className={styles.timesContainer}  >
                      <label className={styles.cancelRideLabel}>
                        <span className={styles.timesTitle}>{"Total Wait Time:"}</span>
                      </label>
                      <div className={styles.timeInputFieldsContainer}>
                        {renderTimeInputFields("waitTime")}
                      </div>
                    </div>

                    <label className={styles.cancelRideLabel}>
                      <span className={styles.required} > Ride total mileage:</span>
                      <Input name='actMileage' type='number' min={0} />
                    </label>
                  </>)}


                  {status === RIDE_STATUSES.CANCELED && (<>
                    <label className={styles.cancelRideLabel}>
                      <span className={styles.required}>Reason</span>
                      <Select name='cancellationReason' placement={"bottomLeft"} options={RIDE_REQUEST_CHANGE_CANCEL_OPTIONS[values?.rideStatus] ? RIDE_REQUEST_CHANGE_CANCEL_OPTIONS[values?.rideStatus] : []} onChange={updateSelectValue} placeholder='Pick an option' />
                    </label>
                    {(
                      <label className={styles.cancelRideLabel}>
                        <span>Additional Comments for ride Cancelation</span>
                        <InputTextArea
                          name='additionalComments'
                          placeholder='Please fill in reason for ride Cancelation'
                          type='text'
                          autosize={{ minRows: 1, maxRows: 5 }}
                        />
                      </label>
                    )}</>)}

                  <label className={styles.cancelRideLabel}>
                    <span > Comments</span>
                    <InputTextArea
                      name='comments'
                      placeholder='Please fill in reason for ride Change Request'
                      type='text'
                      autosize={{ minRows: 1, maxRows: 5 }}
                    />
                  </label>
                </div>
              </form>
              <div className={classnames({
                [styles.modalBtns]: true,
                [styles.cancelBtns]: true,
              })}>
                <div className={styles.mr6}>
                  <Button myClassName={styles.navigationBtn} onClick={onCancel}>
                    Cancel
                  </Button>
                </div>
                <div className={styles.ml6}>
                  <Button myClassName={styles.navigationBtn} onClick={handleSubmit} disabled={!isValid}>
                    Submit
                  </Button>
                </div>
              </div>
            </>
          </FormikProvider>
        </div>
      </Modal>
    ),
    setChangeRequestModalVisible: setVisibleModal,
    changeRequestModalVisible: visibleModal,
  };
};
