import React, { useState } from 'react';

import { CloseOutlined, CaretDownOutlined } from '@ant-design/icons';
import classnames from 'classnames';
import { v4 as uuidv4 } from 'uuid';

import styles from './index.module.css';

export const TagsList = ({ info, handleDeleteTag, isFieldDisabled }) => {
  const [tagsVisible, setTagsVisible] = useState(false);

  return (
    <div className={styles.tagsList}>
      <span className={styles.stateTitle}>
        {info?.counties?.length ? (
          <span className={styles.stateLabel}>
            <span>{`${info.state} (${info?.counties?.length})`}</span>
            {info?.counties?.length > 10 && (
              <>
                <span
                  className={classnames({
                    [styles.showCountiesButton]: true,
                    [styles.hideCountiesButton]: tagsVisible,
                  })}
                  onClick={() => setTagsVisible(!tagsVisible)}>
                  <span>Show counties</span>
                  <CaretDownOutlined className={styles.showCountiesArrow} />
                </span>
                {!isFieldDisabled && (
                  <span
                    className={classnames({
                      [styles.showCountiesButton]: true,
                      [styles.hideCountiesButton]: tagsVisible,
                    })}
                    onClick={handleDeleteTag({ state: info?.state, deleteState: true })}>
                    <span> Clear all</span>
                    <CloseOutlined className={styles.clearAllCounties} />
                  </span>
                )}
              </>
            )}
          </span>
        ) : null}
      </span>

      <div
        data-testid='taglist'
        className={classnames({
          [styles.countiesLabels]: true,
          [styles.hideTagsList]: info?.counties?.length > 10,
          [styles.visibleTagsList]: tagsVisible,
        })}>
        {info.counties.map((county) => (
          <span className={styles.tag} key={uuidv4()}>
            <span>{county}</span>
            {!isFieldDisabled && (
              <CloseOutlined
                className={styles.closeIcon}
                onClick={handleDeleteTag({ state: info.state, county, deleteState: info?.counties?.length <= 1 })}
              />
            )}
          </span>
        ))}
      </div>
    </div>
  );
};
