import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { checkRiderIsUnique, resetIsUniqueCheckResults } from '../store/riders';

import { LodashHelper } from '../utils';
import { usePrevious } from './usePrevious';

export const getUniqueUserFieldValues = (values) =>
  values && {
    firstName: values.firstName,
    lastName: values.lastName,
    dob: values.dob,
    ...(values?.organizationId && { organizationId: values?.organizationId }),
  };

export const useCheckForUniqueRider = (currentValues, allowCheck) => {
  const dispatch = useDispatch();

  const previousValues = usePrevious(currentValues);

  const delayedQuery = useRef(
    LodashHelper.debounce((value) => {
      return dispatch(checkRiderIsUnique(value));
    }, 1000),
  ).current;

  useEffect(() => {
    if (
      allowCheck &&
      currentValues?.firstName &&
      currentValues?.lastName &&
      currentValues?.dob &&
      (previousValues?.firstName !== currentValues?.firstName ||
        previousValues?.lastName !== currentValues?.lastName ||
        previousValues?.dob !== currentValues?.dob)
    ) {
      delayedQuery(getUniqueUserFieldValues(currentValues));
    }
    return;
  }, [allowCheck, delayedQuery, dispatch, previousValues, currentValues]);

  useEffect(() => () => dispatch(resetIsUniqueCheckResults()), [dispatch]);
};
