import { applyMiddleware, combineReducers, createStore, compose } from 'redux';

 

import authorization from './authorization';
import drafts from './drafts';
import healthOrganizations from './health_organizations';
import transportOrganizations from './transport_organizations';
import preferences from './preferences';
import users from './users';
import rides from './rides';
import drivers from './drivers';
import riders from './riders';
import common from './common';
import tcBilling from "./tc_billing";
import { thunk } from 'redux-thunk';
import addressBook from './addressbook';
// import thunk from 'redux-thunk';

const composeEnhancers =
  process.env.NODE_ENV !== 'production' && typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;
/* eslint-enable */

export const reducers = combineReducers({
  authorization,
  drafts,
  rides,
  healthOrganizations,
  transportOrganizations,
  preferences,
  users,
  drivers,
  riders,
  common,
  tcBilling,
  addressBook
});

export const store = createStore(reducers, composeEnhancers(applyMiddleware(thunk)));
