import { useRef, useEffect } from 'react';

export const useInterval = (callback, delay, workerTimers) => {
  const intervalRef = useRef(null);
  const savedCallback = useRef(callback);

  useEffect(() => {
    savedCallback.current = callback;
    return;
  }, [callback]);

  useEffect(() => {
    const tick = () => savedCallback.current();
    if (typeof delay === 'number') {
      intervalRef.current = workerTimers.setInterval(tick, delay);
      return () => workerTimers.clearInterval(intervalRef.current);
    }

    return;
  }, [delay, workerTimers]);
  return intervalRef;
};
