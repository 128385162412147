import classNames from 'classnames';
import { getIn } from 'formik';
import { Input } from '../../../../../index';
import { LodashHelper } from '../../../../../../utils';
import { renderSaveChangesButton } from '../../partials/SaveChangesButton';

import { USER_PREFERENCES_FIELDS } from '../../../../mock';
import styles from './index.module.css';


export const UserPreferenceForm = ({ values, initialValues, errors, disabled = false, handleSaveChanges }) => {
  const parentFieldName = USER_PREFERENCES_FIELDS[0].parentFieldName;
  const hasValidationErrors = errors?.[parentFieldName];

  const isFormUpdated = () =>{
    return initialValues?.[parentFieldName] && !LodashHelper.isEqual(getIn(initialValues, parentFieldName), getIn(values, parentFieldName));
  }

  return (
    <>
    <div className={styles.userPreferencesContainer}>
      <span className={styles.userPreferencesHead}>User Preferences</span>
      <span
        className={classNames({
          [styles.userPreferencesLabel]: true,
          [styles.required]: true,
        })}>
        {USER_PREFERENCES_FIELDS[0].title}
      </span>
      <div className={styles.userPreferencesInput}>
        <Input name={USER_PREFERENCES_FIELDS[0].name} type='number' min={1} />
      </div> 
      <span
        className={classNames({
          [styles.userPreferencesLabel]: true,
          [styles.required]: true,
        })}>
        {USER_PREFERENCES_FIELDS[1].title}
      </span>
      <label className={classNames({
          [styles.userPreferencesInput]: true,
          [styles.userPreferencesInput2]: true,
        })}>
          
        <Input name={USER_PREFERENCES_FIELDS[1].name} type='number' min={1} />
        <span className={styles.measurement}>Hour(s)</span>
      </label> 
      { isFormUpdated() &&
        <div className={styles.userPreferencesBtnContainer}>
          {renderSaveChangesButton(parentFieldName, handleSaveChanges, values, hasValidationErrors)}
        </div>}
      </div>
    </>
  );
};
