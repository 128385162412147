import React, { useCallback } from 'react';
import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import classnames from 'classnames';
import { useRidersAutoComplete } from '../../../../../hooks/useRidersAutoComplete';
import { AutoCompleteDropDownOverlay } from '../../FilterComponents/AutoCompleteDropDownOverlay/AutoCompleteDropDownOverlay';

import styles from './index.module.css';
import { getIn } from 'formik';

const renderDropDownComponent =
  ({ myClassName, value, title, handleClose }) =>
  ({ visible }) =>
    (
      <div
        className={classnames({
          [styles.headRow]: true,
          [myClassName]: myClassName,
          [styles.headRowActive]: visible,
          [styles.headRowDisabled]: !visible,
        })}>
        <>
          <span className={styles.cellTitle}>{value && !visible ? value : title}</span>
          {value && !visible ? (
            <CloseOutlined
              className={classnames({
                [styles.icon]: true,
                [styles.disabled]: !visible,
              })}
              onClick={handleClose}
            />
          ) : (
            <SearchOutlined
              className={classnames({
                [styles.icon]: true,
                [styles.disabled]: !visible,
              })}
            />
          )}
        </>
      </div>
    );

export const FilterAutoCompleteCell = ({
  formattedName,
  name,
  placeholder,
  title,
  values,
  setFieldValue,
  onClose,
  myClassName,
  ...rest
}) => {
  const handleSearch = useCallback(
    (value) => {
      setFieldValue(formattedName, value);

      if (!value && onClose) {
        setFieldValue(name, '');
        onClose();
      }
    },
    [setFieldValue, name, onClose, formattedName],
  );

  const handleSelect = useCallback(
    async (id, entity) => {
      await setFieldValue(name, id);
      await setFieldValue(formattedName, entity?.name);
    },
    [setFieldValue, name, formattedName],
  );

  const { notFoundContent, suggestions, handleSearchRider, handleSelectRider } = useRidersAutoComplete({
    handleSearch,
    handleSelect,
    delay: 1000,
  });

  const handleClose = (e) => {
    e.stopPropagation();
    setFieldValue(formattedName, '');
    setFieldValue(name, '');

    if (onClose) onClose();
  };

  return (
    <AutoCompleteDropDownOverlay
      notFoundContent={notFoundContent}
      suggestions={suggestions}
      handleSearchRider={handleSearchRider}
      handleSelectRider={handleSelectRider}
      placeholder={placeholder}
      Component={renderDropDownComponent({
        value: getIn(values, name),
        title,
        handleClose,
        myClassName,
      })}
      values={values}
      setFieldValue={setFieldValue}
      name={name}
      {...rest}
    />
  );
};
