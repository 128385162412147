import { InfoOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import React from 'react';
import { Tooltip } from '../../..';
import { renderRecordValue } from '../../../../utils';

import styles from './index.module.css';

export const getFlexibleHoursMessage = (range) =>
  `The range allowed for the flexible pickup time is up to ${renderRecordValue(
    range?.hours > 0 && `${range?.hours} hour(s)`,
    '',
  )}${
    (renderRecordValue(range?.minutes > 0 && ` ${range?.minutes} minute(s)`), '')
  }. Please indicate the earliest and the latest times the rider can be picked up.`;

export const ThirdStepTooltip = ({ range, useFlexibleHoursMessage }) => {
  const flexibleHoursMessage = getFlexibleHoursMessage(range);
  const roundTripMessage = `The minimum delay to start a Roundtrip ride is ${renderRecordValue(
    range?.hours > 0 && `${range?.hours} hour(s)`,
    '',
  )}${renderRecordValue(range?.minutes > 0 && ` ${range?.minutes} minute(s)`, '')} from the Pick Up Date & Time.`;

  const tooltipMessage = useFlexibleHoursMessage ? flexibleHoursMessage : roundTripMessage;

  return range?.hours || range?.minutes ? (
    <Tooltip title={tooltipMessage}>
      <span
        className={classNames({
          [styles.tooltipInfo]: true,
          [styles.tooltipGap]: !useFlexibleHoursMessage,
        })}>
        <InfoOutlined className={styles.infoIcon} />
      </span>
    </Tooltip>
  ) : null;
};
