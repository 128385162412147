import { RECAPTHA_ACTIONS, RIDE_STATUSES, URLS, USER_ROLES } from '../constants/constants';
import { statusesDictionary } from '../dictionaries';
import { baseRequest, getRidesRequestParams, LodashHelper } from '../utils';
import download from 'downloadjs';
import { getPage, normalizeSavedFilters, transformDraftToRide } from '../utils/helpers';
import { DraftHelper } from '../utils/DraftHelper';
import { fetchCaptchaToken, types as commonTypes } from './common';
import { updateRiderPhone } from './riders';

const types = {
  GET_RIDE_REQUEST: 'GET_RIDE_REQUEST',
  GET_RIDE_SUCCESS: 'GET_RIDE_SUCCESS',
  GET_RIDE_ERROR: 'GET_RIDE_ERROR',

  FETCH_RIDES_REQUEST: 'FETCH_RIDES_REQUEST',
  FETCH_RIDES_SUCCESS: 'FETCH_RIDES_SUCCESS',
  FETCH_RIDES_ERROR: 'FETCH_RIDES_ERROR',

  EDIT_RIDE_REQUEST: 'EDIT_RIDE_REQUEST',
  EDIT_RIDE_SUCCESS: 'EDIT_RIDE_SUCCESS',
  EDIT_RIDE_ERROR: 'EDIT_RIDE_ERROR',

  FETCH_MATCHED_DRIVERS_FOR_RIDE_REQUEST: 'FETCH_MATCHED_DRIVERS_FOR_RIDE_REQUEST',
  FETCH_MATCHED_DRIVERS_FOR_RIDE_SUCCESS: 'FETCH_MATCHED_DRIVERS_FOR_RIDE_SUCCESS',
  FETCH_MATCHED_DRIVERS_FOR_RIDE_ERROR: 'FETCH_MATCHED_DRIVERS_FOR_RIDE_ERROR',

  TC_ACCEPT_RIDE_REQUEST: 'TC_ACCEPT_RIDE_REQUEST',
  TC_ACCEPT_RIDE_SUCCESS: 'TC_ACCEPT_RIDE_SUCCESS',
  TC_ACCEPT_RIDE_ERROR: 'TC_ACCEPT_RIDE_ERROR',

  TC_ACCEPT_RESCHEDULED_RIDE_REQUEST: 'TC_ACCEPT_RESCHEDULED_RIDE_REQUEST',
  TC_ACCEPT_RESCHEDULED_RIDE_SUCCESS: 'TC_ACCEPT_RESCHEDULED_RIDE_SUCCESS',
  TC_ACCEPT_RESCHEDULED_RIDE_ERROR: 'TC_ACCEPT_RESCHEDULED_RIDE_ERROR',

  TC_ASSIGN_DRIVER_REQUEST: 'TC_ASSIGN_DRIVER_REQUEST',
  TC_ASSIGN_DRIVER_SUCCESS: 'TC_ASSIGN_DRIVER_SUCCESS',
  TC_ASSIGN_DRIVER_ERROR: 'TC_ASSIGN_DRIVER_ERROR',

  TC_CANCEL_RIDE_REQUEST: 'TC_CANCEL_RIDE_REQUEST',
  TC_CANCEL_RIDE_SUCCESS: 'TC_CANCEL_RIDE_SUCCESS',
  TC_CANCEL_RIDE_ERROR: 'TC_CANCEL_RIDE_ERROR',

  HO_CANCEL_RIDE_REQUEST: 'HO_CANCEL_RIDE_REQUEST',
  HO_CANCEL_RIDE_SUCCESS: 'HO_CANCEL_RIDE_SUCCESS',
  HO_CANCEL_RIDE_ERROR: 'HO_CANCEL_RIDE_ERROR',


  HO_RESCHEDULE_RIDE_REQUEST: 'HO_RESCHEDULE_RIDE_REQUEST',
  HO_RESCHEDULE_RIDE_SUCCESS: 'HO_RESCHEDULE_RIDE_SUCCESS',
  HO_RESCHEDULE_RIDE_ERROR: 'HO_RESCHEDULE_RIDE_ERROR',


  TC_DECLINE_RIDE_REQUEST: 'TC_DECLINE_RIDE_REQUEST',
  TC_DECLINE_RIDE_SUCCESS: 'TC_DECLINE_RIDE_SUCCESS',
  TC_DECLINE_RIDE_ERROR: 'TC_DECLINE_RIDE_ERROR',

  SET_START_DATE_RIDE_REQUEST: 'SET_START_DATE_RIDE_REQUEST',
  SET_START_DATE_SUCCESS: 'SET_START_DATE_SUCCESS',
  SET_START_DATE_RIDE_ERROR: 'SET_START_DATE_RIDE_ERROR',

  SET_EDIT_DATE_RIDE_REQUEST: 'SET_EDIT_DATE_RIDE_REQUEST',
  SET_EDIT_DATE_RIDE_SUCCESS: 'SET_EDIT_DATE_RIDE_SUCCESS',
  SET_EDIT_DATE_RIDE_ERROR: 'SET_EDIT_DATE_RIDE_ERROR',

  SET_END_DATE_RIDE_REQUEST: 'SET_END_DATE_RIDE_REQUEST',
  SET_END_DATE_RIDE_SUCCESS: 'SET_END_DATE_RIDE_SUCCESS',
  SET_END_DATE_RIDE_ERROR: 'SET_END_DATE_RIDE_ERROR',

  SET_DRIVER_ARRIVAL_DATE_RIDE_REQUEST: 'SET_DRIVER_ARRIVAL_DATE_RIDE_REQUEST',
  SET_DRIVER_ARRIVAL_DATE_RIDE_SUCCESS: 'SET_DRIVER_ARRIVAL_DATE_RIDE_SUCCESS',
  SET_DRIVER_ARRIVAL_DATE_RIDE_ERROR: 'SET_DRIVER_ARRIVAL_DATE_RIDE_ERROR',

  CHECK_FOR_MATCHED_COMPANIES_REQUEST: 'CHECK_FOR_MATCHED_COMPANIES_REQUEST',
  CHECK_FOR_MATCHED_COMPANIES_SUCCESS: 'CHECK_FOR_MATCHED_COMPANIES_SUCCESS',
  CHECK_FOR_MATCHED_COMPANIES_ERROR: 'CHECK_FOR_MATCHED_COMPANIES_ERROR',

  GET_CUSTOM_USER_FILTER_REQUEST: 'GET_CUSTOM_USER_FILTER_REQUEST',
  GET_CUSTOM_USER_FILTER_SUCCESS: 'GET_CUSTOM_USER_FILTER_SUCCESS',
  GET_CUSTOM_USER_FILTER_ERROR: 'GET_CUSTOM_USER_FILTER_ERROR',

  SAVE_CUSTOM_USER_FILTER_REQUEST: 'SAVE_CUSTOM_USER_FILTER_REQUEST',
  SAVE_CUSTOM_USER_FILTER_SUCCESS: 'SAVE_CUSTOM_USER_FILTER_SUCCESS',
  SAVE_CUSTOM_USER_FILTER_ERROR: 'SAVE_CUSTOM_USER_FILTER_ERROR',

  GENERATE_REPORT_REQUEST: 'GENERATE_REPORT_REQUEST',
  GENERATE_REPORT_SUCCESS: 'GENERATE_REPORT_SUCCESS',
  GENERATE_REPORT_ERROR: 'GENERATE_REPORT_ERROR',

  RIDER_CANCEL_RIDE_REQUEST: 'RIDER_CANCEL_RIDE_REQUEST',
  RIDER_CANCEL_RIDE_SUCCESS: 'RIDER_CANCEL_RIDE_SUCCESS',
  RIDER_CANCEL_RIDE_ERROR: 'RIDER_CANCEL_RIDE_ERROR',

  VALIDATE_PICKUP_DATE_REQUEST: 'VALIDATE_PICKUP_DATE_REQUEST',
  VALIDATE_PICKUP_DATE_SUCCESS: 'VALIDATE_PICKUP_DATE_SUCCESS',
  VALIDATE_PICKUP_DATE_ERROR: 'VALIDATE_PICKUP_DATE_ERROR',

  FORCE_UPDATE_RIDE_REQUEST: 'FORCE_UPDATE_RIDE_REQUEST',
  FORCE_UPDATE_RIDE_SUCCESS: 'FORCE_UPDATE_RIDE_SUCCESS',
  FORCE_UPDATE_RIDE_ERROR: 'FORCE_UPDATE_RIDE_ERROR',

  SET_SHOW_REFRESH_MESSAGE_NOTIFICATION: 'SET_SHOW_REFRESH_MESSAGE_NOTIFICATION',

  RESET_CHECKING_RESULT_OF_MATCHED_TC: 'RESET_CHECKING_RESULT_OF_MATCHED_TC',

  RESET_RIDES_INFO: 'RESET_RIDES_INFO',

  RESET_WINDOW_OF_TIME_VALIDATION: 'RESET_WINDOW_OF_TIME_VALIDATION',

  APPLY_MY_FILTER: 'APPLY_MY_FILTER',
  RIDE_SET_FILTERED_STATUS: 'RIDE_SET_FILTERED_STATUS',
  RIDE_SET_MY_FILTER_PARAMS: 'RIDE_SET_MY_FILTER_PARAMS',
  SET_CURRENT_PAGE_RIDES: 'SET_CURRENT_PAGE_RIDES',
  CLEAR_CURRENT_RIDE: 'CLEAR_CURRENT_RIDE',

  GET_RIDE_HISTORY_REQUEST: 'GET_RIDE_HISTORY_REQUEST',
  GET_RIDE_HISTORY_SUCCESS: 'GET_RIDE_HISTORY_SUCCESS',
  GET_RIDE_HISTORY_ERROR: 'GET_RIDE_HISTORY_ERROR',

  CREATE_CHANGE_REQUEST_FOR_TC_REQUEST: 'CREATE_CHANGE_REQUEST_FOR_TC_REQUEST',
  CREATE_CHANGE_REQUEST_FOR_TC_SUCCESS: 'CREATE_CHANGE_REQUEST_FOR_TC_SUCCESS',
  CREATE_CHANGE_REQUEST_FOR_TC_ERROR: 'CREATE_CHANGE_REQUEST_FOR_TC_ERROR',


  CREATE_CHANGE_REQUEST_STATUS: 'CREATE_CHANGE_REQUEST_STATUS',
  CHANGE_REQUEST_STATUS_SUCCESS: 'CHANGE_REQUEST_STATUS_SUCCESS',
  CHANGE_REQUEST_STATUS_ERROR: 'CHANGE_REQUEST_STATUS_ERROR',
};


const createCHANGE_REQUESTForTCRequest = () => ({
  type: types.CREATE_CHANGE_REQUEST_FOR_TC_REQUEST,
});

const createChangeCHANGE_REQUESTRequest = () => ({
  type: types.CREATE_CHANGE_REQUEST_STATUS,
});

const forceUpdateRideRequest = () => ({
  type: types.FORCE_UPDATE_RIDE_REQUEST,
});

export const riderCancelRideRequest = () => ({
  type: types.RIDER_CANCEL_RIDE_REQUEST,
});

export const setShowRefreshNotification = (payload) => ({
  type: types.SET_SHOW_REFRESH_MESSAGE_NOTIFICATION,
  payload,
});

export const applyMyFilter = (payload) => ({


  type: types.APPLY_MY_FILTER,
  payload,
});

export const saveCustomUserFilterRequest = (payload) => ({
  type: types.SAVE_CUSTOM_USER_FILTER_REQUEST,
  payload,
});

export const getCustomUserFilterRequest = (payload) => ({
  type: types.GET_CUSTOM_USER_FILTER_REQUEST,
  payload,
});

export const setFilteredStatus = (payload) => ({
  type: types.RIDE_SET_FILTERED_STATUS,
  payload,
});

export const setMyFilter = (payload) => ({
  type: types.RIDE_SET_MY_FILTER_PARAMS,
  payload: LodashHelper.removeEmptyAndTrim(payload, true) || {},
});

export const setCurrentPage = (page) => ({
  type: types.SET_CURRENT_PAGE_RIDES,
  payload: page,
});

export const clearCurrentRide = () => ({
  type: types.CLEAR_CURRENT_RIDE,
});

const generateReportRequest = () => ({
  type: types.GENERATE_REPORT_REQUEST,
});

const getRideRequest = () => ({
  type: types.GET_RIDE_REQUEST,
});

const getRideHistoryRequest = () => ({
  type: types.GET_RIDE_HISTORY_REQUEST,
});

const fetchRidesRequest = () => ({
  type: types.FETCH_RIDES_REQUEST,
});

const editRideRequest = () => ({
  type: types.EDIT_RIDE_REQUEST,
});

const fetchMatchedDriversRequest = () => ({
  type: types.FETCH_MATCHED_DRIVERS_FOR_RIDE_REQUEST,
});

const tcAcceptRideRequest = () => ({
  type: types.TC_ACCEPT_RIDE_REQUEST,
});
const tcAcceptRescheduledRideRequest = () => ({
  type: types.TC_ACCEPT_RESCHEDULED_RIDE_REQUEST,
});


const tcCancelRideRequest = () => ({
  type: types.TC_CANCEL_RIDE_REQUEST,
});

const hoCancelRideRequest = () => ({
  type: types.HO_CANCEL_RIDE_REQUEST,
});

const hoRescheduleRideRequest = () => ({
  type: types.HO_RESCHEDULE_RIDE_REQUEST,
});

const tcAssignDriverRequest = () => ({
  type: types.TC_ASSIGN_DRIVER_REQUEST,
});

const tcDeclineRideRequest = () => ({
  type: types.TC_DECLINE_RIDE_REQUEST,
});

const setStartDateRideRequest = () => ({
  type: types.SET_START_DATE_RIDE_REQUEST,
});

const setEditDateRideRequest = () => ({
  type: types.SET_EDIT_DATE_RIDE_REQUEST,
});

const setEndDateRideRequest = () => ({
  type: types.SET_END_DATE_RIDE_REQUEST,
});

const setDriverArrivalDateRideRequest = () => ({
  type: types.SET_DRIVER_ARRIVAL_DATE_RIDE_REQUEST,
});


const checkForMatchedCompaniesRequest = () => ({
  type: types.CHECK_FOR_MATCHED_COMPANIES_REQUEST,
});

const validatePickUpDateRequest = () => ({
  type: types.VALIDATE_PICKUP_DATE_REQUEST,
});

export const validaPickUpDate = (payload) => {
  const success = (payload) => ({
    type: types.VALIDATE_PICKUP_DATE_SUCCESS,
    payload,
  });

  const failure = (error) => ({
    type: types.VALIDATE_PICKUP_DATE_ERROR,
    payload: error,
  });

  return (dispatch) => {
    dispatch(validatePickUpDateRequest());
    return baseRequest('POST', `/ride-requests/validate-pick-up-date`, payload)
      .then((response) => {
        dispatch(success());
      })
      .catch((error) => {
        if (error) dispatch(failure(error?.response?.data));
      });
  };
};



export const createChangeRequestForTC = ({ payload, onSuccesCallback }) => {
  const success = (payload) => ({
    type: types.CREATE_CHANGE_REQUEST_FOR_TC_SUCCESS,
    payload,
  });

  const failure = (error) => ({
    type: types.CREATE_CHANGE_REQUEST_FOR_TC_ERROR,
    payload: error,
  });

  return (dispatch) => {
    dispatch(createCHANGE_REQUESTForTCRequest());
    return baseRequest('POST', `/change-request`, payload)
      .then((response) => {
        dispatch(success(response.data));
        onSuccesCallback && onSuccesCallback();
      })
      .catch((error) => {
        if (error) dispatch(failure(error?.response?.data));
      });
  };
};


export const updateChangeRequestStatus = ({ payload, onSuccesCallback }) => {
  const success = (payload) => ({
    type: types.CHANGE_REQUEST_STATUS_SUCCESS,
    payload,
  });

  const failure = (error) => ({
    type: types.CHANGE_REQUEST_STATUS_ERROR,
    payload: error,
  });

  return (dispatch) => {
    dispatch(createChangeCHANGE_REQUESTRequest());
    return baseRequest('PUT', `/change-request/update-change-request-status`, payload)
      .then((response) => {
        dispatch(success(response));
        dispatch(getRideHistory(response?.data?.rideId));
        onSuccesCallback && onSuccesCallback();
      })
      .catch((error) => {
        if (error) dispatch(failure(error?.response?.data));
      });
  };
};

export const resetWindowOfTimeValidation = () => ({
  type: types.RESET_WINDOW_OF_TIME_VALIDATION,
  payload: true,
});

export const getCustomUserFilter = () => {
  const success = (payload) => ({
    type: types.GET_CUSTOM_USER_FILTER_SUCCESS,
    payload,
  });

  const failure = (error) => ({
    type: types.GET_CUSTOM_USER_FILTER_ERROR,
    payload: error,
  });

  return (dispatch) => {
    dispatch(getCustomUserFilterRequest());
    return baseRequest('GET', `/custom-ride-filters`)
      .then((response) => {
        normalizeSavedFilters(response);
        dispatch(success(LodashHelper.removeEmpty(response.data, true)));
      })
      .catch((error) => {
        if (error) dispatch(failure(error?.response?.data));
      });
  };
};

export const saveCustomUserFilter = (payload) => {
  const success = (payload) => ({
    type: types.SAVE_CUSTOM_USER_FILTER_SUCCESS,
    payload,
  });

  const failure = (error) => ({
    type: types.SAVE_CUSTOM_USER_FILTER_ERROR,
    payload: error,
  });

  return (dispatch) => {
    dispatch(saveCustomUserFilterRequest());
    return baseRequest('PUT', `/custom-ride-filters`, { ...getRidesRequestParams(payload) })
      .then((response) => {
        normalizeSavedFilters(response);
        dispatch(success(LodashHelper.removeEmpty(response.data, true)));
      })
      .catch((error) => {
        if (error) dispatch(failure(error?.response?.data));
      });
  };
};

export const checkForMatchedCompanies = (payload, callback) => {
  const success = (payload) => ({
    type: types.CHECK_FOR_MATCHED_COMPANIES_SUCCESS,
    payload,
  });

  const failure = (error) => ({
    type: types.CHECK_FOR_MATCHED_COMPANIES_ERROR,
    payload: error,
  });

  return (dispatch) => {
    dispatch(checkForMatchedCompaniesRequest());
    return baseRequest('POST', `/transport-companies/matching-tc`, payload)
      .then((response) => {
        dispatch(success(response.data));
      })
      .catch((error) => {
        if (error) dispatch(failure(error?.response?.data));
        callback && callback();
      });
  };
};

export const getRideHistory = (id) => {
  const success = (payload) => ({
    type: types.GET_RIDE_HISTORY_SUCCESS,
    payload,
  });

  const failure = (error) => ({
    type: types.GET_RIDE_HISTORY_ERROR,
    payload: error,
  });

  return (dispatch) => {

    dispatch(getRideHistoryRequest());
    return baseRequest('GET', `/ride-requests/history/${id}`)
      .then((response) => {

        dispatch(success(response.data));
      })
      .catch((error) => {
        if (error) dispatch(failure(error));
      });
  };
};

export const getRide = (id, Navigation) => {

  const navigate = Navigation;

  const success = (payload) => ({
    type: types.GET_RIDE_SUCCESS,
    payload,
  });

  const failure = (error) => ({
    type: types.GET_RIDE_ERROR,
    payload: error,
  });

  return (dispatch) => {
    dispatch(getRideRequest());

    const query = new URLSearchParams(navigate?.location?.search);
    const editSeriesOption = query.get('mode');

    return baseRequest('GET', `/ride-requests/${id}`, null, null, {
      editSeriesOption,
    })
      .then((response) => {

        (navigate?.location?.pathname === URLS.CREATE_RIDE || navigate?.location?.pathname === URLS.EDIT_DRAFT) &&
          sessionStorage.setItem('draft', JSON.stringify(response.data));

        dispatch(success(response.data));
      })
      .catch((error) => {
        if (error) dispatch(failure(error?.response?.data));
        if (error?.response?.status === 404) {
          navigate?.push(URLS.RIDE_NOT_FOUND, { from: navigate?.location?.pathname });
        }
      });
  };
};

export const editRide = ({ id, editSeriesOption, payload, handleLeave, Navigation }) => {

  const navigate = Navigation;
  const success = (payload) => ({
    type: types.EDIT_RIDE_SUCCESS,
    payload,
  });

  const failure = (error) => ({
    type: types.EDIT_RIDE_ERROR,
    payload: error,
  });

  return (dispatch) => {
    dispatch(editRideRequest());
    return baseRequest(
      'PUT',
      `/ride-requests/${id}`,
      transformDraftToRide(DraftHelper.prepareDraftForSaving(payload)),
      {},
      {
        editSeriesOption,
      },
    )
      .then((response) => {
        handleLeave && handleLeave();
        if (response?.data) {
          dispatch(success(response.data));
          navigate.push(`${URLS.RIDES}/${id}`);
        } else {
          navigate.push(URLS.RIDES);
        }
      })
      .catch((error) => {
        if (error) dispatch(failure(error?.response?.data));
      });
  };
};

export const editRideWithRiderInfo = ({ id, editSeriesOption, payload, handleLeave, Navigation }) => {
  return (dispatch) => {
    dispatch(
      updateRiderPhone({
        id: payload?.step1?.riderInfo?.id,
        values: {
          phoneNumber: payload?.step1?.riderInfo?.phoneNumber,
          canPhoneReceiveTexts: payload?.step1?.riderInfo?.canPhoneReceiveTexts,
          canPhoneReceiveCalls: payload?.step1?.riderInfo?.canPhoneReceiveCalls,
        },
      }),
    )
      .then((res) => {
        res && dispatch(editRide({ id, editSeriesOption, payload, handleLeave, Navigation }));
      })
      .catch((e) => { });
  };
};

export const fetchRides = (filtered, Navigation) => {
  const { go } = Navigation;

  const success = (payload) => ({
    type: types.FETCH_RIDES_SUCCESS,
    payload,
  });

  const failure = (error) => ({
    type: types.FETCH_RIDES_ERROR,
    payload: error,
  });

  return (dispatch, getStore) => {
    if (filtered) go(`${URLS.RIDES}?page=1`);
    const { limit, myFilterParams } = getStore().rides;

    let currentPage = getPage();

    dispatch(setCurrentPage(currentPage));
    dispatch(fetchRidesRequest());
    return baseRequest(
      "GET",
      `/ride-requests?`,
      null,
      {},
      {
        docsPerPage: limit,
        page: currentPage,
        ...getRidesRequestParams(myFilterParams),
      }
    )
      .then((response) => {
        dispatch(success(response.data));
      })
      .catch((error) => {
        if (error) {
          dispatch(failure([]));
        }
      });
  };
};

export const fetchMathcedDriversForRide = (id) => {
  const success = (payload) => ({
    type: types.FETCH_MATCHED_DRIVERS_FOR_RIDE_SUCCESS,
    payload,
  });

  const failure = (error) => ({
    type: types.FETCH_MATCHED_DRIVERS_FOR_RIDE_ERROR,
    payload: error,
  });

  return (dispatch) => {
    dispatch(fetchMatchedDriversRequest());
    return baseRequest('GET', `/ride-requests/${id}/drivers`)
      .then((response) => {
        dispatch(success(response.data));
      })
      .catch((error) => {
        if (error) {
          dispatch(failure([]));
        }
      });
  };
};

export const tcAcceptRide = (id) => {
  const success = (payload) => ({
    type: types.TC_ACCEPT_RIDE_SUCCESS,
    payload,
  });

  const failure = (error) => ({
    type: types.TC_ACCEPT_RIDE_ERROR,
    payload: error,
  });

  return (dispatch) => {
    dispatch(tcAcceptRideRequest());
    return baseRequest('PATCH', `/ride-requests/${id}/accept`)
      .then((response) => {
        dispatch(success(response.data));
      })
      .catch((error) => {
        if (error) {
          dispatch(failure([]));
        }
      });
  };
};

export const tcAcceptRescheduleRide = ({id,payload}) => {
  const success = (payload) => ({
    type: types.TC_ACCEPT_RESCHEDULED_RIDE_SUCCESS,
    payload,
  });

  const failure = (error) => ({
    type: types.TC_ACCEPT_RESCHEDULED_RIDE_ERROR,
    payload: error,
  });

  return (dispatch) => {
    dispatch(tcAcceptRescheduledRideRequest());
    return baseRequest('PATCH', `/ride-requests/${id}/accept-reschedule`,payload)
      .then((response) => {
        dispatch(success(response.data));
      })
      .catch((error) => {
        if (error) {
          dispatch(failure([]));
        }
      });
  };
};

export const tcAssignDriver = ({ rideId, payload, role }) => {
  const success = (payload) => ({
    type: types.TC_ASSIGN_DRIVER_SUCCESS,
    payload,
  });

  const failure = (error) => ({
    type: types.TC_ASSIGN_DRIVER_ERROR,
    payload: error,
  });

  return (dispatch) => {
    dispatch(tcAssignDriverRequest());
    return baseRequest('PATCH', `/ride-requests/${rideId}/driver`, payload)
      .then((response) => {
        
        dispatch(success(response.data));

        if (role === USER_ROLES.SUPER_ADMIN) {
          dispatch(getRideHistory(rideId));
        }
      })
      .catch((error) => {
        if (error) {
          dispatch(failure([]));
        }
      });
  };
};

export const tcCancelRide = ({ rideId, payload }) => {
  const success = (payload) => ({
    type: types.TC_CANCEL_RIDE_SUCCESS,
    payload,
  });

  const failure = (error) => ({
    type: types.TC_CANCEL_RIDE_ERROR,
    payload: error,
  });

  return (dispatch) => {
    dispatch(tcCancelRideRequest());
    return baseRequest('PATCH', `/ride-requests/${rideId}/cancel-by-tc`, payload)
      .then((response) => {
        dispatch(success(payload));
      })
      .catch((error) => {
        if (error) {
          dispatch(failure(''));
        }
      });
  };
};

export const hoCancelRide = ({ rideId, payload, userRole }) => {
  const success = (payload) => ({
    type: types.HO_CANCEL_RIDE_SUCCESS,
    payload,
  });

  const failure = (error) => ({
    type: types.HO_CANCEL_RIDE_ERROR,
    payload: error,
  });

  return (dispatch) => {
    dispatch(hoCancelRideRequest());
    return baseRequest('PATCH', `/ride-requests/${rideId}/cancel-by-ho`, payload)
      .then((response) => {
        dispatch(success(response?.data));

        if (userRole === USER_ROLES.SUPER_ADMIN) {
          dispatch(getRideHistory(rideId));
        }
      })
      .catch((error) => {
        if (error) {
          dispatch(failure(''));
        }
      });
  };
};

export const rescheduleRide = ({ rideId, payload }) => {
  const success = (payload) => ({
    type: types.HO_RESCHEDULE_RIDE_SUCCESS,
    payload,
  });

  const failure = (error) => ({
    type: types.HO_RESCHEDULE_RIDE_ERROR,
    payload: error,
  });

  return (dispatch) => {
    dispatch(hoRescheduleRideRequest());
    return baseRequest('PATCH', `/ride-requests/${rideId}/reschedule`, payload)
      .then((response) => {
        dispatch(success(response?.data));


      })
      .catch((error) => {
        dispatch(failure(''));
        if (error) {
          dispatch(failure(''));
        }
      });
  };
};

export const tcDeclineRide = ({ rideId, payload }) => {
  const success = (payload) => ({
    type: types.TC_DECLINE_RIDE_SUCCESS,
    payload,
  });

  const failure = (error) => ({
    type: types.TC_DECLINE_RIDE_ERROR,
    payload: error,
  });

  return (dispatch) => {
    dispatch(tcDeclineRideRequest());
    return baseRequest('PATCH', `/ride-requests/${rideId}/decline`, payload)
      .then((response) => {
        dispatch(success(response));
      })
      .catch((error) => {
        if (error) {
          dispatch(failure(''));
        }
      });
  };
};

export const startRide = ({ rideId, payload }) => {
  const success = (payload) => ({
    type: types.SET_START_DATE_SUCCESS,
    payload,
  });

  const failure = (error) => ({
    type: types.SET_START_DATE_RIDE_ERROR,
    payload: error,
  });

  return (dispatch) => {
    dispatch(setStartDateRideRequest());
    return baseRequest('PATCH', `/ride-requests/${rideId}/start`, payload)
      .then((response) => {
        dispatch(success(payload.datetime));
      })
      .catch((error) => {
        if (error) {
          dispatch(failure(''));
        }
      });
  };
};

export const driverArrival = ({ rideId, payload }) => {
  const success = (payload) => ({
    type: types.SET_DRIVER_ARRIVAL_DATE_RIDE_SUCCESS,
    payload,
  });

  const failure = (error) => ({
    type: types.SET_DRIVER_ARRIVAL_DATE_RIDE_ERROR,
    payload: error,
  });

  return (dispatch) => {
    dispatch(setDriverArrivalDateRideRequest());
    return baseRequest('PATCH', `/ride-requests/${rideId}/arrival`, payload)
      .then((response) => {
        console.log('after update', response);
        dispatch(success(response));
        dispatch(getRide(rideId));
      })
      .catch((error) => {
        if (error) {
          dispatch(failure(''));
        }
      });
  };
};

export const editStartTime = ({ rideId, payload }) => {
  const success = (payload) => ({
    type: types.SET_EDIT_DATE_RIDE_SUCCESS,
    payload,
  });

  const failure = (error) => ({
    type: types.SET_EDIT_DATE_RIDE_ERROR,
    payload: error,
  });

  return (dispatch) => {
    dispatch(setEditDateRideRequest());
    return baseRequest('PATCH', `/ride-requests/${rideId}/start-date`, payload)
      .then((response) => {
        dispatch(success(payload.datetime));
      })
      .catch((error) => {
        if (error) {
          dispatch(failure(''));
        }
      });
  };
};

export const endRide = ({ rideId, payload }) => {
  const success = (payload) => ({
    type: types.SET_END_DATE_RIDE_SUCCESS,
    payload,
  });

  const failure = (error) => ({
    type: types.SET_END_DATE_RIDE_ERROR,
    payload: error,
  });

  return (dispatch) => {
    dispatch(setEndDateRideRequest());
    return baseRequest('PATCH', `/ride-requests/${rideId}/end`, payload)
      .then((response) => {
        dispatch(success(response));
      })
      .catch((error) => {
        if (error) {
          dispatch(failure(''));
        }
      });
  };
};

export const generateReport = (payload, cb) => {
  const success = (payload) => ({
    type: types.GENERATE_REPORT_SUCCESS,
    payload,
  });

  const failure = (error) => ({
    type: types.GENERATE_REPORT_ERROR,
    payload: error,
  });

  return (dispatch, getStore) => {
    const { myFilterParams } = getStore().rides;

    myFilterParams.status = myFilterParams.status?.map((status) => {
      if (status === RIDE_STATUSES.NEW_REQUEST) return RIDE_STATUSES.PENDING;
      return status;
    });

    dispatch(generateReportRequest());
    return baseRequest('POST', `/ride-requests/report`, { ...payload, filters: myFilterParams }, [], [], 'blob')
      .then((response) => {
        const extension = response.headers['content-type'] === 'text/csv' ? 'csv' : 'xlsx';
        download(response.data, `export.${extension}`, response.headers['content-type']);
        cb();
        dispatch(success(response));
      })
      .catch((error) => {
        if (error) {
          dispatch(failure(''));
        }
      });
  };
};

export const riderCancelRide = ({ id, payload }) => {
  const success = (payload) => ({
    type: types.RIDER_CANCEL_RIDE_SUCCESS,
    payload,
  });

  const failure = (error) => ({
    type: types.RIDER_CANCEL_RIDE_ERROR,
    payload: error,
  });

  return async (dispatch, getStore) => {
    await dispatch(fetchCaptchaToken(RECAPTHA_ACTIONS.CANCEL_RIDE_BY_RIDER));
    const recaptchaToken = getStore().common.recaptchaToken;
    dispatch(riderCancelRideRequest());
    return baseRequest('POST', `/public/ride-requests/${id}/cancel-ride-by-rider`, payload, { recaptchaToken })
      .then((response) => {
        dispatch(success(response));
      })
      .catch((error) => {
        if (error) {
          dispatch(failure(''));
        }
      });
  };
};

export const forceUpdateRide = ({ id, payload, onSuccesCallback }) => {
  const success = (payload) => ({
    type: types.FORCE_UPDATE_RIDE_SUCCESS,
    payload,
  });

  const failure = (error) => ({
    type: types.FORCE_UPDATE_RIDE_ERROR,
    payload: error,
  });

  return (dispatch) => {
    dispatch(forceUpdateRideRequest());
    return baseRequest('PUT', `/ride-requests/${id}/force-update`, payload)
      .then((response) => {
        dispatch(success(response.data));
        dispatch(getRideHistory(id));
        onSuccesCallback && onSuccesCallback();
      })
      .catch((error) => {
        if (error) {
          dispatch(failure(''));
        }
      });
  };
};

export const resetHasMatchedTCInfo = () => ({
  type: types.RESET_CHECKING_RESULT_OF_MATCHED_TC,
});

export const resetRides = () => ({
  type: types.RESET_RIDES_INFO,
});

const initialState = {
  ride: null,
  rides: [],
  dataLoaded: false,
  filtered: false,
  myFilterApplied: false,
  myFilterParams: {},
  savedFilterParams: null,
  matchedDrivers: [],
  hasMatchedTC: true,
  limit: 10,
  currentPage: 1,
  totalPages: 1,
  total: 1,
  showRefreshNotification: false,
  loading: false,
  error: false,
  canceledByRider: false,
  windowOfTimeIsValid: true,
  rideHistory: null,
};

export default function rides(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_RIDES_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.FETCH_RIDES_SUCCESS:
      return {
        ...state,
        loading: false,
        rides: action.payload?.data,
        dataLoaded: true,
        currentPage: action.payload?.meta?.page,
        totalPages: action.payload?.meta?.totalPages,
        total: action.payload?.meta?.total,
        filtered: false,
      };

    case types.FETCH_RIDES_ERROR:
      return {
        ...state,
        loading: false,
        rides: [],
        dataLoaded: true,
        currentPage: 1,
        totalPages: 1,
        total: 1,
        filtered: false,
      };

    case types.DRIVER_FETCH_ASSIGNED_RIDES_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.DRIVER_FETCH_ASSIGNED_RIDES_SUCCESS:
      return {
        ...state,
        loading: false,
        assignedRides: [...state.assignedRides, ...action.payload?.data],
        currentPage: action.payload?.meta?.page,
        totalPages: action.payload?.meta?.totalPages,
        total: action.payload?.meta?.total,
      };

    case types.DRIVER_FETCH_ASSIGNED_RIDES_ERROR:
      return {
        ...state,
        loading: false,
        assignedRides: action.payload,
        currentPage: 1,
        totalPages: 1,
        total: 1,
      };

    case types.GET_RIDE_REQUEST:
      return {
        ...state,
        loading: true,
        ride: null,
      };

    case types.GET_RIDE_SUCCESS:
      return {
        ...state,
        ride: action.payload,
        loading: false,
      };

    case types.GET_RIDE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.EDIT_RIDE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.EDIT_RIDE_SUCCESS:
      return {
        ...state,
        ride: action.payload,
        loading: false,
      };

    case types.EDIT_RIDE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.SET_CURRENT_PAGE_RIDES:
      return {
        ...state,
        currentPage: action.payload,
      };

    case types.FETCH_MATCHED_DRIVERS_FOR_RIDE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.FETCH_MATCHED_DRIVERS_FOR_RIDE_SUCCESS:
      return {
        ...state,
        matchedDrivers: action.payload,
        loading: false,
      };

    case types.FETCH_MATCHED_DRIVERS_FOR_RIDE_ERROR:
      return {
        ...state,
        loading: false,
        matchedDrivers: [],
      };

    case types.TC_ACCEPT_RIDE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.TC_ACCEPT_RIDE_SUCCESS:
      return {
        ...state,
        loading: false,
        ride: { ...action.payload },
      };

    case types.TC_ACCEPT_RIDE_ERROR:
      return {
        ...state,
        loading: false,
      };

      case types.TC_ACCEPT_RESCHEDULED_RIDE_REQUEST:
        return {
          ...state,
          loading: true,
        };
  
      case types.TC_ACCEPT_RESCHEDULED_RIDE_SUCCESS:
        return {
          ...state,
          loading: false,
          ride: { ...action.payload },
        };
  
      case types.TC_ACCEPT_RESCHEDULED_RIDE_ERROR:
        return {
          ...state,
          loading: false,
        };

    case types.TC_ASSIGN_DRIVER_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.TC_ASSIGN_DRIVER_SUCCESS:
      return {
        ...state,
        loading: false,
        ride: action.payload,
      };

    case types.TC_ASSIGN_DRIVER_ERROR:
      return {
        ...state,
        loading: false,
      };


    case types.HO_RESCHEDULE_RIDE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.HO_RESCHEDULE_RIDE_SUCCESS:
      return {
        ...state,
        loading: false,
        ride: {...action.payload,
          readableStatus: statusesDictionary[RIDE_STATUSES.RESCHEDULED],
        
        },
      };

    case types.HO_RESCHEDULE_RIDE_ERROR:
      return {
        ...state,
        loading: false,
      };


    case types.TC_CANCEL_RIDE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.TC_CANCEL_RIDE_SUCCESS:
      return {
        ...state,
        loading: false,
        ride: {
          ...state.ride,
          status: RIDE_STATUSES.UNABLE_TO_FULFILL,
          readableStatus: statusesDictionary[RIDE_STATUSES.UNABLE_TO_FULFILL],
          cancelationInfo: action.payload.reason,
        },
      };

    case types.TC_CANCEL_RIDE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.HO_CANCEL_RIDE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.HO_CANCEL_RIDE_SUCCESS:
      return {
        ...state,
        loading: false,
        ride: action.payload,
      };

    case types.HO_CANCEL_RIDE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.TC_DECLINE_RIDE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.TC_DECLINE_RIDE_SUCCESS:
      return {
        ...state,
        loading: false,
        ride: { ...state.ride, readableStatus: statusesDictionary[RIDE_STATUSES.DECLINED] },
      };

    case types.TC_DECLINE_RIDE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.SET_START_DATE_RIDE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.SET_START_DATE_SUCCESS:
      return {
        ...state,
        loading: false,
        ride: {
          ...state.ride,
          readableStatus: statusesDictionary[RIDE_STATUSES.IN_PROGRESS],
          status: RIDE_STATUSES.IN_PROGRESS,
          startDate: action.payload,
        },
      };

    case types.SET_START_DATE_RIDE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.SET_EDIT_DATE_RIDE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.SET_EDIT_DATE_RIDE_SUCCESS:
      return {
        ...state,
        loading: false,
        ride: {
          ...state.ride,
          readableStatus: statusesDictionary[RIDE_STATUSES.IN_PROGRESS],
          status: RIDE_STATUSES.IN_PROGRESS,
          startDate: action.payload,
        },
      };

    case types.SET_EDIT_DATE_RIDE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.SET_END_DATE_RIDE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.SET_DRIVER_ARRIVAL_DATE_RIDE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.SET_END_DATE_RIDE_SUCCESS:
      return {
        ...state,
        loading: false,
        ride: {
          ...state.ride,
          readableStatus: statusesDictionary[RIDE_STATUSES.COMPLETED],
          status: RIDE_STATUSES.COMPLETED,
          endDate: action.payload.endDate,
          comment: action.payload.comment,
          actMileage: action.payload.data.actMileage,
        },
      };

    case types.SET_DRIVER_ARRIVAL_DATE_RIDE_SUCCESS:
      return {
        ...state,
        loading: false,
        ride: {
          ...state.ride,
          driverArrivalTime: action.payload.datetime,
        }
      };

    case types.SET_END_DATE_RIDE_ERROR:
    case types.SET_DRIVER_ARRIVAL_DATE_RIDE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.CLEAR_CURRENT_RIDE:
      return {
        ...state,
        ride: null,
      };

    case types.CHECK_FOR_MATCHED_COMPANIES_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.CHECK_FOR_MATCHED_COMPANIES_SUCCESS:
      return {
        ...state,
        loading: false,
        hasMatchedTC: true,
      };

    case types.CHECK_FOR_MATCHED_COMPANIES_ERROR:
      return {
        ...state,
        loading: false,
        hasMatchedTC: false,
      };

    case types.RIDE_SET_MY_FILTER_PARAMS:
      return {
        ...state,
        myFilterParams: action.payload,
      };

    case types.RIDE_SET_FILTERED_STATUS:
      return {
        ...state,
        filtered: action.payload,
      };

    case types.GET_CUSTOM_USER_FILTER_REQUEST:
      return {
        ...state,
      };

    case types.GET_CUSTOM_USER_FILTER_SUCCESS:
      return {
        ...state,

        savedFilterParams: action.payload,
      };

    case types.GET_CUSTOM_USER_FILTER_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.SAVE_CUSTOM_USER_FILTER_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.SAVE_CUSTOM_USER_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        savedFilterParams: action.payload,
        myFilterApplied: true,
        filtered: false,
      };

    case types.SAVE_CUSTOM_USER_FILTER_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.APPLY_MY_FILTER:
      return {
        ...state,
        myFilterApplied: action.payload,
      };

    case types.GENERATE_REPORT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.GENERATE_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case types.GENERATE_REPORT_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.RIDER_CANCEL_RIDE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.RIDER_CANCEL_RIDE_SUCCESS:
      return {
        ...state,
        loading: false,
        canceledByRider: true,
      };

    case types.RIDER_CANCEL_RIDE_ERROR:
      return {
        ...state,
        loading: false,
        canceledByRider: false,
      };

    case types.VALIDATE_PICKUP_DATE_REQUEST:
      return {
        ...state,
      };

    case types.VALIDATE_PICKUP_DATE_SUCCESS:
      return {
        ...state,
        windowOfTimeIsValid: true,
      };

    case types.VALIDATE_PICKUP_DATE_ERROR:
      return {
        ...state,
        windowOfTimeIsValid: false,
      };

    case types.FORCE_UPDATE_RIDE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.FORCE_UPDATE_RIDE_SUCCESS:
      return {
        ...state,
        ride: action.payload,
        loading: false,
      };

    case types.FORCE_UPDATE_RIDE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.RESET_WINDOW_OF_TIME_VALIDATION:
      return {
        ...state,
        loading: false,
        windowOfTimeIsValid: true,
      };

    case types.RESET_CHECKING_RESULT_OF_MATCHED_TC:
      return {
        ...state,
        hasMatchedTC: true,
      };

    case types.SET_SHOW_REFRESH_MESSAGE_NOTIFICATION:
      return {
        ...state,
        showRefreshNotification: action.payload,
      };

    case commonTypes.CLEAR_STORE_INFO:
      return {
        ...initialState,
        currentPage: 1,
      };

    case types.RESET_RIDES_INFO:
      return {
        ...initialState,
        myFilterParams: state?.myFilterParams,
        rides: [],
        limit: 10,
        currentPage: 1,
        totalPages: 1,
        total: 1,
      };

    case types.GET_RIDE_HISTORY_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.GET_RIDE_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        rideHistory: action.payload,
      };

    case types.GET_RIDE_HISTORY_ERROR:
      return {
        ...state,
        loading: false,
        rideHistory: null,
      };
    case types.CREATE_CHANGE_REQUEST_FOR_TC_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.CREATE_CHANGE_REQUEST_FOR_TC_SUCCESS:

      return {
        ...state,
        ride: {
          ...state.ride,
          changeReqId: action.payload?.id,
        },
        loading: false,
      };

    case types.CREATE_CHANGE_REQUEST_FOR_TC_ERROR:
      return {
        ...state,
        loading: false,
      };
    case types.CREATE_CHANGE_REQUEST_STATUS:
      return {
        ...state,
        loading: true,
      };
    case types.CHANGE_REQUEST_STATUS_SUCCESS:
      return {
        ...state,
        ride: {
          ...state.ride,
          changeReqId: undefined,
        },
        loading: false,
      };

    case types.CHANGE_REQUEST_STATUS_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
