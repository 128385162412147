import React from 'react';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import classnames from 'classnames';

import { vehicleOptionsDictionary } from '../../../dictionaries';
import { DateHelper, renderRecordValue } from '../../../utils';

import styles from './index.module.css';

export const renderColumns = ({ setSelectedRecord, handleEditDraft, setDeleteModalVisible }) => {
  return [
    {
      title: 'Draft ID',
      dataIndex: 'seqId',
      key: 'seqId',
      render: (text) => (
        <div
          className={classnames({
            [styles.draftIdCellValue]: true,
            [styles.cellValue]: true,
          })}>
          {renderRecordValue(text)}
        </div>
      ),
      width: 120,
    },

    {
      title: 'Rider Name',
      dataIndex: 'rider',
      key: 'rider',
      width: 220,
      render: (text) => (
        <div
          className={classnames({
            [styles.riderCellValue]: true,
            [styles.cellValue]: true,
          })}>
          {renderRecordValue(text)}
        </div>
      ),
    },
    {
      title: 'Vehicle Type',
      dataIndex: 'vehicleType',
      key: 'vehicleType',
      width: 300,
      render: (text) => (
        <div
          className={classnames({
            [styles.vehicleCellValue]: true,
            [styles.cellValue]: true,
          })}>
          {renderRecordValue(vehicleOptionsDictionary[text])}
        </div>
      ),
    },
    {
      title: 'Pickup Date',
      dataIndex: 'pickUpDate',
      key: 'pickUpDate',
      width: 240,
      render: (value) => (
        <div
          className={classnames({
            [styles.pickUpDateCellValue]: true,
            [styles.cellValue]: true,
          })}>
          {renderRecordValue(value)}
        </div>
      ),
    },

    {
      title: 'Date Created',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 240,
      render: (text) => (
        <div
          className={classnames({
            [styles.createdByCellValue]: true,
            [styles.cellValue]: true,
          })}>
          {renderRecordValue(text && DateHelper.format(new Date(text), 'M/dd/yyyy'))}
        </div>
      ),
    },
    {
      title: 'Created By',
      dataIndex: 'createdBy',
      key: 'createdBy',
      width: 180,
      render: (text) => (
        <div
          className={classnames({
            [styles.createdByCellValue]: true,
            [styles.cellValue]: true,
          })}>
          {renderRecordValue(text)}
        </div>
      ),
    },
    {
      title: '',
      Component: (
        <th>
          <div className={styles.cellValue} />
        </th>
      ),
      dataIndex: 'actions',
      key: 'actions',
      width: 120,
      render: (text, record) => (
        <div
          style={{ marginLeft: 'auto' }}
          className={classnames({
            [styles.actionsColumn]: true,
            [styles.cellValue]: true,
          })}>
          <EditOutlined
            className={styles.actionsIcon}
            onClick={(e) => {
              e.stopPropagation();
              handleEditDraft(record.id);
            }}
          />
          <DeleteOutlined
            className={styles.actionsIcon}
            onClick={(e) => {
              e.stopPropagation();
              setDeleteModalVisible(true);
              setSelectedRecord(record);
            }}
          />
        </div>
      ),
    },
  ];
};
