import React from 'react';

export const BackIcon = ({ fill, width, height, className }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox='0 0 12 18'
      fill={fill}
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M11.9688 2.11581V0.30409C11.9688 0.147059 11.7883 0.06034 11.6664 0.156434L1.10081 8.40878C1.01105 8.47859 0.938407 8.56798 0.88844 8.67014C0.838474 8.77229 0.8125 8.88451 0.8125 8.99823C0.8125 9.11195 0.838474 9.22417 0.88844 9.32632C0.938407 9.42848 1.01105 9.51787 1.10081 9.58768L11.6664 17.84C11.7907 17.9361 11.9688 17.8494 11.9688 17.6924V15.8807C11.9688 15.7658 11.9149 15.6557 11.8258 15.5853L3.38832 8.9994L11.8258 2.41112C11.9149 2.34081 11.9688 2.23065 11.9688 2.11581Z'
        fill={fill}
      />
    </svg>
  );
};
