import * as Yup from "yup";

import {
  CANCELLATION_TYPE_BY_ROLE,
  COMMUNICATION_TYPES, INVALID_ADDRESS_MESSAGE, REASONS_TO_EDIT_RIDE,
  RECURRING_FREQUENCY,
  RIDE_STATUSES,
  VEHICLE_TYPES,
  VEHICLE_TYPES_BARIATRIC_HEIGHT
} from "../constants/constants";

import { MH_PREFERENCES_FIELDS } from "../components/forms/mock";

const spacesRegex = /\s/;
const nonLetterCharsRegex = new RegExp(
  /^[^`~!@#$%^&*()_+={}[\]|\\:;“’<,>.?\d]*$/
);
export const validateNameforSpaces = (name) => {
  if (spacesRegex.test(name)) {
    return false;
  }
  return true;
};

const hoursSchema = Yup.object().shape({
  hours: Yup.number().required("*required"),
  minutes: Yup.number()
    .min(0, "Minimum 0")
    .max(60, "Maximum 60")
    .required("*required"),
});

export const commentSchema = Yup.object().shape({
  comment: Yup.string().trim().required("*required"),
});

export const reasonForUpdateSwitcherSchema = Yup.object().shape({
  updateReason: Yup.string().trim().required("*required"),
});

export const reasonForUpdateSchema = Yup.object().shape({
  reasonForUpdate: Yup.string().trim().required("*required"),
  otherReason: Yup.string()
    .trim()
    .when("reasonForUpdate", {
      is: (value) => value === REASONS_TO_EDIT_RIDE.CUSTOM_REASON,
      then: () => Yup.string().trim().required("*required"),
    }),
});
export const resolveChangeRequestSchema = Yup.object().shape({
  changeReqStatus: Yup.string().trim().required("*required"),
  comment: Yup.string().trim().required("*required")
    
});
export const reasonForUpdateTCSchema = Yup.object().shape({
  reasonForUpdate: Yup.string().trim().required("*required"),
});

export const signInSchema = Yup.object().shape({
  email: Yup.string().email("Incorrect email").required("*required"),
  password: Yup.string()
    .required("*required")
    .min(8, "minimum 8 characters")
    .test(
      "password cannot contain spaces",
      "password cannot contain spaces",
      (value) => validateNameforSpaces(value)
    ),
});

export const driverEndRideSchema = (isEndRide) =>
  Yup.object().shape({
    comment: Yup.string().trim().nullable(),
    actMileage: isEndRide ?
      Yup.number().min(1, "Minimum 1").required("*required")
      : Yup.number().min(1, "Minimum 1").nullable(),
  });

export const TCEndRideSchema = (isEndRide) =>
  Yup.object().shape({
    datetime: Yup.string().required("*required"),
    actMileage: isEndRide ?
      Yup.number().min(1, "Minimum 1").required("*required")
      : Yup.number().min(1, "Minimum 1").nullable(),
  });

export const mhAdminEditRideSchema = (isPickUpDateFlexible) =>
  Yup.object().shape({
    status: Yup.string().trim().required("*required"),
    driver: Yup.string().trim().nullable(),
    transportCompany: Yup.string()
      .trim()
      .when("status", {
        is: (value) => (value === RIDE_STATUSES.COMPLETED || value === RIDE_STATUSES.CANCELED) ,
        then: () => Yup.string().trim().required("*required"),
      }),
    pickUpDate: Yup.object().shape({
      datetime: Yup.string().trim().required("*required"),
      timezone: Yup.string().trim().required("*required").nullable(),
    }),
    pickUpDateLowerLimit: Yup.object()
      .shape({
        datetime: Yup.string().trim().nullable(),
        timezone: Yup.string().trim().nullable(),
      })
      .when("status", {
        is: () => isPickUpDateFlexible,
        then: () => Yup.object().shape({
          datetime: Yup.string().trim().required("*required"),
          timezone: Yup.string().trim().required("*required").nullable(),
        }),
      }),
    tcEstimatedPickUpDate: Yup.object()
      .shape({
        datetime: Yup.string().trim().nullable(),
        timezone: Yup.string().trim().nullable(),
      })
      .when("status", {
        is: (value) => {
          return value === RIDE_STATUSES.COMPLETED && isPickUpDateFlexible;
        },
        then: () => Yup.object().shape({
          datetime: Yup.string().trim().required("*required"),
          timezone: Yup.string().trim().required("*required").nullable(),
        }),
      }),
    startDate: Yup.object()
      .shape({
        datetime: Yup.string().trim().nullable(),
        timezone: Yup.string().trim().nullable(),
      })
      .when("status", {
        is: (value) => value === RIDE_STATUSES.COMPLETED,
        then: () => Yup.object().shape({
          datetime: Yup.string().trim().required("*required"),
          timezone: Yup.string().trim().required("*required").nullable(),
        }),
      }),
    endDate: Yup.object()
      .shape({
        datetime: Yup.string().trim().nullable(),
        timezone: Yup.string().trim().nullable(),
      })
      .when("status", {
        is: (value) => value === RIDE_STATUSES.COMPLETED,
        then: () => Yup.object().shape({
          datetime: Yup.string().trim().required("*required"),
          timezone: Yup.string().trim().required("*required").nullable(),
        }),
      }),

    waitTime: Yup.object().shape({
      hours: Yup.number().min(0, "Minimum 0").nullable(),
      minutes: Yup.number().min(0, "Minimum 0").max(60, "Maximum 60").nullable()
    }),

    loadTime: Yup.number().min(0, "Minimum 0").nullable(),
    unloadTime: Yup.number().min(0, "Minimum 0").nullable(),
     actMileage: Yup.number().nullable(),
    // actMileage: Yup.number().min(1, "Minimum 1").when("status", {
    //   is: (value) => value === RIDE_STATUSES.COMPLETED,
    //   then: () => Yup.number().nullable(),
    //   otherwise: () => Yup.number().nullable()
    // }),
    reason: Yup.string()
      .trim()
      .when("status", {
        is: (value) => value === RIDE_STATUSES.CANCELED,
        then: () => Yup.string().trim().required("*required"),
        otherwise: () => Yup.string().trim().nullable()
      }),
    cancelationType: Yup.string()
      .trim()
      .when("status", {
        is: (value) => value === RIDE_STATUSES.CANCELED,
        then: () => Yup.string().trim().required("*required"),
        otherwise: () => Yup.string().trim().nullable()
      }),
    additionalComments: Yup.string().trim().nullable(),
    isSameDayCancellation: Yup.boolean()
      .when("cancelationType", {
        is: (value) => [CANCELLATION_TYPE_BY_ROLE["cancelByRider"], CANCELLATION_TYPE_BY_ROLE["cancelByHO"]].includes(value),
        then: () => Yup.boolean(),
        otherwise: () => Yup.boolean().nullable()
      }),
    // sameDayCancellation: Yup.object().shape({
    //   fee: Yup.number().when("enabled", {
    //     is: (value) => value === true,
    //     then: () => Yup.number().required(" "),
    //     otherwise: () => Yup.number(),
    //   }),
    //   enabled: Yup.boolean(),
    // }),
    isRiderNoShowCancellation: Yup.boolean().nullable().optional()
  });

export const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string().trim().email("Incorrect email").required("*required"),
});

export const resetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .trim()
    .required("*required")
    .min(8, "minimum 8 characters")
    .test(
      "password cannot contain spaces",
      "password cannot contain spaces",
      (value) => validateNameforSpaces(value)
    ),
});

export const stepOneRideSchema = (showCovidField) => Yup.object().shape({
  covid19Relation: showCovidField
    ? Yup.string()
      .trim()
      .nullable()
      .test(
        "ErrorMessage",
        "In accordance with the guidelines from the CDC and recommendations from the Department of Health, confirmed cases of COVID19 cannot be booked through the MedHaul platform at this time.",
        (value) => value !== "Rider has a confirmed case of COVID-19"
      )
      .required("*required")
    : null,
  riderInfo: Yup.object().shape({
    firstName: Yup.string()
      .trim()
      .matches(nonLetterCharsRegex, {
        message: "Invalid name, only alphabetic characters are allowed",
      })
      .required("*required"),
    lastName: Yup.string()
      .trim()
      .matches(nonLetterCharsRegex, {
        message: "Invalid name, only alphabetic characters are allowed",
      })
      .required("*required"),
    patientUniqueId: Yup.string().nullable(),
    phoneNumber: Yup.string()
      .trim()
      .matches(new RegExp("[0-9]{10}"), { message: "Incorrect phone number" })
      .required("*required"),
    dob: Yup.string().trim().required("*required"),
  }),
  willBeCaregiverOnScene: Yup.boolean(),
  caregivers: Yup.array()
    .nullable()
    .when("willBeCaregiverOnScene", {
      is: true,
      then: () => Yup.array()
        .of(
          Yup.object().shape({
            firstName: Yup.string()
              .trim()
              .matches(nonLetterCharsRegex, {
                message:
                  "Invalid name, only alphabetic characters are allowed",
              })
              .required("*required"),
            lastName: Yup.string()
              .trim()
              .matches(nonLetterCharsRegex, {
                message:
                  "Invalid last name, only alphabetic characters are allowed",
              })
              .required("*required"),
            phoneNumber: Yup.string()
              .matches(new RegExp("[0-9]{10}"), {
                message: "Incorrect phone number",
              })
              .required("*required"),
            email: Yup.string().trim().email("invalid email"),
          })
        )
        .min(1, "*required"),
    }),
});

export const stepTwoRideSchema = Yup.object().shape({
  vehicleType: Yup.string().nullable().trim().required("*required"),
  hasStairs: Yup.boolean().nullable().required("*required"),
  needsCarSeat: Yup.boolean().nullable().required("*required"),
  needsAdditionalCarSeat: Yup.boolean()
    .nullable()
    .when("needsCarSeat", {
      is: true,
      then: () => Yup.boolean().required("*required"),
    }),
  needsBoosterSeat: Yup.boolean()
    .nullable()
    .when("needsCarSeat", {
      is: true,
      then: () => Yup.boolean().required("*required"),
    }),
  dischargeFromFacility: Yup.boolean().nullable().required("*required"),
  isWheelchairNeeded: Yup.boolean()
    .nullable()
    .when("vehicleType", {
      is: (value) =>
        value === VEHICLE_TYPES.WHEEL_CHAIR_BOUND_BARIATRIC ||
        value === VEHICLE_TYPES.WHEEL_CHAIR_BOUND_NON_BARIATRIC,
      then: () => Yup.boolean().required("*required"),
    }),
  hasMotorizedWheelchair: Yup.boolean()
    .nullable()
    .when("vehicleType", {
      is: (value) =>
        value === VEHICLE_TYPES.WHEEL_CHAIR_BOUND_BARIATRIC ||
        value === VEHICLE_TYPES.WHEEL_CHAIR_BOUND_NON_BARIATRIC,
      then: () => Yup.boolean().required("*required"),
    }),
  weight: Yup.number()
    .nullable()
    .when("vehicleType", {
      is: (value) =>
        value === VEHICLE_TYPES.WHEEL_CHAIR_BOUND_BARIATRIC ||
        value === VEHICLE_TYPES.STRETCHER_BOUND_BARIATRIC,
      then: () =>
        Yup.number()
          .min(250, "Minimum 250")
          .max(999, "Maximum 999")
          .required("*required"),
    }),
  height: Yup.number()
    .nullable()
    .when("vehicleType", {
      is: (value) => VEHICLE_TYPES_BARIATRIC_HEIGHT.includes(value),
      then: () => Yup.number().min(5, "Minimum 5").required("*required"),
    }),
});

const buildingNumberSchema = Yup.string()
  .nullable()
  .trim()
  .matches(/^(\S+$)/, "*This field cannot contain spaces only")
  .when("isPublicPlace", {
    is: true,
    then: () => Yup.string().trim().nullable(),
    otherwise: () => Yup.string().trim().nullable(),
  });

export const stepThreeRideSchema = (validateReturnTime) =>
  Yup.object().shape({
    pickupAddressDetails: Yup.object()
      .shape({
        isPublicPlace: Yup.boolean(),
        formattedAddress: Yup.string()
          .trim()
          .required("*Please enter an exact address"),
        route: Yup.string().trim().required("*required"),
        streetNumber: Yup.string().trim().required("*required"),
        political: Yup.string().trim(),
        locality: Yup.string().trim(),
        state: Yup.string().trim().required("*required"),
        county: Yup.string().trim().required("*required"),
        placeId: Yup.string().trim().required("*required"),
        postalCode: Yup.string().required("*required"),
        coordinates: Yup.object().shape({
          lat: Yup.number().required("*required"),
          lng: Yup.number().required("*required"),
        }),
        apartmentNum: buildingNumberSchema,
        buildingNumber: buildingNumberSchema,
      })
      .required("*Please enter an exact address"),

    pickUpSecurityGateDetails: Yup.object()
      .shape({
        securityGateInstruction: Yup.string(),
        hasSecurityGate: Yup.boolean(),
      }).optional(),
    destinationSecurityGateDetail: Yup.object()
      .shape({
        securityGateInstruction: Yup.string(),
        hasSecurityGate: Yup.boolean(),
      }).optional(),
    destinationAddress: Yup.object()
      .shape({
        isPublicPlace: Yup.boolean(),
        formattedAddress: Yup.string()
          .trim()
          .required("*Please enter an exact address"),
        route: Yup.string().trim().required("*required"),
        streetNumber: Yup.string().trim().required("*required"),
        political: Yup.string().trim(),
        locality: Yup.string().trim(),
        state: Yup.string().trim().required("*required"),
        county: Yup.string().trim().required("*required"),
        placeId: Yup.string().trim().required("*required"),
        postalCode: Yup.string().required("*required"),
        coordinates: Yup.object().shape({
          lat: Yup.number().required("*required"),
          lng: Yup.number().required("*required"),
        }),
        apartmentNum: buildingNumberSchema,
        buildingNumber: buildingNumberSchema,
      })
      ?.when("pickupAddressDetails", (pickupAddressDetails, schema) => {
        return schema.test({
          test: (destinationAddress) =>
            !!pickupAddressDetails &&
            destinationAddress?.formattedAddress !==
            pickupAddressDetails?.formattedAddress,
          message:
            "*Destination address should not be equal to pick up address",
        });
      }),

    isRoundtrip: Yup.boolean(),
    isRecurring: Yup.boolean(),
    pickUpDate: Yup.object().shape({
      datetime: Yup.string().trim().required("*required"),
      timezone: Yup.string().trim().required("*required").nullable(),
    }),
    isPickUpDateFlexible: Yup.boolean(),
    isReturnDateFlexible: Yup.boolean(),
    pickUpDateLowerLimit: Yup.object()
      .nullable()
      .when("isPickUpDateFlexible", {
        is: true,
        then: () => Yup.object({
          datetime: Yup.string().trim().required("*required"),
          timezone: Yup.string().trim().required("*required").nullable(),
        }),
      }),
    returnTimeLowerLimit: Yup.object()
      .nullable()
      .when("isReturnDateFlexible", {
        is: true,
        then: () => Yup.object({
          datetime: Yup.string().trim().required("*required"),
          timezone: Yup.string().trim().required("*required").nullable(),
        }),
      }),
    returnTime: Yup.object()
      .nullable()
      .when("isRoundtrip", {
        is: (value) => {
          return value && validateReturnTime;
        },
        then: () => Yup.object({
          datetime: Yup.string().trim().required("*required"),
          timezone: Yup.string().trim().required("*required").nullable(),
        }),
      }),

    recurrenceRule: Yup.object().when("isRecurring", {
      is: true,
      then: () => Yup.object({
        frequency: Yup.string().trim().required("*required"),
        interval: Yup.number()
          .min(1, "*minimum value is 1")
          .max(10, "*maximum value is 10")
          .required("*required"),
        count: Yup.string().trim().required("*required"),
        byDays: Yup.array().when("frequency", {
          is: (value) => value === RECURRING_FREQUENCY.WEEKLY,
          then: () => Yup.array()
            .min(1)
            .test("*required", "*required", (v, s) => {
              return v?.length <= Number(s.parent.count);
            })
            .required("*required"),
        }),
        monthlyType: Yup.string().when("frequency", {
          is: (value) => value === RECURRING_FREQUENCY.MONTHLY,
          then: () => Yup.string().required("*required"),
        }),
        startPoint: Yup.object().shape({
          datetime: Yup.string().trim().required("*required"),
          timezone: Yup.string().trim().required("*required"),
        }),
        returnStartPoint: Yup.object()
          .nullable()
          .shape({
            datetime: Yup.string()
              .trim()
              .test("*required", "*required", (value, schema) => {
                if (schema?.from[2]?.value?.isRoundtrip && !value) return false;
                else return true;
              }),
            timezone: Yup.string()
              .trim()
              .test("*required", "*required", (value, schema) => {
                if (schema?.from[2]?.value?.isRoundtrip && !value) return false;
                else return true;
              }),
          }),
      }),
    }),
    rideNotes: Yup.string().optional(),
    flightInfo: Yup.string().optional(),

  });


  export const reschduleRideSchema = (validateReturnTime) =>
    Yup.object().shape({
    
     
      pickUpDate: Yup.object().shape({
        datetime: Yup.string().trim().required("*required"),
        timezone: Yup.string().trim().required("*required").nullable(),
      }),
     
      returnTime: Yup.object()
        .nullable()
        .when("isRoundtrip", {
          is: (value) => {
            return value && validateReturnTime;
          },
          then: () => Yup.object({
            datetime: Yup.string().trim().required("*required"),
            timezone: Yup.string().trim().required("*required").nullable(),
          }),
        }),
  
     
    
  
    });

export const stepFourRideSchema = ({
  costCenterField,
  showAdditionalSourceField,
  showDocumentReasonForRideField,
}) => {
  return Yup.object().shape({
    documentReasonForRide: showDocumentReasonForRideField?.isRequired
      ? Yup.array().min(1).required("*required")
      : Yup.array(),
    otherReason: Yup.string()
      .nullable()
      .trim()
      .when("documentReasonForRide", {
        is: (values) => values && values.includes("Other"),
        then: () => Yup.string().trim().required("*required"),
      }),
    costCenter: costCenterField?.isRequired
      ? Yup.string().trim().required("*required")
      : "",
    additionalFundingSource: showAdditionalSourceField?.isRequired
      ? Yup.string().trim().required("*required")
      : "",
  });
};

const locationSchema = Yup.object()
  .shape({
    formattedAddress: Yup.string()
      .trim()
      .required("*Please enter an exact address"),
    route: Yup.string().trim().required("*required"),
    streetNumber: Yup.string().trim().required("*required"),
    political: Yup.string().trim(),
    locality: Yup.string().trim(),
    state: Yup.string().trim().required("*required"),
    county: Yup.string().trim().required("*required"),
    placeId: Yup.string().trim().required("*required"),
    postalCode: Yup.string().required("*required"),
    coordinates: Yup.object().shape({
      lat: Yup.number().required("*required"),
      lng: Yup.number().required("*required"),
    }),
  })
  .required("*required");

export const updateOrganizationSchema = Yup.object().shape({
  name: Yup.string().trim().required("*required"),
  location: locationSchema,
  rideRequestFields: Yup.object().shape({
    additionalFundingSource: Yup.string(),
    costCenter: Yup.string(),
    covid19Relation: Yup.string(),
    documentReasonForRide: Yup.string(),
  }),
  //Todo: Previous vandor unfinshed code will be un-commented once we are cleared about requirement
  // scheduledRidesReminderDay: Yup.string().trim().required('*required'),
  // scheduledRidesReminderHour: Yup.number().nullable().required('*required'),
});

export const createHealthOrganizationSchema = Yup.object().shape({
  name: Yup.string().trim().required("*required"),
  location: locationSchema,
  admin: Yup.object().shape({
    email: Yup.string().trim().email("invalid email").required("*required"),
    firstName: Yup.string()
      .trim()
      .matches(nonLetterCharsRegex, {
        message: "Invalid name, only alphabetic characters are allowed",
      })
      .required("*required"),
    lastName: Yup.string()
      .trim()
      .matches(nonLetterCharsRegex, {
        message: "Invalid name, only alphabetic characters are allowed",
      })
      .required("*required"),
  }),
});

export const createTransportCompanySchema = Yup.object().shape({
  name: Yup.string().trim().required("*required"),
  admin: Yup.object().shape({
    email: Yup.string().trim().email("invalid email").required("*required"),
    firstName: Yup.string()
      .trim()
      .matches(nonLetterCharsRegex, {
        message: "Invalid name, only alphabetic characters are allowed",
      })
      .required("*required"),
    lastName: Yup.string()
      .trim()
      .matches(nonLetterCharsRegex, {
        message: "Invalid name, only alphabetic characters are allowed",
      })
      .required("*required"),
  }),
  location: locationSchema,
  preferredTimezone: Yup.string().trim().required("*required"),
  scheduledRidesReminderDay: Yup.string().trim().required("*required"),
  scheduledRidesReminderHour: Yup.number().nullable().required("*required"),
});

export const updatePasswordSchema = Yup.object().shape({
  newPassword: Yup.string()
    .trim()
    .required("*required")
    .min(8, "minimum 8 characters"),
  // .max(20, 'a maximum 20 characters '),
  oldPassword: Yup.string()
    .trim()
    .required("*required")
    .min(8, "minimum 8 characters"),
  // .max(20, 'a maximum 20 characters '),
});

export const changeEmailSchema = (initialValue) =>
  Yup.object().shape({
    newEmail: Yup.string().trim().email("invalid email").required("*required"),
    password: Yup.string()
      .trim()
      .when("newEmail", {
        is: (value) => value !== initialValue,
        then: () => Yup.string()
          .trim()
          .required("*required")
          .min(8, "minimum 8 characters"),
        // .max(20, 'a maximum 20 characters '),
      }),
  });

export const createUserSchema = Yup.object().shape({
  email: Yup.string().trim().email("invalid email").required("*required"),
  firstName: Yup.string()
    .trim()
    .matches(nonLetterCharsRegex, {
      message: "Invalid name, only alphabetic characters are allowed",
    })
    .required("*required"),
  lastName: Yup.string()
    .trim()
    .matches(nonLetterCharsRegex, {
      message: "Invalid name, only alphabetic characters are allowed",
    })
    .required("*required"),
});


export const updateUserSchema = createUserSchema.shape({
  phoneNumber: Yup.string()
    .trim()
    .matches(new RegExp("[0-9]{10}"), { message: "Incorrect phone number" })
    .required("*required"),
  jobTitle: Yup.string().trim().required("*required"),
});

export const PersonalInformationStepSchema = Yup.object().shape({
  phoneNumber: Yup.string()
    .trim()
    .matches(new RegExp("[0-9]{10}"), { message: "Incorrect phone number" })
    .required("*required"),
  jobTitle: Yup.string().trim().required("*required"),
});

export const createDriverSchema = Yup.object().shape({
  email: Yup.string()
    .nullable()
    .trim()
    .email("invalid email")
    .required("*required"),
  firstName: Yup.string()
    .trim()
    .matches(nonLetterCharsRegex, {
      message: "Invalid name, only alphabetic characters are allowed",
    })
    .required("*required"),
  lastName: Yup.string()
    .trim()
    .matches(nonLetterCharsRegex, {
      message: "Invalid name, only alphabetic characters are allowed",
    })
    .required("*required"),
  phoneNumber: Yup.string()
    .trim()
    .matches(new RegExp("[0-9]{10}"), { message: "Incorrect phone number" })
    .required("*required"),
  jobTitle: Yup.string().trim().required("*required"),
  vehicleTypes: Yup.array().min(1).required("*required"),
  hasDriver: Yup.boolean(),
});
export const driverWaitTimeSchema = Yup.object().shape({
  waitTime: hoursSchema,
});

export const personalInformationSchema = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .matches(nonLetterCharsRegex, {
      message: "Invalid name, only alphabetic characters are allowed",
    })
    .required("*required"),
  lastName: Yup.string()
    .trim()
    .matches(nonLetterCharsRegex, {
      message: "Invalid name, only alphabetic characters are allowed",
    })
    .required("*required"),
  phoneNumber: Yup.string()
    .matches(new RegExp("[0-9]{10}"), { message: "Incorrect phone number" })
    .required("*required"),
  jobTitle: Yup.string().trim().required("*required"),
});

export const driverCancelRideSchema = Yup.object().shape({
  reason: Yup.string().trim().required("*required")

});

export const commonCancelRideSchema = Yup.object().shape({
  cancelBy: Yup.string().trim().required("*required"),
  reason: Yup.string().trim().required("*required"),
  additionalComments: Yup.string().trim().required("*required"),
  applySameDayCancellationCharge:Yup.boolean().optional(),
  applyRiderNoShowCharge:Yup.boolean().optional()
});

export const roundTripCancelRideSchema = Yup.object().shape({
  reason: Yup.string().trim().required("*required"),
  applySameDayCancellationCharge:Yup.boolean().optional(),
  applyRiderNoShowCharge:Yup.boolean().optional()
});

export const assignTcByMHSchema = Yup.object().shape({
  transportCompanyId: Yup.string().trim().required("*required"),
  tcDriverId: Yup.string().trim().required("*required")
});

export const editRecurringSchema = Yup.object().shape({
  editSeriesOption: Yup.string().trim().required("*required"),
});

export const createRideSchema = ({
  optionalStepRules,
  covidFieldIsRequired,
}) => {
  return Yup.object().shape({
    step1: stepOneRideSchema(covidFieldIsRequired),
    step2: stepTwoRideSchema,
    step3: stepThreeRideSchema(true),
    step4: stepFourRideSchema({ ...optionalStepRules }),
  });
};

export const createRiderSchema = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .matches(nonLetterCharsRegex, { message: "Incorrect first name" })
    .required("*required"),
  lastName: Yup.string()
    .trim()
    .matches(nonLetterCharsRegex, { message: "Incorrect last name" })
    .required("*required"),
  dob: Yup.string().trim().required("*required"),
  communicationPreferences: Yup.array().nullable(),
  patientUniqueId: Yup.string().nullable(),
  phoneNumber: Yup.string()
    .trim()
    .matches(new RegExp("[0-9]{10}"), { message: "Incorrect phone number" })
    .required("*required"),
  email: Yup.string()
    .email("Incorrect email")
    .trim()
    .nullable()
    .when("communicationPreferences", {
      is: (value) => {
        return value.includes(COMMUNICATION_TYPES.EMAIL);
      },
      then: () => Yup.string().email("Incorrect email").trim().required("*required"),
    }),
  preferredLanguage: Yup.string().trim().nullable(),
  homeAddress: Yup.object().shape({
    isPublicPlace: Yup.boolean(),
      formattedAddress: Yup.string()
        .trim()
        .when('homeAddress', {
          is: (value) => !!value, // Only required if formattedAddress is not empty
          then: Yup.string().required("*Please enter an exact address")
        }),
      route: Yup.string()
        .trim()
        .when('homeAddress', {
          is: (value) => !!value, // Only required if formattedAddress is not empty
          then: Yup.string().required("*required")
        }),
      streetNumber: Yup.string()
        .trim()
        .when('homeAddress', {
          is: (value) => !!value, // Only required if formattedAddress is not empty
          then: Yup.string().required("*required")
        }),
      political: Yup.string().trim(),
      locality: Yup.string().trim(),
      state: Yup.string()
        .trim()
        .when('homeAddress', {
          is: (value) => !!value, // Only required if formattedAddress is not empty
          then: Yup.string().required("*required")
        }),
      county: Yup.string()
        .trim()
        .when('homeAddress', {
          is: (value) => !!value, // Only required if formattedAddress is not empty
          then: Yup.string().required("*required")
        }),
      placeId: Yup.string()
        .trim()
        .when('homeAddress', {
          is: (value) => !!value, // Only required if formattedAddress is not empty
          then: Yup.string().required("*required")
        }),
      postalCode: Yup.string()
        .when('homeAddress', {
          is: (value) => !!value, // Only required if formattedAddress is not empty
          then: Yup.string().required("*required")
        }),
      coordinates: Yup.object().shape({
        lat: Yup.number()
          .when('homeAddress', {
            is: (value) => !!value, // Only required if formattedAddress is not empty
            then: Yup.number().required("*required")
          }),
        lng: Yup.number()
          .when('homeAddress', {
            is: (value) => !!value, // Only required if formattedAddress is not empty
            then: Yup.number().required("*required")
          }),
      }),
      apartmentNum: buildingNumberSchema,
      buildingNumber: buildingNumberSchema,
    })
    .nullable()
    .test("homeAddress", INVALID_ADDRESS_MESSAGE, function (value) {
      // Only run location validation if formattedAddress is provided
      if (value && value.formattedAddress) {
        return locationSchema.isValidSync(value);
      }
      return true; 
    }),
  weight: Yup.number().min(0, "Minimum 0").max(999, "Maximum 999").nullable(),
  height: Yup.number().nullable(),
});

export const platformSettingsSchema = Yup.object().shape({
  [MH_PREFERENCES_FIELDS.rideExpirationTime]: Yup.object().shape({
    onDemand: hoursSchema,
    scheduled: hoursSchema,
    driverInactivity: hoursSchema,
    acceptedWithoutDriver: hoursSchema,
    rideNotStartedAlert: hoursSchema,
  }),
  [MH_PREFERENCES_FIELDS.minTimeFromRideRequestToPickup]: Yup.object().shape({
    [VEHICLE_TYPES.STRETCHER_BOUND_BARIATRIC]: hoursSchema,
    [VEHICLE_TYPES.STRETCHER_BOUND_NON_BARIATRIC]: hoursSchema,
    [VEHICLE_TYPES.AMBULATORY_ASSIST_RIDER_IS_WALKING]: hoursSchema,
    [VEHICLE_TYPES.AMBULATORY_RIDER_IS_WALKING]: hoursSchema,
    [VEHICLE_TYPES.WHEEL_CHAIR_BOUND_BARIATRIC]: hoursSchema,
    [VEHICLE_TYPES.WHEEL_CHAIR_BOUND_NON_BARIATRIC]: hoursSchema,
  }),
  [MH_PREFERENCES_FIELDS.notifications]: Yup.object().shape({
    firstReminderToRider: hoursSchema,
    secondReminderToRider: hoursSchema,
  }),
  [MH_PREFERENCES_FIELDS.carBoosterSeatBufferTime]: hoursSchema,
  [MH_PREFERENCES_FIELDS.supportContacts]: Yup.object().shape({
    email: Yup.array()
      .transform(function (value, originalValue) {
        if (this.isType(value) && value !== null) {
          return value;
        }
        return originalValue ? originalValue.split(/[\s,]+/) : [];
      })
      .of(Yup.string().email(({ value }) => `${value} is not a valid email`))
      .required("*required"),
    phoneNumber: Yup.string()
      .trim()
      .matches(new RegExp("[0-9]{10}"), { message: "Incorrect phone number" })
      .required("*required"),
  }),
  [MH_PREFERENCES_FIELDS.notificationsSwitcher]: Yup.object().shape({
    emails: Yup.boolean().nullable().required("*required"),
    sms: Yup.boolean().nullable().required("*required"),
    pushNotifications: Yup.boolean().nullable().required("*required"),
  }),
});

export const rideLoadTimeSchema = Yup.object().shape({
  loadTime: Yup.number().min(0, "Minimum 0"),
  unloadTime: Yup.number().min(0, "Minimum 0"),
});

export const rideReceiptChangeRequest = Yup.object().shape({
  rideStatus: Yup.string().nonNullable().required("required"),
  waitTime: Yup.string().nullable(), 
  actMileage : Yup.number().when("rideStatus", {
    is: (value) => value === RIDE_STATUSES.COMPLETED,
    then: () =>  Yup.number().required(" "),
    otherwise: () => Yup.number().nullable(),
  }),
  cancellationReason: Yup.string().when("rideStatus", {
    is: (value) => value !== RIDE_STATUSES.COMPLETED,
    then: () =>
      Yup.string().required(" "),
    otherwise: () => Yup.string().nullable(),
  }),
  additionalComments: Yup.string().nullable(),
  comments:Yup.string().nullable(), 
});
export const billingSubscriptionSchema = Yup.object().shape({
  pricingType: Yup.string().nullable(),
  pricingStatus: Yup.string().nullable(),
  subscriptionFee: Yup.number().min(0, ' ').required(" "),
  ridesPerMonth: Yup.number().min(0, ' ').required(' '),

  flatRate: Yup.object().shape({
    ambulatory: Yup.object().shape({
      miles0_10: Yup.number().test(
        'is-decimal',
        'The number should have maximum two decimal points',
        (value) => {
          if (value !== undefined) {
            return /^-?\d+(\.\d{1,2})?$/.test(value?.toString());
          }
          return true;
        }).required(" "),
      miles10_25: Yup.number().test(
        'is-decimal',
        'The number should have maximum two decimal points',
        (value) => {
          if (value !== undefined) {
            return /^-?\d+(\.\d{1,2})?$/.test(value?.toString());
          }
          return true;
        }).required(" "),
    }),
    stretcher: Yup.object().shape({
      miles0_10: Yup.number().test(
        'is-decimal',
        'The number should have maximum two decimal points',
        (value) => {
          if (value !== undefined) {
            return /^-?\d+(\.\d{1,2})?$/.test(value?.toString());
          }
          return true;
        }).required(" "),
      miles10_25: Yup.number().test(
        'is-decimal',
        'The number should have maximum two decimal points',
        (value) => {
          if (value !== undefined) {
            return /^-?\d+(\.\d{1,2})?$/.test(value?.toString());
          }
          return true;
        }).required(" "),
    }),
    wheelchair: Yup.object().shape({
      miles0_10: Yup.number().required(" "),
      miles10_25: Yup.number().required(" "),
    }),
  }),

  additionalRate: Yup.object().shape({
    milesSurcharge25_90: Yup.object().shape({
      enabled: Yup.boolean(),
      fee: Yup.number().when("enabled", {
        is: (value) => value === true,
        then: () =>
          Yup.number().required(" "),
        otherwise: () => Yup.number(),
      }),
    }),

    milesSurcharge90Plus: Yup.object().shape({
      fee: Yup.number().when("enabled", {
        is: (value) => value === true,
        then: () =>
          Yup.number().required(" "),
        otherwise: () => Yup.number(),
      }),
    }),
  }),

  holiday: Yup.object().shape({
    fee: Yup.number().when("enabled", {
      is: (value) => value === true,
      then: () => Yup.number().required(" "),
      otherwise: () => Yup.number(),
    }),
    enabled: Yup.boolean(),
  }),

  inclementWeather: Yup.object().shape({
    fee: Yup.number().when("enabled", {
      is: (value) => value === true,
      then: () => Yup.number().required(" "),
      otherwise: () => Yup.number(),
    }),
    enabled: Yup.boolean(),
  }),

  overnightRide: Yup.object().shape({
    fee: Yup.number().when("enabled", {
      is: (value) => value === true,
      then: () => Yup.number().required(" "),
      otherwise: () => Yup.number(),
    }),
    enabled: Yup.boolean(),
  }),

  rideNoShow: Yup.object().shape({
    fee: Yup.number().when("enabled", {
      is: (value) => value === true,
      then: () => Yup.number().required(" "),
      otherwise: () => Yup.number(),
    }),
    enabled: Yup.boolean(),
  }),

  sameDayCancellation: Yup.object().shape({
    fee: Yup.number().when("enabled", {
      is: (value) => value === true,
      then: () => Yup.number().required(" "),
      otherwise: () => Yup.number(),
    }),
    enabled: Yup.boolean(),
  }),

  waitTime: Yup.object().shape({
    fee: Yup.number().when("enabled", {
      is: (value) => value === true,
      then: () => Yup.number().required(" "),
      otherwise: () => Yup.number(),
    }),
    enabled: Yup.boolean(),
  }),
});

export const billingTransactionSchema = Yup.object().shape({
  pricingType: Yup.string(),
  pricingStatus: Yup.string(),

  flatRate: Yup.object().shape({
    ambulatory: Yup.object().shape({
      miles0_25: Yup.number().test(
        'is-decimal',
        'The number should have maximum two decimal points',
        (value) => {
          if (value !== undefined) {
            return /^-?\d+(\.\d{1,2})?$/.test(value?.toString());
          }
          return true;
        }).required(" "),
    }),
    stretcher: Yup.object().shape({
      miles0_25: Yup.number().required(" "),
    }),
    wheelchair: Yup.object().shape({
      miles0_25: Yup.number().test(
        'is-decimal',
        'The number should have maximum two decimal points',
        (value) => {
          if (value !== undefined) {
            return /^-?\d+(\.\d{1,2})?$/.test(value?.toString());
          }
          return true;
        }).required(" "),
    }),
  }),

  additionalRate: Yup.object().shape({
    milesSurcharge25_90: Yup.object().shape({
      fee: Yup.number().when("enabled", {
        is: (value) => value === true,
        then: () =>
          Yup.number()
            .min(0)
            .required(" "),
        otherwise: () => Yup.number(),
      }),
      enabled: Yup.boolean(),
    }),

    milesSurcharge90Plus: Yup.object().shape({
      fee: Yup.number().when("enabled", {
        is: (value) => value === true,
        then: () =>
          Yup.number()
            .min(0)
            .required(" "),
        otherwise: () => Yup.number(),
      }),
      enabled: Yup.boolean(),
    }),
  }),

  holiday: Yup.object().shape({
    fee: Yup.number().when("enabled", {
      is: (value) => value === true,
      then: () =>
        Yup.number()
          .min(0)
          .required(" "),
      otherwise: () => Yup.number(),
    }),
    enabled: Yup.boolean(),
  }),

  inclementWeather: Yup.object().shape({
    fee: Yup.number().when("enabled", {
      is: (value) => value === true,
      then: () =>
        Yup.number()
          .min(0)
          .required(" "),
      otherwise: () => Yup.number(),
    }),
    enabled: Yup.boolean(),
  }),

  overnightRide: Yup.object().shape({
    fee: Yup.number().test(
      'is-decimal',
      'The number should have maximum two decimal points',
      (value) => {
        if (value !== undefined) {
          return /^-?\d+(\.\d{1,2})?$/.test(value?.toString());
        }
        return true;
      }).when("enabled", {
        is: (value) => value === true,
        then: () =>
          Yup.number()
            .min(0)
            .required(" "),
        otherwise: () => Yup.number(),
      }),
    enabled: Yup.boolean(),
  }),

  rideNoShow: Yup.object().shape({
    fee: Yup.number().when("enabled", {
      is: (value) => value === true,
      then: () =>
        Yup.number()
          .min(0).test(
            'is-decimal',
            'The number should have maximum two decimal points',
            (value) => {
              if (value !== undefined) {
                return /^-?\d+(\.\d{1,2})?$/.test(value?.toString());
              }
              return true;
            })
          .required(" "),
      otherwise: () => Yup.number(),
    }),
    enabled: Yup.boolean(),
  }),

  sameDayCancellation: Yup.object().shape({
    fee: Yup.number().when("enabled", {
      is: (value) => value === true,
      then: () =>
        Yup.number()
          .min(0).test(
            'is-decimal',
            'The number should have maximum two decimal points',
            (value) => {
              if (value !== undefined) {
                return /^-?\d+(\.\d{1,2})?$/.test(value?.toString());
              }
              return true;
            })
          .required(" "),
      otherwise: () => Yup.number(),
    }),
    enabled: Yup.boolean(),
  }),

  waitTime: Yup.object().shape({
    fee: Yup.number().when("enabled", {
      is: (value) => value === true,
      then: () =>
        Yup.number()
          .min(0).test(
            'is-decimal',
            'The number should have maximum two decimal points',
            (value) => {
              if (value !== undefined) {
                return /^-?\d+(\.\d{1,2})?$/.test(value?.toString());
              }
              return true;
            })
          .required(" "),
      otherwise: () => Yup.number(),
    }),
    enabled: Yup.boolean(),
  }),
});

export const billing_sjcrhSchema = Yup.object().shape({
  pricingType: Yup.string(),
  pricingStatus: Yup.string(),
  subscriptionFee: Yup.number().nullable(),
  ridesPerMonth: Yup.number().nullable(),
  flatRate: Yup.object().shape({
    isolation: Yup.object().shape({
      miles0_3: Yup.number().test(
        'is-decimal',
        'The number should have maximum two decimal points',
        (value) => {
          if (value !== undefined) {
            return /^-?\d+(\.\d{1,2})?$/.test(value?.toString());
          }
          return true;
        }).required(' '),
      moreThen3Miles: Yup.number().test(
        'is-decimal',
        'The number should have maximum two decimal points',
        (value) => {
          if (value !== undefined) {
            return /^-?\d+(\.\d{1,2})?$/.test(value?.toString());
          }
          return true;
        }).required(' '),
    }),
    nonIsolation: Yup.object().shape({
      miles0_3: Yup.number().test(
        'is-decimal',
        'The number should have maximum two decimal points',
        (value) => {
          if (value !== undefined) {
            return /^-?\d+(\.\d{1,2})?$/.test(value?.toString());
          }
          return true;
        }).required(' '),
      moreThen3Miles: Yup.number().test(
        'is-decimal',
        'The number should have maximum two decimal points',
        (value) => {
          if (value !== undefined) {
            return /^-?\d+(\.\d{1,2})?$/.test(value?.toString());
          }
          return true;
        }).required(' '),
    }),
  }),

  additionalRate: Yup.object().shape({
    milesSurcharge35_90: Yup.object().shape({
      enabled: Yup.boolean(),
      fee: Yup.number().when("enabled", {
        is: (value) => value === true,
        then: () =>
          Yup.number().test(
            'is-decimal',
            'The number should have maximum two decimal points',
            (value) => {
              if (value !== undefined) {
                return /^-?\d+(\.\d{1,2})?$/.test(value?.toString());
              }
              return true;
            }).required(" "),
        otherwise: () => Yup.number(),
      }),
    }),

    milesSurcharge90Plus: Yup.object().shape({
      enabled:
        Yup.boolean()
          .nullable(),
      fee: Yup.number().when("enabled", {
        is: (value) => value === true,
        then: () =>
          Yup.number().test(
            'is-decimal',
            'The number should have maximum two decimal points',
            (value) => {
              if (value !== undefined) {
                return /^-?\d+(\.\d{1,2})?$/.test(value?.toString());
              }
              return true;
            }).required(" "),
        otherwise: () => Yup.number(),
      }),
    }),
  }),

  holiday: Yup.object().shape({
    fee: Yup.number().when("enabled", {
      is: (value) => value === true,
      then: () => Yup.number().required(" "),
      otherwise: () => Yup.number(),
    }),
    enabled: Yup.boolean(),
  }),
  inclementWeather: Yup.object().shape({
    fee: Yup.number().when("enabled", {
      is: (value) => value === true,
      then: () => Yup.number().required(" "),
      otherwise: () => Yup.number(),
    }),
    enabled: Yup.boolean(),
  }),
  overnightRide: Yup.object().shape({
    fee: Yup.number().when("enabled", {
      is: (value) => value === true,
      then: () => Yup.number().test(
        'is-decimal',
        'The number should have maximum two decimal points',
        (value) => {
          if (value !== undefined) {
            return /^-?\d+(\.\d{1,2})?$/.test(value?.toString());
          }
          return true;
        }).required(" "),
      otherwise: () => Yup.number(),
    }),
    enabled: Yup.boolean(),
  }),
  rideNoShow: Yup.object().shape({
    fee: Yup.number().when("enabled", {
      is: (value) => value === true,
      then: () => Yup.number().test(
        'is-decimal',
        'The number should have maximum two decimal points',
        (value) => {
          if (value !== undefined) {
            return /^-?\d+(\.\d{1,2})?$/.test(value?.toString());
          }
          return true;
        }).required(" "),
      otherwise: () => Yup.number(),
    }),
    enabled: Yup.boolean(),
  }),
  sameDayCancellation: Yup.object().shape({
    fee: Yup.number().when("enabled", {
      is: (value) => value === true,
      then: () => Yup.number().test(
        'is-decimal',
        'The number should have maximum two decimal points',
        (value) => {
          if (value !== undefined) {
            return /^-?\d+(\.\d{1,2})?$/.test(value?.toString());
          }
          return true;
        }).required(" "),
      otherwise: () => Yup.number(),
    }),
    enabled: Yup.boolean(),
  }),
  waitTime: Yup.object().shape({
    fee: Yup.number().when("enabled", {
      is: (value) => value === true,
      then: () => Yup.number().required(" "),
      otherwise: () => Yup.number(),
    }),
    enabled: Yup.boolean(),
  }),
});


export const tc_Billing_Transaction_Schema = Yup.object().shape({
  pricingStatus: Yup.string(),
  flatRate: Yup.object().shape({
    ambulatory: Yup.object().shape({
      fee: Yup.number().required(" "),
    }),
    wheelchair: Yup.object().shape({
      fee: Yup.number().required(" "),
    }),
    stretcher: Yup.object().shape({
      fee: Yup.number().required(" "),
    }),
  }),

  additionalRate: Yup.object().shape({
    ambulatory: Yup.object().shape({
      enabled: Yup.boolean(),
      fee: Yup.number().when("enabled", {
        is: (value) => value === true,
        then: () => Yup.number().required(" "),
        otherwise: () => Yup.number(),
      }),
    }),
    
    stretcher: Yup.object().shape({
      enabled: Yup.boolean().nullable(),
      fee: Yup.number().when("enabled", {
        is: (value) => value === true,
        then: () => Yup.number().required(" "),
        otherwise: () => Yup.number(),
      }),
    }),
    wheelchair: Yup.object().shape({
      enabled: Yup.boolean().nullable(),
      fee: Yup.number().when("enabled", {
        is: (value) => value === true,
        then: () => Yup.number().required(" "),
        otherwise: () => Yup.number(),
      }),
    }),
  }),
  deadheadMileage: Yup.object().shape({
    enabled: Yup.boolean().nullable(),
    fee: Yup.number().when("enabled", {
      is: (value) => value === true,
      then: () => Yup.number().required(" "),
      otherwise: () => Yup.number().notRequired(),
    }),
  })
  .notRequired(),
  rideNoShow: Yup.object().shape({
    fee: Yup.number().when("enabled", {
      is: (value) => value === true,
      then: () => Yup.number().required(" "),
      otherwise: () => Yup.number(),
    }),
    enabled: Yup.boolean(),
  }),
  sameDayCancellation: Yup.object().shape({
    fee: Yup.number().when("enabled", {
      is: (value) => value === true,
      then: () => Yup.number().required(" "),
      otherwise: () => Yup.number(),
    }),
    enabled: Yup.boolean(),
  }),
  waitTime: Yup.object().shape({
    fee: Yup.number().when("enabled", {
      is: (value) => value === true,
      then: () => Yup.number().required(" "),
      otherwise: () => Yup.number(),
    }),
    enabled: Yup.boolean(),
  }),
});