import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { checkForMatchedCompanies, resetHasMatchedTCInfo } from '../store/rides';
import { LodashHelper } from '../utils';
import { usePrevious } from './usePrevious';

export const useCheckForMatchedTc = (currentValues, allowCheck) => {
  const dispatch = useDispatch();

  const [mounted, setMounted] = useState(false);
  const previousValues = usePrevious(currentValues);

  const isPickUpAddressValid = useMemo(
    () =>
      currentValues?.pickupAddressDetails?.coordinates &&
      currentValues?.pickupAddressDetails?.route &&
      currentValues?.pickupAddressDetails?.streetNumber &&
      currentValues?.pickupAddressDetails?.state &&
      currentValues?.pickupAddressDetails?.county &&
      currentValues?.pickupAddressDetails?.placeId &&
      currentValues?.pickupAddressDetails?.postalCode,
    [currentValues?.pickupAddressDetails],
  );
  const isDestinationAddressValid = useMemo(
    () =>
      currentValues?.destinationAddress?.coordinates &&
      currentValues?.destinationAddress?.route &&
      currentValues?.destinationAddress?.streetNumber &&
      currentValues?.destinationAddress?.state &&
      currentValues?.destinationAddress?.county &&
      currentValues?.destinationAddress?.placeId &&
      currentValues?.destinationAddress?.postalCode,
    [currentValues?.destinationAddress],
  );
  
  useEffect(() => {
    if (
      allowCheck &&
      !mounted &&
      currentValues &&
      currentValues?.vehicleType &&
      isPickUpAddressValid &&
      isDestinationAddressValid
    ) {

      dispatch(
        checkForMatchedCompanies({
          vehicleType: currentValues?.vehicleType,
          rideOptions: currentValues?.rideOptions,
          pickupAddressDetails: currentValues?.pickupAddressDetails,
          destinationAddress: currentValues?.destinationAddress,
          supportCarBoosterSeat: currentValues?.pickupPointAdditionalInfo?.needsCarSeat,
        }),
      );
      setMounted(true);
    }
  }, [allowCheck, isPickUpAddressValid, isDestinationAddressValid, currentValues?.vehicleType,  mounted]);

  useEffect(() => {
    if (
      allowCheck &&
      mounted &&
      previousValues?.vehicleType &&
      currentValues?.vehicleType &&
      !LodashHelper.isEmpty(LodashHelper.removeEmpty(previousValues)) &&
      previousValues?.vehicleType !== currentValues?.vehicleType
    ) {
      
      dispatch(
        checkForMatchedCompanies({
          vehicleType: currentValues?.vehicleType,
          rideOptions: currentValues?.rideOptions,
          pickupAddressDetails: currentValues?.pickupAddressDetails,
          destinationAddress: currentValues?.destinationAddress,
          supportCarBoosterSeat: currentValues?.pickupPointAdditionalInfo?.needsCarSeat,
        }),
      );
    }
  }, [allowCheck, mounted,   previousValues, currentValues?.vehicleType]);

  useEffect(() => {
    if (
      allowCheck &&
      mounted &&
      previousValues?.pickupAddressDetails?.formattedAddress &&
      isPickUpAddressValid &&
      isDestinationAddressValid &&
      !LodashHelper.isEqual(
        previousValues?.pickupAddressDetails?.coordinates,
        currentValues?.pickupAddressDetails?.coordinates,
      )
    ) {
      
      dispatch(
        checkForMatchedCompanies({
          vehicleType: currentValues?.vehicleType,
          rideOptions: currentValues?.rideOptions,
          pickupAddressDetails: currentValues?.pickupAddressDetails,
          destinationAddress: currentValues?.destinationAddress,
          supportCarBoosterSeat: currentValues?.pickupPointAdditionalInfo?.needsCarSeat,
        }),
      );
    }
  }, [allowCheck,  mounted, isPickUpAddressValid, isDestinationAddressValid, previousValues, currentValues?.pickupAddressDetails?.coordinates]);

  useEffect(() => {
    if (
      allowCheck &&
      mounted &&
      previousValues?.destinationAddress?.formattedAddress &&
      isPickUpAddressValid &&
      isDestinationAddressValid &&
      !LodashHelper.isEqual(
        previousValues?.destinationAddress?.coordinates,
        currentValues?.destinationAddress?.coordinates,
      )
    ) {
      
      dispatch(
        checkForMatchedCompanies({
          vehicleType: currentValues?.vehicleType,
          rideOptions: currentValues?.rideOptions,
          pickupAddressDetails: currentValues?.pickupAddressDetails,
          destinationAddress: currentValues?.destinationAddress,
          supportCarBoosterSeat: currentValues?.pickupPointAdditionalInfo?.needsCarSeat,
        }),
      );
    }
  }, [allowCheck, dispatch, isPickUpAddressValid, isDestinationAddressValid, previousValues, currentValues?.destinationAddress?.coordinates, mounted]);

  useEffect(() => {
    if (
      allowCheck &&
      mounted &&
      previousValues?.vehicleType &&
      currentValues?.vehicleType &&
      !LodashHelper.isEqual(previousValues?.rideOptions, currentValues?.rideOptions)
    ) {
      
      dispatch(
        checkForMatchedCompanies({
          vehicleType: currentValues?.vehicleType,
          rideOptions: currentValues?.rideOptions,
          pickupAddressDetails: currentValues?.pickupAddressDetails,
          destinationAddress: currentValues?.destinationAddress,
          supportCarBoosterSeat: currentValues?.pickupPointAdditionalInfo?.needsCarSeat,
        }),
      );
    }
  }, [allowCheck,   mounted, previousValues, currentValues?.rideOptions, currentValues?.vehicleType]);

  useEffect(() => () => dispatch(resetHasMatchedTCInfo()), [dispatch]);
};
