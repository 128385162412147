
import { URLS, USER_ROLES, USER_STATUSES } from '../constants/constants';
import { baseRequest } from '../utils';
import { getPage } from '../utils/helpers';
import { types as commonTypes } from './common';

const types = {
  DRIVER_FETCH_ASSIGNED_RIDES_REQUEST: 'DRIVER_FETCH_ASSIGNED_RIDES_REQUEST',
  DRIVER_FETCH_ASSIGNED_RIDES_SUCCESS: 'DRIVER_FETCH_ASSIGNED_RIDES_SUCCESS',
  DRIVER_FETCH_ASSIGNED_RIDES_ERROR: 'DRIVER_FETCH_ASSIGNED_RIDES_ERROR',

  DRIVER_FETCH_PAST_RIDES_REQUEST: 'DRIVER_FETCH_PAST_RIDES_REQUEST',
  DRIVER_FETCH_PAST_RIDES_SUCCESS: 'DRIVER_FETCH_PAST_RIDES_SUCCESS',
  DRIVER_FETCH_PAST_RIDES_ERROR: 'DRIVER_FETCH_PAST_RIDES_ERROR',

  FETCH_DRIVERS_REQUEST: 'FETCH_DRIVERS_REQUEST',
  FETCH_DRIVERS_SUCCESS: 'FETCH_DRIVERS_SUCCESS',
  FETCH_DRIVERS_ERROR: 'FETCH_DRIVERS_ERROR',

  CREATE_DRIVER_REQUEST: 'CREATE_DRIVER_REQUEST',
  CREATE_DRIVER_SUCCESS: 'CREATE_DRIVER_SUCCESS',
  CREATE_DRIVER_ERROR: 'CREATE_DRIVER_ERROR',

  UPDATE_DRIVER_REQUEST: 'UPDATE_DRIVER_REQUEST',
  UPDATE_DRIVER_SUCCESS: 'UPDATE_DRIVER_SUCCESS',
  UPDATE_DRIVER_ERROR: 'UPDATE_DRIVER_ERROR',

  FETCH_DRIVER_DETAILS_REQUEST: 'FETCH_DRIVER_DETAILS_REQUEST',
  FETCH_DRIVER_DETAILS_SUCCESS: 'FETCH_DRIVER_DETAILS_SUCCESS',
  FETCH_DRIVER_DETAILS_ERROR: 'FETCH_DRIVER_DETAILS_ERROR',

  CHANGE_DRIVER_STATUS_REQUEST: 'CHANGE_DRIVER_STATUS_REQUEST',
  CHANGE_DRIVER_STATUS_SUCCESS: 'CHANGE_DRIVER_STATUS_SUCCESS',
  CHANGE_DRIVER_STATUS_ERROR: 'CHANGE_DRIVER_STATUS_ERROR',

  FETCH_DRIVERS_BY_TC_MATCHING_FOR_RIDE_REQUEST: 'FETCH_DRIVERS_BY_TC_MATCHING_FOR_RIDE_REQUEST',
  FETCH_DRIVERS_BY_TC_MATCHING_FOR_RIDE_SUCCESS: 'FETCH_DRIVERS_BY_TC_MATCHING_FOR_RIDE_SUCCESS',
  FETCH_DRIVERS_BY_TC_MATCHING_FOR_RIDE_ERROR: 'FETCH_DRIVERS_BY_TC_MATCHING_FOR_RIDE_ERROR',

  CLEAR_DRIVERS_INFO: 'CLEAR_DRIVERS_INFO',

  SET_CURRENT_PAGE_DRIVERS: 'SET_CURRENT_PAGE_DRIVERS',

  SET_END_DATE_RIDE_SUCCESS: 'SET_END_DATE_RIDE_SUCCESS',

  SET_ACTIVE_TAB: 'SET_ACTIVE_TAB',

  RESET_DRIVER_PAGINATION: 'RESET_DRIVER_PAGINATION',
};

const fetchDriversByTCMatchingForRideRequest = () => ({
  type: types.FETCH_DRIVERS_BY_TC_MATCHING_FOR_RIDE_REQUEST,
});

const fetchDriversRequest = () => ({
  type: types.FETCH_DRIVERS_REQUEST,
});

const createDriverRequest = () => ({
  type: types.CREATE_DRIVER_REQUEST,
});

const updateDriverRequest = () => ({
  type: types.UPDATE_DRIVER_REQUEST,
});

const fetchDriverDetailsRequest = () => ({
  type: types.FETCH_DRIVER_DETAILS_REQUEST,
});
export const changeDriverStatusRequest = () => ({
  type: types.CHANGE_DRIVER_STATUS_REQUEST,
});

export const resetDriverPagination = () => ({
  type: types.RESET_DRIVER_PAGINATION,
});

export const setActiveTab = (payload) => ({
  type: types.SET_ACTIVE_TAB,
  payload,
});

const driverFetchAssignedRidesRequest = () => ({
  type: types.DRIVER_FETCH_ASSIGNED_RIDES_REQUEST,
});

const driverFetchPastRidesRequest = () => ({
  type: types.DRIVER_FETCH_PAST_RIDES_REQUEST,
});

export const driverFetchAssignedRides = (params = {}) => {
  const success = (payload) => ({
    type: types.DRIVER_FETCH_ASSIGNED_RIDES_SUCCESS,
    payload,
  });

  const failure = (error) => ({
    type: types.DRIVER_FETCH_ASSIGNED_RIDES_ERROR,
    payload: error,
  });

  return (dispatch, getStore) => {
    const { assignedRidesCurrentPage, limit } = getStore().drivers;
    dispatch(driverFetchAssignedRidesRequest());
    return baseRequest(
      'GET',
      `/ride-requests/assigned`,
      null,
      {},
      { docsPerPage: limit, page: assignedRidesCurrentPage, ...params },
    )
      .then((response) => {
        dispatch(success(response.data));
      })
      .catch((error) => {
        if (error) dispatch(failure([]));
      });
  };
};

export const driverFetchPastRides = (params = {}) => {
  const success = (payload) => ({
    type: types.DRIVER_FETCH_PAST_RIDES_SUCCESS,
    payload,
  });

  const failure = (error) => ({
    type: types.DRIVER_FETCH_PAST_RIDES_ERROR,
    payload: error,
  });

  return (dispatch, getStore) => {
    const { pastRidesCurrentPage, limit } = getStore().drivers;
    dispatch(driverFetchPastRidesRequest());
    return baseRequest(
      'GET',
      `/ride-requests/past`,
      null,
      {},
      { docsPerPage: limit, page: pastRidesCurrentPage, ...params },
    )
      .then((response) => {
        dispatch(success(response.data));
      })
      .catch((error) => {
        if (error) dispatch(failure([]));
      });
  };
};

export const createDriver = (payload,Navigation) => {
  const navigate =Navigation;
  const success = (response) => {
    return {
      type: types.CREATE_DRIVER_SUCCESS,
      payload: response,
    };
  };

  const failure = (error) => ({
    type: types.CREATE_DRIVER_ERROR,
    payload: error,
  });

  return (dispatch) => {
    dispatch(createDriverRequest());
    return baseRequest('POST', '/drivers', payload)
      .then((response) => {
      
       
        dispatch(success(response.data));
        navigate.back();
      })
      .catch((error) => {
        if (error) dispatch(failure(error?.response?.data));
      });
  };
};

export const fetchDrivers = ({value, filtered,Navigation}) => {
  const navigate = Navigation;
  const success = (response) => {
    return {
      type: types.FETCH_DRIVERS_SUCCESS,
      payload: response,
    };
  };

  const failure = (error) => ({
    type: types.FETCH_DRIVERS_ERROR,
    payload: error,
  });

  return (dispatch, getStore) => {
    
    if (filtered) navigate.push(URLS.DRIVERS);
    const currentPage = getPage(navigate?.location);
    const { role } = getStore().authorization;

    dispatch(fetchDriversRequest());
    return baseRequest(
      'GET',
      `/drivers`,
      null,
      {},
      {
        docsPerPage: role === USER_ROLES.DRIVER ? 5 : 10,
        page: currentPage,
        value: value || '',
      },
    )
      .then((response) => dispatch(success(response.data)))
      .catch((error) => {
        if (error) dispatch(failure([]));
      });
  };
};

export const getDriverDetails = (id) => {
  const success = (response) => {
    return {
      type: types.FETCH_DRIVER_DETAILS_SUCCESS,
      payload: response,
    };
  };

  const failure = (error) => ({
    type: types.FETCH_DRIVER_DETAILS_ERROR,
    payload: error,
  });

  return (dispatch) => {
    dispatch(fetchDriverDetailsRequest());
    return baseRequest('GET', `/drivers/${id}`)
      .then((response) => dispatch(success(response.data)))
      .catch((error) => {
        if (error) dispatch(failure(error?.response?.data));
      });
  };
};

export const updateDriver = ({ id, values,Navigation }) => {
  const navigate =Navigation;
  const success = (response) => {
    return {
      type: types.UPDATE_DRIVER_SUCCESS,
      payload: response,
    };
  };

  const failure = (error) => ({
    type: types.UPDATE_DRIVER_ERROR,
    payload: error,
  });

  return (dispatch) => {
    dispatch(updateDriverRequest());
    return baseRequest('PUT', `/drivers/${id}`, values)
      .then((response) => {
         
        dispatch(success(response.data));
       navigate.back();
      })
      .catch((error) => {
        if (error) dispatch(failure(error?.response?.data));
      });
  };
};

export const changeDriverStatus = ({ id, status,Navigation }) => {
  const navigate =Navigation;
  const success = (response) => {
    return {
      type: types.CHANGE_DRIVER_STATUS_SUCCESS,
      payload: response,
    };
  };

  const failure = (error) => ({
    type: types.CHANGE_DRIVER_STATUS_ERROR,
    payload: error,
  });

  return (dispatch) => {
    dispatch(changeDriverStatusRequest());
    return baseRequest('PATCH', `/drivers/${id}/disable`, status)
      .then((response) => {
        dispatch(success(response.data));
        
       
        if (status.status === USER_STATUSES.DISABLED) navigate.back();
      })
      .catch((error) => {
        if (error) dispatch(failure(error?.response?.data));
      });
  };
};

export const fetchDriversByTCMatchingForRide = ({ rideId, transportCompanyId }) => {
  const success = (response) => {
    return {
      type: types.FETCH_DRIVERS_BY_TC_MATCHING_FOR_RIDE_SUCCESS,
      payload: response,
    };
  };

  const failure = (error) => ({
    type: types.FETCH_DRIVERS_BY_TC_MATCHING_FOR_RIDE_ERROR,
    payload: error,
  });

  return (dispatch) => {
    dispatch(fetchDriversByTCMatchingForRideRequest());
    return baseRequest('GET', `/ride-requests/${rideId}/transport-company/${transportCompanyId}/drivers`)
      .then((response) => {
        dispatch(success(response.data));
      })
      .catch((error) => {
        if (error) dispatch(failure(error?.response?.data));
      });
  };
};

export const clearDriversInfo = () => ({
  type: types.CLEAR_DRIVERS_INFO,
});

export const setCurrentPage = (page) => ({
  type: types.SET_CURRENT_PAGE_DRIVERS,
  payload: page,
});

const initialState = {
  assignedRides: [],
  assignedRidesCurrentPage: 1,
  assignedRidesTotalPages: 1,
  assignedRidesTotal: 1,
  assignedRidesAreLoaded: false,
  pastRides: [],
  pastRidesCurrentPage: 1,
  pastRidesTotalPages: 1,
  pastRidesTotal: 1,
  pastRidesAreLoaded: false,
  drivers: [],
  driverDetails: null,
  currentPage: 1,
  totalPages: 1,
  total: 1,
  limit: 5,
  activeTab: '1',

  loading: false,
  error: false,
};

export default function drivers(state = initialState, action) {
  switch (action.type) {
    case types.DRIVER_FETCH_ASSIGNED_RIDES_REQUEST:
      return {
        ...state,
        loading: true,
        assignedRidesAreLoaded: false,
      };

    case types.DRIVER_FETCH_ASSIGNED_RIDES_SUCCESS:
      return {
        ...state,
        loading: false,
        assignedRides:
          state.assignedRidesCurrentPage === 1
            ? action.payload?.data
            : [...state.assignedRides, ...action.payload?.data],
        assignedRidesCurrentPage: action.payload?.meta?.page + 1,
        assignedRidesTotalPages: action.payload?.meta?.totalPages,
        assignedRidesTotal: action.payload?.meta?.total || 1,
        assignedRidesAreLoaded: true,
      };

    case types.DRIVER_FETCH_ASSIGNED_RIDES_ERROR:
      return {
        ...state,
        loading: false,
        assignedRides: action.payload,
        assignedRidesCurrentPage: 1,
        assignedRidesTotalPages: 1,
        assignedRidesTotal: 1,
      };

    case types.DRIVER_FETCH_PAST_RIDES_REQUEST:
      return {
        ...state,
        loading: true,
        pastRidesAreLoaded: false,
      };

    case types.DRIVER_FETCH_PAST_RIDES_SUCCESS:
      return {
        ...state,
        loading: false,
        pastRides:
          state.pastRidesCurrentPage === 1 ? action.payload?.data : [...state.pastRides, ...action.payload?.data],
        pastRidesCurrentPage: action.payload?.meta?.page + 1,
        pastRidesTotalPages: action.payload?.meta?.totalPages,
        pastRidesTotal: action.payload?.meta?.total || 1,
        pastRidesAreLoaded: true,
      };

    case types.DRIVER_FETCH_PAST_RIDES_ERROR:
      return {
        ...state,
        loading: false,
        pastRides: action.payload,
        pastRidesCurrentPage: 1,
        pastRidesTotalPages: 1,
        pastRidesTotal: 1,
      };
    case types.SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.payload,
      };

    case types.RESET_DRIVER_PAGINATION:
      return {
        ...state,
        pastRidesCurrentPage: 1,
        assignedRidesCurrentPage: 1,
      };

    case types.CREATE_DRIVER_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.CREATE_DRIVER_SUCCESS:
      return {
        ...state,
        drivers: [...state.drivers, action.payload],
        loading: false,
      };

    case types.CREATE_DRIVER_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.FETCH_DRIVERS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.FETCH_DRIVERS_SUCCESS:
      return {
        ...state,
        drivers: action.payload?.data,
        currentPage: action.payload?.meta?.page,
        totalPages: action.payload?.meta?.totalPages,
        total: action.payload?.meta?.total,
        loading: false,
      };

    case types.FETCH_DRIVERS_ERROR:
      return {
        ...state,
        loading: false,
        drivers: [],
      };

    case types.UPDATE_DRIVER_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.UPDATE_DRIVER_SUCCESS:
      const driverIdx = state.drivers.findIndex((u) => u.id === action.payload.id);
      let copiedDrivers = [...state.drivers];
      if (driverIdx > 0) {
        copiedDrivers[driverIdx] = { ...action.payload };
      }
      return {
        ...state,
        drivers: copiedDrivers,
        driverDetails: action.payload,
        loading: false,
      };

    case types.UPDATE_DRIVER_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.FETCH_DRIVER_DETAILS_REQUEST:
      return {
        ...state,
        driverDetails: null,
        loading: true,
      };

    case types.FETCH_DRIVER_DETAILS_SUCCESS:
      return {
        ...state,
        driverDetails: action.payload,
        loading: false,
      };

    case types.FETCH_DRIVER_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.CLEAR_DRIVERS_INFO:
      return {
        ...state,
        drivers: [],
        currentPage: 1,
        totalPages: 1,
        total: 1,
      };

    case types.CHANGE_DRIVER_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.CHANGE_DRIVER_STATUS_SUCCESS:
      const updatedDriverIdx = state.drivers.findIndex((d) => d.id === action.payload.id);
      const clonedDrivers = [...state.drivers];
      clonedDrivers[updatedDriverIdx] = action.payload;
      return {
        ...state,
        driverDetails: action.payload,
        drivers: clonedDrivers,
        loading: false,
      };

    case types.CHANGE_DRIVER_STATUS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.FETCH_DRIVERS_BY_TC_MATCHING_FOR_RIDE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.FETCH_DRIVERS_BY_TC_MATCHING_FOR_RIDE_SUCCESS:
      return {
        ...state,
        drivers: action.payload,
        loading: false,
      };
    case types.FETCH_DRIVERS_BY_TC_MATCHING_FOR_RIDE_ERROR:
      return {
        ...state,
        drivers: [],
        loading: false,
      };

    case types.SET_CURRENT_PAGE_DRIVERS:
      return {
        ...state,
        currentPage: action.payload,
      };

    case commonTypes.CLEAR_STORE_INFO:
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
