import React, { useCallback, useEffect } from 'react';
import { Spin, Tabs } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import InfiniteScroll from 'react-infinite-scroll-component';

import {
  driverFetchAssignedRides,
  driverFetchPastRides,
  setActiveTab,
  resetDriverPagination,
} from '../../../store/drivers';
import { DriverRideCard } from '../../../components';
import { RIDE_STATUSES } from '../../../constants/constants';
import { setShowRefreshNotification } from '../../../store/rides';
import { destroyNotification } from '../../../components/common/ConfirmPopUp/ConfirmPopUp';

import styles from './index.module.css';
import { Navigation } from '../../../utils/navigation';

const { TabPane } = Tabs;

const LoadMoreSpin = () => (
  <div className={styles.spin}>
    <Spin />
  </div>
);

const Driver_Dashboard = ({
  driverFetchAssignedRides,
  assignedRides,
  assignedRidesTotal,
  assignedRidesAreLoaded,
  assignedRidesCurrentPage,
  pastRidesCurrentPage,
  driverFetchPastRides,
  pastRides,
  pastRidesTotal,
  pastRidesAreLoaded, 
  activeTab,
  setActiveTab,
  resetDriverPagination,
  setShowRefreshNotification,
}) => {
  const {go} = Navigation();
  const showRideStatus = (ride) =>
    ride?.status === RIDE_STATUSES.ACCEPTED ||
    ride?.status === RIDE_STATUSES.IN_PROGRESS ||
    ride?.status === RIDE_STATUSES.COMPLETED ||
    ride?.status === RIDE_STATUSES.UNABLE_TO_FULFILL;

  useEffect(() => {
    if (assignedRidesCurrentPage === 1) driverFetchAssignedRides();
    return;
  }, [driverFetchAssignedRides, assignedRidesCurrentPage]);

  useEffect(() => {
    if (pastRidesCurrentPage === 1) driverFetchPastRides();
    return;
  }, [driverFetchPastRides, pastRidesCurrentPage]);

  useEffect(
    () => () => {
      setShowRefreshNotification(false);
      destroyNotification();
      resetDriverPagination();
      return;
    },
    [resetDriverPagination, setShowRefreshNotification],
  );

  const handleRideDetails = useCallback((id) => () => go(`/rides/${id}`), [go]);

  const loadMoreAssignedRides = () => {
    if (!assignedRidesAreLoaded) return;
    driverFetchAssignedRides();
  };

  const loadMorePastRides = () => {
    if (!pastRidesAreLoaded) return;
    driverFetchPastRides();
  };

  return (
    <div className={styles.pageContent}>
      <Tabs type='card' size='middle' className={styles.tabs} activeKey={activeTab} onChange={setActiveTab}>
        <TabPane tab='Assigned Rides' key='1'>
          {!Boolean(assignedRides?.length) && (
            <h2 className={styles.pastTabDescription}>There are no assigned rides yet</h2>
          )}
          <InfiniteScroll
            dataLength={assignedRidesTotal}
            next={loadMoreAssignedRides}
            hasMore={assignedRidesTotal > assignedRides?.length}
            loader={assignedRides?.length > 0 && <LoadMoreSpin key={0} />}>
            {assignedRides?.map((ride) => (
              <DriverRideCard
                ride={ride}
                key={ride.seqId}
                handleOpenRideDetails={handleRideDetails(ride?.id)}
                showRideStatus={showRideStatus(ride)}
              />
            ))}
          </InfiniteScroll>
        </TabPane>
        <TabPane tab='Completed Rides' key='2'>
          <h2 className={styles.pastTabDescription}>Completed rides for past two days:</h2>
          <InfiniteScroll
            dataLength={pastRidesTotal}
            next={loadMorePastRides}
            hasMore={pastRidesTotal > pastRides?.length}
            loader={pastRides?.length > 0 && <LoadMoreSpin key={1} />}>
            {pastRides?.map((ride) => (
              <DriverRideCard
                ride={ride}
                key={ride.seqId}
                handleOpenRideDetails={handleRideDetails(ride?.id)}
                showRideStatus={showRideStatus(ride)}
              />
            ))}
          </InfiniteScroll>
        </TabPane>
      </Tabs>
    </div>
  );
};

const mapStateToProps = (state) => ({
  assignedRides: state.drivers.assignedRides,
  assignedRidesTotal: state.drivers.assignedRidesTotal,
  assignedRidesAreLoaded: state.drivers.assignedRidesAreLoaded,
  pastRides: state.drivers.pastRides,
  pastRidesTotal: state.drivers.pastRidesTotal,
  pastRidesAreLoaded: state.drivers.pastRidesAreLoaded,
  assignedRidesCurrentPage: state.drivers.assignedRidesCurrentPage,
  pastRidesCurrentPage: state.drivers.pastRidesCurrentPage,
  activeTab: state.drivers.activeTab,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { driverFetchAssignedRides, driverFetchPastRides, setActiveTab, resetDriverPagination, setShowRefreshNotification },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Driver_Dashboard);
