import { InfoOutlined } from '@ant-design/icons';
import { FieldArray } from 'formik';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AutoComplete, Checkbox, Select, Snackbar, Tooltip } from '../../..';
import { COVID_ERROR_MESSAGE, COVID_OPTIONS, URLS } from '../../../../constants/constants';
import { LodashHelper } from '../../../../utils';

import { CreateRiderForm } from '../../CreateRider/CreateRider';
import { initialCaregiver, stepperInfo } from '../stepperInfo';
import { RiderFieldNames } from '../../CreateRider/CreateRider';
import { getUniqueUserFieldValues } from '../../../../hooks/useCheckForUniqueRider';
import { useCheckRiderForMatching } from '../../../../hooks/useCheckRiderForMatching';
import { renderCaregivers } from './renderCaregivers';
import { clearRidersInfo, setRiderInfo } from '../../../../store/riders';
import { RiderHelper } from '../../../../utils/RiderHelper';

import styles from './index.module.css';

const getRiderFieldNames = (RiderFieldNames) => {
  const fieldNames = LodashHelper.cloneDeep(RiderFieldNames);
  Object.entries(fieldNames).forEach(([key, value]) => {
    fieldNames[key] = `riderInfo.${fieldNames[value]}`;
  });
  return fieldNames;
};

export const StepOneFormFields = ({
  values,
  errors,
  setFieldValue,
  showCovidField,
  disabledValues,
  notFoundContent,
  handleSelectRider,
  handleSearchRider,
  suggestions,
  riderIsPopulated,
  setRiderIsPopulated,
}) => {
  const dispatch = useDispatch();
  const riderIsUnique = useSelector((state) => state.riders.riderIsUnique);
  const hasMatchedRiders = useSelector((state) => state.riders.hasMatchedRiders);
  const riders = useSelector((state) => state.riders.riders);
  const isCovidСase = values?.covid19Relation === COVID_OPTIONS[1].value;

  const disableRiderInfo = disabledValues?.riderInfo || isCovidСase;

  const disabledRiderAutofill = disabledValues?.riderAutoComplete;

  const dibaledCaregiverInfo = disabledValues?.caregiverInfo || isCovidСase;

  const showRidersAutoComplete = (URLS.CREATE_RIDE || URLS.EDIT_RIDE || URLS.EDIT_DRAFT) && !disabledRiderAutofill;

  const handleChangeCaregiver = async ({ checked, name, setFieldValue }) => {
    if (checked) {
      await setFieldValue('caregivers', [initialCaregiver]);
    } else if (!checked) {
      await setFieldValue('caregivers', []);
    }

    await setFieldValue(name, checked);
  };

  const allowCheckIsUnique = useMemo(
    () =>
      !values?.riderInfo?.id &&
      !LodashHelper.isEqual(
        getUniqueUserFieldValues(stepperInfo[0].values.riderInfo),
        getUniqueUserFieldValues(values),
      ),
    [values],
  );

  const handleCancelUseExistingRider = () => {
    dispatch(clearRidersInfo());
  };

  const handleChangeRiderInfo = async (rider) => {
    await setFieldValue('riderInfo', rider);
    await setFieldValue('formattedRiderName', RiderHelper.getRiderAutoCompleteName(rider));
    await setRiderIsPopulated(true);
    dispatch(setRiderInfo(rider));
    dispatch(clearRidersInfo());
  };

  const handleSubmitUseExistingRider = async (values) => {
    const rider = riders.find((rider) => rider?.id === values?.riders[0]);
    await handleChangeRiderInfo(rider);
  };

  const handleUseExactRider = async () => {
    const rider = riders[0];
    await handleChangeRiderInfo(rider);
  };

  useCheckRiderForMatching(getUniqueUserFieldValues(values?.riderInfo), allowCheckIsUnique);

  return (
    <>
      {showCovidField && (
        <>
          <label className={styles.rideSelection}>
            <span className={styles.required}>COVID-19</span>
            <Select
              name='covid19Relation'
              options={COVID_OPTIONS}
              placeholder={'Pick one'}
              customErrorMessage={errors.covid19Relation !== '*required'}
              value={values?.covid19Relation}
              disabled={disabledValues?.covid19Relation}
              whenFieldDisabledShowTooltip={Boolean(disabledValues?.covid19Relation)}
            />
          </label>

          {((errors.covid19Relation && errors.covid19Relation !== '*required') ||
            values?.covid19Relation === COVID_OPTIONS[1].title) && (
            <Snackbar type='error' title={'COVID-19'} content={COVID_ERROR_MESSAGE} />
          )}
        </>
      )}
      {showRidersAutoComplete && (
        <div className={styles.searchRider}>
          <label className={styles.autocompleteField}>
            <span>Search for Rider</span>
            <div className={styles.autocompleteWrapper}>
              <AutoComplete
                name='formattedRiderName'
                placeholder='Search for Rider'
                notFoundContent={notFoundContent}
                onSelect={handleSelectRider}
                onSearch={handleSearchRider}
                options={suggestions}
                disabled={disableRiderInfo || disabledRiderAutofill}
                myClassName={styles.autoComplete}
              />
              <Tooltip
                title={
                  <span className={styles.tooltipContent}>
                    If the name you entered into the search field is not found, begin entering the rider information
                    into the fields below.
                  </span>
                }>
                <span className={styles.tooltipInfo}>
                  <InfoOutlined className={styles.infoIcon} />
                </span>
              </Tooltip>
            </div>
          </label>
        </div>
      )}

      <CreateRiderForm
        values={values?.riderInfo}
        disableRiderInfo={isCovidСase}
        fastField={!values?.riderInfo}
        fieldNames={getRiderFieldNames(RiderFieldNames)}
        allowUniqueCheck={false}
        riderIsUnique={riderIsUnique}
        showFullForm={false}
        riderIsPopulated={riderIsPopulated}
        hasMatchedRiders={hasMatchedRiders}
        riders={riders}
        handleSubmitUseExistingRider={handleSubmitUseExistingRider}
        handleCancelUseExistingRider={handleCancelUseExistingRider}
        handleUseExactRider={handleUseExactRider}
      />

      <label className={styles.caregiverCheckbox}>
        <Checkbox
          fastField={dibaledCaregiverInfo}
          name='willBeCaregiverOnScene'
          checked={values?.willBeCaregiverOnScene}
          onChange={handleChangeCaregiver}
          disabled={disabledValues?.caregiverWillBeOntheScene || isCovidСase}
          whenFieldDisabledShowTooltip={Boolean(disabledValues?.caregiverWillBeOntheScene)}
        />
        <span className={styles.checkboxLabel}>Caregiver will be on the scene</span>
      </label>

      {values?.willBeCaregiverOnScene && (
        <>
          <h2 className={styles.caregiverTitle}>Caregiver(s) Information</h2>
          <FieldArray name='caregivers' render={renderCaregivers(values.caregivers, dibaledCaregiverInfo)} />
        </>
      )}
    </>
  );
};
