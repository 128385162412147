import classNames from 'classnames';
import { Formik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, DatePicker, Modal } from '../..';
import { DEFAULT_TIME_FORMAT } from '../../../constants/constants';

import { DateHelper } from '../../../utils';

import styles from './index.module.css';

const renderExtraFooter = () => null;

export const useAcceptRideModal = ({ id, onConfirm, ride }) => {
  const [visibleModal, setModalVisible] = useState(false);

  const [pickUpDate, setPickUpDate] = useState();

  const [pickUpDateLowerLimitRange, setPickUpDateLowerLimitRange] = useState();
  const [pickUpDateHigherLimitRange, setPickUpDateHigherLimitRange] = useState();

  useEffect(() => {
    if (ride) {
      setPickUpDateHigherLimitRange(DateHelper.utcToZonedTime(ride?.pickUpDate?.datetime, ride?.pickUpDate?.timezone));
      setPickUpDateLowerLimitRange(
        DateHelper.utcToZonedTime(ride?.pickUpDateLowerLimit?.datetime, ride?.pickUpDateLowerLimit?.timezone),
      );
    }
    return;
  }, [ride]);

  const onCancel = useCallback(() => {
    setPickUpDate(null);
    setModalVisible(false);
  }, [setModalVisible]);

  const disabledDatesForPickUpDate = (d) => {
    const minDate = pickUpDateLowerLimitRange;
    const maxDate = pickUpDateHigherLimitRange;

    return (
      (minDate != null && DateHelper.isBefore(d, minDate) && !DateHelper.isTheSameDay(d, minDate)) ||
      (maxDate != null && DateHelper.isAfter(d, maxDate) && !DateHelper.isTheSameDay(d, maxDate))
    );
  };

  const disabledDateTime = (currentDate) => {
    const startDate = pickUpDateLowerLimitRange;
    const maxDate = pickUpDateHigherLimitRange;

    return DateHelper.disabledTimeForHigherLimit({ currentDate, startDate, maxDate });
  };

  const handleChangeDateTime = async ({ name, value, setFieldValue }) => {
    const allowedpickupDateTimeValue = DateHelper.compareDates(pickUpDateLowerLimitRange, value)
      ? pickUpDateLowerLimitRange
      : !DateHelper.isWithinInterval(value, {
          start: pickUpDateLowerLimitRange,
          end: pickUpDateHigherLimitRange,
        })
      ? new Date(new Date(value).setHours(12, 0, 0, 0))
      : value;

    await setPickUpDate(allowedpickupDateTimeValue);
    await setFieldValue(name, DateHelper.zonedTimeToUtcISOS(allowedpickupDateTimeValue, ride?.pickUpDate?.timezone));
  };

  const defaultValueForDatePicker = DateHelper.isTheSameDay(pickUpDateLowerLimitRange, pickUpDateHigherLimitRange)
    ? pickUpDateLowerLimitRange
    : new Date(new Date().setHours(0, 0, 0, 0));

  return {
    Modal: (
      <Modal
        myClassName={'rideAcceptModal'}
        visible={visibleModal}
        onCancel={onCancel}
        closable
        width={670}
        title={`Accepting Ride Request ID- ${id}`}>
        <div
          className={classNames({
            [styles.modalContent]: true,
          })}>
          <Formik
            initialValues={{
              datetime: '',
            }}
            onReset={onCancel}
            onSubmit={onConfirm}>
            {({ values, handleSubmit, handleReset, resetForm }) => {
              return (
                <form className={styles.rideStatusForm} onReset={handleReset}>
                  <p className={styles.description}>
                    Please choose the Time for Pickup from within the window provided by the Health Organization
                  </p>
                  <label className={styles.datepickerLabel}>
                    <span className={styles.dateHeader}>Date</span>
                    <span className={styles.timeHeader}>Time</span>
                  </label>
                  <DatePicker
                    defaultPickerValue={new Date(pickUpDateLowerLimitRange)}
                    hideInputField
                    defaultOpen={visibleModal}
                    name='datetime'
                    onChange={handleChangeDateTime}
                    format={DEFAULT_TIME_FORMAT}
                    value={pickUpDate}
                    disabledDate={disabledDatesForPickUpDate}
                    disabledTime={disabledDateTime}
                    showTime={{
                      defaultValue: defaultValueForDatePicker,
                    }}
                    use12Hours
                    renderExtraFooter={renderExtraFooter}
                  />

                  <div className={styles.setDateTime} />

                  <div
                    className={classNames({
                      [styles.modalBtns]: true,
                      [styles.rideStatusBtn]: true,
                    })}>
                    <div>
                      <Button myClassName={styles.navigationBtn} type='reset'>
                        Cancel
                      </Button>
                    </div>
                    <div>
                      <Button
                        myClassName={styles.navigationBtn}
                        type='submit'
                        onClick={handleSubmit}
                        disabled={!values.datetime}>
                        Accept the Ride
                      </Button>
                    </div>
                  </div>
                </form>
              );
            }}
          </Formik>
        </div>
      </Modal>
    ),
    setAcceptlModalVisible: setModalVisible,
    acceptModalVisible: visibleModal,
  };
};
