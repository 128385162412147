import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { DateHelper, renderRecordValue } from "../../../../utils";
import { Record } from "../RideDetails";
import { renderBuildingDetails } from "./BuildingDetails";
import { renderPickUpDate } from "../../../../utils/helpers";

import styles from "../index.module.css";
import { FlightInformation } from "../../FlightInformation/FlightInformation";
import { Popover } from "antd";

export const RidePickUpInfo = ({
  pickupAddressDetails,
  destinationAddress,
  pickUpDate,
  riderDescription,
  isPickUpDateFlexible,
  pickUpDateLowerLimit,
  tcEstimatedPickUpDate,
  securityGate,
  flightInfo,
  appointmentDate,
  isRecurringRide,
  leg,
  seqId,
}) => {
  const pickUpDateTime = useMemo(() => {
    if (isPickUpDateFlexible) {
      return (
        pickUpDate?.datetime &&
        renderPickUpDate({ dateFrom: pickUpDateLowerLimit, dateTo: pickUpDate })
      );
    } else if (pickUpDate?.datetime) {
      return DateHelper.renderDateTimeWithTZ({
        dateTime: pickUpDate.datetime,
        timeZone: pickUpDate.timezone,
      });
    }
  }, [isPickUpDateFlexible, pickUpDate, pickUpDateLowerLimit]);

  const acceptedDateTime = useMemo(() => {
    if (isPickUpDateFlexible && tcEstimatedPickUpDate?.datetime) {
      return DateHelper.renderDateTimeWithTZ({
        dateTime: tcEstimatedPickUpDate.datetime,
        timeZone: tcEstimatedPickUpDate.timezone,
      });
    }
    return null;
  }, [isPickUpDateFlexible, tcEstimatedPickUpDate]);

  const appointmentDateTime = useMemo(() => {
    if (appointmentDate?.datetime) {
      return DateHelper.renderDateTimeWithTZ({
        dateTime: appointmentDate.datetime,
        timeZone: appointmentDate.timezone,
      });
    }
  }, [appointmentDate]);

  return (
    <div className={styles.rideCard}>
      {leg && <h3>Leg {leg + (seqId ? ` - ID (${seqId})` : "")}</h3>}
      {/* Origin Address */}
      <p className={styles.originAddress}>Origin Address:</p>
      <p>{renderRecordValue(pickupAddressDetails?.formattedAddress)}</p>

      {/* Destination Address */}

      {destinationAddress && (
        <>
          <p className={styles.originAddress}>Destination Address:</p>
          <p>{renderRecordValue(destinationAddress?.formattedAddress)}</p>
        </>
      )}

      {/* Origin Facility Name */}
      {pickupAddressDetails?.facilityName && (
        <>
          <p className={styles.originAddress}>Facility Name:</p>
          <p>{renderRecordValue(pickupAddressDetails?.facilityName)}</p>
        </>
      )}

      {flightInfo && (
        <Popover
          content={<FlightInformation flightInfo={flightInfo} />}
          placement="topLeft"
          getPopupContainer={(trigger) => trigger}
        >
          <span style={{ color: "blue", textDecorationLine: "underline" }}>
            {renderRecordValue(flightInfo)}
          </span>
        </Popover>
      )}
      {renderBuildingDetails(pickupAddressDetails)}
      {pickupAddressDetails?.isPublicPlace && (
        <Record
          title="Public Place"
          value={pickupAddressDetails?.isPublicPlace ? "Yes" : "No"}
          myClassName={styles.recordDescription}
        />
      )}
      {securityGate?.hasSecurityGate && (
        <Record
          title="Gate Entry Instructions"
          value={securityGate?.securityGateInstruction}
          myClassName={styles.recordDescription}
        />
      )}
      {pickupAddressDetails?.locationDescription?.trim().length > 0 && (
        <Record
          title="Location Description"
          value={renderRecordValue(pickupAddressDetails?.locationDescription)}
          myClassName={styles.recordDescription}
        />
      )}
      {riderDescription && riderDescription.trim().length > 0 && (
        <Record
          title="Rider Description"
          value={renderRecordValue(riderDescription)}
          myClassName={styles.recordDescription}
        />
      )}
      {appointmentDateTime && (
        <Record
          title="Appointment Scheduled for"
          value={renderRecordValue(appointmentDateTime)}
          myClassName={styles.recordDescription}
        />
      )}
      <Record
        title={
          flightInfo ? "Airport Pickup Scheduled for" : "Pickup Scheduled for"
        }
        value={renderRecordValue(pickUpDateTime)}
        myClassName={styles.recordDescription}
      />

      {acceptedDateTime && (
        <Record
          title="Pickup Accepted for"
          value={renderRecordValue(acceptedDateTime)}
          myClassName={styles.recordDescription}
        />
      )}
    </div>
  );
};

RidePickUpInfo.propTypes = {
  pickupAddressDetails: PropTypes.object,
  destinationAddress: PropTypes.object,
  pickUpDate: PropTypes.object,
  riderDescription: PropTypes.string,
  flightInfo: PropTypes.string,
};
