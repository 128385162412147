import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '../../formControls/Button/Button';

import styles from './index.module.css';

export const DriverManageRidePanel = ({ driverStarted, rideStarted, handleDriverArrival, handleCancel, handleStartRide, handleEndRide }) => (
  <div className={styles.driversBtns}>
    <Button myClassName={styles.cancelRideBtn} onClick={handleCancel}>
      Cancel Ride
    </Button>
    {rideStarted ? (
      <Button onClick={handleEndRide}>End Ride</Button>
    ) : (
      <> {
          driverStarted ? 
            <Button myClassName={styles.arrivalBtn} onClick={handleDriverArrival}>
              Driver Arrived
            </Button> : 
            <Button onClick={handleStartRide}>Start Ride</Button>
        }
      </>
    )}
  </div>
);

DriverManageRidePanel.propTypes = {
  rideStarted: PropTypes.bool,
  handleCancel: PropTypes.func,
  handleStartRide: PropTypes.func,
  handleEndRide: PropTypes.func,
};
