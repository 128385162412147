import axios from 'axios';

import { store } from '../store';
import { LodashHelper } from '.';
import { changeUserRole, logOut } from '../store/authorization';

import { showNotificationMessage } from '../components';
import { renderRecordValue } from './index';
import { ERROR_TYPES, TIMEOUT } from '../constants/constants';
import { authInterceptor } from './authInterceptor';

export function getAuthHeader(token = store.getState()?.authorization?.access_token) {
  if (token) {
    return `Bearer ${token}`;
  } else {
    return '';
  }
}
//todo: (wasim) change the timeout from 15 sec to 2 minute due to some ride queries taking more then 15 sec
const request = axios.create({
  timeout: TIMEOUT,
});

export const baseRequest = (method, api, data, headers = {}, params, responseType) => {
  return request({
    method: method,
    url: `${process.env.REACT_APP_API_URL}${api}`,
    ...(data && { data: JSON.stringify(LodashHelper.trimValues(data)) }),
    responseType,
    headers: {
      'Content-Type': 'application/json',
      ...(getAuthHeader() && { Authorization: getAuthHeader() }),
      ...headers,
    },
    params: params,
    withCredentials: true,
    credentials: 'include',
  });
};

request.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => Promise.reject(error),
);

request.interceptors.response.use((response) => {
  return response;
}, authInterceptor(request));

request.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (!error.response && !error.status) {
      showNotificationMessage({
        type: 'error',
        content: renderRecordValue(ERROR_TYPES.NETWORK_ERROR, ERROR_TYPES.NETWORK_ERROR),
      });
      return Promise.reject(error);
    }

    if (error?.response?.status === 401) {
      if (error?.response?.data?.roleChanged && !store.getState().authorization.userRoleWasChanged) {
        store.dispatch(logOut());
        store.dispatch(changeUserRole());
        showNotificationMessage({
          type: 'error',
          content: renderRecordValue(error?.response?.data?.message, ERROR_TYPES.DEFAULT),
        });
      }
      return Promise.reject(error);
    }

    if (error?.response?.status >= 500) {
      showNotificationMessage({
        type: 'error',
        content: renderRecordValue(error?.response?.data?.message, ERROR_TYPES.INTERNAL_SERVER_ERROR),
      });
      return Promise.reject(error);
    }

    showNotificationMessage({
      type: 'error',
      content: renderRecordValue(error?.response?.data?.message, ERROR_TYPES.DEFAULT),
      duration: 10,
    });
    return Promise.reject(error);
  },
);
