import React from 'react';

import { Formik } from 'formik';

import { InviteNewUserModal } from '../..';
import {createUserSchema} from '../../../utils';

export const InviteUserForm = ({ id, formRef, onConfirmInviteUser, inviteUserModalVisible, onCancelInviteUser }) => (
  <Formik
    innerRef={formRef}
    enableReinitialize
    validateOnMount
    initialValues={{
      firstName: '',
      lastName: '',
      email: '',
    }}
    validationSchema={createUserSchema}
    onSubmit={async (values, { resetForm, setSubmitting }) => {
      try {
        await onConfirmInviteUser({ organizationId: id, values });
        resetForm();
      } catch (error) {
        setSubmitting(false);
      }
    }}>
    {({ isValid, handleSubmit, resetForm }) => {
      return (
        <form onSubmit={handleSubmit}>
          <InviteNewUserModal
            title='Invite New User to this Organization'
            visible={inviteUserModalVisible}
            onCancel={onCancelInviteUser(resetForm)}
            isValid={isValid}
            handleSubmit={handleSubmit}
          />
        </form>
      );
    }}
  </Formik>
);
