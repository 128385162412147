import React from 'react';

import { Formik } from 'formik';
import * as Yup from 'yup';

import { SetNewAdminModal } from '../..';

export const SwitchAdminForm = ({ id, formRef, updateAdminModalVisible, users, onConfirmSwitchAdmin, onCancel }) => (
  <Formik
    innerRef={formRef}
    enableReinitialize
    validateOnMount
    initialValues={{
      admin: '',
    }}
    validationSchema={Yup.object().shape({
      admin: Yup.string().trim().required('*required'),
    })}
    onSubmit={async (values, { resetForm, setSubmitting }) => {
      try {
        await onConfirmSwitchAdmin({ organizationId: id, userId: values.admin });
        resetForm();
      } catch (error) {
        setSubmitting(false);
      }
    }}>
    {({ isValid, handleSubmit, resetForm }) => {
      return (
        <form onSubmit={handleSubmit}>
          <SetNewAdminModal
            title='Switch Admin for this Organization'
            visible={updateAdminModalVisible}
            onCancel={onCancel(resetForm)}
            isValid={isValid}
            users={users}
          />
        </form>
      );
    }}
  </Formik>
);
