import React from 'react';

export const ErrorIcon = ({ fill }) => {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill={fill} xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8 0.125C3.65117 0.125 0.125 3.65117 0.125 8C0.125 12.3488 3.65117 15.875 8 15.875C12.3488 15.875 15.875 12.3488 15.875 8C15.875 3.65117 12.3488 0.125 8 0.125ZM10.9074 10.9918L9.74727 10.9865L8 8.90352L6.25449 10.9848L5.09258 10.99C5.01523 10.99 4.95195 10.9285 4.95195 10.8494C4.95195 10.816 4.96426 10.7844 4.98535 10.758L7.27227 8.0334L4.98535 5.31055C4.96411 5.28478 4.95233 5.25253 4.95195 5.21914C4.95195 5.1418 5.01523 5.07852 5.09258 5.07852L6.25449 5.08379L8 7.1668L9.74551 5.08555L10.9057 5.08027C10.983 5.08027 11.0463 5.1418 11.0463 5.2209C11.0463 5.2543 11.034 5.28594 11.0129 5.3123L8.72949 8.03516L11.0146 10.7598C11.0357 10.7861 11.048 10.8178 11.048 10.8512C11.048 10.9285 10.9848 10.9918 10.9074 10.9918Z'
        fill={fill}
      />
    </svg>
  );
};
