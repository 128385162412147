import React from 'react';
import classnames from 'classnames';
import { Formik } from 'formik';
import { Alert } from 'antd';

import { Button, Input, Modal, Tooltip } from '../../../components';
import { InputTextArea } from '../../../components/formControls/Input/InputTextArea';
import { renderRecordValue } from '../../../utils';
import { driverEndRideSchema } from '../../../utils/validations';
import { DRIVER_ENDRIDE_DEFAULT_VALUES } from '../../../constants/constants';
import styles from './index.module.css';
import { deadHeadMileageTooltipMessage } from '../../MH_Admin/MHEditRide/TooltipMessages';
import { InfoOutlined } from '@ant-design/icons';

export const ConfirmRideActionModal = ({ start, id, visibleModal, onCancel, onConfirm, tcConfiguration }) =>
  (<Modal
    visible={visibleModal}
    onCancel={onCancel}
    closable
    width={370}
    title={`${start ? 'Start' : 'End'} Ride – ID ${renderRecordValue(id)}`}>
    <div className={styles.modalContent}>
      <Formik
        initialValues={()=>DRIVER_ENDRIDE_DEFAULT_VALUES(!start)}
        validationSchema={()=>driverEndRideSchema(!start)}
        onSubmit={onConfirm}
        onReset={onCancel}
      >
        {({ values, handleSubmit, handleReset}) => { 
          return (
            <form onSubmit={handleSubmit} onReset={handleReset}>
              {!start ? (
              <>
                <div className={styles.comment}>
                  <Alert
                    description = {'Mileage will only be added once, for update contact Medhaul admin'}
                    message="Information" type="info" showIcon
                  />
                </div>
                <label className={classnames({[styles.comment]: true, })}>
                  <span className={styles.required}> Ride total mileage:</span>
                  <Input name='actMileage' type='number' min={1} />
                </label>

              { tcConfiguration?.deadheadMileageConfigure && 
              
              // <label className={classnames({[styles.comment]: true, })}>
              //     <span className={styles.required}> Deadhead mileage:</span>
              //     <Input name='deadheadMileage' type='number' min={0} />
              //   </label>
                    <div className={styles.deadheadWrapper} > 
                    <label className={styles.deadhead}>
                     <span > Deadhead Mileage:</span>
                     <Input name='deadheadMileage'    type='number' min={0} />
                   </label> 
                   <span className={styles.tooltipWrapper}>
                   <Tooltip
                   title={
                     <span>
                      {deadHeadMileageTooltipMessage}
                     </span>
                   }>
                   <span className={styles.tooltipInfo}>
                     <InfoOutlined className={styles.infoIcon} />
                   </span>
                 </Tooltip>
                 </span>
    </div>
                
                
                }
              </>
              ): (
                <label className={styles.comment}>
                  Add comment to the ride
                  <InputTextArea name='comment' type='text' autosize={{minRows: 1, maxRows: 3}} />
                </label>
              )}
              <p className={styles.modalDescription}>Click "Confirm" to {start ? 'start' : 'end'} this ride.</p>
              <div className={classnames({ [styles.modalBtns]: true, })}>
                <div className={styles.archiveModalBtn}>
                  <Button myClassName={styles.navigationBtn} type='reset'>
                    Cancel
                  </Button>
                </div>
                <div className={styles.archiveModalBtn}>
                  <Button myClassName={styles.navigationBtn} disabled={(!start) ? (isNaN(values?.actMileage) || !values?.actMileage || values?.actMileage <= 0) : false} type="submit">
                    Confirm
                  </Button>
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  </Modal>)

