import { localStore, decodeToken, baseRequest, history, Socket, LodashHelper, renderRecordValue } from '../utils';
import { showConfirmPopUp, showNotificationMessage } from '../components';
import { setShowRefreshNotification } from './rides';
import { NOTIFICATIONS_ACTIONS, RECAPTHA_ACTIONS, URLS, USER_ROLES } from '../constants/constants';
import { clearStoreInfo, fetchCaptchaToken, resetAllPagination, types as commonTypes } from './common';
import { store } from '.';
import { fetchTCConfiguration } from './transport_organizations';
import { fetchAddresses } from './addressbook';

export const types = {
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',

  FETCH_USER_DATA_REQUEST: 'FETCH_USER_DATA_REQUEST',
  FETCH_USER_DATA_SUCCESS: 'FETCH_USER_DATA_SUCCESS',
  FETCH_USER_DATA_ERROR: 'FETCH_USER_DATA_ERROR',

  SET_NEW_PASSWORD_REQUEST: 'SET_NEW_PASSWORD_REQUEST',
  SET_NEW_PASSWORD_SUCCESS: 'SET_NEW_PASSWORD_SUCCESS',
  SET_NEW_PASSWORD_ERROR: 'SET_NEW_PASSWORD_ERROR',

  FORGOT_PASSWORD_REQUEST: 'FORGOT_PASSWORD_REQUEST',
  FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_ERROR: 'FORGOT_PASSWORD_ERROR',

  RESEND_INVITE_REQUEST: 'RESEND_INVITE_REQUEST',
  RESEND_INVITE_SUCCESS: 'RESEND_INVITE_SUCCESS',
  RESEND_INVITE_ERROR: 'RESEND_INVITE_ERROR',

  CHANGE_PASSWORD_REQUEST: 'CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_ERROR: 'CHANGE_PASSWORD_ERROR',

  CHANGE_EMAIL_REQUEST: 'CHANGE_EMAIL_REQUEST',
  CHANGE_EMAIL_SUCCESS: 'CHANGE_EMAIL_SUCCESS',
  CHANGE_EMAIL_ERROR: 'CHANGE_EMAIL_ERROR',

  UPDATE_PERSONAL_INFORMATION_REQUEST: 'UPDATE_PERSONAL_INFORMATION_REQUEST',
  UPDATE_PERSONAL_INFORMATION_SUCCESS: 'UPDATE_PERSONAL_INFORMATION_SUCCESS',
  UPDATE_PERSONAL_INFORMATION_ERROR: 'UPDATE_PERSONAL_INFORMATION_ERROR',

  SEND_NEW_PASSWORD_REQUEST: 'SEND_NEW_PASSWORD_REQUEST',
  SEND_NEW_PASSWORD_SUCCESS: 'SEND_NEW_PASSWORD_SUCCESS',
  SEND_NEW_PASSWORD_ERROR: 'SEND_NEW_PASSWORD_ERROR',

  SET_ACCOUNT_REQUEST: 'SET_ACCOUNT_REQUEST',
  SET_ACCOUNT_SUCCESS: 'SET_ACCOUNT_SUCCESS',
  SET_ACCOUNT_ERROR: 'SET_ACCOUNT_ERROR',



  SET_DRIVER_ACCOUNT_REQUEST: 'SET_DRIVER_ACCOUNT_REQUEST',
  SET_DRIVER_ACCOUNT_SUCCESS: 'SET_DRIVER_ACCOUNT_SUCCESS',
  SET_DRIVER_ACCOUNT_ERROR: 'SET_DRIVER_ACCOUNT_ERROR',


  CHECK_INVITATION_KEY_REQUEST: 'CHECK_INVITATION_KEY_REQUEST',
  CHECK_INVITATION_KEY_SUCCESS: 'CHECK_INVITATION_KEY_SUCCESS',
  CHECK_INVITATION_KEY_ERROR: 'CHECK_INVITATION_KEY_ERROR',

  LOGIN_AS_DIFFERENT_ROLE_REQUEST: 'LOGIN_AS_DIFFERENT_ROLE_REQUEST',
  LOGIN_AS_DIFFERENT_ROLE_SUCCESS: 'LOGIN_AS_DIFFERENT_ROLE_SUCCESS',
  LOGIN_AS_DIFFERENT_ROLE_ERROR: 'LOGIN_AS_DIFFERENT_ROLE_ERROR',

  REFRESH_TOKEN_REQUEST: 'REFRESH_TOKEN_REQUEST',
  REFRESH_TOKEN_SUCCESS: 'REFRESH_TOKEN_SUCCESS',
  REFRESH_TOKEN_ERROR: 'REFRESH_TOKEN_ERROR',

  LOGOUT: 'LOGOUT',
  LOGOUT_REQUEST: 'LOGOUT_REQUEST',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_ERROR: 'LOGOUT_ERROR',

  SET_VIEW_DASHBOARD_MODE_ORGANIZATION_NAME: 'SET_VIEW_DASHBOARD_MODE_ORGANIZATION_NAME',

  USER_ROLE_HAS_BEEN_CHANGED: 'USER_ROLE_HAS_BEEN_CHANGED',

  LEAVE_VIEW_DASHBOARD_MODE_REQUEST: 'LEAVE_VIEW_DASHBOARD_MODE_REQUEST',
  LEAVE_VIEW_DASHBOARD_MODE_SUCCESS: 'LEAVE_VIEW_DASHBOARD_MODE_SUCCESS',
  LEAVE_VIEW_DASHBOARD_MODE_ERROR: 'LEAVE_VIEW_DASHBOARD_MODE_ERROR',

  CLEAR_VIEWDASHBOARD_INFO: 'CLEAR_VIEWDASHBOARD_INFO',
};

const initialState = {
  user: localStorage.getItem('user') && JSON.parse(localStorage.getItem('user')),
  access_token: null,
  role: localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'))?.role,
  viewDashboardMode: (sessionStorage.getItem('viewDashboardMode') &&
    JSON.parse(sessionStorage.getItem('viewDashboardMode'))) || {
    active: false,
    organizationName: '',
    user: null,
  },
  loading: false,
  userRoleWasChanged: false,
};

export const clearViewDashboardInfo = () => {
  sessionStorage.removeItem('viewDashboardMode');
  return {
    type: types.CLEAR_VIEWDASHBOARD_INFO,
  };
};

export const resetAuthorization = () => ({
  type: types.LOGOUT,
});

export const clearStoragesInfo = () => {
  localStore.remove('user');
};

export const changeUserRole = () => ({
  type: types.USER_ROLE_HAS_BEEN_CHANGED,
});

const setViewDashboardModeSetOrganizationName = (payload) => ({
  type: types.SET_VIEW_DASHBOARD_MODE_ORGANIZATION_NAME,
  payload,
});

const updatePersonalInformationRequest = () => ({
  type: types.UPDATE_PERSONAL_INFORMATION_REQUEST,
});

const loginRequest = () => ({
  type: types.LOGIN_REQUEST,
});

const logoutRequest = () => ({
  type: types.LOGOUT_REQUEST,
});

const loginAsDifferentRoleRequest = () => ({
  type: types.LOGIN_AS_DIFFERENT_ROLE_REQUEST,
});

const fetchUserDataRequest = () => ({
  type: types.FETCH_USER_DATA_REQUEST,
});

const setNewPasswordRequest = () => ({
  type: types.SET_NEW_PASSWORD_REQUEST,
});

const forgotPasswordRequest = () => ({
  type: types.FORGOT_PASSWORD_REQUEST,
});

const resendInviteRequest = () => ({
  type: types.RESEND_INVITE_REQUEST,
});

const changePasswordRequest = () => ({
  type: types.CHANGE_PASSWORD_REQUEST,
});

const changeEmailRequest = () => ({
  type: types.CHANGE_EMAIL_REQUEST,
});

const sendNewPasswordRequest = () => ({
  type: types.SEND_NEW_PASSWORD_REQUEST,
});

const setAccountRequest = () => ({
  type: types.SET_ACCOUNT_REQUEST,
});

const setDriverAccountRequest = () => ({
  type: types.SET_DRIVER_ACCOUNT_REQUEST,
});


const checkInvitationKeyRequest = () => ({
  type: types.CHECK_INVITATION_KEY_REQUEST,
});

const leaveViewDasboardRequest = () => ({
  type: types.LEAVE_VIEW_DASHBOARD_MODE_REQUEST,
});

export const leaveViewDashboardMode = (Navigate) => {
  const navigate = Navigate;
  const success = (payload) => {
    return {
      type: types.LEAVE_VIEW_DASHBOARD_MODE_SUCCESS,
      payload,
    };
  };

  const failure = (error) => {
    return {
      type: types.LEAVE_VIEW_DASHBOARD_MODE_ERROR,
      payload: error,
    };
  };

  return async (dispatch) => {
    dispatch(leaveViewDasboardRequest());

    return baseRequest('POST', '/auth/leave-dashboard', null)
      .then((response) => {
        const { role, ...user } = response.data?.user;
        localStorage.setItem('user', JSON.stringify(user));
        sessionStorage.removeItem('viewDashboardMode');
        if (response.data) dispatch(success(response.data));
        navigate.push(URLS.RIDES);
      })
      .catch((error) => {
        if (error) dispatch(failure(''));
      });
  };
};



export const loginUser = ({ email, password, Navigation }) => {
  const navigate = Navigation;
  const success = (data) => {
    const { user } = data;
    const { role, ...userWithoutRole } = user;

    localStorage.setItem('user', JSON.stringify(userWithoutRole));

    return {
      type: types.LOGIN_SUCCESS,
      payload: { data },
    };
  };

  const failure = (error) => {
    return {
      type: types.LOGIN_ERROR,
      payload: error,
    };
  };

  return async (dispatch, getStore) => {
    await dispatch(fetchCaptchaToken(RECAPTHA_ACTIONS.LOGIN));
    const recaptchaToken = getStore().common.recaptchaToken;
    dispatch(loginRequest());
    return baseRequest('POST', '/auth/login', { email, password }, { recaptchaToken })
      .then(async (response) => {
        if (response.data) {
          await dispatch(success(response.data));
          dispatch(fetchUserData(navigate));

        }
      })
      .catch((error) => {
        if (error) dispatch(failure(''));
      });
  };
};

export const fetchUserData = (Navigate) => {
  const navigate = Navigate;
  const success = (response) => {
    return {
      type: types.FETCH_USER_DATA_SUCCESS,
      payload: response,
    };
  };

  const failure = (error) => ({
    type: types.FETCH_USER_DATA_ERROR,
    payload: error,
  });

  return (dispatch, getStore) => {
    dispatch(fetchUserDataRequest());
    return baseRequest('GET', '/users/profile')
      .then((response) => {
        if (response?.data) {
          const { role, ...user } = response.data;
          localStore.set('user', JSON.stringify(user));
          
          dispatch(success(response.data));
           
          if([USER_ROLES.TC_ADMIN,USER_ROLES.TC_EMPLOYEE,USER_ROLES.DRIVER].includes(role))
          {
            dispatch(fetchTCConfiguration(user.organizationId));
          }

          if([USER_ROLES.HO_ADMIN,USER_ROLES.HO_EMPLOYEE].includes(role))
          {
          
            dispatch(fetchAddresses({id:user.organizationId}));
          }
          

          const socket = new Socket();
          socket.init();
          socket.connect();
          socket.subscribe('rides', (data) => {
            const currentRole = getStore()?.authorization?.role;
            const refreshNotificationVisible = getStore()?.rides?.showRefreshNotification;
            const rideId = getStore()?.rides?.ride?.id;
            const rides = getStore()?.rides?.rides;
            const showNotificationOnDashboard =
              data?.action === NOTIFICATIONS_ACTIONS.RIDE_CREATED ||
              currentRole === USER_ROLES.DRIVER ||
              rides.some((ride) => ride.id === data.rideId);

            const showRefreshNotification =
              (navigate?.location.pathname.includes(rideId) && rideId === data?.rideId) ||
              (navigate?.location.pathname === URLS.RIDES && showNotificationOnDashboard);

            if (!refreshNotificationVisible && showRefreshNotification) {
              dispatch(setShowRefreshNotification(true));
              showConfirmPopUp({
                description: 'This page has been modified, please refresh the page',
                duration: 0,
                refreshButton: true,
                refreshButtonAction: () => dispatch(setShowRefreshNotification(false)),
              });
            }
          });
          socket.subscribe('logout', (data) => {
           let token = sessionStorage.getItem('access_token') || localStore.get('access_token') || store.getState()?.authorization?.access_token
           if (data !== token) {
              dispatch(logOut());

            }


            socket?.unsubscribe('logout');

          })
        }
      })
      .catch((error) => {
        console.log('on socket error', error?.response?.data)
        if (error) dispatch(failure(error?.response?.data));
        dispatch(logOut());
      });
  };
};

export const setPassword = ({ values, key, Navigate }) => {
  const navigate = Navigate;
  const success = (payload) => {
    navigate.push(URLS.LOGIN);
    showNotificationMessage({
      type: 'success',
      content: renderRecordValue(payload),
    });

    return {
      type: types.SET_NEW_PASSWORD_SUCCESS,
      payload,
    };
  };

  const failure = (error) => ({
    type: types.SET_NEW_PASSWORD_ERROR,
    payload: error,
  });

  return async (dispatch, getStore) => {
    await dispatch(fetchCaptchaToken(RECAPTHA_ACTIONS.RESET_PASSWORD));
    const recaptchaToken = getStore().common.recaptchaToken;
    dispatch(setNewPasswordRequest());
    return baseRequest('POST', '/auth/new-password', values, { Authorization: key, recaptchaToken })
      .then(() => {
        dispatch(clearAuthInfo());
        dispatch(success('Your password has been updated successfully'));
      })
      .catch((error) => {
        if (error) dispatch(failure("Something went wrong. Can't update password"));
      });
  };
};

export const setAccount = ({ values, key, Navigation }) => {
  const navigate = Navigation;
  const success = (data) => {
    if (data) {
      const { role, ...user } = data?.user;
      localStore.set('user', JSON.stringify(user));
    }
    return {
      type: types.SET_ACCOUNT_SUCCESS,
      payload: data,
    };
  };

  const failure = (error) => ({
    type: types.SET_ACCOUNT_ERROR,
    payload: error,
  });

  return async (dispatch, getStore) => {
    await dispatch(fetchCaptchaToken(RECAPTHA_ACTIONS.SET_ACCOUNT));
    const recaptchaToken = getStore().common.recaptchaToken;
    dispatch(setAccountRequest());
    return baseRequest('POST', '/auth/set-account', values, { Authorization: key, recaptchaToken })
      .then((response) => {
        dispatch(clearAuthInfo());
        dispatch(success(response.data));
        navigate.push(URLS.RIDES);
      })
      .catch((error) => {
        if (error) {
          dispatch(failure(error?.response?.data?.message));
        }
      });
  };
};

export const setExternalDriverAcount = ({ key }) => {
  const success = (data) => {
    return {
      type: types.SET_DRIVER_ACCOUNT_SUCCESS,
      payload: data,
    };
  };
  const failure = (error) => ({
    type: types.SET_DRIVER_ACCOUNT_ERROR,
    payload: error,
  });

  return async (dispatch, getStore) => {
    await dispatch(fetchCaptchaToken(RECAPTHA_ACTIONS.SET_DRIVER_ACCOUNT));
    const recaptchaToken = getStore().common.recaptchaToken;
    dispatch(setDriverAccountRequest());
    return baseRequest('POST', '/auth/set-external-account', null, { Authorization: key, recaptchaToken })
      .then((response) => {
        // dispatch(clearAuthInfo());
        dispatch(success(response.data));
      })
      .catch((error) => {
        if (error) {
          dispatch(failure(error?.response?.data?.message));
        }
      });
  };
};



export const checkInvitationKey = ({ key, Navigation }) => {
  const navigate = Navigation;
  const success = () => ({
    type: types.CHECK_INVITATION_KEY_SUCCESS,
  });

  const failure = () => ({
    type: types.CHECK_INVITATION_KEY_ERROR,
  });

  return async (dispatch, getStore) => {
    await dispatch(fetchCaptchaToken(RECAPTHA_ACTIONS.SET_ACCOUNT));
    const recaptchaToken = getStore().common.recaptchaToken;

    dispatch(checkInvitationKeyRequest());
    return baseRequest('GET', `/auth/check-invitation-key?`, null, { recaptchaToken }, { key })
      .then(() => {
        dispatch(success());
      })
      .catch((error) => {
        if (error) {
          dispatch(failure());
          navigate.push(URLS.RESEND_INVITE, { key });
        }
      });
  };
};

export const forgotPassword = ({ email, Navigation }) => {
  const navigate = Navigation;
  const success = () => {
    return {
      type: types.FORGOT_PASSWORD_SUCCESS,
    };
  };

  const failure = (error) => ({
    type: types.FORGOT_PASSWORD_ERROR,
    payload: error,
  });

  return async (dispatch, getStore) => {
    await dispatch(fetchCaptchaToken(RECAPTHA_ACTIONS.FORGOT));
    const recaptchaToken = getStore().common.recaptchaToken;

    dispatch(forgotPasswordRequest());
    return baseRequest('POST', '/auth/reset-password', { email }, { recaptchaToken })
      .then((response) => {
        dispatch(clearAuthInfo());
        dispatch(success());
        showNotificationMessage({
          type: 'success',
          content: renderRecordValue(
            LodashHelper.isString(response?.data?.message) && response?.data?.message,
            'Email with instructions has been sent',
          ),
        });
        navigate.push(URLS.LOGIN);
      })
      .catch((error) => {
        if (error) dispatch(failure(error?.response?.data?.message));
      });
  };
};

export const resendInvite = ({ email, Navigation }) => {
  const navigate = Navigation;
  const success = () => {
    return {
      type: types.RESEND_INVITE_SUCCESS,
    };
  };

  const failure = (error) => ({
    type: types.RESEND_INVITE_ERROR,
    payload: error,
  });

  return async (dispatch, getStore) => {
    await dispatch(fetchCaptchaToken(RECAPTHA_ACTIONS.RESEND_INVITE));
    const recaptchaToken = getStore().common.recaptchaToken;
    dispatch(resendInviteRequest());
    return baseRequest(
      'POST',
      '/auth/resend-invitation',
      { email, key: history?.location?.state?.key },
      { recaptchaToken },
    )
      .then(() => {
        dispatch(success());
        showNotificationMessage({
          type: 'success',
          content: renderRecordValue('Invitation has been sent'),
        });
        navigate.push(URLS.LOGIN);
      })
      .catch((error) => {
        if (error) dispatch(failure('Email is incorrect. Please try again'));
      });
  };
};

export const sendNewPassword = (id) => {
  const success = () => {
    return {
      type: types.SEND_NEW_PASSWORD_SUCCESS,
    };
  };

  const failure = (error) => ({
    type: types.SEND_NEW_PASSWORD_ERROR,
    payload: error,
  });

  return async (dispatch, getStore) => {
    await dispatch(fetchCaptchaToken(RECAPTHA_ACTIONS.RESET_PASSWORD));
    const recaptchaToken = getStore().common.recaptchaToken;
    dispatch(sendNewPasswordRequest());
    return baseRequest('POST', `/auth/reset-password/${id}`, null, { recaptchaToken })
      .then(() => dispatch(success()))
      .catch((error) => {
        if (error) dispatch(failure(error?.response?.data));
      });
  };
};

export const resendInvitationForUser = (id) => {
  const success = () => ({ type: types.RESEND_INVITE_SUCCESS });
  const failure = (error) => ({ type: types.RESEND_INVITE_ERROR, payload: error });

  return async (dispatch, getStore) => {
    await dispatch(fetchCaptchaToken(RECAPTHA_ACTIONS.RESEND_INVITE));
    const recaptchaToken = getStore().common.recaptchaToken;
    dispatch(resendInviteRequest());
    return baseRequest('POST', `/auth/resend-invitation-for/${id}`, null, { recaptchaToken })
      .then(() => {
        dispatch(success());
        showNotificationMessage({
          type: 'success',
          content: renderRecordValue('Invitation has been sent'),
        });
      })
      .catch((error) => {
        if (error) dispatch(failure(error?.response?.data));
      });
  };
};

export const logOut = (Navigation) => {
  const navigate = Navigation;
  const success = () => {
    return {
      type: types.LOGOUT_SUCCESS,
    };
  };

  const failure = (error) => ({
    type: types.LOGOUT_ERROR,
    payload: error,
  });
  return (dispatch, getStore) => {
    const role = getStore()?.authorization.role;

    const currentLocation = navigate?.location;

    localStorage.removeItem('user');

    localStore.remove('user');

    dispatch(logoutRequest());
    dispatch({ type: types.LOGOUT });
    sessionStorage.removeItem('viewDashboardMode');

    navigate?.go(URLS.LOGIN, { from: currentLocation, role });


    if (!role) {
      return dispatch(clearStoreInfo());
    }

    return baseRequest('POST', `/auth/logout`, null)
      .then(() => {
        dispatch(clearStoreInfo());
        dispatch(success());

        navigate?.go(URLS.LOGIN, { from: currentLocation, role });
      })
      .catch((error) => {
        console.log('on Logout error', error?.response?.data)
        if (error) dispatch(failure(error?.response?.data));
        navigate?.reset(URLS.LOGIN, { from: currentLocation, role });
      });
  };
};

export const clearAuthInfo = () => (dispatch) => {
  clearStoragesInfo();
  dispatch({ type: types.LOGOUT });
  dispatch(clearStoreInfo());
};

export const changePassword = (payload) => {
  const success = () => {
    return {
      type: types.CHANGE_PASSWORD_SUCCESS,
    };
  };
  const failure = (error) => ({
    type: types.CHANGE_PASSWORD_ERROR,
    payload: error,
  });

  return (dispatch) => {
    dispatch(changePasswordRequest());
    return baseRequest('PUT', '/auth/change-password/request', payload)
      .then(() => {
        dispatch(success());
        return true;
      })
      .catch((error) => {
        if (error) dispatch(failure(error?.response?.data));
        return false;
      });
  };
};

export const changePasswordVerification = (payload) => {
  const success = () => {
    return {
      type: types.CHANGE_PASSWORD_SUCCESS,
    };
  };
  const failure = (error) => ({
    type: types.CHANGE_PASSWORD_ERROR,
    payload: error,
  });

  return (dispatch) => {
    dispatch(changePasswordRequest());
    return baseRequest('PUT', '/auth/change-password/verify', payload)
      .then(() => {
        dispatch(success());
        return true;
      })
      .catch((error) => {
        if (error) dispatch(failure(error?.response?.data));
        return false;
      });
  };
};

export const changeEmail = (payload) => {
  const success = () => {
    return {
      type: types.CHANGE_EMAIL_SUCCESS,
    };
  };

  const failure = (error) => ({
    type: types.CHANGE_EMAIL_ERROR,
    payload: error,
  });

  return (dispatch) => {
    dispatch(changeEmailRequest());
    return baseRequest('PUT', '/users/change-email', payload)
      .then(() => dispatch(success()))
      .catch((error) => {
        if (error) dispatch(failure(error?.response?.data));
      });
  };
};

export const updatePersonalInformation = (payload) => {
  const success = (payload) => {
    const { role, ...user } = payload;
    localStore.set('user', JSON.stringify(user));

    return {
      type: types.UPDATE_PERSONAL_INFORMATION_SUCCESS,
      payload,
    };
  };

  const failure = (error) => ({
    type: types.UPDATE_PERSONAL_INFORMATION_ERROR,
    payload: error,
  });

  return (dispatch) => {
    dispatch(updatePersonalInformationRequest());
    return baseRequest('PUT', '/users', payload)
      .then((response) => {
        dispatch(success(response.data));
      })
      .catch((error) => {
        if (error) dispatch(failure(error?.response?.data));
      });
  };
};

export const loginAsDifferentRole = ({ organizationName, organizationId, role, Navigation }) => {
  const navigate = Navigation
  const success = (payload) => {
    sessionStorage.setItem(
      'viewDashboardMode',
      JSON.stringify({
        active: true,
        organizationName,
      }),
    );

    localStorage.setItem('user', JSON.stringify(payload?.user));
    return {
      type: types.LOGIN_AS_DIFFERENT_ROLE_SUCCESS,
      payload,
    };
  };

  const failure = (error) => ({
    type: types.LOGIN_AS_DIFFERENT_ROLE_ERROR,
    payload: error,
  });

  return async (dispatch) => {
    dispatch(loginAsDifferentRoleRequest());
    return baseRequest('POST', '/auth/login-as', { organizationId, role })
      .then((response) => {
        const { accessToken, user } = response.data;
        const role = decodeToken(accessToken).onBehalfRole;

        dispatch(success({ user, accessToken, role }));
        dispatch(resetAllPagination());
        dispatch(setViewDashboardModeSetOrganizationName(organizationName));
        navigate.replace(URLS.RIDES);
      })
      .catch((error) => {
        if (error) dispatch(failure(error?.response?.data));
        navigate.push(URLS.RIDES);
      });
  };
};

export default function authorization(state = initialState, action) {
  switch (action.type) {
    case types.LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload.data.user,
        access_token: action.payload.data.accessToken,
        role: action.payload.data.user.role,
      };

    case types.LOGIN_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.FETCH_USER_DATA_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.FETCH_USER_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
        role: action.payload?.role,
      };

    case types.FETCH_USER_DATA_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.SET_NEW_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.SET_NEW_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        user: null,
        access_token: '',
        role: '',
      };

    case types.SET_NEW_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        user: null,
        access_token: '',
        role: '',
      };

    case types.CHECK_INVITATION_KEY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.CHECK_INVITATION_KEY_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case types.CHECK_INVITATION_KEY_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case types.CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.CHANGE_EMAIL_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.CHANGE_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case types.CHANGE_EMAIL_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.UPDATE_PERSONAL_INFORMATION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.UPDATE_PERSONAL_INFORMATION_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
      };

    case types.UPDATE_PERSONAL_INFORMATION_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.SEND_NEW_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.SEND_NEW_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case types.SEND_NEW_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.SET_ACCOUNT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.SET_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload.user,
        access_token: action.payload.accessToken,
        role: action.payload.user.role,
      };

    case types.SET_ACCOUNT_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.SET_DRIVER_ACCOUNT_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.SET_DRIVER_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case types.SET_DRIVER_ACCOUNT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.LOGOUT:
      return {
        ...state,
        loading: false,
        user: null,
        role: '',
        access_token: '',
        viewDashboardMode: {
          active: false,
          organizationName: '',
        },
      };

    case types.LOGOUT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.LOGOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        user: null,
        role: '',
        access_token: '',
      };

    case types.LOGOUT_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.LOGIN_AS_DIFFERENT_ROLE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.LOGIN_AS_DIFFERENT_ROLE_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload.user,
        access_token: action.payload.accessToken,
        role: action.payload.role,
      };
    case types.LOGIN_AS_DIFFERENT_ROLE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.REFRESH_TOKEN_REQUEST:
      return {
        ...state,
        loading: false,
      };

    case types.REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        loading: false,
        access_token: action.payload.accessToken,
        user: action.payload.user,
        role: action.payload.user.role,
      };

    case types.REFRESH_TOKEN_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.RESEND_INVITE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.RESEND_INVITE_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case types.RESEND_INVITE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.SET_VIEW_DASHBOARD_MODE_ORGANIZATION_NAME:
      return {
        ...state,
        viewDashboardMode: {
          active: true,
          organizationName: action.payload,
        },
      };

    case types.LEAVE_VIEW_DASHBOARD_MODE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.LEAVE_VIEW_DASHBOARD_MODE_SUCCESS:
      return {
        ...state,
        viewDashboardMode: {
          active: false,
          organizationName: '',
        },
        access_token: action.payload.accessToken,
        user: action.payload?.user,
        role: USER_ROLES.SUPER_ADMIN,
        loading: false,
      };

    case types.LEAVE_VIEW_DASHBOARD_MODE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.CLEAR_VIEWDASHBOARD_INFO:
      return {
        ...state,
        viewDashboardMode: {
          active: false,
          organizationName: '',
        },
      };

    case types.USER_ROLE_HAS_BEEN_CHANGED:
      return {
        ...state,
        userRoleWasChanged: true,
      };

    case commonTypes.CLEAR_STORE_INFO:
      return {
        ...state,
        user: null,
        role: '',
        access_token: '',
        userRoleWasChanged: false,
      };

    default:
      return state;
  }
}
