import React from 'react';
import { SyncOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { renderRecordValue } from '../../../utils';

import styles from './index.module.css';

export const RountTrip = ({ record }) => {
  const isFirstLeg = record?.leg === 'A';
  const isSecondLeg = record?.leg === 'B';
  return record?.leg ? (
    <Popover
      content={`This is the ${isFirstLeg ? 'first' : 'second'} leg of a roundtrip ride.`}
      placement='topLeft'
      overlayClassName={classNames({
        [styles.overlay]: true,
        [styles.firstLeg]: isFirstLeg,
        [styles.secondLeg]: isSecondLeg,
      })}
      getPopupContainer={(trigger) => trigger}>
      <div className={styles.leg}>
        <SyncOutlined className={styles.icon} />
        Leg #{renderRecordValue(record?.leg === 'A' ? '1' : '2')}
      </div>
    </Popover>
  ) : null;
};

RountTrip.propTypes = {
  record: PropTypes.object,
};
