import { Button, Modal } from '../..';
import styles from './index.module.css';

export const CancelCreateModal = ({ title, visible, onCancel, onLeave }) => (
  <Modal visible={visible} onCancel={onCancel} closable width={670} title={title}>
    <div className={styles.modalContent}>
      <p className={styles.modalDescription}>
        If you choose to leave this page, any filled information will not be saved
      </p>
      <div className={styles.modalBtns}>
        <div>
          <Button myClassName={styles.navigationBtn} onClick={onLeave}>
            Leave
          </Button>
        </div>
        <div>
          <Button myClassName={styles.navigationBtn} onClick={onCancel}>
            Stay
          </Button>
        </div>
      </div>
    </div>
  </Modal>
);
