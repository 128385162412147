import {
  RidesIcon,
  OrganizationsIcon,
  CompaniesIcon,
  UsersIcon,
  PreferencesIcon,
  DraftsIcon,
  RidersIcon,
} from '../../../assets';
import { BillingIcon } from '../../../assets/svgs/BillingIcon';
import { USER_ROLES, URLS } from '../../../constants/constants';

export const sidebarTabs = {
  [USER_ROLES.SUPER_ADMIN]: [
    { id: 1, path: URLS.RIDES, title: 'Rides', Icon: RidesIcon },
    {
      id: 2,
      path: URLS.HEALTH_ORGANIZATIONS,
      title: 'Health Organizations',
      Icon: OrganizationsIcon,
    },
    { id: 3, path: URLS.RIDERS, title: 'Riders', Icon: RidersIcon },
    { id: 4, path: URLS.TRANSPORT_ORGANIZATIONS, title: 'Transport Companies', Icon: CompaniesIcon },
    { id: 5, path: URLS.ADMINS, title: 'Admins', Icon: UsersIcon },
    { id: 6, path: URLS.PREFERENCES, title: 'Preferences', Icon: PreferencesIcon },
  ],
  [USER_ROLES.HO_ADMIN]: [
    { id: 1, path: URLS.RIDES, title: 'Rides', Icon: RidesIcon },
    { id: 2, path: URLS.DRAFTS, title: 'Ride Request Drafts', Icon: DraftsIcon },
    { id: 3, path: URLS.RIDERS, title: 'Riders', Icon: RidersIcon },
    { id: 4, path: URLS.USERS, title: 'Users', Icon: UsersIcon },
    { id: 5, path: URLS.PREFERENCES, title: 'Preferences', Icon: PreferencesIcon },
  ],
  [USER_ROLES.HO_EMPLOYEE]: [
    { id: 1, path: URLS.RIDES, title: 'Rides', Icon: RidesIcon },
    { id: 2, path: URLS.DRAFTS, title: 'Ride Request Drafts', Icon: DraftsIcon },
    { id: 3, path: URLS.RIDERS, title: 'Riders', Icon: RidersIcon },
    { id: 4, path: URLS.USERS, title: 'Users', Icon: UsersIcon },
    { id: 5, path: URLS.PREFERENCES, title: 'Preferences', Icon: PreferencesIcon },
  ],
  [USER_ROLES.TC_ADMIN]: [
    { id: 1, path: URLS.RIDES, title: 'Rides', Icon: RidesIcon },
    { id: 2, path: URLS.DRIVERS, title: 'Drivers', Icon: CompaniesIcon },
    { id: 3, path: URLS.USERS, title: 'Users', Icon: UsersIcon },
    {
      id: 4, path: URLS.BILLING, title: 'Billing', Icon: BillingIcon 
    },
    { id: 5, path: URLS.PREFERENCES, title: 'Preferences', Icon: PreferencesIcon },
  ],
  [USER_ROLES.TC_EMPLOYEE]: [
    { id: 1, path: URLS.RIDES, title: 'Rides', Icon: RidesIcon },
    { id: 2, path: URLS.DRIVERS, title: 'Drivers', Icon: CompaniesIcon },
    { id: 3, path: URLS.USERS, title: 'Users', Icon: UsersIcon },
    { id: 4, path: URLS.PREFERENCES, title: 'Preferences', Icon: PreferencesIcon },
  ],
};
