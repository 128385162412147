import React, { useState } from 'react';
import { Tooltip as AntdTooltip } from 'antd';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './index.module.css';

export const Tooltip = ({
  title,
  color = '#de7f39',
  isVisible = true,
  applyEditTooltipOverlayClassname,
  applyEditTooltipChildrenClassname = true,
  myClassName,
  children,
}) => {
  const [visibleTooltip, setVisibleTooltip] = useState(false);
  const handleVisibleChange = (visible) => isVisible && setVisibleTooltip(visible);

  return (
    <AntdTooltip
      title={title}
      color={color}
      overlayClassName={classNames({
        [styles.tooltipOverlay]: true,
        [styles.editTooltipOverlay]: applyEditTooltipOverlayClassname,
      })}
      open={visibleTooltip}
      onOpenChange={handleVisibleChange}>
      <span
        className={classNames({
          [styles.tooltipInfo]: applyEditTooltipChildrenClassname,
          [myClassName]: myClassName,
        })}>
        {children}
      </span>
    </AntdTooltip>
  );
};

Tooltip.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  color: PropTypes.string,
  myClassName: PropTypes.string,
};
