import React  from "react";
import Iframe from "react-iframe";
import styles from "./index.module.css";

export const FlightInformation = ({ flightInfo }) => {
   
 
    function handleIframe() {
 
    
   //var source = document.getElementsByTagName('html')[0].innerHTML;
   //var found = source.indexOf(/M21 16v-2l-8-5V3.5c0-.83-.67-1.5-1.5-1.5S10 2.67 10 3.5V9l-8 5v2l8-2.5V19l-2 1.5V22l3.5-1 3.5 1v-1.5L13 19v-5.5l8 2.5z/g);
 
};

  return (
    <div className={styles.iframe_wrapper} id="ifr">
      <Iframe
     
        id={flightInfo}
        width="640px"
        height="320px"
        display="block"
        position="relative"
        className={styles.iframe}
        onLoad={handleIframe}
        title="Flight Information"
        src={`https://www.google.com/search?igu=1&ei=&q=${flightInfo}`}
        scrolling="no"
      />
    </div>
  );
};
