import React from 'react';

import { Input } from '../../../formControls/Input/Input';
import { Select } from '../../../formControls/Select/Select';
import { MonthPicker } from '../MonthPicker/MonthPicker';
import { WeekRangePicker } from '../WeekRangePicker/WeekRangePicker';
import { RECURRING_FREQUENCY, DISABLED_FIELD_TOOLTIP_CONTENT } from '../../../../constants/constants';
import { LodashHelper } from '../../../../utils';
import { Tooltip } from '../../..';

import styles from './index.module.css';

export const CustomPicker = ({
  intervalName,
  frequencyName,
  recurringFrequencyOpts,
  values,
  monthPickerOptions,
  disabled,
  showDisabledTooltip,
}) => {
  const handleChangeFrequency = async ({ value, setValues, validateForm, values }) => {
    const unMutatedValues = LodashHelper.cloneDeep(values);
    switch (value) {
      case RECURRING_FREQUENCY.DAILY:
      case RECURRING_FREQUENCY.MONTHLY:
        delete unMutatedValues?.recurrenceRule?.byDays;
        delete unMutatedValues?.recurrenceRule?.weekIndex;
        delete unMutatedValues?.recurrenceRule?.monthlyType;

        await setValues({
          ...values,
          recurrenceRule: {
            ...unMutatedValues.recurrenceRule,
            frequency: value,
          },
        });
        break;

      case RECURRING_FREQUENCY.WEEKLY:
        delete values?.recurrenceRule?.weekIndex;
        delete unMutatedValues?.recurrenceRule?.monthlyType;

        await setValues({
          ...values,
          recurrenceRule: {
            ...unMutatedValues?.recurrenceRule,
            frequency: value,
            byDays: [],
          },
        });
        break;

      default:
        break;
    }
    await validateForm();
  };
  return (
    <>
      <span className={styles.required}> Repeat every</span>
      <div className={styles.customPicker}>
        <label className={styles.customRepeats}>
          <Tooltip
            title={<span>{DISABLED_FIELD_TOOLTIP_CONTENT}</span>}
            isVisible={showDisabledTooltip}
            applyEditTooltipOverlayClassname
            applyEditTooltipChildrenClassname={showDisabledTooltip}>
            <Input type='number' name={intervalName} placeholder='Interval' min={1} max={10} disabled={disabled} />
          </Tooltip>
        </label>
        <label className={styles.units}>
          <Tooltip
            title={<span>{DISABLED_FIELD_TOOLTIP_CONTENT}</span>}
            isVisible={showDisabledTooltip}
            applyEditTooltipOverlayClassname>
            <Select
              name={frequencyName}
              options={recurringFrequencyOpts}
              placeholder='Frequency'
              onChange={handleChangeFrequency}
              disabled={disabled}
            />
          </Tooltip>
        </label>
      </div>

      {values?.recurrenceRule?.frequency === RECURRING_FREQUENCY.WEEKLY && (
        <WeekRangePicker
          values={values}
          weeks={1}
          name='recurrenceRule.byDays'
          disabled={disabled}
          showDisabledTooltip={showDisabledTooltip}
        />
      )}
      {values?.recurrenceRule?.frequency === RECURRING_FREQUENCY.MONTHLY && (
        <MonthPicker
          options={monthPickerOptions}
          name='recurrenceRule.monthlyType'
          disabled={disabled}
          showDisabledTooltip={showDisabledTooltip}
        />
      )}
    </>
  );
};
