import React, { useMemo, useCallback, useEffect } from "react";
import { useParams } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { RideDetailsPage } from "../..";
import { hoCancelRide, tcAssignDriver } from "../../../store/rides";
import { RIDE_STATUSES } from "../../../constants/constants";
import {
  Button,
  useCancelRideByMHModal,
  useAssignTCByMHModal,
} from "../../../components";

import styles from "./index.module.css";
import { fetchTransportCompaniesMatchingForRide } from "../../../store/transport_organizations";
import { fetchDriversByTCMatchingForRide } from "../../../store/drivers";

const MH_RideDetails = ({
  ride,
  role,
  hoCancelRide,
  fetchTransportCompaniesMatchingForRide,
  transportOrganizations,
  fetchDriversByTCMatchingForRide,
  drivers,
  tcAssignDriver,
}) => {
  const { id } = useParams();

  const tcOptions = useMemo(
    () =>
      transportOrganizations?.map((tc) => ({ title: tc.name, value: tc.id })),
    [transportOrganizations]
  );

  const driversOptions = useMemo(
    () =>
      drivers?.map((driver) => ({
        title: `${driver.firstName} ${driver.lastName}`,
        value: driver.id,
        disabled: !driver.isEligible,
      })),
    [drivers]
  );

  useEffect(() => {
    id && fetchTransportCompaniesMatchingForRide(id);
  }, [id, fetchTransportCompaniesMatchingForRide]);

  useEffect(() => {
    if (ride?.transportCompanyId)
      fetchDriversByTCMatchingForRide({
        rideId: id,
        transportCompanyId: ride?.transportCompanyId,
      });
  }, [fetchDriversByTCMatchingForRide, id, ride?.transportCompanyId]);

  const cancelableRide = useMemo(
    () =>
      ride?.status === RIDE_STATUSES.PENDING ||
      ride?.status === RIDE_STATUSES.ACCEPTED,
    [ride]
  );

  const handleConfirmCancel = async (values) => {
    await hoCancelRide({
      rideId: id,
      payload: { ...values, connectedLegId: ride?.connectedLegId || null },
      userRole: role
    });
    setCancelModalVisible(false);
  };

  const handleConfirmAssigningRide = async (values) => {
    await tcAssignDriver({
      rideId: id,
      payload: {
        transportCompanyId: values.transportCompanyId,
        driver: values.tcDriverId,
        connectedLegId: ride?.connectedLegId || null,
      },
      role,
    });
    setModalAssignTCVisible(false);
  };

  const { Modal, setCancelModalVisible } = useCancelRideByMHModal({
    id: ride?.seqId,
    role,
    ride:ride,
    status:ride?.status,
    onConfirm: (values)=> handleConfirmCancel(values),
  });

  const { Modal: ModalAssignTC, setModalAssignTCVisible } =
    useAssignTCByMHModal({
      id: ride?.seqId,
      ride,
      tcOptions,
      rideCurrentValues: {
        transportCompanyId: ride?.transportCompanyId,
        tcDriverId: ride?.driver?.id,
      },
      driversOptions,
      role,
      onConfirm: (values) => handleConfirmAssigningRide(values),
      changeTC: fetchDriversByTCMatchingForRide,
    });

  const handleToggleModal = useCallback(
    (flag) => () => setCancelModalVisible(flag),
    [setCancelModalVisible]
  );
  const handleToggleAssignRideModal = useCallback(
    (flag) => () => setModalAssignTCVisible(flag),
    [setModalAssignTCVisible]
  );
  return (
    <>
      <RideDetailsPage showFullInfo />
      {cancelableRide && (
        <div className={styles.pageContent}>
          <div className={styles.cancelRideBtn}>
            <Button
              myClassName={styles.customBtn}
              onClick={handleToggleModal(true)}
            >
              Cancel Ride
            </Button>

            {ride?.status === RIDE_STATUSES.PENDING && (
              <Button
                myClassName={styles.customBtn}
                onClick={handleToggleAssignRideModal(true)}
              >
                Assign TC and Driver
              </Button>
            )}

            {(ride?.status === RIDE_STATUSES.ACCEPTED || ride?.status === RIDE_STATUSES.RESCHEDULED) && (
              <Button
                myClassName={styles.customBtn}
                onClick={handleToggleAssignRideModal(true)}
              >
                Update TC or Driver
              </Button>
            )}
          </div>
        </div>
      )}

      {Modal}
      {ModalAssignTC}
    </>
  );
};

const mapStateToProps = (state) => ({
  ride: state.rides.ride,
  role: state.authorization.role,
  transportOrganizations: state.transportOrganizations.transportOrganizations,
  drivers: state.drivers.drivers,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      hoCancelRide,
      fetchTransportCompaniesMatchingForRide,
      fetchDriversByTCMatchingForRide,
      tcAssignDriver,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(MH_RideDetails);
