import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { connect } from 'react-redux';

import { Input, Button, Logo, Loader } from '../../../components';
import { resetPasswordSchema } from '../../../utils';
import { getLoading } from '../../../store/selectors/common';

import styles from './index.module.css';
import { Navigation } from '../../../utils/navigation';

const SetPassword = ({ pageHeader, formLabel, submitButtonText, loading, handleSubmit }) => {
  const [queryKey, setQueryKey] = useState('');
  const navigate  = Navigation();
  useEffect(() => {
    if (window.location.search) {
      const urlParams = new URLSearchParams(window.location.search);
      const queryKey = urlParams.get('key');
      setQueryKey(queryKey);
    }
    return;
  }, []);

  return (
    <div className={styles.setPasswordPage}>
      <Logo lg />
      <div className={styles.head}>
        <h1 className={styles.pageHeader}>{pageHeader}</h1>
      </div>
      <p className={styles.pageDescription}>
        We require passwords with a minimum of 8 and up to 50 characters. We recommend using long and strong passwords
        with uppercase letters, lowercase letters, numbers, and special characters.
      </p>
      <Formik
        enableReinitialize
        validateOnMount
        initialValues={{ password: '' }}
        validationSchema={resetPasswordSchema}
        onSubmit={(values) => handleSubmit({ values, key: queryKey,Navigate:navigate })}>
        {({ handleSubmit, ...rest }) => {
          return (
            <form onSubmit={handleSubmit} className={styles.resetForm}>
              <label className={styles.passwordInput}>
                <span className={styles.required}>{formLabel}</span>
                <Input type='password' name='password' myClassName={styles.input} placeholder='Password' />
              </label>

              <div className={styles.button}>
                <Button type='submit' disabled={!rest.isValid}>
                  {submitButtonText}
                </Button>
              </div>
            </form>
          );
        }}
      </Formik>
      <Loader loading={loading} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: getLoading(state),
});

export default connect(mapStateToProps)(SetPassword);
