import { Input } from '../..';

import styles from './index.module.css';

export function renderTimeInputFields(fieldName) {
  return (
    <>
      <label className={styles.timeControl}>
        <label className={styles.timeInput}>
          <Input name={`${fieldName}.hours`} type='number' min={0} />
        </label>
        <span className={styles.measurement}>hour(s)</span>
      </label>
      <label className={styles.timeControl}>
        <label className={styles.timeInput}>
          <Input name={`${fieldName}.minutes`} type='number' min={0} max={59} />
        </label>
        <span className={styles.measurement}>minute(s)</span>
      </label>
    </>
  );
}
