import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { fetchAddresses, removeFromFavorite, updateAddressBookItem } from '../../../store/addressbook';
import styles from './index.module.css';
import { Button, Input as AntInput, List } from 'antd';
import { PopConfirm } from '../../../hocs/PopConfirm/PopConfirm';
import classNames from 'classnames';

import { connect } from 'formik';
import { bindActionCreators } from 'redux';
import { EditOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { DELETE_ADDRESSBOOK_ITEM_MESSAGE } from '../../../constants/constants';

export const AddressBookList = ({ form, field, dataSource, isAdmin, handleChangeAddress, handleAddressBookSelectAddress }) => {

  const user = useSelector((state) => state.authorization.user);
  const dispatch = useDispatch();
  const handleRemoveAddress = (id) => {
    dispatch(removeFromFavorite({ id: id, orgId: user.organizationId }));
  }
  const changeAddress = async (e, item) => {
    if (item && handleChangeAddress && handleAddressBookSelectAddress) {
      await handleChangeAddress({ value: item.googleAddressDetails.formattedAddress, values: form.values, setValues: form.setValues });
      await handleAddressBookSelectAddress({ value: item.googleAddressDetails, values: form.values, setValues: form.setValues });
      setTimeout(() => {
        form.validateField(field.name);
        form.setTouched({
          ...form.touched,
          [field.name]: true,
        });
      }, 1000);
    }
  }

  const updateTitle = (updatedAddress) => {
    const { id, nickName, address } = updatedAddress;
    dispatch(
      updateAddressBookItem({
        id: id,
        orgId: user.organizationId,
        payload: {
          organizationId: user.organizationId,
          nickName: nickName,
          googleAddressDetails: address.googleAddressDetails,
          latitude: address.googleAddressDetails?.coordinates?.lat,
          longitude: address.googleAddressDetails?.coordinates?.lng,
        },
      }),

    );
  };

  const [inputValue, setInputValue] = useState('');
  const handleChange = (e) => {
    setInputValue(e.target.value);
  };
  const openUpdatePopup = (item) => {
    setInputValue(item.nickName);
  }

  return (
    <List
      className={styles.addressBookList}
      itemLayout="horizontal"
      dataSource={dataSource}
      renderItem={(item) => (
        <List.Item
           actions={[<>
            {isAdmin && <div>
              { 
               <PopConfirm
                  title="Edit Location Name"
                  description={<AntInput placeholder="Location Name" value={inputValue} maxLength={12} onChange={handleChange} />}
                  onConfirm={() => {
                  //  if(inputValue && inputValue.trim() !='')
                    //{
                    updateTitle({ id: item?.id, nickName: inputValue, address: item.googleAddressDetails });
                     //}
                }
                  }
                // onCancel={()=>{    }} 
                >
                  <Button type="link" className={styles.linkbutton} onClick={() => openUpdatePopup(item)} block>
                    <div className={styles.editText}>Edit</div> <EditOutlined className={styles.editIcon} />
                  </Button>
                </PopConfirm>}
              {<PopConfirm
                title={DELETE_ADDRESSBOOK_ITEM_MESSAGE}
                overlayStyle={{
                  width: "30vw"
                }}
                description=""
                onConfirm={() => handleRemoveAddress(item?.id)}
              // onCancel={()=>{}} 
              >
                {/* Hide delete/Cross feature; maybe used for future */}
                <span className={classNames({ ["ant-tag-close-icon"]: true, [styles.closeIcon]: true })}  >
                  &times;
                </span>
              </PopConfirm>}</div>}</>
          ]}
        >


          <div className={styles.addressContanier}>
            <div>
              <Button
                className={styles.navigationBtn}
                type='button'
                onClick={(e) => { changeAddress(e, item) }}>
                {item.nickName}
              </Button>
            </div>
            <span className={styles.formatted_Address}>{item.googleAddressDetails?.formattedAddress}</span>
          </div>
        </List.Item>
      )}
    />
  );

}
 
AddressBookList.prototype = {
  dataSource: PropTypes.array,
  handleSelectedAddress: PropTypes.func
}

const mapStateToProps = (state) => ({
  dataSource: state.addressbook.addresses,

});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchAddresses,
      removeFromFavorite

    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AddressBookList);



