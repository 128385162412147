import React, { useEffect, useState } from 'react';
import classnames from 'classnames';

import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import { Field } from 'formik';

import { CustomInput } from '../Input/Input';
import { withTooltip } from '../../../hocs/withTooltip';

import styles from './index.module.css';
import classNames from 'classnames';
import { MarkFavoriteGoogleAddess } from '../../common/MarkFavoriteGoogleAddess/MarkFavoriteGoogleAddess';
import { AddressBookIcon } from '../../../assets/svgs/AddressBookIcon';
import { Button, Popover } from 'antd';
import { AddressBookList } from '../../common/AddressBookList/AddressBookList';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { addToAddressBook, removeFromFavorite } from '../../../store/addressbook';
import { USER_ROLES } from '../../../constants/constants';


const searchOptions = {

	componentRestrictions: { country: ['us'] },
};

const renderAutoCompleteSuggestions = ({
	field,
	form,
	value,
	disabled,
	getInputProps,
	suggestions,
	getSuggestionItemProps,
	loading,
	placeholder = 'Search Places ...',
	onBlur,
	myclassname,
}) => {
	return (
		<div
			className={classNames({
				[styles.autocomplete]: true,
				[myclassname]: myclassname,
			})}>
			<CustomInput
				field={field}
				form={form}
				value={value}
				disabled={disabled}
				myclassname={myclassname}
				handleBlurField={onBlur}
				{...getInputProps({
					field,
					form,
					name: field.name,
					placeholder,
					disabled,
					onBlur,
					className: 'location-search-input',
				})}
			/>

			<div className='autocomplete-dropdown-container'>
				{loading && <div>Loading...</div>}

				{suggestions.map((suggestion) => {
					const className = classnames({
						'suggestion-item': true,
						[styles.item]: true,
						'suggestion-item--active': suggestion.active,
						[styles.active]: suggestion.active,
					});

					return (
						<div
							{...getSuggestionItemProps(suggestion, {
								className,
							})}
							key={suggestion.description}>
							<span>{suggestion.description}</span>
						</div>
					);
				})}
			</div>
		</div>
	);
};

const CustomAutoComplete = ({
	form,
	field,
	value,
	handleSelectAddress,
	placeholder,
	onBlur,
	handleChangeAddress,
	disabled,
	myclassname,
	whenFieldDisabledShowTooltip,
}) => {

	const [gmapsLoaded, setGmapsLoaded] = useState(false);
	useEffect(() => {
		if (!window.google) {
			const gmapScriptEl = document.createElement(`script`);
			gmapScriptEl.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCAfWhnA04dtr1Cezw1AYkC8nj7wCds4SA&libraries=places&&language=en&callback=apiLoaded`;
			document
				.querySelector(`body`)
				.insertAdjacentElement(`beforeend`, gmapScriptEl);
			window.apiLoaded = () => setGmapsLoaded(true);
		} else {
			setGmapsLoaded(true);
		}
	}, []);
	const handleSelect = async (address) => {
		try {


			const results = await geocodeByAddress(address);


			if (results[0].formatted_address !== address) {
				results[0].facilityName = address;
			}


			await handleChangeAddress({ value: results[0].formatted_address, values: form.values, setValues: form.setValues });

			await handleSelectAddress({ value: results[0], values: form.values, setValues: form.setValues });

			setTimeout(() => {
				form.validateField(field.name);
				form.setTouched({
					...form.touched,
					[field.name]: true,
				});
			}, 1000);





		} catch (e) {

			return e;
		}
	};

	const handleChange = (value) => {
		handleChangeAddress({ value, values: form.values, setValues: form.setValues });
	};

	return <>
		{gmapsLoaded && withTooltip(
			<PlacesAutocomplete
				value={value || ''}
				onChange={handleChange}
				onSelect={handleSelect}
				onKeyDown={handleSelect}
				debounce={1000}
				disabled={disabled}
				searchOptions={searchOptions}>
				{({ getInputProps, suggestions, getSuggestionItemProps, loading, ...rest }) => {
					return renderAutoCompleteSuggestions({
						form,
						field,
						value,
						disabled,
						getInputProps,
						suggestions,
						getSuggestionItemProps,
						loading,
						placeholder,
						myclassname,
						onBlur,
					});
				}}
			</PlacesAutocomplete>,
			whenFieldDisabledShowTooltip,
		)}</>
};

export const GooglePlacesAutoComplete = ({
	name,
	myclassname,
	value,
	handleSelectAddress,
	placeholder,
	handleChangeAddress,
	handleAddressBookSelectAddress,
	onBlur,
	disabled,
	whenFieldDisabledShowTooltip,
	showFavoriteIcon = false,
    // isFavoriteAddress = false,
	currentAddress,
	currentAddressObj,
	items
}) => {
	 
	 
    const user = useSelector((state) => state.authorization.user);
	const role = useSelector((state) => state.authorization.role);
	const addressList = useSelector((state) => state.addressBook.addresses);
	const dispatch = useDispatch();
 
	 
  
	const addFavoriteAddress = (address) => {
		// console.log("user",user);
		// console.log(address);
	 
		dispatch(addToAddressBook({orgId:user.organizationId,payload:{organizationId:user.organizationId,nickName:address.title,googleAddressDetails:address.location,latitude:address.location?.coordinates?.lat,longitude:address.location?.coordinates?.lng}}));
	
	}

	const removeFavAddress =(id)=>
		{
 

dispatch(removeFromFavorite({id:id,orgId:user.organizationId}));
		}
 

	const [open, setOpen] = useState(false);

	const hide = () => {
		setOpen(false);
	};

	const handleOpenChange = (newOpen) => {
		setOpen(newOpen);
	};

	return (
		<div className={classNames({ [styles.container]: showFavoriteIcon, })}>
			<Field
				name={name}
				value={value}
				handleSelectAddress={handleSelectAddress}
				placeholder={placeholder}
				myclassname={myclassname}>
				{({ field, form }) => {
					//  form && setForm(form);   

					return (
						<>
						<div className={styles.favAddressWrapper} >
							<CustomAutoComplete
								form={form}
								field={field}
								name={field.name}
								handleSelectAddress={handleSelectAddress}
								onKeyDown={handleSelectAddress}
								handleChangeAddress={handleChangeAddress}
								value={value}
								placeholder={placeholder}
								myclassname={myclassname}
								onBlur={onBlur}
								disabled={disabled}
								whenFieldDisabledShowTooltip={whenFieldDisabledShowTooltip}
							/>
<div className={styles.addressBookButtons} >
							{showFavoriteIcon && [USER_ROLES.HO_ADMIN,USER_ROLES.SUPER_ADMIN].includes(role) && !disabled  && 
								<div className={styles.favButton}>
									<MarkFavoriteGoogleAddess

										form={form}
										field={field}
										name={field.name}
										currentAddressObj={currentAddressObj}

										insertFavoriteAddress={addFavoriteAddress}
										handleRemoveAddress={removeFavAddress}
										favAddressIndex={currentAddressObj?.id}
										 
									/>
								</div>

							}
							{!disabled && addressList?.length>0 &&  
								<Popover
									content={<AddressBookList dataSource={addressList} isAdmin={[USER_ROLES.HO_ADMIN,USER_ROLES.SUPER_ADMIN].includes(role)} handleSelectAddress={handleSelectAddress}
									field={field}
									handleAddressBookSelectAddress={handleAddressBookSelectAddress}
									name={field.name}
									handleChangeAddress={handleChangeAddress}	form={form}     />}
									title="Saved Locations"
									trigger="click"
									open={open}
									onOpenChange={handleOpenChange}
								>
									<Button className={styles.addressBookButton}>
										<AddressBookIcon className={styles.backIcon} fill='#DE7F39' />
									</Button>
								</Popover>

							 }
							</div>
							</div>
						</>
					);
				}}
			</Field>

		</div>
	);
};
