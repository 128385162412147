import React from 'react';

import { FilterCheckboxListCell, FilterRangeCell, FilterSearchCell } from '../../../components';
import { vehicleOptionsDictionary } from '../../../dictionaries';
import { VEHICLE_OPTIONS } from '../../../constants/constants';
import { FilterAutoCompleteCell } from '../../../components/common/FilterPanel/FiltersCells/FilterAutoCompleteCell/FilterAutoCompleteCell';

import styles from './index.module.css';

export const renderHeadCells = ({ values, setFieldValue, onClose }) => {
  return [
    {
      title: 'Draft ID',
      dataIndex: 'seqId',
      key: 'seqId',
      width: 120,
      Component: (
        <FilterSearchCell
          name='seqId'
          placeholder='Enter Draft ID'
          title='Draft ID'
          values={values}
          setFieldValue={setFieldValue}
          myClassName={styles.draftIdCell}
          onClose={onClose}
          shouldRenderSorting={false}
        />
      ),
    },

    {
      title: 'Rider Name',
      dataIndex: 'rider',
      key: 'rider',
      width: 220,
      Component: (
        <FilterAutoCompleteCell
          formattedName='formattedRiderName'
          name='rider'
          title='Rider Name'
          placeholder='Enter Rider'
          values={values}
          setFieldValue={setFieldValue}
          myClassName={styles.riderCell}
          onClose={onClose}
          shouldRenderSorting={false}
        />
      ),
    },
    {
      title: 'Vehicle Type',
      dataIndex: 'vehicleType',
      key: 'vehicleType',
      width: 300,
      Component: (
        <FilterCheckboxListCell
          name='vehicleType'
          title='Vehicle Type'
          values={values}
          myClassName={styles.vehicleCell}
          value={vehicleOptionsDictionary?.[values?.vehicleType?.[0]]}
          setFieldValue={setFieldValue}
          options={VEHICLE_OPTIONS}
          onClose={onClose}
          shouldRenderSorting={false}
        />
      ),
    },
    {
      title: 'Pickup Date',
      dataIndex: 'pickUpDate',
      key: 'pickUpDate',
      width: 240,
      Component: (
        <FilterRangeCell
          name='pickUpDate'
          title='Pickup Date'
          values={values}
          setFieldValue={setFieldValue}
          myClassName={styles.pickUpDateCell}
          onClose={onClose}
          shouldRenderSorting={false}
        />
      ),
    },

    {
      title: 'Date Created',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 240,
      Component: (
        <FilterRangeCell
          name='createdAt'
          title='Date Created'
          values={values}
          setFieldValue={setFieldValue}
          myClassName={styles.createdAtCell}
          onClose={onClose}
          shouldRenderSorting={false}
        />
      ),
    },
    {
      title: 'Created By',
      dataIndex: 'createdBy',
      key: 'createdBy',
      width: 180,
      Component: (
        <FilterSearchCell
          name='createdBy'
          title='Created By'
          placeholder='Enter Created By'
          values={values}
          setFieldValue={setFieldValue}
          myClassName={styles.createdByCell}
          onClose={onClose}
          shouldRenderSorting={false}
        />
      ),
    },
    {
      title: '',
      Component: (
        <th>
          <div className={styles.cellValue} />
        </th>
      ),
      dataIndex: 'actions',
      key: 'actions',
    },
  ];
};
