import React, { useCallback, useMemo } from "react";

import { FieldArray } from "formik";

import { getWheelchairQuestions } from "../../mock";
import { Select, Snackbar, renderAdditionalRideOptions, Input } from "../../..";
import {
  VEHICLE_OPTIONS,
  VEHICLE_TYPES,
  REQUIRED_ADDITIONAL_OPTION_FOR_VEHICLE_TYPE_OVER_250_LBS,
  VEHICLE_TYPES_BARIATRIC_HEIGHT,
  VEHICLE_TYPES_BARIATRIC,
} from "../../../../constants/constants";
import { VehicleQuestion } from "./VehicleQuestion";

import styles from "./index.module.css";
import RenderHeightInputFields from "../../../formControls/Input/HeightInputFields";

const renderVehicleQuestions = ({
  questions,
  disabledValues,
  handleWheelchairQuestion,
}) =>
  questions.map((question) => (
    <VehicleQuestion
      key={question.name}
      question={question}
      disabledValues={disabledValues}
      onChange={handleWheelchairQuestion}
    />
  ));

const getWarningMessageByMinTime = (minTime, chosenVehicleType) => {
  if (
    minTime &&
    chosenVehicleType &&
    minTime[chosenVehicleType] &&
    minTime[chosenVehicleType].hours > 0
  ) {
    return `This ride type will take on average a minimum of ${
      minTime?.[chosenVehicleType]?.hours + " hour(s)"
    } ${
      minTime?.[chosenVehicleType]?.minutes
    } minute(s) for the rider to be picked up.`;
  } else if (minTime && chosenVehicleType && minTime[chosenVehicleType])
    return `This ride type will take on average a minimum of  ${minTime?.[chosenVehicleType]?.minutes} minute(s) for the rider to be picked up.`;
  return "";
};

export const StepTwoFormFields = ({
  values,
  rideOptions = [],
  minTimeFromRideRequestToPickup,
  disabledValues,
}) => {
  const vehicleQuestions = useMemo(
    () =>
      getWheelchairQuestions({
        vehicleType: values?.vehicleType,
        needsCarSeat: values?.needsCarSeat,
      }),
    [values?.vehicleType, values?.needsCarSeat]
  );
  const warningMessage = getWarningMessageByMinTime(
    minTimeFromRideRequestToPickup,
    values?.vehicleType
  );

  const handleVehicleType = ({ name, value, setValues, values }) => {
    const copiedValues = { ...values };
    const overLbsOption = rideOptions.find(
      (option) =>
        option.value ===
        REQUIRED_ADDITIONAL_OPTION_FOR_VEHICLE_TYPE_OVER_250_LBS
    );
    const optionsWithoutOverLbs =
      copiedValues?.rideOptions?.filter(
        (option) => option.id !== overLbsOption?.id
      ) || [];
    const optionsWithOverLbs = overLbsOption
      ? [...optionsWithoutOverLbs, overLbsOption]
      : copiedValues?.rideOptions;

    switch (value) {
      case VEHICLE_TYPES.WHEEL_CHAIR_BOUND_BARIATRIC:
      case VEHICLE_TYPES.STRETCHER_BOUND_BARIATRIC:
        return setValues({
          ...copiedValues,
          rideOptions: optionsWithOverLbs,
          weight: values?.weight,
          [name]: value,
        });

      case VEHICLE_TYPES.WHEEL_CHAIR_BOUND_NON_BARIATRIC:
        return setValues({
          ...copiedValues,
          rideOptions: optionsWithoutOverLbs,
          weight: null,
          [name]: value,
        });

      default:
        delete copiedValues.isWheelchairNeeded;
        delete copiedValues.hasMotorizedWheelchair;
        setValues({
          ...copiedValues,
          rideOptions: optionsWithoutOverLbs,
          [name]: value,
          weight: values?.weight,
        });
    }
  };

  const handleWheelchairQuestion = useCallback(
    async ({ event, setFieldValue }) => {
      if (event.target.name === "needsCarSeat" && !event.target.value) {
        await setFieldValue("needsAdditionalCarSeat", undefined);
        await setFieldValue("needsBoosterSeat", undefined);
      }
      await setFieldValue(event.target.name, event.target.value);
    },
    []
  );
  return (
    <>
      <div className={styles.vehicleType}>
        <span className={styles.required}>Vehicle Type</span>
        <Select
          name="vehicleType"
          options={VEHICLE_OPTIONS}
          onChange={handleVehicleType}
          placeholder={"Pick one"}
          value={values?.vehicleType}
          disabled={disabledValues?.vehicleInformation}
          whenFieldDisabledShowTooltip={Boolean(
            disabledValues?.vehicleInformation
          )}
        />
      </div>
      {values?.vehicleType &&
        minTimeFromRideRequestToPickup &&
        minTimeFromRideRequestToPickup[values?.vehicleType] && (
          <div className={styles.snackbarField}>
            <Snackbar type="success" content={warningMessage} />
          </div>
        )}

      {VEHICLE_TYPES_BARIATRIC.includes(values?.vehicleType) ? (
        <div className={styles.measurement}>
          <label className={styles.numberField}>
            <span className={styles.required}>Weight, lb(s)</span> 
            <label>
              <Input
                name="weight"
                value={values?.weight}
                type="number"
                min={250}
                max={999}
                placeholder="Weight"
              />
            </label>
          </label>
        </div>
      ) : (
        <></>
      )}

      {VEHICLE_TYPES_BARIATRIC_HEIGHT.includes(values?.vehicleType) ? (
        <div className={styles.measurement}>
          <RenderHeightInputFields fieldName="height" values={values?.height} />
        </div>
      ) : (
        <></>
      )}

      {values?.vehicleType &&
        renderVehicleQuestions({
          questions: vehicleQuestions,
          handleWheelchairQuestion,
          disabledValues,
        })}
      {rideOptions?.length > 0 && (
        <div className={styles.additionalOptions}>
          <span className={styles.additionalOptionsHead}>
            Additional Rider Transportation Needs
          </span>
          <div className={styles.checkboxesRow}>
            <FieldArray
              name="rideOptions"
              render={renderAdditionalRideOptions({
                renderedValues: rideOptions,
                values: values,
                name: "rideOptions",
                disabled: disabledValues?.vehicleInformation,
              })}
            />
          </div>
        </div>
      )}
    </>
  );
};
