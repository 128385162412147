import React from 'react';
import PropTypes from 'prop-types';

import styles from '../index.module.css';

const renderAdditionalRideRequestOpts = (options) =>
  options?.length > 0 && (
    <ul className={styles.additionalOptions}>
      {options.map((option) => (
        <li key={option.value || option}>{option.value}</li>
      ))}
    </ul>
  );

const rendervehicleInformationRadioOpts = (options) => (
  <ul className={styles.additionalOptions}>
    {options
      .filter((option) => option.value)
      .map((option) => (
        <li key={option.name}>{option.name}</li>
      ))}
  </ul>
);

export const AdditionalOptions = ({ rideOptions, vehicleInformationRadioOpts }) =>
  (rideOptions.length > 0 || vehicleInformationRadioOpts?.some((o) => o.value)) && (
    <>
      <p className={styles.recordTitle}>Additional Rider Transportation Needs:</p>
      {renderAdditionalRideRequestOpts(rideOptions)}
      {rendervehicleInformationRadioOpts(vehicleInformationRadioOpts)}
    </>
  );

AdditionalOptions.propTypes = {
  rideOptions: PropTypes.array,
  vehicleInformationRadioOpts: PropTypes.array,
};
