import React, { useEffect, useMemo } from "react";
import { connect, useDispatch } from "react-redux";
import { Routes, Route } from "react-router-dom";

import {
  ForgotPassword,
  ResendInvite,
  Login,
  SetNewPassword,
  SetAccount,
  SetDriverAccount,
  ViewDashboard,
  Survey,
  NotFound,
  CancelRideByRider,
  VerifyUserPage,
  RideNotFound,
  SetExternalDriver,
} from "../pages";
import {
  AuthorizedRoute,
  LodashHelper,
  PrivateRoute,
  ByPassAuthorization,
} from "../utils";
import { Layout } from "../components/";
import { routesList } from "./";
import { URLS, USER_ROLES } from "../constants/constants";

import { loadCaptcha } from "../store/common";
import ReCaptcha from "../utils/ReCaptcha";
import { isTheSameUser } from "../utils/helpers";
import { clearViewDashboardInfo } from "../store/authorization";
import { Navigation } from "../utils/navigation";

const renderRoutes = ({ user, role, access_token }) => {
   
  if (
    USER_ROLES[role] &&
    access_token &&
    isTheSameUser(user, JSON.parse(localStorage.getItem("user")))
  ) {
    
    return (
      <Route element={<Layout role={role} user={user}></Layout>}>
        {routesList[role]
          ? routesList[role].map((route, index) => {
             
              return (
                <Route
                  key={route.id + index}
                  user={user}
                  role={role}
                  exact={route.exact}
                  path={route.path}
                  element={
                    <PrivateRoute user={user}   role={role}>
                      <route.component   user={user}   role={role} />
                    </PrivateRoute>
                  }
                />
              );
            })
          : []}
      </Route>
    );
  }
  return [
    <Route
    exact 
      path="*"
      key={11111111}
      user={user}
      element={  
      <VerifyUserPage user={user}/>  }
    />,
    <Route
      exact
      key={2}
      user={user}
      role={role}
      element={
        <AuthorizedRoute user={user} role={role}>
       <VerifyUserPage user={user}/> 
        </AuthorizedRoute>
      }
    />,

    <Route
      exact
      path={URLS.HOME} 
      key={3}
      user={user}
      role={role}
      element={
        <AuthorizedRoute  user={user} role={role}>
          <Login />
        </AuthorizedRoute>
      }
    />,

  ];
};

const MDRoutes = ({
  role,
  captchaLoaded,
  user,
  access_token,
  viewDashboardMode,
}) => {
  const dispatch = useDispatch();
  const navigate = Navigation();

  useEffect(() => {
    dispatch(loadCaptcha(true));
    return;
  }, [dispatch]);

  useEffect(() => {
    if (captchaLoaded && !LodashHelper.isEmpty(user))
      ReCaptcha.toggleCaptchaBadge(false);
    if (LodashHelper.isEmpty(user)) ReCaptcha.toggleCaptchaBadge(true);

    return;
  }, [captchaLoaded, user]);

  useEffect(() => {
    if (role === USER_ROLES.SUPER_ADMIN && viewDashboardMode.active) {
      dispatch(clearViewDashboardInfo());
    }
  }, [role, viewDashboardMode]);

  const routes = useMemo(() => {
    return (
      <Routes>
        <Route path="/" exact element={   <AuthorizedRoute user={user}   role={role}>
              <Login />
            </AuthorizedRoute>}></Route>

        <Route
          path={URLS.LOGIN}
          exact
          user={user}
          role={role}
          element={
            <AuthorizedRoute user={user} role={role}>
              <Login />
            </AuthorizedRoute>
          }
        ></Route>

        <Route
          path={URLS.FORGOT}
          exact
          user={user}
          role={role}
          element={
            <AuthorizedRoute user={user} role={role}>
              <ForgotPassword user={user} role={role} />
            </AuthorizedRoute>
          }
        ></Route>
        <Route
          path={URLS.RESEND_INVITE}
          exact
          user={user}
          role={role}
          element={
            <AuthorizedRoute user={user} role={role}>
              <ResendInvite user={user} role={role} />
            </AuthorizedRoute>
          }
        ></Route>

        <Route
          path={URLS.RESET_PASSWORD}
          exact
          user={user}
          role={role}
          element={
            <AuthorizedRoute user={user} role={role}>
              <SetNewPassword user={user} role={role} />
            </AuthorizedRoute>
          }
        ></Route>

        <Route
          path={URLS.RESET_PASSWORD}
          exact
          user={user}
          role={role}
          element={
            <ByPassAuthorization user={user} role={role}>
              <SetNewPassword user={user} role={role} />
            </ByPassAuthorization>
          }
        ></Route>
        <Route
          path={URLS.SET_ACCOUNT}
          exact
          user={user}
          role={role}
          element={
            <ByPassAuthorization user={user} role={role}>
              <SetAccount user={user} role={role} />
            </ByPassAuthorization>
          }
        ></Route>
        <Route
          path={URLS.SET_DRIVER_ACCOUNT}
          exact
          user={user}
          role={role}
          element={
            <ByPassAuthorization user={user} role={role}>
              <SetDriverAccount user={user} role={role} />
            </ByPassAuthorization>
          }
        ></Route>

        <Route key={17} path={URLS.VIEW_DASHBOARD} element={<ViewDashboard/>} />
        <Route key={18} path={URLS.FEEDBACK} exact element={<Survey/>} />
        <Route key={19} path={URLS.NOT_FOUND} exact element={<NotFound navigate={navigate}/>} />
        <Route
          key={20}
          path={URLS.RIDE_NOT_FOUND}
          exact
          element={<RideNotFound/>}
        />
        <Route
          path={URLS.SET_EXTERNAL_DRIVER}
          exact
          user={user}
          role={role}
          element={
            <ByPassAuthorization user={user} role={role}>
              <SetExternalDriver user={user} role={role} />
            </ByPassAuthorization>
          }
        />
        <Route
        key={22}
          path={URLS.CANCEL_RIDE_BY_RIDER}
          exact
          element={<CancelRideByRider/>}
        />
        {renderRoutes({ user, role, access_token  })}
      </Routes>
    );
  }, [user, access_token, role,navigate]);
 
  return routes;
};

const mapStateToProps = (state) => ({
  role: state.authorization.role || state?.authorization?.user?.role || "",
  access_token: state.authorization.access_token,
  user: state?.authorization?.user || {},
  captchaLoaded: state.common.captchaLoaded,
  viewDashboardMode: state.authorization.viewDashboardMode,
});

export default connect(mapStateToProps)(MDRoutes);
