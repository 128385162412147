import { Button as AndtButton } from 'antd';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import styles from './index.module.css';

export const Button = ({ children, type, onClick, disabled, myClassName }) => {
  return (
    <AndtButton
      role='button'
      htmlType={type}
      onClick={onClick}
      className={classnames({
        [styles.btn]: true,
        [styles.customBtn]: true,
        [myClassName]: myClassName,
      })}
      disabled={disabled}>
      {children}
    </AndtButton>
  );
};

Button.propTypes = {
  type: PropTypes.string,
  myClassName: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  children: PropTypes.node,
};
