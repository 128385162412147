import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Formik } from 'formik';
import { useParams } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classnames from 'classnames';
import { InfoOutlined } from '@ant-design/icons';
import { NavLink } from 'react-router-dom';

import { RideDetailsPage } from '../../';
import {
  fetchMathcedDriversForRide,
  tcAcceptRide,
  tcAssignDriver,
  tcAcceptRescheduleRide,
  tcDeclineRide,
  tcCancelRide,
  startRide,
  editStartTime,
  endRide,
  driverArrival,
} from '../../../store/rides';
import { Button, Select, Snackbar, Tooltip } from '../../../components';
import { CANCEL_RIDE_OPTIONS, RIDE_STATUSES, URLS } from '../../../constants/constants';
import { LodashHelper } from '../../../utils';
import { DeclineRideModal } from './DeclineRideModal';
import { CancelRideModal } from './CancelRideModal';
import { RideStatusModal, RIDE_STATUS_MODALS_TYPES } from './RideStatusModal';
import { statusesDictionary } from '../../../dictionaries';
import { useAcceptRideModal } from '../../../components/modals/AcceptRideModal/useAcceptRideModal';

import styles from './index.module.css';
import { useConfirmRoundTripRideModal } from '../../../components/modals/ConfirmRoundTripModal/useConfirmRoundTripRideModal';

const transformValue = (values) =>
  values.map((v) => ({ value: v.id, title: `${v.firstName} ${v.lastName}`, disabled: !v.isEligible }));

export const TC_RideDetails = ({
  startRide,
  editStartTime,
  endRide,
  driverArrival,
  fetchMathcedDriversForRide,
  tcAcceptRescheduleRide,
  tcAcceptRide,
  tcAssignDriver,
  tcDeclineRide,
  tcCancelRide,
  matchedDrivers,
  ride,
  role,
  tcConfiguration
}) => {
  const [showDeclineModal, setShowDeclineModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showStatusRideModal, setShowStatusRideModal] = useState(false);
  const [rideStatusModalType, setRideStatusModalType] = useState('');
  const [isAccepted, setIsAccepted] = useState(false);
  const [isRescheduled, setIsRescheduled] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [showDriverForm, setShowDriverForm] = useState(false);
  const rideIsPending = useMemo(() => ride && ride?.status === RIDE_STATUSES.PENDING, [ride]);
  const { id } = useParams();
  const msgRef = useRef();
  const driverFormREf = useRef();

  const {
    Modal: AcceptRideModal,
    setAcceptlModalVisible,
    acceptModalVisible,
  } = useAcceptRideModal({
    id: ride?.seqId,
    onConfirm: (values) => {
      tcAssignDriver({
        rideId: id,
        payload: {
          driver: driverFormREf.current?.values?.driver,
          estimatedPickUpDate: values?.datetime,
        },
        role
      });
      setAcceptlModalVisible(false);
    },
    ride,
  });

  const {
    Modal: ConfirmRoundTripRideModal,
    setConfirmRoundTripRideModalVisible,
    ConfirmRoundTripRideModalVisible,
  } = useConfirmRoundTripRideModal({
    ride: ride,
    onConfirm: (values) => {
      if(!isRescheduled)
      { 
        tcAssignDriver({
        rideId: id,
        payload: {
          driver: driverFormREf.current?.values?.driver,
          // estimatedPickUpDate: values?.datetime,
          connectedLegId: ride?.connectedLegId
        },
        role
      });
    }else
    {
      tcAcceptRescheduleRide({id:id,payload: { driver :ride.driver,connectedLegId:ride?.connectedLegId }})
 
    }
      setConfirmRoundTripRideModalVisible(false);
    },

  });

  useEffect(() => {
    ride && showDriverForm && fetchMathcedDriversForRide(id);
    return;
  }, [fetchMathcedDriversForRide, id, ride, showDriverForm]);

  useEffect(() => {
    if (ride) {
      setIsAccepted(
        ride?.readableStatus === statusesDictionary[RIDE_STATUSES.ACCEPTED] ||
        ride?.readableStatus === statusesDictionary[RIDE_STATUSES.IN_PROGRESS] ||
        ride?.readableStatus === statusesDictionary[RIDE_STATUSES.COMPLETED],
      );
    }
    return;
  }, [ride]);


  useEffect(() => {
    if (ride) {
      setIsRescheduled(false);
        //
        // {/* ToDo Rescheudleing */}
        //  ride?.readableStatus === statusesDictionary[RIDE_STATUSES.RESCHEDULED]  );
    }
    return;
  }, [ride]);
  useEffect(() => {
    if (ride) {
      setShowDriverForm(
        ride?.readableStatus === statusesDictionary[RIDE_STATUSES.PENDING] ||
        ride?.readableStatus === statusesDictionary[RIDE_STATUSES.NEW_REQUEST] ||
        ride?.readableStatus === statusesDictionary[RIDE_STATUSES.ACCEPTED]  );
    }
    return;
  }, [ride]);

  const handleAcceptRide = () => tcAcceptRide(id);

  const handleAssignDriver = (driver) => () => {

   

    if (ride?.connectedLeg) {
      return setConfirmRoundTripRideModalVisible(true);
    }
    if (ride?.isPickUpDateFlexible && !ride.driver) {
      return setAcceptlModalVisible(true);
    }
    tcAssignDriver({ rideId: id, payload: { driver }, role });
  };


  
  const handleAcceptRescheduleRide = (driver) => () => {

   

    if (ride?.connectedLeg) {
      return setConfirmRoundTripRideModalVisible(true);
    }
    // if (ride?.isPickUpDateFlexible && !ride.driver) {
    //   return setAcceptlModalVisible(true);
    // }
   // tcAssignDriver({ rideId: id, payload: { driver }, role });

   tcAcceptRescheduleRide({id:id,payload: { driver :ride.driver }})
  };

  const handleDeclineModal = (flag) => () => setShowDeclineModal(flag);

  const handleCancelModal = (flag) => () => setShowCancelModal(flag);

  const handleRideStatusModal = useCallback(
    (flag, modalType = '') =>
      async () => {
        if (ride?.driver) {
          await setShowStatusRideModal(flag);
          await setRideStatusModalType(modalType);
        } else {
          await setShowErrorMsg(true);
          await (msgRef?.current?.scrollIntoView && msgRef?.current?.scrollIntoView({ behavior: 'smooth' }));
        }
      },
    [ride?.driver],
  );

  const handleConfirmDecline = (values) => async () => {
    await tcDeclineRide({ rideId: id, payload: { reason: values } });
    await setShowDeclineModal(false);
  };

  const handleConfirmCancel = (values) => async () => {
   

    const reasonObjArr = CANCEL_RIDE_OPTIONS[role].filter(r => r.value === values?.reason);
    const cancelationType = Array.isArray(reasonObjArr) && reasonObjArr.length > 0 ? reasonObjArr[0]?.cancellationType : null;
    const payload = { reason: values?.reason, additionalComments: values?.additionalComments, cancelationType: cancelationType,  connectedLegId: ride?.connectedLegId };
    await tcCancelRide({ rideId: id, payload: payload });
    await setShowCancelModal(false);
  };

  const handleConfirmRideStatus = (values) => async () => {
    const payload = { rideId: id, payload: {...values, driverArrivalTime: ride?.driverArrivalTime || null} };
    if (rideStatusModalType === RIDE_STATUS_MODALS_TYPES.DRIVER_ARRIVED) {
      const driverPayload = {
        rideId: id,
        payload: { driverArrivalTime: values.datetime },
      };
      await driverArrival(driverPayload);
    } else if (rideStatusModalType === RIDE_STATUS_MODALS_TYPES.START_RIDE)
      await startRide(payload);
    else if (rideStatusModalType === RIDE_STATUS_MODALS_TYPES.EDIT_RIDE)
      await editStartTime(payload);
    else await endRide(payload);
    await setShowStatusRideModal(false);
    await setRideStatusModalType("");
  };

  const showRideFullInfo = useMemo(() => isAccepted || ride?.status === RIDE_STATUSES.UNFULFILLED, [isAccepted, ride]);

  const disabledCancelRideButton = useMemo(() => {

    
     return ride?.connectedLeg?.status == RIDE_STATUSES.COMPLETED; 
   

  }, [ride]);
  return (
    <>
      <RideDetailsPage
        showFullInfo={showRideFullInfo}
        showPrefferedLanguageBeforeAccept={rideIsPending}
        handleRideStatusModal={handleRideStatusModal}
      />
      {ride && showDriverForm && (
        <div className={styles.rideDetails}>
          <h1>{isAccepted ? 'Driver' : 'Assign Driver and Accept the Ride'}</h1>
          {ride && !ride?.driver && showErrorMsg && (
            <div ref={msgRef} className={styles.snackbarMsg}>
              <Snackbar
                type='error'
                title='Please Assign a Driver'
                content='The ride can not be started if there is no Driver assigned'
              />
            </div>
          )}

          <Formik
            enableReinitialize
            innerRef={driverFormREf}
            initialValues={{ driver: matchedDrivers?.length ? ride?.driver?.id || '' : '' }}>
            {({ values }) => {
              const acceptWithDriverOnly = ride.status === RIDE_STATUSES.PENDING && ride.canAcceptOnlyWithDriver;
              return  ( 
                <form className={styles.form}>
                  <label className={styles.driverLabel}>
                    <div className={styles.driverLabelContainer}>
                      <span className={styles.driverLabelText}>
                        Pick a Driver
                      </span>
                      <div >
                        <Tooltip
                          title={
                            <span className={styles.tooltipContent}>
                              Drivers listed in gray font do{" "}
                              <span style={{ textDecoration: "underline" }}>
                                not
                              </span>{" "}
                              qualify for this ride request based on Vehicle
                              Selection/Type. You will not be able to Accept
                              this ride if none of your drivers qualify for this
                              ride request based on Vehicle Selection.
                            </span>
                          }
                        >
                          <span
                            className={classnames({
                              [styles.tooltipInfo]: true,
                            })}
                          >
                            <InfoOutlined className={styles.infoIcon} />
                          </span>
                        </Tooltip>
                      </div>
                    </div>
                    <Select
                      name="driver"
                      options={transformValue(matchedDrivers)}
                      placeholder="Pick a Driver"
                    />
                  </label>
                  <div className={styles.formRow}>
                    
                    <div className={styles.btnWithTooltip}>
                      {isAccepted &&
                        (ride?.driver ||
                          ride?.isPickUpDateFlexible ||
                          acceptWithDriverOnly) && (
                          <Button
                            myClassName={styles.styledBtn}
                            onClick={handleAssignDriver(values.driver)}
                            disabled={
                              !values.driver ||
                              LodashHelper.isEqual(
                                ride?.driver?.id,
                                values?.driver
                              )
                            }
                          >
                            {"Reassign the Driver for this Ride"}
                          </Button>
                        )}

                      {!isAccepted && (
                        <>
                          <Button
                            myClassName={styles.styledBtn}
                            onClick={handleAssignDriver(values?.driver)}
                            disabled={!values?.driver}
                          >
                            Accept the Ride
                          </Button>

                          <Tooltip
                            title={
                              <span className={styles.tooltipContent}>
                                You cannot click "Accept the Ride" without
                                assigning a Driver. To start the ride, a Driver
                                must be assigned first.
                              </span>
                            }
                          >
                            <span
                              className={classnames({
                                [styles.tooltipInfo]: true,
                              })}
                            >
                              <InfoOutlined className={styles.infoIcon} />
                            </span>
                          </Tooltip>
                        </>
                      )}
                    </div>
                    <NavLink
                      className={styles.manageDriversLink}
                      to={URLS.DRIVERS}
                    >
                      Manage Drivers
                    </NavLink>
                  </div>
                </form>
              );}
            }
          </Formik>
          <div>
            {isAccepted ? (
             <Button myClassName={styles.navigationBtn} disabled={disabledCancelRideButton} onClick={handleCancelModal(true)}>
                Cancel Ride
              </Button>
            ) : (
              <Button myClassName={styles.navigationBtn} onClick={handleDeclineModal(true)}>
                Decline Ride
              </Button>
            )}



          </div>



        </div>
      )}

{isRescheduled &&<div className={styles.rescheduleContainer}>

<Button myClassName={classnames({
                              [styles.navigationBtn]: true,
                              [styles.fitContent]: true,
                            })} onClick={handleDeclineModal(true)}>
              Decline Ride
            </Button>

            <Button myClassName={classnames({[styles.fitContent]: true,
                              [styles.navigationBtn]: true,
                              
                            })}   onClick={handleAcceptRescheduleRide(true)}>
              Accept Ride
            </Button>
</div>  
           }

      <DeclineRideModal
        visibleModal={showDeclineModal}
        onCancel={handleDeclineModal(false)}
        onConfirm={handleConfirmDecline}
        id={ride?.seqId}
      />

      <CancelRideModal
        visibleModal={showCancelModal}
        onCancel={handleCancelModal(false)}
        onConfirm={handleConfirmCancel}
        id={ride?.seqId}
        ride={ride}
        role={role}
      />

      {showStatusRideModal && (
        <RideStatusModal
          modalType={rideStatusModalType}
          visibleModal={true}
          onCancel={handleRideStatusModal(false)}
          onConfirm={handleConfirmRideStatus}
          date={
            rideStatusModalType === RIDE_STATUS_MODALS_TYPES.DRIVER_ARRIVED ? {
              datetime: ride?.driverArrivalTime || null, timezone: ride?.pickUpDate?.timezone
            } :
            (rideStatusModalType === RIDE_STATUS_MODALS_TYPES.END_RIDE
              ? { datetime: ride?.endDate, timezone: ride?.pickUpDate?.timezone }
              : { datetime: ride?.startDate, timezone: ride?.pickUpDate?.timezone })
          }

          ride={ride}
          setRideStatusModalType={setRideStatusModalType}
          tcConfiguration={tcConfiguration}
        />
      )}
      {acceptModalVisible && AcceptRideModal}
      {ConfirmRoundTripRideModalVisible && ConfirmRoundTripRideModal}
    </>
  );
};

const mapStateToProps = (state) => ({
  matchedDrivers: state.rides.matchedDrivers,
  ride: state.rides.ride,
  role: state.authorization.role,
  tcConfiguration: state.transportOrganizations.configuration
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchMathcedDriversForRide,
      tcAcceptRide,
      tcAssignDriver,
      tcDeclineRide,
      tcAcceptRescheduleRide,
      tcCancelRide,
      startRide,
      editStartTime,
      endRide,
      driverArrival,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(TC_RideDetails);
