import { showConfirmPopUp } from '../components';
import { URLS, USER_ROLES } from '../constants/constants';
import { baseRequest, getRidesRequestParams, LodashHelper } from '../utils';
import { DraftHelper } from '../utils/DraftHelper';
import { getPage } from '../utils/helpers';
import { logOut } from './authorization';
import { types as commonTypes } from './common';

export const operationTypes = {
  CREATE_AND_LEAVE: 'CREATE_AND_LEAVE',
  UPDATE_AND_LEAVE: 'UPDATE_AND_LEAVE',
};

const types = {
  SET_CURRENT_STEP: 'SET_CURRENT_STEP',

  RESET_DRAFT: 'RESET_DRAFT',
  CLEAR_CURRENT_DRAFT: 'CLEAR_CURRENT_DRAFT',

  GET_DRAFT_REQUEST: 'GET_DRAFT_REQUEST',
  GET_DRAFT_SUCCESS: 'GET_DRAFT_SUCCESS',
  GET_DRAFT_ERROR: 'GET_DRAFT_ERROR',

  CREATE_DRAFT_REQUEST: 'CREATE_DRAFT_REQUEST',
  CREATE_DRAFT_SUCCESS: 'CREATE_DRAFT_SUCCESS',
  CREATE_DRAFT_ERROR: 'CREATE_DRAFT_ERROR',

  UPDATE_DRAFT_REQUEST: 'UPDATE_DRAFT_REQUEST',
  UPDATE_DRAFT_SUCCESS: 'UPDATE_DRAFT_SUCCESS',
  UPDATE_DRAFT_ERROR: 'UPDATE_DRAFT_ERROR',

  DELETE_DRAFT_REQUEST: 'DELETE_DRAFT_REQUEST',
  DELETE_DRAFT_SUCCESS: 'DELETE_DRAFT_SUCCESS',
  DELETE_DRAFT_ERROR: 'DELETE_DRAFT_ERROR',

  SAVE_DRAFT_REQUEST: 'SAVE_DRAFT_REQUEST',
  SAVE_DRAFT_SUCCESS: 'SAVE_DRAFT_SUCCESS',
  SAVE_DRAFT_ERROR: 'SAVE_DRAFT_ERROR',

  FETCH_DRAFTS_REQUEST: 'FETCH_DRAFTS_REQUEST',
  FETCH_DRAFTS_SUCCESS: 'FETCH_DRAFTS_SUCCESS',
  FETCH_DRAFTS_ERROR: 'FETCH_DRAFTS_ERROR',

  COPY_RIDE_REQUEST: 'COPY_RIDE_REQUEST',
  COPY_RIDE_SUCCESS: 'COPY_RIDE_SUCCESS',
  COPY_RIDE_ERROR: 'COPY_RIDE_ERROR',

  CLEAR_DRAFTS_INFO: 'CLEAR_DRAFTS_INFO',

  DRAFT_SET_FILTERED_STATUS: 'DRAFT_SET_FILTERED_STATUS',
  DRAFT_SET_MY_FILTER_PARAMS: 'DRAFT_SET_MY_FILTER_PARAMS',
  SET_CURRENT_PAGE_DRAFT: 'SET_CURRENT_PAGE_DRAFT',
  SET_DRAFTid: 'SET_DRAFTid',

  SET_UNSAVED_DRAFT: 'SET_UNSAVED_DRAFT',
};

export const setDraftFilteredStatus = (payload) => ({
  type: types.DRAFT_SET_FILTERED_STATUS,
  payload,
});

export const setMyFilter = (payload) => ({
  type: types.DRAFT_SET_MY_FILTER_PARAMS,
  payload: LodashHelper.removeEmptyAndTrim(payload, true) || {},
});

const getDraftRequest = () => ({
  type: types.GET_DRAFT_REQUEST,
});

const createDraftRequest = () => ({
  type: types.CREATE_DRAFT_REQUEST,
});

const updateDraftRequest = () => ({
  type: types.UPDATE_DRAFT_REQUEST,
});

const saveDraftRequest = () => ({
  type: types.SAVE_DRAFT_REQUEST,
});

const deleteDraftRequest = () => ({
  type: types.DELETE_DRAFT_REQUEST,
});

const fetchDraftsRequest = () => ({
  type: types.FETCH_DRAFTS_REQUEST,
});

export const setDraftId = (id) => {
  sessionStorage.setItem('draftId', id);
  return {
    type: types.SET_DRAFTid,
    payload: id,
  };
};

const copyRideRequest = () => ({
  type: types.COPY_RIDE_REQUEST,
});

export const setCurrentStep = (payload) => (dispatch) => {
  sessionStorage.setItem('currentStep', Number(payload));
  return dispatch({
    type: types.SET_CURRENT_STEP,
    payload: Number(payload),
  });
};

export const resetDraft = () => (dispatch) => {
  sessionStorage.removeItem('draft');
  sessionStorage.removeItem('draftId');
  sessionStorage.removeItem('currentStep');
  return dispatch({
    type: types.RESET_DRAFT,
  });
};

export const clearCurrentDraft = () => ({
  type: types.CLEAR_CURRENT_DRAFT,
});

export const setCurrentPage = (page) => ({
  type: types.SET_CURRENT_PAGE_DRAFT,
  payload: page,
});

export const getDraft = (id) => {
  const success = (payload) => ({
    type: types.GET_DRAFT_SUCCESS,
    payload,
  });

  const failure = (error) => ({
    type: types.GET_DRAFT_ERROR,
    payload: error,
  });

  return (dispatch) => {
    dispatch(getDraftRequest());
    return baseRequest('GET', `/ride-drafts/${id}`)
      .then((response) => {
        sessionStorage.setItem('draft', JSON.stringify(response.data));
        dispatch(success(response.data));
      })
      .catch((error) => {
        if (error) dispatch(failure(error?.response?.data));
      });
  };
};

export const createDraft = ({ draft, type, step }, callback) => {
  const success = (payload) => ({
    type: types.CREATE_DRAFT_SUCCESS,
    payload,
  });

  const failure = (error) => ({
    type: types.CREATE_DRAFT_ERROR,
    payload: error,
  });

  return (dispatch) => {
    dispatch(createDraftRequest());

    return baseRequest('POST', '/ride-drafts', DraftHelper.prepareDraftForSaving(draft))
      .then((response) => {
        if (type === operationTypes.CREATE_AND_LEAVE) {
          dispatch(resetDraft());
          return callback && callback();
        }
        sessionStorage.setItem('draft', JSON.stringify(response.data));
        sessionStorage.setItem('draftId', response.data.id);
        dispatch(success(response.data));
        dispatch(setCurrentStep(step));
      })
      .catch((error) => {
        if (error) dispatch(failure(error?.response?.data));
      });
  };
};

export const updateDraft = ({ draft, draftId, type, step }, callback) => {
  const success = (payload) => ({
    type: types.UPDATE_DRAFT_SUCCESS,
    payload,
  });

  const failure = (error) => ({
    type: types.UPDATE_DRAFT_ERROR,
    payload: error,
  });

  return (dispatch) => {
    dispatch(updateDraftRequest());
    return baseRequest('PUT', `/ride-drafts/${draftId}`, DraftHelper.prepareDraftForSaving(draft))
      .then((response) => {
        if (type === operationTypes.UPDATE_AND_LEAVE) {
          dispatch(resetDraft());
          return callback && callback();
        }
        sessionStorage.setItem('draft', JSON.stringify(response.data));
        dispatch(success(response.data));
        dispatch(setCurrentStep(step));
      })
      .catch((error) => {
        dispatch(failure(error?.response?.data));
      });
  };
};

export const deleteDraft = ({ draftId, pathname,Navigation }) => {
  const navigate = Navigation;
  const success = (payload) => ({
    type: types.DELETE_DRAFT_SUCCESS,
    payload,
  });

  const failure = (error) => ({
    type: types.DELETE_DRAFT_ERROR,
    payload: error,
  });

  return (dispatch) => {
    dispatch(deleteDraftRequest());
    return baseRequest('DELETE', `/ride-drafts/${draftId}`)
      .then((response) => {
        dispatch(success(draftId));
        dispatch(resetDraft());
       
        navigate.go(pathname);
      })
      .catch((error) => {
        if (error?.response?.data) dispatch(failure(error?.response?.data));
      });
  };
};

export const saveDraft = ({ draftId, unblockHistory,Navigation }) => {
  const navigate = Navigation;
  const success = (payload) => ({
    type: types.SAVE_DRAFT_SUCCESS,
    payload,
  });

  const failure = (error) => ({
    type: types.SAVE_DRAFT_ERROR,
    payload: error,
  });

  return (dispatch) => {
    dispatch(saveDraftRequest());
    return baseRequest('POST', `/ride-requests/${draftId}`)
      .then((response) => {
        dispatch(success(response.data));
        dispatch(resetDraft());
        if (unblockHistory) {
          unblockHistory.current();
        }
        showConfirmPopUp({ description: 'New Request was successfully sent', duration: 5 });
        navigate.push(URLS.RIDES);
      })
      .catch((error) => {
        if (error) {
          dispatch(failure(error?.response?.data));
        }
      });
  };
};

export const fetchDrafts = ({filtered,Navigation}) => {
  const navigate = Navigation;
  const success = (payload) => ({
    type: types.FETCH_DRAFTS_SUCCESS,
    payload,
  });

  const failure = (error) => ({
    type: types.FETCH_DRAFTS_ERROR,
    payload: error,
  });

  return (dispatch, getStore) => {
    if (filtered) navigate.push(URLS.DRAFTS);
    const { limit, myFilterParams } = getStore().drafts;
    const currentPage = getPage(navigate?.location);

    dispatch(fetchDraftsRequest());
    return baseRequest(
      'GET',
      `/ride-drafts?`,
      null,
      {},
      {
        docsPerPage: limit,
        page: currentPage,
        ...getRidesRequestParams(myFilterParams),
      },
    )
      .then((response) => {
        dispatch(success(response.data));
      })
      .catch((error) => {
        if (error) {
          dispatch(failure([]));
        }
      });
  };
};

export const copyRide = (id,Navigation) => {
  const navigate = Navigation;
  const success = (payload) => ({
    type: types.COPY_RIDE_SUCCESS,
    payload,
  });

  const failure = (error) => ({
    type: types.COPY_RIDE_ERROR,
    payload: error,
  });

  return (dispatch) => {
    dispatch(copyRideRequest());
    return baseRequest('POST', `/ride-requests/copy/${id}`)
      .then((response) => {
        dispatch(success(response.data));
        dispatch(setDraftId(response.data.id));
        sessionStorage.setItem('draft', JSON.stringify(response.data));
        sessionStorage.setItem('currentStep', 0);
        navigate.push(URLS.CREATE_RIDE);
      })
      .catch((error) => {
        if (error) {
          dispatch(failure([]));
        }
      });
  };
};

export const setUnSavedDraft = (payload) => ({
  type: types.SET_UNSAVED_DRAFT,
  payload,
});

export const saveUnSavedDraftAndLogOut = (Navigation) => {
  const navigate =Navigation;
  return (dispatch, getStore) => {
    const { currentStep, unSavedDraft, draft, draftId } = getStore().drafts;
    const { role } = getStore().authorization;
    const isHO = role === USER_ROLES.HO_ADMIN || role === USER_ROLES.HO_EMPLOYEE;
    const isCreateDraftURL =
    navigate?.location.pathname === URLS.CREATE_RIDE || navigate?.location.pathname === URLS.EDIT_DRAFT;

    if (
      (draft || unSavedDraft) &&
      isHO &&
      isCreateDraftURL &&
      currentStep === 0 &&
      !draftId &&
      !LodashHelper.isEqual(unSavedDraft, draft?.[`step${currentStep}`])
    ) {
      dispatch(createDraft({ draft: unSavedDraft, step: currentStep })).then(() => {
        dispatch(logOut(navigate));
      });
    } else if (
      isHO &&
      isCreateDraftURL &&
      (draft || unSavedDraft) &&
      !LodashHelper.isEqual(unSavedDraft, draft?.[`step${currentStep}`])
    ) {
      dispatch(
        updateDraft({
          draft: { ...draft, ...unSavedDraft },
          draftId,
          step: currentStep,
        }),
      ).then(() => {
        dispatch(logOut(navigate));
      });
    } else {
      dispatch(logOut(navigate));
    }
  };
};

export const clearDraftsInfo = () => ({
  type: types.CLEAR_DRAFTS_INFO,
});

const initialState = {
  currentStep: Number(sessionStorage.getItem('currentStep')) || 0,
  draftId: sessionStorage.getItem('draftId') || '',
  draft: JSON.parse(sessionStorage.getItem('draft')) || null,
  unSavedDraft: null,
  drafts: [],
  dataLoaded: false,
  filtered: false,
  myFilterParams: {},
  limit: 10,
  currentPage: 1,
  totalPages: 1,
  total: 1,
  loading: false,
  error: false,
};

export default function drafts(state = initialState, action) {
  switch (action.type) {
    case types.SET_CURRENT_STEP:
      return { ...state, currentStep: action.payload };

    case types.FETCH_DRAFTS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.FETCH_DRAFTS_SUCCESS:
      return {
        ...state,
        loading: false,
        drafts: action.payload?.data,
        dataLoaded: true,
        currentPage: action.payload?.meta?.page,
        totalPages: action.payload?.meta?.totalPages,
        total: action.payload?.meta?.total,
        filtered: false,
      };

    case types.FETCH_DRAFTS_ERROR:
      return {
        ...state,
        loading: false,
        drafts: action.payload,
        dataLoaded: true,
        currentPage: 1,
        totalPages: 1,
        total: 1,
        filtered: false,
      };

    case types.GET_DRAFT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.GET_DRAFT_SUCCESS:
      return {
        ...state,
        draft: action.payload,
        loading: false,
      };

    case types.GET_DRAFT_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.CREATE_DRAFT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.CREATE_DRAFT_SUCCESS:
      return {
        ...state,
        draftId: action.payload.id,
        draft: action.payload,
        loading: false,
      };

    case types.CREATE_DRAFT_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.UPDATE_DRAFT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.UPDATE_DRAFT_SUCCESS:
      return {
        ...state,
        draft: { ...state.draft, ...action.payload },
        loading: false,
      };

    case types.UPDATE_DRAFT_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.SAVE_DRAFT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.SAVE_DRAFT_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case types.SAVE_DRAFT_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.DELETE_DRAFT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.DELETE_DRAFT_SUCCESS:
      return {
        ...state,
        loading: false,
        drafts: state.drafts.filter((d) => d.id !== action.payload),
      };

    case types.DELETE_DRAFT_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.RESET_DRAFT:
      return {
        ...state,
        draft: null,
        unSavedDraft: null,
        draftId: '',
        currentStep: 0,
        loading: false,
      };

    case types.SET_CURRENT_PAGE_DRAFT:
      return {
        ...state,
        currentPage: action.payload,
      };

    case types.SET_DRAFTid:
      return {
        ...state,
        draftId: action.payload,
      };

    case types.CLEAR_CURRENT_DRAFT:
      return {
        ...state,
        draft: null,
      };

    case types.COPY_RIDE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.COPY_RIDE_SUCCESS:
      return {
        ...state,
        loading: false,
        draft: action.payload,
        currentStep: 0,
      };

    case types.COPY_RIDE_ERROR:
      return {
        ...state,
        loading: false,
        draft: null,
      };

    case types.DRAFT_SET_MY_FILTER_PARAMS:
      return {
        ...state,
        myFilterParams: action.payload,
      };

    case types.DRAFT_SET_FILTERED_STATUS:
      return {
        ...state,
        filtered: action.payload,
      };

    case types.SET_UNSAVED_DRAFT:
      return {
        ...state,
        unSavedDraft: action.payload,
      };

    case types.CLEAR_DRAFTS_INFO:
      return {
        ...state,
        drafts: [],
      };

    case commonTypes.CLEAR_STORE_INFO:
      return {
        ...initialState,
        currentStep: 0,
        draftId: '',
        draft: null,
        currentPage: 1,
      };

    default:
      return state;
  }
}
