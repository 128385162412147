import { Button, Snackbar } from '../..';

import styles from './index.module.css';

export const MatchedExactRiderSnackBar = ({ handleUseExactRider }) => {
  return (
    <div className={styles.snackbarField}>
      <Snackbar
        type='warning'
        title={
          <>
            <span className={styles.subTitle}>
              This rider currently exists within our system. We will use the existing rider for this ride
            </span>
            <div className={styles.confirmExactRiderButton}>
              <Button dis myClassName={styles.formControlBtn} onClick={handleUseExactRider}>
                OK
              </Button>
            </div>
          </>
        }
      />
    </div>
  );
};
