export const TimeLapsIcon = ({ FULL_STROKE_OFFSET, strokeDashOffset }) => (
  <svg width='100' height='100' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='m 50,6
          a 44,44 0 1,0 0,88
          a 44,44 0 1,0 0,-88'
      fill='none'
      stroke='#d9d9d9'
      strokeWidth='8'></path>
    {strokeDashOffset < FULL_STROKE_OFFSET && (
      <path
        d='m 50,6
          a 44,44 0 1,0 0,88
          a 44,44 0 1,0 0,-88'
        fill='none'
        stroke='#de7f39'
        strokeLinecap='round'
        strokeWidth='8'
        strokeDasharray={FULL_STROKE_OFFSET}
        strokeDashoffset={strokeDashOffset}></path>
    )}
  </svg>
);
