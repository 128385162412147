import React from 'react';
import { Input as AntInput, InputNumber } from 'antd';

import { getIn } from 'formik';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { ErrorIcon, OpenEyeIcon, CloseEyeIcon, SuccessIcon } from '../../../assets';
import { withTooltip } from '../../../hocs/withTooltip';
import { CustomField } from '../CustomField/CustomField';

import styles from './index.module.css';

export const renderEyeIcon = (form, field) => (visible) =>
  !form?.values[field?.name] ? (
    <span role='img' className='anticon anticon-eye ant-input-password-icon'>
      <OpenEyeIcon width='14px' height='14px' fill='grey' />
    </span>
  ) : visible ? (
    <span role='img' className='anticon anticon-eye ant-input-password-icon'>
      <OpenEyeIcon width='14px' height='14px' fill='#DE7F39' />
    </span>
  ) : (
    <span role='img' className='anticon anticon-eye ant-input-password-icon '>
      <CloseEyeIcon fill='#DE7F39' />
    </span>
  );

export const renderAuthSuffix = (form, field, showAuthIcons = false) =>
  showAuthIcons &&
  (form?.touched[field?.name] && form?.values[field?.name] && !form?.errors[field?.name] ? (
    <SuccessIcon fill='#52C41A' />
  ) : (
    form?.touched[field?.name] && form?.errors[field?.name] && <ErrorIcon fill='#F5222D' />
  ));

export const CustomInput = ({
  field,
  form,
  type,
  value,
  onChange,
  disabled,
  myclassname,
  name,
  placeholder,
  suffix,
  prefix,
  min,
  max,
  handleBlurField,
  showAuthIcons,
  whenFieldDisabledShowTooltip,
  allowClear,
  precision=0,
  step=1,
  ...rest
}) => {
  const handleChange = (event) => {
    if (onChange) {
      onChange({
        name: field.name,
        value: event.target.value,
        setFieldValue: form.setFieldValue,
        setValues: form.setValues,
        values: form.values,
        validateForm: form.validateForm,
        target: {
          name: event.target.name,
          value: event.target.value,
        },
      });
    } else field.onChange(event);
  };

  const handleBlur = () => {
    if (handleBlurField) {
      handleBlurField();
    } else form.setTouched({ ...form.touched, [field.name]: true });
  };

  if (type === 'password')
    return withTooltip(
      <AntInput.Password
        role='input'
        name={name || field?.name}
        placeholder={placeholder}
        onChange={handleChange}
        value={value || field?.value}
        disabled={disabled}
        iconRender={renderEyeIcon(form, field)}
        prefix={prefix}
        onBlur={handleBlur}
        allowClear={allowClear}
        className={classnames({
          [styles.customInput]: true,
          [styles.input]: true,
          [styles.error]: !disabled && !getIn(form?.values, field?.name) && getIn(form?.errors, field?.name),
          [styles.error]:
            !disabled &&
            (getIn(form?.touched, field?.name) || form?.touched[field?.name]) &&
            getIn(form?.errors, field?.name),
          [myclassname]: myclassname,
        })}
      />,
      whenFieldDisabledShowTooltip,
    );

    // if (type === 'number')
    // return withTooltip(
    //   <InputNumber 
    //     role='input'
    //     name={name || field?.name}
    //     placeholder={placeholder}
    //     onChange={handleChange}
    //     value={value || field?.value}
    //     disabled={disabled}
    //     precision={precision}
    //  step={step} 
    //     iconRender={renderEyeIcon(form, field)}
    //     prefix={prefix}
    //     onBlur={handleBlur}
    //     allowClear={allowClear}
    //     className={classnames({
    //       [styles.customInput]: true,
    //       [styles.input]: true,
    //       [styles.error]: !disabled && !getIn(form?.values, field?.name) && getIn(form?.errors, field?.name),
    //       [styles.error]:
    //         !disabled &&
    //         (getIn(form?.touched, field?.name) || form?.touched[field?.name]) &&
    //         getIn(form?.errors, field?.name),
    //       [myclassname]: myclassname,
    //     })}
    //   />,
    //   whenFieldDisabledShowTooltip,
    // );

  return withTooltip(
    <AntInput
      {...rest}
      role='input'
      name={name || field?.name}
      type={type}
      value={value || field?.value}
      onChange={handleChange}
      onBlur={handleBlur}
      disabled={disabled}
      placeholder={placeholder}
      min={min}
      max={max}
      step={step}
      precision={precision}
      suffix={suffix || renderAuthSuffix(form, field, showAuthIcons) || <span />}
      prefix={prefix}
      allowClear={allowClear}
      className={classnames({
        [styles.customInput]: true,
        [styles.input]: true,
        [styles.error]: !disabled && !getIn(form?.values, field?.name) && getIn(form?.errors, field?.name),
        [styles.error]:
          !disabled &&
          (getIn(form?.touched, field?.name) || form?.touched[field?.name]) &&
          getIn(form?.errors, field?.name),
        [myclassname]: myclassname,
      })}
    />,
    whenFieldDisabledShowTooltip,
  );
};

export const Input = ({
  type,
  name,
  myClassName,
  value,
  onChange,
  disabled,
  placeholder,
  suffix,
  prefix,
  min,
  max,
  handleBlurField,
  showAuthIcons,
  allowClear,
  fastField,
  precision,
  step,
  ...rest
}) => {
  return (
    <CustomField
      fastField={fastField}
      name={name}
      type={type}
      disabled={disabled}
      onChange={onChange}
      value={value}
      handleBlurField={handleBlurField}
      placeholder={placeholder}
      myclassname={myClassName}
      suffix={suffix}>
      {({ field, form }) => {
        return (
          <>
            <CustomInput
              form={form}
              field={field}
              name={name}
              type={type}
              disabled={disabled}
              onChange={onChange}
              value={value}
              placeholder={placeholder}
              myclassname={myClassName}
              suffix={suffix}
              prefix={prefix}
              min={min}
              max={max}
              handleBlurField={handleBlurField}
              showAuthIcons={showAuthIcons}
              allowClear={allowClear}
              precision={precision}
              step={step}
              {...rest}
            />
            {!disabled &&
              (getIn(form.touched, field.name) || form.touched[field.name]) &&
              getIn(form.errors, field.name) && (
                <div className={styles.errorMessage} role='alert'>
                  {getIn(form.errors, field.name)}
                </div>
              )}
          </>
        );
      }}
    </CustomField>
  );
};

Input.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  myClassName: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  showAuthIcons: PropTypes.bool,
  suffix: PropTypes.node,
  min: PropTypes.number,
  max: PropTypes.number,
  handleBlurField: PropTypes.func,
  whenFieldDisabledShowTooltip: PropTypes.bool,
};
