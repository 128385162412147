import { DEFAULT_PHONE_NUMBER_FORMAT } from '../../../../../constants/constants';
import { Input } from '../../../../formControls/Input/Input';
import { MaskInput } from '../../../../formControls/MaskInput/MaskInput';
import { renderSaveChangesButton } from './SaveChangesButton';

import styles from '../index.module.css';

export const SupportContactsBlock = ({ initialValues, values, errors, handleSaveChanges }) => {
  return (
    <div className={styles.supportContactBlock}>
      <div className={styles.supportField}>
        <label className={styles.supportContact}>
          <span className={styles.required}>Contact email for notifications</span>
          <Input name='supportContacts.email' type='text' placeholder='Email' />
        </label>
        {initialValues?.supportContacts?.email !== values?.supportContacts?.email &&
          !Boolean(errors?.supportContacts?.email) &&
          renderSaveChangesButton(
            'supportContacts.email',
            handleSaveChanges,
            values,
            Boolean(errors?.supportContacts?.email),
          )}
      </div>
      <div className={styles.supportField}>
        <label className={styles.supportContact}>
          <span className={styles.required}>Contact phone number for notifications</span>
          <MaskInput
            name='supportContacts.phoneNumber'
            format={DEFAULT_PHONE_NUMBER_FORMAT}
            myClassName={styles.supportEmail}
            placeholder={'###-###-####'}
            value={values?.supportContacts?.phoneNumber || ''}
          />
        </label>
        {initialValues?.supportContacts?.phoneNumber !== values?.supportContacts?.phoneNumber &&
          !Boolean(errors?.supportContacts?.phoneNumber) &&
          renderSaveChangesButton(
            'supportContacts.phoneNumber',
            handleSaveChanges,
            values,
            Boolean(errors?.supportContacts?.phoneNumber),
          )}
      </div>
    </div>
  );
};
