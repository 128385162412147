import React from 'react';
import classnames from 'classnames';

import { FilterCheckboxListCell, FilterRangeCell, FilterSearchCell } from '../../../components';
import { statusesDictionary, vehicleOptionsDictionary } from '../../../dictionaries';
import { renderRecordValue } from '../../../utils';
import { STATUSES_BY_ROLE, USER_ROLES, VEHICLE_OPTIONS } from '../../../constants/constants';
import { FilterAutoCompleteCell } from '../../../components/common/FilterPanel/FiltersCells/FilterAutoCompleteCell/FilterAutoCompleteCell';

import styles from './index.module.css';

export const renderHeadCells = ({ values, setFieldValue, onClose }) => {
  return [
    {
      title: 'Ride ID',
      dataIndex: 'seqId',
      key: 'seqId',
      width: 150,
      Component: (
        <FilterSearchCell
          name='seqId'
          placeholder='Enter Ride ID'
          title='Ride ID'
          values={values}
          setFieldValue={setFieldValue}
          myClassName={styles.draftIdCell}
          onClose={onClose}
        />
      ),
    },

    {
      title: 'Rider Name',
      dataIndex: 'rider',
      key: 'rider',
      width: 180,
      Component: (
        <FilterAutoCompleteCell
          formattedName='formattedRiderName'
          name='rider'
          title='Rider Name'
          placeholder='Enter Rider Name'
          values={values}
          setFieldValue={setFieldValue}
          myClassName={styles.riderCell}
          onClose={onClose}
        />
      ),
    },
    {
      title: 'Vehicle Type',
      dataIndex: 'vehicleType',
      key: 'vehicleType',
      width: 250,
      Component: (
        <FilterCheckboxListCell
          name='vehicleType'
          title='Vehicle Type'
          values={values}
          myClassName={styles.vehicleCell}
          value={renderRecordValue(vehicleOptionsDictionary?.[values?.vehicleType?.[0]])}
          setFieldValue={setFieldValue}
          options={VEHICLE_OPTIONS}
        />
      ),
    },
    {
      title: 'Pickup Date',
      dataIndex: 'pickUpDate',
      key: 'pickUpDate',
      width: 250,
      Component: (
        <FilterRangeCell
          name='pickUpDate'
          title='Pickup Date'
          values={values}
          setFieldValue={setFieldValue}
          myClassName={styles.pickUpDateCell}
        />
      ),
    },
    {
      title: 'Date Created',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 150,
      Component: (
        <FilterRangeCell
          name='createdAt'
          title='Date Created'
          values={values}
          setFieldValue={setFieldValue}
          myClassName={styles.dateCreatedCell}
        />
      ),
    },
    {
      title: 'Created By',
      dataIndex: 'createdBy',
      key: 'createdBy',
      width: 150,
      Component: (
        <FilterSearchCell
          name='createdBy'
          title='Created By'
          placeholder='Enter Created By'
          values={values}
          setFieldValue={setFieldValue}
          myClassName={styles.createdByCell}
          onClose={onClose}
        />
      ),
    },
    {
      title: 'Ride Status',
      dataIndex: 'readableStatus',
      key: 'readableStatus',
      width: 160,
      Component: (
        <FilterCheckboxListCell
          name='status'
          title='Ride Status'
          values={values}
          myClassName={styles.statusCell}
          options={STATUSES_BY_ROLE[USER_ROLES.HO_ADMIN]}
          value={renderRecordValue(statusesDictionary[values?.status?.[0]])}
          setFieldValue={setFieldValue}
        />
      ),
    },
    {
      title: 'Recurring',
      width: 120,
      Component: (
        <th
          className={classnames({
            'ant-table-cell': true,
            [styles.recurring]: true,
          })}>
          <div
            className={classnames({
              [styles.recurring]: true,
            })}>
            Recurring
          </div>
        </th>
      ),
      dataIndex: 'actions',
      key: 'actions',
    },
  ];
};
