import React, { useEffect, useCallback } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { CreateTC, Button, CancelCreateModal } from '../../../components';
import { BackIcon } from '../../../assets';
import { createTO } from '../../../store/transport_organizations';
import { getAdditionalRideOptions } from '../../../store/preferences';
import { usePreventLeave } from '../../../hooks/usePreventLeave';
import { createTransportCompanySchema, LodashHelper, GoogleAddressHelper } from '../../../utils';
import { fetchStatesList } from '../../../store/common';
import { TC_OPERATIONAL_HOURS, FLEET_OPTIONS } from '../../../constants/constants';
import {
  handleAddCounties,
  handleCountiesSelect,
  handleDelete,
  handleStateSelect,
} from '../../../utils/countiesHelper';

import styles from './index.module.css';
import { Navigation } from '../../../utils/navigation';

const initialValues = {
  name: '',
  regionsOfOperation: [],
  admin: {
    email: '',
    firstName: '',
    lastName: '',
  },
  location: {
    formattedAddress: '',
  },
  state: '',
  counties: [],
  vehicleTypes: [],
  fleetSizes: FLEET_OPTIONS,
  hourOfOperations: TC_OPERATIONAL_HOURS,
  rideOptions: [],
  scheduledRidesReminderHour: null,
  scheduledRidesReminderDay: '',
  preferredTimezone: '',
  supportCarBoosterSeat: false,
};

const Create_Transport_Company = ({
  createTO, 
  getAdditionalRideOptions,
  rideOptions,
  fetchStatesList,
  counties,
  states,
  defaultValues,
}) => {
  const navigate = Navigation();
  const formik = useFormik({
    validationSchema: createTransportCompanySchema,
    validateOnMount: true,
    initialValues: defaultValues || initialValues,
    validateOnChange: false,
    onSubmit: ({ state, counties, ...values }) => {
      unblockHistory.current && unblockHistory.current();
      createTO(LodashHelper.trimValues(values),navigate);
    },
  });

  const [visibleModal, setModalVisible, unblockHistory, location] = usePreventLeave(initialValues, formik.values);

  useEffect(() => {
    fetchStatesList();
    return;
  }, [fetchStatesList]);

  useEffect(() => {
    getAdditionalRideOptions();
     return;
  }, [getAdditionalRideOptions]);

  const handleBack = useCallback(() => {
    navigate.back();
  }, [navigate]);

  const handleSelectAddress = async ({ value, values, setValues }) => {
    setValues({ ...values, location: GoogleAddressHelper.transformFields(value) });
  };

  const handleChangeAddress = ({ value, values, setValues }) => {
    setValues({
      ...values,
      location: {
        formattedAddress: value,
      },
    });
  };

  const onCancel = () => {
    setModalVisible(false);
  };

  const onLeave = () => {
    if (unblockHistory.current) {
      unblockHistory.current();
      navigate.push(location.location);
    }
  };
  return (
    <div className={styles.pageContent}>
      <div className={styles.header}>
        <div className={styles.backBtn} onClick={handleBack}>
          <BackIcon width='18px' height='18px' fill='#BDBDBD' />
        </div>
        <h1 className={styles.pageTitle}>Add New Company</h1>
      </div>
      <div>
        <h2>Information of the Transportation Company</h2>

        <FormikProvider value={formik}>
          <form className={styles.form} onSubmit={formik.handleSubmit}>
            <CreateTC
              values={formik.values}
              counties={counties}
              states={states}
              handleSelectAddress={handleSelectAddress}
              handleChangeAddress={handleChangeAddress}
              handleStateSelect={handleStateSelect}
              handleCountiesSelect={handleCountiesSelect(counties)}
              handleAddCounties={handleAddCounties({ values: formik.values, setValues: formik.setValues })}
              handleDelete={handleDelete}
              rideOptions={rideOptions}
              {...formik}
            />
            <div className={styles.btnPanel}>
              <div className={styles.btnControl}>
                <Button myClassName={styles.navigationBtn} onClick={handleBack}>
                  Cancel
                </Button>
              </div>
              <div className={styles.btnControl}>
                <Button disabled={!formik.isValid || !formik.dirty} type='submit' myClassName={styles.styledBtn}>
                  Create Company and Send Invite to Admin
                </Button>
              </div>
            </div>
          </form>
        </FormikProvider>
      </div>
      <CancelCreateModal title='Canceling New Company' visible={visibleModal} onCancel={onCancel} onLeave={onLeave} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  rideOptions: state.preferences.rideOptions?.filter((option) => !option.disabled),
  counties: state.common.counties,
  states: state.common.states,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ createTO, getAdditionalRideOptions, fetchStatesList }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Create_Transport_Company);
