import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Drafts } from '../..';
import { renderColumns } from './renderColumns';
import { renderTableHead } from './renderTableHead';
import { useDeleteRestoreModal } from '../../../components';
import { setDraftFilteredStatus, deleteDraft, setDraftId } from '../../../store/drafts';
import { URLS } from '../../../constants/constants';

import styles from './index.module.css';
import { Navigation } from '../../../utils/navigation';

const HO_Drafts = ({ setDraftFilteredStatus, setDraftId,  deleteDraft }) => {
  const navigate = Navigation();
  const [selectedRecord, setSelectedRecord] = useState(null);
  const handleConfirmDeleteDraft = useCallback(() => {
    deleteDraft({ draftId: selectedRecord?.id, pathname: URLS.DRAFTS, Navigation:navigate});
  }, [deleteDraft, selectedRecord?.id]);
  const {
    Modal,
    setDeleteRestoreModalVisible: setDeleteModalVisible,
    deleteRestoreModalVisible: deleteModalVisible,
  } = useDeleteRestoreModal({
    handleConfrim: handleConfirmDeleteDraft,
    title: `Deleting Draft`,
    description: (
      <>
        Are you sure you want to delete this draft (<span className={styles.bold}>ID {selectedRecord?.seqId}</span>)?
        <br />
        It will be removed permanently.
      </>
    ),
    confirmActionButtonText: 'Delete Draft',
  });

  const handleEditDraft = useCallback(
    (id) => {
      setDraftId(id);
      navigate.push(URLS.EDIT_DRAFT, { from: navigate?.location.pathname });
    },
    [navigate, setDraftId],
  );

  useEffect(
    () => () => {
      setDraftFilteredStatus(false);
      return;
    },
    [setDraftFilteredStatus],
  );

  const tableColumns = useMemo(
    () =>
      renderColumns({
        setDeleteModalVisible,
        setSelectedRecord,
        handleEditDraft,
      }),
    [setDeleteModalVisible, setSelectedRecord, handleEditDraft],
  );

  const tableHead = useMemo(() => renderTableHead(), []);

  return (
    <div className={styles.draftPage}>
      <Drafts renderColumns={tableColumns} renderTableHead={tableHead}  Navigation={navigate} />
      {deleteModalVisible && Modal}
    </div>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setDraftFilteredStatus, setDraftId, deleteDraft }, dispatch);

export default connect(null, mapDispatchToProps)(HO_Drafts);
