import Highlighter from 'react-highlight-words';
import { NavLink } from 'react-router-dom';
import { Button, renderResetCredentialsButton } from '../../../components';
import { URLS, USER_STATUSES } from '../../../constants/constants';

import { USER_ROLES_DICTIONARY } from '../../../dictionaries';
import { renderRecordValue } from '../../../utils';

import styles from './index.module.css';

export const renderButtons = ({
  isMe,
  isAdmin,
  managedRole,
  value,
  switchUserStatus,
  handleSendPassword,
  handleManageUser,
  handleResendInvitation,
  Navigation
}) => {
  
  if (isMe && isAdmin) {
    return [
      <div key={1} className={styles.tableButton}>
        <Button myClassName={styles.navigationBtn}>
          <NavLink
            to={{
              pathname: URLS.PREFERENCES,
              hash: 'personalInfo',
            }}>
            Manage {managedRole}
          </NavLink>
        </Button>
      </div>,
    ];
  } else if (value.status === USER_STATUSES.DISABLED && isAdmin) {
    return [
      <div key={0} className={styles.restoreAccess}>
        <Button
          myClassName={styles.navigationBtn}
          onClick={switchUserStatus({ status: { status: USER_STATUSES.ACTIVE, }, id: value.id,Navigation })}>
          Activate {managedRole}
        </Button>
      </div>,
    ];
  } else if (isAdmin && !isMe) {
    return [
      renderResetCredentialsButton({
        invited: value.status === USER_STATUSES.INVITED,
        value,
        handleSendPassword: handleSendPassword(value?.id),
        handleResendInvitation: handleResendInvitation(value?.id),
        resetButtonText: '(Send) Reset Password',
        resendButtonText: 'Resend Invitation',
      }),
      <div key={1} className={styles.tableButton}>
        <Button myClassName={styles.navigationBtn} onClick={handleManageUser(value.id,Navigation)}>
          Manage {managedRole}
        </Button>
      </div>,
    ];
  } else return [];
};

export const transformData = ({
  data,
  myEmail,
  switchUserStatus,
  handleManageUser,
  managedRole,
  isAdmin,
  handleSendPassword,
  handleResendInvitation,
  Navigation
}) => {
  return data?.map((value) => {
    const isMe = value.email === myEmail;

    return {
      key: value.id,
      name:
        value.firstName &&
        value.lastName &&
        renderRecordValue(`${value.firstName} ${value.lastName} ${isMe ? '(You)' : ''}`),
      email: value.email,
      type: value.role,
      buttons: renderButtons({
        isMe,
        isAdmin,
        managedRole,
        value,
        switchUserStatus,
        handleSendPassword,
        handleResendInvitation,
        handleManageUser,
        Navigation
      }),
    };
  });
};

export const renderColumns = (isAdmin, searchValue, highlighted) =>
  [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text) => (
        <div className={styles.cellValue}>
          {searchValue && highlighted ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[searchValue]}
              autoEscape
              textToHighlight={text ? text.toString() : ''}
            />
          ) : (
            renderRecordValue(text)
          )}
        </div>
      ),
      width: 300,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 400,
      render: (text) => (
        <div className={styles.cellValue}>
          {searchValue && highlighted ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[searchValue]}
              autoEscape
              textToHighlight={text ? text.toString() : ''}
            />
          ) : (
            text
          )}
        </div>
      ),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      width: 200,

      render: (text) => <div className={styles.cellValue}>{USER_ROLES_DICTIONARY[text]}</div>,
    },
    {
      title: '',
      key: 'buttons',
      dataIndex: 'buttons',
      render: (buttons) => <div className={styles.btnPanel}>{buttons.map((Component) => Component)}</div>,
      width: 480,
    },
  ].filter((col) => (isAdmin ? col : col.key !== 'buttons'));
