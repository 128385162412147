import _ from 'lodash';

class LodashHelper {
  removeEmpty(object, includeDeleteArrays) {
    return (function prune(current) {
      _.forOwn(current, function (value, key) {
        if (
          _.isUndefined(value) ||
          _.isNull(value) ||
          _.isNaN(value) ||
          (_.isString(value) && _.isEmpty(value)) ||
          (_.isObject(value) && !_.isArray(value) && _.isEmpty(prune(value))) ||
          (_.isObject(value) && _.isEmpty(prune(value)) && includeDeleteArrays)
        ) {
          delete current[key];
        }
      });
      if (_.isArray(current)) _.pull(current, undefined);

      return current;
    })(_.cloneDeep(object));
  }

  isEqual(x, y) {
    return _.isEqual(x, y);
  }

  isEmpty(obj) {
    return _.isEmpty(obj);
  }

  cloneDeep(obj) {
    return _.cloneDeep(obj);
  }
  isString(str) {
    return _.isString(str);
  }

  isNumber(num) {
    return _.isNumber(num);
  }

  isTcStatusActive(val) {
    return _.isBoolean(val) ? 'Inactive' : 'Active';
  }

  trimValues(obj) {
    if (!obj) return obj;
    if (!Array.isArray(obj) && typeof obj != "object") return obj;
    return Object.keys(obj).reduce(
      (acc, key) => {
        acc[key.trim()] =
          typeof obj[key] == "string"
            ? obj[key].trim()
            : this.trimValues(obj[key]);
        return acc;
      },
      Array.isArray(obj) ? [] : {}
    );
  }

  removeEmptyAndTrim(obj, includeDeleteArrays) {
    return this.removeEmpty(this.trimValues(obj), includeDeleteArrays);
  }

  range(number) {
    return _.range(number);
  }

  get(obj, path) {
    return _.get(obj, path);
  }

  set(obj, path, value) {
    return _.set(obj, path, value);
  }

  debounce(cb, delay) {
    return _.debounce(cb, delay);
  }

  pluck(arr, field) {
    return arr.map((e) => e[field]).join(",");
  }

  has(obj, prop) {
    return _.has(obj, prop) && obj[prop] !== null && obj[prop] !== undefined;
  }
  without(sourceArr, excludeArr) {
    return _.without(sourceArr, ...excludeArr);
  }
  startCase(str)
  {
    return _.startCase(str);
  }
  last(arr) {
    return _.last(arr);
  }

  capitalize(str)
  {
    return _.capitalize(str);
  }
  upperFirst(str)
  {
    return _.upperFirst(str);
  }
}

const LodashHelperInstance = new LodashHelper();
export default LodashHelperInstance;

window.LodashHelper = LodashHelperInstance;
