import Highlighter from "react-highlight-words";
import { Button } from "../../../components";
import { URLS } from "../../../constants/constants";

import { USER_ROLES_DICTIONARY } from "../../../dictionaries";
import { DateHelper,   renderRecordValue } from "../../../utils";
import { renderPhoneNumber } from "../../../utils/helpers";

import styles from "./index.module.css";

const renderButtons = ({ value, switchRiderStatus, handleManageRider,Navigation }) => {
  const navigate =Navigation;
  // if (value.status === USER_STATUSES.DISABLED) {
  //   return [
  //     <div key={1} className={styles.restoreAccess}>
  //       <Button
  //         myClassName={styles.navigationBtn}
  //         onClick={switchRiderStatus({ status: { status: USER_STATUSES.ACTIVE }, id: value.id })}>
  //         Activate Rider
  //       </Button>
  //     </div>,
  //   ];
  // }

  return [
    <div key={0} className={styles.tableButton}>
      <Button
        myClassName={styles.navigationBtn}
        onClick={handleManageRider(value.id,Navigation)}
      >
        Manage Rider
      </Button>
    </div>,
    <div key={1} className={styles.tableButton}>
      <Button
        myClassName={styles.navigationBtn}
        onClick={() => {
          navigate.push(URLS.RIDES, {
            rider: {
              id: value?.id,
              firstName: value?.firstName,
              lastName: value?.lastName,
            },
          });
        }}
      >
        View Rider History
      </Button>
    </div>,
  ];
};

export const transformData = ({
  data,
  switchRiderStatus,
  handleManageRider,
  Navigation,
}) => {
  return data?.map((value) => {
    return {
      key: value.id,
      name:
        value.firstName &&
        value.lastName &&
        renderRecordValue(`${value.firstName} ${value.lastName}`),
      dob: value?.dob,
      healthOrganization: value?.healthOrganization,
      phoneNumber: value.phoneNumber,
      type: value.role,
      buttons: renderButtons({
        value,
        switchRiderStatus,
        handleManageRider,
        Navigation,
      }),
    };
  });
};

export const renderColumns = (isMHAdmin, searchValue, highlighted) =>
  [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => (
        <div className={styles.cellValue}>
          {searchValue && highlighted ? (
            <Highlighter
              highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
              searchWords={[searchValue]}
              autoEscape
              textToHighlight={text ? text.toString() : ""}
            />
          ) : (
            renderRecordValue(text)
          )}
        </div>
      ),
      width: isMHAdmin ? 220 : 300,
    },
    {
      title: "Day of Birth",
      dataIndex: "dob",
      key: "dob",
      render: (text) =>
        renderRecordValue(text && DateHelper.format(new Date(text), "MM/dd/Y")),
      width: isMHAdmin ? 200 : 240,
    },
    {
      title: "Health Organization",
      dataIndex: "healthOrganization",
      key: "healthOrganization",
      render: (text) => renderRecordValue(text),
      width: 240,
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      render: (text) => renderPhoneNumber(text),
      width: 240,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: isMHAdmin ? 100 : 140,
      render: (text) => (
        <div className={styles.cellValue}>
          {renderRecordValue(USER_ROLES_DICTIONARY[text], "Rider")}
        </div>
      ),
    },
    {
      title: "",
      key: "buttons",
      dataIndex: "buttons",
      render: (buttons) => (
        <div className={styles.btnPanel}>
          {buttons.map((Component) => Component)}
        </div>
      ),
      width: isMHAdmin ? 380 : 480,
    },
  ].filter((col) => (isMHAdmin ? col : col.title !== "Health Organization"));
