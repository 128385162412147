import { isString } from 'lodash';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { stepperInfo } from '../../../components/forms/CreateRide/stepperInfo';
import { URLS } from '../../../constants/constants';
import {
  createDraft,
  deleteDraft,
  operationTypes,
  resetDraft,
  saveDraft,
  setCurrentStep,
  updateDraft,
} from '../../../store/drafts';
import { LodashHelper } from '../../../utils';

export const useCreateRideHandlers = ({
  step,
  Navigation,
  formikValues,
  draft,
  draftId,
  showThirdStep,
  unblockHistory,
  setModalVisible,
  location,
  draftIsValid,
}) => {
  const dispatch = useDispatch();
const navigate = Navigation;
 
  const handleDelete = useCallback(() => {
   
    if (unblockHistory) {
      unblockHistory.current();
    }
    setModalVisible(false);
    dispatch(deleteDraft({ draftId, pathname: URLS.RIDES,Navigation:navigate }));
  }, [dispatch, draftId, setModalVisible, unblockHistory]);

  const handleStay = useCallback(() => setModalVisible(false), [setModalVisible]);

  const handlePreviousStep = useCallback(
    (stepNumber) => {
      let stepNavigateTo = isString(stepNumber) && stepNumber;
      if (step === 0) {
        navigate.push(navigate?.location?.state?.from);
      }

      if (!LodashHelper.isEqual(formikValues, draft?.[`step${step + 1}`])) {
        stepNavigateTo = stepNavigateTo || (!showThirdStep && step === 4 ? step - 2 : step - 1);
        dispatch(
          updateDraft({
            draft: { ...draft, [`step${step + 1}`]: formikValues },
            draftId,
            step: stepNavigateTo,
          }),
        );
      }

      if (showThirdStep || step !== 4) dispatch(setCurrentStep(stepNavigateTo || step - 1));
      else if (step === 4 && !showThirdStep) {
        dispatch(setCurrentStep(stepNavigateTo || step - 2));
      }
    },
    [dispatch, draft, draftId, formikValues,navigate, showThirdStep, step],
  );

  const handleSubmit = useCallback(
    (values, stepNumber) => {

      let stepNavigateTo = isString(stepNumber) && stepNumber;
      if (step === 0 && !draftId && !LodashHelper.isEqual(values, draft?.[`step${step + 1}`])) {
        stepNavigateTo = stepNavigateTo || step + 1;
        return dispatch(
          createDraft({
            draft: { [`step${step + 1}`]: values },
            step: stepNavigateTo,
          }),
        );
      }
      if (!LodashHelper.isEqual(values, draft?.[`step${step + 1}`])) {
        stepNavigateTo = stepNavigateTo || (!showThirdStep && step === 2 ? step + 2 : step + 1);
        return dispatch(
          updateDraft({
            draft: { ...draft, [`step${step + 1}`]: values },
            draftId,
            step: stepNavigateTo,
          }),
        );
      }

      if (showThirdStep || step !== 2) {
        stepNavigateTo = stepNavigateTo || step + 1;
        dispatch(setCurrentStep(stepNavigateTo));
      } else if (step === 2 && !showThirdStep) {
        stepNavigateTo = stepNavigateTo || step + 2;
        dispatch(setCurrentStep(stepNavigateTo));
      }
    },
    [dispatch, draft, draftId, showThirdStep, step],
  );

  const saveAndLeaveCallback = useCallback(() => {
    if (unblockHistory) {
      unblockHistory.current();
      if (location) {
        navigate.push(location.location.pathname);
      }
      navigate.push(URLS.DRAFTS);
    }
    dispatch(resetDraft());
  }, [dispatch, location, unblockHistory, navigate]);

  const handleSaveAndClose = useCallback(() => {
    if (step === 0 && !draftId && !LodashHelper.isEqual(formikValues, stepperInfo[step].values)) {
      dispatch(
        createDraft(
          {
            draft: { [`step${step + 1}`]: formikValues },
            type: operationTypes.CREATE_AND_LEAVE,
          },
          saveAndLeaveCallback,
        ),
      );
    } else if (!LodashHelper.isEqual(formikValues, stepperInfo[step]?.values)) {
      dispatch(
        updateDraft(
          {
            draft: { ...draft, [`step${step + 1}`]: formikValues },
            draftId,
            type: operationTypes.UPDATE_AND_LEAVE,
          },
          saveAndLeaveCallback,
        ),
      );
    } else {
      saveAndLeaveCallback();
    }
  }, [saveAndLeaveCallback, dispatch, draft, draftId, formikValues, step]);

  const handleSave = useCallback(
    () => dispatch(saveDraft({ draftId, unblockHistory,Navigation:navigate })),
    [dispatch, draftId, unblockHistory,navigate],
  );

  const handleBack = useCallback(() => navigate.push(navigate?.location?.state?.from), [navigate]);

  const handleLeave = useCallback(() => {
  
    if (unblockHistory) {
      unblockHistory.current();
      navigate.push(location?.location?.pathname);
    }
  }, [navigate, location, unblockHistory]);

  return {
    handleDelete,
    handleStay,
    handlePreviousStep,
    handleSaveAndClose,
    handleSave,
    handleBack,
    handleLeave,
    handleSubmit,
  };
};
