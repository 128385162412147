import React, { useState, useCallback } from 'react';

import { Modal, Button } from '../..';
import { renderRecordValue } from '../../../utils';

import styles from './index.module.css';

export const useDisableUserModal = ({ handleConfirm, userName, modalDescription }) => {
  const [visibleModal, setModalVisible] = useState(false);

  const onCancel = useCallback(() => setModalVisible(false), [setModalVisible]);
  return {
    DisableUserModal: (
      <Modal visible={visibleModal} onCancel={onCancel} closable width={560} title='Are you sure?'>
        <div className={styles.modalContent}>
          <p className={styles.modalDescription}>
            Do you want to deactivate <span className={styles.optionName}>{renderRecordValue(userName)}</span> account?
            They will not be able to access the system, though you can always restore their profile
          </p>
          <div className={styles.modalBtns}>
            <div>
              <Button myClassName={styles.navigationBtn} onClick={onCancel}>
              Cancel
              </Button>
            </div>
            <div>
              <Button myClassName={styles.navigationBtn} onClick={handleConfirm}>
                Confirm
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    ),
    setDisableUserModalVisible: setModalVisible,
  };
};
