import { Tooltip } from '../components';
import { DISABLED_FIELD_TOOLTIP_CONTENT } from '../constants/constants';

export const withTooltip = (Component, isVisible) => {
  return isVisible ? (
    <Tooltip
      title={<span>{DISABLED_FIELD_TOOLTIP_CONTENT}</span>}
      isVisible={isVisible}
      applyEditTooltipOverlayClassname
      applyEditTooltipChildrenClassname={isVisible}>
      {Component}
    </Tooltip>
  ) : (
    Component
  );
};
