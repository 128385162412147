import { Formik } from 'formik';

import { recurringRideModalOptions, recurringRideModalOptionsForRescheduleRides } from '../../forms/mock';
import { Modal, Button, RadioGroup } from '../..';
import { editRecurringSchema } from '../../../utils/validations';

import styles from './index.module.css';

export const renderEditRecurringModal = ({ visibleEditRecurringModal, handleCancel, handleEditRecurringSubmit,isReScheduling=false }) => (
  <Modal
    visible={visibleEditRecurringModal}
    onCancel={handleCancel}
    closable
    width={670}
    title='Edit Recurring Ride(s)'>
    <div className={styles.recurringModalContent}>
      <Formik
        initialValues={{ editSeriesOption: '' }}
        onReset={handleCancel}
        validationSchema={editRecurringSchema}
        validateOnMount
        onSubmit={handleEditRecurringSubmit}>
        {({ isValid, handleSubmit, handleReset }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className={styles.recurringModalOptions}>
                <RadioGroup name='editSeriesOption' radioFieldsCount={isReScheduling?recurringRideModalOptionsForRescheduleRides:recurringRideModalOptions} />
              </div>
              <div className={styles.recurringModalBtns}>
                <div className={styles.formButton}>
                  <Button myClassName={styles.cancelBtn} onClick={handleReset} type='reset'>
                    Cancel
                  </Button>
                </div>
                <div>
                  <Button myClassName={styles.saveBtn} disabled={!isValid} type='submit'>
                    Edit Ride(s)
                  </Button>
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  </Modal>
);
