import React, { useEffect, useRef } from 'react';

import { Input, Select, MaskInput } from '../..';
import { DEFAULT_PHONE_NUMBER_FORMAT, TIMEZONES } from '../../../constants/constants';

 

import styles from './index.module.css';
import { useLocation } from 'react-router-dom';

export const PersonalInfoPreferences = ({
  values,
  hideTimezoneField,
  disableAllFields,
  allowScrollToPersonsalInfo,
  rideOptions,
}) => {
  const personalInfoAnchorRef = useRef();
  const location = useLocation();
  useEffect(() => {
    location.hash &&
      allowScrollToPersonsalInfo &&
      window.scrollTo({
        top: personalInfoAnchorRef?.current.offsetTop - document.querySelector('header')?.offsetHeight,
        behavior: 'smooth',
      });
  }, [rideOptions, allowScrollToPersonsalInfo,location]);
  return (
    <>
      <h2 ref={personalInfoAnchorRef}>User Information</h2>
      <div className={styles.name}>
        <label className={styles.nameLabel}>
          <span className={styles.required}>First Name</span>
          <Input name='firstName' type='text' placeholder='First Name' disabled={disableAllFields} />
        </label>
        <label className={styles.nameLabel}>
          <span className={styles.required}>Last Name</span>
          <Input name='lastName' type='text' placeholder='Last Name' disabled={disableAllFields} />
        </label>
      </div>
      <div className={styles.personalInfo}>
        <label className={styles.personalInfoLabel}>
          <span className={styles.required}>Mobile Phone Number</span>
          <MaskInput
            name='phoneNumber'
            format={DEFAULT_PHONE_NUMBER_FORMAT}
            myClassName={styles.personalInfoLabel}
            placeholder={'###-###-####'}
            value={values.phoneNumber}
            disabled={disableAllFields}
          />
        </label>
        <label className={styles.personalInfoLabel}>
          <span className={styles.required}>Job Title</span>
          <Input
            type='text'
            name='jobTitle'
            myClassName={styles.input}
            placeholder='Job title'
            disabled={disableAllFields}
          />
        </label>
      </div>
      {!hideTimezoneField && (
        <label className={styles.timezone}>
          Time Zone
          <Select name='preferredTimezone' options={TIMEZONES} placeholder={'Pick one'} disabled={disableAllFields} />
        </label>
      )}
    </>
  );
};
