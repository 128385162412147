import React from "react"
import { Popconfirm, } from "antd";

export const PopConfirm = ({
//    Component, 
   title = "Confirm",
   description = "Are you sure?", 
   onConfirm,
   overlayStyle,
   onCancel, 
   okText="Yes",
   cancelText="No",
   open,
   zIndex= 1070,
   children,
})=>{
    return(
        <Popconfirm
        open={open}
        overlayStyle={overlayStyle}
            title={title} description={description}
            onConfirm={onConfirm} onCancel={onCancel} 
            okText={okText} cancelText={cancelText} zIndex={zIndex}
        >
            {children}
        </Popconfirm>)
}