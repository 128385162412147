import { useEffect, useState } from 'react'; 
import { redirectToPage } from '../utils/helpers';
import { Navigation } from '../utils/navigation';

export const usePagination = () => {
 
 const navigate = Navigation();
  const [query, setQuery] = useState(Number(new URLSearchParams(navigate?.location?.search).get('page')));

  useEffect(() => {
    setQuery(Number(new URLSearchParams(window?.location?.search).get('page')));
    return;
  }, [navigate?.location]);

  useEffect(() => {
    if (!query) redirectToPage(navigate?.location?.pathname, 1,navigate);
    return;
  }, [query]);

  return query;
};
