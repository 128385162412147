import React from 'react';
import classnames from 'classnames';

import { Button } from '../../..';

import styles from './index.module.css';

export const ButtonsPanel = ({
  disabled,
  step,
  handlePreviousStep,
  handleSaveAndClose,
  handleSubmit,
  myClassName,
  nextButtonText = 'Next',
}) => {
  const scrollToTop = () => (document.documentElement.scrollTop = 0);
  switch (step) {
    case 0:
      return (
        <div
          className={classnames({
            [styles.btns]: true,
            [myClassName]: myClassName,
          })}>
          <div>
            <Button
              myClassName={classnames({
                [styles.formButton]: true,
                [styles.navigationBtn]: true,
              })}
              onClick={handleSaveAndClose}
              type='button'>
              Save and Close
            </Button>
          </div>
          <div className={styles.btnsSteps}>
            <div>
              <Button myClassName={styles.formButton} type='submit' disabled={disabled} onClick={scrollToTop}>
                {nextButtonText}
              </Button>
            </div>
          </div>
        </div>
      );
    case 1:
    case 2:
    case 3:
      return (
        <div
          className={classnames({
            [styles.btns]: true,
            [styles.myClassName]: myClassName,
          })}>
          <div>
            <Button
              myClassName={classnames({
                [styles.formButton]: true,
                [styles.navigationBtn]: true,
              })}
              onClick={handleSaveAndClose}
              type='button'>
              Save and Close
            </Button>
          </div>
          <div className={styles.btnsSteps}>
            <div className={styles.btnsPrev}>
              <Button
                myClassName={classnames({
                  [styles.formButton]: true,
                  [styles.navigationBtn]: true,
                })}
                type='button'
                onClick={handlePreviousStep}>
                Previous Step
              </Button>
            </div>

            <div>
              <Button myClassName={styles.formButton} type='submit' disabled={disabled} onClick={scrollToTop}>
                {nextButtonText}
              </Button>
            </div>
          </div>
        </div>
      );

    case 4:
      return (
        <div
          className={classnames({
            [styles.btns]: true,
            [myClassName]: myClassName,
          })}>
          <div>
            <Button
              myClassName={classnames({
                [styles.formButton]: true,
                [styles.navigationBtn]: true,
              })}
              onClick={handleSaveAndClose}
              type='button'>
              Save and Close
            </Button>
          </div>
          <div className={styles.btnsSteps}>
            <div className={styles.btnsPrev}>
              <Button
                myClassName={classnames({
                  [styles.formButton]: true,
                  [styles.navigationBtn]: true,
                })}
                type='button'
                onClick={handlePreviousStep}>
                Go back and Edit
              </Button>
            </div>

            <div>
              <Button myClassName={styles.formButton} type='submit' disabled={disabled} onClick={handleSubmit}>
                Send Request
              </Button>
            </div>
          </div>
        </div>
      );

    default:
      return null;
  }
};
