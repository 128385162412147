import React, { useMemo } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import {
  vehicleOptionsDictionary,
  prefferedLanguageDictionary,
} from "../../../dictionaries";
import { DateHelper, renderRecordValue } from "../../../utils";
import { RIDE_ACTION_STATUSES, URLS, USER_ROLES } from "../../../constants/constants";
import { useCurrentRole } from "../../../hooks/useCurrentRole";
import { AdditionalInfo } from "./RideDetailsPartials/AdditionalInfo";
import { AdditionalOptions } from "./RideDetailsPartials/AdditionalOptions";
import { VehicleInfo } from "./RideDetailsPartials/VehicleInfo";
import { CaregiversInfo } from "./RideDetailsPartials/CaregiversInfo";
import { RiderInfo } from "./RideDetailsPartials/RiderInfo";
import { RideStatusInfo } from "./RideDetailsPartials/RideStatusInfo";
import { RideDestinationInfo } from "./RideDetailsPartials/RideDestinationInfo";
import { RidePickUpInfo } from "./RideDetailsPartials/RidePickUpInfo";
import {
  convertInchesToFeet,
  getOtherLegSeqIdOfRoundtrip,
  renderPhoneNumber,
} from "../../../utils/helpers";
import { RecurringInfo } from "./RideDetailsPartials/RecurringInfo";
import styles from "./index.module.css";
import { TimeDurationInfo } from "./RideDetailsPartials/TimeDurationInfo";
import { Divider, Timeline, Tooltip } from "antd";
import RideReceiptComponent from "./RideDetailsPartials/RideBillDetails/RideBillDetails";
import { Snackbar } from "../Snackbar/Snackbar";
import { OverNightRide } from "./RideDetailsPartials/OverNightRide";
import { ArrowIcon } from "../../../assets";

const lastSeriesRideDisclaimerInformation = {
  type: "error",
  title: "Last Ride in Series: Renew to Continue Service",
  description:
    "This is the last ride in the current series. If this rider needs additional rides, please renew to keep their transportation services active.",
    actionBtn: "Renew Ride Series",
};

export const Record = ({ title, value, myClassName, valueClassName }) =>
  value ? (
    <p
      className={classnames({
        [styles.record]: true,
        [myClassName]: myClassName,
      })}
    >
      <span className={styles.recordTitle}>{title}:</span>
      <span className={valueClassName}>{value}</span>
    </p>
  ) : null;

export const RideDetails = ({
  values,
  showFullInfo,
  handleRideStatusModal,
  airportBufferTime,
  showPrefferedLanguageBeforeAccept,
  role,
  tcConfiguration,
  handleRideRenewal
}) => {
  const { isTCWorker, isHOWorker, isMHAdmin,isHOAdmin,isTCAdmin } = useCurrentRole(role);
  const vehicleInformationRadioOpts = useMemo(() => {
    return [
      {
        name: "Wheelchair needs to be provided by the driver",
        value: values?.pickupPointAdditionalInfo?.isWheelchairNeeded,
      },
      {
        name: "Has a motorized wheelchair",
        value: values?.pickupPointAdditionalInfo?.hasMotorizedWheelchair,
      },
      {
        name: "Has stairs in or outside the home",
        value: values?.pickupPointAdditionalInfo?.hasStairs,
      },
      {
        name: "Rider is traveling with someone who uses a car seat or booster seat",
        value: values?.pickupPointAdditionalInfo?.needsCarSeat,
      },
      {
        name: "Car seat needs to be provided by the driver",
        value: values?.pickupPointAdditionalInfo?.needsAdditionalCarSeat,
      },
      {
        name: "Booster seat needs to be provided by the driver",
        value: values?.pickupPointAdditionalInfo?.needsBoosterSeat,
      },
      {
        name: "Rider needs driver escort from pick-up location to van",
        value: values?.pickupPointAdditionalInfo?.needsAdditionalCarSeat,
      },
      {
        name: "Rider needs driver escort from van to drop-off location",
        value: values?.pickupPointAdditionalInfo?.needsAdditionalCarSeat,
      },
      {
        name: "Rider is being picked up as a result of a discharge from a facility",
        value: values?.pickupPointAdditionalInfo?.dischargeFromFacility,
      },
    ];
  }, [values]);
  const timelineItemTail = "#de7f39";

  const showRoundTripHeading = useMemo(() => {

    if(values?.isRoundtrip && !values?.leg)
    {
      return true;
    }else if(values?.isRoundtrip && values?.connectedLeg)
    {
      return true;
    }

    return false;
  },[values]);
  const timelineItems = useMemo(() => {
    return values?.rideHistory?.map((item) => {
      const hoverItem = `${item.comment ? item.comment : ''}`;
      
      return {
        label: DateHelper.renderDateTimeWithTZ({
          dateTime: item.createdAt,
          timeZone: values?.timezone,
          useFullFormattedDate: true
        }),
        children: (
          <> 
          {hoverItem !== "" ? (
              <Tooltip
                color={timelineItemTail}
                title={
                  <span
                    className={classnames({
                      [styles.tooltipContent]: true,
                      [styles.itemPos]: true,
                    })}
                  >
                    {hoverItem}
                  </span>
                }
              >
                { item.newStatus.toLowerCase().includes('dispute') ? 
                (<span><b>Change Request Status: </b></span>) : ''
                }
                 <span className={hoverItem ? styles.rideActionPointer : ""}> 
                  {item.rideAction}
                </span>
                <br />
              </Tooltip>
            ) : (
              <>
                <span>{item.rideAction}</span>
                <br />
              </>
            )}
            <span className={styles.detailFont}>
              <span className={styles.singleLine}><b>Name:</b> {item.user ? item.user.firstName+' '+item.user.lastName : 'System' } </span>
              <span className={styles.singleLine}><b>Organization:</b> {item.user?.role === USER_ROLES.SUPER_ADMIN ? `MedHaul (for ${item.organization.name})` : item.organization.name} </span>
              <span className={styles.singleLine}><b>Title:</b> {item.user?.role === USER_ROLES.SUPER_ADMIN ? 'MH Admin' : item.user?.role || 'MedHaul'} </span>
              <span className={styles.singleLine}><b>Phone Number:</b> {renderPhoneNumber(item.user?.phoneNumber)} </span>
              {item.rideAction === RIDE_ACTION_STATUSES.CREATED && <span className={styles.singleLine}><b>Email:</b> {item.user?.email} </span>}
              {item?.notifications?.length > 0 && (
                <span className={styles.singleLine}>
                  <b>Notifications: </b>
                    {item?.notifications?.map((element) => (
                      <Tooltip
                        key={element.id}
                        color={timelineItemTail}
                        title={
                          <span
                            className={classnames({
                              [styles.tooltipContent]: true,
                              [styles.itemPos]: true,
                                                          })}
                          >
                            {<div dangerouslySetInnerHTML={{ __html: element.body }} />}
                          </span>
                        }
                      >
                        <span
                          className={classnames({
                            [styles.notificationItem]: true,
                            [styles.rideActionPointer]: true,
                          })}
                        >
                          {element.messageType} - {element.recipient}
                        </span>
                        <br />
                      </Tooltip>
                    ))}
                </span>
              )}

            </span>
          </>

        ),
        color: timelineItemTail,
      };
    });
  }, [values?.rideHistory, values?.timezone]);
  return (
    <div className={styles.container}>
    <div className={classnames({ [styles.rideDetails]: true,  [styles.column1] :true, })} >
      {values?.isRoundtrip && values?.connectedLegId && (values?.connectedLeg?true:!isTCWorker)  && (
        <p className={styles.otherLeg}>
          Other leg of the ride –{" "}
          <NavLink
            className={styles.link}
            to={`${URLS.RIDES}/${values?.connectedLegId}`}
          >
            ID{" "}
            {renderRecordValue(
              getOtherLegSeqIdOfRoundtrip(values?.seqId, values?.leg)
            )}
          </NavLink>
        </p>
      )}
      {(isHOWorker || isMHAdmin) && values?.isLastRecurring && (
        <>
        <div className={styles.latestSnackbar}>
          <Snackbar
            type={lastSeriesRideDisclaimerInformation.type}
            title={lastSeriesRideDisclaimerInformation.title}
            content={lastSeriesRideDisclaimerInformation.description}
            actionBtn={isHOWorker?lastSeriesRideDisclaimerInformation.actionBtn: undefined}
            handleActionBtn={handleRideRenewal}
          />

        {
        values?.isRecurring && values?.recurringRideIndex && !isTCWorker && (
          <RecurringInfo info={values?.recurringRideIndex} />
          )
        }
        </div>
        <Record
          title="Request Created"
          value={renderRecordValue(
            values?.createdAt &&
              DateHelper.renderDateTimeWithTZ({
                dateTime: values?.createdAt,
                timeZone: values?.pickUpDate.timezone,
              })
          )}
          myClassName={styles.createdAt}
        />
        <Record
          title="Created By"
          value={`${renderRecordValue(
            values?.createdBy?.firstName
          )} ${renderRecordValue(values?.createdBy?.lastName)}`}
        />
      </>
      )}
      <div className={styles.rideDetailsHead}>
        {showFullInfo && (
          <div className={styles.riderInformation}>
            <RiderInfo
              rider={values?.riderInfo}
              showFullInfo={showFullInfo}
              isHOWorker={isHOWorker}
              isMHAdmin={isMHAdmin}
            />
          </div>
        )}

        
      </div>
      <VehicleInfo
        vehicleType={renderRecordValue(
          vehicleOptionsDictionary[values?.vehicleType]
        )}
        status={Boolean(values?.readableStatus)}
      />

      {values?.status && (
        <RideStatusInfo
          riderInfo={values?.riderInfo}
          driver={values?.driver}
          readableStatus={values?.readableStatus}
          showFullInfo={showFullInfo}
          handleRideStatusModal={handleRideStatusModal}
          role={role}
          startDate={{
            datetime: values?.startDate,
            timezone: values?.pickUpDate?.timezone,
          }}
          endDate={{
            datetime: values?.endDate,
            timezone: values?.pickUpDate?.timezone,
          }}
          driverArrivalTime={values?.driverArrivalTime ? {
            datetime: values?.driverArrivalTime,
            timezone: values?.pickUpDate?.timezone,
          } : null}
          endRideComment={values?.endRideComment}
          isTCWorker={isTCWorker}
          isHOWorker={isHOWorker}
          isMHAdmin={isMHAdmin}
          timezone={values?.pickUpDate?.timezone}
          cancellationInfo={values?.cancellationInfo}
          unableToFulfillInfo={values?.unableToFulfillInfo}
          declineInfo={values?.declineInfo}
        />
      )}

      {isTCWorker && !showFullInfo && values?.riderInfo?.weight && (
          <Record
            title="Weight, lb(s)"
            value={renderRecordValue(values?.riderInfo?.weight)}
          />
      )}

      {isTCWorker && !showFullInfo && values?.riderInfo?.height && (
          <Record
            title="Height (Feet/Inches)"
            value={convertInchesToFeet(values?.riderInfo?.height)}
          />
      )}
      {showPrefferedLanguageBeforeAccept && (
        <Record
          title="Rider's Preferred Language"
          value={renderRecordValue(
            prefferedLanguageDictionary[values?.riderInfo?.preferredLanguage]
          )}
          myClassName={styles.ridersPreferredLanguage}
        />
      )}
         {values?.isOvernightRide && (
          <div className={classnames({[styles.d_flex]:true, [styles.mt5]:true})}>
             <span className={styles.recordTitle}>{'Schedule'}:</span>
        <OverNightRide/>
        </div>
      )}
      <AdditionalOptions
        rideOptions={values?.rideOptions || []}
        vehicleInformationRadioOpts={vehicleInformationRadioOpts}
      />
      {showFullInfo &&
        values?.pickupPointAdditionalInfo?.caregivers?.length > 0 && (
            <div className={styles.caregiverInfo}>
              <CaregiversInfo
                title="Caregiver"
                caregivers={values?.pickupPointAdditionalInfo?.caregivers}
                showFullInfo={showFullInfo}
              />
            </div>
        )}
      {(showRoundTripHeading) && <h3 className={styles.roundTripHeader}>Roundtrip Ride</h3>}
      <div className={styles.routes}>
        <RidePickUpInfo
          pickupAddressDetails={values?.pickupAddressDetails}
          pickUpDate={values?.pickUpDate}
          riderDescription={values?.riderDescription}
          pickUpDateLowerLimit={values?.pickUpDateLowerLimit}
          isPickUpDateFlexible={values?.isPickUpDateFlexible}
          tcEstimatedPickUpDate={values?.tcEstimatedPickUpDate}
          securityGate={values?.pickUpSecurityGateDetails}
          flightInfo={values?.flightInfo}
          airportBufferTime={airportBufferTime}
          appointmentDate={values?.appointmentDate}
          isRecurringRide={values?.isRecurring}
          leg={values?.isRoundtrip ? (values?.leg  ? values?.leg:'A'):''}
          seqId={values?.seqId}
          destinationAddress={values?.isRoundtrip ? values?.destinationAddress : null}
        />

        <div className={styles.destionationRouteInfo}>
          <div className={styles.arrows}>
            { !values?.connectedLeg && (
            (values?.isRoundtrip  && !values?.leg)  ? (
              <>
                {/* <ArrowIcon fill="#bdbdbd" />
                <ArrowIcon fill="#bdbdbd" className={styles.arrowLeft} /> */}
              </>
            ) : (
              <ArrowIcon fill="#bdbdbd" />
            ))
            }
          </div>

          <RideDestinationInfo
            destinationAddress={values?.destinationAddress}
            originAddress={values?.isRoundtrip ? values?.pickupAddressDetails : null}
            showRoundtripInfo={
              values?.isRoundtrip && values?.connectedLeg ? true : (!isTCWorker && !values?.leg)
            }
            securityGate={values?.destinationSecurityGateDetail}
            returnTime={values?.connectedLeg?values?.connectedLeg?.pickUpDate:values?.returnTime}
            returnTimeLowerLimit={values?.returnTimeLowerLimit}
            isReturnDateFlexible={values?.isReturnDateFlexible}
            leg={values?.isRoundtrip ? (values?.connectedLeg ? values?.connectedLeg?.leg: 'B'):''}
            seqId={values?.connectedLeg?.seqId}
          />
        </div>
      </div>

      {values?.estMileage && (
          <TimeDurationInfo
            values={values}
            isTCWorker={isTCWorker}
            isMHAdmin={isMHAdmin}
            tcConfiguration={tcConfiguration}
          />
        )}

      {values?.rideNotes && (
        <div className={styles.otherInformation}>
          <label className={styles.originAddress}>
            <span>Other information /Notes:</span>
          </label>
          <p>{values?.rideNotes}</p>
        </div>
      )}
      {values?.additionalInformation && (
        <AdditionalInfo
          additionalFundingSource={
            values?.additionalInformation?.additionalFundingSource
          }
          costCenter={values?.additionalInformation?.costCenter}
          documentReasonForRide={
            values?.additionalInformation?.documentReasonForRide
          }
          otherReason={values?.additionalInformation?.otherReason}
        />
      )}

      {  
        <RideReceiptComponent 
        rideReceipt={values?.rideReceipt} actMileage={values?.actMileage} tcReceipt={values?.tcReceipt}
          seqId={values?.seqId} isHOAdmin={isHOAdmin} isMHAdmin={isMHAdmin} isTCAdmin={isTCAdmin}
        />
      }

    </div>  
      
    { role === USER_ROLES.SUPER_ADMIN && values?.rideHistory?.length > 0 &&
      (<>
        <Divider type="vertical" className={styles.divider} />
        <div className={styles.column2}>
          <h2 className={styles.rideHistory}>Ride History</h2>
          <Timeline mode={'left'} items={timelineItems} />
        </div>
      </>)
    }

</div>
  );
};

RideDetails.propTypes = {
  handleSave: PropTypes.func,
  values: PropTypes.object,
  step: PropTypes.number,
  handlePreviousStep: PropTypes.func,
  handleSaveAndClose: PropTypes.func,
  airportBufferTime: PropTypes.oneOfType([PropTypes.number, PropTypes.any]),
};
