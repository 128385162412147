import React, { useCallback } from "react";
import classnames from "classnames";
import { Formik } from "formik";
import { Modal, Button, Select } from "../../../components";
import {
  CANCEL_RIDE_OPTIONS,
  CANCEL_ROUNDTRIP_HEADING,
  CANCEL_ROUNDTRIP_POPUP_TITLE,
  REASONS_TO_EDIT_RIDE,
} from "../../../constants/constants";
import { roundTripCancelRideSchema } from "../../../utils/validations";
import styles from "./index.module.css";
import { RidePickUpInfo } from "../../../components/common/RideDetails/RideDetailsPartials/RidePickUpInfo";
import { RideDestinationInfo } from "../../../components/common/RideDetails/RideDetailsPartials/RideDestinationInfo";

const initialValues = { reason: "", otherReason: "" };

export const CancelRideModal = ({
  id,
  role,
  ride,
  visibleModal,
  onCancel,
  onConfirm,
}) => {
  const handleReasonForUpdate = useCallback(
    async ({ name, value, setFieldValue }) => {
      if (value !== REASONS_TO_EDIT_RIDE.CUSTOM_REASON)
        await setFieldValue("additionalComments", "");
      await setFieldValue(name, value);
    },
    []
  );

  return (
    <Modal
      visible={visibleModal}
      onCancel={onCancel}
      closable
      width="auto"
      bodyStyle={{ overflow: "auto", minHeight: '45vh'  }}
      title={
        ride?.connectedLeg
          ? CANCEL_ROUNDTRIP_POPUP_TITLE
          : `Canceling Ride Request – ID ${id}`
      }
    >
      <div className={styles.modalContent}>
        <Formik
          initialValues={initialValues}
          validationSchema={roundTripCancelRideSchema}
        >
          {({ values, isValid, resetForm, validateForm }) => {
            return (
              <>
                {ride?.connectedLeg && (
                  <div className={styles.routes}>
                    <h3 className={styles.roundTripHeading}>
                      {CANCEL_ROUNDTRIP_HEADING}
                    </h3>

                    <h3 className={styles.roundTripHeadingText}>
                      Roundtrip Ride testing for TC
                    </h3>
                    <RidePickUpInfo
                      pickupAddressDetails={ride?.pickupAddressDetails}
                      destinationAddress={ride?.destinationAddress}
                      pickUpDate={ride?.pickUpDate}
                      riderDescription={ride?.riderDescription}
                      pickUpDateLowerLimit={ride?.pickUpDateLowerLimit}
                      isPickUpDateFlexible={ride?.isPickUpDateFlexible}
                      tcEstimatedPickUpDate={ride?.tcEstimatedPickUpDate}
                      securityGate={ride?.pickUpSecurityGateDetails}
                      flightInfo={ride?.flightInfo}
                      appointmentDate={ride?.appointmentDate}
                      isRecurringRide={ride?.isRecurring}
                      leg={ride?.leg}
                      seqId={ride?.seqId}
                    />

                    <div className={styles.destionationRouteInfo}>
                      <RideDestinationInfo
                        destinationAddress={ride?.destinationAddress}
                        originAddress={ride?.pickupAddressDetails}
                        showRoundtripInfo={ride?.isRoundtrip && ride?.leg}
                        securityGate={ride?.destinationSecurityGateDetail}
                        returnTime={ride?.connectedLeg?.pickUpDate}
                        returnTimeLowerLimit={ride?.returnTimeLowerLimit}
                        isReturnDateFlexible={ride?.isReturnDateFlexible}
                        leg={ride?.connectedLeg?.leg}
                        seqId={ride?.connectedLeg?.seqId}
                      />
                    </div>


                  </div>
                )}

                <form>
                  <label className={styles.cancelRideLabel}>
                    Why are you unable to fulfill this ride?
                    <Select
                      name="reason"
                      options={CANCEL_RIDE_OPTIONS[role]}
                      onChange={handleReasonForUpdate}
                      placeholder="Pick an option"
                    />
                  </label>
                  {/* { (
                    <label className={styles.cancelRideLabel}>
                      <span >
                      Additional Comments for ride Cancelation
                      </span>
                      <InputTextArea
                        name="additionalComments"
                        placeholder="Please fill in reason for ride Cancelation"
                        type="text"
                        autosize={{ minRows: 1, maxRows: 5 }}
                      />
                    </label>
                  )} */}
                </form>


                <div
                  className={classnames({
                    [styles.modalBtns]: true,
                    [styles.cancelBtns]: true,
                  })}
                >
                  <div className={styles.mr6}>
                    <Button
                      myClassName={styles.navigationBtn}
                      onClick={onCancel}
                    >
                      {ride?.connectedLeg ? `Keep Roundtrip` : `Don’t Cancel`}
                    </Button>
                  </div>
                  <div className={styles.ml6}>
                    <Button
                      myClassName={styles.navigationBtn}
                      onClick={onConfirm(values)}
                      disabled={!isValid || !values?.reason}
                    >
                      {ride?.connectedLeg
                        ? `Cancel Roundtrip`
                        : `Cancel Ride Request`}
                    </Button>
                  </div>
                </div>
              </>
            );
          }}

        </Formik>
      </div>
    </Modal>
  );
};
