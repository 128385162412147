import { LodashHelper } from ".";

export class DraftHelper {
  constructor(rider) {
    this.rider = rider;
  }

  //This method only used when we create rider while creating the ride request. Every rider that is created on the process of creating ride should be editable on creating ride process
  static updateStepOneDraftForNewRider(values) {
    
    let riderDraft = {};

    if(values?.riderDraft?.id)
    {
     riderDraft.id =  values?.riderInfo.id;
    }
    riderDraft.firstName = values?.riderInfo.firstName;
    riderDraft.lastName = values?.riderInfo.lastName;
    riderDraft.canPhoneReceiveCalls = values?.riderInfo.canPhoneReceiveCalls;
    riderDraft.canPhoneReceiveTexts = values?.riderInfo.canPhoneReceiveTexts;
    riderDraft.communicationPreferences =
      values?.riderInfo.communicationPreferences;
    riderDraft.patientUniqueId = values?.riderInfo.patientUniqueId;
    riderDraft.phoneNumber = values?.riderInfo.phoneNumber;
    riderDraft.dob = values?.riderInfo.dob;
    
    return riderDraft;
  }
  static getFirstStepDraftValues(values, usedExistingRider) {
     
    return LodashHelper.removeEmptyAndTrim({
      covid19Relation: values?.covid19Relation,
      caregivers: values?.caregivers,
      willBeCaregiverOnScene: values?.willBeCaregiverOnScene,
      riderInfo: usedExistingRider ? values?.riderInfo?.id : "",
      riderDraft: values?.riderDraft === undefined ? values?.riderInfo
        : this.updateStepOneDraftForNewRider(values),
    });
  }
  static prepareDraftForSaving = (draft) => {
    return LodashHelper.removeEmptyAndTrim({
      ...draft,
      step1: this.getFirstStepDraftValues(
        draft?.step1,
        draft?.step1?.riderInfo?.id || draft?.step1?.riderDraft?.id
      ),
    });
  };
}
