import React, { useEffect, useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { EntitiesTableList } from '../../../components';

import { fetchDrivers, setCurrentPage, changeDriverStatus, clearDriversInfo } from '../../../store/drivers';
import { resendInvitationForUser, sendNewPassword } from '../../../store/authorization';
import { renderColumns, transformData } from './renderColumns';
import { USER_ROLES } from '../../../constants/constants';
import { redirectToPage } from '../../../utils/helpers';
import { usePagination } from '../../../hooks/usePagination';
import { useSearch } from '../../../hooks/useSearch';
import { Navigation } from '../../../utils/navigation';

const TC_Admin_Drivers = ({
  drivers,
  fetchDrivers,
  sendNewPassword,
  
  limit,
  currentPage,
  total,
  totalPages,
  changeDriverStatus,
  clearDriversInfo,
  resendInvitationForUser,
}) => {
  const navigate = Navigation();
  usePagination(navigate);
  const { searchValue, highlighted, handleChangeSearch } = useSearch(fetchDrivers);
 
  useEffect(() => {fetchDrivers({Navigation:navigate}); return;}, [fetchDrivers]);

  useEffect(() => () =>{ clearDriversInfo(); return;}, [clearDriversInfo]);

  const handleSendPassword = useCallback(
    (id, allowRequest = true) =>
      () =>
        allowRequest && sendNewPassword(id),
    [sendNewPassword],
  );

  const handleResendInvitation = useCallback((id) => () => resendInvitationForUser(id), [resendInvitationForUser]);

  const switchDriverStatus = useCallback((payload) => () => changeDriverStatus(payload), [changeDriverStatus]);

  const handleChangePage = (page) => {
    redirectToPage(navigate?.location?.pathname, page,navigate);
    fetchDrivers({value:searchValue,Navigation:navigate});
  };

  const handleManageDriver = useCallback(
    (id) => () => navigate.push(`${navigate?.location?.pathname}/${id}/edit`),
    [navigate],
  );

  const tableData = useMemo(() => {
    return transformData({
      data: drivers,
      handleManageDriver,
      handleSendPassword,
      switchDriverStatus,
      handleResendInvitation,
      Navigation:navigate
    });
  }, [drivers, handleManageDriver, handleSendPassword, switchDriverStatus, handleResendInvitation]);

  const columns = useMemo(() => {
    return drivers?.length > 0 ? renderColumns(searchValue, highlighted) : null;
  }, [drivers?.length, searchValue, highlighted]);

  return (
    <EntitiesTableList
      title='Drivers'
      addNewBtnTitle='Add New Driver'
      Navigation={navigate}
      columns={columns}
      data={tableData}
      handleChangePage={handleChangePage}
      showAddNewButton
      handleChangeSearch={handleChangeSearch}
      searchValue={searchValue}
      limit={limit}
      currentPage={currentPage}
      total={total}
      totalPages={totalPages}
    />
  );
};

const mapStateToProps = (state) => ({
  drivers: state.drivers.drivers,
  limit: state.authorization.role === USER_ROLES.DRIVER ? 5 : 10,
  currentPage: state.drivers.currentPage,
  total: state.drivers.total,
  totalPages: state.drivers.totalPages,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { fetchDrivers, sendNewPassword, setCurrentPage, changeDriverStatus, clearDriversInfo, resendInvitationForUser },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(TC_Admin_Drivers);
