import React, { useMemo, useCallback } from "react";
import { CopyOutlined, EditOutlined } from "@ant-design/icons";
import { useParams } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { RideDetailsPage } from "../..";
import { hoCancelRide } from "../../../store/rides";
import { copyRide } from "../../../store/drafts";
import { Button, useCancelRideByHOModal } from "../../../components";

import { RIDE_STATUSES } from "../../../constants/constants";
import { useEditReccuringModal } from "../../../hooks/useEditRecurringModal";
import { RideActionsPanel } from "../../../components/common/RideActionsPanel/RideActionsPanel";

import styles from "./index.module.css";
import { Navigation } from "../../../utils/navigation";

const HO_RideDetails = ({ hoCancelRide, ride, role, copyRide }) => {
  const { EditRecurringModal, handleEditRide } = useEditReccuringModal();
  const navigate = Navigation();

  const cancelableRide = useMemo(
    () =>
      ride?.status === RIDE_STATUSES.PENDING ||ride?.status === RIDE_STATUSES.RESCHEDULED ||
      ride?.status === RIDE_STATUSES.ACCEPTED,
    [ride]
  );
  const { id } = useParams();

  const handleConfirmCancel = (values) => {
    setCancelModalVisible(false);
    hoCancelRide({ rideId: id, payload: values, userRole: role });
  };

  const { Modal, setCancelModalVisible } = useCancelRideByHOModal({
    id: ride?.seqId,
    role,
    ride,
    onConfirm: (values) => handleConfirmCancel(values),
  });

  const onEdit = () => {
    handleEditRide(id, ride?.isRecurring);
  };

  const handleCopyRide = useCallback(
    () => 
      
      {

        
        if(ride?.connectedLegId)
          {
           return copyRide(ride?.leg.toLowerCase() === 'a' ? ride?.id:ride?.connectedLegId ,navigate)
          }

      return copyRide(id, navigate)},
    [copyRide, id,ride]
  );

  const handleToggleModal = useCallback(
    (flag) => () => setCancelModalVisible(flag),
    [setCancelModalVisible]
  );

  const rideActions = [
    { id: 0, Icon: EditOutlined, action: onEdit },
    { id: 1, Icon: CopyOutlined, action: handleCopyRide },
  ];

  return (
    <>
      <RideDetailsPage showFullInfo handleRideRenewal={handleCopyRide} />
      {ride && <RideActionsPanel entities={rideActions} />}
      {EditRecurringModal}
      {cancelableRide && (
        <div className={styles.pageContent}>
          <div className={styles.cancelRideBtn}>
            <Button
              myClassName={styles.customBtn}
              onClick={handleToggleModal(true)}
            >
              Cancel Ride
            </Button>
          </div>
        </div>
      )}
      {Modal}
    </>
  );
};

const mapStateToProps = (state) => ({
  ride: state.rides.ride,
  role: state.authorization.role,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      hoCancelRide,
      copyRide,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(HO_RideDetails);
