import { Switch as AntdSwitcher } from 'antd';
import classNames from 'classnames';

import { withTooltip } from '../../../hocs/withTooltip';
import { CustomField } from '../CustomField/CustomField';

import styles from './index.module.css';

export const CustomSwitcher = ({
  field,
  form,
  value,
  onChange,
  disabled,
  myclassname,
  name,
  whenFieldDisabledShowTooltip,
  allowClear,
  ...rest
}) => {
  const handleChange = (value) => {
    if (onChange) {
      onChange({
        name: field?.name,
        setFieldValue: form?.setFieldValue,
        setValues: form?.setValues,
        values: form?.values,
        validateForm: form?.validateForm,
        value,
      });
    } else field.onChange(value);
  };
  return withTooltip(
    <AntdSwitcher
      checked={value}
      onChange={handleChange}
      className={classNames({
        [styles.switcher]: true,
        [myclassname]: myclassname,
      })}
      disabled={disabled}
    />,
    whenFieldDisabledShowTooltip,
  );
};

export const Switcher = ({
  type,
  name,
  myClassName,
  value,
  onChange,
  disabled,
  placeholder,
  suffix,
  prefix,
  min,
  max,
  handleBlurField,
  showAuthIcons,
  allowClear,
  fastField,
  ...rest
}) => {
  return (
    <CustomField
      fastField={fastField}
      name={name}
      disabled={disabled}
      onChange={onChange}
      value={value}
      myclassname={myClassName}>
      {({ field, form }) => {
        return (
          <>
            <CustomSwitcher
              form={form}
              field={field}
              name={name}
              disabled={disabled}
              onChange={onChange}
              value={value}
              myclassname={myClassName}
              {...rest}
            />
          </>
        );
      }}
    </CustomField>
  );
};
