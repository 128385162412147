import classNames from "classnames";
import React, { useEffect, useMemo, useState } from "react";
import { Checkbox, DatePicker, Select, Snackbar, Tooltip } from "../../..";
import {
  APPOINTMENT_DATE_TOOLTIP_CONTENT,
  DEFAULT_TIME_FORMAT, TIMEZONES,
  TIMEZONE_TOOLTIP_CONTENT
} from "../../../../constants/constants";
import { DateHelper } from "../../../../utils";
import { ThirdStepTooltip } from "./ThirdStepTooltip";

import styles from "./index.module.css";

import { InfoOutlined } from "@ant-design/icons";

export const PickUpDateTimeSection = ({
  handleUseWindowOfTime,
  handlePickUpDateTime,
  handleChangeTZ,
  pickUpDate,
  appointmentDate,
  disabledDatesForPickUpDate,
  disabledDatesForAppointment,
  disabledDateTime,
  values,
  windowOfTimeIsValid,
  disabledValues,
  minDelayToStartRide,
  maxPickUpWindowOfTime,
  disabledDateForHigherLimit,
  disabledTimeForHigherLimit,
  handleChangePickUpDateToLowerLimit,
  pickUpDateLowerLimit,
  handleChangeappointmentDate,
  handleAppointmentDateCheckBox,
}) => {
  const [isAppointmentDateSelected, setIsAppointmentDateSelected] =
    useState(false);
  const minAvailablePickUpDate = useMemo(
    () =>
      DateHelper.addDates(
        DateHelper.utcToZonedTime(Date.now(), values?.pickUpDate?.timezone),
        minDelayToStartRide
      ),
    [values?.pickUpDate?.timezone, minDelayToStartRide]
  );

  const diabledDatesForPickUpField = useMemo(
    () =>
      values?.isPickUpDateFlexible
        ? disabledDateForHigherLimit
        : disabledDatesForPickUpDate(minAvailablePickUpDate),
    [
      values?.isPickUpDateFlexible,
      disabledDateForHigherLimit,
      disabledDatesForPickUpDate,
      minAvailablePickUpDate,
    ]
  );
  const diabledDatesForAppointmentField = useMemo(
    () =>
      values?.isPickUpDateFlexible
        ? disabledDateForHigherLimit
        : disabledDatesForAppointment(pickUpDate?pickUpDate:minAvailablePickUpDate),
    [
      values?.isPickUpDateFlexible,
      disabledDateForHigherLimit,
      disabledDatesForAppointment,
      minAvailablePickUpDate,
    ]
  );

  const disabledDateTimeForPickUpField = useMemo(
    () =>
      values?.isPickUpDateFlexible
        ? disabledTimeForHigherLimit
        : disabledDateTime(minAvailablePickUpDate,pickUpDate),
    [
      values?.isPickUpDateFlexible,
      disabledTimeForHigherLimit,
      disabledDateTime,
      pickUpDate,
      minAvailablePickUpDate,
    ]
  );

  const disabledDateTimeForAppointmentField = useMemo(
    () =>
    
        disabledDateTime(appointmentDate, pickUpDate?pickUpDate:minAvailablePickUpDate),
    [
      values?.appointmentDate,
       pickUpDate,
      appointmentDate,
    ]
  );

  const pickUpDateFieldLabel = useMemo(
    () =>
      values?.isPickUpDateFlexible
        ? "Latest pickup time"
        : "Pick Up Date & Time",
    [values?.isPickUpDateFlexible]
  );

  const pickUpDateFieldDisabled = useMemo(
    () =>
      !values?.pickUpDate?.timezone ||
      (values?.isPickUpDateFlexible && !pickUpDateLowerLimit) ||
      disabledValues?.pickUpDate,
    [
      values?.pickUpDate?.timezone,
      values?.isPickUpDateFlexible,
      pickUpDateLowerLimit,
      disabledValues?.pickUpDate,
    ]
  );

  const pickUpDefaultDateTime = useMemo(
    () => new Date().setHours(0, 0, 0, 0),
    []
  );

  const handleChangeAppointmentDate = (e) => {
    setIsAppointmentDateSelected(e.checked);
    handleAppointmentDateCheckBox(e.checked);
  };

  useEffect(() => {
    const selectAppointmentDate =
      values?.appointmentDate && values?.appointmentDate?.datetime !== "";
    selectAppointmentDate &&
      setIsAppointmentDateSelected(selectAppointmentDate);
    return;
  }, [values?.appointmentDate]);
  return (
    <>
      <h3 className={styles.subHeadings}>Pickup Date and Time Information:</h3>
      


      <div className={styles.windowOfAppointmentTime}>
        <label className={styles.useWindowOfTime}>
          <Checkbox
            name="showAppointmentDateTimeZone"
            checked={isAppointmentDateSelected}
            onChange={handleChangeAppointmentDate}
          />
          <span className={styles.checkboxLabelText}>
            This rider is being picked up to go to an appointment
          </span>
        </label>
      </div>
     

       
      {isAppointmentDateSelected && (
        <div className={styles.dateAndTime}>
          <label className={styles.timezone}>
            <span>Timezone</span>
            <Select
              name="appointmentDate.timezone"
              options={TIMEZONES}
              onChange={handleChangeTZ}
              placeholder={"Pick one"}
              
              disabled={true}
              whenFieldDisabledShowTooltip={Boolean(
                disabledValues?.appointmentDate
              )}
            />
          </label>
          <label
            className={classNames({
              [styles.endDate]: values?.isPickUpDateFlexible,
              [styles.datepicker]: !values?.isPickUpDateFlexible,
            })}
          >
            <span>Appointment Date & Time</span>
            {/* <span className={styles.midnightNoonMessage}>
              {Midnight_Noon_Clarification}
            </span> */}
            <div className={styles.appointmentWrapper}>
              <DatePicker
                name="appointmentDate.datetime"
                allowClear
                onChange={handleChangeappointmentDate}
                format={DEFAULT_TIME_FORMAT}
                value={appointmentDate}
                showTime={{ defaultValue: pickUpDefaultDateTime }}
                showNow={false}
                use12Hours
                // disabled={!pickUpDate}
               // disabledDate={diabledDatesForAppointmentField}
               // disabledTime={disabledDateTimeForAppointmentField}
                inputReadOnly
                getPopupContainer={(trigger) => trigger}
              />
              <div className={styles.appointmentDate}>
                <Tooltip
                  title={
                    <span className={styles.tooltipContent}>
                      {APPOINTMENT_DATE_TOOLTIP_CONTENT}
                    </span>
                  }
                >
                  <span className={styles.tooltipInfo}>
                    <InfoOutlined className={styles.infoIcon} />
                  </span>
                </Tooltip>
              </div>
            </div>
          </label>
        </div>
      )} 

         <div className={styles.windowOfTime}>
        <label className={styles.useWindowOfTime}>
          <Checkbox
            name="isPickUpDateFlexible"
            checked={values?.isPickUpDateFlexible}
            onChange={handleUseWindowOfTime}
            disabled={disabledValues?.isPickUpDateFlexible}
            whenFieldDisabledShowTooltip={Boolean(
              disabledValues?.isPickUpDateFlexible
            )}
          />
          <span className={styles.checkboxLabelText}>
            The pickup time is flexible
          </span>
        </label>
        <ThirdStepTooltip
          range={maxPickUpWindowOfTime}
          useFlexibleHoursMessage
        />
      </div>

    
      
      <div className={styles.dateAndTime}>
        <label className={styles.timezone}>
          <span className={styles.required}>Timezone</span>
          <div className={styles.timezoneSection}>
            <Select
              name="pickUpDate.timezone"
              options={TIMEZONES}
              onChange={handleChangeTZ}
              placeholder={"Pick one"}
              disabled={disabledValues?.pickUpDate}

              whenFieldDisabledShowTooltip={Boolean(disabledValues?.pickUpDate)}
            />
            <div className={styles.timeZoneTooltip}>
              <Tooltip
                title={
                  <span className={styles.tooltipContent}>
                    {TIMEZONE_TOOLTIP_CONTENT}
                  </span>
                }
              >
                <span className={styles.tooltipInfo}>
                  <InfoOutlined className={styles.infoIcon} />
                </span>
              </Tooltip>
            </div>
          </div>
        </label>

        <div className={styles.range}>
          {values?.isPickUpDateFlexible && (
            <label className={styles.startDate}>
              <span className={styles.required}>Earliest pickup time</span>

              <DatePicker
                name="pickUpDateLowerLimit.datetime"
                allowClear
                onChange={handleChangePickUpDateToLowerLimit}
                format={DEFAULT_TIME_FORMAT}
                value={pickUpDateLowerLimit}
                showTime={{ defaultValue: pickUpDefaultDateTime }}
                showNow={false}
                use12Hours
                disabledDate={disabledDatesForPickUpDate(
                  minAvailablePickUpDate
                )}
                disabledTime={disabledDateTime(
                  minAvailablePickUpDate,
                  pickUpDateLowerLimit
                )}
                disabled={
                  !values?.pickUpDate?.timezone || disabledValues?.pickUpDate
                }
                whenFieldDisabledShowTooltip={Boolean(
                  disabledValues?.pickUpDate
                )}
                inputReadOnly
                getPopupContainer={(trigger) => trigger}
              />
            </label>
          )}
          <label
            className={classNames({
              [styles.endDate]: values?.isPickUpDateFlexible,
              [styles.datepicker]: !values?.isPickUpDateFlexible,
            })}
          >
            <span className={styles.required}>{pickUpDateFieldLabel}</span>
            {/* <span className={styles.midnightNoonMessage}>
              {Midnight_Noon_Clarification}
            </span> */}
            <DatePicker
              name="pickUpDate.datetime"
              allowClear
              onChange={handlePickUpDateTime}
              format={DEFAULT_TIME_FORMAT}
              value={pickUpDate}
              showTime={{ defaultValue: pickUpDefaultDateTime }}
              showNow={false}
              use12Hours
              disabledDate={diabledDatesForPickUpField}
              disabledTime={disabledDateTimeForPickUpField}
              disabled={pickUpDateFieldDisabled}
              whenFieldDisabledShowTooltip={Boolean(disabledValues?.pickUpDate)}
              inputReadOnly
              getPopupContainer={(trigger) => trigger}
            />
          </label>
        </div>


      </div>

      {!windowOfTimeIsValid && (
        <div className={styles.snackbarField}>
          <Snackbar
            type="warning"
            title="Selected Pick Up Date & Time is not valid"
            content="You have picked a time outside of the window. Please pick a better time and try again"
          />
        </div>
      )}
    </>
  );
};
