import React, { useEffect, useMemo } from "react";
 
import { FieldArray } from "formik";

import {
  Input,
  Checkbox,
  Select,
  Button,
  GooglePlacesAutoComplete,
  renderAdditionalRideOptions,
} from "../..";

import { LodashHelper } from "../../../utils";
import { TagsList } from "./TagsList";
import {
  INVALID_ADDRESS_MESSAGE,
  OPERATIONAL_WORKING_HOUR_TYPE,
  VEHICLE_OPTIONS,
} from "../../../constants/constants";
import { DailyReminderNotification } from "./DailyReminderNotification";

import styles from "./index.module.css";
import FleetSize from "./FleetSize";
import TCOperationHours from "./TCOperationHours";

const renderVehicleTypes =
  (renderedValues, values, name, isFieldDisabled) => (arrayHelpers) => {
    const handleCheckbox =
      (option) =>
      ({ checked }) => {
        if (checked) arrayHelpers.push(option.value);
        else {
          const idx = values[name].indexOf(option.value);
          arrayHelpers.remove(idx);
        }
      };
    return renderedValues.map((option) => (
      <label className={styles.checkbox} key={option.value}>
        <Checkbox
          name={name}
          checked={
            values && values[name] && values[name].includes(option.value)
          }
          value={option.value}
          onChange={handleCheckbox(option)}
          disabled={isFieldDisabled}
        />
        <span className={styles.checkboxLabel}>{option.title}</span>
      </label>
    ));
  };

export const CreateTC = ({
  personalInfoFieldsDisabled,
  values,
  switchAdmin,
  handleSelect,
  handleInviteUser,
  handleChangeAdmin,
  countyValue,
  handleStateSelect,
  handleCountiesSelect,
  handleAddCounties,
  handleDelete,
  showPersonalInfo = true,
  handleSelectAddress,
  handleChangeAddress,
  disableAllFields,
  rideOptions,
  states,
  counties,
  
  ...rest
}) => {

  const {validateForm} = rest;
 useEffect(()=>{validateForm(); return;},[values?.location?.formattedAddress])
  

  const handleDeleteTag =
    ({ values, setValues }) =>
    ({ state, county, deleteState }) =>
      handleDelete({ values, setValues, state, county, deleteState });

  const countiesOptions = useMemo(
    () =>
      values.state && !LodashHelper.isEmpty(counties)
        ? [
            {
              title: "Select All",
              value: "Select All",
            },
            ...counties[values.state].map((c) => ({ title: c, value: c })),
          ]
        : [],
    [counties, values.state]
  );

  const handleCountiesBtnDisabled = useMemo(
    () =>
      (!values.counties?.length &&
        !Boolean(
          values.regionsOfOperation?.find(
            (region) => region.state === values.state
          )
        )) ||
      LodashHelper.isEqual(
        values.counties,
        values.regionsOfOperation.find(
          (region) => region.state === values.state
        )?.counties
      ),
    [values.counties, values.regionsOfOperation, values.state]
  );

  const handleCountiesBtnText = useMemo(
    () =>
      !values.regionsOfOperation.find((region) => region.state === values.state)
        ?.counties
        ? "Add Counties"
        : LodashHelper.isEqual(
            values.counties,
            values.regionsOfOperation.find(
              (region) => region.state === values.state
            )?.counties
          )
        ? "Add Counties"
        : "Update Counties",
    [values.counties, values.regionsOfOperation, values.state]
  );

  return (
    <>
      <label className={styles.organizationName}>
        <span className={styles.required}>Company Name</span>
        <Input
          name="name"
          type="text"
          placeholder="Company Name"
          disabled={disableAllFields}
        />
      </label>
      <label className={styles.organizationLocation}>
        <span className={styles.required}>Address</span>
        <GooglePlacesAutoComplete
          name="location.formattedAddress"
          value={values?.location?.formattedAddress}
          handleSelectAddress={handleSelectAddress}
          handleChangeAddress={handleChangeAddress}
          disabled={disableAllFields}
        />
        {rest.touched &&
          rest.touched["location.formattedAddress"] &&
          rest?.errors.location && (
            <div className={styles.errorMessage} role="alert">
              {INVALID_ADDRESS_MESSAGE}
            </div>
          )}
      </label>
      <div className={styles.regions}>
        <span className={styles.states}>Counties of Operation</span>

        <div className={styles.address}>
          {!disableAllFields && (
            <>
              <label className={styles.statesSelect}>
                State(s)
                <Select
                  showSearch
                  name="state"
                  options={states}
                  onChange={handleStateSelect}
                  placeholder="State"
                  disabled={disableAllFields}
                />
              </label>

              <label className={styles.countiesSelect}>
                County
                <Select
                  name="counties"
                  options={countiesOptions}
                  onChange={handleCountiesSelect}
                  placeholder="Pick a county"
                  mode="multiple"
                  maxTagCount={1}
                  maxTagTextLength={15}
                  disabled={!values.state}
                />
              </label>

              <div className={styles.addCounties}>
                <Button
                  onClick={handleAddCounties}
                  disabled={handleCountiesBtnDisabled}
                >
                  {handleCountiesBtnText}
                </Button>
              </div>
            </>
          )}
        </div>

        {values.regionsOfOperation.map((region) => {
          return (
            <TagsList
              info={region}
              key={region.state}
              handleDeleteTag={handleDeleteTag({
                values,
                setValues: rest.setValues,
              })}
              isFieldDisabled={disableAllFields}
            />
          );
        })}
      </div>
      {showPersonalInfo && (
        <>
          <div className={styles.name}>
            <label className={styles.nameLabel}>
              <span className={styles.required}>
                Administrator's First Name
              </span>
              <Input
                name="admin.firstName"
                type="text"
                placeholder="Administrator's First Name"
                disabled={personalInfoFieldsDisabled}
              />
            </label>
            <label className={styles.nameLabel}>
              <span className={styles.required}>Administrator's Last Name</span>
              <Input
                name="admin.lastName"
                type="text"
                placeholder="Administrator's Last Name"
                disabled={personalInfoFieldsDisabled}
              />
            </label>
          </div>
          <div className={styles.emailContainer}>
            <label className={styles.email}>
              <span className={styles.required}> Admin's Email</span>
              <Input
                name="admin.email"
                type="text"
                placeholder="Admin's Email"
                disabled={personalInfoFieldsDisabled}
              />
            </label>
            {switchAdmin && (
              <div className={styles.changeAdminBtn}>
                <Button
                  myClassName={styles.formControlBtn}
                  onClick={handleChangeAdmin}
                >
                  Switch Admin
                </Button>
              </div>
            )}
          </div>

          {switchAdmin && (
            <div className={styles.inviteBtn}>
              <Button
                myClassName={styles.formControlBtn}
                onClick={handleInviteUser}
              >
                Invite New User
              </Button>
            </div>
          )}
        </>
      )}
      <div className={styles.checkboxesColumn}>
        <label className={styles.rideRequestFieldsHead}>Vehicle Type(s)</label>
        <div className={styles.checkboxesList}>
          <FieldArray
            name="vehicleTypes"
            render={renderVehicleTypes(
              VEHICLE_OPTIONS,
              values,
              "vehicleTypes",
              disableAllFields
            )}
          />
        </div>
      </div>
  
      <div className={styles.carSeatColumn} key={"cbS"}>
        <label className={styles.rideRequestFieldsHead}>Car/Booster Seat </label>
        <label className={styles.checkbox}>
          <Checkbox name='supportCarBoosterSeat' checked={values?.supportCarBoosterSeat}  disabled={disableAllFields}/>
          <span className={styles.checkboxLabel}>Allow TC to View and Accept Car/Booster Seat Ride Requests</span>
        </label>
      </div>
  
      <div className={styles.checkboxesColumn} key={"vcD"}>
        <label className={styles.rideRequestFieldsHead}>Fleet Size(s)</label>
        <div className={styles.checkboxesRow}>
          
          <FleetSize values={values?.fleetSizes} name={"fleetSizes"} isFieldDisabled={disableAllFields}/>
        </div>
      </div>
      {/* {TCOperationHours({
        values: values?.hourOfOperations?.weekdays,
        type: OPERATIONAL_WORKING_HOUR_TYPE.WEEKLY,
        fieldName: "hourOfOperations.weekdays",
        isFieldsDisabled: disableAllFields,
        title: "Hours Of Operation (normal business hours)",
      })} */}

      <TCOperationHours 
      values= {values?.hourOfOperations?.weekdays}
      type= {OPERATIONAL_WORKING_HOUR_TYPE.WEEKLY}
      fieldName= {"hourOfOperations.weekdays"}
      isFieldsDisabled= {disableAllFields}
      title={"Hours Of Operation (normal business hours)"}
      />

      {/* {TCOperationHours({
        values: values?.hourOfOperations?.holiday,
        type: OPERATIONAL_WORKING_HOUR_TYPE.HOLIDAY,
        fieldName: "hourOfOperations.holiday",
        isFieldsDisabled: disableAllFields,
        title: "Holidays",
      })} */}

<TCOperationHours 
      values= {values?.hourOfOperations?.holiday}
      type= {OPERATIONAL_WORKING_HOUR_TYPE.HOLIDAY}
      fieldName= {"hourOfOperations.holiday"}
      isFieldsDisabled= {disableAllFields}
      title={"Holidays"}
      />
      {rideOptions?.length > 0 && (
        <div className={styles.additionalOptions}>
          <span className={styles.additionalOptionsHead}>
            Additional driver/vehicle capabilities
          </span>
          <div className={styles.checkboxesRow}>
            <FieldArray
              name="rideOptions"
              render={renderAdditionalRideOptions({
                renderedValues: rideOptions,
                values,
                name: "rideOptions",
                disabled: disableAllFields,
                showTooltip: false,
              })}
            />
          </div>
        </div>
      )}
      <DailyReminderNotification values={values} disabled={disableAllFields} />
    </>
  );
};
