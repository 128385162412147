import React from 'react';

import { Modal, Button } from '../../../components';

import styles from './index.module.css';

export const renderEditLeaveModal = ({ visibleModal, handleStay, handleLeave }) => (
  <Modal visible={visibleModal} onCancel={handleStay} closable width={670} title='Quit Editing?'>
    <div className={styles.modalContent}>
      <p className={styles.modalDescription}>
        If you choose to leave this page, any changed information will not be saved.
      </p>
      <div className={styles.modalBtns}>
        <div>
          <Button myClassName={styles.navigationBtn} onClick={handleLeave}>
            Leave
          </Button>
        </div>
        <div>
          <Button myClassName={styles.navigationBtn} onClick={handleStay}>
            Stay
          </Button>
        </div>
      </div>
    </div>
  </Modal>
);
