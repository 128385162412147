import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useParams } from 'react-router';

import { UpdateUser } from '../..';
import { USER_ROLES, USER_STATUSES } from '../../../constants/constants';
import { changeUserStatus } from '../../../store/users';
import { Navigation } from '../../../utils/navigation';

const UpdateAdmin = ({ changeUserStatus, ...props }) => {
  const { id } = useParams();
const navigate =Navigation();
  const handleConfirmDisableUser = () => changeUserStatus({ id, status: { status: USER_STATUSES.DISABLED },Navigation:navigate});

  return (
    <UpdateUser
      title='Admin Details'
      headText='Information of the Admin'
      managedRole='Admin'
      Navigation ={navigate}
      id={id}
      disableEmail={props.role === USER_ROLES.SUPER_ADMIN}
      handleConfirmDisableUser={handleConfirmDisableUser}
      {...props}
    />
  );
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ changeUserStatus }, dispatch);

export default connect(null, mapDispatchToProps)(UpdateAdmin);
