import classNames from 'classnames';
import styles from './index.module.css';

const Action = ({ Icon, action, stroke }) => {
  return (
    <div className={styles.action} onClick={action}>
      <Icon className={styles.actionsIcon} stroke={stroke} />
    </div>
  );
};

export const RideActionsPanel = ({ entities }) => {
  return (
    <div
      className={classNames({
        [styles.actionsButtons]: true,
        [styles.twoActionsButtons]: entities?.length <= 2,
        [styles.singleButton]: entities?.length === 1,
      })}>
      {entities.map((entity) => (
        <Action Icon={entity.Icon} action={entity.action} key={entity.id} stroke={entity.stroke} />
      ))}
    </div>
  );
};
