import { Input } from "../..";

import styles from "./index.module.css";

export function renderNumericFields(fieldArray) {
  return (
    <>
      {fieldArray?.map((item) => (
        <label className={styles.timeControl} key={`${item?.id}ld`}>
          <label className={styles.timeInput}>
            <Input name={item?.fieldName} type="number" min={0} />
          </label>
          <span className={styles.measurement}>{item?.title}</span>
        </label>
      ))}
    </>
  );
}
