import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { VIEW_DASHBOARD_LOGIN_TYPES_DICTIONARY } from '../../../dictionaries';

import { loginAsDifferentRole } from '../../../store/authorization';
import { Redirect } from '../Redirect/Redirect';
import { Navigation } from '../../../utils/navigation';

class ViewDashboard extends Component {
  UNSAFE_componentWillMount() {
    const query = new URLSearchParams(this.props.location.search);
    const navigate = Navigation();
    const organizationId = query.get('organizationId');
    const role = VIEW_DASHBOARD_LOGIN_TYPES_DICTIONARY[query.get('role')];
    const organizationName = query.get('organizationName');
    this.props.loginAsDifferentRole({ organizationId, role, organizationName,Navigation:navigate });
  }

  render() {
    return <Redirect />;
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      loginAsDifferentRole,
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(ViewDashboard);
