import React, { useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';
import { FormikProvider, useFormik } from 'formik';
import { Button, Modal, Select, InputTextArea, Checkbox } from '../..';
import { ADD_RIDER_NOSHOW_CANCELLATION_CHARGE, ADD_SAMEDAY_CANCELLATION_CHARGE, CANCEL_BY_FORCE_UPDATE, CANCEL_RIDE_OPTIONS, CANCEL_ROUNDTRIP_HEADING, CANCEL_ROUNDTRIP_POPUP_TITLE, CANCELLATION_TYPE_BY_ROLE, RIDE_STATUSES, riderNoShowChargeMessage_MHAdmin, sameDayCancellationChargeMessage_MHAdmin, USER_ROLES } from '../../../constants/constants';
import { commonCancelRideSchema } from '../../../utils/validations';

import styles from './index.module.css';
import classNames from 'classnames';
import { useSameDayRoundTripConfirmationModal } from '../SameDayRoundTripConfirmationModal/useSameDayRoundTripConfirmationModal';

const initialValues = { reason: '', cancelBy:'',additionalComments: '', applySameDayCancellationCharge: undefined, applyRiderNoShowCharge: undefined, };

export const useCancelRideByMHModal = ({ id, role, ride, onConfirm, status }) => {
  const [visibleModal, setModalVisible] = useState(false);
  // const [reasonBy, setReasonBy] = useState('');
  const [reason, setReason] = useState(null);


  const { Modal: ModalRoundTripConfirmation, setCancelModalVisible } = useSameDayRoundTripConfirmationModal({
    id: ride?.seqId,
    role,
    title: CANCEL_ROUNDTRIP_POPUP_TITLE,
    description: CANCEL_ROUNDTRIP_HEADING,
    ride:ride,
    onConfirm: (values) => handleConfirm(),
  });




  const handleConfirm = useCallback(
    () => { 
     
      onConfirm({
        reason: reason?.reason, additionalComments: values?.additionalComments,
        cancelationType: reason?.type, applyRiderNoShowCharge: values?.applyRiderNoShowCharge,
        applySameDayCancellationCharge: values?.applySameDayCancellationCharge,
        cancelBy:values?.cancelBy
      });
     return setCancelModalVisible(false);

    },
    [onConfirm],
  );

  const confirmRideCancellation = useCallback(
    (values) => {
      if (ride?.connectedLeg) {
        setCancelModalVisible(true);
      } else {
         
        onConfirm({
          reason: reason?.reason, additionalComments: values?.additionalComments,
          cancelationType: reason?.type, applyRiderNoShowCharge: values?.applyRiderNoShowCharge,
          applySameDayCancellationCharge: values?.applySameDayCancellationCharge,
          cancelBy:values?.cancelBy
        });
      }

    },
    [onConfirm],
  );




  const formik = useFormik({
    initialValues,
    validationSchema: commonCancelRideSchema,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: confirmRideCancellation,
  });

  const { resetForm, validateForm, isValid, values, handleSubmit } = formik;

  useEffect(() => {
    if (values?.cancelBy && values?.cancelBy != '') {
      const reasonObjArr = CANCEL_RIDE_OPTIONS[values?.cancelBy].filter(r => r.value === values?.reason);

  
      let information = null;
      if( [USER_ROLES.HO_ADMIN, 'RIDER'].includes(values?.cancelBy)) {
        information = sameDayCancellationChargeMessage_MHAdmin;
      } else  if( [USER_ROLES.TC_ADMIN, USER_ROLES.DRIVER].includes(values?.cancelBy)){
        information = riderNoShowChargeMessage_MHAdmin;
      }


      Array.isArray(reasonObjArr) && reasonObjArr.length > 0 ? setReason({ reason: reasonObjArr[0]?.value, information: information, type: reasonObjArr[0]?.cancellationType }) : setReason(null);



    }
  }, [values?.cancelBy, values, reason?.reason])
  const onCancel = useCallback(async () => {
    await resetForm(initialValues);
    await validateForm();
    await setModalVisible(false);
  }, [setModalVisible, validateForm, resetForm]);

  const handleReasonForUpdate = useCallback(async ({ name, value, setFieldValue }) => {
    await setFieldValue(name, value);
  }, []);

  const handleCancelBy = useCallback(async ({ name, value, setFieldValue }) => {
    await setFieldValue(name, value);
    await setFieldValue('reason', '');
    await setFieldValue('applyRiderNoShowCharge', undefined);
    await setFieldValue('applySameDayCancellationCharge', undefined);
    // setReasonBy(value);
    setReason(null);
  }, []);


  return {
    Modal: (
      <Modal
        visible={visibleModal}
        onCancel={onCancel}
        closable
        width={670}
        bodyStyle={{ height: '55vh' }}
        title={`Canceling Ride Request – ID ${id}`}>
        <div className={styles.modalContent}>
          <FormikProvider value={formik}>
            <>
              <form >

                <label className={styles.cancelRideLabel}>
                  <span className={styles.required}>Cancelled By</span>
                  <Select name='cancelBy' options={CANCEL_BY_FORCE_UPDATE[status]} onChange={handleCancelBy} placeholder='Pick an option' />
                </label>
                <label className={styles.cancelRideLabel}>
                  <span className={styles.required}>Cancellation Reason </span>
                  <Select name='reason' placement={"bottomLeft"} onChange={handleReasonForUpdate} options={CANCEL_RIDE_OPTIONS[values?.cancelBy]} placeholder='Pick an option' />
                </label>

                {status === RIDE_STATUSES.ACCEPTED && [CANCELLATION_TYPE_BY_ROLE.cancelByHO, CANCELLATION_TYPE_BY_ROLE.cancelByRider].includes(reason?.type) &&
                  <div className={classNames({ [styles.timesContainer]: true })} key={"sameDayCancel"}>
                    <label className={styles.cancelRideLabel}>
                      <Checkbox
                        name='applySameDayCancellationCharge'
                        checked={values?.applySameDayCancellationCharge}
                      />
                      <span className={styles.ml12}>{ADD_SAMEDAY_CANCELLATION_CHARGE}</span>
            
                    </label>
                  </div>}

                {status === RIDE_STATUSES.ACCEPTED &&  reason?.information &&
                  // ![CANCELLATION_TYPE_BY_ROLE.cancelByDriver, CANCELLATION_TYPE_BY_ROLE.cancelByTC].includes(reason?.type) &&
                  [CANCELLATION_TYPE_BY_ROLE.riderNoShow].includes(reason?.type) &&
                  <div className={classNames({ [styles.timesContainer]: true })} key={"riderNoShow"}>
                    <label className={styles.cancelRideLabel}>
                      <Checkbox
                        name='applyRiderNoShowCharge'
                        checked={values?.applyRiderNoShowCharge}
                      />
                      <span className={styles.ml12}>{ADD_RIDER_NOSHOW_CANCELLATION_CHARGE}</span>
                    </label>

                  </div>
                }

                {
                  status === RIDE_STATUSES.ACCEPTED &&
                    ![CANCELLATION_TYPE_BY_ROLE.cancelByDriver, CANCELLATION_TYPE_BY_ROLE.cancelByTC].includes(reason?.type) &&
                    reason?.information &&
                    reason?.information != "" && (
                      <label className={styles.cancelRideLabel}>
                        <div className={styles.informationMessage}>
                          {reason?.information}
                        </div>
                      </label>
                    )
                  }



                {(
                  <label className={styles.cancelRideLabel}>
                    <span className={styles.required}>Additional Details</span>
                    <InputTextArea
                      name='additionalComments'
                      placeholder='Please fill in reason for ride Cancelation'
                      type='text'
                      autosize={{ minRows: 1, maxRows: 5 }}
                    />
                  </label>
                )}
              </form>
              <div className={classnames({
                [styles.modalBtns]: true,
                [styles.cancelBtns]: true,
              })}>
                <div className={styles.mr6}>
                  <Button myClassName={styles.navigationBtn} onClick={onCancel}>
                    Keep Ride
                  </Button>
                </div>
                <div className={styles.ml6}>
                  <Button myClassName={styles.navigationBtn} onClick={handleSubmit} disabled={!isValid}>
                    Cancel Ride
                  </Button>
                </div>
              </div>
            </>
          </FormikProvider>
        </div>
        {ModalRoundTripConfirmation}
      </Modal>
    ),
    setCancelModalVisible: setModalVisible,
  };
};
