import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { switchAdminForCurrentOrganization } from '../../../store/health_organizations';
import { UpdateUser } from '../..';

import { changeUserStatus } from '../../../store/users';
import { useParams } from 'react-router';
import { USER_STATUSES } from '../../../constants/constants';
import { Navigation } from '../../../utils/navigation';

const HO_UpdateUser = ({ switchAdminForCurrentOrganization, changeUserStatus, ...props }) => {
  const navigate =Navigation();
  const { id } = useParams();
  const handleConfirmSwitchUser = (confirmCallback) =>
    switchAdminForCurrentOrganization({
      userId: id,
      confirmCallback,
    });

  const handleConfirmDisableUser = () => changeUserStatus({ id, status: { status: USER_STATUSES.DISABLED }, Navigation:navigate});
  return (
    <UpdateUser
      title='User Details'
      Navigation ={navigate}
      headText='Information of the User'
      managedRole='User'
      handleConfirmSwitchUser={handleConfirmSwitchUser}
      handleConfirmDisableUser={handleConfirmDisableUser}
      id={id}
      {...props}
    />
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      switchAdminForCurrentOrganization,
      changeUserStatus,
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(HO_UpdateUser);
