import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import styles from './index.module.css';

export const MaskOverlay = ({ onClick, drawerIsOpen }) => (
  <div
    onClick={onClick}
    className={classnames({
      [styles.drawerMask]: true,
      [styles.drawerMaskOpen]: drawerIsOpen,
    })}
  />
);

MaskOverlay.propTypes = {
  onClick: PropTypes.func,
  drawerIsOpen: PropTypes.bool,
};
