import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import React from 'react';
import { Button, Checkbox, Input, MaskInput } from '../../..';
import { DEFAULT_PHONE_NUMBER_FORMAT } from '../../../../constants/constants';
import { initialCaregiver } from '../stepperInfo';

import styles from './index.module.css';

export const renderCaregivers = (values, dibaledCaregiverInfo, disabledValues) => (arrayHelpers) => {
  const handleRemoveCaregiver = (idx) => () => arrayHelpers.remove(idx);
  const handleAddCaregiver = () => arrayHelpers.push(initialCaregiver);

  return (
    <>
      {values.map((key, idx) => (
        <React.Fragment key={idx}>
          <div className={styles.caregiverSubTitle}>
            <h3 className={styles.caregiverSequencyNumber}>Caregiver #{idx + 1}</h3>
            <CloseOutlined className={styles.removeIcon} onClick={handleRemoveCaregiver(idx)} />
          </div>
          <div className={styles.careGiverInfo}>
            <div className={styles.ridesNameInfo}>
              <label className={styles.careGiverName}>
                <span className={styles.required}>Caregiver/Companion First Name</span>
                <Input
                  fastField={dibaledCaregiverInfo}
                  suffix={false}
                  name={`caregivers.${idx}.firstName`}
                  type='text'
                  placeholder='Caregiver/Companion First Name'
                  value={values?.[idx]?.firstName}
                  disabled={dibaledCaregiverInfo}
                  whenFieldDisabledShowTooltip={Boolean(disabledValues?.caregiverInfo)}
                />
              </label>

              <label className={styles.careGiverName}>
                <span className={styles.required}>Caregiver/Companion Last Name</span>
                <Input
                  fastField={dibaledCaregiverInfo}
                  suffix={false}
                  name={`caregivers.${idx}.lastName`}
                  type='text'
                  placeholder='Caregiver/Companion Last Name'
                  value={values?.[idx]?.lastName}
                  disabled={dibaledCaregiverInfo}
                  whenFieldDisabledShowTooltip={Boolean(disabledValues?.caregiverInfo)}
                />
              </label>
            </div>
            <div className={styles.ridesNameInfo}>
              <div>
                <div className={styles.caregiverContactInfo}>
                  <label className={styles.phoneInfo}>
                    <span className={styles.required}>Mobile Phone Number</span>
                    <MaskInput
                      fastField={dibaledCaregiverInfo}
                      name={`caregivers.${idx}.phoneNumber`}
                      value={values?.[idx]?.phoneNumber}
                      format={DEFAULT_PHONE_NUMBER_FORMAT}
                      placeholder={'###-###-####'}
                      myClassName={styles.phoneInput}
                      disabled={dibaledCaregiverInfo}
                      whenFieldDisabledShowTooltip={Boolean(disabledValues?.caregiverInfo)}
                    />
                  </label>

                  <label className={styles.emailInfo}>
                    <span>Email</span>
                    <Input
                      fastField={dibaledCaregiverInfo}
                      name={`caregivers.${idx}.email`}
                      value={values?.email || ''}
                      type='text'
                      placeholder='Email'
                      suffix={false}
                      disabled={dibaledCaregiverInfo}
                      whenFieldDisabledShowTooltip={Boolean(disabledValues?.caregiverInfo)}
                    />
                  </label>
                </div>
                <div className={styles.phoneCheckboxes}>
                  <label className={styles.textCheckbox}>
                    <Checkbox
                      fastField={dibaledCaregiverInfo}
                      name={`caregivers.${idx}.canPhoneReceiveTexts`}
                      checked={values?.[idx]?.canPhoneReceiveTexts}
                      disabled={dibaledCaregiverInfo}
                      whenFieldDisabledShowTooltip={Boolean(disabledValues?.caregiverInfo)}
                    />
                    <span className={styles.checkboxLabel}>
                      This phone can receive texts and recipient consents to this form of communication
                    </span>
                  </label>
                  <label className={styles.callsCheckbox}>
                    <Checkbox
                      name={`caregivers.${idx}.canPhoneReceiveCalls`}
                      checked={values?.[idx]?.canPhoneReceiveCalls}
                      disabled={disabledValues?.riderInfo}
                      whenFieldDisabledShowTooltip={Boolean(disabledValues?.riderInfo)}
                    />
                    <span className={styles.checkboxLabel}>
                      This phone can receive calls and recipient consents to this form of communication
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      ))}
      <div className={styles.addCaregiver}>
        <Button myClassName={styles.addCaregiverBtn} onClick={handleAddCaregiver} disabled={dibaledCaregiverInfo}>
          <PlusOutlined />
          Add Additional Caregiver Contact
        </Button>
      </div>
    </>
  );
};
