import React from 'react';

import { Modal, Button } from '../../../..';

import styles from '../index.module.css';

export const renderPreventLeaveModal = ({ visibleModal, onCancel, onLeave, handleSaveChangesAndLeave }) => {
  return (
    <Modal visible={visibleModal} onCancel={onCancel} closable width={670} title='Changes not Saved'>
      <div className={styles.modalContent}>
        <p className={styles.modalDescription}>
          Changes you made may not be saved. Do you want to leave this page without saving them?
        </p>
        <div className={styles.modalBtns}>
          <div>
            <Button myClassName={styles.navigationBtn} onClick={onLeave}>
              Leave
            </Button>
          </div>
          <div>
            <Button myClassName={styles.navigationBtn} onClick={onCancel}>
              Stay
            </Button>
          </div>
          <div>
            <Button myClassName={styles.navigationBtn} onClick={handleSaveChangesAndLeave}>
              Save Changes And Leave
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
