import { ScheduleOutlined } from '@ant-design/icons';
import styles from '../index.module.css';

export const RecurringInfo = ({ info }) => {
  return (
    info && (
      <div className={styles.recurringRideDetails}>
        <div className={styles.recurringRideDetailsHead}>
          <ScheduleOutlined className={styles.scheduledRecurringRideIcon} />
          <span className={styles.recurringRideDetailsTitle}>Recurring ride ({info})</span>
        </div>
      </div>
    )
  );
};
