import React from 'react';
import { Checkbox as AntCheckbox } from 'antd';
import classnames from 'classnames';
import { getIn } from 'formik';
import PropTypes from 'prop-types';

import { withTooltip } from '../../../hocs/withTooltip';
import { CustomField } from '../CustomField/CustomField';

import styles from './index.module.css';

export const CustomCheckbox = ({
  field,
  form,
  type,
  checked,
  defaultValue,
  onChange,
  disabled,
  prefix,
  suffix,
  myclassname,
  name,
  whenFieldDisabledShowTooltip,
  ...rest
}) => {
  const handleChange = (event) => {
    if (onChange) {
      onChange({
        name: field?.name,
        checked: event.target.checked,
        setFieldValue: form?.setFieldValue,
        setValues: form?.setValues,
        values: form?.values,
      });
    } else {
      field?.onChange(event);
    }
  };

  return withTooltip(
    <AntCheckbox
      name={name || field.name}
      {...rest}
      onChange={handleChange}
      disabled={disabled}
      checked={checked}
      className={classnames({
        [styles.checkbox]: true,
        [styles.error]: !disabled && !getIn(form?.values, field?.name) && getIn(form?.errors, field?.name),
        [myclassname]: myclassname,
      })}
      role='checkbox'
    />,
    whenFieldDisabledShowTooltip,
  );
};

export const Checkbox = ({
  name,
  checked,
  onChange,
  disabled,
  myClassName,
  whenFieldDisabledShowTooltip,
  fastField,
}) => {
  return (
    <CustomField
      fastField={fastField}
      name={name}
      disabled={disabled}
      onChange={onChange}
      checked={checked}
      myclassname={myClassName}>
      {({ form, field }) => {
        return (
          <>
            <CustomCheckbox
              form={form}
              field={field}
              name={name}
              disabled={disabled}
              onChange={onChange}
              checked={checked}
              myclassname={myClassName}
              whenFieldDisabledShowTooltip={whenFieldDisabledShowTooltip}
            />
            {!disabled &&
              (getIn(form.touched, field.name) || form.touched[field.name]) &&
              getIn(form.errors, field.name) && (
                <div className={styles.errorMessage}>{getIn(form.errors, field.name)}</div>
              )}
          </>
        );
      }}
    </CustomField>
  );
};

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  myClassName: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  whenFieldDisabledShowTooltip: PropTypes.bool,
};
