import classNames from 'classnames';
import { Button } from '../../../formControls/Button/Button';

import styles from './index.module.css';

const resetCredentialsButton = ({ handler, buttonText, key }) => (
  <div key={key} className={styles.tableButton} onClick={handler}>
    <Button myClassName={classNames({ [styles.navigationBtn]: true, [styles.passwordBtn]: true })}>{buttonText}</Button>
  </div>
);

export const renderResetCredentialsButton = ({
  invited,

  handleResendInvitation,
  handleSendPassword,
  resendButtonText,
  resetButtonText,
}) => {
  if (invited) {
    return resetCredentialsButton({ handler: handleResendInvitation, buttonText: resendButtonText, key: 4 });
  } else {
    return resetCredentialsButton({ handler: handleSendPassword, buttonText: resetButtonText, key: 5 });
  }
};
