import React, { useEffect } from 'react';
import { FormikProvider, useFormik } from 'formik';
import PropTypes from 'prop-types';

import { ButtonsPanel } from '../ButtonsPanel/ButtonsPanel';
import { StepFourFormFields } from './StepFourFormFields';
import { stepperInfo } from '../stepperInfo';

import styles from './index.module.css';

export const StepFour = ({
  step,
  draft,
  handleSaveAndClose,
  handleSubmit,
  handlePreviousStep,
  costCenterField,
  showAdditionalSourceField,
  showDocumentReasonForRideField,
  setFormikValues,
  disabledValues,
  showSubInformation = true,
}) => {
  const formik = useFormik({
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: draft && draft.step4 ? draft.step4 : stepperInfo[3].values,
    validationSchema: stepperInfo[3].schema({
      costCenterField,
      showAdditionalSourceField,
      showDocumentReasonForRideField,
    }),
    onSubmit: handleSubmit,
  });

  const { values, isValid } = formik;

  useEffect(() => {
    setFormikValues && setFormikValues(values, isValid, 'step4');
    return;
  }, [setFormikValues, values, isValid]);
  return (
    <>
      {showSubInformation && <h2 className={styles.headTitle}>Step 4: Additional Information</h2>}
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit} className={styles.form}>
          <StepFourFormFields
            {...formik}
            costCenterField={costCenterField}
            showAdditionalSourceField={showAdditionalSourceField}
            showDocumentReasonForRideField={showDocumentReasonForRideField}
            disabledValues={disabledValues}
          />
          {showSubInformation && (
            <ButtonsPanel
              disabled={!formik.isValid}
              step={step}
              handlePreviousStep={handlePreviousStep}
              handleSaveAndClose={handleSaveAndClose}
              handleSubmit={handleSubmit}
            />
          )}
        </form>
      </FormikProvider>
    </>
  );
};

StepFour.propTypes = {
  step: PropTypes.number,
  draft: PropTypes.object,
  handleSaveAndClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  handlePreviousStep: PropTypes.func,
};
