import { useCallback, useState } from 'react';
import { GoogleAddressHelper } from '../../../../utils';

export const useStepThreeHandlers = ({ defaultHomeAddress, validateForm, setFieldValue }) => {
  const [pickUpAddress, setPickUpAddress] = useState('');
  const [destinationAddress, setDestinationAddress] = useState('');

  const handleChangePublicPlace = useCallback(
    ({ name, values }) =>
      ({ setValues, checked }) => {
        const copiedValues = { ...values, [name]: { ...values[name] } };

        if (checked) {
          delete copiedValues[name].apartmentNum;
          delete copiedValues[name].buildingNumber;
          setValues({
            ...copiedValues,
            [name]: {
              ...copiedValues[name],
              isPublicPlace: checked,
            },
          });
        } else {
          copiedValues[name].apartmentNum = '';
          copiedValues[name].buildingNumber = '';
          setValues({
            ...copiedValues,
            [name]: {
              ...copiedValues[name],
              isPublicPlace: checked,
            },
          });
        }
      },
    [],
  );

  
 
  const handleSecurityGateChange = useCallback(
    ({ name, values }) =>
    ({ setValues, checked }) => { 
         setValues({
            ...values,
          [name]: {  hasSecurityGate: checked,
        }});
      

    },
    [],
  );
  const handleSelectAddress = useCallback(
    (fieldName) =>

    
     async ({ value, values, setValues }) => {
     
    const updatedValues = {
          ...values,
          [fieldName]: {
            ...GoogleAddressHelper.transformFields(value),
          },
        };

      
        setValues(updatedValues);
        await validateForm();
      },
    [],
  );
  const handleAddressBookSelectAddress = useCallback(
    (fieldName) =>

    
     async ({ value, values, setValues }) => {
     
    const updatedValues = {
          ...values,
          [fieldName]: {
            ...value,
          },
        };

      
        setValues(updatedValues);
        await validateForm();
      },
    [],
  );

  const handleChangeAddress = useCallback(
     (fieldName) =>
      async ({ value, values, setValues }) => {


       
        
    fieldName === 'pickupAddressDetails' ? setPickUpAddress(() => value) : setDestinationAddress(value);
        setValues({
          ...values,
          [fieldName]: {
            formattedAddress: value,
          },
        });
      await  validateForm();

      },
    [],
  );

  const setPickUpAndDestionationAddress = async (name, value) =>
    name === 'pickupAddressDetails' ? await setPickUpAddress(value) : await setDestinationAddress(value);

  const handleUseDefaultHomeAddress =
    (fieldName) =>
    async ({ checked }) => {
      if (checked && defaultHomeAddress) {
        setPickUpAndDestionationAddress(
          fieldName === 'pickupAddressDetails' && 'pickupAddressDetails',
          defaultHomeAddress?.formattedAddress,
        );
        await setFieldValue(fieldName, defaultHomeAddress);
        pickUpAddress && (await validateForm());
      } else if (!checked) {
        setPickUpAndDestionationAddress(fieldName === 'pickupAddressDetails' && 'pickupAddressDetails', '');
        await setFieldValue(fieldName, { formattedAddress: '' });
        destinationAddress && (await validateForm());
      }
    };

  return {
    pickUpAddress,
    destinationAddress,
    handleSelectAddress,
    handleChangeAddress,
    handleAddressBookSelectAddress,
    handleChangePublicPlace,
    handleSecurityGateChange,
    handleUseDefaultHomeAddress,
  };
};
