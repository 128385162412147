import React, { useCallback, useEffect, useMemo } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useParams } from 'react-router';

import { changeRiderStatus, getRiderDetails, updateRider, clearRiderDetails } from '../../../store/riders';
import { BackIcon } from '../../../assets';
import { createRiderSchema, LodashHelper, renderRecordValue } from '../../../utils';
import { CreateRiderForm } from '../../../components/forms/CreateRider/CreateRider';
import { Button, useDisableUserModal } from '../../../components';
import { URLS, USER_STATUSES } from '../../../constants/constants';

import styles from './index.module.css';
import { Navigation } from '../../../utils/navigation';

const initialValues = {
  firstName: '',
  lastName: '',
  communicationPreferences: [],
  preferredLanguage: '',
  phoneNumber: '',
  dob: '',
  patientUniqueId:'',
  canPhoneReceiveTexts: false,
  canPhoneReceiveCalls: false,
  homePhoneNumber: '',
  email: '',
};

const UpdateRider = ({
 
  updateRider,
  clearRiderDetails,
  riderIsUnique,
  riderDetails,
  changeRiderStatus,
  getRiderDetails,
}) => {
  const navigate = Navigation();
  const { id } = useParams();
  const formik = useFormik({
    initialValues: riderDetails || initialValues,
    validateOnMount: true,
    validateOnChange: false,
    validationSchema: createRiderSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      
      updateRider({ id, values ,Navigation:navigate});
    },
  });

  useEffect(() => {getRiderDetails(id); return;}, [id, getRiderDetails]);

  const { values } = formik;

  const uniqueUserFieldValues = useMemo(
    () => ({
      firstName: values.firstName,
      lastName: values.lastName,
      dob: values.dob,
      organizationId: values?.organizationId,
    }),
    [values.firstName, values.lastName, values.dob, values?.organizationId],
  );

  const initialUniqueRiderFields = useMemo(
    () => ({
      firstName: riderDetails?.firstName,
      lastName: riderDetails?.lastName,
      dob: riderDetails?.dob,
      organizationId: riderDetails?.organizationId,
    }),
    [riderDetails],
  );

  const handleConfirmDisableUser = () => changeRiderStatus({ id, status: { status: USER_STATUSES.DISABLED } });

  const { DisableUserModal } = useDisableUserModal({
    handleConfirm: handleConfirmDisableUser,
    userName:
      riderDetails?.firstName &&
      riderDetails?.lastName &&
      renderRecordValue(`${riderDetails?.firstName} ${riderDetails?.lastName}`),
  });

  // const handleToggleDisableModal = useCallback(
  //   (flag) => () => setDisableUserModalVisible(flag),
  //   [setDisableUserModalVisible],
  // );

  const handleBack = useCallback(() => {
    navigate.push(navigate?.location?.state?.from || URLS.RIDERS);
  }, [navigate]);

  useEffect(() => {
    return () => {
      clearRiderDetails();
    };
  }, [clearRiderDetails]);

  return (
    <div className={styles.pageContent}>
      <div className={styles.header}>
        <div className={styles.backBtn} onClick={handleBack}>
          <BackIcon width='18px' height='18px' fill='#BDBDBD' />
        </div>
        <h1 className={styles.pageTitle}>Rider Details</h1>
      </div>
      <div>
        <h2>Information of the Rider</h2>
        <FormikProvider value={formik}>
          <form className={styles.form} onSubmit={formik.handleSubmit}>
            <CreateRiderForm
              values={formik?.values}
              touched={formik?.touched}
              errors={formik?.errors}
              riderIsUnique={riderIsUnique}
              allowUniqueCheck={riderDetails && !LodashHelper.isEqual(uniqueUserFieldValues, initialUniqueRiderFields)}
            />
            <div className={styles.btnPanel}>
              {/* <div className={styles.btnControl}>
                <Button myClassName={styles.navigationBtn} onClick={handleToggleDisableModal(true)}>
                  Deactivate Rider
                </Button>
              </div> */}

              <div className={styles.btnControl}>
                <Button
                  disabled={!formik.isValid || !riderIsUnique || LodashHelper.isEqual(values, riderDetails)}
                  type='submit'>
                  Save Changes
                </Button>
              </div>
            </div>
          </form>
        </FormikProvider>
      </div>
      {DisableUserModal}
    </div>
  );
};

const mapStateToProps = (state) => ({
  riderIsUnique: state.riders.riderIsUnique,
  riderDetails: state.riders.riderDetails,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ updateRider, changeRiderStatus, getRiderDetails, clearRiderDetails }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UpdateRider);
