import io from 'socket.io-client';
import { localStore } from '.';
import { store } from '../store';

export class Socket {
  constructor(
    url = process.env.NODE_ENV === 'test' ? '' : process.env.REACT_APP_API_URL,
    token = sessionStorage.getItem('access_token') || localStore.get('access_token') || store.getState()?.authorization?.access_token,
  ) {

  
    this.url = url;
    this.token = token;
    this.socket = null;
  }

  init() {
    this.socket = io(`${this.url}?token=${this.token}`,{ transports: ['websocket'],});
  }

  connect() {
    if (this.socket) {
      this.socket.on('connect', function () {});
    } else throw Error('Socket was not initialized');
  }

  subscribe(channel, cb) {
    if (this.socket) {
      this.socket.on(channel, cb);
    } else throw Error('Can not subscribe for this channel');
  }

  
  unsubscribe(channel, cb) {
    if (this.socket) {
      this.socket.off(channel, cb);
    } else throw Error('Can not unsubscribe for this channel');
  }

  disconnect() {
    if (this.socket) {
      this.socket.emit('disconnect', function () {});
    } else throw Error('Socket was not initialized');
  }
}
