import React from 'react';

import { Input, GooglePlacesAutoComplete } from '../..';
import { INVALID_ADDRESS_MESSAGE } from '../../../constants/constants';
import RideRequestFields from '../../common/RideRequestFields/RideRequestFields';
import { renderTimeInputFields } from '../partials/renderTimeInputFields';
import styles from './index.module.css';

export const HoPreferencesOrganizationInfo = ({
  handleSelectAddress,
  handleChangeAddress,
  addressFieldIsDisabled,
  isViewDashboardMode,
  rest,
}) => {

  return (
    <>
      <label className={styles.name}>
        <span className={styles.required}>Company Name</span>
        <Input name='name' type='text' placeholder='Company Name' disabled={addressFieldIsDisabled} />
      </label>
      <label className={styles.organizationLocation}>
        <span className={styles.required}>Address</span>
        <GooglePlacesAutoComplete
          name='location.formattedAddress'
          handleSelectAddress={handleSelectAddress}
          handleChangeAddress={handleChangeAddress}
          value={rest?.values?.location.formattedAddress}
          disabled={addressFieldIsDisabled}
        />
        {rest.touched && rest.touched['location.formattedAddress'] && rest?.errors.location && (
          <div className={styles.errorMessage} role='alert'>
            {INVALID_ADDRESS_MESSAGE}
          </div>
        )}
      </label>

      <h4> Included Ride Request fields</h4>
      {RideRequestFields(rest?.values, addressFieldIsDisabled, rest.setValues)}
      
      {isViewDashboardMode &&
        (<><h3 className={styles.mt28}>{'Configurable Buffer Periods'}</h3>
          <div className={styles.minimumTimeToSubmit}>
            <label className={styles.minimumTimeToSubmitTitle}>
              <span >{'Additional Time for Airport Checkout'}</span>
            </label>
            <div className={styles.rideExpiration}>
              {renderTimeInputFields('airportBufferTime')}
            </div>
          </div></>)}
    </>
  );
};
