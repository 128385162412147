import React from 'react';

import { Modal, Button } from '../..';
import { CountdownCircleTimer } from '../../common/CountdownCircleTimer/CountdownCircleTimer';

import styles from './index.module.css';

export const IdleTimerModal = ({
  visible,
  title,
  handleContinueSession,
  handleLogout,
  MODAL_AUTO_CLOSE_AFTER_SEC,
  workerTimers,
}) => {
  return (
    <Modal
      visible={visible}
      
      onCancel={handleContinueSession}
      closable
      width={580}
      title={title}
      zIndex={1001}
      customMaskStyle={{ zIndex: 1001 }}>
      <div className={styles.modalContent}>
        <p className={styles.modalDescription}>
          Your session is about to expire due to inactivity. For security, the system will automatically log you off
          MedHaul’s portal in 60 seconds. To continue your session, click “Continue Session“
        </p>
        {visible && (
          <CountdownCircleTimer
            handleLogout={handleLogout}
            MODAL_AUTO_CLOSE_AFTER_SEC={MODAL_AUTO_CLOSE_AFTER_SEC}
            workerTimers={workerTimers}
          />
        )}
        <div className={styles.modalBtns}>
          <div className={styles.modalBtn}>
            <Button myClassName={styles.navigationBtn} onClick={handleLogout}>
              Log out from the System
            </Button>
          </div>
          <div className={styles.modalBtn}>
            <Button onClick={handleContinueSession}>Continue Session</Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
