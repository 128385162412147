import React, { useCallback, useEffect, useMemo, useState } from "react";
import { SyncOutlined, ScheduleOutlined } from "@ant-design/icons";
import classNames from "classnames";

import {
  Checkbox,
  Input,
  GooglePlacesAutoComplete,
  CustomCheckbox,
  InputTextArea
} from "../../..";

import { LodashHelper } from "../../../../utils";
import { INVALID_ADDRESS_MESSAGE } from "../../../../constants/constants";
import { Snackbar } from "../../../common/Snackbar/Snackbar";
import { isAddressInvalid } from "../../../../utils/helpers";

import { useStepThreeHandlers } from "./useStepThreeHandlers";
import { RecurringSection } from "./RecurringSection";
import { useDateTimeHandlers } from "./useDateTimeHandlers";
import { ReturnDateTimeSection } from "./ReturnDateTimeSection";
import { PickUpDateTimeSection } from "./PickUpDateTimeSection";
import { useCheckForValidWindowOfTime } from "../../../../hooks/useCheckForValidWindowOfTime";
import { ThirdStepTooltip } from "./ThirdStepTooltip";

import GoogleDistanceMatrix from "../../../common/GoogleMaps/DistanceMatrix";
import DateHelperInstance from "../../../../utils/DateHelper";

import styles from "./index.module.css";
import { Field } from "formik";

export const NotifyAirportBufferTime = ({ flightInfo, updatedPickupDateTime }) => {
  return <>
    {flightInfo && updatedPickupDateTime ?
      <p style={{ color: 'red' }}>{`Note: Your ride is scheduled for ${updatedPickupDateTime} allowing time for baggage collection and airport formalities.`}</p> : null}
  </>
}

export const StepThreeFormFields = ({
  values,
  touched,
  errors,
  validateForm,
  setFieldTouched,
  setFieldValue,
  defaultValues,
  disabledValues,
  minDelayToStartRide,
  hasMatchedTC,
  defaultHomeAddress,
  maxPickUpWindowOfTime,
  vehicleType,
  windowOfTimeIsValid,
  minimumRoundTrip,
  airportBufferTime
}) => {

   
 
  


  const {
    pickUpAddress,
    destinationAddress,
    handleSelectAddress,
    handleChangeAddress,
    handleAddressBookSelectAddress,
    handleChangePublicPlace,
    handleUseDefaultHomeAddress,
    handleSecurityGateChange,
  } = useStepThreeHandlers({
    defaultHomeAddress,
    validateForm,
    setFieldValue,
  });

  const {
    handlePickUpDateTime,
    handleReturnTime,
    handleStartPointTime,
    handleReturnStartPoint,
    handleRoundTripCheckbox,
    disabledDatesForPickUpDate,
    disabledDatesForAppointment,
    disabledDatesForReturnTime,
    disabledDatesForStartPointOfRecurring,
    disabledDatesForReturnTimeForRoundTripRecurring,
    disabledDateTime,
    handleChangeTZ,
    pickUpDate,
    returnTimeDate,
    startPointOfRecurring,
    returnStartPoint,
    setStartPointOfRecurring,
    setReturnStartPoint,
    handleReccuringCheckbox,
    handleUseWindowOfTime,
    disabledDateForHigherLimit,
    disabledTimeForHigherLimit,
    handleChangePickUpDateToLowerLimit,
    pickUpDateLowerLimit,
    returnTimeLowerLimit,
    handleReturnTimeLowerLimit,
    disabledDateForReturnHigherLimit,
    disabledDateTimeForReturnHigherLimit,
    handleChangeappointmentDate,
    appointmentDate,
    handleAppointmentDateCheckBox,
  } = useDateTimeHandlers({
    validateForm,
    setFieldTouched,
    defaultValues,
    minDelayToStartRide,
    maxPickUpWindowOfTime,
    vehicleType,
    minimumRoundTrip,
  });



  useCheckForValidWindowOfTime({
    defaultValues,
    currentValues: values,
    vehicleType,
  });


  useEffect(() => {
    validateForm();
    return;
  }, [pickUpAddress, destinationAddress]);

  const handleChildValueChange = useCallback(
    async (fieldName, updatedValue) => {
      await setFieldValue(fieldName, updatedValue);
    },
    [setFieldValue]
  );


 
 

  const updatedPickupDateTime = useMemo(() => {
    if (values?.flightInfo && pickUpDate && airportBufferTime) {
      let addedMinutesDate = DateHelperInstance.addMinutesInDateWithFormat(pickUpDate, airportBufferTime);
      return addedMinutesDate;
    }

    return null;
  }, [pickUpDate, values?.airportBufferTime, values?.flightInfo]);

  return (
    <div className={styles.formContent}>
    
      <h3 className={styles.subHeadings}>Location Information:</h3>
     
        {/* <Field
        name="pickupAddressDetails.formattedAddress"
        value={pickUpAddress}
       
        />   */}
        
        
      

      <label className={styles.addressLabel}>
        <span className={styles.required}>Pick Up Address</span>
        <GooglePlacesAutoComplete
          name="pickupAddressDetails.formattedAddress"
          handleSelectAddress={handleSelectAddress("pickupAddressDetails")}
          handleAddressBookSelectAddress={handleAddressBookSelectAddress("pickupAddressDetails")}
          handleChangeAddress={handleChangeAddress("pickupAddressDetails")}
          placeholder="Pick Up Address"
          value={pickUpAddress}
          myclassname={styles.googleAutoComplete}
          disabled={disabledValues?.pickupAddressDetails?.formattedAddress}
          whenFieldDisabledShowTooltip={Boolean(
            disabledValues?.pickupAddressDetails?.formattedAddress
          )} 
          showFavoriteIcon={values?.pickupAddressDetails?.formattedAddress}
          currentAddress={values?.pickupAddressDetails?.formattedAddress}
          currentAddressObj={values?.pickupAddressDetails}
         // insertFavoriteAddress={insertFavoriteAddress}
         // handleRemoveAddress={handleRemoveAddress}
         
        />

        {touched &&
          touched["pickupAddressDetails.formattedAddress"] &&
          isAddressInvalid(errors?.pickupAddressDetails) && (
            <div className={styles.errorMessage} role="alert">
              {INVALID_ADDRESS_MESSAGE}
            </div>
          )}
        {values?.destinationAddress?.formattedAddress !== '' && values?.pickupAddressDetails?.formattedAddress !== '' ? (
          <GoogleDistanceMatrix
            destination={destinationAddress}
            origin={pickUpAddress}
            handleChildValueChange={handleChildValueChange}
          />
        ) : (
          ""
        )}
        {defaultHomeAddress && (
          <label className={styles.useDefaultAddress}>
            <CustomCheckbox
              name="useDefaultPickUpAddress"
              onChange={handleUseDefaultHomeAddress("pickupAddressDetails")}
              checked={LodashHelper.isEqual(
                values?.pickupAddressDetails,
                defaultHomeAddress
              )}
              disabled={disabledValues?.pickupAddressDetails?.formattedAddress}
              whenFieldDisabledShowTooltip={Boolean(
                disabledValues?.pickupAddressDetails?.formattedAddress
              )}
            />
            <span className={styles.checkboxLabelText}>
              Apply Home Address from Rider's Profile
            </span>
          </label>
        )}
      </label>
      <label className={styles.facilityName}>
        Facility Name (Information Provided by Google)
        <Input
          name="pickupAddressDetails.facilityName"
          type="text"
          placeholder="Facility Name"
          disabled
          whenFieldDisabledShowTooltip={Boolean(
            disabledValues?.pickupAddressDetails?.formattedAddress
          )}
        />
      </label>
      {!hasMatchedTC && (
        <div className={styles.snackbarField}>
          <Snackbar
            type="error"
            title="No Suitable Vehicles"
            content="There are no suitable vehicles in this area. Please, change the Pick up/Destination addresses or the vehicle type in the previous step"
          />
        </div>
      )}
      <label className={styles.facilityName}>
        If the rider is arriving at an airport, is there relevant flight
        information?
        <Input
          name="flightInfo"
          type="text"
          disabled={disabledValues?.flightInfo}
          whenFieldDisabledShowTooltip={Boolean(disabledValues?.flightInfo)}
          placeholder="Flight Number, ex DL1234 or Delta Flight 1234"
        />
      </label>
      {!values?.pickupAddressDetails?.isPublicPlace && (
        <div className={styles.address}>
          <label className={styles.building}>
            <span>Building/Suite Number</span>
            <Input
              name="pickupAddressDetails.buildingNumber"
              type="text"
              placeholder="Building/Suite #"
              disabled={disabledValues?.pickupAddressDetails?.buildingNumber}
              whenFieldDisabledShowTooltip={Boolean(
                disabledValues?.pickupAddressDetails?.buildingNumber
              )}
            />
          </label>
          <label className={styles.apartment}>
            Apartment Number
            <Input
              name="pickupAddressDetails.apartmentNum"
              type="text"
              placeholder="Apartment #"
              disabled={disabledValues?.pickupAddressDetails?.apartmentNum}
              whenFieldDisabledShowTooltip={Boolean(
                disabledValues?.pickupAddressDetails?.apartmentNum
              )}
            />
          </label>
        </div>
      )}
      <label className={styles.apartmentCheckbox}>
        <Checkbox
          name="pickupAddressDetails.isPublicPlace"
          checked={values?.pickupAddressDetails?.isPublicPlace}
          onChange={handleChangePublicPlace({
            values,
            name: "pickupAddressDetails",
          })}
          disabled={disabledValues?.pickupAddressDetails?.isPublicPlace}
          whenFieldDisabledShowTooltip={Boolean(
            disabledValues?.pickupAddressDetails?.isPublicPlace
          )}
        />
        <span className={styles.checkboxLabelText}>This is a public space</span>
      </label>
      <label className={styles.securityCheckbox}>
        <Checkbox
          name="pickUpSecurityGateDetails.hasSecurityGate"
          checked={values?.pickUpSecurityGateDetails?.hasSecurityGate}
          onChange={handleSecurityGateChange({
            values,
            name: "pickUpSecurityGateDetails",
          })}
        // disabled={disabledValues?.pickUpSecurityGateDetails?.hasSecurityGate}
        // whenFieldDisabledShowTooltip={Boolean(
        //   disabledValues?.pickUpSecurityGateDetails?.hasSecurityGate
        // )}
        />
        <span className={styles.checkboxLabelText}>
          There is a security gate
        </span>
      </label>
      {values?.pickUpSecurityGateDetails?.hasSecurityGate && (
        <div className={styles.address}>
          <label className={styles.securityInstruction}>
            Gate Entry Instruction
            <Input
              name="pickUpSecurityGateDetails.securityGateInstruction"
              type="text"
              placeholder=" Gate Entry Instruction"
            // disabled={
            //   disabledValues?.pickUpSecurityGateDetails?.securityGateInstruction
            // }
            // whenFieldDisabledShowTooltip={Boolean(
            //   disabledValues?.pickUpSecurityGateDetails?.securityGateInstruction
            // )}
            />
          </label>
        </div>
      )}

      <div className={styles.locationDescription}>
        <label className={styles.buildingAdditionalInfo}>
          Pick Up Location Description
          <Input
            name="pickupAddressDetails.locationDescription"
            type="text"
            placeholder="Location Description"
            disabled={disabledValues?.pickupAddressDetails?.locationDescription}
            whenFieldDisabledShowTooltip={Boolean(
              disabledValues?.pickupAddressDetails?.locationDescription
            )}
          />
        </label>

        <label className={styles.buildingAdditionalInfo}>
          Rider Description
          <Input
            name="riderDescription"
            type="text"
            placeholder="Rider Description"
            disabled={disabledValues?.pickupAddressDetails?.locationDescription}
            whenFieldDisabledShowTooltip={Boolean(
              disabledValues?.riderInfo?.riderDescription
            )}
          />
        </label>
      </div>
      <div className={styles.otherInformation}>
        <label>
          <span>Other information /Notes:</span>
          <InputTextArea
            name="rideNotes"
            placeholder="Other information (if any)"
            type="text"
            autosize={{ minRows: 1, maxRows: 5 }}
            disabled={disabledValues?.rideNotes}
          />
        </label>
      </div>
      <div className={styles.destinationAddress}>
        <span className={styles.required}>Destination Address</span>
        <GooglePlacesAutoComplete
          name="destinationAddress.formattedAddress"
          handleSelectAddress={handleSelectAddress("destinationAddress")}
          handleChangeAddress={handleChangeAddress("destinationAddress")}
          handleAddressBookSelectAddress={handleAddressBookSelectAddress("destinationAddress")}
          placeholder="Destination Address"
          value={destinationAddress}
          myclassname={styles.googleAutoComplete}
          disabled={disabledValues?.destinationAddress?.formattedAddress}
          whenFieldDisabledShowTooltip={Boolean(
            disabledValues?.destinationAddress?.formattedAddress
          )}
            showFavoriteIcon={values?.destinationAddress?.formattedAddress}
          currentAddress={values?.destinationAddress?.formattedAddress}
          currentAddressObj={values?.destinationAddress}
          //insertFavoriteAddress={insertFavoriteAddress}
         // handleRemoveAddress={handleRemoveAddress}
          
        />

        <div className={styles.errorMessage} role="alert">
          {LodashHelper.isString(errors.destinationAddress)
            ? errors.destinationAddress
            : touched &&
            touched["destinationAddress.formattedAddress"] &&
            errors?.destinationAddress &&
            isAddressInvalid(errors?.destinationAddress) &&
            INVALID_ADDRESS_MESSAGE}
        </div>
        {defaultHomeAddress && (
          <label className={styles.useDefaultAddress}>
            <CustomCheckbox
              name="useDefaultDestinationAddress"
              onChange={handleUseDefaultHomeAddress("destinationAddress")}
              checked={LodashHelper.isEqual(
                values?.destinationAddress,
                defaultHomeAddress
              )}
              disabled={disabledValues?.destinationAddress?.formattedAddress}
              whenFieldDisabledShowTooltip={Boolean(
                disabledValues?.destinationAddress?.formattedAddress
              )}
            />
            <span className={styles.checkboxLabelText}>
              Apply Home Address from Rider's Profile
            </span>
          </label>
        )}
        <label className={styles.facilityName}>
          Facility Name (Information Provided by Google)
          <Input
            name="destinationAddress.facilityName"
            type="text"
            placeholder="Facility Name"
            disabled
            whenFieldDisabledShowTooltip={Boolean(
              disabledValues?.destinationAddress?.formattedAddress
            )}
          />
        </label>
        {!values?.destinationAddress?.isPublicPlace && (
          <div className={styles.address}>
            <label className={styles.building}>
              <span>Building/Suite Number</span>
              <Input
                name="destinationAddress.buildingNumber"
                type="text"
                placeholder="Building/Suite #"
                disabled={disabledValues?.destinationAddress?.buildingNumber}
                whenFieldDisabledShowTooltip={Boolean(
                  disabledValues?.destinationAddress?.buildingNumber
                )}
              />
            </label>
            <label className={styles.apartment}>
              Apartment Number
              <Input
                name="destinationAddress.apartmentNum"
                type="text"
                placeholder="Apartment #"
                disabled={disabledValues?.destinationAddress?.apartmentNum}
                whenFieldDisabledShowTooltip={Boolean(
                  disabledValues?.destinationAddress?.apartmentNum
                )}
              />
            </label>
          </div>
        )}
        <label className={styles.apartmentCheckbox}>
          <Checkbox
            name="destinationAddress.isPublicPlace"
            checked={values?.destinationAddress?.isPublicPlace}
            onChange={handleChangePublicPlace({
              values,
              name: "destinationAddress",
            })}
            disabled={disabledValues?.destinationAddress?.isPublicPlace}
            whenFieldDisabledShowTooltip={Boolean(
              disabledValues?.destinationAddress?.isPublicPlace
            )}
          />
          <span className={styles.checkboxLabelText}>
            This is a public space
          </span>
        </label>
      </div>

      <label className={styles.securityCheckbox}>
        <Checkbox
          name="destinationSecurityGateDetail.hasSecurityGate"
          checked={values?.destinationSecurityGateDetail?.hasSecurityGate}
          onChange={handleSecurityGateChange({
            values,
            name: "destinationSecurityGateDetail",
          })}
        // disabled={
        //   disabledValues?.destinationSecurityGateDetail?.hasSecurityGate
        // }
        // whenFieldDisabledShowTooltip={Boolean(
        //   disabledValues?.destinationSecurityGateDetail?.hasSecurityGate
        // )}
        />
        <span className={styles.checkboxLabelText}>
          There is a security gate
        </span>
      </label>
      {values?.destinationSecurityGateDetail?.hasSecurityGate && (
        <div className={styles.address}>
          <label className={styles.securityInstruction}>
            Gate Entry Instruction
            <Input
              name="destinationSecurityGateDetail.securityGateInstruction"
              type="text"
              placeholder=" Gate Entry Instruction"
              disabled={
                disabledValues?.destinationSecurityGateDetail
                  ?.securityGateInstruction
              }
              whenFieldDisabledShowTooltip={Boolean(
                disabledValues?.destinationSecurityGateDetail
                  ?.securityGateInstruction
              )}
            />
          </label>
        </div>
      )}
 
      <PickUpDateTimeSection
        handleUseWindowOfTime={handleUseWindowOfTime}
        handlePickUpDateTime={handlePickUpDateTime}
        handleChangeTZ={handleChangeTZ}
        pickUpDate={pickUpDate}
        disabledDatesForPickUpDate={disabledDatesForPickUpDate}
        disabledDatesForAppointment={disabledDatesForAppointment}
        disabledDateTime={disabledDateTime}
        values={values}
        windowOfTimeIsValid={windowOfTimeIsValid}
        disabledValues={disabledValues}
        minDelayToStartRide={minDelayToStartRide}
        maxPickUpWindowOfTime={maxPickUpWindowOfTime}
        disabledDateForHigherLimit={disabledDateForHigherLimit}
        disabledTimeForHigherLimit={disabledTimeForHigherLimit}
        handleChangePickUpDateToLowerLimit={handleChangePickUpDateToLowerLimit}
        pickUpDateLowerLimit={pickUpDateLowerLimit}
        handleChangeappointmentDate={handleChangeappointmentDate}
        appointmentDate={appointmentDate}
        handleAppointmentDateCheckBox={handleAppointmentDateCheckBox}
      />
      {NotifyAirportBufferTime({ flightInfo: values?.flightInfo, updatedPickupDateTime: updatedPickupDateTime })}
      <label
        className={classNames({
          [styles.roundtrip]: true,
          [styles.roundtripChecked]: values?.isRoundtrip,
        })}
      >
        <Checkbox
          name="isRoundtrip"
          checked={values?.isRoundtrip}
          onChange={handleRoundTripCheckbox}
          disabled={disabledValues?.isRoundTrip}
          whenFieldDisabledShowTooltip={Boolean(disabledValues?.isRoundTrip)}
        />
        <span className={styles.checkboxLabelText}>Roundtrip ride</span>
        <SyncOutlined className={styles.icon} />
        <ThirdStepTooltip
          range={minimumRoundTrip}
          useFlexibleHoursMessage={false}
        />
      </label>
      <br />
      <ReturnDateTimeSection
        pickUpDate={pickUpDate}
        returnTimeDate={returnTimeDate}
        maxPickUpWindowOfTime={maxPickUpWindowOfTime}
        handleReturnTime={handleReturnTime}
        disabledDatesForReturnTime={disabledDatesForReturnTime}
        disabledDateTime={disabledDateTime}
        handleUseWindowOfTime={handleUseWindowOfTime}
        handleChangeTZ={handleChangeTZ}
        values={values}
        minimumRoundTrip={minimumRoundTrip}
        disabledValues={disabledValues}
        returnTimeLowerLimit={returnTimeLowerLimit}
        handleReturnTimeLowerLimit={handleReturnTimeLowerLimit}
        disabledDateForReturnHigherLimit={disabledDateForReturnHigherLimit}
        disabledDateTimeForReturnHigherLimit={
          disabledDateTimeForReturnHigherLimit
        }
      />
      <label className={styles.recurring}>
        <Checkbox
          name="isRecurring"
          onChange={handleReccuringCheckbox}
          checked={values?.isRecurring}
          disabled={
            disabledValues?.isRecurring ||
            values?.isPickUpDateFlexible ||
            values?.isReturnDateFlexible
          }
          whenFieldDisabledShowTooltip={Boolean(disabledValues?.isRecurring)}
        />
        <span className={styles.checkboxLabelText}>Recurring ride</span>
        <ScheduleOutlined
          className={styles.icon}
          disabled={disabledValues?.isRecurring}
        />
      </label>
      {values?.isRecurring && (
        <RecurringSection
          values={values}
          validateForm={validateForm}
          pickUpDate={pickUpDate}
          startPointOfRecurring={startPointOfRecurring}
          disabledReccuringValues={disabledValues?.recurrenceRule}
          setStartPointOfRecurring={setStartPointOfRecurring}
          setReturnStartPoint={setReturnStartPoint}
          handleChangeTZ={handleChangeTZ}
          returnStartPoint={returnStartPoint}
          handleStartPointTime={handleStartPointTime}
          handleReturnStartPoint={handleReturnStartPoint}
          disabledDatesForStartPointOfRecurring={
            disabledDatesForStartPointOfRecurring
          }
          minimumRoundTrip={minimumRoundTrip}
          disabledDatesForReturnTimeForRoundTripRecurring={
            disabledDatesForReturnTimeForRoundTripRecurring
          }
          disabledDateTime={disabledDateTime}
          minDelayToStartRide={minDelayToStartRide}
        />
      )}
    </div>
  );
};
