import { PhoneOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { renderPhoneNumber } from '../../../../utils/helpers';
import styles from '../index.module.css';

export const RiderPhoneInfo = ({ title = 'Mobile Phone Number:', phoneNumber }) => {
  return (
    <p className={styles.additionalInfo}>
      <span className={styles.recordTitle}>{title}</span>
      <span className={styles.phoneNumber}>
        {phoneNumber && (
          <a className={styles.phoneLink} href={`tel:${renderPhoneNumber(phoneNumber)}`}>
            {renderPhoneNumber(phoneNumber)}
            <PhoneOutlined className={styles.phoneIcon} />
          </a>
        )}
      </span>
    </p>
  );
};

export const RiderPhoneCommunicationTypes = ({ data }) => {
  return (
    <ul
      className={classNames({
        [styles.additionalOptions]: true,
        [styles.phoneOptions]: true,
      })}>
      {data?.canPhoneReceiveTexts && (
        <li>This phone can receive texts and recipient consents to this form of communication</li>
      )}
      {data?.canPhoneReceiveCalls && (
        <li>This phone can receive calls and recipient consents to this form of communication</li>
      )}
    </ul>
  );
};
