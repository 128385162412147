import React, { useCallback } from 'react';

import { Formik } from 'formik';
import { connect } from 'react-redux';

import { Input, Button, Logo, Loader } from '../../../components';
import { BackIcon } from '../../../assets';
import { forgotPasswordSchema } from '../../../utils';
import { getLoading } from '../../../store/selectors/common';

import styles from './index.module.css';

const SetEmail = ({ navigate, handleSubmit, loading, pageHeader, pageDescription, submitButtonText, showBackBtn }) => {
  const handleBackBtn = useCallback(() => navigate.back(), [navigate]);

  return (
    <div className={styles.forgotPage}>
      <Logo lg />
      <div className={styles.head}>
        {showBackBtn && (
          <Button myClassName={styles.btn} onClick={handleBackBtn}>
            <BackIcon width='11px' height='18px' fill='#BDBDBD' />
          </Button>
        )}
        <h1 className={styles.pageHeader}>{pageHeader}</h1>
      </div>
      <p className={styles.pageDescription}>{pageDescription}</p>
      <Formik
        initialValues={{ email: '' }}
        validationSchema={forgotPasswordSchema}
        onSubmit={(values) => handleSubmit({ email: values.email,Navigation:navigate })}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className={styles.resetForm}>
            <label className={styles.inputControl}>
              <span className={styles.required}>Email Address</span>
              <Input type='text' name='email' myClassName={styles.input} showAuthIcons data-testid='email' />
            </label>
            <div className={styles.submit}>
              <Button type='submit' onClick={handleSubmit}>
                {submitButtonText}
              </Button>
            </div>
          </form>
        )}
      </Formik>
      <Loader loading={loading} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: getLoading(state),
});

export default connect(mapStateToProps)(SetEmail);
