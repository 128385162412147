import React from 'react';
import classnames from 'classnames';

import { Modal, Button } from '../../../../';

import styles from '../index.module.css';

export const renderArchiveModal = ({ visibleModal, onCancel, handleChangeRideOptionStatus, option }) => {
  return (
    <Modal
      visible={visibleModal}
      onCancel={onCancel}
      closable
      width={560}
      title={
        <>
          Archiving <span className={styles.optionName}>{option?.value}</span>
        </>
      }>
      <div className={styles.modalContent}>
        <p className={styles.modalDescription}>
          Are you sure you want to archive the <span className={styles.optionName}>{option?.value}</span> option?
          Archiving will mean losing the ability to create new ride requests with this option.
        </p>

        <div
          className={classnames({
            [styles.archiveModalBtns]: true,
            [styles.modalBtns]: true,
          })}>
          <div className={styles.archiveModalBtn}>
            <Button myClassName={styles.navigationBtn} onClick={() => handleChangeRideOptionStatus(true)}>
              Confirm
            </Button>
          </div>
          <div className={styles.archiveModalBtn}>
            <Button myClassName={styles.navigationBtn} onClick={onCancel}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
