import React, { useCallback, useRef } from 'react';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Button,
  SecurityPreferencesEmail,
  SecurityPreferencesPassword,
  PersonalInfoPreferences,
  useTwoFactorVerificationModal,
} from '../../../components';
import { updatePasswordSchema, changeEmailSchema, personalInformationSchema } from '../../../utils';
import {
  changePassword,
  changePasswordVerification,
  updatePersonalInformation,
  changeEmail,
} from '../../../store/authorization';
import { BackIcon } from '../../../assets';

import styles from './index.module.css';
import { Navigation } from '../../../utils/navigation';

const Driver_Preferences = ({

  updatePersonalInformation,
  changeEmail,
  changePassword,
  changePasswordVerification,
  user,
}) => {
  const {back} = Navigation();
  const securityRef = useRef(null);
  const { TwoFactorAuthenticationModal, twoFactorModalVisible, setTwoFactorModalVisible } =
    useTwoFactorVerificationModal({
      handleConfirm: (token, confirmCallback) =>
        changePasswordVerification({ token, newPassword: securityRef?.current?.values?.newPassword }).then((res) => {
          if (res) {
            confirmCallback();
            securityRef?.current?.resetForm();
          }
        }),
      email: user?.email,
    });

  const handleBack = useCallback(() => back(), [back]);

  return (
    <div className={styles.pageContent}>
      <div className={styles.header}>
        <div className={styles.backBtn} onClick={handleBack}>
          <BackIcon width='18px' height='18px' fill='#BDBDBD' />
        </div>
        <h1 className={styles.pageTitle}>Preferences</h1>
      </div>
      <div className={styles.pageSection}>
        <Formik
          enableReinitialize
          validateOnMount
          initialValues={
            user || {
              firstName: '',
              lastName: '',
              preferredTimezone: '',
            }
          }
          validationSchema={personalInformationSchema}
          onSubmit={({ firstName, lastName, phoneNumber, jobTitle }) =>
            updatePersonalInformation({ firstName, lastName, phoneNumber, jobTitle })
          }>
          {({ values, handleSubmit, ...rest }) => (
            <form className={styles.form} onSubmit={handleSubmit}>
              <PersonalInfoPreferences hideTimezoneField values={values} />
              <div className={styles.btnPanel}>
                <div className={styles.btnControl}>
                  <Button disabled={!rest.isValid} type='submit'>
                    Save Changes
                  </Button>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
      <div>
        <h2>Password Information</h2>
        <Formik
          enableReinitialize
          validateOnMount
          initialValues={{
            newEmail: user?.email || '',
            password: '',
          }}
          onSubmit={(values) => changeEmail(values)}
          validationSchema={changeEmailSchema(user?.email)}>
          {({ values, ...rest }) => (
            <form className={styles.form} onSubmit={rest.handleSubmit}>
              {user && <SecurityPreferencesEmail values={values} initialEmail={user?.email} {...rest} />}
            </form>
          )}
        </Formik>
        <Formik
          innerRef={securityRef}
          validateOnMount
          initialValues={{
            newPassword: '',
            oldPassword: '',
          }}
          validationSchema={updatePasswordSchema}
          onSubmit={(values) => changePassword(values).then((res) => setTwoFactorModalVisible(res))}>
          {({ values, ...rest }) => (
            <form className={styles.form} onSubmit={rest.handleSubmit}>
              <SecurityPreferencesPassword values={values} {...rest} />
            </form>
          )}
        </Formik>
      </div>
      {twoFactorModalVisible && TwoFactorAuthenticationModal}
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.authorization.user,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ changePassword, changePasswordVerification, changeEmail, updatePersonalInformation }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Driver_Preferences);
