import { MailOutlined } from '@ant-design/icons';
import { Formik } from 'formik';
import React, { useState, useCallback, useRef } from 'react';

import { Modal, Button } from '../..';
import { renderRecordValue } from '../../../utils';
import { showNotificationMessage } from '../../common/MessageNotification/showNotificationMessage';
import { Input } from '../../formControls/Input/Input';

import styles from './index.module.css';

export const useTwoFactorVerificationModal = ({ handleConfirm, email }) => {
  const [visibleModal, setModalVisible] = useState(false);

  const verificationRef = useRef();

  const onCancel = useCallback(() => {
    setModalVisible(false);
    verificationRef?.current?.resetForm();
  }, [setModalVisible]);

  const confirmCallback = useCallback(() => {
    setModalVisible(false);
    verificationRef?.current?.resetForm();
    showNotificationMessage({
      type: 'success',
      content: renderRecordValue('Password was successfully updated'),
    });
  }, [setModalVisible]);

  const onConfirm = useCallback(
    (token) => () => handleConfirm(token, confirmCallback),
    [handleConfirm, confirmCallback],
  );

  return {
    TwoFactorAuthenticationModal: (
      <Modal visible={visibleModal} onCancel={onCancel} closable width={560} title='Two-Factor Authentication'>
        <div className={styles.twofactorModal}>
          <MailOutlined className={styles.mailIcon} />
          <div className={styles.modalContent}>
            <p className={styles.modalDescription}>Enter the code sent to your email in the field below.</p>
            <Formik initialValues={{ token: '' }} innerRef={verificationRef}>
              {({ values, handleSubmit }) => {
                return (
                  <>
                    <form onSubmit={handleSubmit}>
                      <label className={styles.inputLabel}>
                        Verification code
                        <Input type='text' name='token' placeholder='Enter Verification Code' />
                      </label>
                    </form>
                    <div className={styles.modalBtns}>
                      <div>
                        <Button myClassName={styles.navigationBtn} onClick={onCancel}>
                          Cancel
                        </Button>
                      </div>
                      <div>
                        <Button myClassName={styles.navigationBtn} onClick={onConfirm(values.token)}>
                          Verify
                        </Button>
                      </div>
                    </div>
                  </>
                );
              }}
            </Formik>
          </div>
        </div>
      </Modal>
    ),
    setTwoFactorModalVisible: setModalVisible,
    twoFactorModalVisible: visibleModal,
  };
};
