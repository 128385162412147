import classNames from 'classnames';
import { FieldArray, Formik, getIn } from 'formik';
import { Button, Checkbox, Snackbar } from '../..';
import { DateHelper, renderRecordValue } from '../../../utils';

import styles from './index.module.css';

const renderMatchedRiders =
  ({ riders, values, setFieldValue, name }) =>
  (arrayHelpers) => {
    const handleCheckbox =
      (value) =>
      async ({ checked }) => {
        if (checked) {
          await setFieldValue(name, [value]);
        } else {
          const idx = getIn(values, name)?.indexOf(value);
          if (idx !== -1) await arrayHelpers.remove(idx);
        }
      };
    return (
      <ul className={styles.suggestionsList}>
        {riders.map((rider) => (
          <label className={styles.riderLabel} key={rider?.id}>
            <Checkbox
              myClassName={styles.checkboxClassname}
              name={name}
              checked={getIn(values, name)?.includes(rider?.id)}
              value={rider.id}
              onChange={handleCheckbox(rider?.id)}
            />

            <li
              className={classNames({
                [styles.optionSuggestion]: true,
                [styles.riderSelected]: getIn(values, name)?.includes(rider.id),
              })}>
              {renderRecordValue(rider.firstName)} {renderRecordValue(rider.lastName)} (
              {renderRecordValue(rider?.dob && DateHelper.format(new Date(rider?.dob), 'MM/dd/Y'))})
            </li>
          </label>
        ))}
      </ul>
    );
  };

export const MatchedRidersSnackBar = ({ riders, handleSubmitUseExistingRider, handleCancelUseExistingRider }) => {
  return (
    <div className={styles.snackbarField}>
      <Snackbar
        type='warning'
        title={
          <>
            <span> A rider currently exists with the DoB of MM/DD/YYYY.</span>
            <br />
            <span>
              If you would like to use one of existing rider, pick one of suggested and Press "Yes, use existing rider"
            </span>
          </>
        }
        content={
          <div>
            <div className={styles.suggestions} />
            <Formik
              initialValues={{
                riders: [],
              }}
              enableReinitialize
              onSubmit={handleSubmitUseExistingRider}>
              {({ values, setFieldValue, handleSubmit }) => {
                return (
                  <>
                    <FieldArray
                      name={'riders'}
                      render={renderMatchedRiders({ riders, name: 'riders', values, setFieldValue })}
                    />

                    <div
                      className={classNames({
                        [styles.btns]: true,
                        [styles.suggestionBtns]: true,
                      })}>
                      <div>
                        <Button myClassName={styles.formControlBtn} onClick={handleCancelUseExistingRider}>
                          No, create new rider
                        </Button>
                      </div>
                      <div>
                        <Button
                          dis
                          myClassName={styles.formControlBtn}
                          onClick={handleSubmit}
                          disabled={!values?.riders.length}>
                          Yes, use existing rider
                        </Button>
                      </div>
                    </div>
                  </>
                );
              }}
            </Formik>
          </div>
        }
      />
    </div>
  );
};
