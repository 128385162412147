import { InfoOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { Tooltip } from '../../../components';
import { RIDE_STATUSES } from '../../../constants/constants';
import { statusesDictionary } from '../../../dictionaries';

import styles from './index.module.css';

export const allowedStatusesForEditOptions = [
  {
    title: statusesDictionary[RIDE_STATUSES.COMPLETED],
    value: RIDE_STATUSES.COMPLETED,
  },
  {
    title: statusesDictionary[RIDE_STATUSES.EXPIRED],
    value: RIDE_STATUSES.EXPIRED,
  },
  {
    title: statusesDictionary[RIDE_STATUSES.CANCELED],
    value: RIDE_STATUSES.CANCELED,
  },
  {
    title: statusesDictionary[RIDE_STATUSES.UNFULFILLED],
    value: RIDE_STATUSES.UNFULFILLED,
  },
  {
    title: statusesDictionary[RIDE_STATUSES.DECLINED],
    value: RIDE_STATUSES.DECLINED,
  },
  {
    title: statusesDictionary[RIDE_STATUSES.UNABLE_TO_FULFILL],
    value: RIDE_STATUSES.UNABLE_TO_FULFILL,
  },
];

export const StyledText = ({ children }) => <span className={styles.styledText}>{children}</span>;

export const driverArrivalTooltipMessage = (
  <>
    The minimum value of <StyledText>Driver Arrival Date</StyledText> should not be greater than{' '}
    <StyledText>Actual Pickup Date</StyledText>
  </>
);

export const endDateTooltipMessage = (
  <>
    The minimum value of <StyledText>Actual Dropoff Date</StyledText> should not be earlier than{' '}
    <StyledText>Actual Pickup Date</StyledText>
  </>
);

export const deadHeadMileageTooltipMessage = (
  <>
    
    <StyledText>Deadhead Mileage</StyledText>, the miles driven without riders, must be logged separately from Ride Total Mileage. 
  
  </>
);

export const rideTotalMileageTooltipMessage = (
  <>
    If 0 is added, estimated mileage will be use to generate the receipt. 
  </>
);

export const acceptedForToolTipMessage = (
  <>
    The value must be within the values of <StyledText> Earliest Requested Pickup Date</StyledText> and{' '}
    <StyledText>Latest Requested Pickup Date</StyledText>
  </>
);

export const driversToolTipMessage = (
  <>
    Pick one of available <StyledText>Driver</StyledText> for this ride.Drivers listed in gray font do not qualify for
    this ride request based on Vehicle Selection/Type.
  </>
);

export const transportationCompaniesTooltipMessage = (
  <>
    Pick one of available<StyledText> Transportation Company </StyledText> for this ride
  </>
);

export const allowedStatuses = (
  <>
    Pick one of available ride statuses:
    <StyledText>{allowedStatusesForEditOptions.map((s) => s.title).join(', ')}</StyledText>
  </>
);

export const DatePickerToolTip = ({ message }) => {
  return (
    <Tooltip title={message} myClassName={styles.alignSelf}>
      <span
        className={classNames({
          [styles.tooltipInfo]: true,
        })}>
        <InfoOutlined className={styles.infoIcon} />
      </span>
    </Tooltip>
  );
};
