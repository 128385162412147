import React from 'react';
import Highlighter from 'react-highlight-words';

import { Button, renderResetCredentialsButton } from '../../../components';
import { renderRecordValue } from '../../../utils';
import { USER_ROLES, USER_STATUSES } from '../../../constants/constants';

import styles from './index.module.css';

export const renderColumns = (searchValue, highlighted) => [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    width: 250,
    render: (text) => (
      <div className={styles.cellValue}>
        {searchValue && highlighted ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchValue]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          renderRecordValue(text)
        )}
      </div>
    ),
  },
  {
    title: 'Users',
    dataIndex: 'usersNumber',
    key: 'usersNumber',
    render: (text) => {
      return <div className={styles.cellValue}>{renderRecordValue(text)}</div>;
    },
    width: 150,
  },
  {
    title: 'State(s)',
    dataIndex: 'state',
    key: 'state',
    render: (text) => <div className={styles.cellValue}>{renderRecordValue(text)}</div>,
    width: 270,
  },
  {
    title: '',
    key: 'buttons',
    dataIndex: 'buttons',
    render: (buttons) => <div className={styles.btnPanel}>{buttons.map((Component) => Component)}</div>,
    width: 680,
  },
];

export const transformData = ({
  data,
  handleManageOrganization,
  switchOrganizationStatus,
  handleSendPassword,
  handleResendInvitation,
  handleViewDashboard,
}) => {
  return data.map((value) => {
    return {
      key: value.id,
      name: value.name,
      usersNumber: value.usersNumber,
      state: value?.state,
      buttons: value.disabled
        ? [
            <div key={0} className={styles.restoreAccess}>
              <Button
                myClassName={styles.navigationBtn}
                onClick={switchOrganizationStatus({ disabled: false, id: value.id })}>
                Activate Organization
              </Button>
            </div>,
          ]
        : [
            <div key={0} className={styles.tableButton}>
              <Button
                disabled={value.adminStatus === USER_STATUSES.INVITED}
                myClassName={styles.navigationBtn}
                onClick={handleViewDashboard({
                  organizationId: value.id,
                  role: USER_ROLES.HO_ADMIN,
                  organizationName: value.name,
                })}>
                View Dashboard
              </Button>
            </div>,
            renderResetCredentialsButton({
              invited: value.adminStatus === USER_STATUSES.INVITED,
              handleSendPassword: handleSendPassword(value?.admin),
              handleResendInvitation: handleResendInvitation(value?.admin),
              resetButtonText: '(Send) Reset Password to Admin',
              resendButtonText: 'Resend Invitation to Admin',
            }),
            <div key={2} className={styles.tableButton}>
              <Button myClassName={styles.navigationBtn} onClick={handleManageOrganization(value.id)}>
                Manage Organization
              </Button>
            </div>,
          ],
    };
  });
};
