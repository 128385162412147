import React, { useCallback } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
 
import  {useIdleTimer }  from 'react-idle-timer'
import PropTypes from 'prop-types';

import { IdleTimerModal } from '../../modals/IdleTimerModal/IdleTimerModal';
import { toggleUserInactivityModal } from '../../../store/common';
import { logOut } from '../../../store/authorization';

import { saveUnSavedDraftAndLogOut } from '../../../store/drafts';
import { Component } from 'react'
import { withIdleTimer } from 'react-idle-timer'
import { Navigation } from '../../../utils/navigation';

const IdleTimer = ({
  userInactivityModalVisible,
  toggleUserInactivityModal,
  saveUnSavedDraftAndLogOut,
  logOut,
  MODAL_WILL_APPEAR_AFTER_MS,
  MODAL_WILL_AUTO_CLOSE_AFTER_MS,
  MODAL_AUTO_CLOSE_AFTER_SEC,
  logoutTimerRef,
  idleTimerRef,
  workerTimers,
}) => {
 
  const handleLogout = useCallback(() => {
    toggleUserInactivityModal(false);
    idleTimerRef?.current?.reset();
    
    
    saveUnSavedDraftAndLogOut();
  }, [idleTimerRef, saveUnSavedDraftAndLogOut, toggleUserInactivityModal]);

  const handleOnIdle = () => {
    if (!idleTimerRef?.current) {
      logoutTimerRef.current = workerTimers.setTimeout(() => {
        toggleUserInactivityModal(false);
        idleTimerRef?.current?.reset();
        logOut(Navigation());
      }, MODAL_WILL_AUTO_CLOSE_AFTER_MS);
    }

    toggleUserInactivityModal(true);
  };

  const handleAction = () => {
    idleTimerRef?.current?.reset();
    if (logoutTimerRef.current) {
      workerTimers.clearTimeout(logoutTimerRef?.current);
      logoutTimerRef.current = null;
    }
  };

  const handleContinueSession = () => {
    
      toggleUserInactivityModal(false);
      
      if (logoutTimerRef.current) {
      workerTimers.clearTimeout(logoutTimerRef?.current);
      logoutTimerRef.current = null;
    } 
  
    idleTimerRef.current.reset();
   
  };

  return (
    <>
      <Timer
        ref={idleTimerRef}
        timeout={MODAL_WILL_APPEAR_AFTER_MS}
        onIdle={handleOnIdle}
        onAction={handleAction}
        debounce={250}
        crossTab={true}
      />
      <IdleTimerModal
        visible={userInactivityModalVisible}
        MODAL_AUTO_CLOSE_AFTER_SEC={MODAL_AUTO_CLOSE_AFTER_SEC}
        title='User Inactivity'
        handleContinueSession={handleContinueSession}
        handleLogout={handleLogout}
        workerTimers={workerTimers}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  userInactivityModalVisible: state.common.userInactivityModalVisible,
  role: state.authorization.role,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ toggleUserInactivityModal, logOut, saveUnSavedDraftAndLogOut }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(IdleTimer);

IdleTimer.propTypes = {
  userInactivityModalVisible: PropTypes.bool.isRequired,
  toggleUserInactivityModal: PropTypes.func.isRequired,
  saveUnSavedDraftAndLogOut: PropTypes.func,
  logOut: PropTypes.func.isRequired,
  MODAL_WILL_APPEAR_AFTER_MS: PropTypes.number.isRequired,
  MODAL_WILL_AUTO_CLOSE_AFTER_MS: PropTypes.number.isRequired,
  MODAL_AUTO_CLOSE_AFTER_SEC: PropTypes.number.isRequired,
  workerTimers: PropTypes.object.isRequired,
};



class TimerComponent extends Component {
  render() {
    return this.props.children
  }
}

export const Timer = withIdleTimer(TimerComponent)