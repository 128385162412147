import React, { useMemo, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { bindActionCreators } from 'redux';

import { BackIcon } from '../../../assets';
import { DriverManageRidePanel, DriverRideCard, GoogleMapComponent } from '../../../components';
import { getRide, clearCurrentRide, startRide, endRide, setShowRefreshNotification, driverArrival } from '../../../store/rides';
import { copyToClipboard, DateHelper, renderRecordValue } from '../../../utils';
import { renderHeaderTitle } from './renderHeaderTitle';
import { DEFAULT_TIME, RIDE_STATUSES, DEFAULT_LOAD_TIME } from '../../../constants/constants';
import { ConfirmRideActionModal } from './ConfirmRideActionModal';
import { destroyNotification } from '../../../components/common/ConfirmPopUp/ConfirmPopUp';
import { Navigation } from '../../../utils/navigation';

import styles from './index.module.css';

const DriverRideDetails = ({
  getRide,
  startRide,
  endRide,
  clearCurrentRide,
  setShowRefreshNotification,
  ride,
  tcConfiguration,
  driverArrival  
}) => {
  const navigate =Navigation();
  const [startRideModalVisible, setStartRideModalVisible] = useState(false);
  const [endRideModalVisible, setEndRideModalVisible] = useState(false);
  const [waitTime, setWaitTime] = useState(DEFAULT_TIME);
  const [loadUnloadTime, setLoadUnloadTime] = useState(DEFAULT_LOAD_TIME);
  const { id } = useParams();
  const showRideStatus = useMemo(
    () =>
      ride?.status === RIDE_STATUSES.ACCEPTED ||
      ride?.status === RIDE_STATUSES.IN_PROGRESS ||
      ride?.status === RIDE_STATUSES.COMPLETED ||
      ride?.status === RIDE_STATUSES.UNABLE_TO_FULFILL ||
      ride?.status === RIDE_STATUSES.UNFULFILLED,
    [ride?.status],
  );

  const showManagePanel = ride?.status === RIDE_STATUSES.ACCEPTED || ride?.status === RIDE_STATUSES.IN_PROGRESS;
  const handleBack = useCallback(() => navigate.back(), []);

  const handleDriverArrival = useCallback(() => {
    driverArrival({
      rideId: id,
      payload: {
        datetime: DateHelper.zonedTimeToUtcISOS(
          DateHelper.utcToZonedTime(new Date(), ride?.pickUpDate.timezone),
          ride?.pickUpDate.timezone,
        ),
      },
    });

  }, [driverArrival, id, ride?.pickUpDate?.timezone]);

  const handleStartRide = useCallback(() => {
    
    startRide({
      rideId: id,
      payload: {
        datetime: DateHelper.zonedTimeToUtcISOS(
          DateHelper.utcToZonedTime(new Date(), ride?.pickUpDate.timezone),
          ride?.pickUpDate.timezone,
        ),
        waitTime : DateHelper.convertTimeToMinutes(waitTime),
        ...loadUnloadTime
      },
    });
    setStartRideModalVisible(false);
  }, [startRide, id, ride?.pickUpDate.timezone, waitTime, loadUnloadTime]);

  const handleEndRide = useCallback(
    ( otherEndRideParams ) => {
      endRide({
        rideId: id,
        payload: {
          datetime: DateHelper.zonedTimeToUtcISOS(
            DateHelper.utcToZonedTime(new Date(), ride?.pickUpDate.timezone),
            ride?.pickUpDate.timezone,
          ),
          waitTime : DateHelper.convertTimeToMinutes(waitTime),
          ...loadUnloadTime,
          ...otherEndRideParams,
        },
      });
      setEndRideModalVisible(false);
    },
    [endRide, id, ride?.pickUpDate.timezone, waitTime, loadUnloadTime],
  );

  const handleCancel = useCallback(() => navigate.go(`/rides/${id}/cancel`), [ id]);

  const handleToggleStartRideModal = useCallback((flag) => () => setStartRideModalVisible(flag), []);

  const handleToggleEndRideModal = useCallback((flag) => () => setEndRideModalVisible(flag), []);

  const handleCopy = useCallback((ref) => copyToClipboard(ref), []);

  useEffect(() => {getRide(id,navigate); return () => {};}, [id, getRide]);

  useEffect(
    () => () => {
      setShowRefreshNotification(false);
      destroyNotification();
      clearCurrentRide();
      return () => {};
    },
    [clearCurrentRide, setShowRefreshNotification],
  );

  
  return (
    <>
      <div className={styles.pageContent}>
        <div className={styles.header}>
          <div className={styles.backBtn} onClick={handleBack}>
            <BackIcon width='18px' height='18px' fill='#BDBDBD' />
          </div>
          <h1 className={styles.pageTitle}>{renderRecordValue(renderHeaderTitle(ride?.status))}</h1>
        </div>

        {ride && (
          <GoogleMapComponent
            destination={ride?.destinationAddress?.formattedAddress}
            origin={ride?.pickupAddressDetails?.formattedAddress}
          />
        )}

        <DriverRideCard
          showFullInfo
          managePanelVisible={showManagePanel}
          showRideStatus={showRideStatus}
          ride={ride}
          handleCopy={handleCopy}
          setWaitTime={setWaitTime}
          setLoadUnloadTime={setLoadUnloadTime}
        />
        {ride && showManagePanel && (
          <DriverManageRidePanel
            driverStarted={Boolean(!ride?.driverArrivalTime)}
            rideStarted={Boolean(ride?.startDate)}
            handleDriverArrival={handleDriverArrival}
            handleStartRide={handleToggleStartRideModal(true)}
            handleEndRide={handleToggleEndRideModal(true)}
            handleCancel={handleCancel}
          />
        )}
      </div>

      {startRideModalVisible && (
        <ConfirmRideActionModal
          visibleModal={startRideModalVisible}
          onConfirm={handleStartRide}
          onCancel={handleToggleStartRideModal(false)}
          id={ride?.seqId}
          start
        />
      )}
      
      {endRideModalVisible && (
        <ConfirmRideActionModal
          visibleModal={endRideModalVisible}
          onConfirm={handleEndRide}
          onCancel={handleToggleEndRideModal(false)}
          id={ride?.seqId}
          tcConfiguration={tcConfiguration}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  ride: state.rides.ride,
  tcConfiguration: state.transportOrganizations.configuration
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ startRide, endRide, driverArrival, getRide, clearCurrentRide, setShowRefreshNotification }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DriverRideDetails);
