import React from "react";
import { EditOutlined, CopyOutlined } from "@ant-design/icons";
import classnames from "classnames";
import { FlightInformation, RountTrip } from "../../../components";
import { DateHelper, renderRecordValue } from "../../../utils";
import { STATUSES_STYLING } from "../../../constants/styles/statuses";
import { getRiderActualHeightForBariaticVehicleType, getRiderActualWeightForBariaticVehicleType } from "../../../utils/helpers";
import styles from "./index.module.css";
import { vehicleOptionsDictionary } from "../../../dictionaries";
import { Popover } from "antd";
import { OverNight } from "../../../components/common/OverNight/OverNight";
import { RIDE_STATUSES } from "../../../constants/constants";
import { RescheduleIcon } from "../../../assets";
import { RescheduleRideIcon } from "../../../components/common/RescheduleRideIcon/RescheduleRideIcon";

export const renderColumns = ({ handleCopyRide, handleEditRide, handleRescheduleRide }) => {
  return [
    {
      title: "Ride ID",
      dataIndex: "seqId",
      key: "seqId",
      render: (text, record) => (
        <div
          className={classnames({
            [styles.draftIdCellValue]: true,
            [styles.cellValue]: true,
          })}
        >
          {record?.flightInfo ? (
            <Popover
              content={<FlightInformation flightInfo={record?.flightInfo} />}
              placement="topLeft"
              getPopupContainer={() => document.querySelector(".ant-table")}
            >
              <span style={{ color: "blue", textDecorationLine: "underline" }}>
                {renderRecordValue(text)}
              </span>
            </Popover>
          ) : (
            renderRecordValue(text)
          )}
          <br />
          <div className={styles.dflex}>
             
            <OverNight record={record} />
            <RountTrip record={record} />

            {/* ToDo Rescheudleing */}
           {/* { (record?.status === RIDE_STATUSES.RESCHEDULED || record?.rescheduledFrom ) &&   <RescheduleRideIcon record={record}/>} */}
          </div>
        </div>

       
      ),
      width: 190,
    },

    {
      title: "Rider Name",
      dataIndex: "rider",
      key: "rider",
      width: 180,
      render: (text) => (
        <div
          className={classnames({
            [styles.riderCellValue]: true,
            [styles.cellValue]: true,
          })}
        >
          {renderRecordValue(text)}
        </div>
      ),
    },
    {
      title: "Vehicle Type",
      dataIndex: "vehicleType",
      key: "vehicleType",
      width: 250,
      render: (text, record) => (
        <>
          <div
            className={classnames({
              [styles.vehicleCellValue]: true,
              [styles.cellValue]: true,
            })}
          >
            {renderRecordValue(vehicleOptionsDictionary[text])}
          </div>
          <div
            className={classnames({
              [styles.vehicleCellValue]: true,
              [styles.cellValue]: true,
              [styles.actualWeight]: true,
            })}
          >
            {getRiderActualWeightForBariaticVehicleType(text, record)}
          </div>
          <div
            className={classnames({
              [styles.vehicleCellValue]: true,
              [styles.cellValue]: true,
              [styles.actualWeight]: true,
            })}
          >
            {getRiderActualHeightForBariaticVehicleType(text, record)}
          </div>
        </>
      ),
    },
    {
      title: "Pickup Date",
      dataIndex: "pickUpDate",
      key: "pickUpDate",
      width: 250,
      render: (value) => (
        <div
          className={classnames({
            [styles.pickUpDateCellValue]: true,
            [styles.cellValue]: true,
          })}
        >
          {renderRecordValue(value)}
        </div>
      ),
    },

    {
      title: "Date Created",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 150,
      render: (text) => (
        <div
          className={classnames({
            [styles.dateCreatedCellValue]: true,
            [styles.cellValue]: true,
          })}
        >
          {renderRecordValue(
            text && DateHelper.format(new Date(text), "M/dd/yyyy")
          )}
        </div>
      ),
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
      width: 150,
      render: (text) => (
        <div
          className={classnames({
            [styles.createdByCellValue]: true,
            [styles.cellValue]: true,
          })}
        >
          {renderRecordValue(text)}
        </div>
      ),
    },
    {
      title: "Ride Status",
      dataIndex: "readableStatus",
      key: "readableStatus",
      width: 160,
      render: (text) => (
        <div
          className={classnames({
            [styles.statusCellValue]: true,
            [STATUSES_STYLING[text]]: true,
          })}
        >
          {renderRecordValue(text.toLowerCase() !== RIDE_STATUSES.RESCHEDULED.toLowerCase()? text : 
          (
            'Pending'

            // <Popover
            //   content={''}
            //   placement="topLeft"
            //   getPopupContainer={() => document.querySelector(".ant-table")}
            // >

            // </Popover>
          )
           )}
        </div>
      ),
    },
    {
      title: "Recurring",
      Component: (
        <th>
          <div className={styles.cellValue} />
        </th>
      ),
      dataIndex: "actions",
      key: "actions",
      width: 120,
      render: (text, record) => {
        return (
          <>

            <div
              className={classnames({
                [styles.recurringInfo]: true,
                [styles.recurringCellValue]: true,
                [styles.lastRide]: record.isLastRecurring,
              })}
            >
              {renderRecordValue(record.recurringRideIndex)}
            </div>
            <div
              className={classnames({
                [styles.actionsColumn]: true,
                [styles.cellValue]: true,
              })}
            >
              <div
                className={classnames({
                  [styles.actions]: true,
                })}
              >
                <EditOutlined
                  className={styles.actionsIcon}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleEditRide(record?.id, record?.isRecurring);
                  }}
                />

{/* ToDo Rescheudleing */}
                {/* {record?.status == RIDE_STATUSES.ACCEPTED  && !record?.rescheduledFrom &&

                  <span className={classnames({
                    [styles.icon_container]: true,

                  })

                  }
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRescheduleRide(record?.id, record?.isRecurring);
                    }}
                  >
                    <RescheduleIcon fill={'#DE7F39'}

                    /></span>} */}
                <CopyOutlined
                  className={classnames({
                    [styles.actionsIcon]: true,

                  })}

                  onClick={(e) => {
                    e.stopPropagation();
                    handleCopyRide(record);
                  }}
                />
              </div>
            </div>
          </>
        );
      },
    },
  ];
};
