import React, { useCallback, useEffect, useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Rides } from '../..';
import { renderColumns } from './renderColumns';
import { renderTableHead } from './renderTableHead';
import { copyRide } from '../../../store/drafts';
import { getCustomUserFilter, saveCustomUserFilter } from '../../../store/rides';
import { useEditReccuringModal } from '../../../hooks/useEditRecurringModal';

import styles from './index.module.css';
import { Navigation } from '../../../utils/navigation';

const HO_Rides = ({ copyRide, getCustomUserFilter, savedFilterParams, hasSavedFilterParams }) => {
  const navigate =Navigation();
  const { EditRecurringModal, handleEditRide,handleRescheduleRide } = useEditReccuringModal();

  const handleCopyRide = useCallback((ride) => {


    
    if(ride?.connectedLegId)
    {
     return copyRide(ride?.leg.toLowerCase() === 'a' ? ride.id:ride?.connectedLegId ,navigate)
    }


    return copyRide(ride?.id,navigate)
  
  }, [copyRide,navigate]);
 
  const tableColumns = useMemo(
    () =>
      renderColumns({
        handleCopyRide,
        handleEditRide,
        handleRescheduleRide
      }),
    [handleCopyRide, handleEditRide],
  );

  const tableHead = useMemo(() => renderTableHead(), []);

  const ridesTable = useMemo(
    () => (
      <div className={styles.table}>
        <Rides
          columns={tableColumns}
          savedFilterParams={savedFilterParams}
          hasSavedFilterParams={hasSavedFilterParams}
          renderTableHead={tableHead}
        />
      </div>
    ),
    [hasSavedFilterParams, tableHead, tableColumns, savedFilterParams],
  );
  useEffect(() => {getCustomUserFilter(); return;}, [getCustomUserFilter]);

  return (
    <>
      {ridesTable}
      {EditRecurringModal}
    </>
  );
};

const mapStateToProps = (state) => ({
  savedFilterParams: state.rides?.savedFilterParams,
  hasSavedFilterParams: state.rides?.savedFilterParams && Boolean(Object.keys(state.rides?.savedFilterParams)?.length),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      copyRide,
      getCustomUserFilter,
      saveCustomUserFilter,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(HO_Rides);
