import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import styles from './index.module.css';

export const Burger = ({ onClick, collapsed }) => (
  <div
    className={classnames({
      [styles.drawerHandle]: true,
      [styles.drawerOpen]: !collapsed,
    })}
    onClick={onClick}>
    <i className={styles.drawerHandleIcon}></i>
  </div>
);

Burger.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};
