import { BOOSTER_CAR_SEAT_TIME_SEGMENTS, MH_PREFERENCES_FIELDS, MINIMUM_TIME_BTW_ROUNDTRIP_SEGMENTS_OBJ } from '../../../mock';
import { VEHICLE_TYPES, MINIMUM_DELAY_FOR_ROUNDTRIP_RIDE, MINIMUM_BUFFER_TIME_FOR_CAR_BOOSTER_RIDE } from '../../../../../constants/constants';

export const defaultTimeValues = {
  hours: 0,
  minutes: 0,
};

export const defaultValues = {
  [MH_PREFERENCES_FIELDS.rideExpirationTime]: {
    onDemand: defaultTimeValues,
    scheduled: defaultTimeValues,
    driverInactivity: defaultTimeValues,
    acceptedWithoutDriver: defaultTimeValues,
    rideNotStartedAlert: defaultTimeValues,
  },
  [MH_PREFERENCES_FIELDS.minTimeFromRideRequestToPickup]: {
    [VEHICLE_TYPES.STRETCHER_BOUND_BARIATRIC]: defaultTimeValues,
    [VEHICLE_TYPES.STRETCHER_BOUND_NON_BARIATRIC]: defaultTimeValues,
    [VEHICLE_TYPES.AMBULATORY_ASSIST_RIDER_IS_WALKING]: defaultTimeValues,
    [VEHICLE_TYPES.AMBULATORY_RIDER_IS_WALKING]: defaultTimeValues,
    [VEHICLE_TYPES.WHEEL_CHAIR_BOUND_BARIATRIC]: defaultTimeValues,
    [VEHICLE_TYPES.WHEEL_CHAIR_BOUND_NON_BARIATRIC]: defaultTimeValues,
  },
  [MH_PREFERENCES_FIELDS.carBoosterSeatBufferTime]: defaultTimeValues,
  [MINIMUM_TIME_BTW_ROUNDTRIP_SEGMENTS_OBJ.value]: MINIMUM_DELAY_FOR_ROUNDTRIP_RIDE,
  [BOOSTER_CAR_SEAT_TIME_SEGMENTS.value]: MINIMUM_BUFFER_TIME_FOR_CAR_BOOSTER_RIDE,
  [MH_PREFERENCES_FIELDS.notifications]: {
    firstReminderToRider: defaultTimeValues,
    secondReminderToRider: defaultTimeValues,
  },
  [MH_PREFERENCES_FIELDS.supportContacts]: {
    email: '',
    phoneNumber: '',
  },
  [MH_PREFERENCES_FIELDS.notificationsSwitcher]: {
    email: false,
    pushNotifications: false,
    sms: false,
  },
  [MH_PREFERENCES_FIELDS.internalReportSettings]: {
    timezone: "America/Chicago",
    reminderDay: "DAY_AHEAD",
    reminderHour: 7,
  },
  [MH_PREFERENCES_FIELDS.userPreferences]: {
    passwordRetries: 5,
    loginBlockedUntil: 1
  },
  maxPickUpWindowOfTime: defaultTimeValues,
  newOption: '',
  rideOptions: [],
};