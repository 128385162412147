import React from 'react';
import Highlighter from 'react-highlight-words';

import { Button } from '../../../components';
import { renderRecordValue } from '../../../utils';
import { USER_ROLES, USER_STATUSES } from '../../../constants/constants';
import { renderResetCredentialsButton } from '../../../components';

import styles from './index.module.css';
import { renderOrgStatus } from '../../../utils/helpers';
import classNames from 'classnames';
import { Popover } from 'antd';

export const renderColumns = (searchValue, highlighted) => [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (text) => (
      <div className={styles.cellValue}>
        {searchValue && highlighted ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchValue]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          renderRecordValue(text)
        )}
      </div>
    ),

    width: 150,
  },
  {
    title: 'Users',
    dataIndex: 'usersNumber',
    key: 'usersNumber',
    render: (text) => <div className={styles.cellValue}>{renderRecordValue(text)}</div>,
    width: 90,
  },
  {
    title: 'Drivers',
    dataIndex: 'drivers',
    key: 'drivers',
    render: (text) => <div className={styles.cellValue}>{renderRecordValue(text)}</div>,
    width: 90,
  },
  {
    title: 'State(s)',
    dataIndex: 'state',
    key: 'state',
    render: (text) => (
      <div className={styles.cellValue}>
        {searchValue && highlighted ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchValue]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          renderRecordValue(text)
        )}
      </div>
    ),
    width: 180,
  },
  {
    title: 'Counties',
    dataIndex: 'counties',
    key: 'counties',
    render: (text) => (
      <div className={styles.colEllipsis}>
        {
        searchValue && highlighted ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchValue]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          <Popover
            content={renderRecordValue(text)}
            placement="topLeft"
            overlayClassName={classNames({
              [styles.overlay]: true,
            })}
            getPopupContainer={(trigger) => trigger}
          >
            <div>{renderRecordValue(text)}</div>
          </Popover>
          // <Tooltip
          //       color={'#de7f39'}
          //       title={
          //         <span
          //           className={classNames({
          //             [styles.tooltipContent]: true,
          //             [styles.itemPos]: true,
          //           })}
          //         >
          //           {renderRecordValue(text)}
          //         </span>
          //       }
          //     >
          //       <div>{renderRecordValue(text)}</div>
          //     </Tooltip>
        )}
      </div>
    ),
    width: 300,
  },
  {
    title: 'Status',
    dataIndex: 'disabled',
    key: 'disabled',
    render: (text) => <div className={styles.cellValue}>{renderOrgStatus(text)}</div>,
    width: 80,
  },
  {
    title: '',
    key: 'buttons',
    dataIndex: 'buttons',
    render: (buttons) => <div className={styles.btnPanel}>{buttons.map((Component) => Component)}</div>,
    width: 500,
  },
];

export const transformData = ({
  data,
  handleManageOrganization,
  switchOrganizationStatus,
  handleSendPassword,
  handleResendInvitation,
  handleViewDashboard,
}) => {
  return data.map((value) => {
    return {
      key: value.id,
      name: value.name,
      usersNumber: value.usersNumber,
      state: value?.states?.join(', '),
      counties: value?.counties?.join(', '),
      drivers: value.driversNumber || 0,
      disabled: value?.disabled,
      buttons: value.disabled
        ? [
          <div key={0} className={styles.tableButton}>
          <Button
           
            myClassName={styles.navigationBtn}
            onClick={handleViewDashboard({
              organizationId: value.id,
              role: USER_ROLES.TC_ADMIN,
              organizationName: value.name,
            })}>
            View Dashboard
          </Button>
        </div>,
            <div key={1} className={styles.restoreAccess}>
              <Button
                myClassName={styles.navigationBtn}
                onClick={switchOrganizationStatus({ disabled: false, id: value.id })}>
                Activate Company
              </Button>
            </div>,
          ]
        : [
            <div key={0} className={styles.tableButton}>
              <Button
                disabled={value.adminStatus === USER_STATUSES.INVITED}
                myClassName={styles.navigationBtn}
                onClick={handleViewDashboard({
                  organizationId: value.id,
                  role: USER_ROLES.TC_ADMIN,
                  organizationName: value.name,
                })}>
                View Dashboard
              </Button>
            </div>,
            renderResetCredentialsButton({
              invited: value.adminStatus === USER_STATUSES.INVITED,
              value,
              handleSendPassword: handleSendPassword(value?.admin),
              handleResendInvitation: handleResendInvitation(value?.admin),
              resetButtonText: '(Send) Reset Password to Admin',
              resendButtonText: 'Resend Invitation to Admin',
            }),
            <div key={2} className={styles.tableButton}>
              <Button myClassName={styles.navigationBtn} onClick={handleManageOrganization(value.id)}>
                Manage Company
              </Button>
            </div>,
          ],
    };
  });
};
