import React from 'react';

import { Modal, Input, Button } from '../..';

import styles from './index.module.css';

export const InviteNewUserModal = ({ visible, onCancel, title, isValid, handleSubmit }) => {
  return (
    <Modal visible={visible} onCancel={onCancel} closable width={580} title={title}>
      <div className={styles.modalContent}>
        <p className={styles.modalDescription}>You are able to invite a new user to this organization</p>
        <form className={styles.modalForm} onSubmit={handleSubmit}>
          <label className={styles.name}>
            First Name
            <Input name='firstName' type='text' />
          </label>
          <label className={styles.name}>
            Last Name
            <Input name='lastName' type='text' />
          </label>
          <label className={styles.email}>
            Email
            <Input name='email' type='text' />
          </label>
          <div className={styles.modalBtns}>
            <div>
              <Button myClassName={styles.navigationBtn} onClick={onCancel}>
                Cancel
              </Button>
            </div>
            <div>
              <Button myClassName={styles.navigationBtn} type='submit' disabled={!isValid}>
                Invite User
              </Button>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};
