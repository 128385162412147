import styles from '../index.module.css';
import { Record } from '../RideDetails';
import { LodashHelper, renderRecordValue } from '../../../../utils';

export const renderBuildingDetails = (address) => {
  return (
    (LodashHelper.has(address, 'buildingNumber') || LodashHelper.has(address, 'apartmentNum')) && (
      <div className={styles.addressInfo}>
        {LodashHelper.has(address, 'buildingNumber') && (
          <Record title='Bldg #' value={renderRecordValue(address.buildingNumber)} />
        )}
        {LodashHelper.has(address, 'buildingNumber') && LodashHelper.has(address, 'apartmentNum') && (
          <span className={styles.separator} />
        )}
        {LodashHelper.has(address, 'apartmentNum') && (
          <Record title='Apt #' value={renderRecordValue(address.apartmentNum)} />
        )}
      </div>
    )
  );
};
