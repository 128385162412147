import React from "react";

import { Checkbox, RadioGroup } from "../..";

import { includedRideRequestField } from "../../forms/mock";

import { rideRequestFieldsDictionary } from "../../../dictionaries";

import {
  MANDATORY_LEVEL,
  RIDE_REQUEST_FIELDS,
} from "../../../constants/constants";

import styles from "./index.module.css";

const RideRequestFields = (values, disableRideRequestFields) => {

  const handleCheckbox = ({ name, checked, setValues, values }) => {
    const copiedValues = { ...values };

    if (checked) {
      setValues({
        ...values,

        rideRequestFields: {
          ...copiedValues.rideRequestFields,

          [name]: MANDATORY_LEVEL.MANDATORY,
        },
      });
    } else {
      delete copiedValues?.rideRequestFields[name];

      setValues({
        ...copiedValues,
      });
    }
  };

  const handleRadio = ({ event, setValues, values }) => {
    setValues({
      ...values,

      rideRequestFields: {
        ...values.rideRequestFields,

        [event.target.name]: event.target.value,
      },
    });
  };

  return Object.keys(RIDE_REQUEST_FIELDS).map((key) => (
   
    <div className={styles.checkbox} key={key}>
      <label className={styles.wrappLabel}>
        <Checkbox
          name={key}
          checked={
            values?.rideRequestFields && Boolean(values.rideRequestFields[key])
          }
          value={key}
          onChange={handleCheckbox}
          disabled={disableRideRequestFields}
        />

        <span className={styles.checkboxLabel}>
          {rideRequestFieldsDictionary[key]}
        </span>
      </label>

      {values?.rideRequestFields && Boolean(values.rideRequestFields[key]) && (
        <>
          {key !== RIDE_REQUEST_FIELDS.covid19Relation && (
            <span className={styles.separator} />
          )}

          <div className={styles.vehicleTypeLabel}>
            <RadioGroup
              name={key}
              radioFieldsCount={includedRideRequestField[key]}
              onChange={handleRadio}
              value={values?.rideRequestFields[key]}
              disabled={disableRideRequestFields}
             
            />
          </div>
        </>
      )}
    </div>
      
  ));
};

RideRequestFields.propTypes = {};

export default RideRequestFields;
