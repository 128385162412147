import { Formik } from "formik";
import styles from "./index.module.css";
import classnames from "classnames";
import { LodashHelper } from "../../../../../utils";
import { RECEIPT_STATUS } from "../../../../../constants/constants";

const rideReceiptComponent = ({ rideReceipt, tcReceipt, seqId,actMileage,isMHAdmin,isHOAdmin,isTCAdmin }) => {
  // Calculate ho receipt total amount
  const hoReceiptTotalAmount = rideReceipt?.receiptItems?.reduce(
    (accumulator, currentItem) => accumulator + currentItem?.amount,
    0
  );

  // Calculate tc receipt total amount
  const tcReceiptTotalAmount = tcReceipt?.receiptItems?.reduce(
    (totAmt, item) => totAmt + item?.amount,
    0
  );

  return (
    <div className={styles.receiptsContainer}>
      {(isMHAdmin || isHOAdmin) &&   rideReceipt && rideReceipt?.status !== RECEIPT_STATUS.ARCHIVED  && (
        <div className={styles.rideReceiptContainer}>
          <div key={`invTitleContr`} className={styles.rideReceiptHeader}>
            <div
              key={`invTitleCol1`}
              className={classnames({
                [styles.flexWithColumnDir]: true,
                [styles.widthPercent65]: true,
              })}
            >
              <h2>Customer Receipt</h2>
            </div>
            <div
              key={`invTitleCol2`}
              className={classnames({
                [styles.flexWithColumnDir]: true,
                [styles.headerCol2]: true,
              })}
            >
              <div style={{ marginTop: "8px" }}>
                <p>{`${rideReceipt?.receiptType}`}</p>
              </div>
            </div>
          </div>
          <div
            key={"rcptHdr"}
            className={classnames({
              [styles.rideReceiptHeader]: true,
              [styles.mb28]: true,
            })}
          >
            <div
              key={"rcptHdrCol1"}
              className={classnames({
                [styles.rideReceiptHdrCol]: true,
                [styles.flexWithColumnDir]: true,
              })}
            >
              <p className={styles.rideReceiptLabel}>Receipt #</p>
              <p>{rideReceipt?.receiptId}</p>
              {<p className={styles.rideReceiptLabel}>Type</p>}
              {<p>{LodashHelper.startCase(rideReceipt?.vehicleType)}</p>}
            </div>
            <div
              key={"rcptHdrCol2"}
              className={classnames({
                [styles.rideReceiptHdrCol]: true,
                [styles.flexWithColumnDir]: true,
              })}
              style={{ alignItems: "self-end" }}
            >
              <div>
                <p className={styles.rideReceiptLabel}>Ride ID </p>
                <p>{seqId}</p>
                {/* {actMileage &&
                  <div>
                    <p className={styles.rideReceiptLabel}>Mileage Type</p>  
                   <p>Estimated</p> 
                   </div> 
                   } */}
              </div>
            </div>
          </div>

          <div key={"rcptDesc"} className={styles.mb28}>
            <p key={"inv-1"} className={styles.rideReceiptDescTitle}>
              Description <span>Amount</span>
            </p>
            {rideReceipt?.receiptItems?.map((inv, index) => (
              <div
                key={`invContr${index}`}
                className={styles.rideReceiptHeader}
              >
                <div
                  key={`invColLft${index}`}
                  className={classnames({
                    [styles.flexWithColumnDir]: true,
                    [styles.widthPercent70]: true,
                  })}
                >
                  <p>{inv?.description}</p>
                </div>
                <div
                  key={`invColRgt${index}`}
                  className={classnames({
                    [styles.flexWithColumnDir]: true,
                    [styles.descriptionCol2]: true,
                  })}
                >
                  <div>
                    <p>{"$" + inv?.amount?.toFixed(2)}</p>
                  </div>
                </div>
              </div>
            ))}
            <hr className={styles.rideReceiptDivider} />
          </div>
          <div key={"rcptBillingCalc"} className={styles.mb28}>
            <p className={styles.billTotal} key={`invAmtDue`}>
              {"Amount Due"}{" "}
              <span
                className={styles.billTotalValue}
              >{`$${hoReceiptTotalAmount?.toFixed(2)}`}</span>
            </p>
            {/* <p className={styles.billSubTotal} key={`invSubTotal`}>  <span >{rideReceipt?.status === 'PAID'? 'PAID': 'UNPAID'}</span></p> */}
          </div>
          <Formik>
          
          </Formik>
        </div>
      )}
      {(isMHAdmin || isTCAdmin) && tcReceipt && tcReceipt?.status !== RECEIPT_STATUS.ARCHIVED   && (
        <div className={styles.rideReceiptContainer}>
          <div key={`invTcTitleContr`} className={styles.rideReceiptHeader}>
            <div
              key={`invTcTitleCol1`}
              className={classnames({
                [styles.flexWithColumnDir]: true,
                [styles.widthPercent65]: true,
              })}
            >
              <h2>NEMT Receipt</h2>
            </div>
            <div
              key={`invTcTitleCol2`}
              className={classnames({
                [styles.flexWithColumnDir]: true,
                [styles.headerCol2]: true,
              })}
            >
              <div style={{ marginTop: "8px" }}>
                {/* <p>{`Type: Transaction`}</p> */}
              </div>
            </div>
          </div>

          <div
            key={"rcptTcHdr"}
            className={classnames({
              [styles.rideReceiptHeader]: true,
              [styles.mb28]: true,
            })}
          >
            <div
              key={"rcptTcHdrCol1"}
              className={classnames({
                [styles.rideReceiptHdrCol]: true,
                [styles.flexWithColumnDir]: true,
              })}
            >
              <p className={styles.rideReceiptLabel}>Receipt #</p>
              <p>{tcReceipt?.tcReceiptId}</p>
              {<p className={styles.rideReceiptLabel}>Type</p>}
              {<p>{LodashHelper.startCase(tcReceipt?.vehicleType)}</p>}
            </div>
            <div
              key={"rcptTcHdrCol2"}
              className={classnames({
                [styles.rideReceiptHdrCol]: true,
                [styles.flexWithColumnDir]: true,
              })}
              style={{ alignItems: "self-end" }}
            >
              <div>
                <p className={styles.rideReceiptLabel}>Ride ID </p>
                <p>{seqId}</p>
              </div>
            </div>
          </div>

          <div key={"rcptTcDesc"} className={styles.mb28}>
            <p key={"inv-detail"} className={styles.rideReceiptDescTitle}>
              Description <span>Amount</span>
            </p>
            {tcReceipt?.receiptItems?.map((inv, index) => (
              <div
                key={`invContrTc${index}`}
                className={styles.rideReceiptHeader}
              >
                <div
                  key={`invColLftTc${index}`}
                  className={classnames({
                    [styles.flexWithColumnDir]: true,
                    [styles.widthPercent70]: true,
                  })}
                >
                  <p>{LodashHelper.upperFirst(inv?.description)}</p>
                </div>
                <div
                  key={`invColRgtTc${index}`}
                  className={classnames({
                    [styles.flexWithColumnDir]: true,
                    [styles.descriptionCol2]: true,
                  })}
                >
                  <div>
                    <p>{"$" + inv?.amount.toFixed(2)}</p>
                  </div>
                </div>
              </div>
            ))}
            <hr className={styles.rideReceiptDivider} />
          </div>

          <div key={"rcptTcBillingCalc"} className={styles.mb28}>
            <p className={styles.billTotal} key={`invTcAmtDue`}>
              {"Amount Due"}{" "}
              <span
                className={styles.billTotalValue}
              >{`$${tcReceiptTotalAmount?.toFixed(2)}`}</span>
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default rideReceiptComponent;
