 
import React, { useCallback, useMemo, useState } from 'react';
import { Button, Modal } from '../..';
 

import styles from './index.module.css';

 
export const AlertModal = ({header,message,onclose}) => {
  const [visibleModal, setModalVisible] = useState(false);
  const onCancel = useCallback(async () => {
      setModalVisible(false);
      onclose && onclose();
  }, [setModalVisible ]);
 
  return {
    Modal: (
      <Modal
        visible={visibleModal}
        onCancel={onCancel}
        closable 
        width={600} 
        title={header}>
         
        <div className={styles.modalContent}>
          <p className={styles.modalDescription}>
      {message}    
          
          </p>
          <div className={styles.modalBtns}>
                <div>
                  <Button myClassName={styles.navigationBtn} onClick={onCancel}>
                    Back to Editing
                  </Button>
                </div>
                
              </div>
        
        </div>
      </Modal>
    ),
    setAlertModalVisible: setModalVisible,
    alertModalVisible: visibleModal,
  };
};
