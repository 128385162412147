import React, { useState, useCallback, memo } from "react";
import PropTypes from "prop-types";
import { DistanceMatrixService } from "@react-google-maps/api";
import { GOOGLE_MAPS_TRAVEL_MODE } from "../../../constants/constants";

 const GoogleDistanceMatrix = memo(({
  origin,
  destination,
  handleChildValueChange,
}) => {
  const [travelMode] = useState(GOOGLE_MAPS_TRAVEL_MODE);

  const distanceCallback = useCallback((response) => {
    if (response !== null && response !== undefined) {
      const result = response?.rows[0]?.elements[0];
      if (result?.status === "OK") {
        const { duration, distance } = result;
        handleChildValueChange("estDuration", duration.value);
        handleChildValueChange("estMileage", distance.value);
      } else {
        console.log("Error:", result.status);
      }
    } else {
      console.log("No response received");
    }
  }, []);
 
  return (
    <DistanceMatrixService
      options={{
        destinations: [destination],
        origins: [origin],
        travelMode: travelMode,
      }}
      callback={distanceCallback}
    />
  );
});

GoogleDistanceMatrix.propTypes = {
  origin: PropTypes.string.isRequired,
  destination: PropTypes.string.isRequired,
};

export default GoogleDistanceMatrix;
