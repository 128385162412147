import classNames from "classnames";
import { DatePicker } from "../..";
import { OPERATIONAL_WORKING_HOUR_TYPE } from "../../../constants/constants";
import { CustomLabels } from "./FleetSize";
import styles from "./index.module.css";
import {  useState,useEffect, memo } from "react";
import PropTypes from 'prop-types';

const OperationHours = ({
  id,
  fieldName,
  isFieldsDisabled,
  values,
  title = "",
}) => {
  const [openDate, setOpenDate] = useState(null);
  const [closeDate, setCloseDate] = useState(null);

  useEffect(() => {
 
    values?.open !== null ? setOpenDate(new Date(new Date().setHours(values?.open))): setOpenDate(null);
    return;
  }, [values?.open]);

  useEffect(() => {
    values?.close !== null ? setCloseDate(new Date(new Date().setHours(values?.close))): setCloseDate(null);
    return;
  }, [values?.close]);

  const handleChangeTimePicker = async ({
    setFieldValue,
    name,
    value,
    validateForm,
  }) => {
    
    await setFieldValue(name, new Date(value).getHours());
    
    await validateForm();
  };
  
  return (
    <div className={styles.vehicles} key={`vc_hour${id}`}>
      <label className={styles.numberField} key={`l_hour${id}`}>
        <DatePicker
          name={`${fieldName}.open`}
          format={"hh aaa"}
          myClassName={styles.customTimepicker}
          onChange={handleChangeTimePicker}
          picker={"time"}
          value={openDate}
          use12Hours 
          showNow={false}
           //disabledTime={disabledTime}
          inputReadOnly
          placeholder="Pick time"
          disabled={isFieldsDisabled}
        />
      </label>
      <label className={styles.numberField} key={`ll_hour${id}`}>
        <DatePicker
          name={`${fieldName}.close`}
          format={"hh aaa"}
          myClassName={styles.customTimepicker}
          onChange={handleChangeTimePicker}
          picker={"time"}
          value={closeDate}
          use12Hours
          showNow={false}
          //disabledTime={disabledTime}
          inputReadOnly
          placeholder="Pick time"
          disabled={isFieldsDisabled}
        />
      </label>
      <label className={styles.nameLabel} key={`lll_hour${id}`}>
        <span>{title}</span>
      </label>
    </div>
  );
};

const TCOperationHours = memo(({
  values,
  fieldName,
  title,
  isFieldsDisabled,
  type,
}) => {
 
  return (
    <div
      className={classNames({
        [styles.checkboxesColumn]: true,
        [styles.resetMarginTop]: type === OPERATIONAL_WORKING_HOUR_TYPE.HOLIDAY,
      })}
      key={`tcOprHrs${type}`}
    >
      <label
        className={styles.rideRequestFieldsHead}
        key={`tcOprHrsLbl${type}`}
      >
        {title}
      </label>
      <div className={styles.checkboxesRow} key={`tcOprHrsCt${type}`}>
        {CustomLabels(["Open", "Closed"])}
        {type === OPERATIONAL_WORKING_HOUR_TYPE.WEEKLY ?
          <>
            {values?.map((item) => (
              <>
                {OperationHours({
                  id: item?.id,
                  fieldName: `${fieldName}.${item?.id}`,
                  values: item, isFieldsDisabled,
                  title: item?.title,
                })}
              </>
            ))} </> : <>
            {OperationHours({
              id: values?.id,
              fieldName, values,
              isFieldsDisabled,
            })}
          </>}
      </div>
    </div>
  );
});
TCOperationHours.propTypes = {
  
  values:PropTypes.array,
  fieldName: PropTypes.string,
  title: PropTypes.string,
  isFieldDisabled: PropTypes.bool,
  type: PropTypes.string
};
export default TCOperationHours;
