import React from 'react';
import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import classnames from 'classnames';
import { getIn } from 'formik';

import { SearchDropdownOverlay } from '../../FilterComponents/SearchDropdownOverlay/SearchDropdownOverlay';

import styles from './index.module.css';

const renderDropDownComponent =
  ({ myClassName, value, title, renderDropdownIcon }) =>
  ({ visible }) =>
    (
      <div
        className={classnames({
          [styles.headRow]: true,
          [myClassName]: myClassName,
          [styles.headRowActive]: visible,
          [styles.headRowDisabled]: !visible,
        })}>
        <>
          <span className={styles.cellTitle}>{value && !visible ? value : title}</span>
          {renderDropdownIcon(visible)}
        </>
      </div>
    );

export const FilterSearchCell = ({
  name,
  placeholder,
  title,
  values,
  setFieldValue,
  onClose,
  myClassName,
  ...rest
}) => {
  const handleClose = (e) => {
    e.stopPropagation();
    setFieldValue(name, '');

    if (onClose) onClose();
  };

  const renderDropdownIcon = (visible) =>
    getIn(values, name) && !visible ? (
      <CloseOutlined
        className={classnames({
          [styles.icon]: true,
          [styles.disabled]: !visible,
        })}
        onClick={handleClose}
      />
    ) : (
      <SearchOutlined
        className={classnames({
          [styles.icon]: true,
          [styles.disabled]: !visible,
        })}
      />
    );
  return (
    <SearchDropdownOverlay
      placeholder={placeholder}
      Component={renderDropDownComponent({
        myClassName,
        value: getIn(values, name),
        title,
        renderDropdownIcon,
      })}
      name={name}
      values={values}
      setFieldValue={setFieldValue}
      {...rest}
    />
  );
};
