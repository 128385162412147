import React from 'react';

import { Formik } from 'formik';
import classnames from 'classnames';

import { Input, Button, MaskInput } from '../../../';
import { PersonalInformationStepSchema } from '../../../../utils';
import { DEFAULT_PHONE_NUMBER_FORMAT } from '../../../../constants/constants';

import styles from './index.module.css';

export const PersonalInformationStep = ({ handleNext, values }) => {
  return (
    <Formik
      enableReinitialize
      validateOnMount
      initialValues={values}
      validationSchema={PersonalInformationStepSchema}
      onSubmit={(values) => {
        handleNext(values);
      }}>
      {({ values, handleSubmit, ...rest }) => {
        return (
          <form onSubmit={handleSubmit} className={styles.resetForm}>
            <label className={styles.inputControl}>
              <span className={styles.required}>Mobile Phone Number</span>
              <MaskInput
                name='phoneNumber'
                value={values.phoneNumber}
                format={DEFAULT_PHONE_NUMBER_FORMAT}
                placeholder={'###-###-####'}
                required
              />
            </label>
            <label
              className={classnames({
                [styles.inputControl]: styles.loginInputControl,
              })}>
              <span className={styles.required}>Job Title</span>
              <Input type='text' name='jobTitle' myClassName={styles.input} placeholder='Job title' required />
            </label>

            <div className={styles.button}>
              <Button disabled={!rest.isValid} onClick={handleSubmit}>
                Next
              </Button>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};
