import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import { BillSegment } from "./BillSegment";
import {
  DEFAULT_BILLING_TRANSACTION,
  DEFAULT_BILLING_SUBSCRIPTION,
  DEFAULT_BILLING_SJCRH,
  BILLING_TABS_OBJECT,
} from "../../../constants/constants";
import {
  billingSubscriptionSchema,
  billingTransactionSchema,
  billing_sjcrhSchema,
} from "../../../utils/validations";
import { CheckCircleOutlined } from "@ant-design/icons";

export const BillingAndInvoicing = ({
  tab,
  isViewDashboardMode,
  onChange,
  HOPricing,
  onSubmitPricing,
}) => {
  const [selectedTab, setSelectedTab] = useState(tab);
  useEffect(()=>{

    setSelectedTab(tab);

    
  },[tab]);

  const shouldHideTabs = tab  == BILLING_TABS_OBJECT.SJCRH;
  let items;
  shouldHideTabs
    ? (items = [
        {
          key: BILLING_TABS_OBJECT.SJCRH,
          label: "Transaction",
          children: (
            <>
              <BillSegment
                tab={tab}
                Default_Value={HOPricing?.pricingType === BILLING_TABS_OBJECT.SJCRH ? HOPricing : DEFAULT_BILLING_SJCRH}
                billingSchema={billing_sjcrhSchema}
                onSubmitPricing={onSubmitPricing}
              />
            </>
          ),
        },
      ])
    : (items = [
        {
          key: BILLING_TABS_OBJECT.Transaction ,
          label: (
            <span onClick={() => setSelectedTab(BILLING_TABS_OBJECT.Transaction)}>
              {selectedTab == BILLING_TABS_OBJECT.Transaction ? <CheckCircleOutlined /> : null}
              Transaction
            </span>
          ),
          children: (
            <>
              <BillSegment
                tab={tab}
                Default_Value={
                  HOPricing?.pricingType == BILLING_TABS_OBJECT.Transaction
                    ? HOPricing
                    : DEFAULT_BILLING_TRANSACTION
                }
                onSubmitPricing={onSubmitPricing}
                billingSchema={billingTransactionSchema}
              />
            </>
          ),
        },
        {
          key: BILLING_TABS_OBJECT.Subscription ,
          label: (
            <span onClick={() => setSelectedTab(BILLING_TABS_OBJECT.Subscription)}>
              {selectedTab == BILLING_TABS_OBJECT.Subscription ? <CheckCircleOutlined /> : null}
              Subscription
            </span>
          ),
          children: (
            <>
              <BillSegment
                tab={tab}
                billingSchema={billingSubscriptionSchema}
                Default_Value={
                  HOPricing?.pricingType === BILLING_TABS_OBJECT.Subscription
                    ? HOPricing
                    : DEFAULT_BILLING_SUBSCRIPTION
                }
                onSubmitPricing={onSubmitPricing}
              />
            </>
          ),
        },
      ]);
  return (
    <>
      {isViewDashboardMode ? (
        <div style={{ marginBottom: "20px" }}>
          <h2> Billing & Invoicing </h2>
          <Tabs
            activeKey={selectedTab}
            items={items}
            onChange={onChange}
          />
        </div>
      ) : null}
    </>
  );
};
