import React from 'react';
import { Modal as AntdModal } from 'antd';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './index.module.css';

export const Modal = ({ visible, title, onCancel, width, children, myClassName, zIndex, customMaskStyle, bodyStyle }) => {
  return (
    <AntdModal
      zIndex={zIndex}
      closable
      title={title}
      centered
      className={classNames({
        [styles.modal]: visible,
        [myClassName]: myClassName,
      })}
      open={visible}
     
      footer={null}
      onCancel={onCancel}
      maskStyle={{
         backgroundColor: 'rgba(196, 196, 196, 0.3)',
        ...(customMaskStyle && customMaskStyle),
      }}
      bodyStyle={{...(bodyStyle && bodyStyle)}}
      confirmLoading
      forceRender 
     destroyOnClose  
      width={width}>
      {children}
    </AntdModal>
  );
};

Modal.propTypes = {
  visible: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.node, PropTypes.string]),
  onCancel: PropTypes.func,
  width: PropTypes.any,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  myClassName: PropTypes.string,
  bodyStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.node, PropTypes.string]),
};
