import React from 'react';

import { transformDraftToRide } from '../../../utils';
import { StepOne, StepTwo, StepThree, StepFour, StepFive } from '../../../components';

export const renderSteps = ({
  handleSave,
  rideRequestFields,
  platformPreferences,
  rideOptions,
  defaultTimeZone,
  showCovidField,
  syncFormikValues,
  ...rest
}) => {
  switch (rest.step) {
    case 0:
      return <StepOne {...rest} showCovidField={showCovidField} setFormikValues={syncFormikValues} />;
    case 1:
      return (
        <StepTwo
          {...rest}
          rideOptions={rideOptions}
          platformPreferences={platformPreferences}
          setFormikValues={syncFormikValues}
        />
      );
    case 2:
      return (
        <StepThree
          {...rest}
          platformPreferences={platformPreferences}
          defaultTimeZone={defaultTimeZone}
          setFormikValues={syncFormikValues}
        />
      );
    case 3:
      return <StepFour {...rest} setFormikValues={syncFormikValues} />;

    case 4:
      return <StepFive handleSave={handleSave} values={transformDraftToRide(rest.draft)} {...rest} />;

    default:
      return null;
  }
};
