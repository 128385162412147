import React from 'react';
import { FieldArray } from 'formik';

import { Input, Checkbox, MaskInput, renderAdditionalRideOptions } from '../../';
import { DEFAULT_PHONE_NUMBER_FORMAT } from '../../../constants/constants';

import styles from './index.module.css';

export const renderCheckboxGroup = (renderedValues, values, name) => (arrayHelpers) => {
  const handleCheckbox =
    (option) =>
    ({ checked }) => {
      if (checked) arrayHelpers.push(option);
      else {
        const idx = values[name].indexOf(option);
        if (idx !== -1) arrayHelpers.remove(idx);
      }
    };
  return renderedValues.map((option) => (
    <label className={styles.checkbox} key={option.title}>
      <Checkbox
        name={name}
        checked={values && values[name] && values[name].includes(option.value)}
        value={option.value}
        onChange={handleCheckbox(option.value)}
        disabled={option.disabled}
      />
      <span className={styles.checkboxLabel}>{option.title}</span>
    </label>
  ));
};

export const CreateDriver = ({
  values,
  rideOptions = [],
  vehicleTypes = [],
  showPersonalInfo = true,
  showAccountCheckbox = true,
}) => {
  const handleHasAccountCheckbox = ({ checked, setValues, values }) => {
    const copiedValues = { ...values };
    if (checked) {
      setValues({
        ...copiedValues,
        hasAccount: true,
      });
    } else {
      setValues({
        ...copiedValues,
        hasAccount: false,
        canReceiveSms: false,
      });
    }
  };

  return (
    <>
      <div className={styles.name}>
        <label className={styles.nameLabel}>
          <span className={styles.required}>First Name</span>
          <Input name='firstName' type='text' placeholder='First Name' />
        </label>
        <label className={styles.nameLabel}>
          <span className={styles.required}>Last Name</span>
          <Input name='lastName' type='text' placeholder='Last Name' />
        </label>
      </div>
      <div className={styles.personalInfo}>
        <label className={styles.email}>
          <span className={styles.required}>Email</span>
          <Input name='email' type='text' placeholder='Email' />
        </label>

        {showPersonalInfo && (
          <label className={styles.personalInfoLabel}>
            <span className={styles.required}>Mobile Phone Number</span>
            <MaskInput
              name='phoneNumber'
              format={DEFAULT_PHONE_NUMBER_FORMAT}
              myClassName={styles.customPhone}
              placeholder={'###-###-####'}
              value={values?.phoneNumber}
            />
          </label>
        )}
        {showPersonalInfo && (
          <label className={styles.personalInfoLabel}>
            <span className={styles.required}>Job Title</span>
            <Input type='text' name='jobTitle' myClassName={styles.input} placeholder='Job title' />
          </label>
        )}
      </div>
      {showAccountCheckbox && (
        <label className={styles.checkbox}>
          <Checkbox
            name='hasAccount'
            checked={values?.hasAccount}
            value={values?.hasAccount}
            onChange={handleHasAccountCheckbox}
          />
          <span className={styles.checkboxLabel}>
            This driver will be managing their rides through the MedHaul portal
          </span>
        </label>
      )}
      {values?.hasAccount && (
        <label className={styles.checkbox}>
          <Checkbox name='canReceiveSms' checked={values?.canReceiveSms} />
          <span className={styles.checkboxLabel}>Send SMS text to driver upon receipt of ride</span>
        </label>
      )}
      {vehicleTypes?.length > 0 && (
        <div className={styles.checkboxesColumn}>
          <label className={styles.rideRequestFieldsHead}>
            <span className={styles.required}>Vehicle Type(s)</span>
          </label>
          <div className={styles.checkboxesList}>
            <FieldArray name='vehicleTypes' render={renderCheckboxGroup(vehicleTypes, values, 'vehicleTypes')} />
          </div>
        </div>
      )}
      {rideOptions?.length > 0 && (
        <div className={styles.additionalOptions}>
          <span className={styles.additionalOptionsHead}>Additional driver/vehicle capabilities</span>
          <div className={styles.checkboxesRow}>
            <FieldArray
              name='rideOptions'
              render={renderAdditionalRideOptions({
                renderedValues: rideOptions,
                values,
                name: 'rideOptions',
              })}
            />
          </div>
        </div>
      )}
    </>
  );
};
