
import React, { useCallback, useState } from 'react';
import styles from './index.module.css';
import { Button, Modal } from '../..';
import { RideDestinationInfo } from "../../common/RideDetails/RideDetailsPartials/RideDestinationInfo";
import { RidePickUpInfo } from "../../common/RideDetails/RideDetailsPartials/RidePickUpInfo";
import classNames from 'classnames';
import { CONFIRM_ROUNDTRIP_RIDE_CONTENT, CONFIRM_ROUNDTRIP_RIDE_TITLE } from '../../../constants/constants';

export const useConfirmRoundTripRideModal = ({ ride, onConfirm }) => {
    const [visibleModal, setVisibleModal] = useState(false);
    const onCancel = useCallback(async () => {
        await setVisibleModal(false);
    }, [setVisibleModal])


    return {
        Modal: (

            <Modal
                visible={visibleModal}
                onCancel={onCancel}
                closable
                width={800}
                bodyStyle={{ height: '40%', padding: '20px 20px 0' }}
                title={CONFIRM_ROUNDTRIP_RIDE_TITLE}>
                <div className={styles.modalContent}>
                    <h3 className={styles.roundTripHeading}>
                        {CONFIRM_ROUNDTRIP_RIDE_CONTENT}
                    </h3>
                    <div className={styles.routes}>
                        <RidePickUpInfo
                            pickupAddressDetails={ride?.pickupAddressDetails}
                            destinationAddress={ride?.destinationAddress}
                            pickUpDate={ride?.pickUpDate}
                            riderDescription={ride?.riderDescription}
                            pickUpDateLowerLimit={ride?.pickUpDateLowerLimit}
                            isPickUpDateFlexible={ride?.isPickUpDateFlexible}
                            tcEstimatedPickUpDate={ride?.tcEstimatedPickUpDate}
                            securityGate={ride?.pickUpSecurityGateDetails}
                            flightInfo={ride?.flightInfo}
                            appointmentDate={ride?.appointmentDate}
                            isRecurringRide={ride?.isRecurring}
                            leg={ride?.leg}
                            seqId={ride?.seqId}
                        />

                        <div className={styles.destionationRouteInfo}>
                            <RideDestinationInfo
                                destinationAddress={ride?.destinationAddress}
                                originAddress={ride?.pickupAddressDetails}
                                showRoundtripInfo={
                                    ride?.isRoundtrip  && ride?.leg
                                }
                                securityGate={ride?.destinationSecurityGateDetail}
                                returnTime={ride?.connectedLeg?.pickUpDate}
                                returnTimeLowerLimit={ride?.returnTimeLowerLimit}
                                isReturnDateFlexible={ride?.isReturnDateFlexible}
                                leg={ride?.connectedLeg?.leg}
                                seqId={ride?.connectedLeg?.seqId}
                            />
                        </div>

                       
                    </div>

                    <div className={classNames({
                        [styles.modalBtns]: true,
                        [styles.cancelBtns]: true,
                    })}>
                  <Button myClassName={styles.navigationBtn} onClick={onCancel}>
                    Cancel
                  </Button>
                  <Button myClassName={styles.navigationBtn} onClick={onConfirm} >
                    Accept Roundtrip Ride
                  </Button>
                
              
                
              </div>
                </div>
            </Modal>



        ),
        setConfirmRoundTripRideModalVisible: setVisibleModal,
        ConfirmRoundTripRideModalVisible: visibleModal

    };
};