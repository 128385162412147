import axios from "axios";
import { baseRequest } from "../utils";
import { getPage, redirectToPage } from "../utils/helpers";
import download from 'downloadjs';
import { BILL_STATUS } from "../constants/constants";
const types = {

    FETCH_TCINVOICES_REQUEST: 'FETCH_TCINVOICES_REQUEST',
    FETCH_TCINVOICES_SUCCESS: 'FETCH_TCINVOICES_SUCCESS',
    FETCH_TCINVOICES_ERROR: 'FETCH_TCINVOICES_ERROR',

    SET_CURRENT_PAGE_TCINVOICES: 'SET_CURRENT_PAGE_TCINVOICES',

    DOWNLOAD_INVOICE_PDF_SUCCESS: 'DOWNLOAD_INVOICE_PDF_SUCCESS',
    DOWNLOAD_INVOICE_PDF_ERROR: 'DOWNLOAD_INVOICE_PDF_ERROR',
    DOWNLOAD_TCINVOICE_REQUEST: 'DOWNLOAD_TCINVOICE_REQUEST',

    DOWNLOAD_INVOICE_RECEIPT_SUCCESS: 'DOWNLOAD_INVOICE_RECEIPT_SUCCESS',
    DOWNLOAD_INVOICE_RECEIPT_ERROR: 'DOWNLOAD_INVOICE_RECEIPT_ERROR',
    DOWNLOAD_TCINVOICE_RECEIPTS_REQUEST: 'DOWNLOAD_TCINVOICE_RECEIPTS_REQUEST',



}


const fetchTCInvoicesRequest = () => ({
    type: types.FETCH_TCINVOICES_REQUEST,
});

export const setCurrentPage = (page) => ({
    type: types.SET_CURRENT_PAGE_TCINVOICES,
    payload: page,
});

const downloadInvoicePDFRequest = () => ({
    type: types.DOWNLOAD_TCINVOICE_REQUEST,
});

const downloadInvoiceReceiptsRequest = () => ({
    type: types.DOWNLOAD_TCINVOICE_RECEIPTS_REQUEST,
});


export const fetchTCInvoices = ({ value, filtered, Navigation }) => {

    const { location } = Navigation;

    const success = (response) => {
        return {
            type: types.FETCH_TCINVOICES_SUCCESS,
            payload: response,
        };
    };

    const failure = (error) => ({
        type: types.FETCH_TCINVOICES_ERROR,
        payload: error,
    });

    return (dispatch, getStore) => {
        if (filtered) redirectToPage(location.pathname, 1, Navigation);
        const { limit } = getStore().tcBilling;
        const currentPage = getPage(location);
        dispatch(setCurrentPage(currentPage));
        dispatch(fetchTCInvoicesRequest());
        return baseRequest(
            'GET',
            `/transport-invoice`,
            null,
            {},
            {
                docsPerPage: limit,
                page: currentPage,
                value: value || '',
            },
        )
            .then((response) => dispatch(success(response.data)))
            .catch((error) => {
                if (error) dispatch(failure([]));
            });
    };
};


export const downloadInvoicePDF = (payload) => {
    const success = (payload) => ({
        type: types.DOWNLOAD_INVOICE_PDF_SUCCESS,
        payload,
    });

    const failure = (error) => ({
        type: types.DOWNLOAD_INVOICE_PDF_ERROR,
        payload: error,
    });

    return (dispatch) => {

        dispatch(downloadInvoicePDFRequest());
        return baseRequest('GET', `/auth/signed`, null, null, {
            fPath: payload
        }).then(async (response) => {
            await axios({ url: response.data, method: 'GET', responseType: 'blob' }).then(response => {
                dispatch(success(response));
                download(new Blob([response.data]), `invoice.pdf`, response.headers['content-type']);
            })
                .catch(error => {
                    dispatch(failure(''));
                });

        })
            .catch((error) => {
                if (error) {
                    dispatch(failure(''));
                }
            });
    };
};

export const downloadInvoiceReceipts = (payload) => {
   

    const success = (payload) => ({
        type: types.DOWNLOAD_INVOICE_RECEIPT_SUCCESS,
        payload,
    });

    const failure = (error) => ({
        type: types.DOWNLOAD_INVOICE_RECEIPT_ERROR,
        payload: error,
    });

    return (dispatch) => {
     
        dispatch(downloadInvoiceReceiptsRequest());
        return baseRequest('POST', `/ride-requests/report`,  {fileType: "xlsx", filters: {tcInvoiceId: payload, billed: BILL_STATUS.value}} , [], [], 'blob')
            .then((response) => {
                const extension = response.headers['content-type'] === 'text/csv' ? 'csv' : 'xlsx';
                download(response.data, `export.${extension}`, response.headers['content-type']);

                dispatch(success(response));
            })
            .catch((error) => {
                if (error) {
                    dispatch(failure(''));
                }
            });
    };


};

const initialState = {

    tcInvoices: [],
    loading: false,
    error: false,
    success: false,
    limit: 10,
    currentPage: 1,
    totalPages: 1,
    total: 1,

};

export default function tcBilling(state = initialState, action) {
    switch (action.type) {
        case types.FETCH_TCINVOICES_SUCCESS:
            return {
                ...state,
                tcInvoices: action.payload.data,
                totalPages: action.payload.meta.totalPages,
                total: action.payload.meta.total,
                limit: action.payload.meta.limit,
                currentPage: action.payload.meta.currentPage,
                loading: false,
            };
        case types.FETCH_TCINVOICES_ERROR:
            return {
                ...state,
                loading: false,
            };

        case types.FETCH_TCINVOICES_REQUEST:
            return {
                ...state,
                loading: true
            };
        case types.DOWNLOAD_TCINVOICE_REQUEST:
            return {
                ...state,
                loading: false,
            };
        case types.DOWNLOAD_INVOICE_PDF_SUCCESS:
            return {
                ...state,
                loading: false,
            };

        case types.DOWNLOAD_INVOICE_PDF_ERROR:
            return {
                ...state,
                loading: false,
            };
        case types.DOWNLOAD_TCINVOICE_RECEIPTS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.DOWNLOAD_INVOICE_RECEIPT_SUCCESS:
            return {
                ...state,
                loading: false,
            };

        case types.DOWNLOAD_INVOICE_RECEIPT_ERROR:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
}