import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { TimeLapsIcon } from '../../../assets';
import { useInterval } from '../../../hooks/useInterval';

import styles from './index.module.css';

const FULL_STROKE_OFFSET = 280;

export const CountdownCircleTimer = ({ handleLogout, MODAL_AUTO_CLOSE_AFTER_SEC, workerTimers }) => {
  const [strokeDashOffset, setStrokeDashOffset] = useState(0);
  const [currentCount, setCount] = useState(MODAL_AUTO_CLOSE_AFTER_SEC);

  useInterval(
    () => {
      if (currentCount > 0) setCount(currentCount - 1);
      else handleLogout();
    },
    1000,
    workerTimers,
  );

  useEffect(
    () => setStrokeDashOffset((prev) => prev + FULL_STROKE_OFFSET / (MODAL_AUTO_CLOSE_AFTER_SEC + 1)),
    [currentCount, MODAL_AUTO_CLOSE_AFTER_SEC],
  );

  return (
    <div className={styles.container}>
      <TimeLapsIcon strokeDashOffset={strokeDashOffset} FULL_STROKE_OFFSET={FULL_STROKE_OFFSET} />
      <div className={styles.content}>
        <span>{currentCount}</span>
      </div>
    </div>
  );
};

CountdownCircleTimer.propTypes = {
  handleLogout: PropTypes.func.isRequired,
  MODAL_AUTO_CLOSE_AFTER_SEC: PropTypes.number.isRequired,
};
