import React, { useCallback, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useParams } from 'react-router';
import { getRide, clearCurrentRide, setShowRefreshNotification, getRideHistory, createChangeRequestForTC } from '../../../store/rides';
import { RideDetails as RideInfo } from '../../../components';
import { BackIcon } from '../../../assets';
import { destroyNotification } from '../../../components/common/ConfirmPopUp/ConfirmPopUp';
import { RIDE_STATUSES, USER_ROLES } from '../../../constants/constants';
import MHEditRide from '../../MH_Admin/MHEditRide/MHEditRide';

import styles from './index.module.css';
import { Navigation } from '../../../utils/navigation';
import { isUUID } from '../../../utils/helpers';
import { ChangeRequestButton } from '../../MH_Admin/MHEditRide/partials';
import { ChangeRequestByTCModal } from '../../../components/modals/ChangeRequestByTCModal/ChangeRequestByTCModal';
import { LodashHelper } from '../../../utils';

const RideDetailsPage = ({
  getRide,
  ride,
  clearCurrentRide,
  showFullInfo,
  handleRideStatusModal,
  role,
  setShowRefreshNotification,
  showPrefferedLanguageBeforeAccept,
  getRideHistory,
  airportBufferTime,
  rideHistory,
  createChangeRequestForTC,
  isViewDashboardMode,
  tcConfiguration,
  handleRideRenewal
}) => {
 
  const { id } = useParams();
  const {back} = Navigation();
  const navigate = Navigation();
    useEffect(() => {
     if(isUUID(id))
     {
      getRide(id,navigate);
     }
      return ()=>{};
    }, [getRide, id]);

    useEffect(() => {
      if (role === USER_ROLES.SUPER_ADMIN) {
       
        if(isUUID(id))
        {
        getRideHistory(id);
        }
      }
      return ()=>{};
    }, [getRideHistory, id, role]);

  useEffect(
    () => () => {
      clearCurrentRide();
      setShowRefreshNotification(false);
      destroyNotification();
      return ()=>{};
    },
    [clearCurrentRide, setShowRefreshNotification],
  );
  
  const handleBack = useCallback(() => back(), [back]);
  const showRequestChangeButton =useMemo(
    () => !(RIDE_STATUSES.PENDING === ride?.status ||
    RIDE_STATUSES?.IN_PROGRESS === ride?.status) ,[ride]);
  const editRidePanelIsVisible = useMemo(
    () =>
      role === USER_ROLES.SUPER_ADMIN &&
      (RIDE_STATUSES.CANCELED === ride?.status ||
        RIDE_STATUSES?.COMPLETED === ride?.status ||
        RIDE_STATUSES?.EXPIRED === ride?.status ||
        RIDE_STATUSES.UNFULFILLED === ride?.status ||
        RIDE_STATUSES.DECLINED === ride?.status ||
        RIDE_STATUSES.UNABLE_TO_FULFILL === ride?.status),
    [ride, role],
  );

  const { changeRequestModalVisible, setChangeRequestModalVisible, Modal } =
  ChangeRequestByTCModal({
    onRequestChange: (payload) =>
      createChangeRequestForTC(
     {payload: {changeReqValues:LodashHelper.removeEmpty({
             ...payload,
          }),
          rideId:id},
        onSuccesCallback: () => setChangeRequestModalVisible(false),
  }),
  id:ride?.seqId
  });

 

  return (
    <>
      <div className={styles.pageContent}>
        <div className={styles.header}>
          <div className={styles.backBtn} onClick={handleBack}>
            <BackIcon width='18px' height='18px' fill='#BDBDBD' />
          </div>
          <h1 className={styles.pageTitle}>Ride Details - ID {ride?.seqId}</h1>
        </div>
        <div className={styles.rideDetails}>
          <RideInfo
            values={ { ...ride, rideHistory } }
            showFullInfo={showFullInfo}
            airportBufferTime={airportBufferTime}
            handleRideStatusModal={handleRideStatusModal}
            role={role}
            showPrefferedLanguageBeforeAccept={showPrefferedLanguageBeforeAccept}
            tcConfiguration={tcConfiguration}
            handleRideRenewal={handleRideRenewal}
          />
        </div>
      </div>
      {editRidePanelIsVisible && <MHEditRide ride={ride} />}
      { 
      showRequestChangeButton &&  isViewDashboardMode && role === USER_ROLES.TC_ADMIN &&   <ChangeRequestButton ride={ride} handleSubmit={() => setChangeRequestModalVisible(true)}/>
      }
     
       {changeRequestModalVisible && Modal} 
    </>
   
  );
};

const mapStateToProps = (state) => ({
  ride: state.rides.ride,
  role: state.authorization.role,
  rideHistory: state.rides.rideHistory,
  airportBufferTime : state?.healthOrganizations?.HOPreferences?.airportBufferTime,
  isViewDashboardMode: state?.authorization?.viewDashboardMode?.active,
  tcConfiguration: state?.transportOrganizations?.configuration
});


const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getRide,
      setShowRefreshNotification,
      clearCurrentRide,
      getRideHistory,
      createChangeRequestForTC,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(RideDetailsPage);

