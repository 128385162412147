import React from 'react';
import { Modal, Button } from '../..';
import styles from './index.module.css';

export const ConfirmPricingTypeModal = ({ handleConfirm, handleCancel}) => (
      <Modal visible={true} onCancel={handleCancel} closable width={500} title="Confirmation" >
        <div className={styles.modalContent}>
          <p className={styles.modalDescription}>
           Are you sure you want to change pricing type?
            
          </p>
          <div className={styles.modalBtns}>
            <div>
              <Button myClassName={styles.navigationBtn} onClick={handleConfirm}>
                OK
              </Button>
            </div>
            <div>
              <Button myClassName={styles.navigationBtn} onClick={handleCancel}>
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    );
