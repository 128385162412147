import React from 'react';

import classnames from 'classnames';
import { NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { clearAuthInfo } from '../../../store/authorization'; 
import { Input, Button } from '../..';
import { URLS } from '../../../constants/constants';

import styles from './index.module.css';
import { Navigation } from '../../../utils/navigation';

export const SecurityPreferencesEmail = ({ values, disableAllFields, initialEmail, ...rest }) => {
  return (
    <>
      <div
        className={classnames({
          [styles.credentials]: true,
          [styles.changeEmail]: true,
        })}>
        <label className={styles.email}>
          <span className={styles.required}>Email</span>
          <Input name='newEmail' type='text' placeholder='Email' disabled={disableAllFields} />
        </label>
        {values?.newEmail?.trim() !== initialEmail?.trim() && !rest.errors.newEmail && (
          <label
            className={classnames({
              [styles.inputControl]: styles.loginInputControl,
              [styles.passwordInput]: styles.passwordInput,
            })}>
            <span className={styles.required}>Password (to change email)</span>
            <Input type='password' name='password' myClassName={styles.input} disabled={disableAllFields} required />
          </label>
        )}
      </div>
      {values?.newEmail?.trim() !== initialEmail?.trim() && !rest.errors.newEmail && (
        <div className={styles.credentials}>
          <div>
            <div className={styles.changePasswordBtn}>
              <Button
                type='submit'
                disabled={disableAllFields || (!rest.isValid && values?.newEmail.trim() !== initialEmail?.trim())}>
                Change Email
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export const SecurityPreferencesPassword = ({ values, disableAllFields, ...rest }) => {
  const dispatch = useDispatch();
   const navigate = Navigation();
  const handleForgotPassword = (e) => {
    e.preventDefault();
    dispatch(clearAuthInfo());
    navigate.go(URLS.FORGOT);
  };
  return (
    <>
      <div className={styles.credentials}>
        <div className={styles.passwordWithLink}>
          <label
            className={classnames({
              [styles.inputControl]: styles.loginInputControl,
              [styles.passwordInput]: styles.passwordInput,
            })}>
            <span className={styles.required}>Change Password - Old Password</span>
            <Input type='password' name='oldPassword' myClassName={styles.input} disabled={disableAllFields} />
          </label>

          <div className={styles.changePasswordAndLink}>
            {values.oldPassword && !rest.errors.oldPassword && (
              <div className={styles.changePasswordBtn}>
                <Button type='submit' disabled={disableAllFields || !rest.isValid}>
                  Change Password
                </Button>
              </div>
            )}
            {!disableAllFields && (
              <NavLink to={URLS.FORGOT} className={styles.forgotLink} onClick={handleForgotPassword}>
                Forgot password?
              </NavLink>
            )}
          </div>
        </div>
        <div className={styles.passwordWithHint}>
          <label
            className={classnames({
              [styles.inputControl]: styles.loginInputControl,
              [styles.passwordInput]: styles.passwordInput,
            })}>
            <span className={styles.required}>Change Password - New Password</span>
            <Input type='password' name='newPassword' myClassName={styles.input} disabled={disableAllFields} required />
          </label>
          <span className={styles.passwordHint}>
            We require passwords with a minimum of 8 and up to 50 characters. We recommend using long and strong
            passwords with uppercase letters, lowercase letters, numbers, and special characters.
          </span>
        </div>
      </div>
      <div className={styles.credentials}>
        <div className={styles.credentialsPasswordAndLink}>
          {values.oldPassword && rest.isValid && (
            <div className={styles.changePasswordBtn}>
              <Button type='submit' disabled={disableAllFields || !rest.isValid}>
                Change Password
              </Button>
            </div>
          )}
          {!disableAllFields && (
            <NavLink to={URLS.FORGOT} className={styles.forgotLink} onClick={handleForgotPassword}>
              Forgot password?
            </NavLink>
          )}
        </div>
      </div>
    </>
  );
};
