import React from 'react';
import PropTypes from 'prop-types';

import { rideRequestFieldsDictionary } from '../../../../dictionaries';
import styles from '../index.module.css';

const renderDocumentRideOptions = (options, otherReason) => {
  return (
    options?.length > 0 && (
      <>
        <p className={styles.recordTitle}>Document Reason for the Ride:</p>
        <ul className={styles.additionalOptions}>
          {options.map((option) =>
            option === rideRequestFieldsDictionary.otherReason ? (
              <li key={option}>
                {option}: {otherReason}
              </li>
            ) : (
              <li key={option}>
                {option}
              </li>
            ),
          )}
        </ul>
      </>
    )
  );
};

export const AdditionalInfo = ({ additionalFundingSource, costCenter, documentReasonForRide, otherReason }) => (
  <>
    {costCenter && (
      <p className={styles.costCenter}>
        <span className={styles.recordTitle}>Cost Center:</span>
        <span>{costCenter}</span>
      </p>
    )}
    {additionalFundingSource && (
      <p className={styles.costCenter}>
        <span className={styles.recordTitle}>Funding Source:</span>
        <span>{additionalFundingSource}</span>
      </p>
    )}

    {renderDocumentRideOptions(documentReasonForRide, otherReason)}
  </>
);

AdditionalInfo.propTypes = {
  additionalFundingSource: PropTypes.string,
  costCenter: PropTypes.string,
  documentReasonForRide: PropTypes.array,
  otherReason: PropTypes.string,
};
