import React from 'react';
import { DownOutlined } from '@ant-design/icons';
import classnames from 'classnames';
import { getIn } from 'formik';

import { DateHelper } from '../../../../../utils';
import { RangeDropDown } from '../../FilterComponents/RangeDropdownOverlay/RangeDropdownOverlay';

import styles from './index.module.css';

const renderDropDownComponent =
  ({ myClassName, name, title, values }) =>
  ({ visible }) =>
    (
      <div
        className={classnames({
          [styles.headRow]: true,
          [myClassName]: myClassName,
          [styles.headRowActive]: visible,
          [styles.headRowDisabled]: !visible,
        })}>
        <>
          <span className={styles.cellTitle}>
            {getIn(values, name)?.from && !visible
              ? `${DateHelper.format(getIn(values, name).from, 'MM/dd')} - ${DateHelper.format(
                  getIn(values, name).to,
                  'MM/dd',
                )}`
              : title}
          </span>
          <DownOutlined
            className={classnames({
              [styles.icon]: true,
              [styles.disabled]: !visible,
            })}
          />
        </>
      </div>
    );

export const FilterRangeCell = ({ name, title, values, setFieldValue, myClassName, ...rest }) => (
  <RangeDropDown
    setFieldValue={setFieldValue}
    values={values}
    Component={renderDropDownComponent({
      myClassName,
      title,
      values,
      name,
    })}
    name={name}
    {...rest}
  />
);
