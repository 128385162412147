import classNames from 'classnames';
import React, { useMemo } from 'react';
import { Checkbox, DatePicker, Select } from '../../..';
import { DEFAULT_TIME_FORMAT, TIMEZONES } from '../../../../constants/constants';
import { DateHelper } from '../../../../utils';
import { ThirdStepTooltip } from './ThirdStepTooltip';

import styles from './index.module.css';

export const ReturnDateTimeSection = ({
  pickUpDate,
  returnTimeDate,
  maxPickUpWindowOfTime,
  handleReturnTime,
  disabledDatesForReturnTime,
  disabledDateTime,
  handleUseWindowOfTime,
  handleChangeTZ,
  values,
  disabledValues,
  returnTimeLowerLimit,
  handleReturnTimeLowerLimit,
  disabledDateForReturnHigherLimit,
  disabledDateTimeForReturnHigherLimit,
  minimumRoundTrip,
}) => {
  const minAvailableReturnDate = useMemo(
    () => DateHelper.addDates(pickUpDate, minimumRoundTrip),
    [pickUpDate,minimumRoundTrip],
  );

  const diabledDatesForReturnField = useMemo(
    () =>
      values?.isReturnDateFlexible
        ? disabledDateForReturnHigherLimit(returnTimeLowerLimit)
        : disabledDatesForReturnTime(pickUpDate),
    [
      values?.isReturnDateFlexible,
      disabledDateForReturnHigherLimit,
      disabledDatesForReturnTime,
      returnTimeLowerLimit,
      pickUpDate,
    ],
  );

  const disabledDateTImeForReturnField = useMemo(
    () =>
      values?.isReturnDateFlexible
        ? disabledDateTimeForReturnHigherLimit
        : disabledDateTime(minAvailableReturnDate, returnTimeDate),
    [
      values?.isReturnDateFlexible,
      disabledDateTimeForReturnHigherLimit,
      disabledDateTime,
      minAvailableReturnDate,
      returnTimeDate,
    ],
  );

  const returnDateFieldDisabled = useMemo(
    () =>
      !values?.returnTime?.timezone ||
      (values?.isReturnDateFlexible && !returnTimeLowerLimit) ||
      disabledValues?.returnTime,
    [values?.returnTime?.timezone, values?.isReturnDateFlexible, returnTimeLowerLimit, disabledValues?.returnTime],
  );

  const returnDateFieldLabel = useMemo(
    () =>
      values?.isReturnDateFlexible
        ? 'End Pick Up Date and Time for Return Trip'
        : 'Pick Up Date and Time for Return Trip',
    [values?.isReturnDateFlexible],
  );

  const returnTimeDefaultDateTime = useMemo(() => new Date().setHours(0, 0, 0, 0), []);

  return values?.isRoundtrip && !disabledValues?.returnTime ? (
    <>
      <div className={styles.windowOfTime}>
        <label className={styles.useWindowOfTime}>
          <Checkbox
            name='isReturnDateFlexible'
            checked={values?.isReturnDateFlexible}
            onChange={handleUseWindowOfTime}
            disabled={disabledValues?.isReturnDateFlexible}
            whenFieldDisabledShowTooltip={Boolean(disabledValues?.isReturnDateFlexible)}
          />
          <span className={styles.checkboxLabelText}>The pickup time is flexible</span>
        </label>
        <ThirdStepTooltip range={maxPickUpWindowOfTime} useFlexibleHoursMessage />
      </div>
      <div className={styles.dateAndTime}>
        <label className={styles.timezone}>
          <span className={styles.required}>Timezone</span>
          <Select
            name='returnTime.timezone'
            options={TIMEZONES}
            onChange={handleChangeTZ}
            placeholder={'Pick one'}
            disabled={disabledValues?.pickUpDate}
            whenFieldDisabledShowTooltip={Boolean(disabledValues?.pickUpDate)}
          />
        </label>

        <div className={styles.range}>
          {values?.isReturnDateFlexible && (
            <label className={styles.startDate}>
              <span className={styles.required}>Start Pick Up Date and Time for Return Trip</span>
              <DatePicker
                name='returnTimeLowerLimit.datetime'
                myClassName={styles.datepicker}
                onChange={handleReturnTimeLowerLimit}
                format={DEFAULT_TIME_FORMAT}
                value={returnTimeLowerLimit}
                showTime={{ defaultValue: returnTimeDefaultDateTime }}
                showNow={false}
                use12Hours
                disabledDate={disabledDatesForReturnTime(pickUpDate)}
                disabledTime={disabledDateTime(minAvailableReturnDate, returnTimeLowerLimit)}
                disabled={!values?.returnTime?.timezone || !pickUpDate || disabledValues?.returnTime}
                whenFieldDisabledShowTooltip={Boolean(disabledValues?.returnTime)}
                inputReadOnly
                allowClear
                getPopupContainer={(trigger) => trigger}
              />
            </label>
          )}
          <label
            className={classNames({
              [styles.endDate]: values?.isPickUpDateFlexible,
              [styles.datepicker]: !values?.isPickUpDateFlexible,
            })}>
            <span className={styles.required}>{returnDateFieldLabel}</span>
            {/* <span className={styles.midnightNoonMessage}>{Midnight_Noon_Clarification}</span> */}
            <DatePicker
              name='returnTime.datetime'
              myClassName={styles.datepicker}
              onChange={handleReturnTime}
              format={DEFAULT_TIME_FORMAT}
              value={returnTimeDate}
              showTime={{ defaultValue: returnTimeDefaultDateTime }}
              showNow={false}
              use12Hours
              disabledDate={diabledDatesForReturnField}
              disabledTime={disabledDateTImeForReturnField}
              disabled={returnDateFieldDisabled}
              whenFieldDisabledShowTooltip={Boolean(disabledValues?.returnTime)}
              inputReadOnly
              allowClear
              getPopupContainer={(trigger) => trigger}
            />
          </label>
        </div>
      </div>
    </>
  ) : null;
};
