import React, { useCallback, useEffect, useMemo } from 'react';
import { renderColumns, transformData } from './renderColumns';
import { Navigation } from '../../../utils/navigation';
import { useSearch } from '../../../hooks/useSearch';
import { useCurrentRole } from '../../../hooks/useCurrentRole';
import { usePagination } from '../../../hooks/usePagination';
import { redirectToPage } from '../../../utils/helpers';
import { BaseTable, Pagination } from '../../../components';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { fetchTCInvoices, downloadInvoicePDF, downloadInvoiceReceipts } from '../../../store/tc_billing';
 
import classNames from 'classnames';
import styles from "./index.module.css";

export const TC_Invoices = ({
  tcInvoices,
  fetchTCInvoices,
  downloadInvoicePDF,
  downloadInvoiceReceipts,
  role,
  limit,
  currentPage,
  total,
  totalPages,
}) => {

  const navigate = Navigation();

  const { searchValue, highlighted,  } = useSearch(fetchTCInvoices);
  const { isMHAdmin, isHOWorker } = useCurrentRole(role);

  usePagination(navigate);

  useEffect(() => {
    fetchTCInvoices({ filtered: false, Navigation: navigate });

    return () => { };
  }, [fetchTCInvoices]);

   

  const handleDownloadInvoice = useCallback((filePath) => () => {
    // window.open(filePath)
    downloadInvoicePDF(filePath);
  }, []);

  const handleDownloadReciepts = useCallback((payload) => () => {
    // window.open(filePath)

 
    downloadInvoiceReceipts(payload);
  }, []);
  const handleChangePage = (page) => {
    redirectToPage(navigate?.location?.pathname, page, navigate);
    fetchTCInvoices({ Navigation: navigate });
  };
 
  const tableData = useMemo(() => {
    return transformData({
      data: tcInvoices,
      handleDownloadInvoice,
      handleDownloadReciepts,
      Navigation: navigate
    });
  }, [
    tcInvoices, 
    handleDownloadInvoice,
      handleDownloadReciepts,
    navigate
  ]);
  const columns = useMemo(() => {
    return role  ? renderColumns(searchValue, highlighted) : null;
  }, [role, tcInvoices, highlighted, searchValue]);

  const InvoiceTable = useMemo(
    () => (
      <BaseTable
        data={tableData}
        columns={columns}
        addHoverEffectToRowTable
      />
    ),
    [  tableData,
      columns,
    ]
  );



  return (
    <div className={styles.pageContent}>
    
      {/* <div className={styles.pageTitle}>
        <h1 className={styles.pageHeader}>Invoices</h1>
      </div> */}
      <div>{InvoiceTable}</div>
      <div className={styles.tableFooter}>
        <div
          className={classNames({
            [styles.pagination]: true,
            [styles.gapForPagination]: tcInvoices?.length > 4,
          })}
        >


          <Pagination
            defaultCurrent={currentPage}
            total={total}
            totalPages={totalPages}
            onChange={handleChangePage}
            pageSize={limit}
          />
        </div>
      </div>

    </div>
 


  );
};

const mapStateToProps = (state) => ({
  tcInvoices: state.tcBilling.tcInvoices,
  limit: state.tcBilling.limit,
  currentPage: state.tcBilling.currentPage,
  total: state.tcBilling.total,
  totalPages: state.tcBilling.totalPages,
  role: state.authorization.role,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchTCInvoices, downloadInvoicePDF,downloadInvoiceReceipts }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TC_Invoices);