import { useEffect, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { validaPickUpDate, resetWindowOfTimeValidation } from '../store/rides';

import { LodashHelper } from '../utils';
import { usePrevious } from './usePrevious';

export const useCheckForValidWindowOfTime = ({ vehicleType, defaultValues, currentValues }) => {
  const dispatch = useDispatch();

  const currentWindowOfTimeFields = useMemo(
    () => ({
      vehicleType,
      pickUpDate: currentValues?.pickUpDate,
      pickUpDateLowerLimit: currentValues?.pickUpDateLowerLimit,
      returnTime: currentValues?.returnTime,
      returnTimeLowerLimit: currentValues?.returnTimeLowerLimit,
      isRoundtrip: currentValues.isRoundtrip,
      isPickUpDateFlexible: currentValues?.isPickUpDateFlexible,
      isReturnDateFlexible: currentValues?.isReturnDateFlexible,
    }),
    [
      vehicleType,
      currentValues?.pickUpDate,
      currentValues?.pickUpDateLowerLimit,
      currentValues.isRoundtrip,
      currentValues?.isPickUpDateFlexible,
      currentValues?.returnTime,
      currentValues?.returnTimeLowerLimit,
      currentValues?.isReturnDateFlexible,
    ],
  );

  const initialWindowOfTimeFields = useMemo(
    () => ({
      vehicleType,
      pickUpDate: defaultValues?.pickUpDate,
      pickUpDateLowerLimit: defaultValues?.pickUpDateLowerLimit,
      returnTime: defaultValues?.returnTime,
      returnTimeLowerLimit: defaultValues?.returnTimeLowerLimit,
      isRoundtrip: defaultValues?.isRoundtrip,
      isPickUpDateFlexible: defaultValues?.isPickUpDateFlexible,
      isReturnDateFlexible: defaultValues?.isReturnDateFlexible,
    }),
    [defaultValues, vehicleType],
  );

  const allowCheck = useMemo(
    () => defaultValues && !LodashHelper.isEqual(currentWindowOfTimeFields, initialWindowOfTimeFields),
    [initialWindowOfTimeFields, currentWindowOfTimeFields, defaultValues],
  );

  const previousValues = usePrevious(initialWindowOfTimeFields);

  const delayedQuery = useRef(
    LodashHelper.debounce((value) => {
      return dispatch(validaPickUpDate(value));
    }, 3000),
  ).current;

  useEffect(() => {
    if (
      allowCheck &&
      currentWindowOfTimeFields?.vehicleType &&
      currentWindowOfTimeFields?.pickUpDate?.datetime &&
      currentWindowOfTimeFields?.pickUpDateLowerLimit?.datetime &&
      currentWindowOfTimeFields?.isPickUpDateFlexible &&
      !currentWindowOfTimeFields?.isRoundtrip &&
      (previousValues?.vehicleType !== currentWindowOfTimeFields?.vehicleType ||
        previousValues?.pickUpDate?.datetime !== currentWindowOfTimeFields?.pickUpDate?.datetime ||
        previousValues?.pickUpDateLowerLimit?.datetime !== currentWindowOfTimeFields?.pickUpDateLowerLimit?.datetime)
    ) {
      delayedQuery(currentWindowOfTimeFields);
    }
    return;
  }, [allowCheck, delayedQuery, dispatch, previousValues, currentWindowOfTimeFields]);

  useEffect(() => {
    if (
      allowCheck &&
      currentWindowOfTimeFields?.vehicleType &&
      (currentWindowOfTimeFields?.pickUpDate?.datetime || currentWindowOfTimeFields?.pickUpDateLowerLimit?.datetime) &&
      currentWindowOfTimeFields?.isRoundtrip &&
      currentWindowOfTimeFields?.isReturnDateFlexible &&
      currentWindowOfTimeFields?.returnTime?.datetime &&
      currentWindowOfTimeFields?.returnTimeLowerLimit?.datetime &&
      (previousValues?.vehicleType !== currentWindowOfTimeFields?.vehicleType ||
        previousValues?.pickUpDate?.datetime !== currentWindowOfTimeFields?.pickUpDate?.datetime ||
        previousValues?.pickUpDateLowerLimit?.datetime !== currentWindowOfTimeFields?.pickUpDateLowerLimit?.datetime ||
        previousValues?.returnTime?.datetime !== currentWindowOfTimeFields?.returnTime?.datetime ||
        previousValues?.returnTimeLowerLimit?.datetime !== currentWindowOfTimeFields?.returnTimeLowerLimit?.datetime)
    ) {
      delayedQuery(currentWindowOfTimeFields);
    }
    return;
  }, [allowCheck, delayedQuery, dispatch, previousValues, currentWindowOfTimeFields]);

  useEffect(() => () => dispatch(resetWindowOfTimeValidation()), [dispatch]);
};
