import React from 'react';
import { Formik } from 'formik';
import { SearchOutlined } from '@ant-design/icons';
import classNames from 'classnames';

import { Input, Button, Pagination, BaseTable } from '../..';

import styles from './index.module.css';

export const EntitiesTableList = ({
  title,
  addNewBtnTitle, 
  Navigation,
  data,
  columns,
  handleChangePage,
  handleRowClick,
  limit,
  currentPage,
  total,
  totalPages,
  showAddNewButton,
  showAddNewAdminButton,
  addHoverEffectToRowTable,
  handleChangeSearch,
  searchValue,
  searchPlaceholder = 'Search by name or email',
}) => {
  const navigate = Navigation;

  return (
    <>
      <div className={styles.pageContent}>
        <div className={styles.pageTitle}>
          <h1 className={styles.title}>{title}</h1>
          <Formik
            enableReinitialize
            initialValues={{ searchInput: searchValue }}
          >
            {({ setFieldValue }) => (
              <div className={styles.inputField}>
                <Input
                  type="text"
                  name="searchInput"
                  onChange={handleChangeSearch}
                  prefix={<SearchOutlined className={styles.searchIcon} />}
                  placeholder={searchPlaceholder}
                  allowClear
                  myClassName={styles.inputFieldWidth}
                />
              </div>
            )}
          </Formik>
          
          {showAddNewAdminButton && (
            
              <div className={[styles.addOrganization,styles.add].join(' ')} >
                 <Button
                  myClassName={styles.addOrganizationBtn}
                  onClick={() => {
                    navigate.push(`${navigate?.location.pathname}/admin/new`, {
                      from: navigate?.location.pathname,
                    });
                  }}
                > Add New Admin 
                </Button> 
              </div> 
        
          )}
          {showAddNewButton && (
            <div className={styles.addOrganization}>
              <Button
                myClassName={styles.addOrganizationBtn}
                onClick={() =>
                  navigate.push(`${navigate?.location.pathname}/new`, {
                    from: navigate?.location.pathname,
                  })
                }
              >
                {addNewBtnTitle}
              </Button>
            </div>
          )}
        </div>
        <div
          className={classNames({
            [styles.tableWrapper]: true,
            [styles.ridersTable]: addHoverEffectToRowTable,
          })}
        >
          <BaseTable
            data={data}
            columns={columns}
            onRowClick={handleRowClick}
          />
        </div>
      </div>
      <div
        className={classNames({
          [styles.pagination]: true,
          [styles.gapForPagination]: data?.length > 6,
        })}
      >
        <Pagination
          defaultCurrent={currentPage}
          total={total}
          totalPages={totalPages}
          onChange={handleChangePage}
          pageSize={limit}
        />
      </div>
    </>
  );
};
