import React from "react";
import PropTypes from "prop-types";

import { Record } from "../RideDetails";
import { DateHelper, renderRecordValue } from "../../../../utils";
import { renderBuildingDetails } from "./BuildingDetails";
import { renderPickUpDate } from "../../../../utils/helpers";

import styles from "../index.module.css";

export const RideDestinationInfo = ({
  destinationAddress,
  originAddress,
  showRoundtripInfo,
  returnTime,
  isReturnDateFlexible,
  returnTimeLowerLimit,
  securityGate,
  leg,
  seqId,
}) => {
  return (
    <div className={styles.rideCard}>
      {leg && <h3>Leg {leg + (seqId ? ` - ID (${seqId})` : "")}</h3>}
      
      {/* Origin Address */}
      {originAddress && (
        <>
          <p className={styles.originAddress}>Origin Address:</p>
          <p>{renderRecordValue(destinationAddress?.formattedAddress)}</p>
        </>
      )}

      {/* Destination Address */}
      <p className={styles.originAddress}>Destination Address:</p>
      <p>{renderRecordValue(originAddress ? originAddress?.formattedAddress:destinationAddress?.formattedAddress )}</p>

      {/* Destination Facility Name */}
      {destinationAddress?.facilityName && (
        <>
          <p className={styles.originAddress}>Facility Name:</p>
          <p>{renderRecordValue(destinationAddress?.facilityName)}</p>
        </>
      )}

      {renderBuildingDetails(destinationAddress)}
      {destinationAddress?.isPublicPlace && (
        <Record
          title="Public Place"
          value={destinationAddress?.isPublicPlace ? "Yes" : "No"}
          myClassName={styles.recordDescription}
        />
      )}
      {securityGate?.hasSecurityGate && (
        <Record
          title="Gate Entry Instructions"
          value={securityGate?.securityGateInstruction}
          myClassName={styles.recordDescription}
        />
      )}

      {showRoundtripInfo &&
        (isReturnDateFlexible ? (
          <Record
            title="Roundtrip Pickup Scheduled for"
            value={renderRecordValue(
              returnTime?.datetime &&
                renderPickUpDate({
                  dateFrom: returnTimeLowerLimit,
                  dateTo: returnTime,
                })
            )}
            myClassName={styles.recordDescription}
          />
        ) : (
          <Record
            title="Roundtrip Pickup Scheduled for"
            value={renderRecordValue(
              returnTime?.datetime &&
                DateHelper.renderDateTimeWithTZ({
                  dateTime: returnTime.datetime,
                  timeZone: returnTime.timezone,
                })
            )}
            myClassName={styles.recordDescription}
          />
        ))}
    </div>
  );
};

RideDestinationInfo.propTypes = {
  destinationAddress: PropTypes.object,
  originAddress: PropTypes.object,
  isRoundtrip: PropTypes.bool,
  isRecurring: PropTypes.bool,
  returnTime: PropTypes.object,

  leg: PropTypes.string,
};
