import { FormikProvider, useFormik } from 'formik';
import React, { useCallback, useMemo, useState } from 'react';
import { Button, Modal } from '../..';
import { REASONS_TO_EDIT_RIDE, SUPER_ADMIN_RIDE_EDIT_REASONS } from '../../../constants/constants';
import { reasonForUpdateSchema } from '../../../utils/validations';
import { InputTextArea } from '../../formControls/Input/InputTextArea';
import { Select } from '../../formControls/Select/Select';

import styles from './index.module.css';

const initialValues = { reasonForUpdate: '', otherReason: '' };

export const useConfirmEditRideModal = ({ onConfirm }) => {
  const [visibleModal, setModalVisible] = useState(false);

  const handleConfirm = useCallback(
    (values) => {
      if (values.otherReason) {
        onConfirm({ reasonForUpdate: values.otherReason });
      } else {
        onConfirm({ reasonForUpdate: values.reasonForUpdate });
      }
    },
    [onConfirm],
  );
  const formik = useFormik({
    initialValues,
    validationSchema: reasonForUpdateSchema,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: handleConfirm,
  });

  const { resetForm, validateForm, isValid, values, handleSubmit } = formik;

  const onCancel = useCallback(async () => {
    await resetForm(initialValues);
    await validateForm();
    await setModalVisible(false);
  }, [setModalVisible, validateForm, resetForm]);

  const handleReasonForUpdate = useCallback(async ({ name, value, setFieldValue }) => {
    if (value !== REASONS_TO_EDIT_RIDE.CUSTOM_REASON) await setFieldValue('otherReason', '');
    await setFieldValue(name, value);
  }, []);

  const saveChangesButtonIsDisabled = useMemo(() => {
    if (visibleModal) {
      return !isValid;
    } else return true;
  }, [visibleModal, isValid]);

  return {
    Modal: (
      <Modal
        visible={visibleModal}
        onCancel={onCancel}
        closable
        width={700}
        title={<h3 className={styles.modalTitle}>Warning - You are about to make changes to production data!</h3>}>
        <div className={styles.modalContent}>
          <p className={styles.modalDescription}>
            Please make sure that your changes are correct for the ride in question.
            <br />
            Your changes will be tracked under your login in the audit table.
          </p>
          <FormikProvider value={formik}>
            <>
              <form className={styles.form}>
                <label className={styles.comment}>
                  <span className={styles.required}>Reason for changes</span>
                  <Select
                    name='reasonForUpdate'
                    onChange={handleReasonForUpdate}
                    options={SUPER_ADMIN_RIDE_EDIT_REASONS}
                    placeholder='Pick a reason for update'
                  />
                </label>
                {values?.reasonForUpdate === REASONS_TO_EDIT_RIDE.CUSTOM_REASON && (
                  <label className={styles.comment}>
                    <span className={styles.required}>Other Reason for update</span>
                    <InputTextArea
                      name='otherReason'
                      placeholder='Please fill in reason for update'
                      type='text'
                      autosize={{ minRows: 1, maxRows: 5 }}
                    />
                  </label>
                )}
              </form>

              <div className={styles.modalBtns}>
                <div>
                  <Button myClassName={styles.navigationBtn} onClick={onCancel}>
                    Back to Editing
                  </Button>
                </div>
                <div>
                  <Button
                    myClassName={styles.navigationBtn}
                    onClick={handleSubmit}
                    disabled={saveChangesButtonIsDisabled}>
                    Save Changes
                  </Button>
                </div>
              </div>
            </>
          </FormikProvider>
        </div>
      </Modal>
    ),
    setConfirmlModalVisible: setModalVisible,
    confirmModalVisible: visibleModal,
  };
};
