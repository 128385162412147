import React from 'react';
import classNames from 'classnames';
import { FieldArray, getIn } from 'formik';

import { DAYS, DISABLED_FIELD_TOOLTIP_CONTENT } from '../../../../constants/constants';

import { Tooltip } from '../../..';
import { Checkbox } from '../../../formControls/Checkbox/Checkbox';

import styles from './index.module.css';

export const renderWeekRangePicker = (values, name, disabled, showDisabledTooltip) => (arrayHelpers) => {
  const handleCheckbox =
    (option, disabledDay) =>
    async ({ checked }) => {
      if (disabled || disabledDay) return;

      if (checked) {
        await arrayHelpers.push(option);
      } else {
        const idx = getIn(values, name)?.indexOf(option);
        if (idx !== -1) await arrayHelpers.remove(idx);
      }

      await arrayHelpers.form.validateForm();
    };

  return DAYS.map((d) => {
    const disabledDay =
      values?.recurrenceRule?.byDays?.length === values?.recurrenceRule?.count &&
      !values?.recurrenceRule?.byDays?.includes(d.value);
    return (
      <Tooltip
        title={<span>{DISABLED_FIELD_TOOLTIP_CONTENT}</span>}
        isVisible={showDisabledTooltip}
        applyEditTooltipOverlayClassname
        key={d.id}>
        <label className={styles.dayLabel} data-testid='dayLabel'>
          <Checkbox
            myClassName={styles.checkboxClassname}
            name={name}
            checked={getIn(values, name)?.includes(d.value)}
            value={d.value}
            onChange={handleCheckbox(d.value, disabledDay)}
          />
          <div
            className={classNames({
              [styles.day]: true,
              [styles.selected]: getIn(values, name)?.includes(d.value),
              [styles.disabled]: disabled || disabledDay,
            })}
            data-testid='day'>
            {d.title}
          </div>
        </label>
      </Tooltip>
    );
  });
};

export const WeekRangePicker = ({ values, name, disabled, showDisabledTooltip }) => {
  return (
    <>
      <span className={styles.required}>Repeat on</span>
      <div className={styles.weekRangePicker}>
        <FieldArray name={name} render={renderWeekRangePicker(values, name, disabled, showDisabledTooltip)} />
      </div>
    </>
  );
};
