export const handleAddCounties =
  ({ setValues, values }) =>
    () => {
      const indexOfState = values.regionsOfOperation.findIndex((region) => region.state === values.state);

      if (indexOfState >= 0) {
        values.regionsOfOperation[indexOfState].counties = values.counties;
        values.regionsOfOperation[indexOfState].state = values.state;
      } else {
        values.regionsOfOperation = [...values.regionsOfOperation, { state: values.state, counties: values.counties }];
      }

      setValues({ ...values, regionsOfOperation: values.regionsOfOperation, state: '', counties: [] });
    };

export const handleStateSelect = ({ name, value, values, setValues }) => {
  const region = values.regionsOfOperation.find((region) => region.state === value);
  setValues({
    ...values,
    [name]: value,
    counties: region?.counties || [],
  });
};

export const handleCountiesSelect = (counties) => ({ name, value, values, setFieldValue }) => {
  if (value.includes('Select All')) {
    return setFieldValue(name, counties[values.state]);
  }
  setFieldValue(name, value);
};

export const handleDelete =
  ({ values, setValues, state, county, deleteState }) =>
    () => {
      if (deleteState) {
        return setValues({
          ...values,
          regionsOfOperation: values.regionsOfOperation.filter((region) => region.state !== state),
        });
      }
      const indexOfState = values.regionsOfOperation.findIndex((region) => region.state === state);
      values.regionsOfOperation[indexOfState].counties = values.regionsOfOperation[indexOfState].counties.filter(
        (c) => c !== county,
      );
      setValues({
        ...values,
        regionsOfOperation: values.regionsOfOperation,
      });
    };