import { LodashHelper } from '../../utils';

export const getLoading = (state) =>
  state.drivers.loading ||
  state.common.loading ||
  state.rides.loading ||
  state.users.loading ||
  state.preferences.loading ||
  state.transportOrganizations.loading ||
  state.healthOrganizations.loading ||
  state.drafts.loading ||
  state.authorization.loading ||
  state.tcBilling.loading ||
  state.riders.loading ||
  state.addressBook.loading;

export const checkForOwnFilters = (state) => {
  const { formattedRiderName, ...myFilterParams } = state.rides.myFilterParams;
  return Boolean(myFilterParams && Object.keys(LodashHelper.removeEmptyAndTrim(myFilterParams))?.length);
};
