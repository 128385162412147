import classNames from "classnames";
import { FormikProvider, useFormik } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useParams } from "react-router-dom";

import { Checkbox, Input, InputTextArea, Select, Tooltip } from "../../../components";
import { useConfirmEditRideModal } from "../../../components/modals/ConfirmEditRideModal/useConfirmEditRideModal";
import { DateHelper, LodashHelper } from "../../../utils";
import { getPlatformSettings } from "../../../store/preferences";
import {
  clearTCInfo,
  fetchTransportCompaniesMatchingForRide,
} from "../../../store/transport_organizations";

import {
  acceptedForToolTipMessage,
  allowedStatuses,
  allowedStatusesForEditOptions,
  DatePickerToolTip,
  deadHeadMileageTooltipMessage, driversToolTipMessage,
  endDateTooltipMessage,
  rideTotalMileageTooltipMessage,
  transportationCompaniesTooltipMessage
} from "./TooltipMessages";
import { useDateTimeHandlers } from "./useDateTimeHandlers";
import { getFlexibleHoursMessage } from "../../../components/forms/CreateRide/StepThree/ThirdStepTooltip";
import {
  Buttons,
  checkIsCancelFieldRequired,
  checkIsCancelStatusFieldRequired, DatePickerPanel,
  PickUpDateTimeRange
} from "./partials";
import {
  fetchDriversByTCMatchingForRide,
  clearDriversInfo,
} from "../../../store/drivers";
import { forceUpdateRide, updateChangeRequestStatus } from "../../../store/rides";

import { Navigation } from "../../../utils/navigation";
import { renderTimeInputFields } from "../../../components/forms/partials/renderTimeInputFields";
import { ADD_RIDER_NOSHOW_CANCELLATION_CHARGE, ADD_SAMEDAY_CANCELLATION_CHARGE, CANCEL_BY_FORCE_UPDATE, CANCEL_RIDE_OPTIONS, CANCELLATION_TYPE_BY_ROLE, GRACE_PERIOD_FOR_WAIT_TIME, LOAD_UNLOAD_FIELD_ARRAY, RIDE_STATUSES, riderNoShowChargeMessage_MHAdmin, sameDayCancellationChargeMessage_MHAdmin, USER_ROLES } from "../../../constants/constants";
import { renderNumericFields } from "../../../components/forms/partials/renderNumericFields";
import styles from "./index.module.css";
import { RequestChangeConfirmationModal } from "../../../components/modals/RequestChangeConfirmationModal/RequestChangeConfirmationModal";
import { InfoOutlined } from "@ant-design/icons";
import { mhAdminEditRideSchema } from "../../../utils/validations";
 

const defaultDatetime = {
  datetime: "",
  timezone: "",
};

const MHEditRide = ({
  ride,
  getPlatformSettings,
  platformPreferences,
  fetchTransportCompaniesMatchingForRide,
  fetchDriversByTCMatchingForRide,
  transportOrganizations,
  drivers,
  forceUpdateRide,
  clearDriversInfo,
  clearTCInfo,
  updateChangeRequestStatus
}) => {
  const { id } = useParams();
  const { back } = Navigation();
  const handleBack = () => back();

  const transportOrganizationsOptions = useMemo(
    () =>
      transportOrganizations.map((tc) => ({ title: tc.name, value: tc.id })),
    [transportOrganizations]
  );

  const driversOptions = useMemo(
    () =>
      drivers.map((driver) => ({
        title: `${driver.firstName} ${driver.lastName}`,
        value: driver.id,
        disabled: !driver.isEligible,
      })),
    [drivers]
  );
 
  const initialValues = {
    status: ride?.status || "",
    transportCompany: ride?.transportCompanyId || "",
    driver: ride?.driver?.id || "",
    pickUpDate: ride?.pickUpDate || defaultDatetime,
    pickUpDateLowerLimit: ride?.pickUpDateLowerLimit || defaultDatetime,
    tcEstimatedPickUpDate: ride?.tcEstimatedPickUpDate || {
      ...defaultDatetime,
      ...(ride?.isPickUpDateFlexible && {
        timezone: ride?.pickUpDate?.timezone,
      }),
    },
    isPickUpDateFlexible: ride?.isPickUpDateFlexible || false,
    startDate: {
      timezone: ride?.pickUpDate?.timezone || "",
      datetime: ride?.startDate || "",
    },
    endDate: {
      timezone: ride?.pickUpDate?.timezone || "",
      datetime: ride?.endDate || "",
    },
    driverArrivalTime: {
      timezone: ride?.pickUpDate?.timezone || null,
      datetime: ride?.driverArrivalTime || null
    },
    waitTime:
      DateHelper.convertMinutesToTime(ride?.waitTime ? (Number(ride?.waitTime) + GRACE_PERIOD_FOR_WAIT_TIME) : null),
    loadTime: ride?.loadTime,
    unloadTime: ride?.unloadTime,
    actMileage: ride?.actMileage,
    deadheadMileage : ride?.deadheadMileage,
    reason: ride?.cancellationInfo?.comment,
    additionalComments: ride?.cancellationInfo?.additionalComments,
    cancelationType: ride?.cancellationInfo?.cancelationType,
    cancelBy: ride?.cancellationInfo?.cancelBy? ride?.cancellationInfo?.cancelBy: 'HO_ADMIN',
    isSameDayCancellation: ride?.cancellationInfo?.isSameDayCancellation,
    isRiderNoShowCancellation: ride?.cancellationInfo?.isRiderNoShowCancellation,
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validateOnMount: true,
    validateOnChange: true,
     validationSchema: mhAdminEditRideSchema(ride?.isPickUpDateFlexible),
    onSubmit: () => setConfirmlModalVisible(true),
  });


 

 // const [reasonBy,setReasonBy]= useState('');
  const [information,setInformation]= useState('');

  const handleRequestChange = () =>
  {
  return  requestChangeModel.setConfirmlModalVisible(true);
  }

  const { setFieldValue, values, handleSubmit, isValid } = formik;

  const {  confirmModalVisible, setConfirmlModalVisible, Modal } =
    useConfirmEditRideModal({
      onConfirm: (reasonForUpdate) =>

         {
       
          return forceUpdateRide({
          payload: LodashHelper.removeEmpty({
            ...values,
            ...reasonForUpdate,
            waitTime: DateHelper.convertTimeToMinutes(values?.waitTime),
          }),
          id,
          onSuccesCallback: () => setConfirmlModalVisible(false),
        })},
    });

    let requestChangeModel  =
    RequestChangeConfirmationModal({
     onConfirm: (payload) =>
      updateChangeRequestStatus({
          payload: LodashHelper.removeEmpty({
            ...payload,
             
          }),
          id,
          onSuccesCallback: () => requestChangeModel.setConfirmlModalVisible(false),
        }
      
      ),
    }
  );

  const {
    pickUpDefaultDateTime,
    disabledDate,
    disabledDateTime,
    handleChangeEarliestDateTime,
    handleChangeLatestDateTime,
    handleChangeStartDateTime,
    handleChangeEndDateTime,
    handleChangeDriverArrivalTime,
    handleChangeTZ,
    diabledDateForStartDate,
    diabledDateForRequestPickupDate,
    diabledTimeForRequestPickupDate,
    disabledDatesForAcceptedFor,
    disabledDateTimeForAcceptedFor,
    handleChangeDateTimeForAcceptedFor,
    defaultValueForDatePicker,
    pickUpDateLowerLimit,
    pickUpDate,
    acceptedForDate,
    startDate,
    endDate,
    driverArrivalTime,
  } = useDateTimeHandlers({
    ride,
    setFieldValue,
    platformPreferences,
    maxPickUpWindowOfTime: platformPreferences?.maxPickUpWindowOfTime,
  });


  useEffect(()=>{
    if(values?.cancelBy && values?.cancelBy != '')
    {

      
      if( [USER_ROLES.HO_ADMIN, 'RIDER'].includes(values?.cancelBy))
      {
        setInformation(sameDayCancellationChargeMessage_MHAdmin);
      }else    if( [USER_ROLES.TC_ADMIN, USER_ROLES.DRIVER].includes(values?.cancelBy))
     {
      setInformation(riderNoShowChargeMessage_MHAdmin);
     }else
     {
      setInformation(null);
     }
    // const reasonObjArr = CANCEL_RIDE_OPTIONS[reasonBy].filter(r => r.value === values?.reason);
    // Array.isArray(reasonObjArr) && reasonObjArr.length > 0 ? setInformation(reasonObjArr[0]?.information) : setInformation(null);
    // 
    
  }
  },[values?.cancelBy,values,information])

  useEffect(() => {
    getPlatformSettings();
    return;
  }, [getPlatformSettings]);

  useEffect(
    () => () => {
      clearDriversInfo();
      clearTCInfo();
    },
    [clearDriversInfo, clearTCInfo]
  );

  useEffect(() => {
    id && fetchTransportCompaniesMatchingForRide(id);
    return;
  }, [id, fetchTransportCompaniesMatchingForRide]);

  useEffect(() => {
    if (values?.transportCompany)
      fetchDriversByTCMatchingForRide({
        rideId: id,
        transportCompanyId: values?.transportCompany,
      });
    return;
  }, [fetchDriversByTCMatchingForRide, id, ride, values.transportCompany]);

  const handleChangePickUpDate = handleChangeLatestDateTime(
    ride?.isPickUpDateFlexible ? pickUpDateLowerLimit : null
  );

  useEffect(() => {
      return;
    }, [formik]);
  

  const startDateDefaultPickerValue = acceptedForDate
    ? new Date(acceptedForDate)
    : new Date(pickUpDate);

  const startDatePickerIsDisabled =
    !values?.startDate?.timezone ||
    (ride?.isPickUpDateFlexible && !acceptedForDate);

  const saveChangesButtonIsDisabled =
    !isValid ||
    !allowedStatusesForEditOptions.some((s) => s.value === values?.status) ||
    LodashHelper.isEqual(initialValues, values);

    

  const handleChangeProvider = async ({ name, value, setFieldValue }) => {
    await setFieldValue(name, value);
    await setFieldValue("driver", "");
    await setFieldValue('reason', null);
    await setFieldValue('cancelationType', null);
    await setFieldValue('isSameDayCancellation', null);
    await setFieldValue('isRiderNoShowCancellation', null);

    clearDriversInfo();
  };


  const findCancellationType = (reason) => {
    const reasonObjArr = CANCEL_RIDE_OPTIONS[values?.cancelBy].filter(r => r.value === reason);
    const cancelationType = Array.isArray(reasonObjArr) && reasonObjArr.length > 0 ? reasonObjArr[0]?.cancellationType : null;
    return cancelationType;
  }

  const handleChangeStatus = async ({ name, value, setFieldValue }) => {
    await setFieldValue(name, value);
    if (value !== RIDE_STATUSES.CANCELED) {
      await setFieldValue('reason', null);
      await setFieldValue('cancelBy', null);
      await setFieldValue('additionalComments', null);
      await setFieldValue('cancelationType', null);
      await setFieldValue('isSameDayCancellation', null);
      await setFieldValue('isRiderNoShowCancellation', null);
    } else {
      await setFieldValue('reason', initialValues?.reason);
      await setFieldValue('additionalComments', initialValues?.additionalComments);
      await setFieldValue('cancelationType', initialValues?.cancelationType);
      await setFieldValue('isSameDayCancellation', initialValues?.isSameDayCancellation);
      await setFieldValue('isRiderNoShowCancellation', initialValues?.isRiderNoShowCancellation);

       
    }

    if (value !== RIDE_STATUSES.COMPLETED) {
      await setFieldValue('actMileage', 0);
    await setFieldValue('deadheadMileage',0);
    } else {
      await setFieldValue('actMileage', initialValues?.actMileage);
      await setFieldValue('deadheadMileage',initialValues?.deadheadMileage);
    }
  };

  const handleSelectCancelReason = async ({ name, value, setFieldValue }) => {
    await setFieldValue(name, value);
    const cancelationType = findCancellationType(value);
    await setFieldValue('cancelationType', cancelationType);
    await setFieldValue('isSameDayCancellation', undefined);
    await setFieldValue('isRiderNoShowCancellation', undefined);
  }


  const handleCancelBy = useCallback(async ({ name, value, setFieldValue }) => {
    await setFieldValue(name, value);
    await setFieldValue('reason', '');
    await setFieldValue('isSameDayCancellation', undefined);
    await setFieldValue('isRiderNoShowCancellation', undefined);
    //await setReasonBy(value);
    await setInformation(null);
  }, []);


  
  return (
    <div className={styles.pageContent}>
      <div className={styles.editRide}>
        <h2>Super Admin Ride Fields available for change</h2>
        <FormikProvider value={formik}>
          <form
            className={classNames({
              [styles.editForm]: true,
              [styles.editFormWithFlexibleHours]: ride?.isPickUpDateFlexible,
            })}
          >
            <div className={styles.container}>
              <label className={styles.dropdown}>
                <span className={styles.required}>Ride Status</span>
                <Select
                  name="status"
                  options={allowedStatusesForEditOptions}
                  placeholder="Ride Status"
                  value={values?.status}
                  onChange={handleChangeStatus}
                  allowClear
                />
              </label>
              <DatePickerToolTip message={allowedStatuses} />
            </div>
            {transportOrganizationsOptions.length > 0 && (
              <div className={styles.container}>
                <label className={styles.dropdown}>
                  <span className={checkIsCancelStatusFieldRequired(values?.status)}>
                    Provider Assigned
                  </span>
                  <Select
                    name="transportCompany"
                    options={transportOrganizationsOptions}
                    onChange={handleChangeProvider}
                    placeholder="Provider Assigned"
                    value={values?.transportCompany}
                    allowClear
                  />
                </label>
                <DatePickerToolTip
                  message={transportationCompaniesTooltipMessage}
                />
              </div>
            )}
            {driversOptions?.length > 0 && (
              <div className={styles.container}>
                <label className={styles.dropdown}>
                  <span>Driver Assigned</span>
                  <Select
                    name="driver"
                    options={driversOptions}
                    placeholder="Driver Assigned"
                    value={values?.driver}
                    allowClear
                  />
                </label>
                <DatePickerToolTip message={driversToolTipMessage} />
              </div>
            )}


            {formik?.values?.status === RIDE_STATUSES.CANCELED &&
             <>
               {CANCEL_BY_FORCE_UPDATE && (
                  <div className={styles.container} key={"keyCancelBy"}>
                 <label className={styles.dropdown}>
                    <span className={checkIsCancelFieldRequired(values?.status)}>Cancelled By </span>
                    <Select name='cancelBy' options={CANCEL_BY_FORCE_UPDATE[RIDE_STATUSES.ACCEPTED]} onChange={handleCancelBy} placeholder='Pick an option' />
                  </label> 
                </div>
                )}

                {CANCEL_RIDE_OPTIONS[values?.cancelBy] && (
                  <div className={styles.container} key={"cancelReason"}>
                  <label className={styles.dropdown}>
                    <span className={checkIsCancelFieldRequired(values?.status)}>Cancellation Reason </span>
                    <Select name='reason' onChange={handleSelectCancelReason}  
                    options={CANCEL_RIDE_OPTIONS[values?.cancelBy]} placeholder='Pick an option' />
                  </label>
                </div>
                )
                }
                
                { [USER_ROLES.HO_ADMIN,'RIDER'].includes(values?.cancelBy) && values?.transportCompany &&
                  <div className={classNames({ [styles.timesContainer]: true })} key={"sameDayCancel"}>
                    <label className={styles.timesLabel}>
                      <Checkbox
                        name='isSameDayCancellation'
                        checked={values?.isSameDayCancellation}
                      />
                      <span className={styles.ml12}>{ADD_SAMEDAY_CANCELLATION_CHARGE}</span>
                    </label>
                   </div>
                }

                { [USER_ROLES.TC_ADMIN,USER_ROLES.DRIVER].includes(values?.cancelBy) && values?.transportCompany &&
                  ![CANCELLATION_TYPE_BY_ROLE.cancelByDriver, CANCELLATION_TYPE_BY_ROLE.cancelByTC].includes(values?.cancelationType) &&
                  <div className={classNames({ [styles.timesContainer]: true})} key={"riderNoShow"}>
                    <label className={styles.timesLabel}>
                      <Checkbox
                        name='isRiderNoShowCancellation'
                        checked={values?.isRiderNoShowCancellation}
                      />
                      <span className={styles.ml12}>{ADD_RIDER_NOSHOW_CANCELLATION_CHARGE}</span>
                    </label>

                   </div>}

                  {information && information !=='' && values?.transportCompany &&
                    ![CANCELLATION_TYPE_BY_ROLE.cancelByDriver, CANCELLATION_TYPE_BY_ROLE.cancelByTC].includes(values?.cancelationType) &&
                     <label className={styles.cancelRideLabel}>
                     <div className={styles.informationMessage}>{information}</div>
                    </label>
                }
                <div className={styles.container} key={"cancelComments"}>
                  <label className={styles.dropdown}>
                    <span >Additional Details for Ride Cancellation</span>
                    <InputTextArea
                      name='additionalComments'
                      placeholder='Please fill in reason for ride Cancelation'
                      type='text'
                      autosize={{ minRows: 1, maxRows: 5 }}
                    />
                  </label>
                </div>
              </>

            }

            <PickUpDateTimeRange
              ride={ride}
              values={values}
              earliestDateValue={pickUpDateLowerLimit}
              latestDateValue={pickUpDate}
              handleChangeEarliestDateTime={handleChangeEarliestDateTime()}
              handleChangeLatestDateTime={handleChangePickUpDate}
              handleChangeTZ={handleChangeTZ}
              showTime={{ defaultValue: pickUpDefaultDateTime }}
              disabledLatestPicker={{
                disabledDate: diabledDateForRequestPickupDate,
                disabledTime: diabledTimeForRequestPickupDate,
              }}
              showTooltip={ride?.isPickUpDateFlexible}
              tooltipMessage={getFlexibleHoursMessage(
                platformPreferences?.maxPickUpWindowOfTime
              )}
            />

            {ride?.isPickUpDateFlexible && (
              <DatePickerPanel
                defaultPickerValue={new Date(pickUpDateLowerLimit)}
                datepickerFieldName={"tcEstimatedPickUpDate.datetime"}
                timezoneFieldName={"tcEstimatedPickUpDate.timezone"}
                datePickerValue={acceptedForDate}
                values={values}
                handleChangeTZ={handleChangeTZ}
                handleChangeDatePicker={handleChangeDateTimeForAcceptedFor}
                datePickerLabel="Accepted for Pickup Date"
                showTime={{ defaultValue: defaultValueForDatePicker }}
                disabledDate={disabledDatesForAcceptedFor}
                disabledTime={disabledDateTimeForAcceptedFor}
                disabled={!values?.tcEstimatedPickUpDate?.timezone}
                showTooltip
                tooltipMessage={acceptedForToolTipMessage}
              />
            )}

<DatePickerPanel
                  
                  datepickerFieldName={"driverArrivalTime.datetime"}
                  timezoneFieldName={"driverArrivalTime.timezone"}
                  datePickerValue={driverArrivalTime}
                  values={values}
                  handleChangeTZ={handleChangeTZ}
                  handleChangeDatePicker={handleChangeDriverArrivalTime()}
                  datePickerLabel="Driver Arrival Date"
                  showTime={{ defaultValue: pickUpDefaultDateTime }}
                   
                  disabled={startDatePickerIsDisabled}
              
                />

            <DatePickerPanel
              datepickerFieldName={"startDate.datetime"}
              timezoneFieldName={"startDate.timezone"}
              datePickerValue={startDate}
              values={values}
              handleChangeTZ={handleChangeTZ}
              handleChangeDatePicker={handleChangeStartDateTime()}
              datePickerLabel="Actual Pickup Date"
              showTime={{ defaultValue: startDateDefaultPickerValue }}
              disabledDate={disabledDate(driverArrivalTime)}
              disabledTime={disabledDateTime(driverArrivalTime, startDate)}
              disabled={startDatePickerIsDisabled}
            />
            <DatePickerPanel
              defaultPickerValue={new Date(startDate)}
              datepickerFieldName={"endDate.datetime"}
              timezoneFieldName={"endDate.timezone"}
              datePickerValue={endDate}
              values={values}
              handleChangeTZ={handleChangeTZ}
              handleChangeDatePicker={handleChangeEndDateTime(startDate)}
              datePickerLabel="Actual Dropoff Date"
              showTime={{ defaultValue: pickUpDefaultDateTime }}
              disabledDate={disabledDate(startDate)}
              disabledTime={disabledDateTime(startDate, endDate)}
              disabled={!values?.endDate?.timezone || !startDate}
              showTooltip
              tooltipMessage={endDateTooltipMessage}
            />

 
       

            <div className={styles.timesContainer} key={"waitTimeMhEditRide"}>
              <label className={styles.timesLabel}>
                <span className={styles.timesTitle}>{"Total Wait Time:"}</span>
              </label>
              <div className={styles.timeInputFieldsContainer}>
                {renderTimeInputFields("waitTime")}
              </div>
            </div>
            <div className={styles.timesContainer} key={"loadUnloadMhEditRide"}>
              <label className={styles.timesLabel}>
                <span className={styles.timesTitle}> {saveChangesButtonIsDisabled}
                  {"Rider Load and Unload Times (in minutes):"}
                </span>
              </label>
              <div className={styles.timeInputFieldsContainer}>
                {renderNumericFields(LOAD_UNLOAD_FIELD_ARRAY)}
              </div>
              {formik?.values?.status === RIDE_STATUSES.COMPLETED && (
                <div className={styles.mileageWrapper}>
                <label className={styles.comment}>
                  <span> Ride total mileage:</span>
                  <Input name="actMileage" type="number"   min={0}
                      precision={2}
                      step={0.01} />
                </label>
                <span className={styles.tooltipWrapper}>
                    <Tooltip
                      title={<span>{rideTotalMileageTooltipMessage}</span>}
                    >
                      <span className={styles.tooltipInfo}>
                        <InfoOutlined className={styles.infoIcon} />
                      </span>
                    </Tooltip>
                  </span>
                </div>
              )}
              {formik?.values?.status === RIDE_STATUSES.COMPLETED && (
                <div className={styles.mileageWrapper}>
                  <label className={styles.deadhead}>
                    <span> Deadhead Mileage:</span>

                    <Input name="deadheadMileage" type="number"   min={0}
                      precision={2}
                      step={0.01} />
                  </label>
                  <span className={styles.tooltipWrapper}>
                    <Tooltip
                      title={<span>{deadHeadMileageTooltipMessage}</span>}
                    >
                      <span className={styles.tooltipInfo}>
                        <InfoOutlined className={styles.infoIcon} />
                      </span>
                    </Tooltip>
                  </span>
                </div>
              )}
            </div>
    
            <Buttons
              ride={ride}
              handleBack={handleBack}
              handleSubmit={handleSubmit}
              handleRequestChange={handleRequestChange}
              saveChangesButtonIsDisabled={saveChangesButtonIsDisabled}
            />
          </form>
        </FormikProvider>
      </div>
      {confirmModalVisible && Modal}
      {requestChangeModel.confirmModalVisible && requestChangeModel.Modal}
    </div>
  );
};

const mapStateToProps = (state) => ({
  platformPreferences: state.preferences.platformPreferences,
  HOPreferences: state.healthOrganizations.HOPreferences,
  transportOrganizations: state.transportOrganizations.transportOrganizations,
  drivers: state.drivers.drivers,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getPlatformSettings,
      fetchTransportCompaniesMatchingForRide,
      fetchDriversByTCMatchingForRide,
      forceUpdateRide,
      clearDriversInfo,
      clearTCInfo,
      updateChangeRequestStatus,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(MHEditRide);
