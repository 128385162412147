import React from 'react';
import { Modal, Button } from '../..';
import styles from './index.module.css';

export const ConfirmChangePricingValuesModal = ({ type, handleConfirm, handleCancel}) => (
      <Modal visible={true} onCancel={handleCancel} closable width={500} 
             title="Confirmation"
      >
        <div className={styles.modalContent}>
          <p className={styles.modalDescription}>
          To confirm, would you like to update the billing details for this {type}? These changes will be applicable to all upcoming rides.
           </p>
          <div className={styles.modalBtns}>
            <div>
              <Button myClassName={styles.navigationBtn} onClick={handleConfirm}>
                OK
              </Button>
            </div>
            <div>
              <Button myClassName={styles.navigationBtn} onClick={handleCancel}>
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    );
