import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useLocation } from 'react-router';
import { FormikProvider, useFormik } from 'formik';
import { Logo, Button, Loader, Select, InputTextArea } from '../../../components';
import { riderCancelRide } from '../../../store/rides';
import { getLoading } from '../../../store/selectors/common';
import { CANCEL_RIDE_OPTIONS, REASONS_TO_EDIT_RIDE } from '../../../constants/constants';
import { commonCancelRideSchema } from '../../../utils/validations';
import styles from './index.module.css';

const initialValues = {cancelBy:'RIDER', cancelByreason: '', additionalComments: '' };

export const CancelRideByRider = () => {
  const { id } = useParams();
  const canceledByRider = useSelector((state) => state.rides.canceledByRider);
  const loading = useSelector((state) => getLoading(state));
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const getFeedback = query.get('getFeedback');

  const dispatch = useDispatch();

   const handleCancelRide = useCallback((values) => dispatch(riderCancelRide({id, payload : {reason : values?.reason,cancelBy:'RIDER', additionalComments: values?.additionalComments, cancelationType: findCancellationType(values?.reason) }})), [dispatch, id]);
  const findCancellationType = (reason)=>{

    const reasonObjArr = CANCEL_RIDE_OPTIONS["RIDER"].filter(r => r.value === reason);

    const cancelationType = Array.isArray(reasonObjArr) && reasonObjArr.length > 0 ? reasonObjArr[0]?.cancellationType : null;

    return cancelationType;

  }
 

  const formik = useFormik({
    initialValues,
    validationSchema: commonCancelRideSchema,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: handleCancelRide,
  });

  const { isValid, handleSubmit } = formik;

  const handleReasonForUpdate = useCallback(async ({ name, value, setFieldValue }) => {
 
    if (value !== REASONS_TO_EDIT_RIDE.CUSTOM_REASON) await setFieldValue('additionalComments', '');
    await setFieldValue(name, value);
  }, []);

  return (
    <div className={styles.cancelRidePage}>
      <Logo lg />
      {canceledByRider ? (
        <div className={styles.pageTitle}>
          <p className={styles.pageDescription}>Your ride was canceled successfully! Thank you!</p>
        </div>
      ) : (
        
          <div className={styles.formContainer}>
            <div className={styles.pageTitle}>
              <p className={styles.pageDescription}>
                If you are sure you want to cancel this ride, click the button below. If you would prefer to reschedule your
                ride, contact your healthcare provider.
              </p>
            </div>
            <FormikProvider value={formik}>
              <>
                <form className={styles.form}>
                  <label className={styles.cancelRideLabel}>
                    <span className={styles.required}>Reason you want to cancel this ride request?</span>
                    <Select name='reason' onChange={handleReasonForUpdate} options={CANCEL_RIDE_OPTIONS["RIDER"]} placeholder='Pick an option' />
                  </label>
               
                    <label className={styles.cancelRideLabel}>
                      <span>Additional Comments for ride Cancelation</span>
                      <InputTextArea
                        name='additionalComments'
                        placeholder='Please fill in reason for ride Cancelation'
                        type='text'
                        autosize={{ minRows: 1, maxRows: 5 }}
                      />
                    </label>
                 
                </form>
                <div className={styles.modalBtns}>
                  <div>
                    <Button onClick={handleSubmit} disabled={!isValid}>{getFeedback ? 'Update Cancellation Reason': 'Cancel Ride'}</Button>
                  </div>
                </div>
              </>
            </FormikProvider>
          </div>
      )}
      <Loader loading={loading} />
    </div>
  );
};
