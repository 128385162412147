import React from 'react';

export const RidesIcon = ({ fill }) => {
  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill={fill} xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M7.875 0.375H0.75C0.54375 0.375 0.375 0.54375 0.375 0.75V7.875C0.375 8.08125 0.54375 8.25 0.75 8.25H7.875C8.08125 8.25 8.25 8.08125 8.25 7.875V0.75C8.25 0.54375 8.08125 0.375 7.875 0.375ZM6.65625 6.65625H1.96875V1.96875H6.65625V6.65625ZM17.25 0.375H10.125C9.91875 0.375 9.75 0.54375 9.75 0.75V7.875C9.75 8.08125 9.91875 8.25 10.125 8.25H17.25C17.4562 8.25 17.625 8.08125 17.625 7.875V0.75C17.625 0.54375 17.4562 0.375 17.25 0.375ZM16.0312 6.65625H11.3438V1.96875H16.0312V6.65625ZM7.875 9.75H0.75C0.54375 9.75 0.375 9.91875 0.375 10.125V17.25C0.375 17.4562 0.54375 17.625 0.75 17.625H7.875C8.08125 17.625 8.25 17.4562 8.25 17.25V10.125C8.25 9.91875 8.08125 9.75 7.875 9.75ZM6.65625 16.0312H1.96875V11.3438H6.65625V16.0312ZM17.25 9.75H10.125C9.91875 9.75 9.75 9.91875 9.75 10.125V17.25C9.75 17.4562 9.91875 17.625 10.125 17.625H17.25C17.4562 17.625 17.625 17.4562 17.625 17.25V10.125C17.625 9.91875 17.4562 9.75 17.25 9.75ZM16.0312 16.0312H11.3438V11.3438H16.0312V16.0312Z'
        fill={fill}
      />
    </svg>
  );
};
