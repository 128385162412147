import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Tooltip } from '../Tooltip/Tooltip';

import styles from './index.module.css';

const SORTING_OPTIONS = {
  ASCENDING: '1',
  DESCENDING: '-1',
  DEFAULT: '0',
};

const sortingDictionary = ['Click to sort ascending', 'Click to sort descending', 'Click to cancel sorting'];

export const SortingPanel = ({ values, name, setFieldValue }) => {
  const [sortingType, setSortingType] = useState(SORTING_OPTIONS.DEFAULT);

  const [tooltipText, setTooltipText] = useState(sortingDictionary[0]);

  useEffect(() => {
    if (values.sortColumn !== name) {
      setSortingType(SORTING_OPTIONS.DEFAULT);
      setTooltipText(sortingDictionary[0]);
    }
    return;
  }, [values.sortColumn, name]);

  const handleChangeSorterSequency = async () => {
    if (sortingType === SORTING_OPTIONS.DEFAULT) {
      await setTooltipText(sortingDictionary[1]);
      await setSortingType(SORTING_OPTIONS.ASCENDING);
      await setFieldValue('sortColumn', name);
      return await setFieldValue('sortOrder', SORTING_OPTIONS.ASCENDING);
    }

    if (sortingType === SORTING_OPTIONS.ASCENDING) {
      await setTooltipText(sortingDictionary[2]);
      await setSortingType(SORTING_OPTIONS.DESCENDING);
      await setFieldValue('sortColumn', name);
      return await setFieldValue('sortOrder', SORTING_OPTIONS.DESCENDING);
    }
    if (sortingType === SORTING_OPTIONS.DESCENDING) {
      await setTooltipText(sortingDictionary[0]);
      await setSortingType(SORTING_OPTIONS.DEFAULT);
      await setFieldValue('sortColumn', undefined);
      return await setFieldValue('sortOrder', undefined);
    }
  };

  return (
    <Tooltip title={tooltipText} applyEditTooltipChildrenClassname={false}>
      <div className={styles.sorting} onClick={handleChangeSorterSequency} data-testid='sorting'>
        <CaretUpOutlined
          className={classNames({
            [styles.highlighted]: sortingType === SORTING_OPTIONS.ASCENDING,
          })}
        />
        <CaretDownOutlined
          className={classNames({
            [styles.highlighted]: sortingType === SORTING_OPTIONS.DESCENDING,
          })}
        />
      </div>
    </Tooltip>
  );
};
