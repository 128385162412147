import React, { useEffect } from 'react';

import { FormikProvider, useFormik } from 'formik';
import PropTypes from 'prop-types';

import { ButtonsPanel } from '../ButtonsPanel/ButtonsPanel';
import { stepperInfo } from '../stepperInfo';
import { StepTwoFormFields } from './StepTwoFormFields';

import styles from './index.module.css';

export const StepTwo = ({
  step,
  draft,
  handleSaveAndClose,
  handleSubmit,
  handlePreviousStep,
  platformPreferences,
  rideOptions,
  setFormikValues,
  showSubInformation = true,
  disabledValues,
}) => {
  const formik = useFormik({
    enableReinitialize: true,
    validateOnMount: true,
    initialValues:
      draft && draft.step2
        ? {
            ...draft.step2,
            weight: draft.step1?.riderInfo?.weight,
            height: draft.step1?.riderInfo?.height,
          }
        : stepperInfo[1].values,
    validationSchema: stepperInfo[1].schema(),
    onSubmit: handleSubmit,
  });

  const { values, isValid } = formik;

  useEffect(() => {
    setFormikValues && setFormikValues(values, isValid, "step2");
    return;
  }, [setFormikValues, values, isValid]);
  return (
    <>
      {showSubInformation && (
        <h2 className={styles.headTitle}>Step 2: Rider Vehicle Needs</h2>
      )}
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit} className={styles.form}>
          <StepTwoFormFields
            values={formik.values}
            rideOptions={rideOptions || []}
            minTimeFromRideRequestToPickup={
              platformPreferences?.minTimeFromRideRequestToPickup
            }
            disabledValues={disabledValues}
          />

          {showSubInformation && (
            <ButtonsPanel
              disabled={!formik.isValid}
              step={step}
              handlePreviousStep={handlePreviousStep}
              handleSaveAndClose={handleSaveAndClose}
              handleSubmit={handleSubmit}
            />
          )}
        </form>
      </FormikProvider>
    </>
  );
};

StepTwo.propTypes = {
  step: PropTypes.number,
  draft: PropTypes.object,
  handleSaveAndClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  handlePreviousStep: PropTypes.func,
  rideOptions: PropTypes.array,
};
