import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Record } from '../RideDetails';

import styles from '../index.module.css';

export const VehicleInfo = ({ vehicleType, status }) => (
  <div
    className={classNames({
      [styles.vehicleInfo]: true,
    })}>
    <Record title='Vehicle Type' value={vehicleType} myClassName={styles.vehicleType} />
  </div>
);

VehicleInfo.propTypes = {
  vehicleType: PropTypes.string,
};
