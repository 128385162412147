import React from 'react';

import { Input, MaskInput } from '../../';
import { DEFAULT_PHONE_NUMBER_FORMAT } from '../../../constants/constants';

import styles from './index.module.css';

export const CreateUser = ({ values, showPersonalInfo, disableEmail }) => {
  return (
    <>
      <div className={styles.name}>
        <label className={styles.nameLabel}>
          <span className={styles.required}>First Name</span>
          <Input name='firstName' type='text' placeholder='First Name' />
        </label>
        <label className={styles.nameLabel}>
          <span className={styles.required}>Last Name</span>
          <Input name='lastName' type='text' placeholder='Last Name' />
        </label>
      </div>
      {showPersonalInfo && (
        <div className={styles.personalInfo}>
          <label className={styles.personalInfoLabel}>
            <span className={styles.required}>Mobile Phone Number</span>
            <MaskInput
              name='phoneNumber'
              format={DEFAULT_PHONE_NUMBER_FORMAT}
              myClassName={styles.personalInfoLabel}
              placeholder={'###-###-####'}
              value={values.phoneNumber || ''}
              required
            />
          </label>
          <label className={styles.personalInfoLabel}>
            <span className={styles.required}>Job Title</span>
            <Input type='text' name='jobTitle' myClassName={styles.input} placeholder='Job title' required />
          </label>
        </div>
      )}
      <div className={styles.emailContainer}>
        <label className={styles.email}>
          <span className={styles.required}>Email</span>
          <Input name='email' type='text' placeholder='Email' disabled={disableEmail} />
        </label>
      </div>
    </>
  );
};
