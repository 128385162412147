import {
  stepFourRideSchema,
  stepOneRideSchema,
  stepThreeRideSchema,
  stepTwoRideSchema,
} from "../../../utils";

export const initialCaregiver = {
  firstName: "",
  lastName: "",
  phoneNumber: "",
  canPhoneReceiveTexts: false,
  canPhoneReceiveCalls: false,
};

export const stepperInfo = [
  {
    values: {
      covid19Relation: null,
      riderInfo: {
        firstName: "",
        lastName: "",
        communicationPreferences: [],
        preferredLanguage: "",
        phoneNumber: "",
        dob: "",
        patientUniqueId: "",
        canPhoneReceiveTexts: false,
        canPhoneReceiveCalls: false,
        homePhoneNumber: "",
      },
      formattedRiderName: "",
      willBeCaregiverOnScene: false,
      caregivers: [],
    },
    schema:(showCovidField) =>  stepOneRideSchema(showCovidField),
  },
  {
    values: {
      vehicleType: null,
      rideOptions: [],
    },
    schema:() => stepTwoRideSchema,
  },
  {
    values: {
      pickupAddressDetails: {
        formattedAddress: "",
        isPublicPlace: false,
        locationDescription: "",
      },
      riderDescription: "",
      destinationAddress: {
        isPublicPlace: false,
        formattedAddress: "",
      },
      pickUpSecurityGateDetails: {
        securityGateInstruction: "",
        hasSecurityGate: false,
      },
      destinationSecurityGateDetail: {
        securityGateInstruction: "",
        hasSecurityGate: false,
      },
      isRoundtrip: false,
      pickUpDate: {
        datetime: "",
        timezone: "",
      },
      appointmentDate: { datetime: "", timezone: "" },
      isRecurring: false,
      rideNotes: "",
      flightInfo: '',
    },
    schema: (validateReturnTime) => stepThreeRideSchema(validateReturnTime),
  },
  {
    values: {
      costCenter: "",
      additionalFundingSource: "",
      documentReasonForRide: [],
      otherReason: "",
    },
    schema:(costCenterField,
      showAdditionalSourceField,
      showDocumentReasonForRideField) => stepFourRideSchema(  costCenterField,
        showAdditionalSourceField,
        showDocumentReasonForRideField),
  },
  {
    values: {},
  },
];

export const initialDraft = {
  step1: stepperInfo[0].values,
  step2: stepperInfo[1].values,
  step3: stepperInfo[2].values,
  step4: stepperInfo[3].values,
};
