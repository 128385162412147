import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { checkRiderForMatching, clearRidersInfo } from '../store/riders';

import { LodashHelper } from '../utils';
import { usePrevious } from './usePrevious';

export const getUniqueUserFieldValues = (values) =>
  values && {
    firstName: values.firstName,
    lastName: values.lastName,
    dob: values.dob,
  };

export const useCheckRiderForMatching = (currentValues, allowCheck) => {
  const dispatch = useDispatch();

  const previousValues = usePrevious(currentValues);

  const delayedQuery = useRef(
    LodashHelper.debounce((value) => {
      return dispatch(checkRiderForMatching(value));
    }, 1000),
  ).current;

  useEffect(() => {
    if (
      allowCheck &&
      ((currentValues?.firstName && currentValues?.lastName) ||
        (currentValues?.firstName && currentValues?.lastName && currentValues?.dob)) &&
      (previousValues?.firstName !== currentValues?.firstName ||
        previousValues?.lastName !== currentValues?.lastName ||
        previousValues?.dob !== currentValues?.dob)
    ) {
      delayedQuery(currentValues);
    }
    return;
  }, [allowCheck, delayedQuery, dispatch, previousValues, currentValues]);

  useEffect(() => () => dispatch(clearRidersInfo()), [dispatch]);
};
