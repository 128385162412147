import { useCallback, useEffect, useMemo, useState } from 'react';
import { getIn } from 'formik';
import { useDispatch } from 'react-redux';
 
import { DateHelper } from '../../../../utils';
import { datePickerFieldsNames } from '../../../../utils/helpers';

import { resetWindowOfTimeValidation } from '../../../../store/rides';
import { useLocation } from 'react-router-dom';

export const useDateTimeHandlers = ({
  validateForm,
  setFieldTouched,
  defaultValues,
  minDelayToStartRide,
  maxPickUpWindowOfTime,
  minimumRoundTrip
}) => {
  const location = useLocation();
  const [pickUpDate, setPickUpDate] = useState(null);

  const [returnTimeDate, setReturnTimeDate] = useState(null);

  const [startPointOfRecurring, setStartPointOfRecurring] = useState(null);

  const [returnStartPoint, setReturnStartPoint] = useState(null);

  const [pickUpDateLowerLimit, setPickUpDateLowerLimit] = useState(null);
  const [returnTimeLowerLimit, setReturnTimeLowerLimit] = useState(null);
  
  const [appointmentDate, setAppointmentDate] = useState(null);
  
  const dispatch = useDispatch();

  useEffect(() => {
    if (!pickUpDate) {
      setReturnTimeDate(null);
      setReturnTimeLowerLimit(null);
    }
    return;
  }, [pickUpDate]);

  const getDefaultValuesForDateTime = useCallback(
    (fieldName) => {
      console.log(fieldName);
      return (
        (getIn(defaultValues, fieldName)?.datetime &&
          DateHelper.utcToZonedTime(
            getIn(defaultValues, fieldName).datetime,
            getIn(defaultValues, fieldName)?.timezone,
          )) ||
        null
      );
    },
    [defaultValues],
  );

  useEffect(() => {
    setPickUpDate(getDefaultValuesForDateTime(datePickerFieldsNames.pickUpDate.title));
    setReturnTimeDate(getDefaultValuesForDateTime(datePickerFieldsNames.returnTime.title));
    setStartPointOfRecurring(getDefaultValuesForDateTime(datePickerFieldsNames.startPoint.title));
    setReturnStartPoint(getDefaultValuesForDateTime(datePickerFieldsNames.returnStartPoint.title));
     setAppointmentDate(getDefaultValuesForDateTime(datePickerFieldsNames.appointmentDate.title));
    
    defaultValues?.isPickUpDateFlexible &&
      setPickUpDateLowerLimit(getDefaultValuesForDateTime(datePickerFieldsNames.pickUpDateLowerLimit.title));
    defaultValues?.isReturnDateFlexible &&
      setReturnTimeLowerLimit(getDefaultValuesForDateTime(datePickerFieldsNames.returnTimeLowerLimit.title));

      return;
  }, [defaultValues, getDefaultValuesForDateTime]);

  const handleUseWindowOfTime = useCallback(
    async ({ name, values, checked, setFieldValue }) => {
      await setFieldValue('isRecurring', false);
      await setFieldValue('recurrenceRule', undefined);

      if (name === 'isPickUpDateFlexible') {
        await setFieldValue('pickUpDateLowerLimit', undefined);
        await setFieldValue('pickUpDate.datetime', '');
        await setPickUpDate(null);
        await setPickUpDateLowerLimit(null);
      } else if (name === 'isReturnDateFlexible') {
        await setFieldValue('returnTimeLowerLimit', undefined);
        await setFieldValue('returnTime.datetime', '');
        await setReturnTimeDate(null);
        await setReturnTimeLowerLimit(null);
      }
      await setFieldValue(name, checked);

      if (!checked && (!returnTimeLowerLimit || !pickUpDateLowerLimit)) {
        dispatch(resetWindowOfTimeValidation());
      }
      await validateForm();
    },
    [  validateForm, returnTimeLowerLimit, pickUpDateLowerLimit],
  );

  const handleChangeappointmentDate = async ({
    value,
    setFieldValue,
    values,
  }) => {
    if (value === null) {
      await setPickUpDate(null);
      await setPickUpDateLowerLimit(null);
     await setAppointmentDate(null);
      await setFieldValue(datePickerFieldsNames.appointmentDate.datetime, "");
      return await setFieldValue(
        datePickerFieldsNames.appointmentDate.datetime,
        ""
      );
    }
    const minDate = DateHelper.addDates(
      DateHelper.utcToZonedTime(Date.now(), values.appointmentDate.timezone),
      minDelayToStartRide
    );
    let datetime, pickupDateTimeValue;
    pickupDateTimeValue = DateHelper.isBefore(value, minDate) ? minDate : value;
    datetime = DateHelper.zonedTimeToUtcISOS(
      pickupDateTimeValue,
      values?.appointmentDate?.timezone
    );
    await setAppointmentDate(pickupDateTimeValue);
    await setFieldValue(
      datePickerFieldsNames.appointmentDate.datetime,
      datetime
    );
    await setFieldValue(
      datePickerFieldsNames.appointmentDate.timezone,
      values?.appointmentDate?.timezone
    );
    await validateForm();
  };

  const handlePickUpDateTime = useCallback(
    async ({ value, setFieldValue, values }) => {
      
      const query = new URLSearchParams(location.search);
      const editSeries = query.get('mode');
 
      if (value === null) {
        await setPickUpDate(null);
        return await setFieldValue(datePickerFieldsNames.pickUpDate.datetime, '');
      }

      const minDate =
        pickUpDateLowerLimit ||
        DateHelper.addDates(DateHelper.utcToZonedTime(Date.now(), values.pickUpDate.timezone), minDelayToStartRide);

      const allowedpickupDateTimeValue = DateHelper.isBefore(value, minDate)
        ? minDate
        : values?.isPickUpDateFlexible && DateHelper.isAfter(value, DateHelper.addDates(minDate, maxPickUpWindowOfTime))
        ? DateHelper.addDates(minDate, maxPickUpWindowOfTime)
        : value;

      const datetime = DateHelper.zonedTimeToUtcISOS(allowedpickupDateTimeValue, values?.pickUpDate?.timezone);

      await setPickUpDate(allowedpickupDateTimeValue);
      //await setAppointmentDate(values?.appointmentDate);
      await setFieldValue(datePickerFieldsNames.pickUpDate.datetime, datetime);

      if (values?.isRoundtrip) {
        await setReturnTimeDate(null);
        await setFieldValue(datePickerFieldsNames.returnTime.datetime, undefined);
      }

      if (values?.isRecurring && !editSeries) {
        await setStartPointOfRecurring(allowedpickupDateTimeValue);
        await setReturnStartPoint(null);
        await setFieldValue(datePickerFieldsNames.startPoint.datetime, datetime);
      }

      await validateForm();
    },
    [minDelayToStartRide, pickUpDateLowerLimit, maxPickUpWindowOfTime, validateForm],
  );

  const handleChangePickUpDateToLowerLimit = async ({ value, setFieldValue, values }) => {
    if (value === null) {
      await setPickUpDate(null);
      await setPickUpDateLowerLimit(null);
      await setFieldValue(datePickerFieldsNames.pickUpDate.datetime, '');
      return await setFieldValue(datePickerFieldsNames.pickUpDateLowerLimit.datetime, '');
    }

    const minDate = DateHelper.addDates(
      DateHelper.utcToZonedTime(Date.now(), values.pickUpDate.timezone),
      minDelayToStartRide,
    );
    let datetime, pickupDateTimeValue;

    pickupDateTimeValue = DateHelper.isBefore(value, minDate) ? minDate : value;

    const selectedDateIsOutOfInterval = !DateHelper.isWithinInterval(pickUpDate, {
      start: value,
      end: DateHelper.addDates(value, maxPickUpWindowOfTime),
    });

    if (pickUpDate && selectedDateIsOutOfInterval) {
      await setPickUpDate(null);
      await setFieldValue(datePickerFieldsNames.pickUpDate.datetime, '');
      await setPickUpDateLowerLimit(pickupDateTimeValue);
      return await setFieldValue(
        datePickerFieldsNames.pickUpDateLowerLimit.datetime,
        DateHelper.zonedTimeToUtcISOS(minDate, values?.pickUpDate?.timezone),
      );
    }

    datetime = DateHelper.zonedTimeToUtcISOS(pickupDateTimeValue, values?.pickUpDate?.timezone);
    await setPickUpDateLowerLimit(pickupDateTimeValue);
    await setFieldValue(datePickerFieldsNames.pickUpDateLowerLimit.datetime, datetime);
    await setFieldValue(datePickerFieldsNames.pickUpDateLowerLimit.timezone, values?.pickUpDate?.timezone);
    await validateForm();
  };

  const handleReturnTime = useCallback(
    async ({ value, setFieldValue, values }) => {
      if (value === null) {
        await setReturnTimeDate(null);
        return await setFieldValue(datePickerFieldsNames.returnTime.datetime, '');
      }

      const minDate = returnTimeLowerLimit || DateHelper.addDates(pickUpDate, minimumRoundTrip);

      const allowedpickupDateTimeValue = DateHelper.isBefore(value, minDate)
        ? minDate
        : values?.isReturnDateFlexible && DateHelper.isAfter(value, DateHelper.addDates(minDate, maxPickUpWindowOfTime))
        ? DateHelper.addDates(minDate, maxPickUpWindowOfTime)
        : value;

      const datetime = DateHelper.zonedTimeToUtcISOS(allowedpickupDateTimeValue, values?.returnTime?.timezone);
      await setReturnTimeDate(allowedpickupDateTimeValue);
      await setReturnStartPoint(allowedpickupDateTimeValue);
      await setFieldValue(datePickerFieldsNames.returnTime.datetime, datetime);
      if (values?.isRecurring) {
        await setFieldValue(datePickerFieldsNames.returnStartPoint.datetime, datetime);
      }
      await validateForm();
    },
    [pickUpDate, returnTimeLowerLimit, maxPickUpWindowOfTime, validateForm,minimumRoundTrip],
  );

  const handleReturnTimeLowerLimit = async ({ value, setFieldValue, values }) => {
    if (value === null) {
      await setReturnTimeDate(null);
      await setReturnTimeLowerLimit(null);
      await setFieldValue(datePickerFieldsNames.returnTime.datetime, '');
      return await setFieldValue(datePickerFieldsNames.returnTimeLowerLimit.datetime, '');
    }

    const selectedDateIsOutOfInterval = !DateHelper.isWithinInterval(returnTimeDate, {
      start: value,
      end: DateHelper.addDates(value, maxPickUpWindowOfTime),
    });

    let minDate, datetime, returnDateTimeValue;

    minDate = DateHelper.addDates(pickUpDate, minimumRoundTrip);
    returnDateTimeValue = DateHelper.isBefore(value, minDate) ? minDate : value;

    if (returnTimeDate && selectedDateIsOutOfInterval) {
      await setReturnTimeDate(null);
      await setFieldValue(datePickerFieldsNames.returnTime.datetime, '');
      await setReturnTimeLowerLimit(returnDateTimeValue);
      return await setFieldValue(
        datePickerFieldsNames.returnTimeLowerLimit.datetime,
        DateHelper.zonedTimeToUtcISOS(minDate, values?.returnTime?.timezone),
      );
    }

    if (minDate && value) {
      datetime = DateHelper.zonedTimeToUtcISOS(returnDateTimeValue, values?.returnTime?.timezone);
      await setReturnTimeLowerLimit(returnDateTimeValue);
      await setFieldValue(datePickerFieldsNames.returnTimeLowerLimit.datetime, datetime);
      await setFieldValue(datePickerFieldsNames.returnTimeLowerLimit.timezone, values?.returnTime?.timezone);
    }
    await validateForm();
  };

  const handleStartPointTime = useCallback(
    async ({ value, setFieldValue, values }) => {
      const minDate = DateHelper.addDates(pickUpDate, minimumRoundTrip);
      const datetime = DateHelper.zonedTimeToUtcISOS(
        DateHelper.compareDates(minDate, value) ? minDate : value,
        values?.recurrenceRule?.startPoint?.timezone,
      );
      const pickupDateTimeValue = DateHelper.compareDates(minDate, value) ? minDate : value;
      await setStartPointOfRecurring(pickupDateTimeValue);
      await setReturnStartPoint(null);
      await setFieldValue(datePickerFieldsNames.startPoint.datetime, datetime);

      if (values?.isRoundtrip) {
        await setFieldValue(
          datePickerFieldsNames.returnStartPoint.timezone,
          values?.recurrenceRule?.returnStartPoint?.timezone,
        );
        await setFieldValue(datePickerFieldsNames.returnStartPoint.datetime, undefined);
      }
      await validateForm();
    },
    [pickUpDate, validateForm,minimumRoundTrip],
  );

  const handleReturnStartPoint = useCallback(
    async ({ value, setFieldValue, values }) => {
      const minDate = DateHelper.addDates(startPointOfRecurring, minimumRoundTrip);

      const datetime = DateHelper.zonedTimeToUtcISOS(
        DateHelper.compareDates(minDate, value) ? minDate : value,
        values?.recurrenceRule?.returnStartPoint.timezone,
      );
      await setReturnStartPoint(DateHelper.compareDates(minDate, value) ? minDate : value);
      await setFieldValue(datePickerFieldsNames.returnStartPoint.datetime, datetime);
      await validateForm();
    },
    [startPointOfRecurring, validateForm,minimumRoundTrip],
  );

  const handleRoundTripCheckbox = useCallback(
    async ({ name, checked, values, setValues }) => {
      const copiedValues = { ...values };
      const returnTimeValue = defaultValues?.returnTime
        ? DateHelper.utcToZonedTime(defaultValues?.returnTime?.datetime, defaultValues?.returnTime?.timezone)
        : null;

      if (checked) {
        copiedValues.returnTime = { timezone: copiedValues?.pickUpDate?.timezone };
        if (values?.isRecurring && copiedValues.recurrenceRule) {
          copiedValues.recurrenceRule.returnStartPoint = {
            timezone: copiedValues?.pickUpDate?.timezone || '',
          };
        }

        setReturnTimeDate(null);
      } else {
        delete copiedValues.returnTime;
        if (values?.isRecurring && copiedValues?.recurrenceRule) {
          delete copiedValues?.recurrenceRule?.returnStartPoint;
        }
        if (values?.isReturnDateFlexible) {
          copiedValues.isReturnDateFlexible = false;
          delete copiedValues.returnTimeLowerLimit;
        }

        await setReturnTimeDate(returnTimeValue);
        await setReturnTimeLowerLimit(returnTimeValue);
        await setReturnStartPoint(returnTimeValue);
      }

      await setValues({ ...copiedValues, [name]: checked });
      await validateForm();
    },
    [defaultValues?.returnTime, validateForm],
  );

  const handleAppointmentDateCheckBox =()=>  {
       setAppointmentDate(null);
       
  }
  
  
  const disabledDate = useCallback(
    (fieldName, minDate) => (current) => {


      if (
        fieldName === datePickerFieldsNames.pickUpDate.title ||
        fieldName === datePickerFieldsNames.startPoint.title
      ) {
        return DateHelper.isBefore(current, minDate) && !DateHelper.isTheSameDay(current, minDate);
       }else   if (
        fieldName === datePickerFieldsNames.appointmentDate.title 
      ) {
        return DateHelper.isBefore(current, minDate) && !DateHelper.isTheSameDay(current, minDate);
      }
       else if (fieldName === datePickerFieldsNames.returnTime.title && minDate) {
        return DateHelper.isBefore(current, minDate) && !DateHelper.isTheSameDay(current, minDate);
      } else if (fieldName === datePickerFieldsNames.returnStartPoint.title && startPointOfRecurring) {
        return current && current.valueOf() < DateHelper.getStartOfTheDay(startPointOfRecurring);
      }
    },
    [startPointOfRecurring],
  );

  const disabledDatesForPickUpDate = useCallback(
    (minDate) => disabledDate(datePickerFieldsNames.pickUpDate.title, minDate),
    [disabledDate],
  );

  const disabledDatesForAppointment = useCallback(


    (minDate) => disabledDate(datePickerFieldsNames.appointmentDate.title, minDate),
    [disabledDate],
  );

  const disabledDatesForReturnTime = useCallback(
    (minDate) => disabledDate(datePickerFieldsNames.returnTime.title, minDate),
    [disabledDate],
  );

  const disabledDatesForStartPointOfRecurring = useMemo(
    () => disabledDate(datePickerFieldsNames.startPoint.title),
    [disabledDate],
  );

  const disabledDatesForReturnTimeForRoundTripRecurring = useMemo(
    () => disabledDate(datePickerFieldsNames.returnStartPoint.title),
    [disabledDate],
  );

  const disabledDateForHigherLimit = (d) => {
    if (pickUpDateLowerLimit) {
      const maxDate = DateHelper.addDates(pickUpDateLowerLimit, maxPickUpWindowOfTime);
      const minDate = pickUpDateLowerLimit;

      return (
        (minDate != null && DateHelper.isBefore(d, minDate) && !DateHelper.isTheSameDay(d, minDate)) ||
        (maxDate != null && DateHelper.isAfter(d, maxDate) && !DateHelper.isTheSameDay(d, maxDate))
      );
    }
  };

  const disabledDateForReturnHigherLimit = (minAvailableDate) => (d) => {
    if (returnTimeLowerLimit) {
      const maxDate = DateHelper.addDates(returnTimeLowerLimit, maxPickUpWindowOfTime);
      const minDate = DateHelper.isWithinInterval(d, { start: returnTimeLowerLimit, end: maxDate })
        ? returnTimeLowerLimit
        : minAvailableDate;

      return (
        (minDate != null && DateHelper.isBefore(d, minDate) && !DateHelper.isTheSameDay(d, minDate)) ||
        (maxDate != null && DateHelper.isAfter(d, maxDate) && !DateHelper.isTheSameDay(d, maxDate))
      );
    }
  };

  const disabledDateTimeForReturnHigherLimit = (currentDate) => {
    const startDate = returnTimeLowerLimit;
    const maxDate = DateHelper.addDates(startDate, maxPickUpWindowOfTime);

    return DateHelper.disabledDateTimeForReturnHigherLimit({ currentDate, startDate, maxDate });
  };

  const disabledTimeForHigherLimit = useCallback(
    (currentDate) => {
      const startDate = pickUpDateLowerLimit;
      const maxDate = DateHelper.addDates(startDate, maxPickUpWindowOfTime);

      return DateHelper.disabledTimeForHigherLimit({ currentDate, startDate, maxDate });
    },
    [maxPickUpWindowOfTime, pickUpDateLowerLimit],
  );

  const disabledDateTime = useCallback(
    (minDate, currentValue) => () => {
      const isTheSameDay = DateHelper.isTheSameDay(currentValue, minDate);
      const isTheSameHour = DateHelper.isTheSameHour(currentValue, minDate);

      if (!currentValue) {
        return {
          disabledHours: () => DateHelper.getRange(0, 24),
          disabledMinutes: () => DateHelper.getRange(0, 60),
        };
      }

      return {
        disabledHours: () =>
          isTheSameDay ? DateHelper.getRange(0, 24).splice(0, DateHelper.getHoursNumber(minDate)) : [],
        disabledMinutes: () => (isTheSameHour ? DateHelper.getRange(0, DateHelper.getMinutesNumber(minDate)) : []),
      };
    },
    [],
  );

  const handleChangeTZ = useCallback(
    async ({ setFieldValue, name, value, values }) => {
      switch (name) {
        case datePickerFieldsNames.pickUpDate.timezone:
        case datePickerFieldsNames.returnTime.timezone:
        case datePickerFieldsNames.appointmentDate.timezone:
          await setFieldValue(datePickerFieldsNames.pickUpDate.timezone, value);
          await setFieldValue(datePickerFieldsNames.returnTime.timezone, value);
        await setFieldValue(datePickerFieldsNames.appointmentDate.timezone, value);
          await setFieldValue(datePickerFieldsNames.pickUpDate.datetime, "");
          // await setFieldValue(
          //   datePickerFieldsNames.appointmentDate.datetime,
          //   ""
          // );
         //await setAppointmentDate("");
          await setFieldValue(
            datePickerFieldsNames.pickUpDateLowerLimit.datetime,
            ""
          );
          await setFieldValue(datePickerFieldsNames.returnTime.datetime, "");
          await setFieldValue(
            datePickerFieldsNames.returnTimeLowerLimit.datetime,
            ""
          );

          await setPickUpDate(null);
          await setPickUpDateLowerLimit(null);

          await setReturnTimeDate(null);
          await setReturnTimeLowerLimit(null);

          if (values?.isRecurring) {
            await setFieldValue(
              datePickerFieldsNames.startPoint.timezone,
              value
            );
            await setFieldValue(datePickerFieldsNames.startPoint.datetime, "");

            if (values?.isRoundtrip) {
              await setFieldValue(
                datePickerFieldsNames.returnStartPoint.timezone,
                value
              );
              await setFieldValue(
                datePickerFieldsNames.returnStartPoint.datetime,
                ""
              );
            }
          }

          await setStartPointOfRecurring(null);
          await setReturnStartPoint(null);
          break;

        case datePickerFieldsNames.startPoint.timezone:
          await setFieldValue(name, value);
          await setFieldValue(datePickerFieldsNames.startPoint.datetime, "");
          await setFieldTouched(
            datePickerFieldsNames.startPoint.datetime,
            true
          );
          await setStartPointOfRecurring(null);
          break;

        case datePickerFieldsNames.returnStartPoint.timezone:
          await setFieldValue(name, value);
          await setFieldValue(
            datePickerFieldsNames.returnStartPoint.datetime,
            ""
          );
          await setFieldTouched(
            datePickerFieldsNames.returnStartPoint.datetime,
            true
          );
          await setReturnStartPoint(null);
          break;

        case datePickerFieldsNames.appointmentDate.timezone:
          await setFieldValue(name, value);
          // await setFieldValue(datePickerFieldsNames.pickUpDate.timezone, value);
          // await setFieldValue(datePickerFieldsNames.returnTime.timezone, value);
          // await setFieldValue(
          //   datePickerFieldsNames.appointmentDate.datetime,
          //   ""
          //);
          await setAppointmentDate(null);
          await setFieldTouched(
            datePickerFieldsNames.appointmentDate.datetime,
            true
          );
          await setReturnStartPoint(null);
          break;

        default:
          break;
      }
      await validateForm();
    },
    [setFieldTouched, validateForm],
  );

  const handleReccuringCheckbox = useCallback(
    async ({ name, checked, setFieldValue, values }) => {
      await setFieldValue(name, checked);

      if (!checked) {
        await setFieldValue('recurrenceRule', undefined);
        await setStartPointOfRecurring(null);
        await setReturnStartPoint(null);
      } else if (checked) {
        await setFieldValue(datePickerFieldsNames.startPoint.datetime, values?.pickUpDate?.datetime);
        await setFieldValue(datePickerFieldsNames.startPoint.timezone, values?.pickUpDate?.timezone);
        await setStartPointOfRecurring(pickUpDate);
        if (values?.isRoundtrip) {
          await setFieldValue(datePickerFieldsNames.returnStartPoint.datetime, values?.returnTime?.datetime);
          await setFieldValue(datePickerFieldsNames.returnStartPoint.timezone, values?.returnTime?.timezone);
          await setReturnStartPoint(returnTimeDate);
        }
      }
      await validateForm();
    },
    [validateForm, pickUpDate, returnTimeDate],
  );

  return {
    handlePickUpDateTime,
    handleReturnTime,
    handleStartPointTime,
    handleReturnStartPoint,
    handleRoundTripCheckbox,
    disabledDatesForPickUpDate,
    disabledDatesForAppointment,
    disabledDatesForReturnTime,
    disabledDatesForStartPointOfRecurring,
    disabledDatesForReturnTimeForRoundTripRecurring,
    disabledDateTime,
    handleChangeTZ,
    pickUpDate,
    returnTimeDate,
    startPointOfRecurring,
    returnStartPoint,
    setStartPointOfRecurring,
    setReturnStartPoint,
    handleReccuringCheckbox,
    handleUseWindowOfTime,
    disabledDateForHigherLimit,
    disabledTimeForHigherLimit,
    handleChangePickUpDateToLowerLimit,
    pickUpDateLowerLimit,
    returnTimeLowerLimit,
    handleReturnTimeLowerLimit,
    disabledDateForReturnHigherLimit,
    disabledDateTimeForReturnHigherLimit,
    handleChangeappointmentDate,
    appointmentDate,
    handleAppointmentDateCheckBox,
  };
};
