import { IMPAIRMENTS_TYPES, languages, RELATIONSHIP_TYPES, USER_ROLES, RIDE_STATUSES, BILL_STATUS } from '../constants/constants';
import { convertConstantsToDictionary } from '../utils/helpers';

export const statusesDictionary = {
  [RIDE_STATUSES.PENDING]: 'Pending',
  [RIDE_STATUSES.IN_PROGRESS]: 'In progress',
  [RIDE_STATUSES.ACCEPTED]: 'Accepted',
  [BILL_STATUS.value]: BILL_STATUS.title,
  [RIDE_STATUSES.COMPLETED]: 'Completed',
  [RIDE_STATUSES.CANCELED]: 'Canceled',
  [RIDE_STATUSES.UNABLE_TO_FULFILL]: 'Unable to fulfill',
  [RIDE_STATUSES.CLAIMED]: 'Claimed',
  [RIDE_STATUSES.DECLINED]: 'Declined',
  [RIDE_STATUSES.EXPIRED]: 'Expired',
   [RIDE_STATUSES.RESCHEDULED]: 'Rescheduled',
  

  [RIDE_STATUSES.NEW_REQUEST]: 'New request',
  [RIDE_STATUSES.UNFULFILLED]: 'Unfulfilled',
};

export const USER_ROLES_DICTIONARY = {
  [USER_ROLES.SUPER_ADMIN]: 'Admin',
  [USER_ROLES.HO_ADMIN]: 'Admin',
  [USER_ROLES.TC_ADMIN]: 'Admin',
  [USER_ROLES.TC_EMPLOYEE]: 'User',
  [USER_ROLES.HO_EMPLOYEE]: 'User',
  [USER_ROLES.DRIVER]: 'Driver',
};

export const timezonesDictionary = {
  'America/Denver': 'MT',
  'America/Chicago': 'CT',
  'America/New_York': 'ET',
  'America/Los_Angeles': 'PT',
};

export const vehicleOptionsDictionary = {
  ambulatoryAssistRiderIsWalking:
    "Ambulatory Assist - Rider is able to walk and needs driver's assistance (Door-through-Door)",
  ambulatoryRiderIsWalking: 'Ambulatory - Rider is able to walk & does NOT need assistance',
  stretcherBoundBariatric: 'Stretcher Bound - Bariatric (over 250 lbs)',
  stretcherBoundNonBariatric: 'Stretcher Bound - Non-Bariatric',
  wheelchairBoundBariatric: 'Wheelchair Bound - Bariatric (over 250 lbs)',
  wheelchairBoundNonBariatric: 'Wheelchair Bound - Non-Bariatric',
};



export const rideRequestFieldsDictionary = {
  covid19Relation: 'COVID-19',
  costCenter: 'Cost Center',
  additionalFundingSource: 'Additional funding source',
  documentReasonForRide: 'Document reason for the ride',
  otherReason: 'Other',
};

export const VIEW_DASHBOARD_LOGIN_TYPES_DICTIONARY = [USER_ROLES.HO_ADMIN, USER_ROLES.TC_ADMIN];

export const genderDictionary = {
  MALE: 'Male',
  FEMALE: 'Female',
  OTHER: 'Other',
  DECLINE_TO_SAY: 'Decline to say',
};

export const prefferedLanguageDictionary = convertConstantsToDictionary(languages);

export const communicationTypesDictionary = {
  PHONE_CALL: 'Phone Call',
  TEXT_SMS: 'Text SMS',
  EMAIL: 'Email',
};

export const impairmentsTypesDictionary = convertConstantsToDictionary(IMPAIRMENTS_TYPES);

export const relationshipToRiderDictionary = convertConstantsToDictionary(RELATIONSHIP_TYPES);
