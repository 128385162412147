import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { DatePicker, Select } from '../..';
import { TIMEZONES } from '../../../constants/constants';
import { DateHelper } from '../../../utils';
import styles from './index.module.css';

const customTimePickerPanel = (originalPanel) => (
  <>
    <div className={styles.panelRender}>
      <div>Hours</div>
      <div>Daylight</div>
    </div>
    {originalPanel}
  </>
);

const scheduledRidesReminderDay = {
  DAY_AHEAD: 'DAY_AHEAD',
  SAME_DAY: 'SAME_DAY',
};

const scheduledRidesReminderDayOptions = [
  {
    title: 'On the previous day',
    value: scheduledRidesReminderDay.DAY_AHEAD,
  },
  {
    title: 'On the day of the ride',
    value: scheduledRidesReminderDay.SAME_DAY,
  },
];
export const DailyReminderNotification = ({ values, disabled }) => {
  const [timePickerDate, setTimePickerDate] = useState(null);

  useEffect(() => {
    values?.scheduledRidesReminderHour &&
      setTimePickerDate(new Date(new Date().setHours(values?.scheduledRidesReminderHour)));
  }, [values?.scheduledRidesReminderHour]);

  const disabledTime = useCallback(() => {
    if (values?.scheduledRidesReminderDay === scheduledRidesReminderDay.DAY_AHEAD)
      return {
        disabledHours: () => DateHelper.getRange(0, 24).splice(0, 16),
      };
    else if (values?.scheduledRidesReminderDay === scheduledRidesReminderDay.SAME_DAY)
      return { disabledHours: () => DateHelper.getRange(0, 24).splice(10, 24) };
  }, [values?.scheduledRidesReminderDay]);

  const handleChangeTimePicker = async ({ setFieldValue, name, value, validateForm }) => {
    const minDateForDayAhead = new Date(new Date().setHours(16));

    const isSelectedDateAllowed = DateHelper.compareDates(value, new Date(new Date().setHours(16)));
    if (values?.scheduledRidesReminderDay === scheduledRidesReminderDay.DAY_AHEAD && isSelectedDateAllowed) {
      await setFieldValue(name, new Date(value).getHours());
      await setTimePickerDate(value);
    } else if (values?.scheduledRidesReminderDay === scheduledRidesReminderDay.DAY_AHEAD) {
      await setFieldValue(name, minDateForDayAhead.getHours());
      await setTimePickerDate(minDateForDayAhead);
    } else {
      await setFieldValue(name, value.getHours());
      await setTimePickerDate(value);
    }

    await validateForm();
  };

  const handleChangeScheduledRidesReminderDay = async ({ name, value, setFieldValue, validateForm }) => {
    await setFieldValue(name, value);
    await setFieldValue('scheduledRidesReminderHour', null);
    await setTimePickerDate(null);
    await validateForm();
  };
  return (
    <>
      <span
        className={classNames({
          [styles.dailyReminderLabel]: true,
          [styles.required]: true,
        })}>
        Daily Reminder for Scheduled Rides (select time for the email notification)
      </span>
      <div className={styles.dailyReminder}>
        <label className={styles.minimumTimeToSubmitTitle}>
          <span className={styles.required}>Timezone</span>
          <Select name='preferredTimezone' options={TIMEZONES} placeholder={'Pick one'} disabled={disabled} />
        </label>
        <label className={styles.minimumTimeToSubmitTitle}>
          <span className={styles.required}>Day</span>
          <Select
            name='scheduledRidesReminderDay'
            options={scheduledRidesReminderDayOptions}
            onChange={handleChangeScheduledRidesReminderDay}
            myClassName={styles.scheduledRidesReminderDay}
            placeholder='Pick one'
            disabled={disabled}
          />
        </label>
        <label className={styles.minimumTimeToSubmitTitle}>
          <span className={styles.required}>Time</span>
          <DatePicker
            name='scheduledRidesReminderHour'
            format={'hh aaa'}
            myClassName={styles.customTimepicker}
            onChange={handleChangeTimePicker}
            picker={'time'}
            value={timePickerDate}
            use12Hours
            showNow={false}
            disabledTime={disabledTime}
            inputReadOnly
            placeholder='Pick time'
            panelRender={customTimePickerPanel}
            disabled={!values?.scheduledRidesReminderDay || disabled}
          />
        </label>
      </div>
    </>
  );
};
