import React from 'react';

import { Modal, Select, Button } from '../..';

import styles from './index.module.css';

export const SetNewAdminModal = ({ visible, onCancel, title, isValid, handleSubmit, users }) => {
  return (
    <Modal visible={visible} onCancel={onCancel} closable width={580} title={title}>
      <div className={styles.modalContent}>
        <p className={styles.modalDescription}>
          You are able to select a new admin for this organization from existing users. If the user you would like to
          choose does not show up below in the dropdown, make sure you have first invited the user to this organization
          and that the user has accepted the invitation
        </p>
        <form className={styles.modalForm} onSubmit={handleSubmit}>
          <div className={styles.selectUser}>
            Select user
            <Select name='admin' options={users} getPopupContainer={() => document.body} />
          </div>

          <div className={styles.modalBtns}>
            <div>
              <Button myClassName={styles.navigationBtn} onClick={onCancel}>
                Cancel
              </Button>
            </div>
            <div>
              <Button myClassName={styles.navigationBtn} type='submit' disabled={!isValid}>
                Switch Admin
              </Button>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};
