import React from 'react';
import { Spin } from 'antd';
import PropTypes from 'prop-types';

import styles from './index.module.css';

export const Loader = ({ size, loading }) =>
  loading && (
    <div className={styles.spinnerWrapper} role='progressbar'>
      <Spin size={size || 'large'} />
    </div>
  );

Loader.propTypes = {
  size: PropTypes.string,
  loading: PropTypes.bool,
};
