import { showNotificationMessage } from '../components';
import { ERROR_TYPES, URLS } from '../constants/constants';
import { baseRequest, renderRecordValue } from '../utils';
import { getPage } from '../utils/helpers';
import { logOut } from './authorization';
import { types as commonTypes } from './common';
 
export const types = {
  CREATE_TRANSPORT_ORGANIZATION_REQUEST: 'CREATE_TRANSPORT_ORGANIZATION_REQUEST',
  CREATE_TRANSPORT_ORGANIZATION_SUCCESS: 'CREATE_TRANSPORT_ORGANIZATION_SUCCESS',
  CREATE_TRANSPORT_ORGANIZATION_ERROR: 'CREATE_TRANSPORT_ORGANIZATION_ERROR',

  UPDATE_TRANSPORT_ORGANIZATION_REQUEST: 'UPDATE_TRANSPORT_ORGANIZATION_REQUEST',
  UPDATE_TRANSPORT_ORGANIZATION_SUCCESS: 'UPDATE_TRANSPORT_ORGANIZATION_REQUEST',
  UPDATE_TRANSPORT_ORGANIZATION_ERROR: 'UPDATE_TRANSPORT_ORGANIZATION_ERROR',

  
  FETCH_TCCONFIG_REQUEST: 'FETCH_TCCONFIG_REQUEST',
  FETCH_TCCONFIG_SUCCESS: 'FETCH_TCCONFIG_SUCCESS',
  FETCH_TCCONFIG_ERROR: 'FETCH_TCCONFIG_ERROR',

  DISABLE_TRANSPORT_ORGANIZATION_REQUEST: 'DISABLE_TRANSPORT_ORGANIZATION_REQUEST',
  DISABLE_TRANSPORT_ORGANIZATION_SUCCESS: 'DISABLE_TRANSPORT_ORGANIZATION_SUCCESS',
  DISABLE_TRANSPORT_ORGANIZATION_ERROR: 'DISABLE_TRANSPORT_ORGANIZATION_ERROR',

  FETCH_ALL_TRANSPORT_ORGANIZATIONS_REQUEST: 'FETCH_ALL_TRANSPORT_ORGANIZATIONS_REQUEST',
  FETCH_ALL_TRANSPORT_ORGANIZATIONS_SUCCESS: 'FETCH_ALL_TRANSPORT_ORGANIZATIONS_SUCCESS',
  FETCH_ALL_TRANSPORT_ORGANIZATIONS_ERROR: 'FETCH_ALL_TRANSPORT_ORGANIZATIONS_ERROR',

  FETCH_TRANSPORT_ORGANIZATIONS_REQUEST: 'FETCH_TRANSPORT_ORGANIZATIONS_REQUEST',
  FETCH_TRANSPORT_ORGANIZATIONS_SUCCESS: 'FETCH_TRANSPORT_ORGANIZATIONS_SUCCESS',
  FETCH_TRANSPORT_ORGANIZATIONS_ERROR: 'FETCH_TRANSPORT_ORGANIZATIONS_ERROR',

  FETCH_TRANSPORT_ORGANIZATION_REQUEST: 'FETCH_TRANSPORT_ORGANIZATION_REQUEST',
  FETCH_TRANSPORT_ORGANIZATION_SUCCESS: 'FETCH_TRANSPORT_ORGANIZATION_SUCCESS',
  FETCH_TRANSPORT_ORGANIZATION_ERROR: 'FETCH_TRANSPORT_ORGANIZATION_ERROR',

  FETCH_TRANSPORT_ORGANIZATION_ADMIN_PREFERENCES_REQUEST: 'FETCH_TRANSPORT_ORGANIZATION_ADMIN_PREFERENCES_REQUEST',
  FETCH_TRANSPORT_ORGANIZATION_ADMIN_PREFERENCES_SUCCESS: 'FETCH_TRANSPORT_ORGANIZATION_ADMIN_PREFERENCES_SUCCESS',
  FETCH_TRANSPORT_ORGANIZATION_ADMIN_PREFERENCES_ERROR: 'FETCH_TRANSPORT_ORGANIZATION_ADMIN_PREFERENCES_ERROR',

  TO_ADMIN_UPDATE_OWN_ORGANIZATION_REQUEST: 'TO_ADMIN_UPDATE_OWN_ORGANIZATION_REQUEST',
  TO_ADMIN_UPDATE_OWN_ORGANIZATION_SUCCESS: 'TO_ADMIN_UPDATE_OWN_ORGANIZATION_SUCCESS',
  TO_ADMIN_UPDATE_OWN_ORGANIZATION_ERROR: 'TO_ADMIN_UPDATE_OWN_ORGANIZATION_ERROR',

  MH_SWITCH_TC_ADMIN_REQUEST: 'MH_SWITCH_TC_ADMIN_REQUEST',
  MH_SWITCH_TC_ADMIN_SUCCESS: 'MH_SWITCH_TC_ADMIN_SUCCESS',
  MH_SWITCH_TC_ADMIN_ERROR: 'MH_SWITCH_TC_ADMIN_ERROR',

  MH_INVITE_NEW_USER_FOR_TC_REQUEST: 'MH_INVITE_NEW_USER_FOR_TC_REQUEST',
  MH_INVITE_NEW_USER_FOR_TC_SUCCESS: 'MH_INVITE_NEW_USER_FOR_TC_SUCCESS',
  MH_INVITE_NEW_USER_FOR_TC_ERROR: 'MH_INVITE_NEW_USER_FOR_TC_ERROR',

  FETCH_TRANSPORT_ORGANIZATIONS_MATCHING_FOR_RIDE_REQUEST: 'FETCH_TRANSPORT_ORGANIZATIONS_MATCHING_FOR_RIDE_REQUEST',
  FETCH_TRANSPORT_ORGANIZATIONS_MATCHING_FOR_RIDE_SUCCESS: 'FETCH_TRANSPORT_ORGANIZATIONS_MATCHING_FOR_RIDE_SUCCESS',
  FETCH_TRANSPORT_ORGANIZATIONS_MATCHING_FOR_RIDE_ERROR: 'FETCH_TRANSPORT_ORGANIZATIONS_MATCHING_FOR_RIDE_ERROR',

  CLEAR_TC_INFO: 'CLEAR_TC_INFO',

  SET_CURRENT_PAGE_TO: 'SET_CURRENT_PAGE_TO',

  FETCH_TC_PRICING_REQUEST: "FETCH_TC_PRICING_REQUEST",
  FETCH_TC_PRICING_SUCCESS: "FETCH_TC_PRICING_SUCCESS",
  FETCH_TC_PRICING_ERROR: "FETCH_TC_PRICING_ERROR",

  SET_TC_PRICING_REQUEST: "SET_TC_PRICING_REQUEST",
  SET_TC_PRICING_SUCCESS: "SET_TC_PRICING_SUCCESS",
  SET_TC_PRICING_ERROR: "SET_TC_PRICING_ERROR",
};

export const fetchTCPricingRequest = () => ({
  type: types.FETCH_TC_PRICING_REQUEST,
});

export const SetTCPricingRequest = () => ({
  type: types.SET_TC_PRICING_REQUEST,
});

export const fetchTransportCompaniesMatchingForRideRequest = () => ({
  type: types.FETCH_TRANSPORT_ORGANIZATIONS_MATCHING_FOR_RIDE_REQUEST,
});

export const clearTCInfo = () => ({
  type: types.CLEAR_TC_INFO,
});

const createTORequest = () => ({
  type: types.CREATE_TRANSPORT_ORGANIZATION_REQUEST,
});

const updateTORequest = () => ({
  type: types.UPDATE_TRANSPORT_ORGANIZATION_REQUEST,
});

const disableTORequest = () => ({
  type: types.DISABLE_TRANSPORT_ORGANIZATION_REQUEST,
});

const fetchAllTOsRequest = () => ({
  type: types.FETCH_ALL_TRANSPORT_ORGANIZATIONS_REQUEST,
});

const fetchTOsRequest = () => ({
  type: types.FETCH_TRANSPORT_ORGANIZATIONS_REQUEST,
});

const fetchTORequest = () => ({
  type: types.FETCH_TRANSPORT_ORGANIZATION_REQUEST,
});

const fetchTOAdminPreferencesRequest = () => ({
  type: types.FETCH_TRANSPORT_ORGANIZATION_ADMIN_PREFERENCES_REQUEST,
});

const ToAdminUpdateOwnOrganizationRequest = () => ({
  type: types.TO_ADMIN_UPDATE_OWN_ORGANIZATION_REQUEST,
});

export const setCurrentPage = (page) => ({
  type: types.SET_CURRENT_PAGE_TO,
  payload: page,
});

export const switchTCAdminRequest = () => ({
  type: types.MH_SWITCH_TC_ADMIN_REQUEST,
});

const inviteNewUserforTcRequest = () => ({
  type: types.MH_INVITE_NEW_USER_FOR_TC_REQUEST,
});


const fetchTCConfigurationRequest = () => ({
  type: types.FETCH_TCCONFIG_REQUEST,
});

export const fetchTCConfiguration=(id)=>
  {
    const success = (payload) => ({
      type: types.FETCH_TCCONFIG_SUCCESS,
      payload,
    });
  
    const failure = (error) => ({
      type: types.FETCH_TCCONFIG_ERROR,
      payload: error,
    });
  
    return (dispatch) => {

      console.log("dispatching rodle",id)
      dispatch(fetchTCConfigurationRequest());
      return baseRequest('GET', `/transport-companies/configuration/${id}`)
        .then((response) => {
          dispatch(success(response.data));
        })
        .catch((error) => {
          if (error) dispatch(failure());
        });
    };
  }

export const fetchAllTransportOrganizations = () => {
  const success = (payload) => ({
    type: types.FETCH_ALL_TRANSPORT_ORGANIZATIONS_SUCCESS,
    payload,
  });

  const failure = (error) => ({
    type: types.FETCH_ALL_TRANSPORT_ORGANIZATIONS_ERROR,
    payload: error,
  });

  return (dispatch) => {
    dispatch(fetchAllTOsRequest());
    return baseRequest('GET', `/transport-companies/list`)
      .then((response) => {
        dispatch(success(response.data));
      })
      .catch((error) => {
        if (error) dispatch(failure([]));
      });
  };
};

export const fetchTransportOrganizations = ({value, filtered,Navigation}) => {
const navigate = Navigation;
  const success = (payload) => ({
    type: types.FETCH_TRANSPORT_ORGANIZATIONS_SUCCESS,
    payload,
  });

  const failure = (error) => ({
    type: types.FETCH_TRANSPORT_ORGANIZATIONS_ERROR,
    payload: error,
  });

  return (dispatch, getStore) => {
     
    if (filtered) navigate.push(URLS.TRANSPORT_ORGANIZATIONS);
    const { limit } = getStore().transportOrganizations;
    const currentPage = getPage();
    dispatch(setCurrentPage(currentPage));
    dispatch(fetchTOsRequest());
    return baseRequest(
      'GET',
      `/transport-companies`,
      null,
      {},
      { docsPerPage: limit, page: currentPage, value: value || '' },
    )
      .then((response) => {
        dispatch(success(response.data));
      })
      .catch((error) => {
        if (error) dispatch(failure([]));
      });
  };
};

export const fetchTransportOrganization = (id) => {
  const success = (payload) => ({
    type: types.FETCH_TRANSPORT_ORGANIZATION_SUCCESS,
    payload,
  });

  const failure = (error) => ({
    type: types.FETCH_TRANSPORT_ORGANIZATION_ERROR,
    payload: error,
  });

  return (dispatch) => {
    dispatch(fetchTORequest());
    return baseRequest('GET', `/transport-companies/${id}`)
      .then((response) => {
        dispatch(success(response.data));
      })
      .catch((error) => {
        if (error) dispatch(failure(error?.response?.data));
      });
  };
};

export const fetchTOAdminPreferences = () => {
  const success = (payload) => ({
    type: types.FETCH_TRANSPORT_ORGANIZATION_ADMIN_PREFERENCES_SUCCESS,
    payload,
  });

  const failure = (error) => ({
    type: types.FETCH_TRANSPORT_ORGANIZATION_ADMIN_PREFERENCES_ERROR,
    payload: error,
  });

  return (dispatch) => {
    dispatch(fetchTOAdminPreferencesRequest());
    return baseRequest('GET', '/transport-companies/my')
      .then((response) => {
        console.log("transportnprefrence",response.data);
        dispatch(success(response.data));
      })
      .catch((error) => {
        if (error) dispatch(failure(error?.response?.data));
      });
  };
};

export const createTO = (values,Navigation) => {
  const navigate =Navigation;
  const success = (payload) => ({
    type: types.CREATE_TRANSPORT_ORGANIZATION_SUCCESS,
    payload,
  });

  const failure = (error) => ({
    type: types.CREATE_TRANSPORT_ORGANIZATION_ERROR,
    payload: error,
  });

  return (dispatch) => {
    dispatch(createTORequest());
    return baseRequest('POST', '/transport-companies', values)
      .then((response) => {
        
        dispatch(success(response.data));
        navigate.push(URLS.TRANSPORT_ORGANIZATIONS);
      })
      .catch((error) => {
        if (error) dispatch(failure(error?.response?.data));
      });
  };
};

export const updateTO = ({ values, id ,Navigation}) => {
  const navigate =Navigation;
  const success = (payload) => ({
    type: types.UPDATE_TRANSPORT_ORGANIZATION_SUCCESS,
    payload,
  });

  const failure = (error) => ({
    type: types.UPDATE_TRANSPORT_ORGANIZATION_ERROR,
    payload: error,
  });

  return (dispatch) => {
    dispatch(updateTORequest());
    return baseRequest('PUT', `/transport-companies/${id}`, values)
      .then((response) => {
        
        dispatch(success(response.data));
        navigate.push(URLS.TRANSPORT_ORGANIZATIONS);
      })
      .catch((error) => {
        if (error) dispatch(failure(error?.response?.data));
      });
  };
};

export const disableTO = ({ id, disabled,Navigation }) => {
  const navigate =Navigation;
  const success = (payload) => ({
    type: types.DISABLE_TRANSPORT_ORGANIZATION_SUCCESS,
    payload,
  });

  const failure = (error) => ({
    type: types.DISABLE_TRANSPORT_ORGANIZATION_ERROR,
    payload: error,
  });

  return (dispatch) => {
    dispatch(disableTORequest());
    return baseRequest('PATCH', `/transport-companies/${id}/disable`, { disabled })
      .then((response) => { 
        dispatch(success({ id, disabled }));
        navigate.push(URLS.TRANSPORT_ORGANIZATIONS);
      })
      .catch((error) => {
        if (error) dispatch(failure(error?.response?.data));
      });
  };
};

export const TOAdminUpdateOwnOrganization = (payload) => {
  const success = (payload) => ({
    type: types.TO_ADMIN_UPDATE_OWN_ORGANIZATION_SUCCESS,
    payload,
  });

  const failure = (error) => ({
    type: types.TO_ADMIN_UPDATE_OWN_ORGANIZATION_ERROR,
    payload: error,
  });

  return (dispatch) => {
    dispatch(ToAdminUpdateOwnOrganizationRequest());
    return baseRequest('PUT', '/transport-companies/my', payload)
      .then((response) => {
        dispatch(success(response.data));
      })
      .catch((error) => {
        if (error) dispatch(failure(error?.response?.data));
      });
  };
};

export const switchTCAdmin = ({ organizationId, userId, closeModal }) => {
  const success = (payload) => ({
    type: types.MH_SWITCH_TC_ADMIN_SUCCESS,
    payload,
  });

  const failure = (error) => ({
    type: types.MH_SWITCH_TC_ADMIN_ERROR,
    payload: error,
  });

  return (dispatch) => {
    dispatch(switchTCAdminRequest());
    return baseRequest('PATCH', `/transport-companies/${organizationId}/admin/${userId}`)
      .then((response) => {
        dispatch(success(response.data));
        closeModal();
      })
      .catch((error) => {
        if (error) dispatch(failure(error?.response?.data));
      });
  };
};

export const switchAdminForCurrentOrganization = ({ userId, confirmCallback }) => {
  const success = (payload) => ({
    type: types.MH_SWITCH_TC_ADMIN_SUCCESS,
    payload,
  });

  const failure = (error) => ({
    type: types.MH_SWITCH_TC_ADMIN_ERROR,
    payload: error,
  });

  return (dispatch) => {
    dispatch(switchTCAdminRequest());
    return baseRequest('PATCH', `/transport-companies/my/replace-admin/${userId}`)
      .then((response) => {
        dispatch(success(response.data));
        if (confirmCallback) confirmCallback();
        dispatch(logOut());
        showNotificationMessage({
          type: 'error',
          content: renderRecordValue(ERROR_TYPES.ROLE_CHANGED),
        });
      })
      .catch((error) => {
        if (error) dispatch(failure(error?.response?.data));
      });
  };
};

export const inviteNewUserForTc = ({ organizationId, values, closeModal }) => {
  const success = (payload) => ({
    type: types.MH_INVITE_NEW_USER_FOR_TC_SUCCESS,
    payload,
  });

  const failure = (error) => ({
    type: types.MH_INVITE_NEW_USER_FOR_TC_ERROR,
    payload: error,
  });

  return (dispatch) => {
    dispatch(inviteNewUserforTcRequest());
    return baseRequest('POST', `/transport-companies/${organizationId}/invite`, values)
      .then((response) => {
        dispatch(success(response.data));
        closeModal();
      })
      .catch((error) => {
        if (error) dispatch(failure(error?.response?.data));
      });
  };
};

export const fetchTransportCompaniesMatchingForRide = (rideId) => {
  const success = (payload) => ({
    type: types.FETCH_TRANSPORT_ORGANIZATIONS_MATCHING_FOR_RIDE_SUCCESS,
    payload,
  });

  const failure = (error) => ({
    type: types.FETCH_TRANSPORT_ORGANIZATIONS_MATCHING_FOR_RIDE_ERROR,
    payload: error,
  });

  return (dispatch) => {
    dispatch(fetchTransportCompaniesMatchingForRideRequest());
    return baseRequest('GET', `/ride-requests/${rideId}/matching-companies`)
      .then((response) => {
        dispatch(success(response.data));
      })
      .catch((error) => {
        if (error) dispatch(failure(error?.response?.data));
      });
  };
};

export const fetchTransportCompanyPricing = (payload) => {
  
  const success = (payload) =>{ 
   
    return({
    type: types.FETCH_TC_PRICING_SUCCESS,
    payload,
  })};

  const failure = (error) => ({
    type: types.FETCH_TC_PRICING_ERROR,
    payload: error,
  });
  return (dispatch) => {
    dispatch(fetchTCPricingRequest());

    return baseRequest("GET", `/tc-pricing/organization`, null, {}, payload)
      .then((response) => {
        dispatch(success(response.data));
      })

      .catch((error) => {
        if (error) dispatch(failure(error));
      });
  };
};

export const postTransportCompanyPricing = (values) => {
  const success = (payload) => ({
    type: types.SET_TC_PRICING_SUCCESS,
    payload,
  });

  const failure = (error) => ({
    type: types.SET_TC_PRICING_ERROR,
    payload: error,
  });

  return (dispatch) => {
    dispatch(SetTCPricingRequest());

    return baseRequest("POST", `/tc-pricing`, values)
      .then((response) => {
        dispatch(success(response?.data));
        //dispatch(fetchTOAdminPreferences());
      })
      .catch((error) => {
        if (error) dispatch(failure(error?.response?.data));
      });
  };
};

const initialState = {
  TCPreferences: null,
  currentTransportOrganization: null,
  transportOrganizations: [], 
  loading: false,
  error: false,
  success: false,
  limit: 10,
  currentPage: 1,
  totalPages: 1,
  total: 1,
  TCPricing: null,
  configuration:null,
};

export default function transportOrganizations(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_TCCONFIG_REQUEST:
      return {
        ...state,
        loading: true,
      
      };
      case types.FETCH_TCCONFIG_SUCCESS:
      return {
        ...state,
        loading: false,
        configuration:action.payload
      };
      case types.FETCH_TCCONFIG_ERROR:
        return {
          ...state,
          loading: false,
          configuration: null
        };
    case types.CREATE_TRANSPORT_ORGANIZATION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.CREATE_TRANSPORT_ORGANIZATION_SUCCESS:
      return {
        ...state,
        transportOrganizations: [...state.transportOrganizations, action.payload],
        loading: false,
      };

    case types.CREATE_TRANSPORT_ORGANIZATION_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.UPDATE_TRANSPORT_ORGANIZATION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.UPDATE_TRANSPORT_ORGANIZATION_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case types.UPDATE_TRANSPORT_ORGANIZATION_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.DISABLE_TRANSPORT_ORGANIZATION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.DISABLE_TRANSPORT_ORGANIZATION_SUCCESS:
      return {
        ...state,
        loading: false,
        transportOrganizations: state.transportOrganizations.filter((to) => {
          if (to.id === action.payload.id) {
            to.disabled = action.payload.disabled;
          }
          return to;
        }),
      };

    case types.DISABLE_TRANSPORT_ORGANIZATION_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.FETCH_TRANSPORT_ORGANIZATIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.FETCH_TRANSPORT_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        transportOrganizations: action.payload?.data,
        currentPage: action.payload?.meta?.page,
        totalPages: action.payload?.meta?.totalPages,
        total: action.payload?.meta?.total,
      };

    case types.FETCH_TRANSPORT_ORGANIZATIONS_ERROR:
      return {
        ...state,
        loading: false,
        transportOrganizations: action.payload,
        currentPage: 1,
        totalPages: 1,
        total: 1,
      };

    case types.FETCH_TRANSPORT_ORGANIZATION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.FETCH_TRANSPORT_ORGANIZATION_SUCCESS:
      return {
        ...state,
        loading: false,
        currentTransportOrganization: action.payload,
      };

    case types.FETCH_TRANSPORT_ORGANIZATION_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.FETCH_TRANSPORT_ORGANIZATION_ADMIN_PREFERENCES_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.FETCH_TRANSPORT_ORGANIZATION_ADMIN_PREFERENCES_SUCCESS:
      return {
        ...state,
        TCPreferences: action.payload,
        TCPricing: action.payload?.tcPricing[0], 
        loading: false,
      };

    case types.FETCH_TRANSPORT_ORGANIZATION_ADMIN_PREFERENCES_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.TO_ADMIN_UPDATE_OWN_ORGANIZATION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.TO_ADMIN_UPDATE_OWN_ORGANIZATION_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case types.TO_ADMIN_UPDATE_OWN_ORGANIZATION_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.SET_CURRENT_PAGE_TO:
      return {
        ...state,
        currentPage: action.payload,
      };

    case types.MH_SWITCH_TC_ADMIN_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.MH_SWITCH_TC_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        currentTransportOrganization: action.payload,
      };

    case types.MH_SWITCH_TC_ADMIN_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.MH_INVITE_NEW_USER_FOR_TC_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.MH_INVITE_NEW_USER_FOR_TC_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case types.MH_INVITE_NEW_USER_FOR_TC_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.FETCH_ALL_TRANSPORT_ORGANIZATIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.FETCH_ALL_TRANSPORT_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        transportOrganizations: action.payload,
      };

    case types.FETCH_ALL_TRANSPORT_ORGANIZATIONS_ERROR:
      return {
        ...state,
        loading: false,
        transportOrganizations: [],
      };

    case types.FETCH_TRANSPORT_ORGANIZATIONS_MATCHING_FOR_RIDE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.FETCH_TRANSPORT_ORGANIZATIONS_MATCHING_FOR_RIDE_SUCCESS:
      return {
        ...state,
        loading: false,
        transportOrganizations: action.payload,
      };

    case types.FETCH_TRANSPORT_ORGANIZATIONS_MATCHING_FOR_RIDE_ERROR:
      return {
        ...state,
        loading: false,
        transportOrganizations: [],
      };

    case types.CLEAR_TC_INFO:
      return {
        ...state,
        transportOrganizations: [],
        TCPricing: null,
      };

    case commonTypes.CLEAR_STORE_INFO:
      return {
        ...initialState,
        currentPage: 1,
      };

      case types.FETCH_TC_PRICING_REQUEST:
      return {
        ...state,
        loading: true,
      };

      case types.FETCH_TC_PRICING_SUCCESS:
      return {
        ...state,
        loading: false,
        TCPricing: action.payload,
      };

    case types.FETCH_TC_PRICING_ERROR:
      return {
        ...state,
        loading: false,
      };

      case types.SET_TC_PRICING_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.SET_TC_PRICING_SUCCESS:
      return {
        ...state,
        loading: false,
        TCPricing: action.payload,
      };

    case types.SET_TC_PRICING_ERROR:
      return {
        ...state,
        loading: false,
        
      };

    default:
      return state;
  }
}
