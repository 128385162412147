import React from 'react';
import classnames from 'classnames';

import { FilterCheckboxListCell, FilterRangeCell, FilterSearchCell } from '../../../components';
import { statusesDictionary, vehicleOptionsDictionary } from '../../../dictionaries';
import { renderRecordValue } from '../../../utils';

import { VEHICLE_OPTIONS, STATUSES_BY_ROLE, USER_ROLES } from '../../../constants/constants';
import { FilterAutoCompleteCell } from '../../../components/common/FilterPanel/FiltersCells/FilterAutoCompleteCell/FilterAutoCompleteCell';

import styles from './index.module.css';

export const renderHeadCells = ({ setFieldValue, transportOrganizations, healthOrganizations, values, onClose }) => {

  
  return [
    {
      title: 'Organization',
      dataIndex: 'healthOrganizationName',
      key: 'healthOrganizationName',
      width: 180,
      Component: (
        <FilterCheckboxListCell
          name='healthOrganizations'
          title='Organization'
          values={values}
          setFieldValue={setFieldValue}
          value={renderRecordValue(values?.healthOrganizations?.[0]?.name)}
          myClassName={styles.organizationCell}
          options={healthOrganizations}
          onClose={onClose}
        />
      ),
    },

    {
      title: 'Transport Co.',
      dataIndex: 'transportCompanyName',
      key: 'transportCompanyName',
      width: 180,
      Component: (
        <FilterCheckboxListCell
          name='transportCompanies'
          title='Transport Co.'
          values={values}
          setFieldValue={setFieldValue}
          value={renderRecordValue(values?.transportCompanies?.[0]?.name)}
          myClassName={styles.organizationCell}
          options={transportOrganizations}
          onClose={onClose}
        />
      ),
    },
    {
      title: 'Ride ID',
      dataIndex: 'seqId',
      key: 'seqId',
      width: 130,
      Component: (
        <FilterSearchCell
          name='seqId'
          placeholder='Enter Ride ID'
          title='Ride ID'
          values={values}
          setFieldValue={setFieldValue}
          myClassName={styles.draftIdCell}
          onClose={onClose}
        />
      ),
    },

    {
      title: 'Rider Name',
      dataIndex: 'rider',
      key: 'rider',
      width: 150,
      Component: (
        <FilterAutoCompleteCell
          formattedName='formattedRiderName'
          name='rider'
          title='Rider Name'
          placeholder='Enter Rider Name'
          values={values}
          setFieldValue={setFieldValue}
          myClassName={styles.riderCell}
          onClose={onClose}
        />
      ),
    },
    {
      title: 'Vehicle Type',
      dataIndex: 'vehicleType',
      key: 'vehicleType',
      width: 250,
      Component: (
        <FilterCheckboxListCell
          name='vehicleType'
          title='Vehicle Type'
          values={values}
          myClassName={styles.vehicleCell}
          value={renderRecordValue(vehicleOptionsDictionary?.[values?.vehicleType?.[0]])}
          setFieldValue={setFieldValue}
          options={VEHICLE_OPTIONS}
          onClose={onClose}
        />
      ),
    },

    {
      title: 'Pickup Date',
      dataIndex: 'pickUpDate',
      key: 'pickUpDate',
      width: 200,
      Component: (
        <FilterRangeCell
          name='pickUpDate'
          title='Pickup Date'
          values={values}
          setFieldValue={setFieldValue}
          myClassName={styles.pickUpDateCell}
          onClose={onClose}
        />
      ),
    },

    {
      title: 'Ride Status',
      dataIndex: 'readableStatus',
      key: 'readableStatus',
      width: 150,
      Component: (
        <FilterCheckboxListCell
          name='status'
          title='Ride Status'
          values={values}
          myClassName={styles.statusCell}
          options={STATUSES_BY_ROLE[USER_ROLES.SUPER_ADMIN]}
          value={renderRecordValue(statusesDictionary[values?.status?.[0]])}
          setFieldValue={setFieldValue}
          onClose={onClose}
        />
      ),
    },
    {
      title: 'Recurring',
      dataIndex: 'recurrenceIndex',
      key: 'recurrenceIndex',
      width: 120,
      Component: (
        <th
          className={classnames({
            'ant-table-cell': true,
            [styles.recurringCell]: true,
          })}>
          <div
            className={classnames({
              [styles.recurringCell]: true,
            })}>
            Recurring
          </div>
        </th>
      ),
    },
  ];
};
