
import styles from '../index.module.css';
import { OverNightIcon } from '../../../../assets/svgs/OverNightIcon';

export const OverNightRide = () => {
  return (
    <p className={styles.mb10}>

      <span className={styles.phoneNumber}>
        {/* <span className={styles.recordTitle}>{"Type:"}</span> */}
        <div className={styles.d_contnent}  >
 <span className={styles.m5}  >
            <OverNightIcon width={'20px'} height={'20px'}></OverNightIcon>
          </span>
          {"Overnight Ride"}
           </div>

      </span>
    </p>
  );
};

