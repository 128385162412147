import React, { useCallback, useEffect } from 'react';
import { Popover } from 'antd';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { SortingPanel } from '../../..';

import styles from './index.module.css';

export const FilterDropDown = ({
  visible,
  onVisibleChange,
  children,
  content,
  applyStyles,
  showDateRange,
  shouldRenderSorting = true,
  values,
  name,
  setFieldValue,
}) => {
  const onHorizontalScroll = useCallback(
    (e) => {
      if (e.currentTarget.scrollLeft > 10) {
        onVisibleChange(false);
      }
    },
    [onVisibleChange],
  );
  useEffect(() => {
    const table = document.querySelector('.ant-table-body');

    if (table) {
      table?.addEventListener('scroll', onHorizontalScroll);
    }
    return () => table?.removeEventListener('sroll', onHorizontalScroll);
  }, [onHorizontalScroll]);

  return (
    <div className={styles.filterRow}>
      <Popover
        open={visible}
        placement='bottomLeft'
        trigger='click'
        destroyTooltipOnHide={false}
        onOpenChange={onVisibleChange}
        content={content}
        overlayClassName={classNames({
          [styles.customPopOver]: true,
          [styles.popOver]: applyStyles,
          [styles.overlay]: applyStyles,
          [styles.dateRangeVisible]: showDateRange,
        })}
        getPopupContainer={() => document.querySelector('.ant-table')}>
        <div className={styles.filter}>{children}</div>
      </Popover>
      {shouldRenderSorting && <SortingPanel values={values} name={name} setFieldValue={setFieldValue} />}
    </div>
  );
};

FilterDropDown.propTypes = {
  visible: PropTypes.bool,
  onVisibleChange: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  content: PropTypes.node,
  applyStyles: PropTypes.bool,
  showDateRange: PropTypes.bool,
};
