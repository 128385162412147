import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useParams } from 'react-router';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'; 
import { getDraft, clearCurrentDraft, setDraftId, deleteDraft } from '../../../store/drafts';
import { transformDraftToRide } from '../../../utils/';
import { RideDetails, useDeleteRestoreModal } from '../../../components';
import { BackIcon } from '../../../assets';
import { URLS } from '../../../constants/constants';
import { RideActionsPanel } from '../../../components/common/RideActionsPanel/RideActionsPanel';

import styles from './index.module.css';
import { Navigation } from '../../../utils/navigation';

const DraftInfo = ({ setDraftId, getDraft, currentDraft, clearCurrentDraft,  deleteDraft }) => {
  const { id } = useParams();
  const navigate = Navigation();
  const handleConfirmDeleteDraft = useCallback(
    () => deleteDraft({ draftId: currentDraft?.id, pathname: URLS.DRAFTS,Navigation:navigate }),
    [currentDraft?.id, deleteDraft],
  );
  const { Modal, setDeleteRestoreModalVisible: setDeleteModalVisible } = useDeleteRestoreModal({
    handleConfrim: handleConfirmDeleteDraft,
    title: `Deleting Draft`,
    description: (
      <>
        Are you sure you want to delete this draft (<span className={styles.bold}>ID {currentDraft?.seqId}</span>)?
        <br />
        It will be removed permanently.
      </>
    ),
    confirmActionButtonText: 'Delete Draft',
  });
  useEffect(() => {
    getDraft(id);
    return;
  }, [getDraft, id]);

  useEffect(() => () => clearCurrentDraft(), [clearCurrentDraft]);

  const handleBack = useCallback(() => navigate.back(), [navigate]);

  const handleEditDraft = useCallback(() => {
    setDraftId(id);
    navigate.push(URLS.EDIT_DRAFT,{ from: navigate?.location.pathname });
  }, [navigate, id, setDraftId]);

  const handleDeleteDraft = useCallback(() => setDeleteModalVisible(true), [setDeleteModalVisible]);

  return (
    <div className={styles.pageContent}>
      <div className={styles.header}>
        <div className={styles.backBtn} onClick={handleBack}>
          <BackIcon width='18px' height='18px' fill='#BDBDBD' />
        </div>
        <h1 className={styles.pageTitle}>Draft Details - ID {currentDraft?.seqId}</h1>
      </div>
      <div className={styles.rideDetails}>
        <RideDetails values={transformDraftToRide(currentDraft)} showFullInfo />
        <RideActionsPanel
          entities={[
            { id: 0, Icon: EditOutlined, action: handleEditDraft },
            { id: 1, Icon: DeleteOutlined, action: handleDeleteDraft },
          ]}
        />
        {Modal}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentDraft: state.drafts.draft,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getDraft, clearCurrentDraft, setDraftId, deleteDraft }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DraftInfo);
