import React from 'react';

import { Modal, Button } from '../..';
import { renderRecordValue } from '../../../utils';

import styles from './index.module.css';

export const DisableOrganizationModal = ({ name, visible, onCancel, onConfirm }) => {
  return (
    <Modal visible={visible} onCancel={onCancel} closable width={670} title='Deactivate Organization'>
      <div className={styles.modalContent}>
        <p className={styles.modalDescription}>
          Do you want to deactivate {<span className={styles.name}>{renderRecordValue(name)}</span>}? They will not be
          able to access the system, though you can always restore their profile
        </p>
        <div className={styles.disableOrganizationBtns}>
          <div>
            <Button myClassName={styles.navigationBtn} onClick={onCancel}>
              Cancel
            </Button>
          </div>
          <div>
            <Button myClassName={styles.navigationBtn} onClick={onConfirm}>
              Confirm deactivation
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
