import React from "react";
import { Input } from "../../formControls/Input/Input";
import { Switcher } from "../../formControls/Switcher/Switcher";
import styles from "./index.module.css";

export const renderInputField = (
  fieldName,
  placeholder,
  label,
  switchValue,
  onChange,
  colHeader
) => (
  <div
    className={styles.renderField}
  >
    <label
    className={styles.labelRidecost}
    >
      {label}
    </label>
    <div className={styles.renderTimeinput}>
      {colHeader && (
        <label className={styles.colHeader}>{colHeader}</label>
      )}

<div className={styles.inputPlaceHolder} >
         <div className={styles.inputfield} >
        <Input
         prefix={'$'}
          name={fieldName}
          type="number"
          min={0}
          precision={2}
          step={0.01}
          disabled={!switchValue}
        />
       
    </div>
    <div className={styles.renderTimeinput}>
      <Switcher
        name={`${placeholder}.enabled`}
        onChange={onChange}
        value={switchValue}
      />
      </div>
      </div>
    </div>

  </div>
);

export const renderInputFieldRideCost = (fieldName,  label, colHeader) => (
  <div
    className={styles.renderField}
  >
    <label   className={styles.labelRidecost}>
      {label}
    </label>
    <div
    className={styles.renderTimeinput}
   >
      {colHeader && (
        <label className={styles.measurement}>{colHeader}</label>
      )}
        <div className={styles.inputfield} >
        <Input
        prefix={'$'}
          name= {`${fieldName}.miles0_25`}
          type="number"
          min={0}
          precision={2}
          step={0.01}
        />
      </div>
    </div>
  </div>
);

export const renderInputFieldRideCostTC = (fieldName,  label, colHeader) => (
  <div
    className={styles.renderField}
  >
    <label   className={styles.labelRidecost}>
      {label}
    </label>
    <div
    className={styles.renderTimeinput}
   >
      {colHeader && (
        <label className={styles.measurement}>{colHeader}</label>
      )}
        <div className={styles.inputfield} >
        <Input
        prefix={'$'}
          name= {`${fieldName}.fee`}
          type="number"
          min={0}
          precision={2}
          step={0.01}
        />
      </div>
    </div>
  </div>
);
