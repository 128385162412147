import React from 'react';

export const DraftsIcon = ({ fill }) => {
  return (
    <svg width='18' height='22' viewBox='0 0 18 22' fill={fill} xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M16.5 0.5H1.5C1.08516 0.5 0.75 0.835156 0.75 1.25V20.75C0.75 21.1648 1.08516 21.5 1.5 21.5H16.5C16.9148 21.5 17.25 21.1648 17.25 20.75V1.25C17.25 0.835156 16.9148 0.5 16.5 0.5ZM10.4062 2.1875H12.6562V7.10703L11.5664 6.3125L10.4062 7.14219V2.1875ZM15.5625 19.8125H2.4375V2.1875H9V9.14609C9 9.22344 9.02344 9.30078 9.07031 9.36406C9.09877 9.40429 9.13491 9.43847 9.17667 9.46463C9.21842 9.49079 9.26494 9.50842 9.31355 9.51648C9.36215 9.52454 9.41187 9.52289 9.45984 9.51161C9.5078 9.50034 9.55305 9.47966 9.59297 9.45078L11.557 8.04688L13.4648 9.43906C13.5281 9.48594 13.6055 9.51172 13.6852 9.51172C13.8914 9.51172 14.0602 9.34297 14.0602 9.13672V2.1875H15.5602V19.8125H15.5625Z'
        fill={fill}
      />
    </svg>
  );
};
