import React, { Component } from 'react';
import PropTypes from 'prop-types';

import styles from './index.module.css';

export default class ErrorBoundary extends Component {
  state = {
    error: '',
    errorInfo: '',
    hasError: false,
  };
  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }
  componentDidCatch(error, errorInfo) {
    this.setState({ errorInfo });
  }
  render() {
    const { hasError } = this.state;
    if (hasError) {
      return (
        <div className={styles.errorBoundary}>
          <div className={styles.head}>
            <p className={styles.error}>
              There was an error in loading this page.
              <span
                className={styles.reload}
                onClick={() => {
                  window.location.reload();
                }}>
                Reload this page
              </span>
            </p>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}
ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};
