import { useCallback, useEffect, useMemo, useState } from 'react';

import { DateHelper } from '../../../utils';
import { startOfDay } from 'date-fns';
 
 

export const useDateTimeHandlers = ({ ride, platformPreferences, setFieldValue, maxPickUpWindowOfTime }) => {
  const [pickUpDate, setPickUpDate] = useState(null);

  const [pickUpDateLowerLimit, setPickUpDateLowerLimit] = useState(null);

  const [startDate, setStartDate] = useState(null);

  const [endDate, setEndDate] = useState(null);

  const [driverArrivalTime, setDriverArrivalTime] = useState(null);

  const [minmumAllowedDriverArrvialTime, setMinmumAllowedDriverArrvialTime] = useState(null);
  const [maximumAllowedDriverArrvialTime, setMaximumAllowedDriverArrvialTime] = useState(null);
  const [acceptedForDate, setAcceptedForDate] = useState(null);

  const setInitialDateValues = useCallback(() => {
    if (ride) {
      setPickUpDate(DateHelper.utcToZonedTime(ride?.pickUpDate.datetime, ride?.pickUpDate?.timezone));
      ride?.startDate && setStartDate(DateHelper.utcToZonedTime(ride?.startDate, ride?.pickUpDate?.timezone));
      ride?.endDate && setEndDate(DateHelper.utcToZonedTime(ride?.endDate, ride?.pickUpDate?.timezone));
      ride?.driverArrivalTime && setDriverArrivalTime(DateHelper.utcToZonedTime(ride?.driverArrivalTime, ride?.pickUpDate?.timezone));
      ride?.pickUpDateLowerLimit &&
        setPickUpDateLowerLimit(
          DateHelper.utcToZonedTime(ride?.pickUpDateLowerLimit.datetime, ride?.pickUpDateLowerLimit?.timezone),
        );
      ride?.tcEstimatedPickUpDate &&
        setAcceptedForDate(
          DateHelper.utcToZonedTime(ride?.tcEstimatedPickUpDate.datetime, ride?.tcEstimatedPickUpDate?.timezone),
        );

        setMaximumAllowedDriverArrvialTime(DateHelper.addDays(
          DateHelper.utcToZonedTime(ride?.pickUpDate?.datetime, ride?.pickUpDate?.timezone),
         2));
        setMinmumAllowedDriverArrvialTime(DateHelper.subDates(
                DateHelper.utcToZonedTime(ride?.pickUpDate?.datetime, ride?.pickUpDate?.timezone),
                {hours:5,minutes:0}
              ));
 
              
    }
  }, [ride,ride?.driverArrivalTime,ride?.pickUpDate?.datetime,ride?.startDate]);

  useEffect(() => {
    setInitialDateValues();
    return;
  }, [setInitialDateValues]);

  const pickUpDefaultDateTime = useMemo(() => new Date().setHours(0, 0, 0, 0), []);

  const disabledDate = useCallback(
    (minAvailableDate) => (current) => {

      
      if (minAvailableDate) {
        return DateHelper.isBefore(current, minAvailableDate) && !DateHelper.isTheSameDay(current, minAvailableDate);
      } else return false;
    },
    [],
  );

  const disabledDateTime = useCallback(
    (minAvailableDate, currentValue) => () => {
      const isTheSameDay = DateHelper.isTheSameDay(currentValue, minAvailableDate);
      const isTheSameHour = DateHelper.isTheSameHour(currentValue, minAvailableDate);

      if (!currentValue) {
        return {
          disabledHours: () => DateHelper.getRange(0, 24),
          disabledMinutes: () => DateHelper.getRange(0, 60),
        };
      }

      return {
        disabledHours: () =>
          isTheSameDay ? DateHelper.getRange(0, 24).splice(0, DateHelper.getHoursNumber(minAvailableDate)) : [],
        disabledMinutes: () =>
          isTheSameHour ? DateHelper.getRange(0, DateHelper.getMinutesNumber(minAvailableDate)) : [],
      };
    },
    [],
  );


 

  const handleChangeEarliestDateTime =
    (minAvailableDate) =>
    async ({ name, values, value, setFieldValue }) => {
      if (value === null) {
        await setPickUpDateLowerLimit(null);
        await setFieldValue(name, '');
      } else {
        const minDate = minAvailableDate
          ? DateHelper.isBefore(value, minAvailableDate)
            ? minAvailableDate
            : value
          : value;
        if (pickUpDate && maxPickUpWindowOfTime && value) {
          const selectedDateIsOutOfInterval = !DateHelper.isWithinInterval(pickUpDate, {
            start: value,
            end: DateHelper.addDates(value, maxPickUpWindowOfTime),
          });

          if (pickUpDate && selectedDateIsOutOfInterval) {
            await setPickUpDate(null);
            await setFieldValue('pickUpDate.datetime', '');
          }
        }

        await setPickUpDateLowerLimit(minDate);
        await setFieldValue(name, DateHelper.zonedTimeToUtcISOS(minDate, values?.pickUpDateLowerLimit?.timezone));
      }
    };

  const handleChangeLatestDateTime =
    (minAvailableDate) =>
    async ({ name, values, value, setFieldValue }) => {
      if (value === null) {
        await setPickUpDate(null);
        await setFieldValue(name, '');
      } else {
        const minDate = minAvailableDate
          ? DateHelper.isBefore(value, minAvailableDate)
            ? minAvailableDate
            : DateHelper.isAfter(value, DateHelper.addDates(minAvailableDate, maxPickUpWindowOfTime))
            ? DateHelper.addDates(minAvailableDate, maxPickUpWindowOfTime)
            : value
          : value;

        await setPickUpDate(minDate);
        await setFieldValue(name, DateHelper.zonedTimeToUtcISOS(minDate, values?.pickUpDate?.timezone));
      }
    };

  const handleChangeStartDateTime =
    (minAvailableDate) =>
    async ({ name, values, value, setFieldValue }) => {
      if (value === null) {
        await setStartDate(null);
        await setFieldValue(name, '');
      } else {
        const minDate = minAvailableDate
          ? DateHelper.isBefore(value, minAvailableDate)
            ? minAvailableDate
            : value
          : value;

        await setStartDate(minDate);
        await setFieldValue(name, DateHelper.zonedTimeToUtcISOS(minDate, values?.startDate?.timezone));
      }
    };

  const handleChangeEndDateTime =
    (minAvailableDate) =>
    async ({ name, values, value, setFieldValue }) => {
      if (value === null) {
        await setEndDate(null);
        await setFieldValue(name, '');
      } else {
        const minDate = minAvailableDate
          ? DateHelper.isBefore(value, minAvailableDate)
            ? minAvailableDate
            : value
          : value;

        await setEndDate(minDate);

        await setFieldValue(name, DateHelper.zonedTimeToUtcISOS(minDate, values?.pickUpDate?.timezone));
      }
    };

    const handleChangeDriverArrivalTime =
    (minAvailableDate) =>
    async ({ name, values, value, setFieldValue }) => {

    if (value === null) {
        setDriverArrivalTime(null);
        await setFieldValue(name, "");
      } else {
        const minDate = minAvailableDate
          ? DateHelper.isBefore(value, minAvailableDate)
            ? minAvailableDate
            : value
          : value;
      await setDriverArrivalTime(minDate);

        await setFieldValue(
          name,
          DateHelper.zonedTimeToUtcISOS(minDate, values?.driverArrivalTime?.timezone)
        );
      }
    };

  const resetAllDateTimeValues = async (setFieldValue) => {
    
    setPickUpDate(null);
    setPickUpDateLowerLimit(null);
    setStartDate(null);
    setEndDate(null);
    setDriverArrivalTime(null);
    ride?.isPickUpDateFlexible && setAcceptedForDate(null);
    setFieldValue('pickUpDate.datetime', '');
    setFieldValue('pickUpDateLowerLimit.datetime', '');
    setFieldValue('startDate.datetime', '');
    setFieldValue('endDate.datetime', '');
    setFieldValue('tcEstimatedPickUpDate.datetime', '');
    setFieldValue('driverArrivalTime.datetime', '');
  };

  const handleChangeTZ = async ({ value, setFieldValue }) => {
    await setFieldValue('pickUpDate.timezone', value);
    await setFieldValue('pickUpDateLowerLimit.timezone', value);
    await setFieldValue('startDate.timezone', value);
    await setFieldValue('endDate.timezone', value);
    await setFieldValue('driverArrivalTime.timezone', value);
    (await ride?.isPickUpDateFlexible) && setFieldValue('tcEstimatedPickUpDate.timezone', value);
    await resetAllDateTimeValues(setFieldValue);
  };

  useEffect(() => {
    if (DateHelper.isAfter(startDate, endDate)) {
      setEndDate(null);
      setFieldValue('endDate.datetime', '');
    }
    if (DateHelper.isAfter(pickUpDateLowerLimit, pickUpDate)) {
      setPickUpDate(null);
      setFieldValue('pickUpDate.datetime', '');
    }
    if (acceptedForDate && (!pickUpDateLowerLimit || !pickUpDate)) {
      setAcceptedForDate(null);
      ( ride?.isPickUpDateFlexible) && setFieldValue('tcEstimatedPickUpDate.datetime', '');
    }
    // if (DateHelper.isBefore(startDate, driverArrivalTime)) {
    //   setDriverArrivalTime(null);
    //   setFieldValue('driverArrivalTime.datetime', '')
    // }

    return;
  }, [setStartDate,ride?.isPickUpDateFlexible, setDriverArrivalTime, endDate, acceptedForDate, startDate, setFieldValue, pickUpDateLowerLimit, pickUpDate, driverArrivalTime]);

  const diabledDateForRequestPickupDate = useCallback(
    (d) => {
      if (ride?.isPickUpDateFlexible && platformPreferences?.maxPickUpWindowOfTime) {
        const maxDate = DateHelper.addDates(pickUpDateLowerLimit, platformPreferences?.maxPickUpWindowOfTime);
        const minDate = pickUpDateLowerLimit;

        return (
          (minDate != null && DateHelper.isBefore(d, minDate) && !DateHelper.isTheSameDay(d, minDate)) ||
          (maxDate != null && DateHelper.isAfter(d, maxDate) && !DateHelper.isTheSameDay(d, maxDate))
        );
      } else return false;
    },
    [ride, pickUpDateLowerLimit, platformPreferences],
  );

     
   

  const diabledTimeForRequestPickupDate = useCallback(
    (currentDate) => {
      if (ride?.isPickUpDateFlexible) {
        const startDate = pickUpDateLowerLimit;
        const maxDate = DateHelper.addDates(startDate, platformPreferences?.maxPickUpWindowOfTime);

        return DateHelper.disabledTimeForHigherLimit({
          currentDate,
          startDate,
          maxDate,
        });
      } else return false;
    },
    [ride, pickUpDateLowerLimit, platformPreferences?.maxPickUpWindowOfTime],
  );

  const disabledDatesForAcceptedFor = (d) => {
    const minDate = pickUpDateLowerLimit;
    const maxDate = pickUpDate;

    return (
      (minDate != null && DateHelper.isBefore(d, minDate) && !DateHelper.isTheSameDay(d, minDate)) ||
      (maxDate != null && DateHelper.isAfter(d, maxDate) && !DateHelper.isTheSameDay(d, maxDate))
    );
  };

  const disabledDateForDriverChekinBeforeAfter =  (date) => {
    const minDate = startOfDay(minmumAllowedDriverArrvialTime);
    const maxDate = startOfDay(maximumAllowedDriverArrvialTime);
    const currentdate = startOfDay(new Date(date))
 
    return (currentdate && (currentdate < minDate || currentdate > maxDate));
  
      
  };

  
  const diabledDateForStartDate = 
    (d) => { 
      const minDate = driverArrivalTime;
      return !( minDate != null && DateHelper.isAfter(d, minDate) )
    
    };


  // const disableDatesBeforeDriverArrival  =  (date) => {
  //   const minDate = minmumAllowedDriverArrvialTime;
  //   const maxDate = maximumAllowedDriverArrvialTime;

  //   if (driverArrivalTime) {
  //     return DateHelper.isBefore(current, minAvailableDate) && !DateHelper.isTheSameDay(current, minAvailableDate);
  //   } else return false;
    
  //   return (date && (date < minDate || date > maxDate));
  
      
  // };


  const diabledTimeBeforeAfter = useCallback(
    ({date,beforeDate,afterDate}) => {

    
      const currentDate = new Date(date);
        const startDate = new Date(beforeDate);
        const maxDate =  new Date(afterDate);

       
 
        return {
          disabledHours:()=>getExcludedHours(getAllowedHours(currentDate,startDate,maxDate)),
        disabledMinutes:()=>[]
      };
       
    },
    [ride, pickUpDateLowerLimit, platformPreferences?.maxPickUpWindowOfTime],
  );

  const getExcludedHours = (allowedHours) => {
     
    const allHours = Array.from({ length: 24 }, (_, i) => i);
    return allHours.filter(hour => !allowedHours.includes(hour));
  };


  const getExcludedMintues = (allowedMinutes) => {
    
    const allMin = Array.from({ length: 60 }, (_, i) => i);
    return allMin.filter(min => !allowedMinutes.includes(min));
  };
  const getAllowedHours = (currentDate, minDate, maxDate) => {

    
    const hours = [];
    const current =  currentDate;
    const min =  minDate;
    const max =  maxDate;
  
    for (let hour = 0; hour < 24; hour++) {
      current.setHours(hour);
      if (current >= min && current <= max) {
        hours.push(hour);
      }
    }
   
    return hours;
  };

  const getAllowedMinutes = (currentDate, minDate, maxDate) => {
    const minutes = [];
    const current =  currentDate;
    const min =  minDate;
    const max =  maxDate;
  
    for (let minute = 0; minute < 60; minute++) {
      current.setMinutes(minute);
      if (current >= min && current <= max) {
        minutes.push(minute);
      }
    }
 
    return minutes;
  };

  const disabledDateTimeForAcceptedFor = (currentDate) => {
    const startDate = pickUpDateLowerLimit;
    const maxDate = pickUpDate;

    return DateHelper.disabledTimeForHigherLimit({ currentDate, startDate, maxDate });
  };


  

  const handleChangeDateTimeForAcceptedFor = async ({ name, value, setFieldValue, values }) => {
    if (value === null && name === 'tcEstimatedPickUpDate.datetime') {
      await setEndDate(null);
      await setFieldValue(name, '');
    } else {
      const allowedpickupDateTimeValue = DateHelper.compareDates(pickUpDateLowerLimit, value)
        ? pickUpDateLowerLimit
        : !DateHelper.isWithinInterval(value, {
            start: pickUpDateLowerLimit,
            end: pickUpDate,
          })
        ? new Date(new Date(value).setHours(12, 0, 0, 0))
        : value;

      await setAcceptedForDate(allowedpickupDateTimeValue);
      await setFieldValue(
        name,
        DateHelper.zonedTimeToUtcISOS(allowedpickupDateTimeValue, values?.tcEstimatedPickUpDate?.timezone),
      );
    }
  };

  const defaultValueForDatePicker = DateHelper.isTheSameDay(pickUpDateLowerLimit, pickUpDate)
    ? pickUpDateLowerLimit
    : new Date(new Date(pickUpDateLowerLimit).setHours(0, 0, 0, 0));

  return {
    pickUpDefaultDateTime,
    disabledDate,
    disabledDateTime,
    handleChangeEarliestDateTime,
    handleChangeLatestDateTime,
    handleChangeStartDateTime,
    diabledTimeBeforeAfter,
    diabledDateForStartDate,
    handleChangeEndDateTime,
    handleChangeDriverArrivalTime,
    handleChangeTZ,
    diabledDateForRequestPickupDate,
    disabledDateForDriverChekinBeforeAfter,
    diabledTimeForRequestPickupDate,
    disabledDatesForAcceptedFor,
    disabledDateTimeForAcceptedFor,
    handleChangeDateTimeForAcceptedFor,
    defaultValueForDatePicker,
    pickUpDateLowerLimit,
    pickUpDate,
    acceptedForDate,
    startDate,
    endDate,
    driverArrivalTime,
    minmumAllowedDriverArrvialTime,
    maximumAllowedDriverArrvialTime
  };
};
