import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearRiderDetails, getRiderDetails } from '../store/riders';
import { baseRequest, DateHelper, LodashHelper } from '../utils';

export const transformSuggestions = (suggestions = []) =>
  suggestions.map((s) => {
    const optionLabel = s?.organization
      ? `${s?.firstName} ${s?.lastName} (${DateHelper.format(new Date(s.dob), 'MM/dd/yyyy')}, ${s?.organization})`
      : `${s?.firstName} ${s?.lastName} (${DateHelper.format(new Date(s.dob), 'MM/dd/yyyy')})`;
    return {
      label: optionLabel,
      value: s.id,
      name: `${s?.firstName} ${s?.lastName}`,
      title: optionLabel,
    };
  });

export const useRidersAutoComplete = ({ value = '', handleSearch, handleSelect, delay = 1000 }) => {
  const riderDetails = useSelector((state) => state.riders.riderDetails);

  const [isLoading, setIsLoading] = useState(false);
  const [suggestions, setSuggestions] = useState([]);

  const [riderIsPopulated, setRiderIsPopulated] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    return () => dispatch(clearRiderDetails());
  }, [dispatch]);

  const getAutoCompleteSuggestions = useCallback(async (value) => {
    setIsLoading(true);

    try {
      const { data } = await baseRequest('POST', '/patients/autocomplete', { value });

      await setSuggestions(transformSuggestions(data));
    } catch (error) {
      setSuggestions([]);
    }
    setIsLoading(false);
  }, []);

  const delayedQuery = useRef(LodashHelper.debounce((value) => getAutoCompleteSuggestions(value), delay)).current;

  const handleSearchRider = useCallback(
    ({ name, value, setFieldValue }) => {
      if (!value) {
        setRiderIsPopulated(false);
      }
      if (handleSearch) {
        handleSearch(value);
      } else setFieldValue(name, value);
      value?.length >= 3 && delayedQuery(value);
    },
    [delayedQuery, handleSearch],
  );

  const handleSelectRider = useCallback(
    ({ id, entity, setFieldValue, name }) => {
      if (handleSelect) {
        handleSelect(id, entity, name);
      } else {
        setFieldValue(name, entity?.name);

        dispatch(getRiderDetails(id))
          .then((action) => {
            setFieldValue('riderInfo', action?.payload);
            setRiderIsPopulated(true);
          })
          .catch((e) => e);
      }
    },
    [dispatch, handleSelect],
  );

  const notFoundContent = useMemo(() => {
    if (value?.length <= 3 && !isLoading) {
      return 'Type at least 3 symbols';
    } else if (isLoading) {
      return 'Loading...';
    } else return 'No matches';
  }, [value, isLoading]);

  return {
    riderDetails,
    notFoundContent,
    suggestions,
    handleSearchRider,
    handleSelectRider,
    riderIsPopulated,
    setRiderIsPopulated,
  };
};
