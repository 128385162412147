import React from 'react';
import { AutoComplete as AntdAutoComplete } from 'antd';

import { Field, getIn } from 'formik';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './index.module.css';

export const CustomAutoComplete = ({
  field,
  form,
  type,
  value,
  onSearch,
  onSelect,
  disabled,
  myclassname,
  name,
  placeholder,
  handleBlurField,
  options,
  notFoundContent,
  getPopupContainer,
  ...rest
}) => {
  const handleSearch = (value) => {
    if (onSearch) {
      onSearch({
        name: field?.name,
        setFieldValue: form?.setFieldValue,
        value,
      });
    } else field?.onChange(value);
  };

  const handleSelect = (id, entity) => {
    if (onSelect) {
      onSelect({
        id,
        entity,
        name: field?.name,
        setFieldValue: form?.setFieldValue,
      });
    } else form?.setFieldValue(field.name, entity.label);
  };

  const handleBlur = () => {
    if (handleBlurField) {
      handleBlurField();
    } else form?.setTouched({ ...form?.touched, [field?.name]: true });
  };

  const popUpContainer = (trigger) => {
    if (getPopupContainer) return getPopupContainer(trigger);
    else return trigger.parentElement;
  };

  return (
    <AntdAutoComplete
      {...rest}
      name={name || field?.name}
      type={type}
      value={value || field?.value}
      onSearch={handleSearch}
      onSelect={handleSelect}
      onBlur={handleBlur}
      disabled={disabled}
      placeholder={placeholder}
      options={options}
      notFoundContent={notFoundContent}
      popupClassName={styles.dropdown}
      getPopupContainer={popUpContainer}
      allowClear
      className={classnames({
        [styles.autoComplete]: true,
        [styles.input]: true,
        [styles.error]: !disabled && !getIn(form?.values, field?.name) && getIn(form?.errors, field?.name),
        [styles.error]:
          !disabled &&
          (getIn(form?.touched, field?.name) || form?.touched[field?.name]) &&
          getIn(form?.errors, field?.name),
        [myclassname]: myclassname,
      })}
      data-testid='autocomplete'
    />
  );
};

export const AutoComplete = ({
  type,
  name,
  myClassName,
  value,
  disabled,
  placeholder,
  handleBlurField,
  options,
  notFoundContent,
  onSearch,
  onSelect,
  getPopupContainer,
  ...rest
}) => {
  return (
    <Field
      name={name}
      type={type}
      disabled={disabled}
      value={value}
      handleBlurField={handleBlurField}
      placeholder={placeholder}
      myclassname={myClassName}>
      {({ field, form }) => {
        return (
          <>
            <CustomAutoComplete
              form={form}
              field={field}
              name={name}
              type={type}
              disabled={disabled}
              value={value}
              placeholder={placeholder}
              myclassname={myClassName}
              handleBlurField={handleBlurField}
              options={options}
              notFoundContent={notFoundContent}
              onSearch={onSearch}
              onSelect={onSelect}
              getPopupContainer={getPopupContainer}
              {...rest}
            />

            {!disabled &&
              (getIn(form?.touched, field?.name) || form?.touched[field.name]) &&
              getIn(form?.errors, field?.name) && (
                <div className={styles.errorMessage} role='alert'>
                  {getIn(form?.errors, field?.name)}
                </div>
              )}
          </>
        );
      }}
    </Field>
  );
};

AutoComplete.propTypes = {
  name: PropTypes.string.isRequired,
  myClassName: PropTypes.string,
  placeholder: PropTypes.string,
  onSearch: PropTypes.func,
  onSelect: PropTypes.func,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  handleBlurField: PropTypes.func,
};
