import React, { useState, useCallback } from 'react';

import { Modal, Button } from '../..';
import { renderRecordValue } from '../../../utils';

import styles from './index.module.css';

export const useSwitchAdminModal = ({ handleConfirm, userName }) => {
  const [visibleModal, setModalVisible] = useState(false);

  const onCancel = useCallback(() => setModalVisible(false), [setModalVisible]);

  const confirmCallback = useCallback(() => setModalVisible(false), [setModalVisible]);

  const onConfirm = useCallback(() => handleConfirm(confirmCallback), [handleConfirm, confirmCallback]);

  return {
    SwitchAdminModal: (
      <Modal visible={visibleModal} onCancel={onCancel} closable width={560} title='Are you sure?'>
        <div className={styles.modalContent}>
          <p className={styles.modalDescription}>
            Do you want to set <span className={styles.optionName}>{renderRecordValue(userName)}</span> as
            <span className={styles.optionName}> Admin</span> of
            this Organization? By clicking "Confirm", you will lose your current Admin role.
            <br />
            Upon clicking "Confirm" you will be automatically logged out of the system as the system updates the Admin
            as {renderRecordValue(userName)}.
          </p>
          <div className={styles.modalBtns}>
            <div>
              <Button myClassName={styles.navigationBtn} onClick={onConfirm}>
                Confirm
              </Button>
            </div>
            <div>
              <Button myClassName={styles.navigationBtn} onClick={onCancel}>
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    ),
    setSwitchAdminModalVisible: setModalVisible,
  };
};
