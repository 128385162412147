import React, { useState } from 'react';

import PropTypes from 'prop-types';

import { AutoComplete } from '../../../../';
import { FilterDropDown } from '../../FilterDropdown/FilterDropdown';

import styles from './index.module.css';

export const AutoCompleteDropDownOverlay = ({
  values,
  name,
  setFieldValue,
  Component,
  placeholder,
  notFoundContent,
  suggestions,
  handleSearchRider,
  handleSelectRider,
  ...rest
}) => {
  const [visible, setVisible] = useState(false);

  return (
    <th className='ant-table-cell'>
      <FilterDropDown
        visible={visible}
        content={
          <div className={styles.dropdownContent}>
            <AutoComplete
              name={name}
              placeholder={placeholder}
              onSearch={handleSearchRider}
              onSelect={handleSelectRider}
              options={suggestions}
              notFoundContent={notFoundContent}
              getPopupContainer={() => document.querySelector('.ant-table')}
            />
          </div>
        }
        values={values}
        name={name}
        setFieldValue={setFieldValue}
        onVisibleChange={setVisible}
        {...rest}>
        <Component visible={visible} />
      </FilterDropDown>
    </th>
  );
};

AutoCompleteDropDownOverlay.propTypes = {
  Component: PropTypes.elementType,
  name: PropTypes.string,
  placeholder: PropTypes.string,
};
