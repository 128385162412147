import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { LodashHelper } from '../utils';

export const useFilterStatusHandler = (myFilterParams, formikValues, setFilteredStatus) => {
  const dispatch = useDispatch();
  const filterParamsChanged = useMemo(() => {
    const { formattedRiderName, ...restMyFilterParams } = myFilterParams;
    const { formattedRiderName: formikFormattedRiderName, ...restFormikValues } = formikValues;
    return !LodashHelper.isEqual(restMyFilterParams, restFormikValues);
  }, [myFilterParams, formikValues]);

  useEffect(() => {
    filterParamsChanged && dispatch(setFilteredStatus(true));
   
  }, [dispatch, filterParamsChanged, setFilteredStatus]);

  const onClose = () => dispatch(setFilteredStatus(true));

  return {
    onClose,
    filterParamsChanged,
  };
};
