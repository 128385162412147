import { MailOutlined } from '@ant-design/icons';
import { USER_ROLES, USER_STATUSES } from '../../constants/constants';

export const getSortedEmployees = (state, styles) =>
  state?.users?.users
    .filter((u) => u.role === USER_ROLES.TC_EMPLOYEE || u.role === USER_ROLES.HO_EMPLOYEE)
    .map((u) => ({
      ...u,
      title: (
        <div className={styles.styledOption}>
          {u.status !== USER_STATUSES.ACTIVE && <MailOutlined className={styles.mailIcon} />}
          <span>
            {u.firstName} {u.lastName} {u.email}
          </span>
        </div>
      ),
      value: u.id,
      disabled: u.status !== USER_STATUSES.ACTIVE,
    }))
    .sort((a, b) => a.disabled - b.disabled);
