import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import {
  format,
  add,
  compareAsc,
  getHours,
  getMinutes,
  isSameDay,
  isSameHour,
  isBefore,
  isAfter,
  startOfDay,
  endOfDay,
  isFuture,
  setSeconds,
  sub,
  isWithinInterval,
  addDays,
} from 'date-fns';

import getDay from 'date-fns/getDay';
import startOfMonth from 'date-fns/startOfMonth';
import getMonth from 'date-fns/getMonth';

import { LodashHelper } from '.';
import { renderRecordValue } from './helpers';

class DateHelper {
  constructor() {
    this.defaultFormat = 'yyyy/MM/dd HH:mm a';
  }

  formatDOBString = (dateTime) => {
    const dateValue = dateTime.split('-');

    return `${dateValue[1]}/${dateValue[2]}/${dateValue[0]}`;
  };

  format = (dateTime, dateFormat = this.defaultFormat) => format(dateTime, dateFormat);

  utcToZonedTime = (date, timezone, defaultFormated = false, dateFormat) => {
    if (defaultFormated) {
      return format(utcToZonedTime(date, timezone), defaultFormated);
    } else if (dateFormat) {
      return format(utcToZonedTime(date, timezone), dateFormat);
    } else {
      return utcToZonedTime(date, timezone);
    }
  };

  zonedTimeToUtcISOS = (date, timezone) => zonedTimeToUtc(date, timezone).toISOString();

  zoneTimeToUtc = (date, timezone) => zonedTimeToUtc(date, timezone);

  toISOString = (datetime) => datetime.toISOString();

  convertTimeToMinutes = (time) => {
    if (LodashHelper.isNumber(time?.hours) && LodashHelper.isNumber(time?.minutes)) {
      return time?.hours * 60 + time?.minutes;
    } else if (LodashHelper.isNumber(time?.hours) && !LodashHelper.isNumber(time?.minutes)) {
      return time?.hours * 60;
    } else if (!LodashHelper.isNumber(time?.hours) && LodashHelper.isNumber(time?.minutes)) {
      return time?.minutes;
    } else return null;
  };

  convertMinutesToTime(minutes) {
    return !isNaN(minutes) && minutes > 0 ? { hours: Math.floor(minutes / 60), minutes: minutes % 60 } : { hours: null, minutes: null };
  }

  transformTime = (values, callback) => {
    let formattedValues = { ...values, minTimeFromRideRequestToPickup: {}, rideExpirationTime: {}, notifications: {} };
    Object.keys(values.minTimeFromRideRequestToPickup).forEach((key) => {
      formattedValues.minTimeFromRideRequestToPickup[key] = callback(values.minTimeFromRideRequestToPickup[key]);
    });
    Object.keys(values.rideExpirationTime).forEach((key) => {
      formattedValues.rideExpirationTime[key] = callback(values.rideExpirationTime[key]);
    });
    Object.keys(values.notifications).forEach((key) => {
      formattedValues.notifications[key] = callback(values.notifications[key]);
    });

    formattedValues.maxPickUpWindowOfTime = callback(values.maxPickUpWindowOfTime);
    formattedValues.minimumRoundTrip = callback(values.minimumRoundTrip);
    formattedValues.carBoosterSeatBufferTime = callback(values.carBoosterSeatBufferTime);
    return formattedValues;
  };

  getRange = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  addMinutesInDateWithFormat(date, minutes, format = 'MM/dd/yyyy hh:mm a') {
    let d = new Date(date);
    
    let newTimestamp = d.getTime() + (minutes * 60 * 1000);
    let newDate = new Date(newTimestamp);
    
    return this.format(newDate, format);

  }

  addMinutesInDateWithoutFormat(date, minutes) {
    let originalDate = new Date(date);
    let newTimestamp = originalDate.getTime() + (minutes * 60 * 1000);
    let newDate = new Date(newTimestamp);
    return newDate.toISOString();

  }
  subtractMinutesInDateWithFormat(date, minutes, format = 'MM/dd/yyyy hh:mm a') {
    let d = new Date(date);
    d.setMinutes(d.getMinutes() - minutes);
    return d;//this.format(d, format);
  }
  subtractMinutesInDateWithoutFormat(date, minutes, format = 'MM/dd/yyyy hh:mm a') {
    let d = new Date(date);
    d.setMinutes(d.getMinutes() - minutes);
    return d.toISOString();
  }

  addDates = (leftDate, rightDate) => setSeconds(add(leftDate, rightDate), 0);
  addDays = (date, days) => addDays(date, days);

  subDates = (leftDate, rightDate) => setSeconds(sub(leftDate, rightDate), 0);

  compareDates = (leftDate, rightDate) => compareAsc(leftDate, rightDate) > 0;

  getStartOfTheDay = (date) => setSeconds(startOfDay(date), 0);

  getEndOfTheDay = (date) => setSeconds(endOfDay(date), 0);

  isTheSameDay = (leftDate, rightDate) => isSameDay(leftDate, rightDate);

  isTheSameHour = (leftDate, rightDate) => isSameHour(leftDate, rightDate);

  getHoursNumber = (date) => getHours(date);

  getMinutesNumber = (date) => getMinutes(date);

  isWithinInterval = (date, interval) => isWithinInterval(date, interval);

  getCurrentTimeZoneABBR = (timeZone) =>
    new Intl.DateTimeFormat('en-us', { timeZone, timeZoneName: 'short' })
      .formatToParts(new Date())
      .find((part) => part.type === 'timeZoneName').value;

  getTimezonesList = () => {
    return [
      { title: this.getCurrentTimeZoneABBR('America/New_York'), value: 'America/New_York' },
      { title: this.getCurrentTimeZoneABBR('America/Chicago'), value: 'America/Chicago' },
      { title: this.getCurrentTimeZoneABBR('America/Denver'), value: 'America/Denver' },
      { title: this.getCurrentTimeZoneABBR('America/Los_Angeles'), value: 'America/Los_Angeles' },
    ];
  };

  renderDateTime = ({ dateTime, timeZone, showTimezoneString }) => {
    return renderRecordValue(
      new Date(dateTime).toLocaleString('en-US', {
        month: 'numeric',
        year: 'numeric',
        day: 'numeric',
        hour12: true,
        hour: '2-digit',
        minute: '2-digit',
        timeZone: timeZone,
        ...(showTimezoneString && { timeZoneName: 'short' }),
      }),
    );
  };

  renderDate = ({ dateTime }) => {
    
    return renderRecordValue(
      new Date(dateTime).toLocaleString('en-US', {
        month: 'numeric',
        year: 'numeric',
        day: 'numeric',
        hour12: true
      }),
    );
  };

  renderTimeWithTZ = ({ dateTime, timeZone }) => {
    return renderRecordValue(
      new Date(dateTime).toLocaleString('en-US', {
        hour12: true,
        hour: '2-digit',
        minute: '2-digit',
        timeZone: timeZone,
        timeZoneName: 'short',
      }),
    );
  };

  renderDateTimeWithTZ = ({ dateTime, timeZone, useFullFormattedDate = true, showTimezoneString = true }) => {
    if (useFullFormattedDate) {
      return this.renderDateTime({ dateTime, timeZone, showTimezoneString });
    } else {
      return this.renderTimeWithTZ({ dateTime, timeZone });
    }
  };

  isValidDate = (year, month, day) => {
    const date = new Date(year, month, day);
    if (date.getFullYear() === year && date.getMonth() === month && date.getDate() === day) {
      return true;
    }
    return false;
  };

  isFuture = (dateString) => isFuture(dateString);

  isBefore = (leftDate, rightDate) => isBefore(leftDate, rightDate);

  isAfter = (leftDate, rightDate) => isAfter(leftDate, rightDate);

  getNumberOfDayOccurrencesInWeek(date) {
    let dates = [];
    const month = getMonth(date);
    const dayNumberWhichWeSearch = getDay(date);
    const startOfMonthDay = startOfMonth(date);
    const monthStartWithDayNumber = startOfMonthDay.getDay();
    const daysToFirst = (dayNumberWhichWeSearch + 7 - monthStartWithDayNumber) % 7;
    const firstOf = new Date(startOfMonthDay.setDate(startOfMonthDay.getDate() + daysToFirst));

    while (firstOf.getMonth() === month) {
      dates.push(new Date(+firstOf));
      firstOf.setDate(firstOf.getDate() + 7);
    }

    return dates.findIndex((d) => isSameDay(d, date));
  }

  checkIfSelectedDayIsInTheSameDay({ currentDate, startDate, maxDate }) {
    const isCurrentHourIsOutOfInterval =currentDate? !this.isWithinInterval(currentDate, {
      start: startDate,
      end: maxDate,
    }):false;
    const isMinAvailableAndMaxAvailableDaySame = this.isTheSameDay(startDate, maxDate);
    const isMinAvailableAndMaxAvailableDateAndHourInSameDay =
      isMinAvailableAndMaxAvailableDaySame && this.isTheSameHour(startDate, maxDate);
    const isLastAvailableDaySelected = this.isTheSameDay(currentDate, maxDate);
    const isLastAvailableHourSelected = isLastAvailableDaySelected && this.isTheSameHour(currentDate, maxDate);
    const isMinAvailableDaySelected = this.isTheSameDay(currentDate, startDate);
    const isMinAvailabelHourSelected = isMinAvailableDaySelected && this.isTheSameHour(currentDate, startDate);

    return {
      isMinAvailableAndMaxAvailableDaySame,
      isCurrentHourIsOutOfInterval,
      isMinAvailableAndMaxAvailableDateAndHourInSameDay,
      isLastAvailableHourSelected,
      isMinAvailabelHourSelected,
    };
  }

  disabledTimeForHigherLimit({ startDate, maxDate, currentDate }) {
    if (startDate && maxDate && currentDate) {
      const {
        isMinAvailableAndMaxAvailableDaySame,
        isCurrentHourIsOutOfInterval,
        isMinAvailableAndMaxAvailableDateAndHourInSameDay,
        isLastAvailableHourSelected,
        isMinAvailabelHourSelected,
      } = this.checkIfSelectedDayIsInTheSameDay({ startDate, maxDate, currentDate });

      if (isMinAvailabelHourSelected || isLastAvailableHourSelected || isMinAvailableAndMaxAvailableDaySame) {
        const disabledMinHours =
          isMinAvailableAndMaxAvailableDateAndHourInSameDay || isMinAvailableAndMaxAvailableDaySame
            ? [
              ...this.getRange(0, this.getHoursNumber(startDate)),
              ...this.getRange(this.getHoursNumber(maxDate) + 1, 24),
            ]
            : isMinAvailabelHourSelected
              ? this.getRange(0, 24).splice(0, this.getHoursNumber(startDate))
              : isLastAvailableHourSelected
                ? this.getRange(this.getHoursNumber(maxDate) + 1, 24)
                : [];
        const disabledMinMinutes = isMinAvailableAndMaxAvailableDateAndHourInSameDay
          ? [
            ...this.getRange(0, this.getMinutesNumber(startDate)),
            ...this.getRange(this.getMinutesNumber(maxDate) + 1, 60),
          ]
          : isMinAvailabelHourSelected
            ? this.getRange(0, this.getMinutesNumber(startDate))
            : isLastAvailableHourSelected
              ? this.getRange(this.getMinutesNumber(maxDate) + 1, 60)
              : isCurrentHourIsOutOfInterval
                ? this.getRange(0, 60)
                : isMinAvailableAndMaxAvailableDaySame
                  ? []
                  : this.getRange(0, 60);


                  
        return {
          disabledHours: () => disabledMinHours,
          disabledMinutes: () => disabledMinMinutes,
        };
      }
      if (this.isTheSameDay(currentDate, startDate)) {
        return {
          disabledHours: () =>
            this.getHoursNumber(startDate) > 0 ? this.getRange(0, this.getHoursNumber(startDate)) : [],
          disabledMinutes: (hour) =>
            hour === this.getHoursNumber(startDate) && this.getMinutesNumber(startDate) > 0
              ? this.getRange(0, this.getMinutesNumber(startDate))
              : isCurrentHourIsOutOfInterval
                ? this.getRange(0, 60)
                : [],
        };
      }
      if (this.isTheSameDay(currentDate, maxDate)) {
        return {
          disabledHours: () => this.getRange(this.getHoursNumber(maxDate) + 1, 24),
          disabledMinutes: (hour) =>
            hour === this.getHoursNumber(maxDate)
              ? this.getRange(this.getMinutesNumber(maxDate) + 1, 60)
              : isCurrentHourIsOutOfInterval
                ? this.getRange(0, 60)
                : [],
        };
      }
      return null;
    } else
      return {
        disabledHours: () => this.getRange(0, 24),
        disabledMinutes: () => this.getRange(0, 60),
      };
  }

  disabledDateTimeForReturnHigherLimit({ currentDate, startDate, maxDate }) {
    if (startDate && maxDate && currentDate) {
      const {
        isMinAvailableAndMaxAvailableDaySame,
        isCurrentHourIsOutOfInterval,
        isMinAvailableAndMaxAvailableDateAndHourInSameDay,
        isLastAvailableHourSelected,
        isMinAvailabelHourSelected,
      } = this.checkIfSelectedDayIsInTheSameDay({ startDate, maxDate, currentDate });

      if (isMinAvailabelHourSelected || isLastAvailableHourSelected || isMinAvailableAndMaxAvailableDaySame) {
        const disabledMinHours =
          isMinAvailableAndMaxAvailableDateAndHourInSameDay || isMinAvailableAndMaxAvailableDaySame
            ? [
              ...this.getRange(0, this.getHoursNumber(startDate)),
              ...this.getRange(this.getHoursNumber(maxDate) + 1, 24),
            ]
            : isMinAvailabelHourSelected
              ? this.getRange(0, 24).splice(0, this.getHoursNumber(startDate))
              : isLastAvailableHourSelected
                ? this.getRange(this.getHoursNumber(maxDate) + 1, 24)
                : this.getRange(0, 24);

        const disabledMinMinutes = isMinAvailableAndMaxAvailableDateAndHourInSameDay
          ? [
            ...this.getRange(0, this.getMinutesNumber(startDate)),
            ...this.getRange(this.getMinutesNumber(maxDate) + 1, 60),
          ]
          : isMinAvailabelHourSelected
            ? this.getRange(0, this.getMinutesNumber(startDate))
            : isLastAvailableHourSelected
              ? this.getRange(this.getMinutesNumber(maxDate) + 1, 60)
              : isCurrentHourIsOutOfInterval
                ? this.getRange(0, 60)
                : isMinAvailableAndMaxAvailableDaySame
                  ? []
                  : this.getRange(0, 60);

        return {
          disabledHours: () => disabledMinHours,
          disabledMinutes: () => disabledMinMinutes,
        };
      }

      if (this.isTheSameDay(currentDate, startDate)) {
        const isCurrentHourIsLessThenMinAvalilable = this.getHoursNumber(currentDate) < this.getHoursNumber(startDate);

        return {
          disabledHours: () =>
            this.getHoursNumber(startDate) > 0 ? this.getRange(0, this.getHoursNumber(startDate)) : [],
          disabledMinutes: (hour) =>
            hour === this.getHoursNumber(startDate) && this.getMinutesNumber(startDate) > 0
              ? this.getRange(0, this.getMinutesNumber(startDate) - 1)
              : isCurrentHourIsLessThenMinAvalilable
                ? this.getRange(0, 60)
                : [],
        };
      }

      if (this.isTheSameDay(currentDate, maxDate)) {
        const isCurrentHourIsLessThenMaxAvalilable = this.getHoursNumber(currentDate) < this.getHoursNumber(maxDate);

        return {
          disabledHours: () => this.getRange(this.getHoursNumber(maxDate) + 1, 24),
          disabledMinutes: (hour) => {
            return hour === this.getHoursNumber(maxDate)
              ? this.getRange(this.getMinutesNumber(maxDate) + 1, 60)
              : isCurrentHourIsLessThenMaxAvalilable
                ? []
                : this.getRange(0, 60);
          },
        };
      }
    } else
      return {
        disabledHours: () => this.getRange(0, 24),
        disabledMinutes: () => this.getRange(0, 60),
      };
  }

  addHours(leftTime, rightTime, bufferTime) {
    return {
      hours: leftTime.hours + rightTime.hours + bufferTime?.hours,
      minutes: leftTime.minutes + rightTime.minutes + bufferTime?.minutes,
    };
  }
}

const DateHelperInstance = new DateHelper();
export default DateHelperInstance;

window.DateHelper = DateHelperInstance;
