import React, { useEffect } from "react";

import { Input, GooglePlacesAutoComplete, Button } from "../..";

import { INVALID_ADDRESS_MESSAGE } from "../../../constants/constants";

import styles from "./index.module.css";
import RideRequestFields from "../../common/RideRequestFields/RideRequestFields";

export const CreateHO = ({
  personalInfoFieldsDisabled,
  switchAdmin,
  handleUpdateAdmin,
  values,
  handleSelectAddress,
  handleChangeAddress,
  handleChangeAdmin,
  handleInviteUser,

  ...rest
}) => {
  const { validateForm } = rest;
  useEffect(() => {
    validateForm();
  }, [values?.location])

  return (
    <>
      <label className={styles.name}>
        <span className={styles.required}>Company Name</span>
        <Input name="name" type="text" placeholder="Organization Name" />
      </label>
      <label className={styles.organizationLocation}>
        <span className={styles.required}>Address</span>
        <GooglePlacesAutoComplete
          name="location.formattedAddress"
          value={values?.location?.formattedAddress}
          handleSelectAddress={handleSelectAddress}
          handleChangeAddress={handleChangeAddress}
        />
        {rest.touched &&
          rest.touched["location.formattedAddress"] &&
          rest.errors.location && (
            <div className={styles.errorMessage}>{INVALID_ADDRESS_MESSAGE}</div>
          )}
      </label>
      <div className={styles.name}>
        <label className={styles.nameLabel}>
          <span className={styles.required}>Administrator's First Name</span>
          <Input
            name="admin.firstName"
            type="text"
            placeholder="Administrator's First Name"
            disabled={personalInfoFieldsDisabled}
          />
        </label>
        <label className={styles.nameLabel}>
          <span className={styles.required}>Administrator's Last Name</span>
          <Input
            name="admin.lastName"
            type="text"
            placeholder="Administrator's Last Name"
            disabled={personalInfoFieldsDisabled}
          />
        </label>
      </div>

      <div className={styles.emailContainer}>
        <label className={styles.email}>
          <span className={styles.required}> Admin's Email</span>
          <Input
            name="admin.email"
            type="text"
            placeholder="Admin's Email"
            disabled={personalInfoFieldsDisabled}
          />
        </label>
        {switchAdmin && (
          <div className={styles.changeAdminBtn}>
            <Button
              myClassName={styles.formControlBtn}
              onClick={handleChangeAdmin}
            >
              Switch Admin
            </Button>
          </div>
        )}
      </div>
      {switchAdmin && (
        <div className={styles.inviteBtn}>
          <Button
            myClassName={styles.formControlBtn}
            onClick={handleInviteUser}
          >
            Invite New User
          </Button>
        </div>
      )}
      <div>
        <div className={styles.checkboxesColumn}>
          <label className={styles.rideRequestFieldsHead}>
            Included Ride Request fields
          </label>
          {values && RideRequestFields(values, false, rest.setValues)}
        </div>
      </div>
    </>
  );
};
