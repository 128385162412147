import React from "react";
import { Modal, Button } from "../..";
import styles from "./index.module.css";
import {
  RESCHEDULE_ROUNDTRIP_HEADING,
  RESCHEDULE_ROUNDTRIP_POPUP_TITLE,
} from "../../../constants/constants";
import { RidePickUpInfo } from "../../common/RideDetails/RideDetailsPartials/RidePickUpInfo";
import { RideDestinationInfo } from "../../common/RideDetails/RideDetailsPartials/RideDestinationInfo";

export const ConfirmRescheduleRideModal = ({
  rideValues,
  ride,
  handleConfirm,
  handleCancel,
}) => (
  <Modal
    visible={true}
    onCancel={handleCancel}
    closable
    width="auto"
    bodyStyle={{ minHeight: "25vh" }}
    title={
      ride?.connectedLeg
        ? RESCHEDULE_ROUNDTRIP_POPUP_TITLE
        : `Reschedule Ride Request – ID ${ride?.id}`
    }
    // title="Confirmation"
  >
    <>
      {ride?.connectedLeg && ( 
        <div className={styles.routes}>
          <h3 className={styles.roundTripHeading}>
            {RESCHEDULE_ROUNDTRIP_HEADING}
          </h3>
          <h3 className={styles.roundTripHeadingText}>
            Roundtrip Ride Reschedule
          </h3>

          <RidePickUpInfo
            pickupAddressDetails={ride?.pickupAddressDetails}
            destinationAddress={ride?.destinationAddress}
            pickUpDate={ride?.pickUpDate}
            riderDescription={ride?.riderDescription}
            pickUpDateLowerLimit={ride?.pickUpDateLowerLimit}
            isPickUpDateFlexible={ride?.isPickUpDateFlexible}
            tcEstimatedPickUpDate={ride?.tcEstimatedPickUpDate}
            securityGate={ride?.pickUpSecurityGateDetails}
            flightInfo={ride?.flightInfo}
            appointmentDate={ride?.appointmentDate}
            isRecurringRide={ride?.isRecurring}
            leg={ride?.leg}
            seqId={ride?.seqId}
          />

          <div className={styles.destionationRouteInfo}>
            <RideDestinationInfo
              destinationAddress={ride?.destinationAddress}
              originAddress={ride?.pickupAddressDetails}
              showRoundtripInfo={ride?.isRoundtrip && ride?.leg}
              securityGate={ride?.destinationSecurityGateDetail}
              returnTime={ride?.connectedLeg?.pickUpDate}
              returnTimeLowerLimit={ride?.returnTimeLowerLimit}
              isReturnDateFlexible={ride?.isReturnDateFlexible}
              leg={ride?.connectedLeg?.leg}
              seqId={ride?.connectedLeg?.seqId}
            />
          </div>
        </div>
      )}

      {
        <div className={styles.modalContent}>
          <p className={styles.modalDescription}>
            Are you sure you want to Reschedule ride?
          </p>
          <div className={styles.modalBtns}>
            <div>
              <Button
                myClassName={styles.navigationBtn}
                onClick={handleConfirm}
              >
                OK
              </Button>
            </div>
            <div>
              <Button myClassName={styles.navigationBtn} onClick={handleCancel}>
                Cancel
              </Button>
            </div>
          </div>
        </div>
      }
    </>
  </Modal>
);
