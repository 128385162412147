import React from 'react';
import { Field, getIn } from 'formik';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';

import generatePicker from 'antd/lib/date-picker/generatePicker';

import { AntdDatePicker as Datepicker } from '../DatePicker/DatePicker';
import 'antd/lib/date-picker/style/index';

import { withTooltip } from '../../../hocs/withTooltip';

import styles from './index.module.css';

export const AntdDatePicker = generatePicker(dateFnsGenerateConfig);

const { RangePicker: AntdRangePicker } = Datepicker;

export const CustomRangePicker = ({
  onChange,
  onCalendarChange,
  value,
  field,
  form,
  format,
  placeholder,
  disabled,
  disabledDate,
  disabledTime,
  open,
  renderExtraFooter,
  myClassName,
  whenFieldDisabledShowTooltip,
  picker,
  showTime,
  inputReadOnly,
  allowEmpty,
  hideDisabledOptions,
  hideFooter,
  onPanelChange,
  placement,
  getPopupContainer,
}) => {
  const handleChange = (...date) => {
  
    if (onChange) {
      onChange({
        name: field.name,
        value: date[0],
        setFieldValue: form.setFieldValue,
        values: form.values,
        setValues: form.setValues,
      });
    } else form.setFieldValue([field.name], date);
  };

  return withTooltip(
    <AntdRangePicker
      picker={picker}
      open={open}
      name={field.name}
      allowClear
      placement={placement}
      hideDisabledOptions={hideDisabledOptions}
      onChange={handleChange}
      onCalendarChange={handleChange}
      onPanelChange={onPanelChange}
      allowEmpty={allowEmpty}
      value={value ? value : []}
      format={format}
      showTime={showTime}
      disabled={disabled}
      onSelect={handleChange}
      onBlur={() => form.setTouched({ ...form.touched, [field.name]: true })}
      className={classNames({
        [styles.datePicker]: true,
        [myClassName]: myClassName,
        [styles.error]: !disabled && !getIn(form.values, field.name) && getIn(form.errors, field.name),
        [styles.error]:
          !disabled && (getIn(form.touched, field.name) || form.touched[field.name]) && getIn(form.errors, field.name),
      })}
      popupClassName={classNames({
        [styles.popUp]: true,
        [styles.hideFooter]: hideFooter,
      })}
      placeholder={placeholder}
      disabledDate={disabledDate}
      disabledTime={disabledTime}
      renderExtraFooter={renderExtraFooter}
      inputReadOnly={inputReadOnly}
      data-testid='rangepicker'
      getPopupContainer={getPopupContainer}
    />,
    whenFieldDisabledShowTooltip,
  );
};

export const RangePicker = ({
  open,
  name,
  value,
  onChange,
  disabled,
  format,
  disabledDate,
  disabledTime,
  onSelect,
  renderExtraFooter,
  myClassName,
  whenFieldDisabledShowTooltip,
  picker = 'date',
  showTime,
  placeholder,
  onCalendarChange,
  inputReadOnly,
  allowEmpty,
  hideDisabledOptions,
  hideFooter,
  onPanelChange,
  placement,
  getPopupContainer,
}) => {
  return (
    <Field name={name} disabled={disabled} onChange={onChange} value={value}>
      {({ form, field }) => {
        return (
          <>
            <CustomRangePicker
              form={form}
              field={field}
              open={open}
              name={name}
              format={format}
              disabled={disabled}
              onChange={onChange}
              onSelect={onSelect}
              onCalendarChange={onCalendarChange}
              renderExtraFooter={renderExtraFooter}
              value={value}
              disabledDate={disabledDate}
              disabledTime={disabledTime}
              myClassName={myClassName}
              whenFieldDisabledShowTooltip={whenFieldDisabledShowTooltip}
              picker={picker}
              showTime={showTime}
              placeholder={placeholder}
              inputReadOnly={inputReadOnly}
              allowEmpty={allowEmpty}
              hideDisabledOptions={hideDisabledOptions}
              hideFooter={hideFooter}
              onPanelChange={onPanelChange}
              placement={placement}
              getPopupContainer={getPopupContainer}
            />
            {!disabled &&
              (getIn(form.touched, field.name) || form.touched[field.name]) &&
              getIn(form.errors, field.name) && (
                <div className={styles.errorMessage} role='alert'>
                  {getIn(form.errors, field.name)}
                </div>
              )}
          </>
        );
      }}
    </Field>
  );
};

RangePicker.propTypes = {
  format: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.array,
  onChange: PropTypes.func,
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
  whenFieldDisabledShowTooltip: PropTypes.bool,
};
