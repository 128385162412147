import React, { useCallback, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { clearRidersInfo, fetchRiders, changeRiderStatus } from '../../../store/riders';

import { renderColumns, transformData } from './renderColumns';
import { useCurrentRole } from '../../../hooks/useCurrentRole';
import { redirectToPage } from '../../../utils/helpers';
import { useSearch } from '../../../hooks/useSearch';
import { usePagination } from '../../../hooks/usePagination';
import { EntitiesTableList } from '../../../components';
import { Navigation } from '../../../utils/navigation';

const Riders = ({
  riders,
  fetchRiders,
  clearRidersInfo,
  role,
  limit,
  currentPage,
  total,
  totalPages,
  changeRiderStatus,
}) => {
  
  const navigate = Navigation();
  
  const { searchValue, highlighted, handleChangeSearch } = useSearch(fetchRiders);
  const { isMHAdmin, isHOWorker } = useCurrentRole(role);

  usePagination(navigate);

  useEffect(() => {
    fetchRiders({ filtered:false,Navigation : navigate});

    return ()=>{};
  }, [fetchRiders]);

  useEffect(() =>()=> clearRidersInfo(), [clearRidersInfo]);

  const handleManageRider = useCallback((id) => () => navigate.push(`${navigate?.location?.pathname}/${id}/edit`), []);

  const handleChangePage = (page) => {
    redirectToPage(navigate?.location?.pathname, page,navigate);
    fetchRiders({Navigation:navigate});
  };

  const switchRiderStatus = useCallback((payload) => () => changeRiderStatus({...payload,Navigation:navigate}), [changeRiderStatus]);

  const tableData = useMemo(() => {
    return transformData({
      data: riders,
      switchRiderStatus,
      handleManageRider,
      isHOWorker,
      Navigation:navigate
    });
  }, [riders, switchRiderStatus, handleManageRider, isHOWorker,navigate]);

  const columns = useMemo(() => {
    return role && riders?.length > 0 ? renderColumns(isMHAdmin, searchValue, highlighted) : null;
  }, [role, riders, isMHAdmin, highlighted, searchValue]);

  return (
    <EntitiesTableList
      title='Riders'
      addNewBtnTitle='Add New Rider'
      Navigation={navigate}
      columns={columns}
      data={tableData}
      handleChangePage={handleChangePage}
      showAddNewButton={isHOWorker}
      handleChangeSearch={handleChangeSearch}
      searchValue={searchValue}
      searchPlaceholder='Search by name'
      limit={limit}
      currentPage={currentPage}
      total={total}
      totalPages={totalPages}
    />
  
   
  );
};

const mapStateToProps = (state) => ({
  riders: state.riders.riders,
  limit: state.riders.limit,
  currentPage: state.riders.currentPage,
  total: state.riders.total,
  totalPages: state.riders.totalPages,
  role: state.authorization.role,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchRiders, clearRidersInfo, changeRiderStatus }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Riders);
