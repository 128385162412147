import React, { useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

import { Input } from '../../../../';
import { FilterDropDown } from '../../FilterDropdown/FilterDropdown';

import styles from './index.module.css';

export const SearchDropdownOverlay = ({ Component, placeholder, name, values, setFieldValue, ...rest }) => {
  const [visible, setVisible] = useState(false);
  return (
    <th className='ant-table-cell'>
      <FilterDropDown
        visible={visible}
        content={
          <div className={styles.dropdownContent}>
            <Input
              type='text'
              name={name}
              placeholder={placeholder}
              suffix={<SearchOutlined style={{ color: '#8C8C8C' }} />}
            />
          </div>
        }
        onVisibleChange={setVisible}
        name={name}
        values={values}
        setFieldValue={setFieldValue}
        {...rest}>
        <Component visible={visible} />
      </FilterDropDown>
    </th>
  );
};

SearchDropdownOverlay.propTypes = {
  Component: PropTypes.elementType,
  name: PropTypes.string,
  placeholder: PropTypes.string,
};
