import React from 'react';
import { MONTHL_TYPE, DISABLED_FIELD_TOOLTIP_CONTENT } from '../../../../constants/constants';
import { DateHelper } from '../../../../utils';
import { Select } from '../../../formControls/Select/Select';
import { Tooltip } from '../../..';
import styles from './index.module.css';

export const MonthPicker = ({ name, options, disabled, showDisabledTooltip }) => {
  const handleChangeMonthPicker = async ({ name, value, values, setFieldValue, validateForm }) => {
    value === MONTHL_TYPE.BY_WEEK_INDEX &&
      setFieldValue(
        'recurrenceRule.weekIndex',
        DateHelper.getNumberOfDayOccurrencesInWeek(
          DateHelper.utcToZonedTime(values?.pickUpDate?.datetime, values.pickUpDate.timezone),
        ),
      );
    await setFieldValue(name, value);
    await validateForm();
  };
  return (
    <div className={styles.monthlyPicker} data-testid='monthlyPicker'>
      <span className={styles.required}>Repeat on</span>
      <Tooltip
        title={<span>{DISABLED_FIELD_TOOLTIP_CONTENT}</span>}
        isVisible={showDisabledTooltip}
        applyEditTooltipOverlayClassname
        applyEditTooltipChildrenClassname={showDisabledTooltip}>
        <Select
          name={name}
          options={options}
          placeholder='Monthly picker'
          onChange={handleChangeMonthPicker}
          disabled={disabled}
        />
      </Tooltip>
    </div>
  );
};
