import React from 'react';
import PropTypes from 'prop-types';

import { renderRecordValue } from '../../../../utils';
import { Record } from '../RideDetails';
import { RiderPhoneCommunicationTypes, RiderPhoneInfo } from './RiderPhoneInfo';

import styles from '../index.module.css';

export const CaregiversInfo = ({ caregivers, showFullInfo }) => (
  <>
    {caregivers.map((caregiver, idx) => {
      return (
        <div className={styles.caregiver} key={caregiver.firstName}>
          <div className={styles.ridersInfo}>
            <Record
              title={`Caregiver #${idx + 1}`}
              value={`${renderRecordValue(caregiver?.firstName)} ${renderRecordValue(caregiver?.lastName)} `}
            />
            {showFullInfo && (
              <>
                <span className={styles.separator}></span>
                <RiderPhoneInfo phoneNumber={caregiver?.phoneNumber} />
              </>
            )}
          </div>
          <RiderPhoneCommunicationTypes data={caregiver} />

          {caregiver?.email && (
            <Record
              title={`Email`}
              value={
                <a href={`mailto:${renderRecordValue(caregiver?.email)}`} className={styles.caregiverEmail}>
                  {renderRecordValue(caregiver?.email)}
                </a>
              }
              myClassName={styles.caregiverEmail}
            />
          )}
        </div>
      );
    })}
  </>
);

CaregiversInfo.propTypes = {
  riderInfo: PropTypes.object,
  title: PropTypes.string,
  showFullInfo: PropTypes.bool,
};
