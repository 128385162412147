import styles from './index.module.css';

export const STATUSES_STYLING = {
  Accepted: styles.accepted,
  Pending: styles.pending,
  'In progress': styles.inProgress,
  Completed: styles.completed,
  Canceled: styles.canceled,
  'Unable to fulfill': styles.unableToFulfill,
  'New request': styles.request,
  Expired: styles.expired,
  Claimed: styles.pending,
  Declined: styles.expired,
  Unfulfilled: styles.expired,
};
