import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SetEmail from '../../common/SetEmail/SetEmail';

import { forgotPassword } from '../../../store/authorization';
import { Navigation } from '../../../utils/navigation';

const ForgotPassword = ({   forgotPassword }) => {
  const navigate = Navigation();
  return (
    <SetEmail
      navigate={navigate}
      handleSubmit={forgotPassword}
      pageHeader='Forgot Password'
      pageDescription='Enter the email address you used when you joined and we’ll send you instructions to reset your password'
      submitButtonText='Send instructions to this address'
      showBackBtn={true}
    />
  );
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ forgotPassword }, dispatch);

export default connect(null, mapDispatchToProps)(ForgotPassword);
