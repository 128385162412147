import { Formik } from 'formik';
import { SecurityPreferencesPassword } from '../../..';
import { updatePasswordSchema } from '../../../../utils';

import styles from './index.module.css';

export const EmailSettings = ({ securityRef, changePassword, setTwoFactorModalVisible }) => {
  return (
    <Formik
      innerRef={securityRef}
      initialValues={{
        newPassword: '',
        oldPassword: '',
      }}
      validationSchema={updatePasswordSchema}
      onSubmit={(values) => {
        changePassword(values).then((res) => setTwoFactorModalVisible(res));
      }}>
      {({ values, handleSubmit, ...rest }) => {
        return (
          <form onSubmit={handleSubmit} className={styles.personalInfoForm}>
            <SecurityPreferencesPassword values={values} {...rest} />
          </form>
        );
      }}
    </Formik>
  );
};
