import { USER_STATUSES } from '../constants/constants';
import { baseRequest } from '../utils';
import { getPage, redirectToPage } from '../utils/helpers';
import { types as commonTypes } from './common';

const types = {
  CREATE_RIDER_REQUEST: 'CREATE_RIDER_REQUEST',
  CREATE_RIDER_SUCCESS: 'CREATE_RIDER_SUCCESS',
  CREATE_RIDER_ERROR: 'CREATE_RIDER_ERROR',

  UPDATE_RIDER_REQUEST: 'UPDATE_RIDER_REQUEST',
  UPDATE_RIDER_SUCCESS: 'UPDATE_RIDER_SUCCESS',
  UPDATE_RIDER_ERROR: 'UPDATE_RIDER_ERROR',

  FETCH_RIDERS_REQUEST: 'FETCH_RIDERS_REQUEST',
  FETCH_RIDERS_SUCCESS: 'FETCH_RIDERS_SUCCESS',
  FETCH_RIDERS_ERROR: 'FETCH_RIDERS_ERROR',

  FETCH_RIDER_DETAILS_REQUEST: 'FETCH_RIDER_DETAILS_REQUEST',
  FETCH_RIDER_DETAILS_SUCCESS: 'FETCH_RIDER_DETAILS_SUCCESS',
  FETCH_RIDER_DETAILS_ERROR: 'FETCH_RIDER_DETAILS_ERROR',

  CHANGE_RIDER_STATUS_REQUEST: 'CHANGE_RIDER_STATUS_REQUEST',
  CHANGE_RIDER_STATUS_SUCCESS: 'CHANGE_RIDER_STATUS_SUCCESS',
  CHANGE_RIDER_STATUS_ERROR: 'CHANGE_RIDER_STATUS_ERROR',

  CHECK_RIDER_IS_UNIQUE_REQUEST: 'CHECK_RIDER_IS_UNIQUE_REQUEST',
  CHECK_RIDER_IS_UNIQUE_SUCCESS: 'CHECK_RIDER_IS_UNIQUE_SUCCESS',
  CHECK_RIDER_IS_UNIQUE_ERROR: 'CHECK_RIDER_IS_UNIQUE_ERROR',

  CHECK_RIDER_FOR_MATCHING_REQUEST: 'CHECK_RIDER_FOR_MATCHING_REQUEST',
  CHECK_RIDER_FOR_MATCHING_SUCCESS: 'CHECK_RIDER_FOR_MATCHING_SUCCESS',
  CHECK_RIDER_FOR_MATCHING_ERROR: 'CHECK_RIDER_FOR_MATCHING_ERROR',

  SET_CURRENT_PAGE_RIDERS: 'SET_CURRENT_PAGE_RIDERS',

  SET_RIDER_INFO: 'SET_RIDER_INFO',

  RESET_IS_UNIQUE_CHECK_RESULTS: 'RESET_IS_UNIQUE_CHECK_RESULTS',

  CLEAR_RIDERS_INFO: 'CLEAR_RIDERS_INFO',
  CLEAR_RIDER_DETAILS: 'CLEAR_RIDER_DETAILS',
};

export const setRiderInfo = (payload) => ({
  type: types.SET_RIDER_INFO,
  payload,
});

const createRiderRequest = () => ({
  type: types.CREATE_RIDER_REQUEST,
});

const fetchRidersRequest = () => ({
  type: types.FETCH_RIDERS_REQUEST,
});

const updateRiderRequest = () => ({
  type: types.UPDATE_RIDER_REQUEST,
});

const fetchRiderDetailsRequest = () => ({
  type: types.FETCH_RIDER_DETAILS_REQUEST,
});

export const clearRidersInfo = () => ({
  type: types.CLEAR_RIDERS_INFO,
});

export const clearRiderDetails = () => ({
  type: types.CLEAR_RIDER_DETAILS,
});

export const changeRiderStatusRequest = () => ({
  type: types.CHANGE_RIDER_STATUS_REQUEST,
});

export const setCurrentPage = (page) => ({
  type: types.SET_CURRENT_PAGE_RIDERS,
  payload: page,
});

const checkRiderIsUniqueRequest = () => ({
  type: types.CHECK_RIDER_IS_UNIQUE_REQUEST,
});

const checkRiderForMatchingRequest = () => ({
  type: types.CHECK_RIDER_IS_UNIQUE_REQUEST,
});

export const checkRiderIsUnique = (payload) => {
  const success = (response) => {
    return {
      type: types.CHECK_RIDER_IS_UNIQUE_SUCCESS,
      payload: response,
    };
  };

  const failure = (error) => ({
    type: types.CHECK_RIDER_IS_UNIQUE_ERROR,
    payload: error,
  });

  return (dispatch) => {
    dispatch(checkRiderIsUniqueRequest());
    return baseRequest('POST', '/patients/is-unique', payload)
      .then(() => {
        dispatch(success(true));
      })
      .catch((error) => {
        if (error && error.response.status === 409) dispatch(failure(false));
        dispatch(failure(true));
      });
  };
};

export const checkRiderForMatching = (payload) => {
  const success = (response) => {
    return {
      type: types.CHECK_RIDER_FOR_MATCHING_SUCCESS,
      payload: response,
    };
  };

  const failure = (error) => ({
    type: types.CHECK_RIDER_FOR_MATCHING_ERROR,
    payload: error,
  });

  return (dispatch) => {
    dispatch(checkRiderForMatchingRequest());
    return baseRequest('POST', '/patients/matching', payload)
      .then((response) => {
        dispatch(success(response.data));
      })
      .catch((error) => {
        if (error && error?.response?.status === 409) dispatch(failure(false));
        dispatch(failure(true));
      });
  };
};

export const resetIsUniqueCheckResults = () => ({
  type: types.RESET_IS_UNIQUE_CHECK_RESULTS,
});

export const createRider = (payload,Navigation) => {
  const navigate =Navigation;
  const success = (response) => {
    return {
      type: types.CREATE_RIDER_SUCCESS,
      payload: response,
    };
  };

  const failure = (error) => ({
    type: types.CREATE_RIDER_ERROR,
    payload: error,
  });

  return (dispatch) => {
    dispatch(createRiderRequest());
    return baseRequest('POST', '/patients', payload)
      .then((response) => {
        dispatch(success(response.data));
        navigate.back();
      })
      .catch((error) => {
        if (error) dispatch(failure(error?.response?.data));
      });
  };
};

export const fetchRiders = ({value, filtered,Navigation}) => {
  
   const { location } = Navigation;

   const success = (response) => {
     return {
       type: types.FETCH_RIDERS_SUCCESS,
       payload: response,
     };
   };

  const failure = (error) => ({
    type: types.FETCH_RIDERS_ERROR,
    payload: error,
  });

  return (dispatch, getStore) => {
    if (filtered) redirectToPage(location.pathname, 1,Navigation);
    const { limit } = getStore().riders;
    const currentPage = getPage(location);
    dispatch(setCurrentPage(currentPage));
    dispatch(fetchRidersRequest());
    return baseRequest(
      'GET',
      `/patients`,
      null,
      {},
      {
        docsPerPage: limit,
        page: currentPage,
        value: value || '',
      },
    )
      .then((response) => dispatch(success(response.data)))
      .catch((error) => {
        if (error) dispatch(failure([]));
      });
  };
};

export const getRiderDetails = (id) => {
  const success = (response) => {
    return {
      type: types.FETCH_RIDER_DETAILS_SUCCESS,
      payload: response,
    };
  };

  const failure = (error) => ({
    type: types.FETCH_RIDER_DETAILS_ERROR,
    payload: error,
  });

  return (dispatch) => {
    dispatch(fetchRiderDetailsRequest());
    return baseRequest('GET', `/patients/${id}`)
      .then((response) => dispatch(success(response.data)))
      .catch((error) => {
        if (error) dispatch(failure(error?.response?.data));
      });
  };
};

export const updateRiderPhone = ({ id, values }) => {
  const success = (response) => {
    return {
      type: types.UPDATE_RIDER_SUCCESS,
      payload: response,
    };
  };

  const failure = (error) => ({
    type: types.UPDATE_RIDER_ERROR,
    payload: error,
  });

  return (dispatch) => {
    dispatch(updateRiderRequest());
    return baseRequest('PUT', `/patients/${id}/phone`, values)
      .then((response) => {
        return dispatch(success(response.data));
      })
      .catch((error) => {
        if (error) dispatch(failure(error?.response?.data));
      });
  };
};

export const updateRider = ({ id, values,Navigation }) => {
 
  const navigate = Navigation;
  const success = (response) => {
    return {
      type: types.UPDATE_RIDER_SUCCESS,
      payload: response,
    };
  };

  const failure = (error) => ({
    type: types.UPDATE_RIDER_ERROR,
    payload: error,
  });

  return (dispatch) => {
    dispatch(updateRiderRequest());
    return baseRequest('PUT', `/patients/${id}`, values)
      .then((response) => {
        dispatch(success(response.data));
        navigate.back();
      })
      .catch((error) => {
        if (error) dispatch(failure(error?.response?.data));
      });
  };
};

export const changeRiderStatus = ({ id, status ,Navigation }) => {
  const {back} = Navigation;
  const success = (response) => {
    return {
      type: types.CHANGE_RIDER_STATUS_SUCCESS,
      payload: response,
    };
  };

  const failure = (error) => ({
    type: types.CHANGE_RIDER_STATUS_ERROR,
    payload: error,
  });

  return (dispatch) => {
    dispatch(changeRiderStatusRequest());
    return baseRequest('PATCH', `/patients/${id}`, status)
      .then((response) => {
        dispatch(success(response.data));
        if (status.status === USER_STATUSES.DISABLED) back();
      })
      .catch((error) => {
        if (error) dispatch(failure(error?.response?.data));
      });
  };
};

const initialState = {
  riders: [],
  riderDetails: null,
  loading: false,
  riderIsUnique: true,
  limit: 10,
  currentPage: 1,
  totalPages: 1,
  total: 1,
};

export default function riders(state = initialState, action) {
  switch (action.type) {
    case types.CREATE_RIDER_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.CREATE_RIDER_SUCCESS:
      return {
        ...state,
        riders: [...state.riders, action.payload],
        loading: false,
      };

    case types.CREATE_RIDER_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.FETCH_RIDERS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.FETCH_RIDERS_SUCCESS:
      return {
        ...state,
        riders: action.payload?.data,
        currentPage: action.payload?.meta?.page,
        totalPages: action.payload?.meta?.totalPages,
        total: action.payload?.meta?.total,

        loading: false,
      };

    case types.FETCH_RIDERS_ERROR:
      return {
        ...state,
        loading: false,
        riders: action.payload,
        currentPage: 1,
        totalPages: 1,
        total: 1,
      };

    case types.UPDATE_RIDER_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.UPDATE_RIDER_SUCCESS:
      const riderIdx = state.riders.findIndex((r) => r.id === action.payload.id);
      let copiedRiders = [...state.riders];
      if (riderIdx > 0) {
        copiedRiders[riderIdx] = { ...action.payload };
      }
      return {
        ...state,
        riders: copiedRiders,
        riderDetails: action.payload,
        loading: false,
      };

    case types.UPDATE_RIDER_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.FETCH_RIDER_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
        riderDetails: null,
      };

    case types.FETCH_RIDER_DETAILS_SUCCESS:
      return {
        ...state,
        riderDetails: { ...action.payload },
        loading: false,
      };

    case types.FETCH_RIDER_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.CLEAR_RIDERS_INFO:
      return {
        ...state,
        riders: [],
        hasMatchedRiders: false,
        currentPage: 1,
        totalPages: 1,
        total: 1,
      };

    case types.CLEAR_RIDER_DETAILS:
      return {
        ...state,
        riderDetails: null,
      };

    case types.SET_CURRENT_PAGE_RIDERS:
      return {
        ...state,
        currentPage: action.payload,
      };

    case types.CHANGE_RIDER_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.CHANGE_RIDER_STATUS_SUCCESS:
      const updatedRiderIdx = state.riders.findIndex((u) => u.id === action.payload.id);
      const clonedRiders = [...state.riders];
      clonedRiders[updatedRiderIdx] = action.payload;
      return {
        ...state,
        riderDetails: action.payload,
        riders: clonedRiders,
        loading: false,
      };

    case types.CHECK_RIDER_IS_UNIQUE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.CHECK_RIDER_IS_UNIQUE_SUCCESS:
      return {
        ...state,
        loading: false,
        riderIsUnique: true,
      };
    case types.CHECK_RIDER_IS_UNIQUE_ERROR:
      return {
        ...state,
        loading: false,
        riderIsUnique: false,
      };

    case types.CHECK_RIDER_FOR_MATCHING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.CHECK_RIDER_FOR_MATCHING_SUCCESS:
      return {
        ...state,
        loading: false,
        riders: action.payload || [],
        hasMatchedRiders: action?.payload?.length > 0,
      };
    case types.CHECK_RIDER_FOR_MATCHING_ERROR:
      return {
        ...state,
        loading: false,
        hasMatchedRiders: false,
        riders: [],
      };

    case types.SET_RIDER_INFO:
      return {
        ...state,
        riderDetails: action.payload,
      };

    case types.RESET_IS_UNIQUE_CHECK_RESULTS:
      return {
        ...state,
        riderIsUnique: true,
      };

    case types.CHANGE_RIDER_STATUS_ERROR:
      return {
        ...state,
        loading: false,
      };
    case commonTypes.CLEAR_STORE_INFO:
      return {
        ...initialState,
        currentPage: 1,
      };

    default:
      return state;
  }
}
