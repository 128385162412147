import React, { Fragment } from 'react';
import { Radio as AndtRadio } from 'antd';

import PropTypes from 'prop-types';
import { CustomField } from '../CustomField/CustomField';

import { withTooltip } from '../../../hocs/withTooltip';

import styles from './index.module.css';

const CustomRadio = ({
  field,
  form,
  type,
  checked,
  defaultValue,
  onChange,
  value,
  prefix,
  suffix,
  radioFieldsCount,
  whenFieldDisabledShowTooltip,
  ...rest
}) => {
  const handleChange = (event) => {
    if (onChange) {
      onChange({ event, setFieldValue: form.setFieldValue, setValues: form.setValues, values: form.values });
    } else field.onChange(event);
  };

  const handleBlur = () => {
    form.setTouched({ ...form.touched, [field.name]: true });
  };

  return (
    <AndtRadio.Group
      name={field.name}
      onChange={handleChange}
      value={value || field.value}
      className={styles.radioGroup}>
      {radioFieldsCount.map((radioInput) => {
        return (
          <Fragment key={radioInput.value}>
            {withTooltip(
              <AndtRadio
                {...rest}
                className={styles.radioButton}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={(rest.disabled ||radioInput.disabled )}
                value={radioInput.value}
                role='radio'>
                {radioInput.title}
              </AndtRadio>,
              whenFieldDisabledShowTooltip,
            )}
          </Fragment>
        );
      })}
    </AndtRadio.Group>
  );
};

export const RadioGroup = ({ name, value, onChange, radioFieldsCount, whenFieldDisabledShowTooltip, fastField,disabled }) => {
  return (
    <CustomField
      fastField={fastField}
      name={name}
      onChange={onChange}
      value={value}
      radioFieldsCount={radioFieldsCount}>
      {({ field, form }) => (
        <CustomRadio
          form={form}
          field={field}
          name={name}
          onChange={onChange}
          disabled = {disabled}
          value={value}
          radioFieldsCount={radioFieldsCount}
          whenFieldDisabledShowTooltip={whenFieldDisabledShowTooltip}
        />
      )}
    </CustomField>
  );
};

RadioGroup.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onChange: PropTypes.func,
  radioFieldsCount: PropTypes.array,
  whenFieldDisabledShowTooltip: PropTypes.bool,
};
