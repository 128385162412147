import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { generateRideRequestSteps, LodashHelper, transformRideRequestFields } from '../../../utils';
import { Stepper } from '../../../components';
import { BackIcon } from '../../../assets';
import { renderSteps } from './renderSteps';
import {
  setCurrentStep,
  getDraft,
  createDraft,
  updateDraft,
  resetDraft,
  saveDraft,
  deleteDraft,
  setUnSavedDraft,
} from '../../../store/drafts';
import { getPlatformSettings, getAdditionalRideOptions } from '../../../store/preferences';
import { fetchHOPreferences } from '../../../store/health_organizations';
import { stepperInfo } from '../../../components/forms/CreateRide/stepperInfo';
import { usePreventLeave } from '../../../hooks/usePreventLeave';
import { MANDATORY_LEVEL, RIDE_REQUEST_FIELDS, THIRD_STEP_RIDE_REQUEST_FIELDS } from '../../../constants/constants';
import { renderEditLeaveModal } from '../EditDraft/renderEditLeaveModal';
import { renderCreateRideModal } from './renderCreateRideModal';
import { useCreateRideHandlers } from './useCreateRideHandlers';
import { createRideSchema } from '../../../utils/validations';
import { checkStepIsDisabled, checkStepStatus } from '../../../utils/helpers';

import styles from './index.module.css';
import { Navigation } from '../../../utils/navigation';

const CreateRide = ({
  step,
  draftId,
  getDraft,
  airportBufferTime,
  draft,
  history,
  getPlatformSettings,
  platformPreferences,
  defaultTimeZone,
  fetchHOPreferences,
  HOPreferences,
  getAdditionalRideOptions,
  rideOptions,
  isDraftEditMode = false,
  setUnSavedDraft,
}) => {
  const [rideRequestFields, setRideRequestFields] = useState(null);
  const [showThirdStep, setShowThirdStep] = useState(true);

  const [showCovidField, setShowCoviedField] = useState(false);

  const [formikValues, setFormikValues] = useState(null);

  const [formIsValid, setFormIsValid] = useState(false);

  const [draftIsValid, setDraftIsValid] = useState(null);

  const [visibleModal, setModalVisible, unblockHistory, location] = usePreventLeave(
    formikValues,
    stepperInfo[0].values,
  );
const navigate = Navigation();
  const syncFormikValues = useCallback(
    (values, isValid, path) => {
      setFormikValues(values);
      setFormIsValid((prevValues) => {
        return {
          ...prevValues,
          [path]: isValid,
        };
      });
    },

    [],
  );

  const costCenterField = useMemo(
    () => ({
      show: rideRequestFields?.some((field) => field.name === RIDE_REQUEST_FIELDS.costCenter),
      isRequired:
        rideRequestFields?.find((field) => field.name === RIDE_REQUEST_FIELDS.costCenter)?.value ===
        MANDATORY_LEVEL.MANDATORY,
    }),
    [rideRequestFields],
  );
  const showAdditionalSourceField = useMemo(
    () => ({
      show: rideRequestFields?.some((field) => field.name === RIDE_REQUEST_FIELDS.additionalFundingSource),
      isRequired:
        rideRequestFields?.find((field) => field.name === RIDE_REQUEST_FIELDS.additionalFundingSource)?.value ===
        MANDATORY_LEVEL.MANDATORY,
    }),
    [rideRequestFields],
  );
  const showDocumentReasonForRideField = useMemo(
    () => ({
      show: rideRequestFields?.some((field) => field.name === RIDE_REQUEST_FIELDS.documentReasonForRide),
      isRequired:
        rideRequestFields?.find((field) => field.name === RIDE_REQUEST_FIELDS.documentReasonForRide)?.value ===
        MANDATORY_LEVEL.MANDATORY,
    }),
    [rideRequestFields],
  );

  useEffect(() => {
    rideRequestFields &&
      setShowCoviedField(rideRequestFields?.some((field) => field.name === RIDE_REQUEST_FIELDS.covid19Relation));
      return;
  }, [rideRequestFields]);

  useEffect(() => {
    draft &&
      costCenterField &&
      showAdditionalSourceField &&
      showDocumentReasonForRideField &&
      createRideSchema({
        optionalStepRules: { costCenterField, showAdditionalSourceField, showDocumentReasonForRideField },
        covidFieldIsRequired: showCovidField,
      })
        .validate(draft, { abortEarly: false })
        .then((res) => {
          return setDraftIsValid([true, true, true, true, true]);
        })
        .catch((error) => {
          const step1IsValid = !error?.inner?.some((innerError) => innerError.path.includes('step1'));
          const step2IsValid = !error?.inner?.some((innerError) => innerError.path.includes('step2'));
          const step3IsValid = !error?.inner?.some((innerError) => innerError.path.includes('step3'));
          const step4IsValid =
            costCenterField?.show &&
            step3IsValid &&
            !error?.inner?.some((innerError) => innerError.path.includes('step4'));
          setDraftIsValid([step1IsValid, step2IsValid, step3IsValid, step4IsValid]);
        });

        return;
  }, [draft, costCenterField, showAdditionalSourceField, showDocumentReasonForRideField, showCovidField]);

  const {
    handleDelete,
    handleStay,
    handlePreviousStep,
    handleSaveAndClose,
    handleSave,
    handleBack,
    handleLeave,
    handleSubmit,
  } = useCreateRideHandlers({
    Navigation:navigate,
    step,
    history,
    formikValues,
    draft,
    draftId,
    showThirdStep,
    unblockHistory,
    setModalVisible,
    location,
    draftIsValid,
  });

  useEffect(() => {
    if (draftId) getDraft(draftId);
    return;
  }, [draftId, getDraft]);

  useEffect(() => {
    if (rideRequestFields)
      setShowThirdStep(rideRequestFields.some((field) => THIRD_STEP_RIDE_REQUEST_FIELDS[field.name]));

      return;
  }, [rideRequestFields]);

  useEffect(() => {
    if (HOPreferences) setRideRequestFields(transformRideRequestFields(HOPreferences?.rideRequestFields));
    return;
  }, [HOPreferences]);

  useEffect(() => {fetchHOPreferences();    return;}, [fetchHOPreferences]);

  useEffect(() => {getAdditionalRideOptions();    return;}, [getAdditionalRideOptions]);

  useEffect(() => {getPlatformSettings();    return;}, [getPlatformSettings]);

  useEffect(() => () => {resetDraft();    return;});

  useEffect(() => {
    if (step !== 4) {
      setUnSavedDraft({ [`step${step + 1}`]: LodashHelper.trimValues(formikValues) });
    }
    return;
  }, [draft, draftId, setUnSavedDraft, step, formikValues]);

  const handleNavigateThroughStepper = async (current) => {
    const checkIfFormIsValid = Number(step) !== 4 ? formIsValid[`step${step + 1}`] : true;
    const allowNavigateForward =
      (draftIsValid?.[current] && checkIfFormIsValid && current > step) ||
      (formIsValid[`step${step + 1}`] && current - step === 1) ||
      draftIsValid?.[current - 1] ||
      (current === 4 && !showThirdStep);
    const allowNavigateBack = draftIsValid?.[current] && (checkIfFormIsValid || current < step);

    if (allowNavigateForward) {
      handleSubmit(formikValues, String(current));
    } else if (allowNavigateBack) {
      handlePreviousStep(String(current));
    }
  };

  const steps = useMemo(() => {
    const generatedSteps = generateRideRequestSteps().map((s, index, steps) => {
      const stepIsDisabled = checkStepIsDisabled({ steps, step, index, draftIsValid, formIsValid, showThirdStep });

      const stepStatus = checkStepStatus({ steps, step, index, draftIsValid, formIsValid, showThirdStep });

      s.disabled = stepIsDisabled;
      s.status = stepStatus;

      return s;
    });

    return generatedSteps;
  }, [draftIsValid, formIsValid, showThirdStep, step]);

  return (
    <div className={styles.createRide}>
      <div className={styles.header}>
        <div className={styles.backBtn} onClick={handleBack}>
          <BackIcon width='18px' height='18px' fill='#BDBDBD' />
        </div>
        <h1 className={styles.pageTitle}> Request a Ride</h1>
      </div>
      <div className={styles.steps}>
        <Stepper
          currentStep={step}
          showThirdStep={showThirdStep}
          steps={steps}
          handleNavigateThroughStepper={handleNavigateThroughStepper}
        />
      </div>
      {renderSteps({
        step,
        draft,
        airportBufferTime,
        handleSaveAndClose,
        handleSubmit,
        handlePreviousStep,
        platformPreferences,
        rideOptions,
        defaultTimeZone,
        handleSave,
        rideRequestFields,
        syncFormikValues,
        showCovidField,
        costCenterField,
        showAdditionalSourceField,
        showDocumentReasonForRideField,
      })}

      {isDraftEditMode
        ? renderEditLeaveModal({ visibleModal, handleStay, handleLeave })
        : renderCreateRideModal({
            draftId,
            visibleModal,
            handleStay,
            handleDelete,
            handleSaveAndClose,
            handleLeave,
          })}
    </div>
  );
};

const mapStateToProps = (state) => ({
  step: state.drafts.currentStep,
  draftId: state.drafts.draftId,
  draft: state.drafts.draft,
  platformPreferences: state.preferences.platformPreferences,
  defaultTimeZone: state.authorization?.user?.preferredTimezone,
  HOPreferences: state.healthOrganizations.HOPreferences,
  rideOptions: state.preferences.rideOptions?.filter((option) => !option.disabled),
  userInactivityModalVisible: state.common.userInactivityModalVisible,
  airportBufferTime : state?.healthOrganizations?.HOPreferences?.airportBufferTime
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setCurrentStep,
      getDraft,
      createDraft,
      updateDraft,
      resetDraft,
      saveDraft,
      deleteDraft,
      getPlatformSettings,
      fetchHOPreferences,
      getAdditionalRideOptions,
      setUnSavedDraft,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(CreateRide);
