import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { setPassword } from '../../../store/authorization';
import SetPassword from '../../common/SetPassword/SetPassword';

const SetNewPassword = ({ setPassword }) => {
  return (
    <SetPassword
      pageHeader='Reset Password'
      formLabel='Enter New Password'
      submitButtonText='Reset Password'
      handleSubmit={setPassword}
    />
  );
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ setPassword }, dispatch);

export default connect(null, mapDispatchToProps)(SetNewPassword);
