import React from 'react';

import { FilterCheckboxListCell, FilterRangeCell, FilterSearchCell } from '../../../components';
import { statusesDictionary, vehicleOptionsDictionary } from '../../../dictionaries';
import { renderRecordValue } from '../../../utils';
import { VEHICLE_OPTIONS, STATUSES_BY_ROLE, USER_ROLES } from '../../../constants/constants';

import styles from './index.module.css';

export const renderHeadCells = ({ values, setFieldValue, rideOptions, onClose }) => [
  {
    title: 'Ride ID',
    dataIndex: 'seqId',
    key: 'seqId',
    width: 150,
    Component: (
      <FilterSearchCell
        name='seqId'
        placeholder='Enter Ride ID'
        title='Ride ID'
        values={values}
        setFieldValue={setFieldValue}
        myClassName={styles.draftIdCell}
        onClose={onClose}
      />
    ),
  },

  {
    title: 'Rider Name',
    dataIndex: 'rider',
    key: 'rider',
    width: 180,
    Component: (
      <FilterSearchCell
        name='rider'
        title='Rider Name'
        placeholder='Enter Rider Name'
        values={values}
        setFieldValue={setFieldValue}
        myClassName={styles.riderCell}
        onClose={onClose}
      />
    ),
  },
  {
    title: 'Vehicle Type',
    dataIndex: 'vehicleType',
    key: 'vehicleType',
    width: 280,
    Component: (
      <FilterCheckboxListCell
        name='vehicleType'
        title='Vehicle Type'
        values={values}
        myClassName={styles.vehicleCell}
        value={renderRecordValue(vehicleOptionsDictionary?.[values?.vehicleType?.[0]])}
        setFieldValue={setFieldValue}
        options={VEHICLE_OPTIONS}
        onClose={onClose}
      />
    ),
  },
  {
    title: 'Ride Details',
    dataIndex: 'rideOptions',
    key: 'rideOptions',
    width: 200,
    Component: (
      <FilterCheckboxListCell
        name='rideOptions'
        title='Ride Details'
        values={values}
        myClassName={styles.rideOptionsCell}
        value={renderRecordValue(values?.rideOptions?.[0]?.value)}
        options={rideOptions}
        onClose={onClose}
        shouldRenderSorting={false}
      />
    ),
  },
  {
    title: 'Pickup Date',
    dataIndex: 'pickUpDate',
    key: 'pickUpDate',
    width: 250,
    Component: (
      <FilterRangeCell
        name='pickUpDate'
        title='Pickup Date'
        values={values}
        setFieldValue={setFieldValue}
        myClassName={styles.pickUpDateCell}
        onClose={onClose}
      />
    ),
  },

  {
    title: 'Driver Name',
    dataIndex: 'driver',
    key: 'driver',
    width: 150,
    Component: (
      <FilterSearchCell
        name='driver'
        title='Driver Name'
        placeholder='Enter Driver Name'
        values={values}
        setFieldValue={setFieldValue}
        myClassName={styles.driverCell}
        onClose={onClose}
      />
    ),
  },
  {
    title: 'Ride Status',
    dataIndex: 'readableStatus',
    key: 'readableStatus',
    width: 160,
    Component: (
      <FilterCheckboxListCell
        name='status'
        title='Ride Status'
        values={values}
        myClassName={styles.statusCell}
        options={STATUSES_BY_ROLE[USER_ROLES.TC_ADMIN]}
        value={renderRecordValue(statusesDictionary[values?.status?.[0]])}
        setFieldValue={setFieldValue}
        onClose={onClose}
      />
    ),
  },
];
