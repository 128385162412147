import React, { useCallback, useMemo, useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { Button, Modal } from '../..';
import { SUPER_ADMIN_TRANSPORT_COMPANY_EDIT_REASONS } from '../../../constants/constants';
import { reasonForUpdateTCSchema } from '../../../utils/validations';
import { Select } from '../../formControls/Select/Select';

import styles from './index.module.css';

const initialValues = { reasonForUpdate: '',};

export const useConfirmEditTransportCompanyModal = ({ onConfirm }) => {
  const [visibleModal, setModalVisible] = useState(false);

  const handleConfirm = useCallback(
    (values) => {
      onConfirm({ reasonForUpdate: values.reasonForUpdate });
    },
    [onConfirm],
  );

  const formik = useFormik({
    initialValues,
    validationSchema: reasonForUpdateTCSchema,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: handleConfirm,
  });

  const { resetForm, validateForm, isValid, handleSubmit } = formik;

  const onCancel = useCallback(async () => {
    await resetForm(initialValues);
    await validateForm();
    await setModalVisible(false);
  }, [setModalVisible, validateForm, resetForm]);

  const handleReasonForUpdate = useCallback(async ({ name, value, setFieldValue }) => {
    await setFieldValue(name, value);
  }, []);

  const saveChangesButtonIsDisabled = useMemo(() => {
    if (visibleModal) {
      return !isValid;
    } else return true;
  }, [visibleModal, isValid]);

  return {
    Modal: (
      <Modal
        visible={visibleModal}
        onCancel={onCancel}
        closable
        width={700}
        title={<h3 className={styles.modalTitle}>Warning - You are about to make changes to your data!</h3>}>
        <div className={styles.modalContent}>
          <p className={styles.modalDescription}>
            Please make sure that your changes are correct.
          </p>
          <FormikProvider value={formik}>
            <>
              <form className={styles.form}>
                <label className={styles.comment}>
                  <span className={styles.required}>Reason for changes</span>
                  <Select
                    name='reasonForUpdate'
                    onChange={handleReasonForUpdate}
                    options={SUPER_ADMIN_TRANSPORT_COMPANY_EDIT_REASONS}
                    placeholder='Pick a reason for update'
                  />
                </label>
              </form>

              <div className={styles.modalBtns}>
                <div>
                  <Button myClassName={styles.navigationBtn} onClick={onCancel}>
                    Back to Editing
                  </Button>
                </div>
                <div>
                  <Button
                    myClassName={styles.navigationBtn}
                    onClick={handleSubmit}
                    disabled={saveChangesButtonIsDisabled}>
                    Save Changes
                  </Button>
                </div>
              </div>
            </>
          </FormikProvider>
        </div>
      </Modal>
    ),
    setConfirmModalVisible: setModalVisible,
    confirmModalVisible: visibleModal,
  };
};
