import React, { useCallback, useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import classnames from 'classnames';
import { Button, Modal } from '../..';

import styles from './index.module.css';
import { RideDestinationInfo } from '../../common/RideDetails/RideDetailsPartials/RideDestinationInfo';
import { RidePickUpInfo } from '../../common/RideDetails/RideDetailsPartials/RidePickUpInfo';


export const useSameDayRoundTripConfirmationModal = ({ id, role, ride, title, description, onConfirm }) => {
  const [visibleModal, setModalVisible] = useState(false);

  const handleConfirm = useCallback(
    (values) => {
      onConfirm();
    },
    [onConfirm],
  );

  const formik = useFormik({

    onSubmit: handleConfirm,
  });

  const { handleSubmit } = formik;

  const onCancel = useCallback(async () => {


    await setModalVisible(false);
  }, [setModalVisible]);


  return {
    Modal: (
      <Modal
        visible={visibleModal}
        onCancel={onCancel}
        closable
        width='auto'
        bodyStyle={{ minHeight: '25vh' }}
        title={title}>
        <div className={ride?.connectedLeg && styles.modalContent}>
          <FormikProvider value={formik}>
            <>

              {ride?.connectedLeg && <div className={styles.routes}>
                <h3 className={styles.roundTripHeading}>{description}</h3>

                <h3 className={styles.roundTripHeadingText}>Roundtrip Ride</h3>
                <RidePickUpInfo
                  pickupAddressDetails={ride?.pickupAddressDetails}
                  destinationAddress={ride?.destinationAddress}
                  pickUpDate={ride?.pickUpDate}
                  riderDescription={ride?.riderDescription}
                  pickUpDateLowerLimit={ride?.pickUpDateLowerLimit}
                  isPickUpDateFlexible={ride?.isPickUpDateFlexible}
                  tcEstimatedPickUpDate={ride?.tcEstimatedPickUpDate}
                  securityGate={ride?.pickUpSecurityGateDetails}
                  flightInfo={ride?.flightInfo}
                  appointmentDate={ride?.appointmentDate}
                  isRecurringRide={ride?.isRecurring}
                  leg={ride?.leg}
                  seqId={ride?.seqId}
                />

                <div className={styles.destionationRouteInfo}>
                  <RideDestinationInfo
                    destinationAddress={ride?.destinationAddress}
                    originAddress={ride?.pickupAddressDetails}
                    showRoundtripInfo={
                      ride?.isRoundtrip && ride?.leg
                    }
                    securityGate={ride?.destinationSecurityGateDetail}
                    returnTime={ride?.connectedLeg?.pickUpDate}
                    returnTimeLowerLimit={ride?.returnTimeLowerLimit}
                    isReturnDateFlexible={ride?.isReturnDateFlexible}
                    leg={ride?.connectedLeg?.leg}
                    seqId={ride?.connectedLeg?.seqId}
                  />
                </div>
              </div>
              }



              <div className={classnames({
                [styles.modalBtns]: true,
                [styles.cancelBtns]: true,
              })}>
                <div className={styles.ml6}>
                  <Button myClassName={styles.navigationBtn} onClick={onCancel} >
                    {`Cancel`}
                  </Button>
                </div>

                <div className={styles.mr6}>
                  <Button myClassName={styles.navigationBtn} onClick={handleConfirm}>
                    {`Confirm`}
                  </Button>

                </div>

              </div>
            </>
          </FormikProvider>
        </div>
      </Modal>
    ),
    setCancelModalVisible: setModalVisible,
  };
};
