import { Formik } from 'formik';
import { AdditionalRideOptionsForm } from './partials/AdditionalRideOptions';

import styles from './index.module.css';

export const AdditionalRideOptionsSettings = ({
  activeOptions,
  archivedOptions,
  handleCreateOption,
  handleArchiveRideOption,
  handleUnarchiveRideOption,
  allOptions,
}) => {
  return (
    <Formik enableReinitialize validateOnMount initialValues={{ allOptions }}>
      {({ handleSubmit, values }) => {
        return (
          <form className={styles.form} onSubmit={handleSubmit}>
            <AdditionalRideOptionsForm
              values={values}
              activeOptions={activeOptions}
              archivedOptions={archivedOptions}
              handleCreateOption={handleCreateOption}
              handleArchiveRideOption={handleArchiveRideOption}
              handleUnarchiveRideOption={handleUnarchiveRideOption}
            />
          </form>
        );
      }}
    </Formik>
  );
};
