import React, { useCallback } from 'react';

import { Logo, Button } from '../../../components';
import { URLS } from '../../../constants/constants';

import styles from './index.module.css';
import { Navigation } from '../../../utils/navigation';

export const RideNotFound = () => {
  const {go} = Navigation();
  const handleMainBtn = useCallback(() => go(URLS.RIDES), [go]);

  return (
    <div className={styles.notFoundPage}>
      <Logo lg />
      <div className={styles.head}>
        <h2 className={styles.pageHeader}>The ride does not exist or is no longer available</h2>
      </div>
      <p className={styles.pageDescription}>Return to the home page by clicking the button below.</p>
      <div>
        <Button onClick={handleMainBtn}>Go to MedHaul Home</Button>
      </div>
    </div>
  );
};
