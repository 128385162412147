import { baseRequest } from "../utils";

const types = {
  FETCH_ADDRESSES_REQUEST: "FETCH_ADDRESSES_REQUEST",
  FETCH_ADDRESSES_SUCCESS: "FETCH_ADDRESSES_SUCCESS",
  FETCH_ADDRESSES_ERROR: "FETCH_ADDRESSES_ERROR",

  ADD_TO_ADDRESSBOOK_REQUEST: "ADD_TO_ADDRESSBOOK_REQUEST",
  ADD_TO_ADDRESSBOOK_SUCCESS: "ADD_TO_ADDRESSBOOK_SUCCESS",
  ADD_TO_ADDRESSBOOK_ERROR: "ADD_TO_ADDRESSBOOK_ERROR",

  UPDATE_ADDRESSBOOK_REQUEST: "UPDATE_ADDRESSBOOK_REQUEST",
  UPDATE_ADDRESSBOOK_SUCCESS: "UPDATE_ADDRESSBOOK_SUCCESS",
  UPDATE_ADDRESSBOOK_ERROR: "UPDATE_ADDRESSBOOK_ERROR",

  REMOVE_FAV_ADDRESS_REQUEST: "REMOVE_FAV_ADDRESS_REQUEST",
  REMOVE_FAV_ADDRESS_SUCCESS: "REMOVE_FAV_ADDRESS_SUCCESS",
  REMOVE_FAV_ADDRESS_ERROR: "REMOVE_FAV_ADDRESS_ERROR",

  SET_CURRENT_PAGE_ADDRESSES: "SET_CURRENT_PAGE_ADDRESSES",
};

const addToAddressBookRequest = () => ({
  type: types.ADD_TO_ADDRESSBOOK_REQUEST,
});

const updateAddressBookRequest = () => ({
  type: types.UPDATE_ADDRESSBOOK_REQUEST,
});

const removeFavoriteAddressRequest = () => ({
  type: types.REMOVE_FAV_ADDRESS_REQUEST,
});

const fetchAddressesRequest = () => ({
  type: types.FETCH_ADDRESSES_REQUEST,
});

export const setCurrentPage = (page) => ({
  type: types.SET_CURRENT_PAGE_ADDRESSES,
  payload: page,
});

export const addToAddressBook = ({ orgId, payload }) => {
  const success = (response) => {
    return {
      type: types.ADD_TO_ADDRESSBOOK_SUCCESS,
      payload: response,
    };
  };

  const failure = (error) => ({
    type: types.ADD_TO_ADDRESSBOOK_ERROR,
    payload: error,
  });

  return (dispatch, getStore) => {
    dispatch(addToAddressBookRequest());
    return baseRequest("Post", `/address-book`, {
      orgId: orgId,
      createAddressBookDto: payload,
    })
      .then((response) => {
        dispatch(fetchAddresses({ id: orgId }));
        dispatch(success(response.data));
      })
      .catch((error) => {
        if (error?.response?.data) dispatch(failure(error?.response?.data));
      });
  };
};

export const updateAddressBookItem = ({ id, orgId, payload }) => {
  const success = (response) => {
    return {
      type: types.UPDATE_ADDRESSBOOK_SUCCESS,
      payload: response,
    };
  };

  const failure = (error) => ({
    type: types.UPDATE_ADDRESSBOOK_ERROR,
    payload: error,
  });

  return (dispatch, getStore) => {
    dispatch(updateAddressBookRequest());
    return baseRequest("Put", `/address-book/${orgId}/${id}`, { ...payload })
      .then((response) => {dispatch(success(response.data));

        dispatch(fetchAddresses({ id: orgId }));
      })

     
      .catch((error) => {
        if (error?.response?.data) dispatch(failure(error?.response?.data));
      });
  };
};

export const removeFromFavorite = ({ id, orgId }) => {
  const success = (response) => {
    return {
      type: types.REMOVE_FAV_ADDRESS_SUCCESS,
      payload: response,
    };
  };

  const failure = (error) => ({
    type: types.REMOVE_FAV_ADDRESS_ERROR,
    payload: error,
  });

  return (dispatch, getStore) => {
    dispatch(removeFavoriteAddressRequest());
    return baseRequest("Delete", `/address-book/${orgId}/${id}`)
      .then((response) => dispatch(success(id)))
      .catch((error) => {
        if (error?.response?.data) dispatch(failure(error?.response?.data));
      });
  };
};

export const fetchAddresses = ({ id }) => {
  const success = (response) => {
    return {
      type: types.FETCH_ADDRESSES_SUCCESS,
      payload: response,
    };
  };

  const failure = (error) => ({
    type: types.FETCH_ADDRESSES_ERROR,
    payload: error,
  });

  return (dispatch, getStore) => {
    console.log(id);
    dispatch(fetchAddressesRequest());
    return baseRequest(
      "GET",
      `/address-book`,
      null,
      {},
      {
        orgId: id,
      }
    )
      .then((response) => dispatch(success(response.data)))
      .catch((error) => {
        if (error?.response?.data) dispatch(failure(error?.response?.data));
      });
  };
};

const initialState = {
  addresses: [],
  loading: false,
  error: false,
  success: false,
  limit: 10,
};

export default function addressBook(state = initialState, action) {
 
  switch (action.type) {
    case types.FETCH_ADDRESSES_SUCCESS:
      return {
        ...state,
        addresses: action.payload,
        loading: false,
      };
    case types.FETCH_ADDRESSES_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.FETCH_ADDRESSES_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.ADD_TO_ADDRESSBOOK_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.REMOVE_FAV_ADDRESS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.ADD_TO_ADDRESSBOOK_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };

      case types.UPDATE_ADDRESSBOOK_REQUEST:
        return {
          ...state,
          loading: true,
        };
      
    case types.UPDATE_ADDRESSBOOK_SUCCESS:{

     
      return {
        ...state,
        loading: false,
        success: true,
        
      };}

    case types.ADD_TO_ADDRESSBOOK_ERROR:
    case types.REMOVE_FAV_ADDRESS_ERROR:
    case types.UPDATE_ADDRESSBOOK_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case types.REMOVE_FAV_ADDRESS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        addresses: state.addresses.filter(
          (address) => address.id !== action.payload
        ),
      };

    default:
      return state;
  }
}
