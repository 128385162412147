import React from "react";
import { FormikProvider, useFormik } from "formik";
import { Button } from "../../formControls/Button/Button";
import { renderInputField, renderInputFieldRideCostTC } from "./renderInputField";

import { DateHelper, LodashHelper, renderRecordValue } from "../../../utils";
import styles from "./index.module.css";
import { tc_Billing_Transaction_Schema } from "../../../utils/validations";
import { useSelector } from "react-redux";
import { Record } from "../RideDetails/RideDetails";

export const TCBillSegment = ({
  Default_Value,
  onSubmitPricing,
}) => {

  const pricing = useSelector((state) => state?.transportOrganizations?.TCPricing);
  
  const formik = useFormik({
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: Default_Value,
   validationSchema: tc_Billing_Transaction_Schema,
    onSubmit: (values) => {
      onSubmitPricing(values);
    },
  });

  const { values } = formik;

  const onChange = async ({ name, value, setFieldValue }) => {
    await setFieldValue(name, value);
  };

  return (
    <FormikProvider value={formik}>
      <div className={styles.mb20}>
        <h2 className={styles.mb20}> Billing & Invoicing </h2>
        <form onSubmit={formik.handleSubmit}>
          <label className={styles.labelBilling}>Ride Cost</label>

          <div className={styles.fieldData}>
            {renderInputFieldRideCostTC(
              "flatRate.ambulatory",
              "Ambulatory",
              "Base Charge (Include First 10 Miles)"
            )}
            {renderInputFieldRideCostTC("flatRate.stretcher", "Stretcher")}
            {renderInputFieldRideCostTC("flatRate.wheelchair", "Wheelchair")}
          </div>

          <div className={styles.fieldData}>
            <label className={styles.labelBilling}>Mileage Surcharge</label>
            {renderInputField(
              "additionalRate.ambulatory.fee",
              "additionalRate.ambulatory",
              "Ambulatory",
              values?.additionalRate?.ambulatory?.enabled,
              onChange,
              "Per 1 Mile (After first 10 Miles)"
            )}

            {renderInputField(
              "additionalRate.stretcher.fee",
              "additionalRate.stretcher",
              "Stretcher",
              values?.additionalRate?.stretcher?.enabled,
              onChange
            )}

            {renderInputField(
              "additionalRate.wheelchair.fee",
              "additionalRate.wheelchair",
              "Wheelchair",
              values?.additionalRate?.wheelchair?.enabled,
              onChange
            )}
          </div>
          {renderInputField(
              "deadheadMileage.fee",
              "deadheadMileage",
              "Deadhead Mileage",
              values?.deadheadMileage?.enabled,
              onChange
            )}
          <label className={styles.labelBilling}>Add-on Fee</label>
          <div className={styles.timeInputFieldsContainer}>
            {renderInputField(
              "rideNoShow.fee",
              "rideNoShow",
              "Rider No Show",
              values?.rideNoShow?.enabled,
              onChange,
              "One Time Fee"
            )}

            {renderInputField(
              "sameDayCancellation.fee",
              "sameDayCancellation",
              "Same-Day Cancellation",
              values?.sameDayCancellation?.enabled,
              onChange
            )}

            {renderInputField(
              "waitTime.fee",
              "waitTime",
              "Wait Time (Per Minute)",
              values?.waitTime?.enabled,
              onChange
            )}
          </div>

          {pricing?.updatedBy && <Record
            myClassName={styles.riderNameGap}
            title={'Last Updated By'}
            value={`(${DateHelper.renderDateTime({dateTime:pricing?.updatedAt})})  ${renderRecordValue(pricing?.updatedBy?.firstName)} ${renderRecordValue(pricing?.updatedBy?.lastName)} `}

          />}
          <div className={styles.btnPanel}>
            <div className={styles.btnControl}>
              <Button
                disabled={LodashHelper.isEqual(values, Default_Value)}
                type="submit"
              >
                Save Changes
              </Button>
            </div>
          </div>
        </form>
      </div>
    </FormikProvider>
  );
};
