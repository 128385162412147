import React, { useCallback, useEffect } from "react";
import { Formik } from "formik";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import classNames from "classnames";
import { InfoOutlined } from "@ant-design/icons";

import {
  updateDriver,
  getDriverDetails,
  changeDriverStatus,
} from "../../../store/drivers";
import { fetchTOAdminPreferences } from "../../../store/transport_organizations";
import { BackIcon, PreferencesIcon } from "../../../assets";
import { createDriverSchema, renderRecordValue } from "../../../utils";
import {
  CreateDriver,
  Button,
  Tooltip,
  useDisableUserModal,
} from "../../../components/";
import { vehicleOptionsDictionary } from "../../../dictionaries";
import { USER_STATUSES } from "../../../constants/constants";
import { LodashHelper } from "../../../utils";

import styles from "./index.module.css";
import { Navigation } from "../../../utils/navigation";

const UpdateDriver = ({
  driverDetails,
  rideOptions,
  vehicleTypes,
  history,
  fetchTOAdminPreferences,
  updateDriver,
  getDriverDetails,
  changeDriverStatus,
  showAccountCheckbox,
}) => {
  const { id } = useParams();
  const navigate = Navigation();
  const handleConfirmDisableDriver = () => {
    changeDriverStatus({ id, status: { status: USER_STATUSES.DISABLED } });
    setDisableUserModalVisible(false);
  };
    const { DisableUserModal, setDisableUserModalVisible } = useDisableUserModal({
    handleConfirm: handleConfirmDisableDriver,
    userName:
      driverDetails?.firstName &&
      driverDetails?.lastName &&
      renderRecordValue(
        `${driverDetails?.firstName} ${driverDetails?.lastName}`
      ),
  });

  const toggleDisableUserModal = useCallback(
    (flag) => () => setDisableUserModalVisible(flag),
    [setDisableUserModalVisible]
  );

  useEffect(() => {
    fetchTOAdminPreferences();
    return;
  }, [fetchTOAdminPreferences]);

  useEffect(() => {
    getDriverDetails(id);
    return;
  }, [id, getDriverDetails]);

  const handleBack = useCallback(() => navigate.back(), [navigate]);

  return (
    <div className={styles.pageContent}>
      <div className={styles.header}>
        <div className={styles.backBtn} onClick={handleBack}>
          <BackIcon width="18px" height="18px" fill="#BDBDBD" />
        </div>
        <h1 className={styles.pageTitle}>Driver Details</h1>
      </div>
      <div>
        <h2>Information of the Driver</h2>
        <Formik
          enableReinitialize
          validationSchema={createDriverSchema}
          validateOnMount
          initialValues={driverDetails}
          onSubmit={(values) =>
            updateDriver({ id, values, Navigation: navigate })
          }
        >
          {({ values, ...rest }) => {
            return (
              <form className={styles.form} onSubmit={rest.handleSubmit}>
                <CreateDriver
                  values={values}
                  rideOptions={rideOptions || []}
                  vehicleTypes={vehicleTypes || []}
                  showPersonalInfo
                  showAccountCheckbox={showAccountCheckbox}
                />
                <div className={styles.btnPanel}>
                  <div
                    className={classNames({
                      [styles.buttonWithTooltip]: true,
                      [styles.deactivateTooltip]: true,
                    })}
                  >
                    {driverDetails.status === USER_STATUSES.ACTIVE && (
                      <div className={styles.btnControl}>
                        <Button
                          myClassName={styles.navigationBtn}
                          onClick={toggleDisableUserModal(true)}
                          disabled={driverDetails.unableToDisable}
                        >
                          Deactivate Driver
                        </Button>
                      </div>
                    )}
                    {driverDetails.unableToDisable && (
                      <Tooltip
                        title={
                          "This driver has upcoming rides assigned. Prior to deactivating the driver, reassign the ride(s) to another driver in your organization. If you are unable to reassign the ride request(s), please Cancel the request."
                        }
                      >
                        <span className={styles.tooltipInfo}>
                          <InfoOutlined className={styles.infoIcon} />
                        </span>
                      </Tooltip>
                    )}
                  </div>
                  <div
                    className={classNames({
                      [styles.buttonWithTooltip]: true,
                      [styles.inviteTooltip]: true,
                    })}
                  >
                    <div className={styles.btnControl}>
                      <Button
                        disabled={
                          !rest.isValid ||
                          LodashHelper.isEqual(values, driverDetails)
                        }
                        type="submit"
                      >
                        Save Changes
                      </Button>
                    </div>
                    <Tooltip
                      title={
                        <span className={styles.tooltipContent}>
                          The{" "}
                          <span className={styles.italicText}>
                            Vehicle Type(s){" "}
                          </span>{" "}
                          and
                          <span className={styles.italicText}>
                            {" "}
                            Additional driver/vehicle capabilities{" "}
                          </span>
                          fields are
                          <span
                            className={classNames({
                              [styles.boldText]: true,
                              [styles.required]: true,
                            })}
                          >
                            required
                          </span>
                          for the driver. Please fill in this information in the
                          Organization
                          <span className={styles.italicText}>
                            {" "}
                            Preferences{" "}
                          </span>
                          tab {<PreferencesIcon fill="#fff" />}
                        </span>
                      }
                    >
                      <span
                        className={classNames({
                          [styles.tooltipInfo]: true,
                          [styles.tooltipDisabled]:
                            !rideOptions?.length && !vehicleTypes?.length,
                        })}
                      >
                        <InfoOutlined className={styles.infoIcon} />
                      </span>
                    </Tooltip>
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
      {DisableUserModal}
    </div>
  );
};

const mapStateToProps = (state) => ({
  driverDetails: {
    email: state?.drivers?.driverDetails?.email,
    firstName: state?.drivers?.driverDetails?.firstName,
    lastName: state?.drivers?.driverDetails?.lastName,
    phoneNumber: state?.drivers?.driverDetails?.phoneNumber,
    jobTitle: state?.drivers?.driverDetails?.jobTitle,
    hasAccount: state?.drivers?.driverDetails?.hasAccount,
    rideOptions: state?.drivers?.driverDetails?.rideOptions,
    vehicleTypes: state?.drivers?.driverDetails?.vehicleTypes,
    unableToDisable: state?.drivers?.driverDetails?.ridesCount > 0,
    canReceiveSms: state?.drivers?.driverDetails?.canReceiveSms,
    status: state?.drivers?.driverDetails?.status,
  },
  rideOptions: state?.transportOrganizations?.TCPreferences?.rideOptions,
  vehicleTypes: state?.transportOrganizations?.TCPreferences?.vehicleTypes?.map(
    (tt, idx) => ({
      id: idx,
      title: vehicleOptionsDictionary[tt],
      value: tt,
    })
  ),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateDriver,
      getDriverDetails,
      fetchTOAdminPreferences,
      changeDriverStatus,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(UpdateDriver);
