import classNames from 'classnames';
import { getIn } from 'formik';
import React from 'react';

import { LodashHelper } from '../../../../../utils';
import {
  EXPIRATION_TIME_PREFERENCES_FIELDS,
  VEHICLE_TYPE_TIME_PREFERENCES_FIELDS,
  MH_PREFERENCES_FIELDS,
  WINDOW_OF_TIME_RANGE_FIELDS,
  MINIMUM_TIME_BTW_ROUNDTRIP_SEGMENTS_OBJ,
  BOOSTER_CAR_SEAT_TIME_SEGMENTS,
} from '../../../mock';
import { renderTimeInputFields } from '../../../partials/renderTimeInputFields';
import { renderSaveChangesButton } from './SaveChangesButton';

import styles from '../index.module.css';

export const renderHoursAndMinutesTimeFields = (fields, errors, values, initialValues, handleSaveChanges) => {
  return fields.map((field) => {
    const hasValidationErrors = errors?.[field.name];
    return (
      <div
        className={classNames({
          [styles.minimumTimeToSubmit]: field.parentFieldName === MH_PREFERENCES_FIELDS.minTimeFromRideRequestToPickup,
          [styles.reminder]: field.parentFieldName !== MH_PREFERENCES_FIELDS.minTimeFromRideRequestToPickup,
        })}
        key={field.title}>
        <label className={styles.minimumTimeToSubmitTitle}>
          <span className={styles.required}>{field.title}</span>
        </label>
        <div className={styles.rideExpiration}>
          {renderTimeInputFields(field.name)}
          {initialValues?.[field?.parentFieldName] &&
            !LodashHelper.isEqual(getIn(initialValues, field.name), getIn(values, field.name)) &&
            renderSaveChangesButton(field.name, handleSaveChanges, values, hasValidationErrors)}
        </div>
      </div>
    );
  });
};


export const renderRoundTripTimeFields = (
  errors,
  values,
  initialValues,
  handleSaveChanges
) => { 
  const fieldName = MINIMUM_TIME_BTW_ROUNDTRIP_SEGMENTS_OBJ.name;
  const hasValidationErrors = errors?.[fieldName];
  return (
    <div className={styles.minimumTimeToSubmit}>
      <label className={styles.minimumTimeToSubmitTitle}>
        <span className={styles.required}>
          {MINIMUM_TIME_BTW_ROUNDTRIP_SEGMENTS_OBJ.title}
        </span>
      </label>
      <div className={styles.rideExpiration}>
         {renderTimeInputFields(fieldName)} 
        {!LodashHelper.isEqual(
          getIn(initialValues, fieldName),
          getIn(values, fieldName)
        ) &&
          renderSaveChangesButton(
            fieldName,
            handleSaveChanges,
            values,
            hasValidationErrors
          )}
      </div>
    </div>
  );
};

export const renderCarBoosterFieldsBufferTime = (
  errors,
  values,
  initialValues,
  handleSaveChanges
) => { 
  const fieldName = BOOSTER_CAR_SEAT_TIME_SEGMENTS.name;
  const hasValidationErrors = errors?.[fieldName];
  return (
    <div className={styles.minimumTimeToSubmit}>
      <label className={styles.minimumTimeToSubmitTitle}>
        <span className={styles.required}>
          {BOOSTER_CAR_SEAT_TIME_SEGMENTS.title}
        </span>
      </label>
      <div className={styles.rideExpiration}>
         {renderTimeInputFields(fieldName)} 
        {!LodashHelper.isEqual(
          getIn(initialValues, fieldName),
          getIn(values, fieldName)
        ) &&
          renderSaveChangesButton(
            fieldName,
            handleSaveChanges,
            values,
            hasValidationErrors
          )}
      </div>
    </div>
  );
};

export const RideExpirations = ({ values, initialValues, handleSaveChanges, errors }) => {
  return (
    <>
      <div>
        <span className={styles.expirationTitle}>
          Ride Expirations for Inactivity by Ride type (Global)
        </span>
        {renderHoursAndMinutesTimeFields(
          EXPIRATION_TIME_PREFERENCES_FIELDS,
          errors,
          values,
          initialValues,
          handleSaveChanges
        )}
        {renderHoursAndMinutesTimeFields(
          WINDOW_OF_TIME_RANGE_FIELDS,
          errors,
          values,
          initialValues,
          handleSaveChanges
        )}
      </div>
      <div>
        <span className={styles.minimumTimeToRequest}>
          Minimum time from Ride Request Submit to Pickup time (Global)
        </span>
        {renderHoursAndMinutesTimeFields(
          VEHICLE_TYPE_TIME_PREFERENCES_FIELDS,
          errors,
          values,
          initialValues,
          handleSaveChanges
        )}
        {renderRoundTripTimeFields(
          errors,
          values,
          initialValues,
          handleSaveChanges
        )}
        {renderCarBoosterFieldsBufferTime(
          errors,
          values,
          initialValues,
          handleSaveChanges
        )}
      </div>
    </>
  );
};
