import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { getIn } from 'formik';
import { Select as AntdSelect } from 'antd';
import { withTooltip } from '../../../hocs/withTooltip';
import { CustomField } from '../CustomField/CustomField';

import styles from './index.module.css';

const { Option } = AntdSelect;

const CustomSelect = ({
  value,
  field,
  onChange,
  options,
  placeholder,
  form,
  mode,
  maxTagCount,
  maxTagTextLength,
  disabled,
  tagRender,
  showSearch,
  allowClear,
  suffixIcon,
  myClassName,
  whenFieldDisabledShowTooltip,
  getPopupContainer,
}) => {
  const handleChange = (value) => {
    if (onChange) {
      onChange({
        name: field.name,
        value,
        setFieldValue: form.setFieldValue,
        setValues: form.setValues,
        values: form.values,
        validateForm: form.validateForm,
      });
    } else {
      form.setFieldValue(field.name, value);
    }
  };

  const onFilter = useCallback(
    (input, option) =>
      option.props.value.toLowerCase().startsWith(input.toLowerCase()) ||
      option.children.toLowerCase().startsWith(input.toLowerCase()),
    [],
  );

  const popUpContainer = (trigger) => {
    if (getPopupContainer) return getPopupContainer(trigger);
    else return trigger.parentElement;
  };

  return withTooltip(
    <AntdSelect
      allowClear={allowClear && value}
      filterOption={onFilter}
      showSearch={showSearch}
      name={field.name}
      value={value || field.value || null}
      onChange={handleChange}
      mode={mode}
      maxTagCount={maxTagCount}
      maxTagTextLength={maxTagTextLength}
      onBlur={() => form.setTouched({ ...form.touched, [field.name]: true })}
      disabled={disabled}
      placeholder={placeholder}
      tagRender={tagRender}
      suffixIcon={suffixIcon}
      className={classnames({
        [styles.select]: true,
        [styles.customSelect]: true,
        [styles.allowClear]: allowClear,
        [styles.error]: !disabled && !getIn(form.values, field.name) && getIn(form.errors, field.name),
        [styles.error]:
          !disabled && (getIn(form.touched, field.name) || form.touched[field.name]) && getIn(form.errors, field.name),
        [myClassName]: myClassName,
      })}
      popupClassName={styles.dropdown}
      getPopupContainer={popUpContainer}
      data-testid='select'>
      {options &&
        options.map((option) => (
          <Option key={option.value} value={option.value} disabled={option.disabled}>
            {option.title}
          </Option>
        ))}
    </AntdSelect>,
    whenFieldDisabledShowTooltip,
  );
};

export const Select = ({
  options,
  name,
  placeholder,
  value,
  onChange,
  customErrorMessage,
  mode,
  maxTagCount,
  maxTagTextLength,
  disabled,
  tagRender,
  showSearch,
  suffixIcon,
  allowClear,
  myClassName,
  whenFieldDisabledShowTooltip,
  fastField,
  getPopupContainer,
}) => {
  return (
    <CustomField fastField={fastField} name={name} options={options} placeholder={placeholder} onChange={onChange}>
      {({ form, field }) => {
        return (
          <>
            <CustomSelect
              form={form}
              field={field}
              options={options}
              placeholder={placeholder}
              onChange={onChange}
              value={value}
              mode={mode}
              maxTagCount={maxTagCount}
              maxTagTextLength={maxTagTextLength}
              disabled={disabled}
              tagRender={tagRender}
              showSearch={showSearch}
              allowClear={allowClear}
              suffixIcon={suffixIcon}
              myClassName={myClassName}
              whenFieldDisabledShowTooltip={whenFieldDisabledShowTooltip}
              getPopupContainer={getPopupContainer}
            />
            {!disabled &&
              !customErrorMessage &&
              (getIn(form.touched, field.name) || form.touched[field.name]) &&
              getIn(form.errors, field.name) && (
                <div className={styles.errorMessage} role='alert'>
                  {getIn(form.errors, field.name)}
                </div>
              )}
          </>
        );
      }}
    </CustomField>
  );
};

Select.propTypes = {
  options: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onChange: PropTypes.func,
  customErrorMessage: PropTypes.bool,
  allowClear: PropTypes.bool,
  filterOption: PropTypes.func,
  suffixIcon: PropTypes.node,
  myClassName: PropTypes.string,
  whenFieldDisabledShowTooltip: PropTypes.bool,
};
