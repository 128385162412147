import { USER_STATUSES } from "../constants/constants";
import { baseRequest } from "../utils";
import { getPage, redirectToPage } from "../utils/helpers";
import { types as commonTypes } from "./common";

const types = {
  CREATE_USER_REQUEST: "CREATE_USER_REQUEST",
  CREATE_USER_SUCCESS: "CREATE_USER_SUCCESS",
  CREATE_USER_ERROR: "CREATE_USER_ERROR",

  CREATE_USER_ADMIN_REQUEST: "CREATE_USER_ADMIN_REQUEST",
  CREATE_USER_ADMIN_SUCCESS: "CREATE_USER_ADMIN_SUCCESS",
  CREATE_USER_ADMIN_ERROR: "CREATE_USER_ADMIN_ERROR",

  UPDATE_USER_REQUEST: "UPDATE_USER_REQUEST",
  UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",
  UPDATE_USER_ERROR: "UPDATE_USER_ERROR",

  FETCH_USERS_REQUEST: "FETCH_USERS_REQUEST",
  FETCH_USERS_SUCCESS: "FETCH_USERS_SUCCESS",
  FETCH_USERS_ERROR: "FETCH_USERS_ERROR",

  FETCH_USER_DETAILS_REQUEST: "FETCH_USER_DETAILS_REQUEST",
  FETCH_USER_DETAILS_SUCCESS: "FETCH_USER_DETAILS_SUCCESS",
  FETCH_USER_DETAILS_ERROR: "FETCH_USER_DETAILS_ERROR",

  FETCH_USERS_BY_ORGANIZATION_REQUEST: "FETCH_USERS_BY_ORGANIZATION_REQUEST",
  FETCH_USERS_BY_ORGANIZATION_SUCCESS: "FETCH_USERS_BY_ORGANIZATION_SUCCESS",
  FETCH_USERS_BY_ORGANIZATION_ERROR: "FETCH_USERS_BY_ORGANIZATION_ERROR",

  CHANGE_USER_STATUS_REQUEST: "CHANGE_USER_STATUS_REQUEST",
  CHANGE_USER_STATUS_SUCCESS: "CHANGE_USER_STATUS_SUCCESS",
  CHANGE_USER_STATUS_ERROR: "CHANGE_USER_STATUS_ERROR",

  SET_CURRENT_PAGE_USERS: "SET_CURRENT_PAGE_USERS",

  CLEAR_USERS_INFO: "CLEAR_USERS_INFO",
  CLEAR_USER_DETAILS: "CLEAR_USER_DETAILS",
};

const createUserRequest = () => ({
  type: types.CREATE_USER_REQUEST,
});

const createUserAdminRequest = () => ({
  type: types.CREATE_USER_ADMIN_REQUEST,
});
const fetchUsersRequest = () => ({
  type: types.FETCH_USERS_REQUEST,
});

const updateUserRequest = () => ({
  type: types.UPDATE_USER_REQUEST,
});

const fetchUserDetailsRequest = () => ({
  type: types.FETCH_USER_DETAILS_REQUEST,
});

const fetchUsersByOrganizationRequest = () => ({
  type: types.FETCH_USERS_BY_ORGANIZATION_REQUEST,
});

export const clearUsersInfo = () => ({
  type: types.CLEAR_USERS_INFO,
});

export const clearUserDetails = () => ({
  type: types.CLEAR_USER_DETAILS,
});

export const changeUserStatusRequest = () => ({
  type: types.CHANGE_USER_STATUS_REQUEST,
});

export const setCurrentPage = (page) => ({
  type: types.SET_CURRENT_PAGE_USERS,
  payload: page,
});

export const createUser = ({payload,Navigation}) => {
  const navigate =Navigation
  const success = (response) => {
    return {
      type: types.CREATE_USER_SUCCESS,
      payload: response,
    };
  };

  const failure = (error) => ({
    type: types.CREATE_USER_ERROR,
    payload: error,
  });

  return (dispatch) => {
    dispatch(createUserRequest());
    return baseRequest("POST", "/users", payload)
      .then((response) => {
       
        dispatch(success(response.data));
        navigate.back();
      })
      .catch((error) => {
        if (error) dispatch(failure(error?.response?.data));
      });
  };
};

export const createUserAdmin = ({payload,Navigation}) => {
  const navigate =Navigation
  const success = (response) => {
    return { type: types.CREATE_USER_ADMIN_SUCCESS, payload: response };
  };
  const failure = (error) => ({
    type: types.CREATE_USER_ADMIN_ERROR,
    payload: error,
  });
  return (dispatch) => {
    dispatch(createUserAdminRequest());
    return baseRequest("POST", "/users/createAdmin", payload)
      .then((response) => {
        
        dispatch(success(response.data));
        navigate.back();
      })
      .catch((error) => {
        if (error) dispatch(failure(error?.response?.data));
      });
  };
};

export const fetchUsers = ({value, filtered,Navigation}) => {
  const navigate = Navigation;
  const success = (response) => {
    return {
      type: types.FETCH_USERS_SUCCESS,
      payload: response,
    };
  };

  const failure = (error) => ({
    type: types.FETCH_USERS_ERROR,
    payload: error,
  });

  return (dispatch, getStore) => {  
    if (filtered) redirectToPage(navigate?.location.pathname, 1);
    const { limit } = getStore().users;
    const currentPage = getPage(navigate?.location);
    dispatch(setCurrentPage(currentPage));
    dispatch(fetchUsersRequest());
    return baseRequest(
      "GET",
      `/users`,
      null,
      {},
      {
        docsPerPage: limit,
        page: currentPage,
        value: value || "",
      }
    )
      .then((response) => dispatch(success(response.data)))
      .catch((error) => {
        if (error) dispatch(failure([]));
      });
  };
};

export const getUserDetails = (id) => {
  const success = (response) => {
    return {
      type: types.FETCH_USER_DETAILS_SUCCESS,
      payload: response,
    };
  };

  const failure = (error) => ({
    type: types.FETCH_USER_DETAILS_ERROR,
    payload: error,
  });

  return (dispatch) => {
    dispatch(fetchUserDetailsRequest());
    return baseRequest("GET", `/users/${id}`)
      .then((response) => dispatch(success(response.data)))
      .catch((error) => {
        if (error) dispatch(failure(error?.response?.data));
      });
  };
};

export const updateUser = ({ id, values,Navigation }) => {
  const navigate = Navigation;
  const success = (response) => {
    return {
      type: types.UPDATE_USER_SUCCESS,
      payload: response,
    };
  };

  const failure = (error) => ({
    type: types.UPDATE_USER_ERROR,
    payload: error,
  });

  return (dispatch) => {
    dispatch(updateUserRequest());
    return baseRequest("PUT", `/users/${id}`, values)
      .then((response) => {
       
        dispatch(success(response.data));
        navigate.back();
      })
      .catch((error) => {
        if (error) dispatch(failure(error?.response?.data));
      });
  };
};

export const fetchUsersByOrganization = ({ id }) => {
  const success = (response) => {
    return {
      type: types.FETCH_USERS_BY_ORGANIZATION_SUCCESS,
      payload: response,
    };
  };

  const failure = (error) => ({
    type: types.FETCH_USERS_BY_ORGANIZATION_ERROR,
    payload: error,
  });

  return (dispatch) => {
    dispatch(fetchUsersByOrganizationRequest());
    return baseRequest("GET", `/users/organization/${id}`)
      .then((response) => {
        dispatch(success(response.data));
      })
      .catch((error) => {
        if (error) dispatch(failure(error?.response?.data));
      });
  };
};

export const changeUserStatus = ({ id, status,Navigation }) => {
  const navigate =Navigation;
  const success = (response) => {
    return {
      type: types.CHANGE_USER_STATUS_SUCCESS,
      payload: response,
    };
  };

  const failure = (error) => ({
    type: types.CHANGE_USER_STATUS_ERROR,
    payload: error,
  });

  return (dispatch) => {
    dispatch(changeUserStatusRequest());
    return baseRequest("PATCH", `/users/${id}`, status)
      .then((response) => {
     
        dispatch(success(response.data));
        if (status.status === USER_STATUSES.DISABLED) navigate.back();
      })
      .catch((error) => {
        if (error) dispatch(failure(error?.response?.data));
      });
  };
};

const initialState = {
  users: [],
  userDetails: null,
  loading: false,
  limit: 10,
  currentPage: 1,
  totalPages: 1,
  total: 1,
};

export default function users(state = initialState, action) {
  switch (action.type) {
    case types.CREATE_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.CREATE_USER_SUCCESS:
      return {
        ...state,
        users: [...state.users, action.payload],
        loading: false,
      };

    case types.CREATE_USER_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.FETCH_USERS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.FETCH_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload?.data,
        currentPage: action.payload?.meta?.page,
        totalPages: action.payload?.meta?.totalPages,
        total: action.payload?.meta?.total,

        loading: false,
      };

    case types.FETCH_USERS_ERROR:
      return {
        ...state,
        loading: false,
        users: action.payload,
        currentPage: 1,
        totalPages: 1,
        total: 1,
      };

    case types.UPDATE_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.UPDATE_USER_SUCCESS:
      const userIdx = state.users.findIndex((u) => u.id === action.payload.id);
      let copiedUsers = [...state.users];
      if (userIdx > 0) {
        copiedUsers[userIdx] = { ...action.payload };
      }
      return {
        ...state,
        users: copiedUsers,
        userDetails: action.payload,
        loading: false,
      };

    case types.UPDATE_USER_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.FETCH_USER_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
        userDetails: null,
      };

    case types.FETCH_USER_DETAILS_SUCCESS:
      return {
        ...state,
        userDetails: action.payload,
        loading: false,
      };

    case types.FETCH_USER_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.FETCH_USERS_BY_ORGANIZATION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.FETCH_USERS_BY_ORGANIZATION_SUCCESS:
      return {
        ...state,
        users: action.payload,
        loading: false,
      };

    case types.FETCH_USERS_BY_ORGANIZATION_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.CLEAR_USERS_INFO:
      return {
        ...state,
        users: [],
        currentPage: 1,
        totalPages: 1,
        total: 1,
      };

    case types.CLEAR_USER_DETAILS:
      return {
        ...state,
        userDetails: null,
      };

    case types.SET_CURRENT_PAGE_USERS:
      return {
        ...state,
        currentPage: action.payload,
      };

    case types.CHANGE_USER_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.CHANGE_USER_STATUS_SUCCESS:
      const updatedUserIdx = state.users.findIndex(
        (u) => u.id === action.payload.id
      );
      const clonedUsers = [...state.users];
      clonedUsers[updatedUserIdx] = action.payload;
      return {
        ...state,
        userDetails: action.payload,
        users: clonedUsers,
        loading: false,
      };

    case types.CHANGE_USER_STATUS_ERROR:
      return {
        ...state,
        loading: false,
      };
    case commonTypes.CLEAR_STORE_INFO:
      return {
        ...initialState,
        currentPage: 1,
      };

    default:
      return state;
  }
}
