import { Checkbox } from '../../';

import styles from './index.module.css';

export const renderAdditionalRideOptions =
  ({ renderedValues, values, name, disabled, showTooltip = true }) =>
  (arrayHelpers) => {
    const handleCheckbox =
      (value) =>
      ({ checked }) => {
        if (checked) arrayHelpers.push(value);
        else {
          const idx = values[name].findIndex((option) => option.id === value.id);
          arrayHelpers.remove(idx);
        }
      };

    return renderedValues.map((option) => {
      const isChecked = Boolean(values && values[name] && values[name].find((o) => o.id === option.id));
      return (
        <div className={styles.checkboxContainer} key={option.value}>
          <label className={styles.checkbox} key={option.value}>
            <Checkbox
              name={name}
              checked={isChecked}
              value={option}
              onChange={handleCheckbox(option)}
              disabled={option.disabled || disabled}
              whenFieldDisabledShowTooltip={Boolean(showTooltip && disabled)}
            />
            <span className={styles.checkboxLabel}>{option.value}</span>
          </label>
        </div>
      );
    });
  };
