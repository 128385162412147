import { baseRequest, renderRecordValue } from '../utils';
import { setCurrentPage as setCurrentPageDrafts } from './drafts';
import { setCurrentPage as setCurrentPageHO } from './health_organizations.js';
import { resetDriverPagination } from './drivers';
import { clearUsersInfo } from './users';
import { setCurrentPage as setCurrentPageRides } from './rides';
import { setCurrentPage as setCurrentPageTC } from './transport_organizations';
import { showNotificationMessage } from '../components';
import { ERROR_TYPES } from '../constants/constants';
import ReCaptcha from '../utils/ReCaptcha';

export const types = {
  FETCH_STATES_LIST_REQUEST: 'FETCH_STATES_LIST_REQUEST',
  FETCH_STATES_LIST_SUCCESS: 'FETCH_STATES_LIST_SUCCESS',
  FETCH_STATES_LIST_ERROR: 'FETCH_STATES_LIST_ERROR',

  LOAD_CAPTCHA_SUCCESS: 'LOAD_CAPTCHA_SUCCESS',
  LOAD_CAPTCHA_ERROR: 'LOAD_CAPTCHA_ERROR',

  FETCH_CAPTCHA_TOKEN_REQUEST: 'FETCH_CAPTCHA_TOKEN_REQUEST',
  FETCH_CAPTCHA_TOKEN_SUCCESS: 'FETCH_CAPTCHA_TOKEN_SUCCESS',
  FETCH_CAPTCHA_TOKEN_ERROR: 'FETCH_CAPTCHA_TOKEN_ERROR',

  TOGGLE_USER_INACTIVITY_MODAL: 'TOGGLE_USER_INACTIVITY_MODAL',

  CLEAR_STORE_INFO: 'CLEAR_STORE_INFO',
};

export const loadCaptcha = (payload) => async (dispatch) => {
  window._grecaptcha_callback = () => {
    try {
      window.grecaptcha.enterprise.render({
        sitekey: process.env.REACT_APP_RECAPTCHA_KEY,
        badge: 'bottomleft',
      });
      dispatch({
        type: types.LOAD_CAPTCHA_SUCCESS,
        payload,
      });
    } catch (error) {
      dispatch({
        type: types.LOAD_CAPTCHA_ERROR,
        payload,
      });
      showNotificationMessage({
        type: 'error',
        content: renderRecordValue(ERROR_TYPES.RECAPTCHA, ERROR_TYPES.DEFAULT),
      });
    }
  };

  window.errorHandler = () => {
    dispatch({
      type: types.LOAD_CAPTCHA_ERROR,
      payload,
    });
    showNotificationMessage({
      type: 'error',
      content: renderRecordValue(ERROR_TYPES.RECAPTCHA, ERROR_TYPES.DEFAULT),
    });
  };

  await ReCaptcha.init(window.errorHandler);
};

export const fetchCaptchaTokenRequest = () => ({
  type: types.FETCH_CAPTCHA_TOKEN_REQUEST,
});

export const clearStoreInfo = () => ({
  type: types.CLEAR_STORE_INFO,
});

export const resetAllPagination = () => (dispatch) => {
  dispatch(setCurrentPageDrafts(1));
  dispatch(resetDriverPagination());
  dispatch(setCurrentPageHO(1));
  dispatch(setCurrentPageRides(1));
  dispatch(clearUsersInfo());
  dispatch(setCurrentPageTC(1));
};

const fetchStatesListRequest = () => ({
  type: types.FETCH_STATES_LIST_REQUEST,
});

export const fetchStatesList = () => {
  const success = (payload) => ({
    type: types.FETCH_STATES_LIST_SUCCESS,
    payload,
  });

  const failure = (error) => ({
    type: types.FETCH_STATES_LIST_ERROR,
    payload: error,
  });

  return (dispatch) => {
    dispatch(fetchStatesListRequest());
    return baseRequest('GET', '/states')
      .then((response) => {
        dispatch(success(response.data));
      })
      .catch((error) => {
        if (error) dispatch(failure([]));
      });
  };
};

export const toggleUserInactivityModal = (payload) => ({
  type: types.TOGGLE_USER_INACTIVITY_MODAL,
  payload,
});

export const fetchCaptchaToken = (action) => {
  const success = (payload) => ({
    type: types.FETCH_CAPTCHA_TOKEN_SUCCESS,
    payload,
  });

  const failure = (error) => ({
    type: types.FETCH_CAPTCHA_TOKEN_ERROR,
    payload: error,
  });

  return (dispatch, getStore) => {
    const captchaLoaded = getStore().common.captchaLoaded;
    if (captchaLoaded) {
      dispatch(fetchCaptchaTokenRequest());
      return window?.grecaptcha?.enterprise
        .execute(0, { action })
        .then((response) => {
          return dispatch(success(response));
        })
        .catch((e) => {
          dispatch(failure(e));
          return showNotificationMessage({
            type: 'error',
            content: renderRecordValue(ERROR_TYPES.RECAPTCHA, ERROR_TYPES.DEFAULT),
          });
        });
    }
  };
};

const initialState = {
  counties: [],
  states: [],
  userInactivityModalVisible: false,
  captchaLoaded: false,
  recaptchaToken: '',
  loading: false,
  error: false,
};

export default function common(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_STATES_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.FETCH_STATES_LIST_SUCCESS:
      const counties = {};
      action.payload?.forEach((item) => {
        counties[item?.state] = item.counties;
      });
      return {
        ...state,
        loading: false,
        states: action?.payload?.map((item) => ({ title: item.state, value: item.state })),
        counties,
      };

    case types.FETCH_STATES_LIST_ERROR:
      return {
        ...state,
        loading: false,
        states: action.payload,
      };

    case types.TOGGLE_USER_INACTIVITY_MODAL:
      return {
        ...state,
        userInactivityModalVisible: action.payload,
      };

    case types.LOAD_CAPTCHA_SUCCESS:
      return {
        ...state,
        captchaLoaded: true,
      };

    case types.LOAD_CAPTCHA_ERROR:
      return {
        ...state,
        captchaLoaded: false,
      };

    case types.FETCH_CAPTCHA_TOKEN_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.FETCH_CAPTCHA_TOKEN_SUCCESS:
      return {
        ...state,
        loading: false,
        recaptchaToken: action.payload,
      };

    case types.FETCH_CAPTCHA_TOKEN_ERROR:
      return {
        ...state,
        loading: false,
        recaptchaToken: '',
      };

    default:
      return state;
  }
}
