import React from 'react';
import classnames from 'classnames';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import { medhaulIcon } from '../../../assets';
import { URLS } from '../../../constants/constants';

import styles from './index.module.css';

export const Logo = ({ sm, md, lg, xl, className, onClick, width = 251, height = 72 }) => {
  return (
    <NavLink to={URLS.HOME} onClick={onClick}>
      <img
        src={medhaulIcon}
        className={classnames({
          [styles.xl]: xl,
          [styles.lg]: lg,
          [styles.md]: md,
          [styles.sm]: sm,
          [className]: className,
        })}
        alt='medHaul_logo'
        width={`${width}px`}
        height={`${height}px`}
      />
    </NavLink>
  );
};

Logo.propTypes = {
  xs: PropTypes.bool,
  s: PropTypes.bool,
  md: PropTypes.bool,
  lg: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
};
