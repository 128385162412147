import React from 'react';

import {NumericFormat, PatternFormat} from 'react-number-format';
import PropTypes from 'prop-types';
import { getIn } from 'formik';
import classnames from 'classnames';

import { CustomField } from '../CustomField/CustomField';
import { withTooltip } from '../../../hocs/withTooltip';

import styles from './index.module.css';

export const CustomNumberInput = ({
  name,
  format,
  placeholder,
  myClassName,
  onChange,
  field,
  form,
  value,
  disabled,
  whenFieldDisabledShowTooltip,
}) => {
  const handleChange = (values, sourceEvent) => {
    if (sourceEvent?.source !== 'prop') {
      if (onChange)
        onChange({
          name: field.name,
          value: values.value,
          setFieldValue: form.setFieldValue,
          setValues: form.setValues,
          values: form.values,
          validateForm: form.validateForm,
        });
      else form.setFieldValue(field.name, values.value);
    }
  };

  return withTooltip(
    <PatternFormat 
      name={field.name}
      className={classnames({
        [styles.input]: true,
        [myClassName]: myClassName,
        [styles.error]: !disabled && !getIn(form.values, field.name) && getIn(form.errors, field.name),
        [styles.error]:
          !disabled && (getIn(form.touched, field.name) || form.touched[field.name]) && getIn(form.errors, field.name),
      })}
      onBlur={() => form.setTouched({ ...form.touched, [field.name]: true })}
      format={format}
      placeholder={placeholder}
      value={value || field.value || ''}
      onValueChange={handleChange}
      disabled={disabled}
      type='text'
      role='input'
    />,
    whenFieldDisabledShowTooltip,
  );
};

export const MaskInput = ({
  format,
  name,
  value,
  onChange,
  placeholder,
  required,
  myClassName,
  disabled,
  whenFieldDisabledShowTooltip,
  fastField,
}) => {
  return (
    <CustomField
      fastField={fastField}
      name={name}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      format={format}
      required={required}>
      {({ form, field }) => (
        <>
          <CustomNumberInput
            form={form}
            field={field}
            name={name}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            format={format}
            required={required}
            myClassName={myClassName}
            disabled={disabled}
            whenFieldDisabledShowTooltip={whenFieldDisabledShowTooltip}
          />
          {!disabled &&
            (getIn(form.touched, field.name) || form.touched[field.name]) &&
            getIn(form.errors, field.name) && (
              <div className={styles.errorMessage} role='alert'>
                {getIn(form.errors, field.name)}
              </div>
            )}
        </>
      )}
    </CustomField>
  );
};

MaskInput.propTypes = {
  format: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  customClassName: PropTypes.string,
  whenFieldDisabledShowTooltip: PropTypes.bool,
};
