import { FormikProvider, useFormik } from 'formik';
import React, { useCallback, useState } from 'react';
import { Button, Modal } from '../..';
import { REQUEST_CHANGE_OPTIONS,   } from '../../../constants/constants';
import { resolveChangeRequestSchema } from '../../../utils/validations';
import { InputTextArea } from '../../formControls/Input/InputTextArea';
import { Select } from '../../formControls/Select/Select';

import styles from './index.module.css';

const initialValues = { changeReqStatus: '', comment: '' };

export const RequestChangeConfirmationModal = ({ id,onConfirm }) => {
  const [visibleModal, setVisibleModal] = useState(false);

  const handleConfirm = useCallback(
    (values) => {
      
        onConfirm({changeReqStatus:values.changeReqStatus, resolvedComments: values.comment });
      
    },
    [onConfirm],
  );
  const formik = useFormik({
    initialValues,
    validationSchema: resolveChangeRequestSchema,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: handleConfirm,
  });

  const { resetForm, validateForm, isValid,  handleSubmit } = formik;

  const onCancel = useCallback(async () => {
      resetForm(initialValues);
    await validateForm();
    setVisibleModal(false);
  }, [setVisibleModal, validateForm, resetForm]);

  const handleRequestChangeStatus = useCallback(async ({ name, value, setFieldValue }) => {
 
    await setFieldValue(name, value);
  }, []);

  

  return {
    Modal: (
      <Modal
        visible={visibleModal}
        onCancel={onCancel}
        closable
        width={700}
        title={<h3 className={styles.modalTitle}>Warning - You are about to make changes to production data!</h3>}>
        <div className={styles.modalContent}>
          <p className={styles.modalDescription}>
            Please make sure that your changes are correct for the ride in question.
            <br />
            Your changes will be tracked under your login in the audit table.
          </p>
          <FormikProvider value={formik}>
            <>
              <form className={styles.form}>
                <label className={styles.comment}>
                  <span className={styles.required}>Select change request status</span>
                  <Select
                    name='changeReqStatus'
                    onChange={handleRequestChangeStatus}
                    options={REQUEST_CHANGE_OPTIONS}
                    placeholder='Pick change request status'
                  />
                </label>
               
                  <label className={styles.comment}>
                    <span className={styles.required}>Comments</span>
                    <InputTextArea
                      name='comment'
                      placeholder='Please fill in comments'
                      type='text'
                      autosize={{ minRows: 1, maxRows: 5 }}
                    />
                  </label>
               
              </form>

              <div className={styles.modalBtns}>
                <div>
                  <Button myClassName={styles.navigationBtn} onClick={onCancel}>
                    Back to Editing
                  </Button>
                </div>
                <div>
                  <Button
                    myClassName={styles.navigationBtn}
                    onClick={handleSubmit}
                    disabled={!isValid}>
                    Submit
                  </Button>
                </div>
              </div>
            </>
          </FormikProvider>
        </div>
      </Modal>
    ),
    setConfirmlModalVisible: setVisibleModal,
    confirmModalVisible: visibleModal,
  };
};
