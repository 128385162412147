import React, { useCallback } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { CreateUser, Button, CancelCreateModal } from '../../../components';
import { createUser,createUserAdmin } from '../../../store/users';
import { BackIcon } from '../../../assets';
import { usePreventLeave } from '../../../hooks/usePreventLeave';
import { createUserSchema } from '../../../utils';
import { USER_ROLES } from "../../../constants/constants";

import styles from './index.module.css';
import {Navigation} from  '../../../utils/navigation'

const initialValues = {
  email: '',
  firstName: '',
  lastName: '',
};

const Create_User = ({  createUser,createUserAdmin, managedRole, title, headText }) => {
  const navigate = Navigation();
  const formik = useFormik({
    initialValues,
    validateOnMount: true,
    validationSchema: createUserSchema,
    onSubmit: (values) => {
      unblockHistory?.current && unblockHistory.current();
      if (
        managedRole === USER_ROLES.TC_ADMIN ||
        managedRole === USER_ROLES.HO_ADMIN
      ) {
        createUserAdmin({payload:values,Navigation:navigate});
      } else {
        createUser({payload:values,Navigation:navigate});
      }
    },
  });

  const [visibleModal, setModalVisible, unblockHistory, location] = usePreventLeave(initialValues, formik.values);

  const handleBack = useCallback(() => {
    navigate.push(navigate?.location?.state?.from);
  }, [navigate]);

  const onCancel = useCallback(() => setModalVisible(false), [setModalVisible]);

  const onLeave = useCallback(() => {
    if (unblockHistory?.current) {
      unblockHistory.current();
      navigate.push(location.location);
    }
  }, [navigate, location, unblockHistory]);

  return (
    <div className={styles.pageContent}>
      <div className={styles.header}>
        <div className={styles.backBtn} onClick={handleBack}>
          <BackIcon width='18px' height='18px' fill='#BDBDBD' />
        </div>
        <h1 className={styles.pageTitle}>{title}</h1>
      </div>
      <div>
        <h2>{headText}</h2>
        <FormikProvider value={formik}>
          <form className={styles.form} onSubmit={formik.handleSubmit}>
            <CreateUser values={formik.values} {...formik} />
            <div className={styles.btnPanel}>
              <div className={styles.btnControl}>
                <Button myClassName={styles.navigationBtn} onClick={handleBack}>
                  Cancel
                </Button>
              </div>
              <div className={styles.btnControl}>
                <Button disabled={!formik.isValid} type='submit'>
                  Send Invite
                </Button>
              </div>
            </div>
          </form>
        </FormikProvider>
      </div>
      <CancelCreateModal
        title={`Canceling New ${managedRole}`}
        visible={visibleModal}
        onCancel={onCancel}
        onLeave={onLeave}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ createUser ,createUserAdmin }, dispatch);

export default connect(null, mapDispatchToProps)(Create_User);
