import React, { useEffect, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { EntitiesTableList } from '../../../components';
import { fetchHealthOrganizations, disableHO, clearHOInfo } from '../../../store/health_organizations';
import { resendInvitationForUser, sendNewPassword, loginAsDifferentRole } from '../../../store/authorization';
import { renderColumns, transformData } from './renderColumns';
import { URLS } from '../../../constants/constants';
import { redirectToPage } from '../../../utils/helpers';
import { usePagination } from '../../../hooks/usePagination';
import { useSearch } from '../../../hooks/useSearch';
import { Navigation } from '../../../utils/navigation';

const Health_Organizations = ({
  disableHO,
  fetchHealthOrganizations,
  limit,
  currentPage,
  total,
  totalPages,
  healthOrganizations, 
  sendNewPassword,
  resendInvitationForUser,
  clearHOInfo,
  loginAsDifferentRole,
}) => {
  const navigate = Navigation();
  usePagination(navigate);
  const { searchValue, highlighted, handleChangeSearch } = useSearch(fetchHealthOrganizations);

  useEffect(() => {fetchHealthOrganizations({Navigation:navigate});return;}, [fetchHealthOrganizations]);

  useEffect(() => () => {clearHOInfo(); return;}, [clearHOInfo]);

  const handleManageOrganization = useCallback(
    (id) => () =>  navigate.push(`/health_organizations/${id}/edit`),
    [navigate],
  );

  const switchOrganizationStatus = useCallback((status) => () => disableHO({...status,Navigation:navigate}), [disableHO,navigate]);

  const handleSendPassword = useCallback((id) => () => sendNewPassword(id), [sendNewPassword]);

  const handleResendInvitation = useCallback((id) => () => resendInvitationForUser(id), [resendInvitationForUser]);

  const handleChangePage = (page) => {
    redirectToPage(URLS.HEALTH_ORGANIZATIONS, page,navigate);
    fetchHealthOrganizations({...searchValue,Navigation:navigate});
  };

  const handleViewDashboard = useCallback(
    (viewDashboardParams) => () => loginAsDifferentRole({...viewDashboardParams,Navigation:navigate}),
    [loginAsDifferentRole],
  );

  const tableData = useMemo(() => {
    return transformData({
      data: healthOrganizations,
      handleManageOrganization,
      switchOrganizationStatus,
      handleSendPassword,
      handleResendInvitation,
      handleViewDashboard,
    });
  }, [
    healthOrganizations,
    handleManageOrganization,
    switchOrganizationStatus,
    handleSendPassword,
    handleViewDashboard,
    handleResendInvitation,
  ]);

  const columns = useMemo(() => {
    return healthOrganizations?.length > 0 ? renderColumns(searchValue, highlighted) : null;
  }, [healthOrganizations, highlighted, searchValue]);

  return (
    <EntitiesTableList
      title='Organizations'
      addNewBtnTitle='Add New Organization' 
      Navigation={navigate}
      columns={columns}
      data={tableData}
      handleChangePage={handleChangePage}
      showAddNewButton
      handleChangeSearch={handleChangeSearch}
      searchValue={searchValue}
      limit={limit}
      currentPage={currentPage}
      total={total}
      totalPages={totalPages}
    />
  );
};

const mapStateToProps = (state) => ({
  healthOrganizations: state.healthOrganizations.healthOrganizations,
  limit: state.healthOrganizations.limit,
  currentPage: state.healthOrganizations.currentPage || 1,
  total: state.healthOrganizations.total,
  totalPages: state.healthOrganizations.totalPages,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchHealthOrganizations,
      disableHO,
      sendNewPassword,
      clearHOInfo,
      resendInvitationForUser,
      loginAsDifferentRole,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Health_Organizations);
