import React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { bindActionCreators } from 'redux';

import { UpdateUser } from '../..';
import { USER_STATUSES } from '../../../constants/constants';
import { switchAdminForCurrentOrganization } from '../../../store/transport_organizations';
import { changeUserStatus } from '../../../store/users';
import { Navigation } from '../../../utils/navigation';

const TC_UpdateUser = ({ switchAdminForCurrentOrganization, changeUserStatus, ...props }) => {
  const navigate = Navigation();
  const { id } = useParams();
  const handleConfirmSwitchUser = (confirmCallback) =>
    switchAdminForCurrentOrganization({
      userId: id,
      confirmCallback,
    });

  const handleConfirmDisableUser = () => changeUserStatus({ id, status: { status: USER_STATUSES.DISABLED },Navigation:navigate});

  return (
    <UpdateUser
      title='User Details'
      headText='Information of the User'
      managedRole='User'
      Navigation ={navigate}
      handleConfirmSwitchUser={handleConfirmSwitchUser}
      handleConfirmDisableUser={handleConfirmDisableUser}
      id={id}
      {...props}
    />
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      switchAdminForCurrentOrganization,
      changeUserStatus,
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(TC_UpdateUser);
