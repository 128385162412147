import { RIDE_STATUSES } from '../../../constants/constants';

export const renderHeaderTitle = (status) => {
  switch (status) {
    case RIDE_STATUSES.ACCEPTED:
      return 'Upcoming Ride Details';

    case RIDE_STATUSES.COMPLETED:
    case RIDE_STATUSES.UNABLE_TO_FULFILL:
    case RIDE_STATUSES.UNFULFILLED:
      return 'Completed Ride Details';

    case RIDE_STATUSES.IN_PROGRESS:
      return 'Ride in Progress Details';

    default:
      return '-';
  }
};
