import Highlighter from "react-highlight-words";
import { Button } from "../../../components";
import { DateHelper, renderRecordValue } from "../../../utils";
import styles from "./index.module.css";
import { CloudDownloadOutlined } from "@ant-design/icons";
 

const renderButtons = ({ value,name, callBackMethod,showDownloadIcon }) => {
  
  
  return [
    value &&  <div key={0} className={styles.tableButton}>
      <Button
        myClassName={styles.navigationBtn}
        onClick={callBackMethod(value)}
      >
     {showDownloadIcon &&  <CloudDownloadOutlined className={styles.actionIcon} />}
       {name}
      </Button>
    </div>,
 
  ];
};

export const transformData = ({
  data, 
  handleDownloadInvoice,
  handleDownloadReciepts,
 
}) => {
  return data?.map((value) => {
    return {
      key: value.tcInvoiceId,
      invoiceId: value.tcInvoiceId,
      invoicePeriod:  `${value?.invoicePeriod}`,  
      issueDate: value?.issueDate,
      billedRides: renderButtons({
        value:value.id, 
        name:"Export",
        callBackMethod:handleDownloadReciepts
      }),
        buttons: renderButtons({
        value:value.fPath, 
        name:"Download",
        callBackMethod:handleDownloadInvoice,
        showDownloadIcon:true
        
      }),
    };
  });
};

export const renderColumns = (searchValue, highlighted) =>
  [
    {
      title: "Invoice ID",
      dataIndex: "invoiceId",
      key: "invoiceId",
      render: (text) => (
        <div className={styles.cellValue}>
          {searchValue && highlighted ? (
            <Highlighter
              highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
              searchWords={[searchValue]}
              autoEscape
              textToHighlight={text ? text.toString() : ""}
            />
          ) : (
            renderRecordValue(text)
          )}
        </div>
      ),
      width:  300,
    },
    {
        title: "Invoice Period",
        dataIndex: "invoicePeriod",
        key: "invoicePeriod",
        render: (text) =>
          renderRecordValue(text),
        width:  340,
      },
    {
      title: "Invoice Date",
      dataIndex: "issueDate",
      key: "issueDate",
      render: (text) =>
        renderRecordValue(text && DateHelper.format(new Date(text), "MM/dd/Y")),
      width: 240,
    },
    {
      title: "Monthly Billed Ride Data",
      dataIndex: "billedRides",
      key: "billedRides",
      render: (text) => renderRecordValue(text),
      render: (buttons) => (
        <div className={styles.btnPanel}>
          {buttons.map((Component) => Component)}
        </div>
      ),
      width: 240,
    },
  
    {
      title: "Invoices",
      key: "buttons",
      dataIndex: "buttons",
      render: (buttons) => (
        <div className={styles.btnPanel}>
          {buttons.map((Component) => Component)}
        </div>
      ),
      width: 300,
    },
  ];