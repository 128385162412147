import { useRef, useState } from 'react';
import { LodashHelper } from '../utils';
import { Navigation } from '../utils/navigation';

export const useSearch = (fetchData) => {
  const [searchValue, setSearchValue] = useState('');
  const [highlighted, setHighlighted] = useState(false);
   const navigate = Navigation();
  const delayedQuery = useRef(
    LodashHelper.debounce((value) => {
      value ? setHighlighted(true) : setHighlighted(false);
      return fetchData({value:value, filtered:true,Navigation:navigate});
    }, 1000),
  ).current;

  const handleChangeSearch = ({ name, value, setFieldValue }) => {
    setSearchValue(value);
    setFieldValue(name, value);
    delayedQuery(value);
  };

  return { searchValue, highlighted, handleChangeSearch };
};
