import Highlighter from 'react-highlight-words';

import { USER_STATUSES } from '../../../constants/constants';
import { vehicleOptionsDictionary } from '../../../dictionaries';
import { Button, renderResetCredentialsButton } from '../../../components';
import { renderRecordValue } from '../../../utils';

import styles from './index.module.css';

export const renderColumns = (searchValue, highlighted,Navigation) => [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (text) => (
      <div className={styles.cellValue}>
        {searchValue && highlighted ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchValue]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          renderRecordValue(text)
        )}
      </div>
    ),
    width: 180,
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    width: 280,
    render: (text) => (
      <div className={styles.cellValue}>
        {searchValue && highlighted ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchValue]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          renderRecordValue(text)
        )}
      </div>
    ),
  },
  {
    title: 'Vehicle Type',
    dataIndex: 'type',
    key: 'type',
    render: (text) => (
      <div className={styles.cellValue}>
        {text?.map((type, idx) => (
          <p key={type} className={styles.vehicle}>{`${renderRecordValue(
            vehicleOptionsDictionary[type],
          )}${renderRecordValue(text?.length > 1 && idx !== text?.length - 1 && ', ', '')}`}</p>
        ))}
      </div>
    ),
    width: 400,
  },
  {
    title: '',
    key: 'buttons',
    dataIndex: 'buttons',
    className: styles.btnsColumn,
    width: 480,
    render: (buttons) => <div className={styles.btnPanel}>{buttons.map((Component) => Component)}</div>,
  },
];

export const transformData = ({
  data,
  handleManageDriver,
  handleSendPassword,
  switchDriverStatus,
  handleResendInvitation,
  Navigation
}) => {
  return data?.map((value) => {
    return {
      key: value.id,
      name: value.firstName && value.lastName && renderRecordValue(`${value.firstName} ${value.lastName}`),
      email: value.email,
      type: value?.vehicleTypes,
      buttons:
        value.status === USER_STATUSES.DISABLED
          ? [
              <div key={0} className={styles.restoreAccess}>
                <Button
                  myClassName={styles.navigationBtn}
                  onClick={switchDriverStatus({ status: { status: USER_STATUSES.ACTIVE }, id: value.id, Navigation })}>
                  Activate Driver
                </Button>
              </div>,
            ]
          : [
              renderResetCredentialsButton({
                invited: value.status === USER_STATUSES.INVITED,
                handleSendPassword: handleSendPassword(value?.id),
                handleResendInvitation: handleResendInvitation(value?.id),
                resetButtonText: '(Send) Reset Password',
                resendButtonText: 'Resend Invitation',
              }),
              <div key={1} className={styles.tableButton}>
                <Button myClassName={styles.navigationBtn} onClick={handleManageDriver(value.id)}>
                  Manage Driver
                </Button>
              </div>,
            ],
    };
  });
};
