import classNames from 'classnames';
import { FormikProvider, useFormik } from 'formik';
import React, { useCallback, useMemo, useState } from 'react';
import { Button, Modal } from '../..';
import { reasonForUpdateSwitcherSchema } from '../../../utils/validations';
import { InputTextArea } from '../../formControls/Input/InputTextArea';

import styles from '../ConfirmEditRideModal/index.module.css';

import css from './index.module.css';

const initialValues = { updateReason: '' };

export const useSwticherNotificationsModal = ({ onConfirm }) => {
  const [visibleModal, setModalVisible] = useState(false);

  const handleConfirm = useCallback((values) => onConfirm(values), [onConfirm]);
  const formik = useFormik({
    initialValues,
    validationSchema: reasonForUpdateSwitcherSchema,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: handleConfirm,
  });

  const { resetForm, validateForm, isValid, handleSubmit } = formik;

  const onCancel = useCallback(async () => {
    await resetForm(initialValues);
    await validateForm();
    await setModalVisible(false);
  }, [setModalVisible, validateForm, resetForm]);

  const saveChangesButtonIsDisabled = useMemo(() => {
    if (visibleModal) {
      return !isValid;
    } else return true;
  }, [visibleModal, isValid]);

  return {
    Modal: (
      <Modal
        visible={visibleModal}
        onCancel={onCancel}
        closable
        width={700}
        title={<h3 className={styles.modalTitle}>Warning - You are about to make changes to notifications data!</h3>}>
        <div className={styles.modalContent}>
          <p className={css.modalDescription}>
            Please make sure that your changes are correct for the updating notifications switcher.
            <br />
            Your changes will be tracked under your login in the audit table.
          </p>
          <FormikProvider value={formik}>
            <>
              <form className={styles.form}>
                <label className={styles.comment}>
                  <span className={styles.required}>Reason for update</span>
                  <InputTextArea
                    name='updateReason'
                    placeholder='Please fill in reason for update'
                    type='text'
                    autosize={{ minRows: 1, maxRows: 5 }}
                    rows={4}
                  />
                </label>
              </form>

              <div
                className={classNames({
                  [styles.modalBtns]: true,
                  [css.modalBtnsWidth]: true,
                })}>
                <div>
                  <Button myClassName={styles.navigationBtn} onClick={onCancel}>
                    Back to Platform Settings
                  </Button>
                </div>
                <div>
                  <Button
                    myClassName={styles.navigationBtn}
                    onClick={handleSubmit}
                    disabled={saveChangesButtonIsDisabled}>
                    Save Changes
                  </Button>
                </div>
              </div>
            </>
          </FormikProvider>
        </div>
      </Modal>
    ),
    setConfirmlModalVisible: setModalVisible,
    confirmModalVisible: visibleModal,
    resetForm,
    validateForm,
  };
};
