import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';
import { Formik } from 'formik';

import { Modal, Button, DatePicker, Input, Tooltip } from '../../../components';
import { DateHelper, renderRecordValue } from '../../../utils';
import { timezonesDictionary } from '../../../dictionaries';
import { DEFAULT_TIME_FORMAT, TC_ENDRIDE_DEFAULT_VALUES } from '../../../constants/constants';
import { TCEndRideSchema } from '../../../utils/validations';
import styles from './index.module.css';
import { Alert } from 'antd';
import { deadHeadMileageTooltipMessage } from '../../MH_Admin/MHEditRide/TooltipMessages';
import { InfoOutlined } from '@ant-design/icons';
import { useDateTimeHandlers } from '../../MH_Admin/MHEditRide/useDateTimeHandlers';

export const RIDE_STATUS_MODALS_TYPES = {
  DRIVER_ARRIVED: 'DRIVER_ARRIVED',
  START_RIDE: 'START_RIDE',
  EDIT_RIDE: 'EDIT_RIDE',
  END_RIDE: 'END_RIDE',
};

const modalsInfo = {
  DRIVER_ARRIVED: {
    title: 'Driver Check-In Details',
  },
  START_RIDE: {
    title: 'Ride Started - Specify date and time',
  },
  EDIT_RIDE: {
    title: 'Edit Ride - Specify date and time',
  },
  END_RIDE: {
    title: 'Ride Ended',
    showComment: true,
  },
};

export const RideStatusModal = ({ visibleModal, onCancel, onConfirm, modalType, date, ride, setRideStatusModalType, tcConfiguration }) => {
  const [pickUpDate, setPickUpDate] = useState('');
  const isEndRide = modalType === RIDE_STATUS_MODALS_TYPES.END_RIDE;

  useEffect(() => {
    if(date.datetime)
    {
       
    setPickUpDate(DateHelper.utcToZonedTime(date.datetime, ride?.pickUpDate?.timezone));
    }
    
    return;
  }, [ride,date]);

  const {

    disabledDatesForPickUpDate,
    disabledAllDateBeforeAfter,
    disabledDateForDriverChekinBeforeAfter,
    diabledDateForStartDate,
    disabledDateTime,
    disabledDate,
    driverArrivalTime,
    startDate,
    minmumAllowedDriverArrvialTime,
    maximumAllowedDriverArrvialTime

  } = useDateTimeHandlers({
    ride

  });
  const minAvailablePickUpDate = useMemo(
    () =>

      DateHelper.subDates(
        DateHelper.utcToZonedTime(ride?.pickUpDate?.datetime, ride?.pickUpDate?.timezone),
        { hours: 5, minutes: 0 }
      ),
    [ride?.pickUpDate?.timezone, ride?.pickUpDate?.datetime]
  );
  const maxAvailablePickUpDate = useMemo(
    () =>
      DateHelper.addDays(
        DateHelper.utcToZonedTime(ride?.pickUpDate?.datetime, ride?.pickUpDate?.timezone),
        2
      ),
    [ride?.pickUpDate?.timezone, ride?.pickUpDate?.datetime]
  );



  const diabledDatesForPickUpField = useCallback(
    () => { 
       if (modalType === RIDE_STATUS_MODALS_TYPES.DRIVER_ARRIVED) {
        return disabledDateForDriverChekinBeforeAfter;
      } else 
      {
        return disabledDate(driverArrivalTime);
      }
      
     

    },
    [
      modalType,
      driverArrivalTime,
      disabledDatesForPickUpDate,
      diabledDateForStartDate,
      minmumAllowedDriverArrvialTime,
      maximumAllowedDriverArrvialTime
    ]
  );

  const diabledTimeForPickUpField = useCallback(
    () => {


      if (modalType == RIDE_STATUS_MODALS_TYPES.DRIVER_ARRIVED) {
      
        return disabledDateForDriverChekinBeforeAfter;
      } else {


        return disabledDate(driverArrivalTime);
      }
    

    },
    [

      disabledDatesForPickUpDate,
      disabledDate,
      minAvailablePickUpDate,
    ]
  );





  // const disabledDateTimeForPickUpField = useCallback(
  //   () =>
  //     disabledAllDateBeforeAfter({ date: pickUpDate, beforeDate: minAvailablePickUpDate, afterDate: maxAvailablePickUpDate }),
  //   [disabledAllDateBeforeAfter,
  //     pickUpDate,
  //     minAvailablePickUpDate,
  //   ]
  // );






  const handleChangeDateTime = ({ value, name, setFieldValue }) => {
    setPickUpDate(value);
    setFieldValue(name, DateHelper.zonedTimeToUtcISOS(value, date?.timezone));
  };

  const handleCancel = async () => await onCancel();

  const handleDateTimeToCurrent =
    ({ setFieldValue, name }) =>
      () => {
        setPickUpDate(DateHelper.utcToZonedTime(new Date(), date?.timezone));
        setFieldValue(
          name,
          DateHelper.zonedTimeToUtcISOS(DateHelper.utcToZonedTime(new Date(), date?.timezone), date?.timezone),
        );
      };

  const renderExtraFooter = () => null;

  // useEffect(() => () => { setRideStatusModalType(''); return; }, [setRideStatusModalType]);
  const isDisabled = (currentValues) => {
    if (isEndRide) {
      return !(currentValues?.datetime && !isNaN(currentValues?.actMileage) && (currentValues?.actMileage > 0))
    } else {
      return !(currentValues?.datetime)

    }

  }
  return (
    <Modal
      myClassName={classnames({
        [styles.rideStatusModal]: true,
        [styles.rideStatusEndedModal]: isEndRide,
      })}
      visible={visibleModal}
      onCancel={handleCancel}
      closable
      width={700}
      title={modalType === RIDE_STATUS_MODALS_TYPES.DRIVER_ARRIVED ? `Driver Check-In Details` :
        `${renderRecordValue(modalsInfo[modalType]?.title)} in ${timezonesDictionary[date?.timezone]} timezone`}>
      <div
        className={classnames({
          [styles.modalContent]: true,
        })}>
        <Formik
          initialValues={() => TC_ENDRIDE_DEFAULT_VALUES(isEndRide)}
          validationSchema={() => TCEndRideSchema(isEndRide)}
        >
          {({ values, setFieldValue }) => {
            return (
              <form className={styles.rideStatusForm}>
                {isEndRide && (<div >
                  <Alert message={'Mileage will only be added once'} description={'For update contact MedHaul admin'} type="info" showIcon banner
                    closable />
                  <label className={styles.comment}>
                    <span className={styles.required}> Ride total mileage:</span>
                    <Input name='actMileage' type='number' min={1} />
                  </label>
                  {tcConfiguration?.deadheadMileageConfigure &&


                    // <label className={styles.comment}>
                    //   <span  > Deadhead mileage:</span>
                    //   <Input name='deadheadMileage' type='number' min={0} />
                    // </label>

                    <div className={styles.deadheadWrapper} >
                      <label className={styles.deadhead}>
                        <span > Deadhead Mileage:</span>
                        <Input name='deadheadMileage' type='number' min={0} />
                      </label>
                      <span className={styles.tooltipWrapper}>
                        <Tooltip
                          title={
                            <span>
                              {deadHeadMileageTooltipMessage}
                            </span>
                          }>
                          <span className={styles.tooltipInfo}>
                            <InfoOutlined className={styles.infoIcon} />
                          </span>
                        </Tooltip>
                      </span>
                    </div>
                  }
                </div>

                )}

                <div className={styles.datePickerWrapper} >
                  <label className={styles.datepickerLabel}>
                    <span className={styles.dateHeader}>Date</span>
                    <span className={styles.timeHeader}>Time</span>
                  </label>
                  <DatePicker
                    hideInputField
                    defaultOpen={visibleModal}
                    name='datetime'
                    onChange={handleChangeDateTime}
                    format={DEFAULT_TIME_FORMAT}
                    value={pickUpDate}
                    disabledDate={diabledDatesForPickUpField()}
                    //disabledTime={diabledTimeForPickUpField}
                    showTime
                    use12Hours
                    renderExtraFooter={renderExtraFooter}
                    getPopupContainer={(trigger) => trigger.parentElement}
                  />
                </div>

                {modalType !== RIDE_STATUS_MODALS_TYPES.DRIVER_ARRIVED && (
                  <div className={styles.setDateTime}>
                    <Button
                      myClassName={styles.dateTimeBtn}
                      onClick={handleDateTimeToCurrent({ name: 'datetime', setFieldValue })}>
                      Set date and time to current in {timezonesDictionary[date?.timezone]} timezone
                    </Button>
                  </div>
                )}

               

                <div
                  className={classnames({
                    [styles.modalBtns]: true,
                    [styles.rideStatusBtn]: true,
                  })}>
                  <div>
                    <Button myClassName={styles.navigationBtn} onClick={handleCancel}>
                      Cancel
                    </Button>
                  </div>
                  <div>
                    <Button myClassName={styles.navigationBtn} onClick={onConfirm(values)} disabled={isDisabled(values)}>
                      Save
                    </Button>
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};
