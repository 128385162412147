import { RadioGroup } from '../../..';
import { radioOptions } from '../../mock';

import styles from './index.module.css';

export const transformRadioOptions = (options, disableAllValues) =>
  disableAllValues ? options.map((o) => ({ ...o, disabled: true })) : options;

export const VehicleQuestion = ({ question, disabledValues, onChange }) => {
  return (
    <div className={styles.vehicleTypeLabel}>
      <span className={styles.required}>{question.title}</span>
      <br />
      <RadioGroup
        name={question.name}
        onChange={onChange}
        radioFieldsCount={transformRadioOptions(radioOptions, disabledValues?.vehicleInformation)}
        disabled={disabledValues?.vehicleInformation}
        whenFieldDisabledShowTooltip={Boolean(disabledValues?.vehicleInformation)}
      />
    </div>
  );
};
