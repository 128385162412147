import {
  endOfMonth,
  endOfToday,
  endOfWeek,
  startOfMonth,
  startOfToday,
  startOfWeek,
} from "date-fns";
import { CalendarOutlined } from "@ant-design/icons";

export const NEXT_DAY = 86400000;
export const MINIMUM_DELAY_FOR_ROUNDTRIP_RIDE = { hours: 0, minutes: 5 };
export const MINIMUM_BUFFER_TIME_FOR_CAR_BOOSTER_RIDE = { hours: 0, minutes: 10 };
export const ADDITIONAL_DELAY_FOR_RIDE_CREATION = { hours: 0, minutes: 5 };

export const DELETE_ADDRESSBOOK_ITEM_MESSAGE=`This address will be permanently removed from your saved 
locations. Would you like to continue?`
export const CANCEL_ROUNDTRIP_HEADING="If you cancel this ride, both legs of the roundtrip will be canceled. Would you like to proceed?";
export const CANCEL_ROUNDTRIP_POPUP_TITLE="Roundtrip Alert: All rides in the roundtrip will be cancelled";

export const RESCHEDULE_ROUNDTRIP_POPUP_TITLE="Roundtrip Alert: All rides in the roundtrip will be rescheduled";
export const RESCHEDULE_ROUNDTRIP_HEADING="Both legs of the roundtrip will be rescheduled. Would you like to proceed?";

export const COVID_ERROR_MESSAGE =
  "In accordance with the guidelines from the CDC and recommendations from the Department of Health, confirmed cases of COVID19 cannot be booked through the MedHaul platform at this time.";

// export const Midnight_Noon_Clarification =
//   "(Note: 12am = Midnight; 12pm = Noon)";

export const TIMEOUT = 260000; // changing timeout to 260 seconds because export files request takes time to process.

export const ADD_SAMEDAY_CANCELLATION_CHARGE=`Add Same Day Cancellation Fee to Ride Receipt`;
export const ADD_RIDER_NOSHOW_CANCELLATION_CHARGE=`Add Rider No Show Fee to Ride Receipt`;

export const USER_STATUSES = {
  ACTIVE: "ACTIVE",
  DISABLED: "DISABLED",
  INVITED: "INVITED",
};

export const USER_ROLES = {
  HO_ADMIN: "HO_ADMIN",
  HO_EMPLOYEE: "HO_EMPLOYEE",
  TC_ADMIN: "TC_ADMIN",
  TC_EMPLOYEE: "TC_EMPLOYEE",
  SUPER_ADMIN: "SUPER_ADMIN",
  DRIVER: "DRIVER",
  SUPER_TITLE: "MH Admin",
};

export const RIDE_STATUSES = {
  PENDING: "PENDING",
  ACCEPTED: "ACCEPTED",
  IN_PROGRESS: "IN_PROGRESS",
  CANCELED: "CANCELED",
  EXPIRED: "EXPIRED",
  UNFULFILLED: "UNFULFILLED",
  DECLINED: "DECLINED",
  UNABLE_TO_FULFILL: "UNABLE_TO_FULFILL",
  CLAIMED: "CLAIMED",
  COMPLETED: "COMPLETED",
  NEW_REQUEST: "NEW_REQUEST",
   RESCHEDULED:"RESCHEDULED"
};

export const RIDE_ACTION_STATUSES = {
  CREATED: "Created",
}

export const ERideCancellationReason = {
  NO_SHOW: 'Ride no Show',
  SAME_DAY_CANCEL: 'Same Day Cancellation'
}

export const OTHER_CANCELLATION_REASON = {title: 'Other', value: "Other"};
 
export const E_RIDE_CANCELLATION_REASON = [
  {title: ERideCancellationReason.NO_SHOW, value: ERideCancellationReason.NO_SHOW},
 {title: ERideCancellationReason.SAME_DAY_CANCEL, value: ERideCancellationReason.SAME_DAY_CANCEL},
  OTHER_CANCELLATION_REASON,
 ]

export const COVID_OPTIONS = [
  {
    title: "The rider does not have COVID-19",
    value: "The rider does not have COVID-19",
  },
  {
    title: "Rider has a confirmed case of COVID-19",
    value: "Rider has a confirmed case of COVID-19",
  },
];

export const VEHICLE_OPTIONS = [
  {
    id: 0,
    title:
      "Ambulatory Assist - Rider is able to walk and needs driver's assistance (Door-through-Door)",
    value: "ambulatoryAssistRiderIsWalking",
  },
  {
    id: 1,
    title: "Ambulatory - Rider is able to walk & does NOT need assistance",
    value: "ambulatoryRiderIsWalking",
  },
  {
    id: 2,
    title: "Stretcher Bound - Bariatric (over 250 lbs)",
    value: "stretcherBoundBariatric",
  },
  {
    id: 3,
    title: "Stretcher Bound - Non-Bariatric",
    value: "stretcherBoundNonBariatric",
  },
  {
    id: 4,
    title: "Wheelchair Bound - Bariatric (over 250 lbs)",
    value: "wheelchairBoundBariatric",
  },
  {
    id: 5,
    title: "Wheelchair Bound - Non-Bariatric",
    value: "wheelchairBoundNonBariatric",
  },
];

export const FLEET_OPTIONS = [
  {
    id: 0,
    title:
      "Ambulatory Assist - Rider is able to walk and needs driver's assistance (Door-through-Door)",
    value: "ambulatoryAssistRiderIsWalking",
    vehicles: null,
    drivers: null,
  },
  {
    id: 1,
    title: "Ambulatory - Rider is able to walk & does NOT need assistance",
    value: "ambulatoryRiderIsWalking",
    vehicles: null,
    drivers: null,
  },
  {
    id: 2,
    title: "Stretcher Bound - Bariatric (over 250 lbs)",
    value: "stretcherBoundBariatric",
    vehicles: null,
    drivers: null,
  },
  {
    id: 3,
    title: "Stretcher Bound - Non-Bariatric",
    value: "stretcherBoundNonBariatric",
    vehicles: null,
    drivers: null,
  },
  {
    id: 4,
    title: "Wheelchair Bound - Bariatric (over 250 lbs)",
    value: "wheelchairBoundBariatric",
    vehicles: null,
    drivers: null,
  },
  {
    id: 5,
    title: "Wheelchair Bound - Non-Bariatric",
    value: "wheelchairBoundNonBariatric",
    vehicles: null,
    drivers: null,
  },
  {
    id: 6,
    title: "Totals",
    value: "totals",
    vehicles: null,
    drivers: null,
  },
];

export const TIMEZONES = [
  { title: "ET", value: "America/New_York" },
  { title: "CT", value: "America/Chicago" },
  { title: "MT", value: "America/Denver" },
  { title: "PT", value: "America/Los_Angeles" },
];

export const BILL_STATUS = {title: "Billed", value: "BILLED"};

export const STATUSES_BY_ROLE = {
  [USER_ROLES.TC_ADMIN]: [
    { title: "Accepted", value: "ACCEPTED" },
    BILL_STATUS,
    { title: "Canceled", value: "CANCELED" },
    //{ title: "Claimed", value: "CLAIMED" },
    { title: "Completed", value: "COMPLETED" },
    { title: "Declined", value: "DECLINED" },
    { title: "Expired", value: "EXPIRED" },
    { title: "In progress", value: "IN_PROGRESS" },
    { title: "New request", value: "NEW_REQUEST" },
    // {/* ToDo Rescheudleing */}  { title: "Rescheduled", value: "RESCHEDULED" },
    { title: "Unable to fulfill", value: "UNABLE_TO_FULFILL" },
    { title: "Unfulfilled", value: "UNFULFILLED" },
    
  ],

  [USER_ROLES.SUPER_ADMIN]: [
    { title: "Accepted", value: "ACCEPTED" },
    BILL_STATUS,
    { title: "Canceled", value: "CANCELED" },
    { title: "Completed", value: "COMPLETED" },
    { title: "Expired", value: "EXPIRED" },
    { title: "In progress", value: "IN_PROGRESS" },
    { title: "Pending", value: "PENDING" },
    //{/* ToDo Rescheudleing */}  { title: "Rescheduled", value: "RESCHEDULED" },
    { title: "Unable to fulfill", value: "UNABLE_TO_FULFILL" },
    { title: "Unfulfilled", value: "UNFULFILLED" },
  ],

  [USER_ROLES.HO_ADMIN]: [
    { title: "Accepted", value: "ACCEPTED" },
    BILL_STATUS,
    { title: "Canceled", value: "CANCELED" },
    { title: "Completed", value: "COMPLETED" },
    { title: "Expired", value: "EXPIRED" },
    { title: "In progress", value: "IN_PROGRESS" },
    { title: "Pending", value: "PENDING" },
 
    { title: "Unable to fulfill", value: "UNABLE_TO_FULFILL" },
  ],
};

export const DECLINE_RIDE_OPTIONS = [
  "No drivers available",
  "Can not accommodate the ride specifications",
  "Vehicles for this request are down",
  "If roundtrip, can only facilitate one leg of the request",
];
export const  CANCELLATION_TYPE_BY_ROLE={
  cancelByHO:"cancelByHO",
  cancelByTC:"cancelByTC",
  riderNoShow:"riderNoShow",
  cancelByRider:"cancelByRider",
  cancelByDriver:"cancelByDriver",
  cancelByMHAdmin:"cancelByMHAdmin",
 
}

export const riderNoShowChargeMessage_MHAdmin='Select this checkbox to add a rider no-show fee for cancellation due to rider’s unavailability'
export const sameDayCancellationChargeMessage_MHAdmin='Select this checkbox to add a same-day cancellation fee for rides canceled on the day of scheduled pickup'

export const riderNoChargeMessage='A Rider No-Show charge will be applied if the ride is canceled within 24 hours of the scheduled pickup time.'
export const sameDayCancelChargeMessage='A same-day cancellation charge will be applied if the ride is canceled within 24 hours of the scheduled pickup time.'
export const CANCEL_BY_FORCE_UPDATE ={
  [RIDE_STATUSES.ACCEPTED] : [{ title: "Health Organization", value: USER_ROLES.HO_ADMIN},

  { title: "Transportation Company", value: USER_ROLES.TC_ADMIN},
  { title: "Driver", value: USER_ROLES.DRIVER},
  { title: "Rider", value: 'RIDER'}
 ],
 [RIDE_STATUSES.PENDING] : [{ title: "Health Organization", value: USER_ROLES.HO_ADMIN},
   { title: "Rider", value: 'RIDER'}
 ]
}

export const CANCEL_RIDE_OPTIONS_FORCE_UPDATE =
 [
  { title: "Incorrect ride details.", value: "Incorrect ride details.",
  cancellationType: CANCELLATION_TYPE_BY_ROLE.riderNoShow, additionalComments:'' },
  { title: "Rider declined ride after driver started or arrived.", value: "Rider declined ride after driver started or arrived.",
  cancellationType: CANCELLATION_TYPE_BY_ROLE.riderNoShow, additionalComments:'' },
  {
    title: "Rider has alternate transportation.(Rider No Show)",
    value: "Rider has alternate transportation.(Rider No Show)",
    cancellationType: CANCELLATION_TYPE_BY_ROLE.riderNoShow, additionalComments:''
  },
  {
    title:
      "Rider needed accommodations that were not indicated in the request and driver is unable to facilitate.",
    value:
      "Rider needed accommodations that were not indicated in the request and driver is unable to facilitate.",
     
  cancellationType: CANCELLATION_TYPE_BY_ROLE.riderNoShow, additionalComments:''
  },
  { title: "Rider was not on scene.", value: "Rider was not on scene.",
  cancellationType: CANCELLATION_TYPE_BY_ROLE.riderNoShow, additionalComments:'' },
  {
    title: "Rider was not on scene when the driver arrived.",
    value: "Rider was not on scene when the driver arrived.",
    cancellationType: CANCELLATION_TYPE_BY_ROLE.riderNoShow, additionalComments:''
  },
  {
    title: "Rider was undergoing a medical emergency.(Rider No Show)",
    value: "Rider was undergoing a medical emergency.(Rider No Show)",
    cancellationType: CANCELLATION_TYPE_BY_ROLE.riderNoShow, additionalComments:''
  },

  {
    title: "Unforeseen issue with driver's vehicle.",
    value: "Unforeseen issue with driver's vehicle.",
    
  cancellationType: CANCELLATION_TYPE_BY_ROLE.cancelByDriver, additionalComments:''
  },
  {
    title: "Rider's appointment cancelled.",
    value: "Rider's appointment cancelled.",
    cancellationType: CANCELLATION_TYPE_BY_ROLE.cancelByHO, additionalComments:''
  },
  {
    title: "Rider's appointment rescheduled.",
    value: "Rider's appointment rescheduled.",
    cancellationType: CANCELLATION_TYPE_BY_ROLE.cancelByHO, additionalComments:''
  },

  {
    title: "Rider has alternate transportation.(Cancel By HO)",
    value: "Rider has alternate transportation.(Cancel By HO)",
    cancellationType: CANCELLATION_TYPE_BY_ROLE.cancelByHO, additionalComments:''
  },
  {
    title: "Rider has conflict and unable to make appointment.",
    value: "Rider has conflict and unable to make appointment.",
    cancellationType: CANCELLATION_TYPE_BY_ROLE.cancelByHO, additionalComments:''
  },
  {
    title: "Rider was undergoing a medical emergency.(Cancel By HO)",
    value: "Rider was undergoing a medical emergency.(Cancel By HO)",
    cancellationType: CANCELLATION_TYPE_BY_ROLE.cancelByHO, additionalComments:''
  },
  {
    title: "I have alternate transportation.",
    value: "I have alternate transportation.",
    cancellationType: CANCELLATION_TYPE_BY_ROLE.cancelByRider, additionalComments:''
  },
  { title: "I have a conflict.", value: "I have a conflict.",
  cancellationType: CANCELLATION_TYPE_BY_ROLE.cancelByRider, additionalComments:'' },
  {
    title: "My appointment was canceled.",
    value: "My appointment was canceled.",
    cancellationType: CANCELLATION_TYPE_BY_ROLE.cancelByRider, additionalComments:''
  },
  {
    title: "My appointment was rescheduled.",
    value: "My appointment was rescheduled.",
    cancellationType: CANCELLATION_TYPE_BY_ROLE.cancelByRider, additionalComments:''
  },
 ]
export const CANCEL_RIDE_OPTIONS = {
  [USER_ROLES.DRIVER]: [
    { title: "Incorrect ride details.", value: "Incorrect ride details.",
    cancellationType: CANCELLATION_TYPE_BY_ROLE.riderNoShow, information:riderNoChargeMessage, additionalComments:'' },
    { title: "Rider declined ride after driver started or arrived.", value: "Rider declined ride after driver started or arrived.",
    cancellationType: CANCELLATION_TYPE_BY_ROLE.riderNoShow,information:riderNoChargeMessage, additionalComments:'' },
    {
      title: "Rider has alternate transportation.",information:riderNoChargeMessage,
      value: "Rider has alternate transportation.",
      cancellationType: CANCELLATION_TYPE_BY_ROLE.riderNoShow, additionalComments:''
    },
    {
      title:
        "Rider needed accommodations that were not indicated in the request and driver is unable to facilitate.",
      value:
        "Rider needed accommodations that were not indicated in the request and driver is unable to facilitate.",
       
    cancellationType: CANCELLATION_TYPE_BY_ROLE.riderNoShow,information:riderNoChargeMessage, additionalComments:''
    },
    { title: "Rider was not on scene.", value: "Rider was not on scene.",
    cancellationType: CANCELLATION_TYPE_BY_ROLE.riderNoShow,information:riderNoChargeMessage, additionalComments:'' },
    {
      title: "Rider was undergoing a medical emergency.",
      value: "Rider was undergoing a medical emergency.",
      cancellationType: CANCELLATION_TYPE_BY_ROLE.riderNoShow,information:riderNoChargeMessage, additionalComments:''
    },

    {
      title: "Unforeseen issue with driver's vehicle.",
      value: "Unforeseen issue with driver's vehicle.",
      
    cancellationType: CANCELLATION_TYPE_BY_ROLE.cancelByDriver, additionalComments:''
    },

    // { title: "Other", value: "Other" },
  ],
  [USER_ROLES.TC_ADMIN]: [
    { title: "Incorrect ride details.", value: "Incorrect ride details.",
    cancellationType: CANCELLATION_TYPE_BY_ROLE.riderNoShow,information:riderNoChargeMessage, additionalComments:'' },
    { title: "Rider declined ride after driver started or arrived.", value: "Rider declined ride after driver started or arrived.",
    cancellationType: CANCELLATION_TYPE_BY_ROLE.riderNoShow,information:riderNoChargeMessage, additionalComments:'' },
    {
      title: "Rider has alternate transportation.",
      value: "Rider has alternate transportation.",
      cancellationType: CANCELLATION_TYPE_BY_ROLE.riderNoShow,information:riderNoChargeMessage, additionalComments:''
    },
    {
      title:
        "Rider needed accommodations that were not indicated in the request and driver is unable to facilitate.",
      value:
        "Rider needed accommodations that were not indicated in the request and driver is unable to facilitate.",
        cancellationType: CANCELLATION_TYPE_BY_ROLE.riderNoShow,information:riderNoChargeMessage, additionalComments:''
    },
    { title: "Rider was not on scene.", value: "Rider was not on scene.",
    cancellationType: CANCELLATION_TYPE_BY_ROLE.riderNoShow,information:riderNoChargeMessage, additionalComments:'' },

    {
      title: "Rider was undergoing a medical emergency.",
      value: "Rider was undergoing a medical emergency.",
      cancellationType: CANCELLATION_TYPE_BY_ROLE.riderNoShow,information:riderNoChargeMessage, additionalComments:''
    },

    {
      title: "Unforeseen issue with driver's vehicle.",
      value: "Unforeseen issue with driver's vehicle.",
      cancellationType: CANCELLATION_TYPE_BY_ROLE.cancelByTC, additionalComments:''
    },

     
  ],
  [USER_ROLES.TC_EMPLOYEE]: [
    { title: "Incorrect ride details.", value: "Incorrect ride details.",
    cancellationType: CANCELLATION_TYPE_BY_ROLE.riderNoShow,information:riderNoChargeMessage, additionalComments:'' },
    { title: "Rider declined ride after driver started or arrived.", value: "Rider declined ride after driver started or arrived.",
    cancellationType: CANCELLATION_TYPE_BY_ROLE.riderNoShow,information:riderNoChargeMessage, additionalComments:'' },
    {
      title: "Rider has alternate transportation.",
      value: "Rider has alternate transportation.",
      cancellationType: CANCELLATION_TYPE_BY_ROLE.riderNoShow,information:riderNoChargeMessage, additionalComments:''
    },
    {
      title:
        "Rider needed accommodations that were not indicated in the request and driver is unable to facilitate.",
      value:
        "Rider needed accommodations that were not indicated in the request and driver is unable to facilitate.",
        cancellationType: CANCELLATION_TYPE_BY_ROLE.riderNoShow,information:riderNoChargeMessage, additionalComments:''
    },
    { title: "Rider was not on scene.", value: "Rider was not on scene.",
    cancellationType: CANCELLATION_TYPE_BY_ROLE.riderNoShow,information:riderNoChargeMessage, additionalComments:'' },

    {
      title: "Rider was undergoing a medical emergency.",
      value: "Rider was undergoing a medical emergency.",
      cancellationType: CANCELLATION_TYPE_BY_ROLE.riderNoShow,information:riderNoChargeMessage, additionalComments:''
    },

    {
      title: "Unforeseen issue with driver's vehicle.",
      value: "Unforeseen issue with driver's vehicle.",
      cancellationType: CANCELLATION_TYPE_BY_ROLE.cancelByDriver, additionalComments:''
    },

   
  ],
  [USER_ROLES.HO_ADMIN]: [
    {
      title: "Rider's appointment cancelled.",
      value: "Rider's appointment cancelled.",
      cancellationType: CANCELLATION_TYPE_BY_ROLE.cancelByHO,information:sameDayCancelChargeMessage, additionalComments:''
    },
    {
      title: "Rider's appointment rescheduled.",
      value: "Rider's appointment rescheduled.",
      cancellationType: CANCELLATION_TYPE_BY_ROLE.cancelByHO,information:sameDayCancelChargeMessage, additionalComments:''
    },

    {
      title: "Rider has alternate transportation.",
      value: "Rider has alternate transportation.",
      cancellationType: CANCELLATION_TYPE_BY_ROLE.cancelByHO,information:sameDayCancelChargeMessage, additionalComments:''
    },
    {
      title: "Rider has conflict and unable to make appointment.",
      value: "Rider has conflict and unable to make appointment.",
      cancellationType: CANCELLATION_TYPE_BY_ROLE.cancelByHO,information:sameDayCancelChargeMessage,additionalComments:''
    },
    {
      title: "Rider was undergoing a medical emergency.",
      value: "Rider was undergoing a medical emergency.",
      cancellationType: CANCELLATION_TYPE_BY_ROLE.cancelByHO,information:sameDayCancelChargeMessage, additionalComments:''
    },

  
  ],
  [USER_ROLES.HO_EMPLOYEE]: [
    {
      title: "Rider's appointment cancelled.",
      value: "Rider's appointment cancelled.",
      cancellationType: CANCELLATION_TYPE_BY_ROLE.cancelByHO,information:sameDayCancelChargeMessage, additionalComments:''
    },
    {
      title: "Rider's appointment rescheduled.",
      value: "Rider's appointment rescheduled.",
      cancellationType: CANCELLATION_TYPE_BY_ROLE.cancelByHO,information:sameDayCancelChargeMessage, additionalComments:''
    },

    {
      title: "Rider has alternate transportation.",
      value: "Rider has alternate transportation.",
      cancellationType: CANCELLATION_TYPE_BY_ROLE.cancelByHO,information:sameDayCancelChargeMessage, additionalComments:''
    },
    {
      title: "Rider has conflict and unable to make appointment.",
      value: "Rider has conflict and unable to make appointment.",
      cancellationType: CANCELLATION_TYPE_BY_ROLE.cancelByHO,information:sameDayCancelChargeMessage, additionalComments:''
    },
    {
      title: "Rider was undergoing a medical emergency.",
      value: "Rider was undergoing a medical emergency.",
      cancellationType: CANCELLATION_TYPE_BY_ROLE.cancelByHO,information:sameDayCancelChargeMessage, additionalComments:''
    }
  ],
  [USER_ROLES.SUPER_ADMIN]: [
    {
      title: "Rider has alternate transportation.",
      value: "Rider has alternate transportation.",
      cancellationType: CANCELLATION_TYPE_BY_ROLE.riderNoShow,information:riderNoChargeMessage, additionalComments:''
    },
    {
      title:
        "Rider needed accommodations that were not indicated in the request and driver is unable to facilitate.",
      value:
        "Rider needed accommodations that were not indicated in the request and driver is unable to facilitate.",
        cancellationType: CANCELLATION_TYPE_BY_ROLE.riderNoShow,information:riderNoChargeMessage, additionalComments:''
    },
    {
      title: "Rider was not on scene when the driver arrived.",
      value: "Rider was not on scene when the driver arrived.",
      cancellationType: CANCELLATION_TYPE_BY_ROLE.riderNoShow,information:riderNoChargeMessage, additionalComments:''
    },

    {
      title: "Rider was undergoing a medical emergency.",
      value: "Rider was undergoing a medical emergency.",
      cancellationType: CANCELLATION_TYPE_BY_ROLE.riderNoShow,information:riderNoChargeMessage, additionalComments:''
    },

    {
      title: "Unforeseen issue with driver's vehicle.",
      value: "Unforeseen issue with driver's vehicle.",
      cancellationType: CANCELLATION_TYPE_BY_ROLE.cancelByDriver, additionalComments:''
    },
 
  ],
  RIDER: [
    {
      title: "I have alternate transportation.",
      value: "I have alternate transportation.",
      cancellationType: CANCELLATION_TYPE_BY_ROLE.cancelByRider,information:sameDayCancelChargeMessage, additionalComments:''
    },
    { title: "I have a conflict.", value: "I have a conflict.",
    cancellationType: CANCELLATION_TYPE_BY_ROLE.cancelByRider,information:sameDayCancelChargeMessage, additionalComments:'' },
    {
      title: "My appointment was canceled.",
      value: "My appointment was canceled.",
      cancellationType: CANCELLATION_TYPE_BY_ROLE.cancelByRider,information:sameDayCancelChargeMessage, additionalComments:''
    },
    {
      title: "My appointment was rescheduled.",
      value: "My appointment was rescheduled.",
      cancellationType: CANCELLATION_TYPE_BY_ROLE.cancelByRider,information:sameDayCancelChargeMessage, additionalComments:''
    },

    
  ],
};

export const EXPORT_FORMATS = {
  CSV: "csv",
  XLSX: "xlsx",
};

export const VEHICLE_TYPES = {
  AMBULATORY_RIDER_IS_WALKING: "ambulatoryRiderIsWalking",
  STRETCHER_BOUND_BARIATRIC: "stretcherBoundBariatric",
  STRETCHER_BOUND_NON_BARIATRIC: "stretcherBoundNonBariatric",
  AMBULATORY_ASSIST_RIDER_IS_WALKING: "ambulatoryAssistRiderIsWalking",
  WHEEL_CHAIR_BOUND_BARIATRIC: "wheelchairBoundBariatric",
  WHEEL_CHAIR_BOUND_NON_BARIATRIC: "wheelchairBoundNonBariatric",
};

export const VEHICLE_TYPES_BARIATRIC = [
  VEHICLE_TYPES.STRETCHER_BOUND_BARIATRIC,
  VEHICLE_TYPES.WHEEL_CHAIR_BOUND_BARIATRIC,
];

export const VEHICLE_TYPES_BARIATRIC_HEIGHT = [
  VEHICLE_TYPES.STRETCHER_BOUND_NON_BARIATRIC,
  VEHICLE_TYPES.STRETCHER_BOUND_BARIATRIC,
  VEHICLE_TYPES.WHEEL_CHAIR_BOUND_BARIATRIC,
  VEHICLE_TYPES.WHEEL_CHAIR_BOUND_NON_BARIATRIC,
];

export const VEHICLE_TYPES_NON_BARIATRIC = [
  VEHICLE_TYPES.AMBULATORY_RIDER_IS_WALKING,
  VEHICLE_TYPES.STRETCHER_BOUND_NON_BARIATRIC,
  VEHICLE_TYPES.AMBULATORY_ASSIST_RIDER_IS_WALKING,
  VEHICLE_TYPES.WHEEL_CHAIR_BOUND_NON_BARIATRIC,
];

export const DATE_RANGE_FILTER = {
  TODAY: "TODAY",
  THIS_WEEK: "THIS_WEEK",
  THIS_MONTH: "THIS_MONTH",
  DATE_RANGE: "DATE_RANGE",
};

export const defaultRangeOptions = [
  {
    title: "Show all",
    date: "not specified",
  },
  {
    title: "Today",
    date: {
      range: DATE_RANGE_FILTER.TODAY,
      from: startOfToday().getTime(),
      to: endOfToday().getTime(),
    },
  },
  {
    title: "This week",
    date: {
      range: DATE_RANGE_FILTER.THIS_WEEK,
      from: startOfWeek(new Date()).getTime(),
      to: endOfWeek(new Date()).getTime(),
    },
  },
  {
    title: "This month",
    date: {
      range: DATE_RANGE_FILTER.THIS_MONTH,
      from: startOfMonth(new Date()).getTime(),
      to: endOfMonth(new Date()).getTime(),
    },
  },
  {
    name: "dateRange",
    title: (
      <>
        Specific date range{" "}
        <CalendarOutlined
          style={{ color: "rgba(0, 0, 0, 0.25)", marginLeft: "8px" }}
        />
      </>
    ),
    date: {
      from: "",
      to: "",
    },
  },
];

export const ERROR_TYPES = {
  INTERNAL_SERVER_ERROR: "Internal Server Error. Try again later",
  NETWORK_ERROR: "Network Error. Try again later",
  DEFAULT: "Something went wrong. Try again later",
  REFRESH_TOKEN: "Your session has expired",
  ROLE_CHANGED: "Your role has been changed",
  RECAPTCHA: "Can not get access to Google API",
};

export const URLS = {
  HOME: "/",
  RIDES: "/rides",
  CREATE_RIDE: "/rides/create",
  EDIT_RIDE: "/rides/:id/edit",
  RESCHEDULE_RIDE: "/rides/:id/reschedule",
  RIDE_DETAILS: "/rides/:id",
  CANCEL_RIDE: "/rides/:id/cancel",
  DRAFTS: "/drafts",
  EDIT_DRAFT: "/drafts/edit",
  DRAFT_DETAILS: "/drafts/:id",
  HEALTH_ORGANIZATIONS: "/health_organizations",
  CREATE_HEALTH_ORGANIZATION: "/health_organizations/new",
  EDIT_HEALTH_ORGANIZATION: "/health_organizations/:id/edit",
  TRANSPORT_ORGANIZATIONS: "/transport_companies",
  CREATE_TRANSPORT_ORGANIZATION: "/transport_companies/new",
  EDIT_TRANSPORT_ORGANIZATION: "/transport_companies/:id/edit",
  ADMINS: "/admins",
  CREATE_ADMIN: "/admins/new",
  EDIT_ADMIN: "/admins/:id/edit",
  USERS: "/users",
  CREATE_USER: "/users/new",
  EDIT_USER: "/users/:id/edit",
  RIDERS: "/riders",
  BILLING: "/billing",
  CREATE_RIDER: "/riders/new",
  EDIT_RIDER: "/riders/:id/edit",
  RIDER_DETAILS: "/riders/:id",
  DRIVERS: "/drivers",
  CREATE_DRIVER: "/drivers/new",
  EDIT_DRIVER: "/drivers/:id/edit",
  PREFERENCES: "/preferences",
  VIEW_DASHBOARD: "/view-dashboard",
  LOGIN: "/login",
  FORGOT: "/forgot",
  RESEND_INVITE: "/resend-invite",
  RESET_PASSWORD: "/reset-password",
  SET_ACCOUNT: "/set-account",
  FEEDBACK: "/feedback/:rideId",
  NOT_FOUND: "/not-found",
  RIDE_NOT_FOUND: "/ride-not-found",
  CANCEL_RIDE_BY_RIDER: "/:id/cancel-ride-by-rider",
  SET_DRIVER_ACCOUNT: "/set-driver-account",
  CREATE_ADMIN_USER: "/users/admin/new",
  SET_EXTERNAL_DRIVER: "/set-external-driver",
};

export const NOTIFICATIONS_ACTIONS = {
  RIDE_CREATED: "RIDE_CREATED",
  RIDE_UPDATED: "RIDE_UPDATED",
  RIDE_ACCEPTED: "RIDE_ACCEPTED",
  RIDE_DECLINED: "RIDE_DECLINED",
  RIDE_IN_PROGRESS: "RIDE_IN_PROGRESS",
  RIDE_COMPLETED: "RIDE_COMPLETED",
  RIDE_CANCELED: "RIDE_CANCELED",
  RIDE_UNASSIGNED: "RIDE_UNASSIGNED",
   RIDE_RESCHEDULED:"RIDE_RESCHEDULED"
};

export const CREATE_RIDE_REQUEST_STEPS = {
  FIRST: "Rider Information",
  SECOND: "Vehicle Information",
  THIRD: "Location and Time",
  FOURTH: "Additional Information",
  FIFTH: "Review and Submit",
};
export const RIDE_REQUEST_FIELDS = {
  covid19Relation: "covid19Relation",
  costCenter: "costCenter",
  additionalFundingSource: "additionalFundingSource",
  documentReasonForRide: "documentReasonForRide",
};

export const THIRD_STEP_RIDE_REQUEST_FIELDS = {
  costCenter: "costCenter",
  additionalFundingSource: "additionalFundingSource",
  documentReasonForRide: "documentReasonForRide",
};
export const MANDATORY_LEVEL = {
  MANDATORY: "Mandatory",
  OPTIONAL: "Optional",
};
export const DEFAULT_TIME = { hours: 0, minutes: 0 };

export const DEFAULT_TIME_FORMAT = "MM/dd/yyyy, hh:mm a";

export const GOOGLE_MAPS_TRAVEL_MODE = "DRIVING";

export const GOOGLE_AUTOCOMPLETE_RESPONSE_TYPE = "ROOFTOP";

export const SIDEBAR_DRAWER_SIZE = {
  BREAKPOINT: 812,
  COLLAPSED_WIDTH: 64,
  DESKTOP_EXPAND_WIDTH: 220,
  MOBILE_EXPAND_WIDTH: 200,
};

export const STEPPER_BREAKPOINT = 1200;

export const USA_COUNTRY_CODE = "+1";

export const DEFAULT_PHONE_NUMBER_FORMAT = `${USA_COUNTRY_CODE} (###)-###-####`;

export const REQUIRED_ADDITIONAL_OPTION_FOR_VEHICLE_TYPE_OVER_250_LBS =
  "Is over 250 lbs";

export const MODAL_WILL_APPEAR_AFTER_MS = 600000;

export const MODAL_WILL_AUTO_CLOSE_AFTER_MS = 60000;

export const MODAL_AUTO_CLOSE_AFTER_SEC = 60;

export const DRIVER_NOT_ASSIGNED = "Driver NOT Assigned";

export const NOT_SELECTED = "Not selected";

export const INVALID_ADDRESS_MESSAGE =
  "*Invalid Address. Make sure you enter and select the complete and exact street address for your location " +
  '(Example: "1600 Amphitheatre Parkway, Mountain View, CA")';

export const RECURRING_FREQUENCY = {
  DAILY: "DAILY",
  WEEKLY: "WEEKLY",

  MONTHLY: "MONTHLY",
  WEEKDAYS: "WEEKDAYS",
  CUSTOM: "CUSTOM",
};

export const RECCURING_CUSTOM_FREQUENCY_OPTIONS = [
  { title: "day(s)", value: RECURRING_FREQUENCY.DAILY },
  { title: "week(s)", value: RECURRING_FREQUENCY.WEEKLY },
  { title: "month(s)", value: RECURRING_FREQUENCY.MONTHLY },
];

export const RECURRING_REPEATS_OPTIONS = [
  { title: "Daily (Monday to Sunday)", value: RECURRING_FREQUENCY.DAILY },
  { title: "Weekly", value: RECURRING_FREQUENCY.WEEKLY },
  { title: "Monthly", value: RECURRING_FREQUENCY.MONTHLY },
  {
    title: "Every weekday (Monday to Friday)",
    value: RECURRING_FREQUENCY.WEEKDAYS,
  },
  { title: "Custom", value: RECURRING_FREQUENCY.CUSTOM },
];

export const DAYS = [
  { id: 0, title: "S", value: 0 },
  { id: 1, title: "M", value: 1 },
  { id: 2, title: "T", value: 2 },
  { id: 3, title: "W", value: 3 },
  { id: 4, title: "T", value: 4 },
  { id: 5, title: "F", value: 5 },
  { id: 6, title: "S", value: 6 },
];

export const MONTHL_TYPE = {
  BY_WEEK_INDEX: "BY_WEEK_INDEX",
  BY_DAY_OF_MONTH: "BY_DAY_OF_MONTH",
};

export const DISABLED_FIELD_TOOLTIP_CONTENT = `This data is uneditable. If you need to change these parameters,
create a copy of this ride, make necessary changes and submit
a new request. Cancel the old ride if necessary.`;

export const APPOINTMENT_DATE_TOOLTIP_CONTENT = `Please enter the rider’s appointment date and time if available. If it’s unknown or not applicable, enter the pickup date and time instead. Note: The appointment timezone will default to the pickup timezone.`;

export const TIMEZONE_TOOLTIP_CONTENT = `Please select Timezone before selecting Date and Time.
`;

export const RIDE_LOGISTICS_TOOLTIP_CONTENT = `The Estimated Time may significantly vary depending upon time of day, weather, and traffic conditions. Please allow
additional time for rider handling, paperwork, loading, and unloading.
`;

export const CONFIRM_ROUNDTRIP_RIDE_CONTENT = `By accepting this ride, both legs of the roundtrip will be confirmed. Would you like to proceed?`;
export const CONFIRM_ROUNDTRIP_RIDE_TITLE = `Roundtrip Alert: All rides in the roundtrip will be accepted`;

export const RECURRING_RIDE_EDIT_MODE = {
  THIS_ONLY: "THIS_ONLY",
  THIS_AND_FOLLOWING: "THIS_AND_FOLLOWING",
  ALL_IN_SERIES: "ALL_IN_SERIES",
};

export const VIEW_DASHBOARD_LOGIN_TYPES = {
  [USER_ROLES.HO_ADMIN]: 0,
  [USER_ROLES.TC_ADMIN]: 1,
};

export const languages = [
  { title: "English", value: "en" },
  { title: "Spanish", value: "es" },
  // { title: "Afrikaans", value: "af" },
  // { title: "Arabic", value: "ar" },
  // { title: "Catalan", value: "ca" },
  // { title: "Chinese", value: "zh" },
  // { title: "Chinese (Mandarin)", value: "zh-CN" },
  // { title: "Chinese (Cantonese)", value: "zh-HK" },
  // { title: "Croatian", value: "hr" },
  // { title: "Czech", value: "cs" },
  // { title: "Danish", value: "da" },
  // { title: "Dutch", value: "nl" },
  // { title: "English (British)", value: "en-GB" },
  // { title: "Finnish", value: "fi" },
  // { title: "French", value: "fr" },
  // { title: "German", value: "de" },
  // { title: "Greek", value: "el" },
  // { title: "Hebrew", value: "he" },
  // { title: "Hindi", value: "hi" },
  // { title: "Hungarian", value: "hu" },
  // { title: "Indonesian", value: "id" },
  // { title: "Italian", value: "it" },
  // { title: "Japanese", value: "ja" },
  // { title: "Korean", value: "ko" },
  // { title: "Malay", value: "ms" },
  // { title: "Norwegian", value: "nb" },
  // { title: "Polish", value: "pl" },
  // { title: "Portuguese - Brazil", value: "pt-BR" },
  // { title: "Portuguese", value: "pt" },
  // { title: "Romanian", value: "ro" },
  // { title: "Russian", value: "ru" },
  // { title: "Swedish", value: "sv" },
  // { title: "Tagalog", value: "tl" },
  // { title: "Thai", value: "th" },
  // { title: "Turkish", value: "tr" },
  // { title: "Vietnamese", value: "vi" },
];

export const COMMUNICATION_TYPES = {
  PHONE_CALL: "PHONE_CALL",
  TEXT_SMS: "TEXT_SMS",
  EMAIL: "EMAIL",
};

export const GENDER_TYPES = {
  MALE: "MALE",
  FEMALE: "FEMALE",
  OTHER: "OTHER",
  DECLINE_TO_SAY: "DECLINE_TO_SAY",
};

export const IMPAIRMENTS_TYPES = [
  { title: "Vision", value: "VISION" },
  { title: "Hearing", value: "HEARING" },
  { title: "Cognitive", value: "COGNITIVE" },
  { title: "Speech", value: "SPEECH" },
];

export const RELATIONSHIP_TYPES = [
  { title: "Spouse", value: "SPOUSE" },
  { title: "Child", value: "CHILD" },
  { title: "Parent", value: "PARENT" },
  { title: "Relative", value: "RELATIVE" },
  { title: "Friend", value: "FRIEND" },
  { title: "Medical Professional", value: "MEDICAL_PROFESSIONAL" },
];

export const RECAPTHA_ACTIONS = {
  LOGIN: "LOGIN",
  FORGOT: "FORGOT",
  RESEND_INVITE: "RESEND_INVITE",
  RESET_PASSWORD: "RESET_PASSWORD",
  SET_ACCOUNT: "SET_ACCOUNT",
  SET_DRIVER_ACCOUNT: "SET_DRIVER_ACCOUNT",
  CANCEL_RIDE_BY_RIDER: "CANCEL_RIDE_BY_RIDER",
};

export const REASONS_TO_EDIT_RIDE = {
  TC_REASON:
    "Driver / TC did not update the ride information (pickup time / actual pickup time / drop-off time) in time",
  HO_REASON: "Pickup time was changed by HO",
  CUSTOM_REASON: "Other",
};

export const SUPER_ADMIN_RIDE_EDIT_REASONS = [
  {
    title: REASONS_TO_EDIT_RIDE.TC_REASON,
    value: REASONS_TO_EDIT_RIDE.TC_REASON,
  },
  {
    title: REASONS_TO_EDIT_RIDE.HO_REASON,
    value: REASONS_TO_EDIT_RIDE.HO_REASON,
  },
  {
    title: REASONS_TO_EDIT_RIDE.CUSTOM_REASON,
    value: REASONS_TO_EDIT_RIDE.CUSTOM_REASON,
  },
];

export const REQUEST_CHANGE_OPTIONS = [
  {
    title: 'RESOLVED',
    value: 'RESOLVED',
  },
  {
    title: 'REJECTED',
    value: 'REJECTED',
  } 
];
export const REASONS_TO_EDIT_TRANSPORT_COMPANY = {
  STAFF_REASON: "Change in staffing",
  SERVICES_REASON: "Change in service offerings",
  GROWTH_REASON: "Company growth",
};
export const SUPER_ADMIN_TRANSPORT_COMPANY_EDIT_REASONS = [
  {
    title: REASONS_TO_EDIT_TRANSPORT_COMPANY.STAFF_REASON,
    value: REASONS_TO_EDIT_TRANSPORT_COMPANY.STAFF_REASON,
  },
  {
    title: REASONS_TO_EDIT_TRANSPORT_COMPANY.SERVICES_REASON,
    value: REASONS_TO_EDIT_TRANSPORT_COMPANY.SERVICES_REASON,
  },
  {
    title: REASONS_TO_EDIT_TRANSPORT_COMPANY.GROWTH_REASON,
    value: REASONS_TO_EDIT_TRANSPORT_COMPANY.GROWTH_REASON,
  },
];
export const WEEK_DAYS = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",

  "Thursday",
  "Friday",
  "Saturday",
];

export const HOURS_OPTIONS = [
  { title: "0", value: 0 },
  { title: "1", value: 1 },
  { title: "2", value: 2 },

  { title: "3", value: 3 },
  { title: "4", value: 4 },
  { title: "5", value: 5 },

  { title: "6", value: 6 },
  { title: "7", value: 7 },
  { title: "8", value: 8 },

  { title: "9", value: 9 },
  { title: "10", value: 10 },
  { title: "11", value: 11 },

  { title: "12", value: 12 },
  { title: "13", value: 13 },
  { title: "14", value: 14 },

  { title: "15", value: 15 },
  { title: "16", value: 16 },
  { title: "17", value: 17 },

  { title: "18", value: 18 },
  { title: "19", value: 19 },
  { title: "20", value: 20 },

  { title: "21", value: 21 },
  { title: "22", value: 22 },
  { title: "23", value: 23 },
];

export const WEEKLY_OPERATIONAL_HOURS = [
  { id: 0, title: "Sunday", value: "Sunday", open: null, close: null },

  {
    id: 1,

    title: "Monday",

    value: "Monday",

    open: null,

    close: null,
  },

  {
    id: 2,

    title: "Tuesday",

    value: "Tuesday",

    open: null,

    close: null,
  },

  {
    id: 3,

    title: "Wednesday",

    value: "Wednesday",

    open: null,

    close: null,
  },

  {
    id: 4,

    title: "Thursday",

    value: "Thursday",

    open: null,

    close: null,
  },

  {
    id: 5,

    title: "Friday",

    value: "Friday",

    open: null,

    close: null,
  },

  {
    id: 6,

    title: "Saturday",

    value: "Saturday",

    open: null,

    close: null,
  },
];

export const HOLIDAY_OPERATIONAL_HOURS = {
  id: 7,
  title: "Holiday",
  value: "Holiday",
  open: null,
  close: null,
};

export const TC_OPERATIONAL_HOURS = {
  weekdays: WEEKLY_OPERATIONAL_HOURS,
  holiday: HOLIDAY_OPERATIONAL_HOURS,
};

export const OPERATIONAL_WORKING_HOUR_TYPE = {
  WEEKLY: "WEEKLY",
  HOLIDAY: "HOLIDAY",
};

export const CANCEL_RIDE_PREFIX_BY_ROLES = {
  [USER_ROLES.SUPER_ADMIN]: {
    [USER_ROLES.SUPER_ADMIN]: "MedHaul Admin",
    [USER_ROLES.HO_ADMIN]: "Admin",
    [USER_ROLES.HO_EMPLOYEE]: "User",
    [USER_ROLES.TC_ADMIN]: "Transportation Company",
    [USER_ROLES.TC_EMPLOYEE]: "Transportation Company",
    [USER_ROLES.DRIVER]: "Driver",
    RIDER: "Rider",
  },

  [USER_ROLES.HO_ADMIN]: {
    [USER_ROLES.SUPER_ADMIN]: "MedHaul Admin",
    [USER_ROLES.HO_ADMIN]: "Admin",
    [USER_ROLES.HO_EMPLOYEE]: "User",
    [USER_ROLES.TC_ADMIN]: "Transportation Company",
    [USER_ROLES.TC_EMPLOYEE]: "Transportation Company",
    [USER_ROLES.DRIVER]: "Transportation Company",
    RIDER: "Rider",
  },

  [USER_ROLES.HO_EMPLOYEE]: {
    [USER_ROLES.SUPER_ADMIN]: "MedHaul Admin",
    [USER_ROLES.HO_ADMIN]: "Admin",
    [USER_ROLES.HO_EMPLOYEE]: "User",
    [USER_ROLES.TC_ADMIN]: "Transportation Company",
    [USER_ROLES.TC_EMPLOYEE]: "Transportation Company",
    [USER_ROLES.DRIVER]: "Transportation Company",
    RIDER: "Rider",
  },

  [USER_ROLES.TC_ADMIN]: {
    [USER_ROLES.SUPER_ADMIN]: "MedHaul Admin",
    [USER_ROLES.HO_ADMIN]: "Healthcare Organization",
    [USER_ROLES.HO_EMPLOYEE]: "Healthcare Organization",
    [USER_ROLES.TC_ADMIN]: "Transportation Company Admin",
    [USER_ROLES.TC_EMPLOYEE]: "Transportation Company User",
    [USER_ROLES.DRIVER]: "Transportation Driver",
    RIDER: "Rider",
  },

  [USER_ROLES.TC_EMPLOYEE]: {
    [USER_ROLES.SUPER_ADMIN]: "MedHaul Admin",
    [USER_ROLES.HO_ADMIN]: "Healthcare Organization",
    [USER_ROLES.HO_EMPLOYEE]: "Healthcare Organization",
    [USER_ROLES.TC_ADMIN]: "Transportation Company Admin",
    [USER_ROLES.TC_EMPLOYEE]: "Transportation Company User",
    [USER_ROLES.DRIVER]: "Transportation Driver",
    RIDER: "Rider",
  },

  [USER_ROLES.DRIVER]: {
    [USER_ROLES.SUPER_ADMIN]: "MedHaul Admin",
    [USER_ROLES.HO_ADMIN]: "Healthcare Organization",
    [USER_ROLES.HO_EMPLOYEE]: "Healthcare Organization",
    [USER_ROLES.TC_ADMIN]: "Transportation Company Admin",
    [USER_ROLES.TC_EMPLOYEE]: "Transportation Company User",
    [USER_ROLES.DRIVER]: "Transportation Driver",
    RIDER: "Rider",
  },
};

export const DEFAULT_LOAD_TIME = { loadTime: 0, unloadTime: 0 };

export const LOAD_UNLOAD_FIELD_ARRAY = [
  { id: 1, fieldName: "loadTime", title: "Load" },
  { id: 2, fieldName: "unloadTime", title: "Unload" },
];

export const BILLING_TABS_OBJECT = {
  Transaction: "Transaction",
  Subscription: "Subscription",
  SJCRH: "SJCRH",
};

export const RECEIPT_STATUS = {
    DRAFT: "DRAFT",
    INVOICED: "INVOICED",
    ARCHIVED: "ARCHIVED",
    PAID: "PAID",
}

export const DEFAULT_BILLING_TRANSACTION = {
  pricingType: "Transaction",
  pricingStatus: "Active",
  subscriptionFee: null,
  ridesPerMonth: null,
  flatRate: {
    ambulatory: {
      miles0_25: 0,
    },
    stretcher: {
      miles0_25: 0,
    },
    wheelchair: {
      miles0_25: 0,
    },
  },

  additionalRate: {
    milesSurcharge25_90: {
      fee: 0,
      enabled: true,
    },
    milesSurcharge90Plus: {
      fee: 0,
      enabled: true,
    },
  },

  holiday: { fee: 0, enabled: true },
  inclementWeather: { fee: 0, enabled: true },
  overnightRide: { fee: 0, enabled: true },
  rideNoShow: { fee: 0, enabled: true },
  sameDayCancellation: { fee: 0, enabled: true },
  waitTime: { fee: 0, enabled: true },
};

export const DEFAULT_BILLING_SUBSCRIPTION = {
  pricingType: "Subscription",
  pricingStatus: "Active",
  subscriptionFee: 0,
  ridesPerMonth: 0,
  flatRate: {
    ambulatory: {
      miles0_10: 0,
      miles10_25: 0,
    },
    stretcher: {
      miles0_10: 0,
      miles10_25: 0,
    },
    wheelchair: {
      miles0_10: 0,
      miles10_25: 0,
    },
  },

  additionalRate: {
    milesSurcharge25_90: {
      fee: 0,
      enabled: true,
    },
    milesSurcharge90Plus: {
      fee: 0,
      enabled: true,
    },
  },

  holiday: { fee: 0, enabled: true },
  inclementWeather: { fee: 0, enabled: true },
  overnightRide: { fee: 0, enabled: true },
  rideNoShow: { fee: 0, enabled: true },
  sameDayCancellation: { fee: 0, enabled: true },
  waitTime: { fee: 0, enabled: true },
};

export const DEFAULT_BILLING_SJCRH = {
  pricingType: "SJCRH",
  organizationId: null,
  subscriptionFee: null,
  ridesPerMonth: null,
  pricingStatus: "Active",
  flatRate: {
    isolation: {
      miles0_3: 0,
      moreThen3Miles: 0,
    },
    nonIsolation: {
      miles0_3: 0,
      moreThen3Miles: 0,
    },
  },
  additionalRate: {
    milesSurcharge35_90: {
      fee: 0,
      enabled: true,
    },
    milesSurcharge90Plus: {
      fee: 0,
      enabled: true,
    },
  },
  holiday: { fee: 0, enabled: true },
  inclementWeather: { fee: 0, enabled: true },
  overnightRide: { fee: 0, enabled: true },
  rideNoShow: { fee: 0, enabled: true },
  sameDayCancellation: { fee: 0, enabled: true },
  waitTime: { fee: 0, enabled: true },
};

export const DEFAULT_TC_BILLING = {
  pricingStatus: "Active",
  flatRate: {
    ambulatory: {
      fee: 0,
          },
    wheelchair: {
      fee: 0,
          }, 
    stretcher: {
      fee: 0,
          }
  },
  additionalRate: {
    ambulatory: {
      fee: 0,
      enabled: true,
    },
    wheelchair: {
      fee: 0,
      enabled: true,
    },
    stretcher: {
      fee: 0,
      enabled: true,
    },
  },
  rideNoShow: { fee: 0, enabled: true },
  sameDayCancellation: { fee: 0, enabled: true },
  waitTime: { fee: 0, enabled: true },
};


export const TC_ENDRIDE_DEFAULT_VALUES = (isEndRide)=>{
  return isEndRide ? { datetime: '', actMileage: 1 } : { datetime: '' };
}

export const DRIVER_ENDRIDE_DEFAULT_VALUES = (isEndRide)=>{
  return isEndRide ? { comment: '', actMileage: 1 } : { comment: '' };
}
export const GRACE_PERIOD_FOR_WAIT_TIME = 15;