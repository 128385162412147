import React from "react";
import { FlightInformation, Modal } from "../..";

export const renderShowFlightInfoModal = ({ flightInfo, handleCancel }) => (
  <Modal
    visible={true}
    onCancel={handleCancel}
    closable
    width={700}
    title="Flight Information"
    mask={true}
  >
    <div>
      <FlightInformation flightInfo={flightInfo} />
    </div>
  </Modal>
);
