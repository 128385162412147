import React from 'react';
import { Popover } from 'antd';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { BackIcon } from '../../../assets';
import { Button } from '../../';
import { LodashHelper, renderRecordValue } from '../../../utils';
import { useCurrentRole } from '../../../hooks/useCurrentRole';
import { URLS } from '../../../constants/constants';
import HO_ADMIN_GUIDE from '../../../assets/docs/HO_ADMIN_GUIDE.pdf';
import HO_USER_GUIDE from '../../../assets/docs/HO_USER_GUIDE.pdf';
import TC_USER_GUIDE from '../../../assets/docs/TC_USER_GUIDE.pdf';

import styles from './index.module.css';

export const PopOver = ({
  user,
  role,
  visible,
  handleLogout,
  toggleVisibility,
  viewDashboardMode,
  handleLeaveViewDashboardMode,
}) => {
  const { isDriver, isMHAdmin, isHOAdmin, isTCAdmin, isTCWorker, isEmployee, isHOWorker } = useCurrentRole(role);
  const userExistInLocalStorage = localStorage.getItem('user');
  const userGuide = isHOAdmin ? HO_ADMIN_GUIDE : (isEmployee && isHOWorker) ? HO_USER_GUIDE : null;
  const tcUserGuide = isTCWorker ? TC_USER_GUIDE : null;
  
  return (
    <Popover
      className={styles.popOver}
      overlayClassName={styles.overlay}
      placement='bottomRight'
      content={
        <ul className={styles.popOverList}>
          {viewDashboardMode || (
            <li className={styles.popOverListItem} onClick={() => toggleVisibility(false)}>
              <NavLink to={URLS.PREFERENCES}>{isDriver ? 'Preferences' : 'Account settings'}</NavLink>
            </li>
          )}
          {viewDashboardMode && (
            <li
              className={classnames({
                [styles.popOverListItem]: true,
                [styles.disabled]: !userExistInLocalStorage,
              })}
              onClick={() => {
                if (userExistInLocalStorage) {
                  handleLeaveViewDashboardMode();
                  toggleVisibility(false);
                }
              }}>
              {userExistInLocalStorage ? (
                <span className={styles.popOverOption}>Return to MedHaul Admin dashboard</span>
              ) : (
                <span className={styles.popOverListItem}>Return to MedHaul Admin dashboard</span>
              )}
            </li>
          )}
          {(isHOAdmin || (isEmployee && isHOWorker)) && (
            <li className={classnames({ [styles.popOverListItem]: true })}>
              <a href={userGuide} target="_blank" rel="noreferrer" open>
                User Guide
              </a>
            </li>
          )}
          {(isTCAdmin || (isEmployee && isTCWorker)) && (
            <li className={classnames({ [styles.popOverListItem]: true })}>
              <a href={tcUserGuide} target="_blank" rel="noreferrer" open>
                User Guide
              </a>
            </li>
          )}
          <li
            className={classnames({
              [styles.popOverListItem]: true,
              [styles.logoutBtn]: true,
            })}
            onClick={handleLogout}>
            Log out
          </li>
        </ul>
      }
      trigger='click'
      open={visible}
      onOpenChange={toggleVisibility}>
      <div
        className={classnames({
          [styles.btn]: isMHAdmin,
        })}>
        <Button myClassName={styles.styledBtn}>
          <span className={styles.userName}>
            <>
              {renderRecordValue(LodashHelper.isString(user.firstName) && `${user.firstName} `)}
              {renderRecordValue(LodashHelper.isString(user.lastName) && user.lastName)}
            </>
          </span>
          <BackIcon className={styles.backIcon} fill='#DE7F39' />
        </Button>
      </div>
    </Popover>
  );
};

PopOver.propTypes = {
  handleLogout: PropTypes.func,
  toggleVisibility: PropTypes.func,
  visible: PropTypes.bool,
  user: PropTypes.object,
  role: PropTypes.string,
  viewDashboardMode: PropTypes.bool,
  handleLeaveViewDashboardMode: PropTypes.func,
};
