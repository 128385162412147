import { showNotificationMessage } from "../components";
import { ERROR_TYPES, URLS } from "../constants/constants";
import { baseRequest, renderRecordValue } from "../utils";
import { getPage } from "../utils/helpers";
import { logOut } from "./authorization";
import { types as commonTypes } from "./common";

export const types = {
  CREATE_HEALTH_ORGANIZATION_REQUEST: "CREATE_HEALTH_ORGANIZATION_REQUEST",
  CREATE_HEALTH_ORGANIZATION_SUCCESS: "CREATE_HEALTH_ORGANIZATION_SUCCESS",
  CREATE_HEALTH_ORGANIZATION_ERROR: "CREATE_HEALTH_ORGANIZATION_ERROR",

  UPDATE_HEALTH_ORGANIZATION_REQUEST: "UPDATE_HEALTH_ORGANIZATION_REQUEST",
  UPDATE_HEALTH_ORGANIZATION_SUCCESS: "UPDATE_HEALTH_ORGANIZATION_SUCCESS",
  UPDATE_HEALTH_ORGANIZATION_ERROR: "UPDATE_HEALTH_ORGANIZATION_ERROR",

  DISABLE_HEALTH_ORGANIZATION_REQUEST: "DISABLE_HEALTH_ORGANIZATION_REQUEST",
  DISABLE_HEALTH_ORGANIZATION_SUCCESS: "DISABLE_HEALTH_ORGANIZATION_SUCCESS",
  DISABLE_HEALTH_ORGANIZATION_ERROR: "DISABLE_HEALTH_ORGANIZATION_ERROR",

  FETCH_ALL_HEALTH_ORGANIZATIONS_REQUEST:
    "FETCH_ALL_HEALTH_ORGANIZATIONS_REQUEST",
  FETCH_ALL_HEALTH_ORGANIZATIONS_SUCCESS:
    "FETCH_ALL_HEALTH_ORGANIZATIONS_SUCCESS",
  FETCH_ALL_HEALTH_ORGANIZATIONS_ERROR: "FETCH_ALL_HEALTH_ORGANIZATIONS_ERROR",

  FETCH_HEALTH_ORGANIZATIONS_REQUEST: "FETCH_HEALTH_ORGANIZATIONS_REQUEST",
  FETCH_HEALTH_ORGANIZATIONS_SUCCESS: "FETCH_HEALTH_ORGANIZATIONS_SUCCESS",
  FETCH_HEALTH_ORGANIZATIONS_ERROR: "FETCH_HEALTH_ORGANIZATIONS_ERROR",

  FETCH_HEALTH_ORGANIZATION_REQUEST: "FETCH_HEALTH_ORGANIZATION_REQUEST",
  FETCH_HEALTH_ORGANIZATION_SUCCESS: "FETCH_HEALTH_ORGANIZATION_SUCCESS",
  FETCH_HEALTH_ORGANIZATION_ERROR: "FETCH_HEALTH_ORGANIZATION_ERROR",

  FETCH_HEALTH_ORGANIZATION_PREFERENCES_REQUEST:
    "FETCH_HEALTH_ORGANIZATION_PREFERENCES_REQUEST",
  FETCH_HEALTH_ORGANIZATION_PREFERENCES_SUCCESS:
    "FETCH_HEALTH_ORGANIZATION_PREFERENCES_SUCCESS",
  FETCH_HEALTH_ORGANIZATION_PREFERENCES_ERROR:
    "FETCH_HEALTH_ORGANIZATION_PREFERENCES_ERROR",

  HO_ADMIN_UPDATE_OWN_ORGANIZATION_REQUEST:
    "HO_ADMIN_UPDATE_OWN_ORGANIZATION_REQUEST",
  HO_ADMIN_UPDATE_OWN_ORGANIZATION_SUCCESS:
    "HO_ADMIN_UPDATE_OWN_ORGANIZATION_SUCCESS",
  HO_ADMIN_UPDATE_OWN_ORGANIZATION_ERROR:
    "HO_ADMIN_UPDATE_OWN_ORGANIZATION_ERROR",

  MH_SWITCH_HO_ADMIN_REQUEST: "MH_SWITCH_HO_ADMIN_REQUEST",
  MH_SWITCH_HO_ADMIN_SUCCESS: "MH_SWITCH_HO_ADMIN_SUCCESS",
  MH_SWITCH_HO_ADMIN_ERROR: "MH_SWITCH_HO_ADMIN_ERROR",

  MH_INVITE_NEW_USER_FOR_HO_REQUEST: "MH_INVITE_NEW_USER_FOR_HO_REQUEST",
  MH_INVITE_NEW_USER_FOR_HO_SUCCESS: "MH_INVITE_NEW_USER_FOR_HO_SUCCESS",
  MH_INVITE_NEW_USER_FOR_HO_ERROR: "MH_INVITE_NEW_USER_FOR_HO_ERROR",

  CLEAR_HO_INFO: "CLEAR_HO_INFO",

  SET_CURRENT_PAGE_HO: "SET_CURRENT_PAGE_HO",

  FETCH_HO_PRICING_REQUEST: "FETCH_HO_PRICING_REQUEST",
  FETCH_HO_PRICING_SUCCESS: "FETCH_HO_PRICING_SUCCESS",
  FETCH_HO_PRICING_ERROR: "FETCH_HO_PRICING_ERROR",

  SET_HO_PRICING_REQUEST: "SET_HO_PRICING_REQUEST",
  SET_HO_PRICING_SUCCESS: "SET_HO_PRICING_SUCCESS",
  SET_HO_PRICING_ERROR: "SET_HO_PRICING_ERROR",
};

export const fetchHOPricingRequest = () => ({
  type: types.FETCH_HO_PRICING_REQUEST,
});

export const SetHOPricingRequest = () => ({
  type: types.SET_HO_PRICING_REQUEST,
});

export const clearHOInfo = () => ({
  type: types.CLEAR_HO_INFO,
});

const createHORequest = () => ({
  type: types.CREATE_HEALTH_ORGANIZATION_REQUEST,
});

const updateHORequest = () => ({
  type: types.UPDATE_HEALTH_ORGANIZATION_REQUEST,
});

const disableHORequest = () => ({
  type: types.DISABLE_HEALTH_ORGANIZATION_REQUEST,
});

const fetchAllHOsRequest = () => ({
  type: types.FETCH_ALL_HEALTH_ORGANIZATIONS_REQUEST,
});

const fetchHOsRequest = () => ({
  type: types.FETCH_HEALTH_ORGANIZATIONS_REQUEST,
});

const fetchHORequest = () => ({
  type: types.FETCH_HEALTH_ORGANIZATION_REQUEST,
});

const fetchHOPreferencesRequest = () => ({
  type: types.FETCH_HEALTH_ORGANIZATION_PREFERENCES_REQUEST,
});

const HoAdminUpdateOwnOrganizationRequest = () => ({
  type: types.HO_ADMIN_UPDATE_OWN_ORGANIZATION_REQUEST,
});

export const switchHoAdminRequest = () => ({
  type: types.MH_SWITCH_HO_ADMIN_REQUEST,
});

const inviteNewUserforHoRequest = () => ({
  type: types.MH_INVITE_NEW_USER_FOR_HO_REQUEST,
});

export const setCurrentPage = (page) => ({
  type: types.SET_CURRENT_PAGE_HO,
  payload: page,
});

export const fetchAllHealthOrganizations = () => {
  const success = (payload) => ({
    type: types.FETCH_ALL_HEALTH_ORGANIZATIONS_SUCCESS,
    payload,
  });

  const failure = (error) => ({
    type: types.FETCH_ALL_HEALTH_ORGANIZATIONS_ERROR,
    payload: error,
  });

  return (dispatch) => {
    dispatch(fetchAllHOsRequest());
    return baseRequest("GET", `/health-organizations/list`)
      .then((response) => {
        dispatch(success(response.data));
      })
      .catch((error) => {
        if (error) dispatch(failure([]));
      });
  };
};

export const fetchHealthOrganizations = ({ value, filtered, Navigation }) => {
  const navigate = Navigation;
  const success = (payload) => ({
    type: types.FETCH_HEALTH_ORGANIZATIONS_SUCCESS,
    payload,
  });

  const failure = (error) => ({
    type: types.FETCH_HEALTH_ORGANIZATIONS_ERROR,
    payload: error,
  });

  return (dispatch, getStore) => {
    if (filtered) navigate.push(URLS.HEALTH_ORGANIZATIONS);
    const { limit } = getStore().healthOrganizations;
    const currentPage = getPage(navigate?.location);
    dispatch(setCurrentPage(currentPage));
    dispatch(fetchHOsRequest());
    return baseRequest(
      "GET",
      `/health-organizations`,
      null,
      {},
      { docsPerPage: limit, page: currentPage, value: value || "" }
    )
      .then((response) => {
        dispatch(success(response.data));
      })
      .catch((error) => {
        if (error) dispatch(failure([]));
      });
  };
};

export const postHealthOrganizationPricing = (values) => {
  const success = (payload) => ({
    type: types.SET_HO_PRICING_SUCCESS,
    payload,
  });

  const failure = (error) => ({
    type: types.SET_HO_PRICING_ERROR,
    payload: error,
  });

  return (dispatch) => {
    dispatch(SetHOPricingRequest());
 
    return baseRequest("POST", `/ho-pricing`, values)
      .then((response) => {
         
        dispatch(success(response?.data));
        // dispatch(fetchHOPreferences());
      })
      .catch((error) => {
        if (error) dispatch(failure(error?.response?.data));
      });
  };
};

export const fetchHealthOrganizationPricing = (payload) => {
  
  const success = (payload) =>{ 
   
    return({
    type: types.FETCH_HO_PRICING_SUCCESS,
    payload,
  })};

  const failure = (error) => ({
    type: types.FETCH_HO_PRICING_ERROR,
    payload: error,
  });
  return (dispatch) => {
    dispatch(fetchHOPricingRequest());

    return baseRequest("GET", `/ho-pricing/organization`, null, null, payload)
      .then((response) => { 
        dispatch(success(response.data));
      })

      .catch((error) => {
        console.log({error});
        if (error) dispatch(failure(error));
      });
  };
};

export const fetchHealthOrganization = (id) => {
  const success = (payload) => ({
    type: types.FETCH_HEALTH_ORGANIZATION_SUCCESS,
    payload,
  });

  const failure = (error) => ({
    type: types.FETCH_HEALTH_ORGANIZATION_ERROR,
    payload: error,
  });

  return (dispatch) => {
    dispatch(fetchHORequest());
    return baseRequest("GET", `/health-organizations/${id}`)
      .then((response) => {
        dispatch(success(response.data));
      })
      .catch((error) => {
        if (error) dispatch(failure(error?.response?.data));
      });
  };
};

export const fetchHOPreferences = () => {
  
  const success = (payload) => ({
    type: types.FETCH_HEALTH_ORGANIZATION_PREFERENCES_SUCCESS,
    payload,
  });

  const failure = (error) => ({
    type: types.FETCH_HEALTH_ORGANIZATION_PREFERENCES_ERROR,
    payload: error,
  });

  return (dispatch) => {
    dispatch(fetchHOPreferencesRequest());
    return baseRequest("GET", "/health-organizations/my")
      .then((response) => {
        dispatch(success(response.data));
      })
      .catch((error) => {
        if (error) dispatch(failure(error?.response?.data));
      });
  };
};

export const HOAdminUpdateOwnOrganization = (payload) => {
  const success = (payload) => ({
    type: types.HO_ADMIN_UPDATE_OWN_ORGANIZATION_SUCCESS,
    payload,
  });

  const failure = (error) => ({
    type: types.HO_ADMIN_UPDATE_OWN_ORGANIZATION_ERROR,
    payload: error,
  });

  return (dispatch) => {
    dispatch(HoAdminUpdateOwnOrganizationRequest());
    return baseRequest("PUT", "/health-organizations/my", payload)
      .then((response) => {
        dispatch(success(response.data));
      })
      .catch((error) => {
        if (error) dispatch(failure(error?.response?.data));
      });
  };
};

export const createHO = (values, Navigation) => {
  const navigate = Navigation;
  const success = (payload) => ({
    type: types.CREATE_HEALTH_ORGANIZATION_SUCCESS,
    payload,
  });

  const failure = (error) => ({
    type: types.CREATE_HEALTH_ORGANIZATION_ERROR,
    payload: error,
  });

  return (dispatch) => {
    dispatch(createHORequest());
    return baseRequest("POST", "/health-organizations", values)
      .then((response) => {
        dispatch(success(response.data));
        navigate.push(URLS.HEALTH_ORGANIZATIONS);
      })
      .catch((error) => {
        if (error) dispatch(failure(error?.response?.data));
      });
  };
};

export const updateHO = ({ values, id, Navigation }) => {
  const navigate = Navigation;
  const success = (payload) => ({
    type: types.UPDATE_HEALTH_ORGANIZATION_SUCCESS,
    payload,
  });

  const failure = (error) => ({
    type: types.UPDATE_HEALTH_ORGANIZATION_ERROR,
    payload: error,
  });

  return (dispatch) => {
    dispatch(updateHORequest());
    return baseRequest("PUT", `/health-organizations/${id}`, values)
      .then((response) => {
        dispatch(success(response.data));
        navigate.push(URLS.HEALTH_ORGANIZATIONS);
      })
      .catch((error) => {
        if (error) dispatch(failure(error?.response?.data));
      });
  };
};

export const disableHO = ({ id, disabled, Navigation }) => {
  const navigate = Navigation;
  const success = (payload) => ({
    type: types.DISABLE_HEALTH_ORGANIZATION_SUCCESS,
    payload,
  });

  const failure = (error) => ({
    type: types.DISABLE_HEALTH_ORGANIZATION_ERROR,
    payload: error,
  });

  return (dispatch) => {
    dispatch(disableHORequest());
    return baseRequest("PATCH", `/health-organizations/${id}/disable`, {
      disabled,
    })
      .then((response) => {
        dispatch(success({ id, disabled }));
        navigate.push(URLS.HEALTH_ORGANIZATIONS);
      })
      .catch((error) => {
        if (error) dispatch(failure(error?.response?.data));
      });
  };
};

export const switchHOAdmin = ({ organizationId, userId, closeModal }) => {
  const success = (payload) => ({
    type: types.MH_SWITCH_HO_ADMIN_SUCCESS,
    payload,
  });

  const failure = (error) => ({
    type: types.MH_SWITCH_HO_ADMIN_ERROR,
    payload: error,
  });

  return (dispatch) => {
    dispatch(switchHoAdminRequest());
    return baseRequest(
      "PATCH",
      `/health-organizations/${organizationId}/admin/${userId}`
    )
      .then((response) => {
        dispatch(success(response.data));
        closeModal();
      })
      .catch((error) => {
        if (error) dispatch(failure(error?.response?.data));
      });
  };
};

export const switchAdminForCurrentOrganization = ({
  userId,
  confirmCallback,
  Navigation,
}) => {
  const success = (payload) => ({
    type: types.MH_SWITCH_HO_ADMIN_SUCCESS,
    payload,
  });

  const failure = (error) => ({
    type: types.MH_SWITCH_HO_ADMIN_ERROR,
    payload: error,
  });

  return (dispatch) => {
    dispatch(switchHoAdminRequest());
    return baseRequest(
      "PATCH",
      `/health-organizations/my/replace-admin/${userId}`
    )
      .then((response) => {
        dispatch(success(response.data));
        if (confirmCallback) confirmCallback();
        dispatch(logOut(Navigation));
        showNotificationMessage({
          type: "error",
          content: renderRecordValue(ERROR_TYPES.ROLE_CHANGED),
        });
      })
      .catch((error) => {
        if (error) dispatch(failure(error?.response?.data));
      });
  };
};

export const inviteNewUserForHo = ({ organizationId, values, closeModal }) => {
  const success = (payload) => ({
    type: types.MH_INVITE_NEW_USER_FOR_HO_SUCCESS,
    payload,
  });

  const failure = (error) => ({
    type: types.MH_INVITE_NEW_USER_FOR_HO_ERROR,
    payload: error,
  });

  return (dispatch) => {
    dispatch(inviteNewUserforHoRequest());
    return baseRequest(
      "POST",
      `/health-organizations/${organizationId}/invite`,
      values
    )
      .then((response) => {
        dispatch(success(response.data));
        closeModal();
      })
      .catch((error) => {
        if (error) dispatch(failure(error?.response?.data));
      });
  };
};

const initialState = {
  HOPreferences: null,
  currentHealthOrganization: null,
  healthOrganizations: [],
  loading: false,
  error: false,
  success: false,
  limit: 10,
  currentPage: 1,
  totalPages: 1,
  total: 1,
  HOPricing:null,
  pricingType:null
};

export default function healthOrganizations(state = initialState, action) {
  switch (action.type) {
    case types.CREATE_HEALTH_ORGANIZATION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.CREATE_HEALTH_ORGANIZATION_SUCCESS:
      return {
        ...state,
        healthOrganizations: [...state.healthOrganizations, action.payload],
        loading: false,
      };

    case types.CREATE_HEALTH_ORGANIZATION_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.UPDATE_HEALTH_ORGANIZATION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.UPDATE_HEALTH_ORGANIZATION_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case types.UPDATE_HEALTH_ORGANIZATION_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.DISABLE_HEALTH_ORGANIZATION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.DISABLE_HEALTH_ORGANIZATION_SUCCESS:
      return {
        ...state,
        loading: false,
        healthOrganizations: state.healthOrganizations.filter((ho) => {
          if (ho.id === action.payload.id) {
            ho.disabled = action.payload.disabled;
          }
          return ho;
        }),
      };

    case types.DISABLE_HEALTH_ORGANIZATION_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.FETCH_HEALTH_ORGANIZATIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.FETCH_HEALTH_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        healthOrganizations: action.payload?.data,
        currentPage: action.payload?.meta?.page,
        totalPages: action.payload?.meta?.totalPages,
        total: action.payload?.meta?.total,
      };

    case types.FETCH_HEALTH_ORGANIZATIONS_ERROR:
      return {
        ...state,
        loading: false,
        healthOrganizations: [],
        currentPage: 1,
        totalPages: 1,
        total: 1,
      };

    case types.FETCH_HEALTH_ORGANIZATION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.FETCH_HEALTH_ORGANIZATION_SUCCESS:
      return {
        ...state,
        loading: false,
        currentHealthOrganization: action.payload,
      };

    case types.FETCH_HEALTH_ORGANIZATION_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.FETCH_HEALTH_ORGANIZATION_PREFERENCES_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.FETCH_HEALTH_ORGANIZATION_PREFERENCES_SUCCESS:
      return {
        ...state,
        HOPreferences: action.payload,
        HOPricing: action.payload?.hoPricing[0],
        pricingType:action.payload?.hoPricing[0]?.pricingType,
        loading: false,
      };

    case types.FETCH_HEALTH_ORGANIZATION_PREFERENCES_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.HO_ADMIN_UPDATE_OWN_ORGANIZATION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.HO_ADMIN_UPDATE_OWN_ORGANIZATION_SUCCESS:
      return {
        ...state, 
        loading: false,
      };

    case types.HO_ADMIN_UPDATE_OWN_ORGANIZATION_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.SET_CURRENT_PAGE_HO:
      return {
        ...state,
        currentPage: action.payload,
      };

    case types.MH_SWITCH_HO_ADMIN_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.MH_SWITCH_HO_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        currentHealthOrganization: action.payload,
      };

    case types.MH_SWITCH_HO_ADMIN_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.MH_INVITE_NEW_USER_FOR_HO_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.MH_INVITE_NEW_USER_FOR_HO_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case types.MH_INVITE_NEW_USER_FOR_HO_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.FETCH_ALL_HEALTH_ORGANIZATIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.FETCH_ALL_HEALTH_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        healthOrganizations: action.payload,
      };

    case types.FETCH_ALL_HEALTH_ORGANIZATIONS_ERROR:
      return {
        ...state,
        loading: false,
        healthOrganizations: [],
      };

    case types.CLEAR_HO_INFO:
      return {
        ...state,
        healthOrganizations: [],
        HOPreferences: null,
        HOPricing: null
      };

    case commonTypes.CLEAR_STORE_INFO:
      return {
        ...initialState,
        currentPage: 1,
      };

    case types.FETCH_HO_PRICING_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.FETCH_HO_PRICING_SUCCESS:
      return {
        ...state,
        loading: false,
        HOPricing: action.payload,
      };

    case types.FETCH_HO_PRICING_ERROR:
      return {
        ...state,
        loading: false,
       
      };

    case types.SET_HO_PRICING_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.SET_HO_PRICING_SUCCESS:
      return {
        ...state,
        loading: false,
        HOPricing: action.payload,
        pricingType : action?.payload?.pricingType,
      };

    case types.SET_HO_PRICING_ERROR:
      return {
        ...state,
        loading: false,
        
      };

    default:
      return state;
  }
}
