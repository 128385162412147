import { COMMUNICATION_TYPES, GENDER_TYPES, VEHICLE_OPTIONS, VEHICLE_TYPES } from '../../constants/constants';

export const radioOptions = [
  { title: 'Yes', value: true },
  { title: 'No', value: false },
];

export const endsAfterOptions = Array.from(Array(11).keys())
  .map((k) => ({ title: k, value: k }))
  .splice(2);

export const recurringRideModalOptions = [
  { title: 'This ride only', value: 'THIS_ONLY' },
  { title: 'This and following rides in series', value: 'THIS_AND_FOLLOWING' },
  { title: 'All rides in series', value: 'ALL_IN_SERIES' },
];

export const recurringRideModalOptionsForRescheduleRides = [
  { title: 'This ride only', value: 'THIS_ONLY' },
  { title: 'This and following rides in series', value: 'THIS_AND_FOLLOWING' },
 
];
export const repeatsOptions = [
  { title: 'Weekly', value: 'Weekly' },
  { title: 'Every weekday (Monday to Friday)', value: 'Every weekday (Monday to Friday)' },
  { title: 'Every weekend (Saturday to Sunday)', value: 'Every weekend (Saturday to Sunday)' },
];

export const documentsReasons = {
  'Does not have appropriate type of transportation': 'Does not have appropriate type of transportation',
  'No personal vehicle': 'No personal vehicle',
  'No funds for public transportation': 'No funds for public transportation',
  Other: 'Other',
};

export const vehicleInformationRadio = {
  hasMotorizedWheelchair: 'Has a motorized wheelchair',
  isWheelchairNeeded: 'Wheelchair need to be provided by the driver',
  hasStairs: 'Has stairs in or outside the home',
};

export const includedRideRequestField = {
  covid19Relation: [],
  costCenter: [
    { title: 'Mandatory', value: 'Mandatory', disabled: false },
    { title: 'Optional', value: 'Optional', disabled: false },
  ],
  additionalFundingSource: [
    { title: 'Mandatory', value: 'Mandatory', disabled: false },
    { title: 'Optional', value: 'Optional', disabled: false },
  ],
  documentReasonForRide: [
    { title: 'Mandatory', value: 'Mandatory', disabled: false },
    { title: 'Optional', value: 'Optional', disabled: false },
  ],
};

export const genderOptions = [
  { title: 'Male', value: GENDER_TYPES.MALE },
  { title: 'Female', value: GENDER_TYPES.FEMALE },
  { title: 'Other', value: GENDER_TYPES.OTHER },
  { title: 'Decline to say', value: GENDER_TYPES.DECLINE_TO_SAY },
];

export const communicationsOptions = [
  { title: 'Email', value: COMMUNICATION_TYPES.EMAIL },
  { title: 'Phone Call', value: COMMUNICATION_TYPES.PHONE_CALL },
  { title: 'Phone Text', value: COMMUNICATION_TYPES.TEXT_SMS },
];
export const MH_PREFERENCES_FIELDS = {
  rideExpirationTime: 'rideExpirationTime',
  minTimeFromRideRequestToPickup: 'minTimeFromRideRequestToPickup',
  notifications: 'notifications',
  supportContacts: 'supportContacts',
  notificationsSwitcher: 'notificationsSwitcher',
  internalReportSettings: 'internalReportSettings',
  userPreferences: 'userPreferences',
  carBoosterSeatBufferTime: 'carBoosterSeatBufferTime',
};

export const EXPIRATION_TIME_PREFERENCES_FIELDS = [
  {
    id: 0,
    title: 'On Demand',
    name: `${MH_PREFERENCES_FIELDS.rideExpirationTime}.onDemand`,
    parentFieldName: MH_PREFERENCES_FIELDS.rideExpirationTime,
  },
  {
    id: 1,
    title: 'Scheduled',
    name: `${MH_PREFERENCES_FIELDS.rideExpirationTime}.scheduled`,
    parentFieldName: MH_PREFERENCES_FIELDS.rideExpirationTime,
  },
  // {
  //   id: 2,
  //   title: 'Accepted (but Driver not assigned)',
  //   name: `${MH_PREFERENCES_FIELDS.rideExpirationTime}.acceptedWithoutDriver`,
  //   parentFieldName: MH_PREFERENCES_FIELDS.rideExpirationTime,
  // },
  {
    id: 3,
    title: 'Accepted with Driver (but not started in time)',
    name: `${MH_PREFERENCES_FIELDS.rideExpirationTime}.driverInactivity`,
    parentFieldName: MH_PREFERENCES_FIELDS.rideExpirationTime,
  },
  {
    id: 4,
    title:
      'Alert notification to MedHaul if ride not started in time (select how many hours/minutes after pick-up time)',
    name: `${MH_PREFERENCES_FIELDS.rideExpirationTime}.rideNotStartedAlert`,
    parentFieldName: MH_PREFERENCES_FIELDS.rideExpirationTime,
  },
];

export const WINDOW_OF_TIME_RANGE_FIELDS = [
  {
    id: 0,
    title: 'Window of time duration',
    name: 'maxPickUpWindowOfTime',
    parentFieldName: 'maxPickUpWindowOfTime',
  },
];

export const MINIMUM_TIME_BTW_ROUNDTRIP_SEGMENTS_OBJ = {
  
   title: "Minimum time between round-trip segments",
   value: "minimumRoundTrip",
   name: "minimumRoundTrip",
  };
  
export const BOOSTER_CAR_SEAT_TIME_SEGMENTS = {
  title: "Booster/Car Seat Time Buffer",
  value: "carBoosterSeatBufferTime",
  name: "carBoosterSeatBufferTime",
  };

export const VEHICLE_TYPE_TIME_PREFERENCES_FIELDS = VEHICLE_OPTIONS.map((option) => ({
  ...option,
  name: `${MH_PREFERENCES_FIELDS.minTimeFromRideRequestToPickup}.${option?.value}`,
  parentFieldName: MH_PREFERENCES_FIELDS.minTimeFromRideRequestToPickup,
}));

export const RIDER_REMINDERS_TIME_PREFERENCES_FIELDS = [
  {
    id: 0,
    title: 'First text reminder to Rider (select how many hours before pickup time)',
    name: `${MH_PREFERENCES_FIELDS.notifications}.firstReminderToRider`,
    parentFieldName: MH_PREFERENCES_FIELDS.notifications,
  },
  {
    id: 1,
    title: 'Second text reminder to Rider (select how many hours before pickup time)',
    name: `${MH_PREFERENCES_FIELDS.notifications}.secondReminderToRider`,
    parentFieldName: MH_PREFERENCES_FIELDS.notifications,
  },
];

export const INTERNAL_REPORT_NOTIFICATIONS_FIELDS = [
  {
    id: 0,
    title: 'Timezone',
    name: `${MH_PREFERENCES_FIELDS.internalReportSettings}.timezone`,
    parentFieldName: MH_PREFERENCES_FIELDS.internalReportSettings,
  },
  {
    id: 1,
    title: 'Day',
    name: `${MH_PREFERENCES_FIELDS.internalReportSettings}.reminderDay`,
    parentFieldName: MH_PREFERENCES_FIELDS.internalReportSettings,
  },
  {
    id: 2,
    title: 'Time',
    name: `${MH_PREFERENCES_FIELDS.internalReportSettings}.reminderHour`,
    parentFieldName: MH_PREFERENCES_FIELDS.internalReportSettings,
  },

];

export const USER_PREFERENCES_FIELDS = [
  {
    id: 0,
    title: 'Number of login retries with incorrect password until account is locked',
    name: `${MH_PREFERENCES_FIELDS.userPreferences}.passwordRetries`,
    parentFieldName: MH_PREFERENCES_FIELDS.userPreferences,
  },
  {
    id: 1,
    title: 'Amount of time until account automatically unlocks',
    name: `${MH_PREFERENCES_FIELDS.userPreferences}.loginBlockedUntil`,
    parentFieldName: MH_PREFERENCES_FIELDS.userPreferences,
  },
];

export const getWheelchairQuestions = ({ vehicleType, needsCarSeat }) =>
  [
    { id: 0, name: 'isWheelchairNeeded', title: 'Does this rider need a wheelchair to be provided by the driver?' },
    { id: 1, name: 'hasMotorizedWheelchair', title: 'Is the rider in a motorized wheelchair?' },
    { id: 2, name: 'hasStairs', title: 'Will there be stairs in or outside of the home?' },
    { id: 3, name: 'needsCarSeat', title: 'Is the rider traveling with someone who uses a car seat or a booster seat?' },
    { id: 4, name: 'needsAdditionalCarSeat', title: 'Does the rider require the driver to provide a car seat?' },
    { id: 5, name: 'needsBoosterSeat', title: 'Does the rider require the driver to provide a booster seat?' },
    {
      id: 6,
      name: 'dischargeFromFacility',
      title: 'Is rider being picked up as a result of a discharge from a facility?',
    },
  ]
    .filter((question) => {
      if (!needsCarSeat) {
        return (question.name !== 'needsAdditionalCarSeat' && question.name !== 'needsBoosterSeat') ;
      } else return question;
    })
    .filter((question) => {
      if (
        vehicleType === VEHICLE_TYPES.WHEEL_CHAIR_BOUND_BARIATRIC ||
        vehicleType === VEHICLE_TYPES.WHEEL_CHAIR_BOUND_NON_BARIATRIC
      ) {
        return question;
      } else {
        return question.name !== 'isWheelchairNeeded' && question.name !== 'hasMotorizedWheelchair';
      }
    });
