import React from 'react';
import classnames from 'classnames';
import { FlightInformation, RountTrip } from '../../../components';
import { vehicleOptionsDictionary } from '../../../dictionaries';
import { LodashHelper, renderRecordValue } from '../../../utils';
import { STATUSES_STYLING } from '../../../constants/styles/statuses';
import { DRIVER_NOT_ASSIGNED } from '../../../constants/constants';
import { RIDE_STATUSES } from '../../../constants/constants';
import { getRiderActualHeightForBariaticVehicleType, getRiderActualWeightForBariaticVehicleType } from '../../../utils/helpers';
import styles from './index.module.css';
import { Popover } from 'antd';
import { OverNightIcon } from '../../../assets/svgs/OverNightIcon';
import { OverNight } from '../../../components/common/OverNight/OverNight';
export const renderColumns = () => [
  {
    title: "Ride ID",
    dataIndex: "seqId",
    key: "seqId",
    render: (text, record) => (
      <div
        className={classnames({
          [styles.draftIdCellValue]: true,
          [styles.cellValue]: true,
        })}
      >
        {record?.flightInfo ? (
          <Popover
            content={<FlightInformation flightInfo={record?.flightInfo} />}
            placement="topLeft"
            getPopupContainer={() => document.querySelector(".ant-table")}
          >
            <span style={{ color: "blue", textDecorationLine: "underline" }}>
              {renderRecordValue(text)}
            </span>
          </Popover>
        ) : (
          renderRecordValue(text)
        )}
        <br />
        <div className={styles.dflex}>
          {/* { record?.isOvernightRide && <OverNightIcon width="20px" height="20px"></OverNightIcon>} */}
          <OverNight record={record}/>
        <RountTrip record={record} />
        </div>
      </div>

      //  <div
      //    className={classnames({
      //     [styles.draftIdCellValue]: true,
      //      [styles.cellValue]: true,
      //   })}>
      //    {renderRecordValue(text)}
      //    <br />
      //    <RountTrip record={record} />
      //  </div> -->
    ),
    width: 150,
  },
  {
    title: "Rider Name",
    dataIndex: "rider",
    key: "rider",
    width: 180,
    render: (text) => (
      <div
        className={classnames({
          [styles.riderCellValue]: true,
          [styles.cellValue]: true,
        })}
      >
        {renderRecordValue(text)}
      </div>
    ),
  },
  {
    title: "Vehicle Type",
    dataIndex: "vehicleType",
    key: "vehicleType",
    width: 280,
    render: (text, record) => (
      <>
        <div
          className={classnames({
            [styles.vehicleCellValue]: true,
            [styles.cellValue]: true,
          })}
        >
          {renderRecordValue(vehicleOptionsDictionary[text])}
        </div>
        <div
          className={classnames({
            [styles.vehicleCellValue]: true,
            [styles.cellValue]: true,
            [styles.actualWeight]: true,
          })}
        >
         <div> {getRiderActualWeightForBariaticVehicleType(text, record)}</div>
         <div> {getRiderActualHeightForBariaticVehicleType(text, record)}</div>
        </div>
      </>
    ),
  },
  {
    title: "Ride Details",
    dataIndex: "rideOptions",
    key: "rideOptions",
    width: 200,
    render: (values) => (
      <div
        className={classnames({
          [styles.rideOptionsCellValue]: true,
          [styles.cellValue]: true,
        })}
      >
        <span className={classnames({ [styles.cellValue]: true })}>
          {renderRecordValue(values && LodashHelper.pluck(values, "value"))}
        </span>
      </div>
    ),
  },
  {
    title: "Pickup Date",
    dataIndex: "pickUpDate",
    key: "pickUpDate",
    width: 250,
    render: (value) => (
      <div
        className={classnames({
          [styles.pickUpDateCellValue]: true,
          [styles.cellValue]: true,
        })}
      >
        {renderRecordValue(value)}
      </div>
    ),
  },

  {
    title: "Driver Name",
    dataIndex: "driver",
    key: "driver",
    width: 150,
    render: (text, record) => (
      <div
        className={classnames({
          [styles.driverCellValue]: true,
          [styles.cellValue]: true,
          [styles.driverNotAssigned]:
            RIDE_STATUSES.ACCEPTED === record.status && !text,
        })}
      >
        {RIDE_STATUSES.ACCEPTED === record.status && !text
          ? renderRecordValue(DRIVER_NOT_ASSIGNED)
          : renderRecordValue(text)}
      </div>
    ),
  },
  {
    title: "Ride Status",
    dataIndex: "readableStatus",
    key: "readableStatus",
    width: 160,
    render: (text) => (
      <div
        className={classnames({
          [styles.statusCellValue]: true,
          [STATUSES_STYLING[text]]: true,
        })}
      >
        {renderRecordValue(text)}
      </div>
    ),
  },
];
