import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {  Tooltip } from 'antd';
// import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { PopConfirm } from '../../../hocs/PopConfirm/PopConfirm';

import styles from "./index.module.css";
import { Field, FieldArray } from 'formik';

 

import { closestCenter, DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import {
  arrayMove,
  horizontalListSortingStrategy,
  SortableContext,
  useSortable,
} from '@dnd-kit/sortable';
import { Tag } from 'antd';
const commonStyle = {
  cursor: 'move',
  transition: 'unset', // Prevent element from shaking after drag
};
const DraggableTag = (props) => {
  const { tag,handleRemoveAddress,currentAddress,updatedAddress,handelOnClick,handleChangeAddress } = props;
  const { listeners, transform, transition, isDragging, setNodeRef } = useSortable({
    id: tag.id,
  });

  const isSelectedAddress =  ([currentAddress, updatedAddress].includes(tag?.location?.formattedAddress));
  const closeIconStyles = isSelectedAddress ? {color: '#fff'}: {};
 
  const commonStyle = { 
    borderColor: isSelectedAddress ? '#de7f39' : '#d9d9d9',
    backgroundColor: isSelectedAddress ? '#de7f39': '#d9d9d9'
  };

  const style = transform
    ? {
        ...commonStyle,
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
        transition: isDragging ? 'unset' : transition, // Improve performance/visual effect when dragging
      }
    : commonStyle;
  return (
    <Tooltip title={tag?.location?.formattedAddress}>
    <Tag style={style} ref={setNodeRef} {...listeners}
    onClick={()=>{ handelOnClick(tag)}} 
  
    // checked={isSelectedAddress}
    // onChange={()=>handleChangeAddress(item?.location?.formattedAddress)}
    //  onChange={handleChangeAddress}
    >
      {tag.title} 
      <PopConfirm
                title="Confirmation"
                description="Are you sure you want to delete this address?"
                onConfirm={()=>handleRemoveAddress(tag?.id)}
                // onCancel={()=>{}} 
            >
            {/* Hide delete/Cross feature; maybe used for future */}
            <span className={classNames({ ["ant-tag-close-icon"]: true, [styles.closeIcon]: true })} style={closeIconStyles} >
                &times;
            </span>
            </PopConfirm>
    </Tag>
    </Tooltip>
  );
};


// const DraggableTag = React.memo(({
//     item, currentAddress, updatedAddress, selectedId, setSeletedId ,
//     handleChangeAddress, handleSelectAddress, handleRemoveAddress,handelOnClick,
//     field,
//     value
// }) => {
// //   console.log("DraggableTag called~ props-->",{ item, handleRemoveAddress });

// //   const isSelectedAddress =  (selectedId == item?.id);
//   const isSelectedAddress =  ([currentAddress, updatedAddress].includes(item?.location?.formattedAddress));
//   const closeIconStyles = isSelectedAddress ? {color: '#fff'}: {};

//   const commonStyle = { 
//     borderColor: isSelectedAddress ? '#de7f39' : '#d9d9d9',
//     backgroundColor: isSelectedAddress ? '#de7f39': '#d9d9d9'
//   };

//   return ( 
//       <Tooltip title={item?.location?.formattedAddress}>
//         <Tag.CheckableTag 
//             style={commonStyle}
//             className={styles.item}
//             onClose={()=>handleRemoveAddress(item?.id)}
//             onClick={()=>{
              
            
//               handelOnClick(item)}}
//             checked={isSelectedAddress}
//             // onChange={()=>handleChangeAddress(item?.location?.formattedAddress)}
//              onChange={handleChangeAddress}
        
//         >
//             {item.title}
//             <PopConfirm
//                 title="Confirmation"
//                 description="Are you sure you want to delete this address?"
//                 onConfirm={()=>handleRemoveAddress(item?.id)}
//                 // onCancel={()=>{}} 
//             >
//             {/* Hide delete/Cross feature; maybe used for future */}
//             <span className={classNames({ ["ant-tag-close-icon"]: true, [styles.closeIcon]: true })} style={closeIconStyles} >
//                 &times;
//             </span>
//             </PopConfirm>
//         </Tag.CheckableTag>
//       </Tooltip>
//   );
// });

export const FavoriteGoogleAddressList = React.memo(({
     name,
     value,
     form,
     setItems,
    tagListId='tagListId', items, updatedAddress, currentAddress,
    handleChangeAddress, handleSelectAddress, handleRemoveAddress
}) => {
   
    const [selectedId, setSeletedId] = useState(null);


    const sensors = useSensors(useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }));
    const handleDragEnd = (event) => {
      const { active, over } = event;
      if (!over) {
        return;
      }

      console.log(active);
      if (active.id !== over.id) {
        setItems((data) => {
          const oldIndex = data.findIndex((item) => item.id === active.id);
          const newIndex = data.findIndex((item) => item.id === over.id);
          return arrayMove(data, oldIndex, newIndex);
        });
      }}

 
  const handelOnClick = (item) => {
     
       handleChangeAddress({ value: item.location.formatted_address, values: form.values, setValues: form.setValues });

        handleSelectAddress({ value: item.location, values: form.values, setValues: form.setValues });
       
      
       setSeletedId(item?.id)};
 

  return (
    <div key={`cntr${tagListId}`} className={styles.container}>
     
			 

<DndContext sensors={sensors} onDragEnd={handleDragEnd} collisionDetection={closestCenter}>
      <SortableContext items={items} strategy={horizontalListSortingStrategy}>
        <div  className={styles.flex}>
          {items.map((item) => (
            <DraggableTag tag={item} key={item.id} updatedAddress={updatedAddress} currentAddress={currentAddress} 
            handelOnClick={handelOnClick}
            //handleChangeAddress handleSelectAddress 
            handleRemoveAddress={handleRemoveAddress} />
          ))}
        </div>
      </SortableContext>
    </DndContext>
       
    </div>
  );
});

FavoriteGoogleAddressList.prototype = {
    tagListId: PropTypes.string,
    items: PropTypes.array,
    updatedAddress: PropTypes.oneOfType([ PropTypes.object, PropTypes.string, PropTypes.any]),
    currentAddress: PropTypes.string,
    handleChangeAddress: PropTypes.func,
    handleSelectAddress : PropTypes.func,
    handleRemoveAddress: PropTypes.func,
}