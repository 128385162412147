import React from 'react';
import { FieldArray } from 'formik';

import { Checkbox, Input } from '../../../';
import { documentsReasons } from '../../mock';
import classnames from 'classnames';

import styles from './index.module.css';

const renderCheckboxGroup = (values, disabled) => (arrayHelpers) => {
  const handleCheckbox =
    (option) =>
    ({ checked, setValues }) => {
      if (checked) arrayHelpers.push(option);
      else {
        setValues({
          ...values,
          documentReasonForRide: values.documentReasonForRide.filter((value) => value !== option),
        });
        if (option === 'Other') {
          setValues({
            ...values,
            documentReasonForRide: values.documentReasonForRide.filter((value) => value !== option),
            otherReason: '',
          });
        }
      }
    };

  return Object.keys(documentsReasons).map((key) => (
    <label className={styles.checkbox} key={key}>
      <Checkbox
        name='documentReasonForRide'
        checked={values && values.documentReasonForRide && values.documentReasonForRide.includes(key)}
        value={key}
        onChange={handleCheckbox(key)}
        disabled={disabled}
        whenFieldDisabledShowTooltip={disabled}
      />
      <span className={styles.checkboxLabel}>{documentsReasons[key]}</span>
    </label>
  ));
};

export const StepFourFormFields = ({
  costCenterField,
  showAdditionalSourceField,
  showDocumentReasonForRideField,
  values,
  disabledValues,
}) => {
  return (
    <>
      {costCenterField?.show && (
        <label className={styles.costCenter}>
          <span
            className={classnames({
              [styles.required]: costCenterField?.isRequired,
            })}
          >
            Cost Center
          </span>
          <Input
            name="costCenter"
            type="text"
            placeholder="Cost Center"
            disabled={disabledValues?.costCenter}
            whenFieldDisabledShowTooltip={Boolean(disabledValues?.costCenter)}
          />
        </label>
      )}
      {showAdditionalSourceField?.show && (
        <label className={styles.additionalSource}>
          <span
            className={classnames({
              [styles.required]: showAdditionalSourceField?.isRequired,
            })}
          >
            Additional Funding Source
          </span>
          <Input
            name="additionalFundingSource"
            type="text"
            placeholder="i.e Program, Payer, Grant Funding"
            disabled={disabledValues?.additionalFundingSource}
            whenFieldDisabledShowTooltip={Boolean(
              disabledValues?.additionalFundingSource
            )}
          />
        </label>
      )}

      {values && showDocumentReasonForRideField?.show && (
        <>
          <span
            className={classnames({
              [styles.additionalOptionsHead]: true,
              [styles.required]: showDocumentReasonForRideField?.isRequired,
            })}
          >
            Document reason for the ride
          </span>
          <div className={styles.checkboxesColumn}>
            <FieldArray
              name="documentReasonForRide"
              render={renderCheckboxGroup(
                values,
                disabledValues?.documentReasonForRide
              )}
            />
          </div>
        </>
      )}

      {values.documentReasonForRide &&
        values.documentReasonForRide.includes("Other") && (
          <label className={styles.additionalFundingSource}>
            <div className={styles.otherFieldsContainer}>
               <div className={styles.otherFieldColumn1}>
                <Input
                  name="otherReason"
                  type="text"
                  placeholder="Please, specify here"
                  disabled={disabledValues?.otherReason}
                  whenFieldDisabledShowTooltip={Boolean(
                    disabledValues?.otherReason
                  )}
                />
               </div>
                <div className={styles.otherFieldColumn2}>
                 <div className={styles.requiredContainer}>
                  <span className={styles.required}></span> 
                </div>
               
              </div>
               
            </div>
            
          </label>
        )}
    </>
  );
};
