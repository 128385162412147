import { NotificationsSwitchers } from './partials/NofificationSwitchers';
import { SupportContactsBlock } from './partials/SupportContactsBlock';
import { renderHoursAndMinutesTimeFields } from './partials/RideExpirations';
import { RIDER_REMINDERS_TIME_PREFERENCES_FIELDS } from '../../mock';
import { InternalReportNotificationForm } from './partials/InternalReportNotificationForm/InternalReportNotificationForm';
import { UserPreferenceForm } from './partials/UserPreferenceForm/UserPreferenceForm';

import styles from './index.module.css';

export const NotificationSettings = ({
  values,
  initialValues,
  handleSaveChanges,
  handleSaveChangesNotificationSwitchers,
  errors,
}) => {
  return (
    <>
      <div>
        <span className={styles.minimumTimeToRequest}>Notification Preferences</span>
        <SupportContactsBlock
          initialValues={initialValues}
          values={values}
          errors={errors}
          handleSaveChanges={handleSaveChanges}
        />
        {renderHoursAndMinutesTimeFields(
          RIDER_REMINDERS_TIME_PREFERENCES_FIELDS,
          errors,
          values,
          initialValues,
          handleSaveChanges,
        )}
      </div>
      <NotificationsSwitchers
        initialValues={initialValues}
        values={values}
        errors={errors}
        handleSaveChangesNotificationSwitchers={handleSaveChangesNotificationSwitchers}
      />
      <InternalReportNotificationForm 
        initialValues={initialValues}
        values={values} 
        errors={errors}
        handleSaveChanges={handleSaveChanges}
        disabled={false} 
      />
      <UserPreferenceForm
        initialValues={initialValues}
        values={values} 
        errors={errors}
        handleSaveChanges={handleSaveChanges}
        disabled={false} 
      />
    </>
  );
};
