import React, { useCallback } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { CreateHO, Button, CancelCreateModal } from '../../../components';
import { GoogleAddressHelper, LodashHelper } from '../../../utils/';
import { createHO } from '../../../store/health_organizations';
import { BackIcon } from '../../../assets';
import { usePreventLeave } from '../../../hooks/usePreventLeave';
import { createHealthOrganizationSchema } from '../../../utils/validations';
 import styles from './index.module.css';
import { Navigation } from '../../../utils/navigation';

const initialValues = {
  name: '',
  location: {
    formattedAddress: '',
  },
  admin: {
    email: '',
    firstName: '',
    lastName: '',
  },
  rideRequestFields: {},
};

const Create_Health_Organization = ({ defaultValues, createHO }) => {
  const navigate =Navigation();
  const formik = useFormik({
    validationSchema: createHealthOrganizationSchema,
    validateOnMount: true,
    initialValues: defaultValues || initialValues,
    validateOnChange: false,
    onSubmit: (values) => {
      unblockHistory.current && unblockHistory.current();
      createHO(LodashHelper.trimValues(values),navigate);
    },
  }); 
  const [visibleModal, setModalVisible, unblockHistory, location] = usePreventLeave(initialValues, formik.values);

  const handleBack = useCallback(() => navigate.back(), [navigate]);

  const onCancel = useCallback(() => setModalVisible(false), [setModalVisible]);

  const onLeave = () => {
    if (unblockHistory.current) {
      unblockHistory.current();
      navigate.push(location.location);
    }
  };

  const handleSelectAddress = async ({ value, values, setValues }) => {
    setValues({ ...values, location: GoogleAddressHelper.transformFields(value) });
  };

  const handleChangeAddress = ({ value, values, setValues }) => {
    setValues({
      ...values,
      location: {
        formattedAddress: value,
      },
    });
  };

  return (
    <div className={styles.pageContent}>
      <div className={styles.header}>
        <div className={styles.backBtn} onClick={handleBack}>
          <BackIcon width='18px' height='18px' fill='#BDBDBD' />
        </div>
        <h1 className={styles.pageTitle}>Add New Organization</h1>
      </div>
      <div>
        <h2>Provide information for the new Organization</h2>

        <FormikProvider value={formik}>
          <form className={styles.form} onSubmit={formik.handleSubmit}>
            <CreateHO
              values={formik.values}
              handleSelectAddress={handleSelectAddress}
              handleChangeAddress={handleChangeAddress}

              {...formik}
            />
            <div className={styles.btnPanel}>
              <div className={styles.btnControl}>
                <Button myClassName={styles.navigationBtn} onClick={handleBack}>
                  Cancel
                </Button>
              </div>
              <div className={styles.btnControl}>
                <Button disabled={!formik.isValid || !formik.dirty} type='submit' myClassName={styles.styledBtn}>
                  Create Organization and Send Invite to Admin
                </Button>
              </div>
            </div>
          </form>
        </FormikProvider>
      </div>
      <CancelCreateModal
        title='Canceling New Organization'
        visible={visibleModal}
        onCancel={onCancel}
        onLeave={onLeave}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ createHO }, dispatch);

export default connect(null, mapDispatchToProps)(Create_Health_Organization);
