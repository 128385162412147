import React, { useCallback, useEffect, useState } from "react";
import classnames from "classnames";
import { FormikProvider, useFormik } from "formik";
import { Button, Modal, Select } from "../..";
import { assignTcByMHSchema } from "../../../utils/validations";

import styles from "./index.module.css";
import { useSameDayRoundTripConfirmationModal } from "../SameDayRoundTripConfirmationModal/useSameDayRoundTripConfirmationModal";
import { CONFIRM_ROUNDTRIP_RIDE_CONTENT, CONFIRM_ROUNDTRIP_RIDE_TITLE } from "../../../constants/constants";

const initialValues = { transportCompanyId: "", tcDriverId: "" };

export const useAssignTCByMHModal = ({
  id,
  ride,
  role,
  tcOptions,
  driversOptions,
  rideCurrentValues,
  onConfirm,
  changeTC: changeTCRes,
}) => {
  const [visibleModal, setModalVisible] = useState(false);
  
  
  const { Modal: ModalRoundTripConfirmation, setCancelModalVisible } = useSameDayRoundTripConfirmationModal({
    id: ride?.seqId,
    role,
    title: CONFIRM_ROUNDTRIP_RIDE_TITLE,
    description: CONFIRM_ROUNDTRIP_RIDE_CONTENT,
    ride:ride,
    onConfirm: () => confirmRoundTrip(),
  });

  const handleConfirm = useCallback(
    (values) => {
      if (ride?.connectedLeg) {
        setCancelModalVisible(true);
      } else {
      onConfirm({ ...values });
      }
    },
    [onConfirm]
  );


  const confirmRoundTrip= useCallback(
    () => {
     
      onConfirm({ ...values });
      return setCancelModalVisible(false);
    },
    [onConfirm]
  );
  

  const formik = useFormik({
    initialValues: rideCurrentValues,
    validationSchema: assignTcByMHSchema,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: handleConfirm,
  });
  const { resetForm, validateForm, isValid, values, handleSubmit } = formik;
  useEffect(() => {
    if(visibleModal)
    {
      if(values?.transportCompanyId)
      {
      changeTCRes({ transportCompanyId: values?.transportCompanyId, rideId: ride?.id });
      }
    }else
    {
      resetForm();
    }
    return;
  }, [visibleModal]);



  const onCancel = useCallback(async () => {
    await resetForm(initialValues);
    await validateForm();
    await setModalVisible(false);
  }, [setModalVisible, validateForm, resetForm]);

  const handleChangeTC = async ({ name, value, setFieldValue }) => {
    await setFieldValue(name, value);
    await setFieldValue("tcDriverId", "");
    await changeTCRes({ transportCompanyId: value, rideId: ride?.id });
  };

  const handleChangeDriver = useCallback(
    async ({ name, value, setFieldValue }) => {
      await setFieldValue(name, value);
    },
    []
  );

  return {
    Modal: (
      <Modal
        visible={visibleModal}
        onCancel={onCancel}
        closable
        width={670}
        bodyStyle={{ height: "20vh" }}
        title={`Assign Driver to Ride HO – ID ${id} `}
      >
        <div className={styles.modalContent}>
          <FormikProvider value={formik}>
            <>
              <form>
                {tcOptions?.length > 0 && (
                  <label className={styles.cancelRideLabel}>
                    <span className={styles.required}>Select TC</span>
                    <Select
                      name="transportCompanyId"
                      options={tcOptions}
                      onChange={handleChangeTC}
                      placeholder="Pick Transport Company"
                    />
                  </label>
                )}
{/* {formik?.values?.transportCompanyId != undefined && !driversOptions?.length >0 &&
<span className={styles.errorMessage}>No Drivers Available</span>
} */}
                {  (
                  <label className={styles.cancelRideLabel}>
                    <span className={styles.required}>Select Driver</span>
                    <Select
                      name="tcDriverId"
                      placement={"bottomLeft"}
                      options={driversOptions}
                      onChange={handleChangeDriver}
                      disabled={!driversOptions?.length > 0 }
                      placeholder={driversOptions?.length > 0 ? "Pick Driver": "No Drivers Available"}
                    />
                  </label>
                )}
              </form>
              <div
                className={classnames({
                  [styles.modalBtns]: true,
                  [styles.cancelBtns]: true,
                })}
              >
                <div className={styles.mr6}>
                  <Button myClassName={styles.navigationBtn} onClick={onCancel}>
                    Cancel
                  </Button>
                </div>
                <div className={styles.ml6}>
                  <Button
                    myClassName={styles.navigationBtn}
                    onClick={handleSubmit}
                    disabled={!isValid}
                  >
                    Confirm
                  </Button>
                </div>
              </div>
            </>
          </FormikProvider>
        </div>
        {ModalRoundTripConfirmation}
      </Modal>
    ),
    setModalAssignTCVisible: setModalVisible,
  };
};
