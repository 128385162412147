import React, { useCallback, useEffect, useState } from 'react';
import { getIn } from 'formik';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';
import generatePicker from 'antd/lib/date-picker/generatePicker';
import 'antd/lib/date-picker/style/index';

import { DateHelper } from '../../../utils';
import { withTooltip } from '../../../hocs/withTooltip';
import { CustomField } from '../CustomField/CustomField';

import styles from './index.module.css';

export const AntdDatePicker = generatePicker(dateFnsGenerateConfig);

export const CustomDatePicker = ({
  onChange,
  value,
  field,
  form,
  format,
  picker,
  placeholder,
  showTime,
  showNow,
  use12Hours,
  disabled,
  disabledDate,
  disabledTime,
  disabledHours,
  onSelect,
  defaultOpen,
  hideInputField,
  inputReadOnly,
  panelRender,
  myClassName,
  whenFieldDisabledShowTooltip,
  hideDisabledOptions,
  allowClear,
  getPopupContainer,
  defaultPickerValue,
  placement,
}) => {
  const handleChange = (...date) => {
    if (onChange) {
      onChange({
        name: field.name,
        value: date[0],
        setFieldValue: form.setFieldValue,
        values: form.values,
        setValues: form.setValues,
        validateForm: form.validateForm,
        dateString: date[1],
      });
    } else field.onChange(date);
  };

  return withTooltip(
    <AntdDatePicker
      defaultPickerValue={defaultPickerValue}
      open={defaultOpen}
      name={field.name}
      onChange={handleChange}
      value={value ? value : ''}
      format={format}
      picker={picker}
      showTime={showTime}
      showNow={showNow}
      disabledHours={disabledHours}
      use12Hours={use12Hours}
      disabled={disabled}
      onSelect={handleChange}
      inputReadOnly={inputReadOnly}
      panelRender={panelRender}
      onBlur={() => form.setTouched({ ...form.touched, [field.name]: true })}
      className={classnames({
        [styles.datePicker]: true,
        [styles.hideInputPanel]: hideInputField,
        [styles.error]: !disabled && !getIn(form.values, field.name) && getIn(form.errors, field.name),
        [styles.error]:
          !disabled && (getIn(form.touched, field.name) || form.touched[field.name]) && getIn(form.errors, field.name),
        [myClassName]: myClassName,
      })}
      allowClear={allowClear}
      popupClassName={classnames({
        [styles.popUp]: true,
        [styles.hideInputPanel]: hideInputField,
      })}
      placeholder={placeholder}
      disabledDate={disabledDate}
      disabledTime={disabledTime}
      data-testid='datepicker'
      hideDisabledOptions={hideDisabledOptions}
      getPopupContainer={getPopupContainer}
      placement={placement}
    />,
    whenFieldDisabledShowTooltip,
  );
};

export const DatePicker = ({
  name,
  value,
  onChange,
  disabled,
  format,
  picker,
  showTime,
  showNow,
  use12Hours,
  disabledDate,
  placeholder,
  disabledTime,
  disabledHours,
  onSelect,
  defaultOpen,
  hideInputField,
  inputMaxLength,
  inputReadOnly,
  whenFieldDisabledShowTooltip,
  fastField,
  myClassName,
  panelRender,
  hideDisabledOptions,
  allowClear,
  getPopupContainer,
  placement,
  defaultPickerValue,
}) => {
  const [invalidDate, setInvalidDate] = useState('');

  const onKeyUp = useCallback((e) => {
    const targetLength = e.target.value.length;
    if (targetLength === 10 && DateHelper.isFuture(new Date(e.target.value))) {
      setInvalidDate('Invalid date');
      return e.preventDefault();
    } else if (targetLength === 10) {
      const date = e.target.value.split('/');
      const currentMonth = Number(date[0] - 1);
      return !DateHelper.isValidDate(Number(date[2]), currentMonth, Number(date[1]))
        ? setInvalidDate('Invalid date')
        : setInvalidDate('');
    } else return setInvalidDate('');
  }, []);

  const onKeyPress = useCallback((e) => {
    const targetLength = e.target.value.length;
    if (e.keyCode !== 8 && (e.keyCode <= 47 || e.keyCode > 57)) {
      return e.preventDefault();
    } else if (e.keyCode !== 8 && targetLength === 2) {
      return (e.target.value += '/');
    } else if (e.keyCode !== 8 && targetLength === 5) {
      return (e.target.value += '/');
    }
  }, []);

  const onKeyDown = useCallback((e) => {
    if (
      (e.keyCode === 8 || e.keyCode === 46) &&
      (e.target.selectionStart === 3 || e.target.selectionStart === 6) &&
      e.target.value.length > e.target.selectionStart
    ) {
      e.preventDefault();
      e.target.selectionStart = e.target.value.length;
    }
  }, []);

  useEffect(() => {
    const dateInput = document.querySelector(`input[name="${name}"]`);
    if (dateInput && inputMaxLength) {
      dateInput.setAttribute('maxlength', inputMaxLength);
      dateInput.addEventListener('keypress', onKeyPress);
      dateInput.addEventListener('keyup', onKeyUp);
      dateInput.addEventListener('keydown', onKeyDown);
    }
    return () => {
      dateInput.removeEventListener('keypress', onKeyPress);
      dateInput.removeEventListener('keyup', onKeyUp);
      dateInput.removeEventListener('keydown', onKeyDown);
    };
  }, [name, inputMaxLength, onKeyUp, onKeyPress, onKeyDown]);

  const disableAllDatesInDatepicker = useCallback(() => true, []);

  return (
    <CustomField fastField={fastField} name={name} disabled={disabled} onChange={onChange} value={value}>
      {({ form, field }) => {
        return (
          <>
            <CustomDatePicker
              form={form}
              field={field}
              name={name}
              format={format}
              picker={picker}
              use12Hours={use12Hours}
              disabled={disabled}
              onChange={onChange}
              onSelect={onSelect}
              value={value}
              showTime={showTime}
              showNow={showNow}
              disabledDate={invalidDate ? disableAllDatesInDatepicker : disabledDate}
              disabledTime={disabledTime}
              disabledHours={disabledHours}
              defaultOpen={defaultOpen}
              hideInputField={hideInputField}
              placeholder={placeholder}
              inputReadOnly={inputReadOnly}
              panelRender={panelRender}
              myClassName={myClassName}
              whenFieldDisabledShowTooltip={whenFieldDisabledShowTooltip}
              hideDisabledOptions={hideDisabledOptions}
              allowClear={allowClear}
              getPopupContainer={getPopupContainer}
              placement={placement}
              defaultPickerValue={defaultPickerValue}
            />
            {invalidDate ? (
              <div className={styles.errorMessage} role='alert'>
                {invalidDate}
              </div>
            ) : (
              !disabled &&
              (getIn(form.touched, field.name) || form.touched[field.name]) &&
              getIn(form.errors, field.name) && (
                <div className={styles.errorMessage} role='alert'>
                  {getIn(form.errors, field.name)}
                </div>
              )
            )}
          </>
        );
      }}
    </CustomField>
  );
};

DatePicker.propTypes = {
  format: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  showTime: PropTypes.PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  showNow: PropTypes.bool,
  open: PropTypes.bool,
  use12Hours: PropTypes.bool,
  disabledDate: PropTypes.func,
  disabledTime: PropTypes.func,
  inputReadOnly: PropTypes.bool,
  inputMaxLength: PropTypes.number,
  placeholder: PropTypes.string,
  whenFieldDisabledShowTooltip: PropTypes.bool,
};
