import { useMemo } from 'react';
import classNames from 'classnames';

import { DatePicker, renderReccuringTypes, Select } from '../../..';
import {
  DEFAULT_TIME_FORMAT, 
  RECURRING_REPEATS_OPTIONS,
  TIMEZONES,
  URLS,
} from '../../../../constants/constants';
import { endsAfterOptions } from '../../mock';
import { useRecurringInfo } from './useRecurringInfo';
import { DateHelper } from '../../../../utils';

import styles from './index.module.css';
import { datePickerFieldsNames } from '../../../../utils/helpers';
import { useLocation } from 'react-router-dom';

export const RecurringSection = ({
  values,
  validateForm,
  pickUpDate,
  startPointOfRecurring,
  disabledReccuringValues,
  setStartPointOfRecurring,
  setReturnStartPoint,
  handleChangeTZ,
  returnStartPoint,
  handleStartPointTime,
  handleReturnStartPoint,
  disabledDatesForStartPointOfRecurring,
  disabledDatesForReturnTimeForRoundTripRecurring,
  disabledDateTime,
  minDelayToStartRide,
  minimumRoundTrip,
}) => {
  const location = useLocation(); 
  const { monthPickerOptions, handleChangeEndsAfter, handleRecurringFrequency, recurringType } = useRecurringInfo({
    values,
    validateForm,
    pickUpDate,
    startPointOfRecurring,
    disabledReccuringValues,
    setStartPointOfRecurring,
    setReturnStartPoint,
  });
  const recurringDateFieldsAreDisabled = useMemo(() => {
    return location.pathname === URLS.CREATE_RIDE || location.pathname === URLS.EDIT_DRAFT;
  }, [location]);

  const startPointFieldTitle = useMemo(
    () => (values?.isRoundtrip ? 'Start point of Recurring ride, leg 1' : 'Start point of Recurring ride'),
    [values?.isRoundtrip],
  );

  return (
    <div>
      {!recurringDateFieldsAreDisabled && (
        <>
          <div className={styles.statPointOfRecurring}>
            <div className={styles.dateAndTime}>
              <label className={styles.timezone}>
                <span className={styles.required}>Timezone</span>
                <Select
                  name={datePickerFieldsNames.startPoint.timezone}
                  onChange={handleChangeTZ}
                  options={TIMEZONES}
                  placeholder={'Pick one'}
                  disabled={disabledReccuringValues || recurringDateFieldsAreDisabled}
                  whenFieldDisabledShowTooltip={Boolean(disabledReccuringValues)}
                />
              </label>
              <label className={styles.datepicker}>
                <span className={styles.required}>{startPointFieldTitle}</span>
                <DatePicker
                  name={datePickerFieldsNames.startPoint.datetime}
                  onChange={handleStartPointTime}
                  format={DEFAULT_TIME_FORMAT}
                  value={startPointOfRecurring}
                  showTime
                  showNow={false}
                  use12Hours
                  disabledDate={disabledDatesForStartPointOfRecurring}
                  disabledTime={disabledDateTime(
                    DateHelper.addDates(
                      DateHelper.utcToZonedTime(Date.now(), values?.recurrenceRule?.startPoint?.timezone),
                      minDelayToStartRide,
                    ),
                    startPointOfRecurring,
                  )}
                  disabled={
                    !values?.recurrenceRule?.startPoint?.timezone ||
                    disabledReccuringValues ||
                    recurringDateFieldsAreDisabled
                  }
                  whenFieldDisabledShowTooltip={Boolean(disabledReccuringValues)}
                  inputReadOnly
                />
              </label>
            </div>
          </div>
          {values?.isRecurring && values?.isRoundtrip && (
            <div>
              <div className={styles.dateAndTime}>
                <label className={styles.timezone}>
                  <span className={styles.required}>Timezone</span>
                  <Select
                    name={datePickerFieldsNames.returnStartPoint.timezone}
                    options={TIMEZONES}
                    onChange={handleChangeTZ}
                    placeholder={'Pick one'}
                    disabled={disabledReccuringValues || recurringDateFieldsAreDisabled}
                    whenFieldDisabledShowTooltip={Boolean(disabledReccuringValues)}
                  />
                </label>
                <div className={classNames({ [styles.datepicker]: true, [styles.nestedDatepicker]: true })}>
                  <span className={styles.required}>Start point of Recurring ride, leg 2</span>
                  <DatePicker
                    name={datePickerFieldsNames.returnStartPoint.datetime}
                    onChange={handleReturnStartPoint}
                    format={DEFAULT_TIME_FORMAT}
                    value={returnStartPoint}
                    showTime
                    showNow={false}
                    use12Hours
                    disabledDate={disabledDatesForReturnTimeForRoundTripRecurring}
                    disabledTime={disabledDateTime(
                      DateHelper.addDates(startPointOfRecurring, minimumRoundTrip),
                      returnStartPoint,
                    )}
                    disabled={
                      !values?.recurrenceRule?.returnStartPoint?.timezone ||
                      !values?.recurrenceRule?.startPoint?.datetime ||
                      disabledReccuringValues ||
                      recurringDateFieldsAreDisabled
                    }
                    whenFieldDisabledShowTooltip={Boolean(disabledReccuringValues)}
                    inputReadOnly
                  />
                </div>
              </div>
            </div>
          )}
        </>
      )}
      <div className={styles.recurringInfo}>
        <label className={styles.repeats}>
          <span className={styles.required}>Repeats</span>
          <Select
            name='recurrenceRule.frequency'
            options={RECURRING_REPEATS_OPTIONS}
            placeholder={'Pick one'}
            value={recurringType}
            disabled={!values?.pickUpDate?.datetime || disabledReccuringValues}
            onChange={handleRecurringFrequency}
            whenFieldDisabledShowTooltip={Boolean(disabledReccuringValues)}
          />
        </label>

        <label className={styles.repeats}>
          <span className={styles.required}>Ends After</span>

          <Select
            name='recurrenceRule.count'
            options={endsAfterOptions}
            placeholder={'Number of rides'}
            onChange={handleChangeEndsAfter}
            value={values?.recurrenceRule?.count && `${values?.recurrenceRule?.count} rides`}
            disabled={!values?.pickUpDate?.datetime || disabledReccuringValues}
            whenFieldDisabledShowTooltip={Boolean(disabledReccuringValues)}
          />
        </label>
      </div>
      {renderReccuringTypes({
        recurringType,
        values,
        monthPickerOptions,
        showDisabledTooltip: Boolean(disabledReccuringValues),
        disabled: disabledReccuringValues,
      })}
    </div>
  );
};
