import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { GoogleMap, DirectionsService, DirectionsRenderer } from '@react-google-maps/api';
import { GOOGLE_MAPS_TRAVEL_MODE } from '../../../constants/constants';

const containerStyle = {
  width: '100%',
  height: '400px',
};

export const GoogleMapComponent = ({ origin, destination }) => {
  const [travelMode] = useState(GOOGLE_MAPS_TRAVEL_MODE);
  const [directions, setDirections] = useState(null);

  const directionsCallback = (response) => {
    if (response?.status === 'OK') {
      if (!directions) setDirections(response);
    }
  };
  return (
    <GoogleMap id='google-map-script' mapContainerStyle={containerStyle}>
      <DirectionsService options={{ destination, origin, travelMode }} callback={directionsCallback} />
      {directions && <DirectionsRenderer options={{ directions }} />}
    </GoogleMap>
  );
};

GoogleMapComponent.propTypes = {
  origin: PropTypes.string.isRequired,
  destination: PropTypes.string.isRequired,
};
