import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { useLocation, useParams } from "react-router";
import { bindActionCreators } from "redux";

import { NavLink } from "react-router-dom";
import { InfoOutlined } from "@ant-design/icons";

import { BackIcon } from "../../../assets";

import {
  Button,
  Record,
  StepFour,
  StepOne,
  StepThree,
  StepTwo,
  Tooltip,
  useCancelRideByHOModal,
} from "../../../components";
import {
  getRide,
  editRide,
  hoCancelRide,
  clearCurrentRide,
  setShowRefreshNotification,
  editRideWithRiderInfo,
} from "../../../store/rides";
import { fetchHOPreferences } from "../../../store/health_organizations";
import { getRideRequestOptions } from "../../../store/selectors/healthOrganizations";
import { DateHelper, LodashHelper, renderRecordValue } from "../../../utils";

import { STATUSES_STYLING } from "../../../constants/styles/statuses";
import {
  DEFAULT_TIME_FORMAT,
  MANDATORY_LEVEL,
  RIDE_STATUSES,
  THIRD_STEP_RIDE_REQUEST_FIELDS,
  URLS,
} from "../../../constants/constants";
import { renderEditLeaveModal } from "../EditDraft/renderEditLeaveModal";

import { usePreventLeave } from "../../../hooks/usePreventLeave";

import {
  getOtherLegSeqIdOfRoundtrip,
  getRecurringRideDisabledFieldsForEdit,
  getRideDisabledFieldsForEdit,
  transformRideToDraft,
  transformDraftToRide,
  checkFormIsValid,
  renderPhoneNumber,
} from "../../../utils/helpers";
import {
  getAdditionalRideOptions,
  getPlatformSettings,
} from "../../../store/preferences";
import { useCheckForMatchedTc } from "../../../hooks/useCheckForMatchedTc";
import { initialDraft } from "../../../components/forms/CreateRide/stepperInfo";
import { destroyNotification } from "../../../components/common/ConfirmPopUp/ConfirmPopUp";
import { RideHelper } from "../../../utils/RideHelper";

import styles from "./index.module.css";
import { Navigation } from "../../../utils/navigation";

const EditRide = ({
  hoCancelRide,
  getRide,
  ride,
  fetchHOPreferences,
  rideRequestFields,
  getAdditionalRideOptions,
  rideOptions,
  platformPreferences,
  getPlatformSettings,
  hasMatchedTC,
  editRide,
  editRideWithRiderInfo,
  setShowRefreshNotification,
  clearCurrentRide,
  role,
  airportBufferTime,
}) => {
  const [editSeriesOption, setEditSeriesOption] = useState(null);
  const navigate = Navigation();
  const loc = useLocation();
  const isReschedule = loc?.pathname?.includes('reschedule') 
  const { id } = useParams();
 
  const draft = useMemo(() => {
    return ride
      ? transformRideToDraft(ride)
      : LodashHelper.removeEmpty(initialDraft);
  }, [ride]);

  useEffect(() => {
    setFormikValues(draft);
    return;
  }, [draft]);

  useEffect(
    () => () => {
      clearCurrentRide();
      setShowRefreshNotification(false);
      destroyNotification();
    },
    [clearCurrentRide, setShowRefreshNotification]
  );

  const handleSaveChanges = () => {
    if (formikValues?.flightInfo && formikValues?.pickUpDate && airportBufferTime) {
      const updatePickupDate = DateHelper.addMinutesInDateWithoutFormat(formikValues?.pickUpDate?.datetime, airportBufferTime);
      Object.assign(formikValues, { pickUpDate: {...formikValues?.pickUpDate, datetime: updatePickupDate}, airportBufferTime: airportBufferTime });
    }
    // Object.assign(formikValues?.step3, { airportBufferTime: airportBufferTime });
    if (
      !LodashHelper.isEqual(
        draft?.step1?.riderInfo,
        formikValues?.step1?.riderInfo
      )
    ) {
      return editRideWithRiderInfo({
        id,
        payload: formikValues,
        editSeriesOption,
        handleLeave: unblockHistory?.current,
        navigate,
      });
    }
    editRide({
      id,
      payload: formikValues,
      editSeriesOption,
      handleLeave: unblockHistory?.current,
    });
  };

  const showDocumentReasonForRideField = useMemo(() => {
    return {
      show:
        ride?.additionalInformation?.documentReasonForRide?.length > 0 ||
        rideRequestFields?.some(
          (field) =>
            field.name === THIRD_STEP_RIDE_REQUEST_FIELDS.documentReasonForRide
        ),
      isRequired:
        rideRequestFields?.find(
          (field) =>
            field.name === THIRD_STEP_RIDE_REQUEST_FIELDS.documentReasonForRide
        )?.value === MANDATORY_LEVEL.MANDATORY,
    };
  }, [ride, rideRequestFields]);

  const showAdditionalSourceField = useMemo(() => {
    return {
      show:
        Boolean(ride?.additionalInformation?.additionalFundingSource) ||
        rideRequestFields?.some(
          (field) =>
            field.name ===
            THIRD_STEP_RIDE_REQUEST_FIELDS.additionalFundingSource
        ),
      isRequired:
        rideRequestFields?.find(
          (field) =>
            field.name ===
            THIRD_STEP_RIDE_REQUEST_FIELDS.additionalFundingSource
        )?.value === MANDATORY_LEVEL.MANDATORY,
    };
  }, [ride, rideRequestFields]);

  const costCenterField = useMemo(() => {
    return {
      show:
        Boolean(ride?.additionalInformation?.costCenter) ||
        rideRequestFields?.some(
          (field) => field.name === THIRD_STEP_RIDE_REQUEST_FIELDS.costCenter
        ),
      isRequired:
        rideRequestFields?.find(
          (field) => field.name === THIRD_STEP_RIDE_REQUEST_FIELDS.costCenter
        )?.value === MANDATORY_LEVEL.MANDATORY,
    };
  }, [ride, rideRequestFields]);

  const shouldRenderFourthStep =
    costCenterField.show ||
    showAdditionalSourceField.show ||
    showDocumentReasonForRideField.show;

  const [formikValues, setFormikValues] = useState(draft);

  const [formIsValid, setFormikIsValid] = useState(null);

  const [visibleModal, setModalVisible, unblockHistory, location] =
    usePreventLeave(draft, formikValues);

  const handleStay = () => {
    setModalVisible(false);
  };

  const handleLeave = () => {
    if (unblockHistory) {
      unblockHistory.current();
      navigate.push(location.location);
    }
  };

  const cancelableRide = useMemo(
    () =>
      ride?.status === RIDE_STATUSES.PENDING ||
      ride?.status === RIDE_STATUSES.ACCEPTED,
    [ride]
  );

  const handleConfirmCancel = (values) => {
    setCancelModalVisible(false);
    hoCancelRide({ rideId: id, payload: values, userRole: role }).then(() => handleLeave());
  };

  const { Modal, setCancelModalVisible } = useCancelRideByHOModal({
    id: ride?.seqId,
    role,
    ride,
    onConfirm: (values) => handleConfirmCancel(values),
  });

  const handleToggleModal = useCallback(
    (flag) => () => setCancelModalVisible(flag),
    [setCancelModalVisible]
  );

  useEffect(() => {
    fetchHOPreferences();
    return;
  }, [fetchHOPreferences]);

  useEffect(() => {
    getAdditionalRideOptions();
    return;
  }, [getAdditionalRideOptions]);

  useEffect(() => {
    getRide(id,navigate);
    return;
  }, [getRide, id]);

  useEffect(() => {
    getPlatformSettings();
    return;
  }, [getPlatformSettings]);

  useEffect(() => {
    const query = new URLSearchParams(window?.location?.search);
    setEditSeriesOption(query.get("mode"));
    return;
  }, [id]);

  const handleBack = () => navigate.push(URLS.RIDES);

  const saveChangesButtonIsDisabled = useMemo(() => {
    console.log('RideHelper.compareRideValues(draft, formikValues)', RideHelper.compareRideValues(draft, formikValues));
    console.log('!hasMatchedTC', !hasMatchedTC);
    console.log('!checkFormIsValid(formIsValid)', checkFormIsValid(formIsValid));
    return (
      RideHelper.compareRideValues(draft, formikValues) ||
      !hasMatchedTC ||
      checkFormIsValid(formIsValid)
    );
  }, [draft, formikValues, hasMatchedTC, formIsValid]);

  const disabledValues = useMemo(() => {
    if (ride?.isRecurring && !isReschedule) {
      return getRecurringRideDisabledFieldsForEdit(
        ride?.strictestStatus,
        editSeriesOption
      );
    } else return getRideDisabledFieldsForEdit(ride?.status);
  }, [
    ride?.strictestStatus,
    ride?.status,
    ride?.isRecurring,
    editSeriesOption,
    // isReschedule
  ]);

  const syncFormikValues = useCallback(
    (values, isValid, path) => {
      if (!LodashHelper.isEqual(formikValues?.[path], values)) {
        setFormikValues((prevValues) => {
          return LodashHelper.removeEmpty({
            ...prevValues,
            [path]: values,
          });
        });

        setFormikIsValid((prevValues) => {
          return {
            ...prevValues,
            [path]: isValid,
          };
        });
      }
    },

    [formikValues]
  );

  useCheckForMatchedTc(
    transformDraftToRide(formikValues),
    Boolean(transformDraftToRide(formikValues))
  );

console.log('saveChangesButtonIsDisabled', saveChangesButtonIsDisabled);
  return (
    <div className={styles.pageContent}>
      <div className={styles.header}>
        <div className={styles.backBtn} onClick={handleBack}>
          <BackIcon width="18px" height="18px" fill="#BDBDBD" />
        </div>
        {isReschedule?   <h1 className={styles.pageTitle}>Reschedule Ride - ID {ride?.seqId}</h1> :
        <h1 className={styles.pageTitle}>Edit Ride - ID {ride?.seqId}</h1>}
      </div>
      <div className={styles.editRide}>
        {ride?.isRoundtrip && ride?.connectedLegId && (
          <p className={styles.otherLeg}>
            Other leg of the ride –{" "}
            <NavLink
              className={styles.link}
              to={`${URLS.RIDES}/${ride?.connectedLegId}`}
            >
              ID {getOtherLegSeqIdOfRoundtrip(ride?.seqId, ride?.leg)}
            </NavLink>
          </p>
        )}
        {ride?.driver && (
          <p>
            Matched with driver
            <span
              className={STATUSES_STYLING[ride?.readableStatus]}
            >{` ${renderRecordValue(
              ride?.driver?.firstName
            )} ${renderRecordValue(ride?.driver?.lastName)} `}</span>
            <a
              href={`mailto:${ride?.driver?.email}`}
              className={styles.driverEmail}
            >
              {ride?.driver?.email}
            </a>
            {ride?.driver?.phoneNumber && (
              <span className={STATUSES_STYLING[ride?.readableStatus]}>
                {renderPhoneNumber(ride?.driver?.phoneNumber)}
              </span>
            )}
          </p>
        )}
        <Record
          title="Status"
          value={renderRecordValue(ride?.readableStatus)}
          valueClassName={STATUSES_STYLING[ride?.readableStatus]}
        />
        <Record
          title="Request Created"
          value={renderRecordValue(
            ride?.createdAt &&
              DateHelper.format(new Date(ride?.createdAt), DEFAULT_TIME_FORMAT)
          )}
          myClassName={styles.createdAt}
        />
        <Record
          title="Created By"
          value={`${renderRecordValue(
            ride?.createdBy?.firstName
          )} ${renderRecordValue(ride?.createdBy?.lastName)}`}
        />
        {ride && (
          <div className={styles.editForm}>
           { !isReschedule && <>
            <div className={styles.formBlock}>
              <h2>Rider Information</h2>
              <StepOne
                draft={draft}
                setFormikValues={syncFormikValues}
                showCovidField={draft?.step1?.covid19Relation}
                disabledValues={disabledValues[0]}
                showSubInformation={false}
              />
            </div>
 
            <div className={styles.formBlock}>
              <h2>Vehicle Information</h2>
              <StepTwo
                draft={draft}
                setFormikValues={syncFormikValues}
                platformPreferences={platformPreferences}
                rideOptions={rideOptions}
                disabledValues={disabledValues[1]}
                showSubInformation={false}
              />
            </div></>}

            <div className={styles.formBlock}>
              <StepThree
                draft={draft}
                setFormikValues={syncFormikValues}
                disabledValues={disabledValues[2]}
              
                platformPreferences={platformPreferences}
                showSubInformation={false}
                validateReturnTime={false}
                airportBufferTime={airportBufferTime}
              />
            </div>

            {shouldRenderFourthStep && !isReschedule && (
              <div className={styles.formBlock}>
                <h2>Additional Information</h2>
                <StepFour
                  draft={draft}
                  setFormikValues={syncFormikValues}
                  costCenterField={costCenterField}
                  showAdditionalSourceField={showAdditionalSourceField}
                  showDocumentReasonForRideField={
                    showDocumentReasonForRideField
                  }
                  disabledValues={disabledValues[3]}
                  showSubInformation={false}
                />
              </div>
            )}

            <p className={styles.sendDescription}>
              After pressing "Save Changes" button, this request will be sent to
              our network of transportation providers
            </p>
            <div className={styles.btns}>
              <div className={styles.buttonControl}>
                <Button myClassName={styles.navigationBtn} onClick={handleBack}>
                  Cancel Editing
                </Button>
              </div>
              {cancelableRide && !isReschedule && (
                <div className={styles.buttonControl}>
                  <Button
                    myClassName={styles.navigationBtn}
                    onClick={handleToggleModal(true)}
                  >
                    Cancel Ride
                  </Button>
                </div>
              )}

              <div className={styles.buttonControl}>
                <Button
                  disabled={saveChangesButtonIsDisabled}
                  onClick={handleSaveChanges}
                  type="button"
                >
                  {!isReschedule? `Save Change`: `Reschedule`}
                </Button>
                {ride?.isRoundtrip && (
                  <div className={styles.toolTip}>
                    <Tooltip
                      title={
                        <span className={styles.tooltipContent}>
                        This is roundtrip ride, changes that you made will
                          have affect on other leg of this ride
                        </span>
                      }
                    >
                      <span className={styles.tooltipInfo}>
                        <InfoOutlined className={styles.infoIcon} />
                      </span>
                    </Tooltip>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        {Modal}
      </div>

      {renderEditLeaveModal({
        visibleModal,
        handleStay,
        handleLeave,
      })}
    </div>
  );
};

const mapStateToProps = (state) => ({
  ride: state.rides.ride,
  role: state.authorization.role,
  rideRequestFields: getRideRequestOptions(state),
  hasMatchedTC: state.rides.hasMatchedTC,
  rideOptions: state.preferences.rideOptions?.filter(
    (option) => !option.disabled
  ),
  platformPreferences: state.preferences.platformPreferences,
  airportBufferTime : state?.healthOrganizations?.HOPreferences?.airportBufferTime
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      hoCancelRide,
      getRide,
      editRide,
      editRideWithRiderInfo,
      fetchHOPreferences,
      getAdditionalRideOptions,
      getPlatformSettings,
      setShowRefreshNotification,
      clearCurrentRide,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(EditRide);
