import React, { useState, useEffect, useCallback } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Logo, Loader, Stepper, PersonalInformationStep, PasswordStep } from '../../../components';
import { setAccount, checkInvitationKey } from '../../../store/authorization';
import { getLoading } from '../../../store/selectors/common';

import styles from './index.module.css';
import { Navigation } from '../../../utils/navigation';

const renderForm = ({ step, handleNext, handleBack, formValues, handleSubmit }) => {
  if (step === 0) return <PersonalInformationStep handleNext={handleNext} values={formValues} />;
  else return <PasswordStep handleBack={handleBack} values={formValues} handleSubmit={handleSubmit} />;
};

const steps = [
  {
    title: 'User Information',
    description: 'User set-up is almost complete!  Please fill out user information below.',
  },
  {
    title: 'Create Password',
    description:
      'We recommend the use of strong and long passwords with a minimum of 8 characters with uppercase letters, lowercase letters, numbers, and special characters.',
  },
];

const SetAccount = ({ loading, setAccount, checkInvitationKey, captchaLoaded }) => {
  const navigate =Navigation();
  const [step, setStep] = useState(0);
  const [queryKey, setQueryKey] = useState('');
  const [formValues, setValues] = useState({ phoneNumber: '', jobTitle: '', password: '' });

  useEffect(() => {
    if (window.location.search) {
      const urlParams = new URLSearchParams(window.location.search);
      const queryKey = urlParams.get('key');
      setQueryKey(queryKey);
    }
    return;
  }, []);

  useEffect(() => {
    queryKey && captchaLoaded && checkInvitationKey({ key: queryKey,Navigation:navigate });
    return;
  }, [queryKey, captchaLoaded, checkInvitationKey]);

  const handleNext = useCallback(
    (values) => {
      setStep(step + 1);
      setValues({ ...formValues, ...values });
    },
    [formValues, step],
  );

  const handleBack = useCallback(
    (value) => () => {
      setStep(step - 1);
      setValues({ ...formValues, password: value });
    },
    [formValues, step],
  );

  const handleSubmit = useCallback(
    (values) => {
      setAccount({ values, key: queryKey,Navigation:navigate });
    },
    [setAccount, queryKey],
  );

  return (
    <div className={styles.setPasswordPage}>
      <Logo lg />
      <div className={styles.head}>
        <h1 className={styles.pageHeader}>{steps[step].title}</h1>
      </div>
      <p className={styles.pageDescription}>{steps[step].description}</p>
      <div className={styles.resetForm}>
        <Stepper currentStep={step} steps={steps} />
      </div>
      {renderForm({ step, handleNext, handleBack, formValues, handleSubmit })}
      <Loader loading={loading} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: getLoading(state),
  captchaLoaded: state.common.captchaLoaded,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ setAccount, checkInvitationKey }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SetAccount);
