import React, { useState, useCallback } from 'react';
import classNames from 'classnames';
import { Formik } from 'formik';

import { Button, Checkbox, Modal } from '../..';
import { EXPORT_FORMATS } from '../../../constants/constants';

import styles from './index.module.css';

export const useExportRidesModal = ({ onConfirm }) => {
  const [visibleModal, setModalVisible] = useState(false);
  const handleCheckbox = useCallback(({ name, setFieldValue }) => setFieldValue('fileType', name), []);

  const onCancel = useCallback(() => setModalVisible(false), []);

  const handleConfirm = useCallback(
    (values, resetForm) =>
      onConfirm(values, async () => {
        await setModalVisible(false);
        resetForm();
      }),
    [onConfirm],
  );

  return {
    Modal: (
      <Modal visible={visibleModal} onCancel={onCancel} closable width={670} title={`Exporting Data`}>
        <div className={styles.modalContent}>
          <p className={styles.modalDescription}>Please choose the format for this report document</p>
          <Formik
            initialValues={{ fileType: '' }}
            onSubmit={(values, { resetForm }) => {
              handleConfirm(values, resetForm);
            }}>
            {({ values, handleSubmit }) => {
              return (
                <>
                  <form className={styles.form} onSubmit={handleSubmit}>
                    <div className={styles.checkboxRow}>
                      <label className={styles.checkbox}>
                        <Checkbox
                          name={EXPORT_FORMATS.CSV}
                          checked={values?.fileType === EXPORT_FORMATS.CSV}
                          value={EXPORT_FORMATS.CSV}
                          onChange={handleCheckbox}
                        />
                        <span className={styles.checkboxLabel}>.csv</span>
                      </label>
                      <label className={styles.checkbox}>
                        <Checkbox
                          name={EXPORT_FORMATS.XLSX}
                          checked={values?.fileType === EXPORT_FORMATS.XLSX}
                          value={EXPORT_FORMATS.XLSX}
                          onChange={handleCheckbox}
                        />
                        <span className={styles.checkboxLabel}>.xlsx</span>
                      </label>
                    </div>
                  </form>
                  <div className={styles.modalBtns}>
                    <div>
                      <Button
                        myClassName={classNames({
                          [styles.navigationBtn]: true,
                          [styles.cancelBtn]: true,
                        })}
                        onClick={onCancel}>
                        Cancel
                      </Button>
                    </div>
                    <div>
                      <Button myClassName={styles.navigationBtn} onClick={handleSubmit} disabled={!values.fileType}>
                        Choose and Export
                      </Button>
                    </div>
                  </div>
                </>
              );
            }}
          </Formik>
        </div>
      </Modal>
    ),
    setExportModalVisible: setModalVisible,
  };
};
