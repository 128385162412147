class ReCaptcha {
  constructor(siteKey = process.env.REACT_APP_RECAPTCHA_KEY, action) {
    this.siteKey = siteKey;
    this.action = action;
  }

  static async init(onError) {
    if (!document.querySelector('#recaptcha-script')) {
      const script = document.createElement('script');

      script.src = `https://www.google.com/recaptcha/enterprise.js?onload=_grecaptcha_callback`;

      script.id = 'recaptcha-script';
      script.async = true;
      script.onerror = onError;

      document.body.append(script);
    }
  }

  static toggleCaptchaBadge = (show) => {
    const badge = document.querySelector('.grecaptcha-badge');
    if (badge && badge instanceof HTMLElement) {
      badge.style.visibility = show ? 'visible' : 'hidden';
    }
  };
}

export default ReCaptcha;
