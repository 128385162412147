import React, { useMemo, useCallback, useEffect } from 'react';
import { Formik } from 'formik';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { CreateUser, Button, useDisableUserModal, useSwitchAdminModal } from '../../../components';
import { updateUser, getUserDetails } from '../../../store/users';
import { BackIcon } from '../../../assets';
import { createUserSchema, updateUserSchema } from '../../../utils/validations';
import { LodashHelper, renderRecordValue } from '../../../utils/';
import { USER_ROLES, USER_STATUSES } from '../../../constants/constants';

import styles from './index.module.css';

const UpdateUser = ({
  userDetails,
  getUserDetails,
  Navigation,
  updateUser,
  title,
  managedRole,
  headText,
  id,
  handleConfirmSwitchUser = () => {},
  handleConfirmDisableUser,
  disableEmail = false,
}) => {
  const navigate = Navigation;
  const showSetAsAdminBtn = useMemo(
    () =>
      userDetails.role &&
      ![USER_ROLES.TC_ADMIN, USER_ROLES.HO_ADMIN, USER_ROLES.SUPER_ADMIN].includes(userDetails?.role),
    [userDetails], 
  );

  const showDeactivateButton = useMemo(() => {
    return (
      (userDetails.role && userDetails.role === USER_ROLES.SUPER_ADMIN) ||
      userDetails.role === USER_ROLES.TC_ADMIN ||
      userDetails.role === USER_ROLES.HO_ADMIN
    );
  }, [userDetails.role]);

  const userIsActive = useMemo(
    () =>
      userDetails?.status &&
      userDetails?.status !== USER_STATUSES.INVITED &&
      userDetails?.status &&
      userDetails?.status !== USER_STATUSES.DISABLED,
    [userDetails],
  );

  const { DisableUserModal, setDisableUserModalVisible } = useDisableUserModal({
    handleConfirm: handleConfirmDisableUser,
    userName:
      userDetails?.firstName &&
      userDetails?.lastName &&
      renderRecordValue(`${userDetails?.firstName} ${userDetails?.lastName}`),
  });

  const { SwitchAdminModal, setSwitchAdminModalVisible } = useSwitchAdminModal({
    handleConfirm: handleConfirmSwitchUser,
    userName:
      userDetails?.firstName &&
      userDetails?.lastName &&
      renderRecordValue(`${userDetails?.firstName} ${userDetails?.lastName}`),
  });

  const handleToggleDisableModal = useCallback(
    (flag) => () => setDisableUserModalVisible(flag),
    [setDisableUserModalVisible],
  );

  const handleToggleSwitchAdminModal = useCallback(
    (flag) => () => setSwitchAdminModalVisible(flag),
    [setSwitchAdminModalVisible],
  );
  useEffect(() => {
    getUserDetails(id);
    return;
  }, [id, getUserDetails]);
  const handleBack = () => {
    navigate.back();
  };

  return (
    <div className={styles.pageContent}>
      <div className={styles.header}>
        <div className={styles.backBtn} onClick={handleBack}>
          <BackIcon width='18px' height='18px' fill='#BDBDBD' />
        </div>
        <h1 className={styles.pageTitle}>{title}</h1>
      </div>
      <div>
        <h2>{headText}</h2>
        <Formik
          enableReinitialize
          validationSchema={userIsActive ? updateUserSchema : createUserSchema}
          validateOnMount
          initialValues={userDetails}
          onSubmit={(values) => updateUser({ id, values,Navigation:navigate })}>
          {({ values, ...rest }) => {
            return (
              <form className={styles.form} onSubmit={rest.handleSubmit}>
                <CreateUser values={values} {...rest} showPersonalInfo={userIsActive} disableEmail={disableEmail} />

                <div className={styles.btnPanel}>
                  {userIsActive && (showDeactivateButton || showSetAsAdminBtn) && (
                    <div className={styles.manageUserBtns}>
                      <div className={styles.btnControl}>
                        <Button myClassName={styles.navigationBtn} onClick={handleToggleDisableModal(true)}>
                          Deactivate {managedRole}
                        </Button>
                      </div>
                      {showSetAsAdminBtn && (
                        <div className={styles.btnControl}>
                          <Button myClassName={styles.navigationBtn} onClick={handleToggleSwitchAdminModal(true)}>
                            Set as Admin
                          </Button>
                        </div>
                      )}
                    </div>
                  )}
                  <div
                    className={classNames({
                      [styles.btnControl]: true,
                      [styles.saveBtn]: true,
                    })}>
                    <Button disabled={!rest.isValid || LodashHelper.isEqual(values, userDetails)} type='submit'>
                      Save Changes
                    </Button>
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      </div>

      {DisableUserModal}
      {showSetAsAdminBtn && SwitchAdminModal}
    </div>
  );
};

const mapStateToProps = (state) => ({
  userDetails: {
    email: state?.users?.userDetails?.email,
    firstName: state?.users?.userDetails?.firstName,
    lastName: state?.users?.userDetails?.lastName,
    jobTitle: state?.users?.userDetails?.jobTitle,
    phoneNumber: state?.users?.userDetails?.phoneNumber,
    role: state?.users?.userDetails?.role,
    status: state?.users?.userDetails?.status,
  },
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ updateUser, getUserDetails }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UpdateUser);
