import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Formik, getIn } from 'formik';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useParams } from 'react-router-dom';
import { useConfirmEditTransportCompanyModal } from '../../../components/modals/ConfirmEditTransportCompanyModal/useConfirmEditTransportCompanyModal'
import { CreateTC, Button, DisableOrganizationModal, SwitchAdminForm, InviteUserForm } from '../../../components';
import { createTransportCompanySchema, LodashHelper, GoogleAddressHelper } from '../../../utils';
import { TC_OPERATIONAL_HOURS, FLEET_OPTIONS } from '../../../constants/constants'
import {
  fetchTransportOrganization,
  disableTO,
  updateTO,
  switchTCAdmin,
  inviteNewUserForTc,
} from '../../../store/transport_organizations';
import { fetchStatesList } from '../../../store/common';
import { getAdditionalRideOptions } from '../../../store/preferences';
import { fetchUsersByOrganization } from '../../../store/users';
import { BackIcon } from '../../../assets';
import { getSortedEmployees } from '../../../store/selectors/users';
import {
  handleAddCounties,
  handleCountiesSelect,
  handleDelete,
  handleStateSelect,
} from '../../../utils/countiesHelper';

import styles from './index.module.css';
import { Navigation } from '../../../utils/navigation';

const Update_Transport_Company = ({
  currentTransportOrganization,
  history,
  fetchTransportOrganization,
  disableTO,
  updateTO,
  switchTCAdmin,
  getAdditionalRideOptions,
  rideOptions,
  inviteNewUserForTc,
  fetchUsersByOrganization,
  users,
  fetchStatesList,
  counties,
  states,
}) => {
  const [inviteUserModalVisible, setInviteUserModalVisible] = useState(false);
  const [disableOrganizationModalVisible, setDisableOrganizationModalVisible] = useState(false);
  const [updateAdminModalVisible, setUpdateAdminModalVisible] = useState(false);
  const [finalFormValues, setFinalFormValues] = useState({});
  const formRef = useRef();
const navigate =Navigation();
  useEffect(() =>{ fetchStatesList(); return;}, [fetchStatesList]);

  useEffect(() => {getAdditionalRideOptions(); return;}, [getAdditionalRideOptions]);

  const { id } = useParams();

  useEffect(() => {formRef?.current?.validateForm(); return;});

  useEffect(() => {fetchTransportOrganization(id); return;}, [fetchTransportOrganization, id]);

  const handleBack = useCallback(() => navigate.back(), [navigate]);

  const handleSelectAddress = ({ value, values, setValues }) => {
    setValues({ ...values, location: GoogleAddressHelper.transformFields(value) });
  };

  const handleChangeAddress = ({ value, values, setValues }) => {
    setValues({
      ...values,
      location: {
        formattedAddress: value,
      },
    });
  };

  const onCancelInviteUser = (resetForm) => () => {
    setInviteUserModalVisible(false);
    resetForm({ email: '' });
  };

  const onCancelSwitchAdmin = (resetForm) => () => {
    setUpdateAdminModalVisible(false);
    resetForm();
  };

  const onCancelDisableModal = () => setDisableOrganizationModalVisible(false);

  const handleInviteUser = () => setInviteUserModalVisible(true);

  const handleDisableOrganization = () => setDisableOrganizationModalVisible(true);

  const onConfirmDisable = () => disableTO({ id, disabled: true,Navigation:navigate });

  const onConfirmSwitchAdmin = ({ organizationId, userId }) =>
    switchTCAdmin({ organizationId, userId, closeModal: () => setUpdateAdminModalVisible(false) });

  const onConfirmInviteUser = ({ organizationId, values }) =>
    inviteNewUserForTc({ organizationId, values, closeModal: () => setInviteUserModalVisible(false) });

  const handleChangeAdmin = () => {
    fetchUsersByOrganization({ id });
    setUpdateAdminModalVisible(true);
  };

  const { confirmModalVisible, setConfirmModalVisible, Modal } =
    useConfirmEditTransportCompanyModal({
      onConfirm: (reasonForUpdate) =>
        updateTO({
          values: LodashHelper.trimValues({
            ...finalFormValues,
            ...reasonForUpdate,
          }),
          id ,Navigation:navigate,
          onSuccesCallback: () => setConfirmModalVisible(false),
        }),
    });

    const handleSubmitTC = (values) => {
      setFinalFormValues(values); 
      const fleetSizeUpdated = !LodashHelper.isEqual(
        getIn(values, "fleetSizes"),
        currentTransportOrganization?.fleetSizes
      );
      if (fleetSizeUpdated) {
        setConfirmModalVisible(true);
      } else {
        updateTO({ values: LodashHelper.trimValues(values), id ,Navigation:navigate});
      }
    };
 
  return (
    <div className={styles.pageContent}>
      <div className={styles.header}>
        <div className={styles.backBtn} onClick={handleBack}>
          <BackIcon width='18px' height='18px' fill='#BDBDBD' />
        </div>
        <h1 className={styles.pageTitle}>Company Details</h1>
      </div>
      <div>
        <h2>Information on the Transportation Company</h2>
        <Formik
          enableReinitialize
          validationSchema={createTransportCompanySchema}
          validateOnMount
          validateOnChange={false}
          initialValues={
            currentTransportOrganization
              ? { ...currentTransportOrganization,fleetSizes:Array.isArray(currentTransportOrganization.fleetSizes)?currentTransportOrganization.fleetSizes : FLEET_OPTIONS, 
                hourOfOperations:currentTransportOrganization?.hourOfOperations || TC_OPERATIONAL_HOURS, 
                 counties: [], state: '' }
              : {
                  name: '',
                  regionsOfOperation: [],
                  location: {
                    formattedAddress: '',
                  },
                  admin: {
                    email: '',
                    firstName: '',
                    lastName: '',
                  },
                  state: '',
                  counties: [],
                  vehicleTypes: [],
                  fleetSizes:FLEET_OPTIONS,
                  hourOfOperations:TC_OPERATIONAL_HOURS, 
                  rideOptions: [],
                  scheduledRidesReminderHour: null,
                  scheduledRidesReminderDay: '',
                  preferredTimezone: '',
                }
          }
          onSubmit={({ admin, counties, state, ...values }) => {
           // updateTO({ values: LodashHelper.trimValues(values), id });
            handleSubmitTC(values)
          }}>
          {({ values, ...rest }) => {
            return (
              <form className={styles.form} onSubmit={rest.handleSubmit}>
                <CreateTC
                  values={values}
                  states={states}
                  counties={counties}
                  switchAdmin
                  handleSelectAddress={handleSelectAddress}
                  handleChangeAddress={handleChangeAddress}
                  handleInviteUser={handleInviteUser}
                  handleChangeAdmin={handleChangeAdmin}
                  personalInfoFieldsDisabled
                  handleStateSelect={handleStateSelect}
                  handleCountiesSelect={handleCountiesSelect(counties)}
                  handleAddCounties={handleAddCounties({ values, setValues: rest.setValues })}
                  handleDelete={handleDelete}
                  rideOptions={rideOptions}
                  {...rest}
                />
                <div className={styles.btnPanel}>
                  <div className={styles.btnControl}>
                    <Button myClassName={styles.navigationBtn} onClick={handleDisableOrganization}>
                      Deactivate Company
                    </Button>
                  </div>
                  <div className={styles.btnControl}>
                    <Button disabled={!rest.isValid} type='submit'>
                      Save Changes
                    </Button>
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
      <DisableOrganizationModal
        name={currentTransportOrganization?.name}
        visible={disableOrganizationModalVisible}
        onCancel={onCancelDisableModal}
        onConfirm={onConfirmDisable}
      />

      <SwitchAdminForm
        users={users}
        id={id}
        formRef={formRef}
        updateAdminModalVisible={updateAdminModalVisible}
        onCancel={onCancelSwitchAdmin}
        onConfirmSwitchAdmin={onConfirmSwitchAdmin}
      />

      <InviteUserForm
        id={id}
        formRef={formRef}
        inviteUserModalVisible={inviteUserModalVisible}
        onCancelInviteUser={onCancelInviteUser}
        onConfirmInviteUser={onConfirmInviteUser}
      />
       {confirmModalVisible && Modal}
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentTransportOrganization: state.transportOrganizations.currentTransportOrganization,
  rideOptions: state.preferences.rideOptions?.filter((option) => !option.disabled),
  users: getSortedEmployees(state, styles),
  counties: state.common.counties,
  states: state.common.states,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchTransportOrganization,
      disableTO,
      updateTO,
      switchTCAdmin,
      getAdditionalRideOptions,
      inviteNewUserForTc,
      fetchUsersByOrganization,
      fetchStatesList,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Update_Transport_Company);
