import React, { useCallback, useEffect, useMemo } from "react";

import {
  DatePicker
} from "../../..";

import { DateHelper } from "../../../../utils";
import { DEFAULT_TIME_FORMAT, RIDE_STATUSES } from "../../../../constants/constants";

import { useDateTimeHandlers } from "./useDateTimeHandlers";

import DateHelperInstance from "../../../../utils/DateHelper";

import styles from "./index.module.css";
import { FormikProvider, useFormik } from "formik";
import { reschduleRideSchema } from "../../../../utils/validations";
import { RidePickUpInfo } from "../../../common/RideDetails/RideDetailsPartials/RidePickUpInfo";
import { RideDestinationInfo } from "../../../common/RideDetails/RideDetailsPartials/RideDestinationInfo";
import { ArrowIcon } from "../../../../assets";

export const NotifyAirportBufferTime = ({ flightInfo, updatedPickupDateTime }) => {
  return <>
    {flightInfo && updatedPickupDateTime ?
      <p style={{ color: 'red' }}>{`Note: Your ride is scheduled for ${updatedPickupDateTime} allowing time for baggage collection and airport formalities.`}</p> : null}
  </>
}

export const RescheduleRideForm = ({
  values,
  setFormikValues,

  minDelayToStartRide={hours: 0, minutes: 15},
  maxPickUpWindowOfTime,
  vehicleType,

  minimumRoundTrip,
  airportBufferTime
}) => {
  const handleSubmitWithConfirm = (currentValues, stepNumber) => {


    // handleSubmit(currentValues, stepNumber);
  }

  const InitialValues = useMemo(() => {

    let ridePickupDate = values?.pickUpDate;
    let rideReturnTime;

    if (values?.isRoundtrip) {
      if (values?.leg.toLowerCase() === "b") {
        ridePickupDate = values?.connectedLeg?.pickUpDate;
        rideReturnTime = values?.pickUpDate;

      } else {
        rideReturnTime = values?.connectedLeg?.pickUpDate;
      }
    }



    return {
      pickUpDate: ridePickupDate || {},
      returnTime: rideReturnTime || {}
    };
  }, [values.pickUpDate, values.returnTime]);
  const formik = useFormik({
    enableReinitialize: true,
    validateOnMount: true,
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: InitialValues,
    validationSchema: reschduleRideSchema(values?.isRoundtrip),
    onSubmit: handleSubmitWithConfirm,
  });

  const { values: formValue, touched, errors, validateForm, setFieldTouched, setFieldValue, isValid } = formik;

  useEffect(() => {


    if (setFormikValues) {

      let payload = {};
     
      if (values?.leg?.toLowerCase() === "b") {
        payload.rescheduledDate = formValue?.returnTime?.datetime;
        payload.connectedLegId = values?.connectedLegId;
        payload.connectedLegRescheduleDate = formValue?.pickUpDate?.datetime;
      } else {
        payload.rescheduledDate = formValue?.pickUpDate?.datetime;
        if (values.isRoundtrip) {
          payload.connectedLegId = values?.connectedLegId;
          payload.connectedLegRescheduleDate = formValue?.returnTime?.datetime;
        }
      }

       
      setFormikValues(payload, isValid)


    }
    return;
  }, [setFormikValues, formValue, isValid]);


  const {
    handlePickUpDateTime,
    handleReturnTime,
    disabledDatesForPickUpDate,
    disabledDatesForReturnTime,
    disabledDateTime,
    pickUpDate,
    returnTimeDate,
    returnTimeLowerLimit,
    appointmentDate,
  } = useDateTimeHandlers({
    validateForm: formik.validateForm,
    setFieldTouched,
    defaultValues: InitialValues,
    minDelayToStartRide,
    maxPickUpWindowOfTime,
    vehicleType,
    minimumRoundTrip,
  });

  const minAvailablePickUpDate = useMemo(
    () =>
      DateHelper.addDates(
        DateHelper.utcToZonedTime(Date.now(), values?.pickUpDate?.timezone),
        minDelayToStartRide
      ),
    [values?.pickUpDate?.timezone, minDelayToStartRide]
  );


  const diabledDatesForPickUpField = useMemo(
    () =>
      disabledDatesForPickUpDate(minAvailablePickUpDate),
    [

      disabledDatesForPickUpDate,
      minAvailablePickUpDate,
    ]
  );


  const disabledDateTimeForPickUpField = useMemo(
    () =>
      disabledDateTime(minAvailablePickUpDate, pickUpDate),
    [

      disabledDateTime,
      pickUpDate,
      minAvailablePickUpDate,
    ]
  );

  const pickUpDefaultDateTime = useMemo(
    () => new Date().setHours(0, 0, 0, 0),
    []
  );

  const returnTimeDefaultDateTime = useMemo(() => new Date().setHours(0, 0, 0, 0), []);



  const minAvailableReturnDate = useMemo(
    () => DateHelper.addDates(pickUpDate, minimumRoundTrip),
    [pickUpDate, minimumRoundTrip],
  );

  const diabledDatesForReturnField = useMemo(
    () =>
      disabledDatesForReturnTime(pickUpDate),
    [

      disabledDatesForReturnTime,
      returnTimeLowerLimit,
      pickUpDate,
    ],
  );

  const disabledDateTImeForReturnField = useMemo(
    () =>
      disabledDateTime(minAvailableReturnDate, returnTimeDate),
    [

      disabledDateTime,
      minAvailableReturnDate,
      returnTimeDate,
    ],
  );

  const handleChildValueChange = useCallback(
    async (fieldName, updatedValue) => {
      await setFieldValue(fieldName, updatedValue);
    },
    [setFieldValue]
  );




  const pickupDateIsDisabled = useMemo(() => {
     return !(values?.isRoundTrip && values?.leg?.toLowerCase()==='b' && values?.connectedLeg?.status === RIDE_STATUSES.ACCEPTED)
     

    
     
    
     
  }, [values]);




  const updatedPickupDateTime = useMemo(() => {
    if (values?.flightInfo && pickUpDate && airportBufferTime) {
      let addedMinutesDate = DateHelperInstance.addMinutesInDateWithFormat(pickUpDate, airportBufferTime);
      return addedMinutesDate;
    }

    return null;
  }, [pickUpDate, values?.airportBufferTime, values?.flightInfo]);

  return (
    <>
      {(values?.connectedLeg) && <h3 className={styles.roundTripHeader}>Roundtrip Ride</h3>}
      <div className={styles.routes}>
        <RidePickUpInfo
          pickupAddressDetails={values?.pickupAddressDetails}
          pickUpDate={values?.pickUpDate}
          riderDescription={values?.riderDescription}
          pickUpDateLowerLimit={values?.pickUpDateLowerLimit}
          isPickUpDateFlexible={values?.isPickUpDateFlexible}
          tcEstimatedPickUpDate={values?.tcEstimatedPickUpDate}
          securityGate={values?.pickUpSecurityGateDetails}
          flightInfo={values?.flightInfo}
          airportBufferTime={airportBufferTime}
          appointmentDate={values?.appointmentDate}
          isRecurringRide={values?.isRecurring}
          leg={values?.isRoundtrip ? (values?.leg ? values?.leg : 'A') : ''}
          seqId={values?.seqId}
          destinationAddress={values?.isRoundtrip ? values?.destinationAddress : null}
        />

        <div className={styles.destionationRouteInfo}>
          <div className={styles.arrows}>
            {!values?.connectedLeg && (
              (values?.isRoundtrip && !values?.leg) ? (
                <>
                  {/* <ArrowIcon fill="#bdbdbd" />
                <ArrowIcon fill="#bdbdbd" className={styles.arrowLeft} /> */}
                </>
              ) : (
                <ArrowIcon fill="#bdbdbd" />
              ))
            }
          </div>

          <RideDestinationInfo
            destinationAddress={values?.destinationAddress}
            originAddress={values?.isRoundtrip ? values?.pickupAddressDetails : null}
            showRoundtripInfo={values?.isRoundtrip && values?.connectedLeg}
            securityGate={values?.destinationSecurityGateDetail}
            returnTime={values?.connectedLeg ? values?.connectedLeg?.pickUpDate : values?.returnTime}
            returnTimeLowerLimit={values?.returnTimeLowerLimit}
            isReturnDateFlexible={values?.isReturnDateFlexible}
            leg={values?.isRoundtrip ? (values?.connectedLeg ? values?.connectedLeg?.leg : 'B') : ''}
            seqId={values?.connectedLeg?.seqId}
          />
        </div>
      </div>

      <FormikProvider value={formik}>
        <form onSubmit={formik?.handleSubmit} className={styles.form}>
          <div className={styles.rescheduleFormContent}>



            {/* {values?.isRoundtrip && <span>Leg A</span>} */}


            <label  >
              <span className={styles.rescheduleInputLabel}>Leg A Pick Up Date & Time</span>
              {/* <span className={styles.midnightNoonMessage}>
                {Midnight_Noon_Clarification}
              </span> */}
              <DatePicker
                name="pickUpDate.datetime"
                allowClear
                onChange={handlePickUpDateTime}
                format={DEFAULT_TIME_FORMAT}
                value={pickUpDate}
                showTime={{ defaultValue: pickUpDefaultDateTime }}
                showNow={false}
                use12Hours
                disabledDate={diabledDatesForPickUpField}
                disabledTime={disabledDateTimeForPickUpField}
                //disabled={pickupDateIsDisabled}
                inputReadOnly
                getPopupContainer={(trigger) => trigger}
              />
            </label>

            {values?.connectedLeg &&

              <label  >
                <span className={styles.rescheduleInputLabel}>Leg B Pickup Date & Time</span>
                {/* <span className={styles.midnightNoonMessage}>
                  {Midnight_Noon_Clarification}
                </span> */}
                <DatePicker
                  name='returnTime.datetime'
                  myClassName={styles.datepicker}
                  onChange={handleReturnTime}
                  format={DEFAULT_TIME_FORMAT}
                  value={returnTimeDate}
                  showTime={{ defaultValue: returnTimeDefaultDateTime }}
                  showNow={false}
                  use12Hours
                  disabledDate={diabledDatesForReturnField}
                  disabledTime={disabledDateTImeForReturnField}

                  inputReadOnly
                  allowClear
                  getPopupContainer={(trigger) => trigger}
                />
              </label>

            }

          </div>
        </form>
      </FormikProvider>

    </>
  );
};
