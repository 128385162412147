import React, { useCallback, useEffect } from 'react';

import PropTypes from 'prop-types';
import { FormikProvider, useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { ButtonsPanel } from '../ButtonsPanel/ButtonsPanel';
import { stepperInfo } from '../stepperInfo';
import { StepOneFormFields } from './StepOneFormFields';
import { useRidersAutoComplete } from '../../../../hooks/useRidersAutoComplete';
import { RiderHelper } from '../../../../utils/RiderHelper';
 
import { URLS } from '../../../../constants/constants';

import styles from './index.module.css';
import { useLocation } from 'react-router-dom';

export const StepOne = ({
  step,
  draft,
  handleSaveAndClose,
  handleSubmit,
  handlePreviousStep,
  showCovidField,
  setFormikValues,
  showSubInformation = true,
  disabledValues,
  delay = 1000,
}) => {

  const location = useLocation();  
  const formik = useFormik({
    validationSchema:  stepperInfo[0].schema(showCovidField),
    enableReinitialize: true,
    initialValues: stepperInfo[0]?.values,
    validateOnMount: true,
    onSubmit: handleSubmit,
  });

  const hasMatchedRiders = useSelector((state) => state.riders.hasMatchedRiders);

  const { validateForm, setValues, values, isValid, setFieldValue } = formik;

  const onRiderSearch = async (value) => {
    if (!value) {
      await setFieldValue('riderInfo', stepperInfo[0].values.riderInfo);
    }
    await setFieldValue('formattedRiderName', value);
  };

  const { notFoundContent, suggestions, handleSearchRider, handleSelectRider, riderIsPopulated, setRiderIsPopulated } =
    useRidersAutoComplete({
      handleSearch: onRiderSearch,
      value: values?.formattedRiderName,
      delay,
    });

  useEffect(() => {
    draft?.step1?.riderDraft?.id && setRiderIsPopulated(true);
    return;
  }, [draft?.step1?.riderDraft?.id, setRiderIsPopulated]);

  const validate = useCallback(async () => {
    if (draft?.step1) {
      await setValues(draft?.step1);
      await validateForm();
      await setFieldValue('formattedRiderName', RiderHelper.getRiderAutoCompleteName(draft?.step1?.riderInfo));
      if (draft?.step1?.riderDraft && location.pathname === URLS.EDIT_DRAFT) {
        await setFieldValue('riderInfo', draft?.step1?.riderDraft);
      }
    }
  }, [draft, setFieldValue, setValues, validateForm,location]);

  useEffect(() => {
    validate();
    return () => {
      setValues(stepperInfo[0].values);
    };
  }, [validate, setValues]);

  useEffect(() => {
    setFormikValues && setFormikValues(values, isValid, 'step1');
    return;
  }, [setFormikValues, values, isValid]);

  return (
    <>
      {showSubInformation && <h2 className={styles.headTitle}>Step 1: Enter Rider Information</h2>}
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit} className={styles.riderInfo}>
          <StepOneFormFields
            errors={formik.errors}
            values={formik.values}
            setFieldValue={setFieldValue}
            showCovidField={showCovidField}
            disabledValues={disabledValues}
            notFoundContent={notFoundContent}
            handleSelectRider={handleSelectRider}
            handleSearchRider={handleSearchRider}
            suggestions={suggestions}
            riderIsPopulated={riderIsPopulated}
            setRiderIsPopulated={setRiderIsPopulated}
          />

          {showSubInformation && (
            <ButtonsPanel
              disabled={!formik.isValid || hasMatchedRiders}
              step={step}
              handlePreviousStep={handlePreviousStep}
              handleSaveAndClose={handleSaveAndClose}
              handleSubmit={handleSubmit}
              nextButtonText={'Save Changes and Next'}
            />
          )}
        </form>
      </FormikProvider>
    </>
  );
};

StepOne.propTypes = {
  step: PropTypes.number,
  draft: PropTypes.object,
  handleSaveAndClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  handlePreviousStep: PropTypes.func,
};
