import React from "react";
import { Input } from "../../formControls/Input/Input";
import styles from "./index.module.css";

export const renderTimeInputSubFields = (fieldName, label,showMileage=false) => {
  return (
    <>
      <div
      className={styles.renderFieldView}
      >
        <label
         className={styles.labelSubcsription}>
          {label}
        </label>
        <div  className={styles.renderTimeinput}>
          {showMileage && (
            <label className={styles.measurement}>0-10 Miles</label>
          )}
            <div className={styles.inputfield} >
            <Input
                prefix={'$'}
              name={`${fieldName}.miles0_10`}
              type="number"
              min={0}
              precision={2}
              step={0.01}
            />
          </div>
        </div>
        <div>
          {fieldName === "flatRate.ambulatory" && (
            <label className={styles.measurement}>10-25 Miles</label>
          )}
            <div className={styles.inputfield} >
            <Input
                prefix={'$'}
              name={`${fieldName}.miles10_25`}
              type="number"
              min={0}
              precision={2}
              step={0.01}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export const renderSJCRHFields = (fieldName, label,showMileage=false) => {
  return (
    <>
      <div
        className={styles.renderFieldView}
      >
        <label className={styles.labelSubcsription}>
          {label}
        </label>
        <div className={styles.labeldata}>
          {showMileage && (
            <label className={styles.measurement}>Under 3 Miles</label>
          )}
            <div className={styles.inputfield} >
            <Input
            prefix={'$'}
              name={`${fieldName}.miles0_3`}
              type="number"
              min={0} 
              precision={2}
              step={0.01}
            />
          </div>
        </div>
        <div>
          {showMileage && (
            <label className={styles.measurement}>Over 3 Miles</label>
          )}
            <div className={styles.inputfield} >
            <Input
               prefix={'$'}
              name={`${fieldName}.moreThen3Miles`}
              type="number"
              min={0}
              precision={2}
              step={0.01}
            />
          </div>
        </div>
      </div>
    </>
  );
};
