import { Formik } from 'formik';
import { SecurityPreferencesEmail } from '../../..';
import { changeEmailSchema } from '../../../../utils';

import styles from './index.module.css';

export const PasswordSettings = ({ user, changeEmail }) => {
  return (
    <>
      <h2>Password Information</h2>
      <Formik
        enableReinitialize
        validateOnMount
        initialValues={{
          newEmail: user.email || '',
          password: '',
        }}
        validationSchema={changeEmailSchema(user?.email)}
        onSubmit={(values) => changeEmail(values)}>
        {({ values, handleSubmit, ...rest }) => {
          return (
            <form onSubmit={handleSubmit} className={styles.personalInfoForm}>
              <SecurityPreferencesEmail values={values} initialEmail={user?.email} {...rest} />
            </form>
          );
        }}
      </Formik>
    </>
  );
};
