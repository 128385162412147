import React from 'react';

import { Modal, Button } from '../../../components';

import styles from './index.module.css';

export const renderCreateRideModal = ({
  draftId,
  visibleModal,
  handleStay,
  handleDelete,
  handleSaveAndClose,
  handleLeave,
}) => {
  return (
    <Modal visible={visibleModal} onCancel={handleStay} closable width={670} title='Canceling the Request'>
      <div className={styles.modalContent}>
        <p className={styles.modalDescription}>
          If you choose to leave this page, any filled information will not be saved. You can also Save it to Drafts to
          continue working on it later
        </p>
        <div className={styles.modalBtns}>
          <div>
            <Button myClassName={styles.navigationBtn} onClick={draftId ? handleDelete : handleLeave}>
              Delete
            </Button>
          </div>
          <div>
            <Button myClassName={styles.navigationBtn} onClick={handleSaveAndClose}>
              Save and Close
            </Button>
          </div>
          <div>
            <Button myClassName={styles.navigationBtn} onClick={handleStay}>
              Stay
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
