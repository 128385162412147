import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Formik } from 'formik';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  Button,
  SecurityPreferencesEmail,
  SecurityPreferencesPassword,
  CreateTC,
  PersonalInfoPreferences,
  useTwoFactorVerificationModal,
} from '../../../components';
import {
  fetchTOAdminPreferences,
  TOAdminUpdateOwnOrganization,
  postTransportCompanyPricing,
  fetchTransportCompanyPricing
} from '../../../store/transport_organizations';
import {
  changePassword,
  changePasswordVerification,
  changeEmail,
  updatePersonalInformation,
} from '../../../store/authorization';
import { getAdditionalRideOptions } from '../../../store/preferences';
import { fetchStatesList } from '../../../store/common';
import {
  updatePasswordSchema,
  changeEmailSchema,
  GoogleAddressHelper,
  personalInformationSchema,
  LodashHelper,
} from '../../../utils/';
import { useCurrentRole } from '../../../hooks/useCurrentRole';
import { updateOrganizationSchema } from '../../../utils/validations';
import {
  handleAddCounties,
  handleCountiesSelect,
  handleDelete,
  handleStateSelect,
} from '../../../utils/countiesHelper';

import styles from './index.module.css';
import { TC_OPERATIONAL_HOURS, FLEET_OPTIONS, DEFAULT_TC_BILLING } from '../../../constants/constants';
import { TCBillSegment } from '../../../components/common/BillingAndInvoicing/TCBillSegment';
import { ConfirmChangePricingValuesModal } from '../../../components/modals/ConfirmPricingActionModals/ConfirmChangePricingValuesModal';

const TC_Preferences = ({
  fetchTOAdminPreferences,
  TCPreferences,
  TOAdminUpdateOwnOrganization,
  postTransportCompanyPricing,
  fetchTransportCompanyPricing,
  updatePersonalInformation,
  changePassword,
  changePasswordVerification,
  changeEmail,
  role,
  rideOptions,
  getAdditionalRideOptions,
  user,
  fetchStatesList,
  counties,
  states,
  isViewDashboardMode,
  TCPricing,
}) => {
  const { isEmployee, isTCWorker } = useCurrentRole(role);
  const securityRef = useRef(null);
  const { TwoFactorAuthenticationModal, twoFactorModalVisible, setTwoFactorModalVisible } =
    useTwoFactorVerificationModal({
      handleConfirm: (token, confirmCallback) =>
        changePasswordVerification({ token, newPassword: securityRef?.current?.values?.newPassword }).then((res) => {
          if (res) {
            confirmCallback();
            securityRef?.current?.resetForm();
          }
        }),
      email: user?.email,
    });
    
  const [currentPricing, setCurrentPricing] = useState();
  const [openPricingValuesModal, setOpenPricingValuesModal] = useState(false);
  
  useEffect(() => {fetchTOAdminPreferences(); }, [fetchTOAdminPreferences]);

  useEffect(() => {fetchStatesList(); }, [fetchStatesList]);

  useEffect(() => {getAdditionalRideOptions(); }, [getAdditionalRideOptions]);

  useEffect(() => {
      if (TCPreferences?.tcPricingId) {
        const payload = {
          orgId: user?.organizationId,
          tcPricingId: TCPreferences?.tcPricingId
        }

        fetchTransportCompanyPricing(payload);
      }

  }, [fetchTransportCompanyPricing, TCPreferences?.tcPricingId, user?.organizationId]);

  useEffect(() => { 
    if (TCPricing) {
      console.log('Tc pricing', TCPricing);
      if (TCPricing?.data) {
        setCurrentPricing(TCPricing?.data[0]);
      } else {
        setCurrentPricing(TCPricing);
      }
    }
  }, [TCPricing]);

  const onSubmitPricing = (values) => {
    const isValuesChanged = !LodashHelper.isEqual(values, TCPricing);

    if (isValuesChanged) {
      setOpenPricingValuesModal(true);
      setCurrentPricing(values);
    }
  };

  const onConfirm = (values) => {
    postTransportCompanyPricing({ ...values, id: TCPricing?.id, organizationId: user?.organizationId });
    setOpenPricingValuesModal(false);
  }

  const handleSelectAddress = async ({ value, values, setValues }) => {
    setValues({ ...values, location: GoogleAddressHelper.transformFields(value) });
  };

  const handleChangeAddress = ({ value, values, setValues }) => {
    setValues({
      ...values,
      location: {
        formattedAddress: value,
      },
    });
  };

  const allowScrollToPersonalInfo = useMemo(() => {
    return !LodashHelper.isEmpty(TCPreferences) && !LodashHelper.isEmpty(user);
  }, [TCPreferences, user]);

  return (
    <div className={styles.pageContent}>
      <div className={styles.header}>
        <h1 className={styles.pageTitle}>Preferences</h1>
      </div>
      <div className={styles.pageSection}>
        <h2 className={styles.formHead}> Company Information</h2>

        <Formik
          enableReinitialize
          validateOnChange={false}
          validationSchema={updateOrganizationSchema}
          initialValues={
            TCPreferences
              ? { ...TCPreferences, fleetSizes:Array.isArray(TCPreferences.fleetSizes)? TCPreferences.fleetSizes: FLEET_OPTIONS, 
                hourOfOperations:TCPreferences?.hourOfOperations || TC_OPERATIONAL_HOURS,  counties: [], state: '' }
              : {
                  name: '',
                  regionsOfOperation: [],
                  state: '',
                  counties: [],
                  location: {
                    formattedAddress: '',
                  },
                  vehicleTypes: [],
                  fleetSizes: FLEET_OPTIONS,
                  hourOfOperations : TC_OPERATIONAL_HOURS,
                  rideOptions: [],
                }
          }
          onSubmit={({ admin, counties, state, ...values }) => TOAdminUpdateOwnOrganization(values)}>
          {({ values, ...rest }) => {
            return (
              <form className={styles.form} onSubmit={rest.handleSubmit}>
                <CreateTC
                  handleChangeAddress={handleChangeAddress}
                  handleSelectAddress={handleSelectAddress}
                  showPersonalInfo={false}
                  values={values}
                  counties={counties}
                  states={states}
                  handleStateSelect={handleStateSelect}
                  handleCountiesSelect={handleCountiesSelect(counties)}
                  handleAddCounties={handleAddCounties({ values, setValues: rest.setValues })}
                  handleDelete={handleDelete}
                  rideOptions={rideOptions || []}
                  disableAllFields={(isEmployee && isTCWorker) || isViewDashboardMode}
                  {...rest}
                />
                {role && !((isEmployee && isTCWorker) || isViewDashboardMode) && (
                  <div className={styles.btnPanel}>
                    <div className={styles.btnControl}>
                      <Button disabled={!rest.isValid || isViewDashboardMode} type={'submit'}>
                        Save Changes
                      </Button>
                    </div>
                  </div>
                )}
              </form>
            );
          }}
        </Formik>
      </div>
{isViewDashboardMode &&
      <div className={styles.pageSection}>
        <TCBillSegment
          key="TCPricing"
          Default_Value={TCPricing ? currentPricing : DEFAULT_TC_BILLING}
          onSubmitPricing={onSubmitPricing}
        />

        {openPricingValuesModal &&
          ConfirmChangePricingValuesModal({
            type: "transport company",
            handleConfirm: () => onConfirm(currentPricing),
            handleCancel: () => setOpenPricingValuesModal(false),
          })}
      </div>
}

      <div className={styles.pageSection}>
        <Formik
          validateOnMount
          enableReinitialize
          initialValues={
            TCPreferences && !(isEmployee && isTCWorker)
              ? {
                  ...TCPreferences.admin,
                }
              : {
                  firstName: user.firstName || '',
                  lastName: user.lastName || '',
                  phoneNumber: user.phoneNumber || '',
                  jobTitle: user.jobTitle || '',
                }
          }
          validationSchema={personalInformationSchema}
          onSubmit={({ firstName, lastName, phoneNumber, jobTitle }) => {
            updatePersonalInformation({ firstName, lastName, phoneNumber, jobTitle });
          }}>
          {({ values, isValid, handleSubmit }) => {
            return (
              <form className={styles.form} onSubmit={handleSubmit}>
                <PersonalInfoPreferences
                  values={values}
                  hideTimezoneField
                  disableAllFields={isViewDashboardMode}
                  allowScrollToPersonsalInfo={allowScrollToPersonalInfo}
                />
                {!isViewDashboardMode && (
                  <div className={styles.btnPanel}>
                    <div className={styles.btnControl}>
                      <Button disabled={!isValid || isViewDashboardMode} type='submit'>
                        Save Changes
                      </Button>
                    </div>
                  </div>
                )}
              </form>
            );
          }}
        </Formik>
      </div>
      <div>
        <h2>Password Information</h2>
        <Formik
          enableReinitialize
          validateOnMount
          initialValues={
            TCPreferences && !(isEmployee && isTCWorker)
              ? {
                  newEmail: TCPreferences?.admin.email || '',
                  password: '',
                }
              : { newEmail: user?.email || '', password: '' }
          }
          onSubmit={(values) => changeEmail(values)}
          validationSchema={changeEmailSchema(isEmployee && isTCWorker ? user?.email : TCPreferences?.admin?.email)}>
          {({ values, ...rest }) => {
            return (
              <form className={styles.form} onSubmit={rest.handleSubmit}>
                {user && (
                  <SecurityPreferencesEmail
                    values={values}
                    initialEmail={isEmployee && isTCWorker ? user?.email : TCPreferences?.admin?.email}
                    disableAllFields={isViewDashboardMode}
                    {...rest}
                  />
                )}
              </form>
            );
          }}
        </Formik>
        <Formik
          innerRef={securityRef}
          validateOnMount
          initialValues={{
            newPassword: '',
            oldPassword: '',
          }}
          validationSchema={updatePasswordSchema}
          onSubmit={(values) => {
            changePassword(values).then((res) => setTwoFactorModalVisible(res));
          }}>
          {({ values, ...rest }) => {
            return (
              <form className={styles.form} onSubmit={rest.handleSubmit}>
                <SecurityPreferencesPassword values={values} disableAllFields={isViewDashboardMode} {...rest} />
              </form>
            );
          }}
        </Formik>
      </div>
      {twoFactorModalVisible && TwoFactorAuthenticationModal}
    </div>
  );
};

const mapStateToProps = (state) => ({
  TCPreferences: state.transportOrganizations.TCPreferences,
  rideOptions: state.preferences.rideOptions?.filter((option) => !option.disabled),
  user: state?.authorization?.user,
  counties: state.common.counties,
  states: state.common.states,
  isViewDashboardMode: state?.authorization?.viewDashboardMode?.active,
  TCPricing: state.transportOrganizations.TCPricing,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchTOAdminPreferences,
      TOAdminUpdateOwnOrganization,
      updatePersonalInformation,
      changePassword,
      changeEmail,
      getAdditionalRideOptions,
      fetchStatesList,
      changePasswordVerification,
      postTransportCompanyPricing,
      fetchTransportCompanyPricing,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(TC_Preferences);
