import React, { useEffect } from 'react';
import { useParams } from 'react-router';

import { Redirect } from '../Redirect/Redirect';

export const Survey = () => {
  const { rideId } = useParams();

  useEffect(() => {
    if (rideId) window.location = `https://h0n8tjjl.paperform.co?ride_id=${rideId}`;
    return;
  }, [rideId]);
  return <Redirect />;
};
