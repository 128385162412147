import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { bindActionCreators } from "redux";

import { NavLink } from "react-router-dom";
import { InfoOutlined } from "@ant-design/icons";

import { BackIcon } from "../../../assets";

import {
  Button,
  Record, Tooltip,
  useCancelRideByHOModal
} from "../../../components";
import {
  getRide,
  rescheduleRide, clearCurrentRide,
  setShowRefreshNotification
} from "../../../store/rides";
import { fetchHOPreferences } from "../../../store/health_organizations";
import { getRideRequestOptions } from "../../../store/selectors/healthOrganizations";
import { DateHelper, LodashHelper, renderRecordValue } from "../../../utils";

import { STATUSES_STYLING } from "../../../constants/styles/statuses";
import {
  ADDITIONAL_DELAY_FOR_RIDE_CREATION,
  DEFAULT_TIME_FORMAT,

  URLS,
} from "../../../constants/constants";
import { renderEditLeaveModal } from "../EditDraft/renderEditLeaveModal";

import { usePreventLeave } from "../../../hooks/usePreventLeave";

import {
  getOtherLegSeqIdOfRoundtrip,

  renderPhoneNumber,
} from "../../../utils/helpers";
import {
  getAdditionalRideOptions,
  getPlatformSettings,
} from "../../../store/preferences";

import { destroyNotification } from "../../../components/common/ConfirmPopUp/ConfirmPopUp";


import styles from "./index.module.css";
import { Navigation } from "../../../utils/navigation";
import { RescheduleRideForm } from "../../../components/forms/CreateRide/StepThree/RescheduleRideForm";
import { ConfirmRescheduleRideModal } from "../../../components/modals/ConfirmRescheduleRideModal/ConfirmRescheduleRideModal";
import { defaultTimeValues } from "../../../components/forms/Preferences/MHPreferences/partials/defaultValues";

const RescheduleRide = ({
  hoCancelRide,
  getRide,
  ride,
  rescheduleRide,
  platformPreferences,
  fetchHOPreferences,
  getAdditionalRideOptions,
  getPlatformSettings,
  setShowRefreshNotification,
  clearCurrentRide,
  role,
}) => {
  const [editSeriesOption, setEditSeriesOption] = useState(null);
  const navigate = Navigation();
  const [openModal, setOpenModal] = useState(false);
  const { id } = useParams();

  const draft = useMemo(() => {
    const roundtripRide = {

      rescheduledDate: ride?.pickUpDate,
      isRoundTrip: ride?.isRoundTrip,
      connectedLegId: ride?.connectedLegId,
      connectedLegRescheduleDate: ride?.connectedLeg?.pickUpDate
    };

    return roundtripRide;

  }, [ride]);

  useEffect(
    () => () => {
      clearCurrentRide();
      setShowRefreshNotification(false);
      destroyNotification();
    },
    [clearCurrentRide, setShowRefreshNotification]
  );

  const handleSaveChanges = () => {
    setOpenModal(true);
  };





  const [formikValues, setFormikValues] = useState(draft);

  const [formIsValid, setFormikIsValid] = useState(null);

  const [visibleModal, setModalVisible, unblockHistory, location] =
    usePreventLeave(draft, formikValues);

  const handleStay = () => {
    setModalVisible(false);
  };

  const handleLeave = () => {
    if (unblockHistory) {
      unblockHistory.current();
      navigate.push(location.location);
    }
  };

  const onConfirm = (values) => {
    //Call API

    
    rescheduleRide({rideId:id,payload:formikValues?.values});
    setOpenModal(false);

  }

  const handleConfirmCancel = (values) => {
    setCancelModalVisible(false);
    hoCancelRide({ rideId: id, payload: values, userRole: role }).then(() => handleLeave());
  };

  const { Modal, setCancelModalVisible } = useCancelRideByHOModal({
    id: ride?.seqId,
    role,
    ride,
    onConfirm: (values) => handleConfirmCancel(values),
  });

  const handleToggleModal = useCallback(
    (flag) => () => setCancelModalVisible(flag),
    [setCancelModalVisible]
  );

  useEffect(() => {
    fetchHOPreferences();
    return;
  }, [fetchHOPreferences]);

  useEffect(() => {
    getAdditionalRideOptions();
    return;
  }, [getAdditionalRideOptions]);

  useEffect(() => {
    getRide(id, navigate);
    return;
  }, [getRide, id]);

  useEffect(() => {
    getPlatformSettings();
    return;
  }, [getPlatformSettings]);

  useEffect(() => {
    const query = new URLSearchParams(window?.location?.search);
    setEditSeriesOption(query.get("mode"));
    return;
  }, [id]);

  const handleBack = () => navigate.push(URLS.RIDES);

  const saveChangesButtonIsDisabled = useMemo(() => {


    return (
      LodashHelper.isEqual(draft, formikValues) ||
      !formIsValid?.isValid
    );
  }, [draft, formikValues, formIsValid]);



  const syncFormikValues = useCallback(
    (values, isValid) => {


      if (!LodashHelper.isEqual(formikValues, values)) {
        setFormikValues((prevValues) => {
          return LodashHelper.removeEmpty({
            ...prevValues,
            values,
          });
        });

        setFormikIsValid((prevValues) => {
          return {
            ...prevValues,
            isValid,
          };
        });
      }





    },

    []
  );



  return (
    <div className={styles.pageContent}>
      <div className={styles.header}>
        <div className={styles.backBtn} onClick={handleBack}>
          <BackIcon width="18px" height="18px" fill="#BDBDBD" />
        </div>
        <h1 className={styles.pageTitle}>Reschedule Ride - ID {ride?.seqId}</h1>

      </div>
      <div className={styles.editRide}>
        {ride?.isRoundtrip && ride?.connectedLegId && (
          <p className={styles.otherLeg}>
            Other leg of the ride –{" "}
            <NavLink
              className={styles.link}
              to={`${URLS.RIDES}/${ride?.connectedLegId}`}
            >
              ID {getOtherLegSeqIdOfRoundtrip(ride?.seqId, ride?.leg)}
            </NavLink>
          </p>
        )}
        {ride?.driver && (
          <p>
            Matched with driver
            <span
              className={STATUSES_STYLING[ride?.readableStatus]}
            >{` ${renderRecordValue(
              ride?.driver?.firstName
            )} ${renderRecordValue(ride?.driver?.lastName)} `}</span>
            <a
              href={`mailto:${ride?.driver?.email}`}
              className={styles.driverEmail}
            >
              {ride?.driver?.email}
            </a>
            {ride?.driver?.phoneNumber && (
              <span className={STATUSES_STYLING[ride?.readableStatus]}>
                {renderPhoneNumber(ride?.driver?.phoneNumber)}
              </span>
            )}
          </p>
        )}
        <Record
          title="Status"
          value={renderRecordValue(ride?.readableStatus)}
          valueClassName={STATUSES_STYLING[ride?.readableStatus]}
        />
        <Record
          title="Request Created"
          value={renderRecordValue(
            ride?.createdAt &&
            DateHelper.format(new Date(ride?.createdAt), DEFAULT_TIME_FORMAT)
          )}
          myClassName={styles.createdAt}
        />
        <Record
          title="Created By"
          value={`${renderRecordValue(
            ride?.createdBy?.firstName
          )} ${renderRecordValue(ride?.createdBy?.lastName)}`}
        />
        {ride && (
          <div className={styles.editForm}>

            <div className={styles.formBlock}>
              <h2>Rider Information</h2>
           </div>
            <div className={styles.formBlock}>
              <RescheduleRideForm
                minDelayToStartRide={
                              platformPreferences?.minTimeFromRideRequestToPickup[ride?.vehicleType] &&
                              DateHelper.addHours(
                                platformPreferences?.minTimeFromRideRequestToPickup[ride?.vehicleType],
                                ADDITIONAL_DELAY_FOR_RIDE_CREATION,
                                ride?.needsCarSeat ? platformPreferences?.carBoosterSeatBufferTime : defaultTimeValues
                              )
                            }
              setFormikValues={syncFormikValues} values={ride} />
            </div>
            <div className={styles.formBlock}>
            </div>
            {openModal &&
              ConfirmRescheduleRideModal({
                rideValues: formikValues?.values,
                ride,
                handleConfirm: () => onConfirm(),
                handleCancel: () => setOpenModal(false)
              })}
            <p className={styles.sendDescription}>
              After pressing "Reschedule" button, this request will be sent to
              our network of transportation providers
            </p>
            <div className={styles.btns}>
              <div className={styles.buttonControl}>
                <Button myClassName={styles.navigationBtn} onClick={handleBack}>
                  Keep Ride
                </Button>
              </div>
              <div className={styles.buttonControl}>
                <Button
                  disabled={saveChangesButtonIsDisabled}
                  onClick={handleSaveChanges}
                  type="button"
                > Reschedule
                </Button>
                {ride?.isRoundtrip && (
                  <div className={styles.toolTip}>
                    <Tooltip
                      title={
                        <span className={styles.tooltipContent}>
                          Users can reschedule the ride 24 hours before 
                          scheduled pickup time with no charge
                        </span>
                      }
                    >
                      <span className={styles.tooltipInfo}>
                        <InfoOutlined className={styles.infoIcon} />
                      </span>
                    </Tooltip>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        {Modal}
      </div>

      {renderEditLeaveModal({
        visibleModal,
        handleStay,
        handleLeave,
      })}
    </div>
  );
};

const mapStateToProps = (state) => ({
  ride: state.rides.ride,
  role: state.authorization.role,
  rideRequestFields: getRideRequestOptions(state),
  hasMatchedTC: state.rides.hasMatchedTC,
  rideOptions: state.preferences.rideOptions?.filter(
    (option) => !option.disabled
  ),
  platformPreferences: state.preferences.platformPreferences,
  airportBufferTime: state?.healthOrganizations?.HOPreferences?.airportBufferTime
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {

      getRide,
      rescheduleRide,
      fetchHOPreferences,
      getAdditionalRideOptions,
      getPlatformSettings,
      setShowRefreshNotification,
      clearCurrentRide,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(RescheduleRide);
