import { Input } from "../..";
import classNames from "classnames";
import styles from "./index.module.css";
import React, { memo } from "react";
import PropTypes from 'prop-types';
export const CustomLabels = (labels)=> (
    <div className={styles.vehicles} key={'vc-1'}>
      {labels?.map((lb, i) => (
        <label className={classNames({
          [styles.numberField]: true,
          [styles.fontWeight_500]: true,
        })} key={`ctLbl${i}`}>{lb}</label>
      ))}
    </div>)

const FleetSize = memo(({values, name, isFieldDisabled}) => {
  const totalItems = values?.length;
   
  return (
    <>
    {CustomLabels(["# Vehicles","# Drivers","Type"])}
    
    {values?.map((option, index) => { 
   
      return(
      
      <div className={styles.vehicles} key={`vc${index}`}>
        <label className={classNames({
            [styles.numberField]: true,
            [styles.lineHeight_60]: (totalItems === (index + 1)),
          })} key={`l${index}`}>
            {(totalItems === (index + 1)) &&
              <span className={
                classNames({[styles.fontWeight_500]:true,
                            [styles.fleetTitle]:true})}>{'Total Vehicles'}</span>}
          <Input
            key={`l_Option${option.id}${index}`}
            name={`${name}.${option.id}.vehicles`}
            type="number"
            min={0}
            max={200}
            disabled={isFieldDisabled}
            styles={{marginTop: '12px'}}
          />
        </label>
        <label className={classNames({
            [styles.numberField]: true,
            [styles.lineHeight_60]: (totalItems === (index + 1)),
          })} key={`ll${index}`}>
          {(totalItems === (index + 1)) && 
            <span className={
              classNames({[styles.fontWeight_500]:true,
                          [styles.fleetTitle]:true})}>
                            {'Total Drivers'}</span>}
          <Input
            key={`ll_Option${option.id}${index}`}
            name={`${name}.${option.id}.drivers`}
            type="number"
            min={0}
            max={200}
            disabled={isFieldDisabled}
          />
        </label>
        {
          <label className={styles.nameLabel} key={`lll${index}`}>
            <span>{(totalItems !== (index + 1)) && option.title}</span>
          </label>}
      </div>
    )})}
  </>
  );
});

FleetSize.propTypes = {
  
  values:PropTypes.array,
  name: PropTypes.string,
  isFieldDisabled: PropTypes.bool
};

export default FleetSize

