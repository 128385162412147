import React from "react";
import { Record } from "../RideDetails";
import {
  distanceInMetersToMiles,
  formatDuration,
} from "../../../../utils/helpers";
import { InfoOutlined } from "@ant-design/icons";
import styles from "../index.module.css";
import {
  RIDE_LOGISTICS_TOOLTIP_CONTENT,
  RIDE_STATUSES,
} from "../../../../constants/constants";
import { Tooltip } from "../../..";

export const TimeDurationInfo = ({ values, isTCWorker, isMHAdmin,tcConfiguration }) => {
  return (
    <>
      {values?.estMileage && values?.estDuration && (
        <div className={styles.rideDetails}>
          <div className={styles.driverLabelContainer}>
            <span className={styles.driverLabelText}>Ride Logistics:</span>
            <div className={styles.tooltipWrapper}>
              <Tooltip
                title={
                  <span className={styles.tooltipContent}>
                    {RIDE_LOGISTICS_TOOLTIP_CONTENT}
                  </span>
                }
              >
                <span className={styles.tooltipInfo}>
                  <InfoOutlined className={styles.infoIcon} />
                </span>
              </Tooltip>
            </div>
          </div>
          <Record
            myClassName={styles.mtb5}
            title="Estimated Ride Duration"
            value={formatDuration(values?.estDuration)}
          />
          <Record
            myClassName={styles.mtb5}
            title="Estimated Ride Mileage"
            value={distanceInMetersToMiles(values?.estMileage)}
          />
          {values?.status === RIDE_STATUSES.COMPLETED && (
            <Record
              myClassName={styles.mtb5}
              title="Actual Ride Mileage"
              value={values?.actMileage || 0 + " miles"}
            />
          )}

          {((isTCWorker && tcConfiguration.tcConfiguration?.deadheadMileageConfigure) || isMHAdmin) &&
            values?.status === RIDE_STATUSES.COMPLETED &&
            values?.deadheadMileage && (
              <Record
                myClassName={styles.mtb5}
                title="Deadhead Mileage"
                value={values?.deadheadMileage || 0 + " miles"}
              />
            )}
        </div>
      )}
    </>
  );
};
