import React, { useEffect, useRef, useState } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  endOfDay,
  endOfMonth,
  endOfToday,
  endOfWeek,
  startOfDay,
  startOfMonth,
  startOfToday,
  startOfWeek,
} from 'date-fns';
import { CalendarOutlined } from '@ant-design/icons';

import { RangePicker } from '../../../../';
import { FilterDropDown } from '../../FilterDropdown/FilterDropdown';
import { Button } from '../../../../';
import { DATE_RANGE_FILTER, NEXT_DAY } from '../../../../../constants/constants';

import styles from './index.module.css';

export const renderExtraFooter =
  ({ handlePickDate, disabled }) =>
  () =>
    (
      <div className={styles.datepickerFooter}>
        <span>Pick a day or a range</span>
        <Button
          myClassName={classNames({ [styles.footerBtn]: true, [styles.btn]: true })}
          onClick={handlePickDate}
          disabled={disabled}>
          Pick
        </Button>
      </div>
    );

export const RangeDropDown = ({ Component, setFieldValue, placeholder, name, values, ...rest }) => {
  const [visible, setVisible] = useState(false);
  const [showDateRange, setShowDateRange] = useState(false);

  const [dateRange, setDateRange] = useState({});

  const defaultOptions = useRef([
    {
      title: 'Show all',
      date: 'not specified',
    },
    {
      title: 'Today',
      date: {
        range: DATE_RANGE_FILTER.TODAY,
        from: startOfToday().getTime(),
        to: endOfToday().getTime(),
      },
    },
    {
      title: 'This week',
      date: {
        range: DATE_RANGE_FILTER.THIS_WEEK,
        from: startOfWeek(new Date()).getTime(),
        to: endOfWeek(new Date()).getTime(),
      },
    },
    {
      title: 'This month',
      date: {
        range: DATE_RANGE_FILTER.THIS_MONTH,
        from: startOfMonth(new Date()).getTime(),
        to: endOfMonth(new Date()).getTime(),
      },
    },
    {
      name: 'dateRange',
      title: (
        <>
          Specific date range <CalendarOutlined className={styles.calendarIcon} />
        </>
      ),
      date: {
        from: '',
        to: '',
      },
    },
  ]);
  const [options, setOptions] = useState(defaultOptions.current);
  const handlePickOption = (option) => () => {
    if (option.name === 'dateRange') {
      setShowDateRange(true);
    
      return setOptions([]);
    } else {
      setVisible(false);
    }
    option.date === 'not specified' ? setFieldValue(name, '') && setDateRange({}) : setFieldValue(name, option.date);
  };

  const handlePickDate = async () => {
    await setShowDateRange(false);
    await setVisible(false);
    await setFieldValue(name, {
      from: dateRange.from,
      to: dateRange.to,
    });
  };

  useEffect(()=>() => {
   

    !values?.[name] && setDateRange({});
    
  }, [name, values, setDateRange]);

  const handleChangeRangePicker = ({ name, value }) => {
    
    setDateRange({
      from: startOfDay(value[0]).getTime(),
      to: endOfDay(value[1] ? value[1]: (new Date(value[0]+NEXT_DAY))).getTime(),
    });
   
  };

  useEffect(()=>() => {
    setShowDateRange(visible);
    setOptions(defaultOptions.current);
    
  }, [visible]);

  const FilterDropDownContent = (
    <div className={styles.dropdownContent}>
      {showDateRange ? (
        <RangePicker
          open={showDateRange}
          name={name}
          value={[dateRange.from, dateRange.to]}
          onChange={handleChangeRangePicker}
          onSelect={handleChangeRangePicker}
          placeholder={placeholder}
          myClassName={styles.styledRangePicker}
          renderExtraFooter={renderExtraFooter({ handlePickDate, disabled: !dateRange.from })}
          placement={'bottomRight'}
          getPopupContainer={() => document.querySelector('.ant-table')}
        />
      ) : (
        <ul className={styles.dropdownList}>
          {options.map((o) => (
            <li className={styles.dropdownListItem} onClick={handlePickOption(o)} key={o.title}>
              {o.title}
            </li>
          ))}
        </ul>
      )}
    </div>
  );

  return (
    <th className='ant-table-cell'>
      <FilterDropDown
        visible={visible}
        applyStyles
        showDateRange={showDateRange}
        content={FilterDropDownContent}
        onVisibleChange={setVisible}
        setFieldValue={setFieldValue}
        name={name}
        values={values}
        {...rest}>
        <Component visible={visible} />
      </FilterDropDown>
    </th>
  );
};

RangeDropDown.propTypes = {
  Component: PropTypes.elementType,
  setFieldValue: PropTypes.func,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  values: PropTypes.object,
};
