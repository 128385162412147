import { CANCELLATION_TYPE_BY_ROLE } from "../../../constants/constants";

 
 

export const RIDE_REQUEST_CHANGE_STATUSES = {

    RIDER_NO_SHOW: "RIDER_NO_SHOW",
    SAME_DAY_CANCELLATION: "SAME_DAY_CANCELLATION",
    COMPLETED: "COMPLETED",

};

export const requestChangeStatusesDictionary = {
    [RIDE_REQUEST_CHANGE_STATUSES.COMPLETED]: 'Completed',
    [RIDE_REQUEST_CHANGE_STATUSES.SAME_DAY_CANCELLATION]: 'Same day cancellation',
    [RIDE_REQUEST_CHANGE_STATUSES.RIDER_NO_SHOW]: 'Rider no show',
};


export const RIDE_REQUEST_CHANGE_CANCEL_OPTIONS = {
    [RIDE_REQUEST_CHANGE_STATUSES.RIDER_NO_SHOW]: [
        {
            title: "Incorrect ride details.", value: "Incorrect ride details.",
            cancellationType: CANCELLATION_TYPE_BY_ROLE.riderNoShow, additionalComments: ''
        },
        {
            title: "Rider declined ride after driver started or arrived.", value: "Rider declined ride after driver started or arrived.",
            cancellationType: CANCELLATION_TYPE_BY_ROLE.riderNoShow, additionalComments: ''
        },
        {
            title: "Rider has alternate transportation.",
            value: "Rider has alternate transportation.",
            cancellationType: CANCELLATION_TYPE_BY_ROLE.riderNoShow, additionalComments: ''
        },
        {
            title:
                "Rider needed accommodations that were not indicated in the request and driver is unable to facilitate.",
            value:
                "Rider needed accommodations that were not indicated in the request and driver is unable to facilitate.",

            cancellationType: CANCELLATION_TYPE_BY_ROLE.riderNoShow, additionalComments: ''
        },
        {
            title: "Rider was not on scene.", value: "Rider was not on scene.",
            cancellationType: CANCELLATION_TYPE_BY_ROLE.riderNoShow, additionalComments: ''
        },
        {
            title: "Rider was undergoing a medical emergency.",
            value: "Rider was undergoing a medical emergency.",
            cancellationType: CANCELLATION_TYPE_BY_ROLE.riderNoShow, additionalComments: ''
        }

    ],
    [RIDE_REQUEST_CHANGE_STATUSES.SAME_DAY_CANCELLATION]: [

        {
            title: "Rider's appointment cancelled.",
            value: "Rider's appointment cancelled.",
            cancellationType: CANCELLATION_TYPE_BY_ROLE.cancelByHO, additionalComments: ''
        },
        {
            title: "Rider's appointment rescheduled.",
            value: "Rider's appointment rescheduled.",
            cancellationType: CANCELLATION_TYPE_BY_ROLE.cancelByHO, additionalComments: ''
        },

        {
            title: "Rider has alternate transportation.",
            value: "Rider has alternate transportation.",
            cancellationType: CANCELLATION_TYPE_BY_ROLE.cancelByHO, additionalComments: ''
        },
        {
            title: "Rider has conflict and unable to make appointment.",
            value: "Rider has conflict and unable to make appointment.",
            cancellationType: CANCELLATION_TYPE_BY_ROLE.cancelByHO, additionalComments: ''
        },
        {
            title: "Rider was undergoing a medical emergency.",
            value: "Rider was undergoing a medical emergency.",
            cancellationType: CANCELLATION_TYPE_BY_ROLE.cancelByHO, additionalComments: ''
        },



    ]

 

};

export const allowedStatusesForRequestChangeOptions = [
    {
        title: requestChangeStatusesDictionary[RIDE_REQUEST_CHANGE_STATUSES.COMPLETED],
        value: RIDE_REQUEST_CHANGE_STATUSES.COMPLETED,
    },

    {
        title: requestChangeStatusesDictionary[RIDE_REQUEST_CHANGE_STATUSES.RIDER_NO_SHOW],
        value: RIDE_REQUEST_CHANGE_STATUSES.RIDER_NO_SHOW,
    },
    {
        title: requestChangeStatusesDictionary[RIDE_REQUEST_CHANGE_STATUSES.SAME_DAY_CANCELLATION],
        value: RIDE_REQUEST_CHANGE_STATUSES.SAME_DAY_CANCELLATION,
    },

];
