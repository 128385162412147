import { useCallback, useMemo } from "react";
import classNames from "classnames";
import { FieldArray } from "formik";

import {
  Checkbox,
  DatePicker,
  GooglePlacesAutoComplete,
  Input,
  MaskInput,
  Select,
  Snackbar,
} from "../..";
import {
  COMMUNICATION_TYPES,
  DEFAULT_PHONE_NUMBER_FORMAT,
  IMPAIRMENTS_TYPES,
  INVALID_ADDRESS_MESSAGE,
  languages,
} from "../../../constants/constants";
import {
  DateHelper,
  GoogleAddressHelper,
  renderRecordValue,
} from "../../../utils";
import { communicationsOptions, genderOptions } from "../mock";
import { useCheckForUniqueRider } from "../../../hooks/useCheckForUniqueRider";
import { MatchedRidersSnackBar } from "./MatchedRidersSnackBar";
import { MatchedExactRiderSnackBar } from "./MatchedExactRiderSnackBar";

import styles from "./index.module.css";
import RenderHeightInputFields from "../../formControls/Input/HeightInputFields";

export const RiderFieldNames = {
  firstName: "firstName",
  lastName: "lastName",
  dob: "dob",
  patientUniqueId: "patientUniqueId",
  communicationPreferences: "communicationPreferences",
  preferredLanguage: "preferredLanguage",
  phoneNumber: "phoneNumber",
  canPhoneReceiveTexts: "canPhoneReceiveTexts",
  canPhoneReceiveCalls: "canPhoneReceiveCalls",
  homePhoneNumber: "homePhoneNumber",
  email: "email",
  impairments: "impairments",
  otherImpairments: "otherImpairments",
  homeAddress: "homeAddress.formattedAddress",
  gender: "gender",
  weight: "weight",
  height: "height",
};

const renderCheckboxGroup =
  (values, disableRiderInfo, showDisabledTooltip, fastField) =>
  (arrayHelpers) => {
    const handleCheckbox =
      (option) =>
      ({ checked, setValues }) => {
        if (checked) arrayHelpers.push(option);
        else {
          setValues({
            ...values,
            impairments: values.impairments.filter((value) => value !== option),
          });
        }
      };
    return IMPAIRMENTS_TYPES.map((type) => (
      <div className={styles.checkboxContainer} key={type.value}>
        <label className={styles.checkbox}>
          <Checkbox
            fastField={fastField}
            name="impairments"
            checked={
              values &&
              values?.impairments &&
              values?.impairments?.includes(type.value)
            }
            value={type.value}
            onChange={handleCheckbox(type.value)}
            disabled={disableRiderInfo}
            whenFieldDisabledShowTooltip={showDisabledTooltip}
          />
          <span className={styles.checkboxLabel}>{type.title}</span>
        </label>
      </div>
    ));
  };

export const CreateRiderForm = ({
  disabledValues,
  disableRiderInfo,
  values,
  touched,
  errors,
  riderIsUnique = true,
  fastField,
  fieldNames = RiderFieldNames,
  allowUniqueCheck,
  showFullForm = true,
  riderIsPopulated,
  hasMatchedRiders,
  riders,
  handleSubmitUseExistingRider,
  handleCancelUseExistingRider,
  handleUseExactRider,
}) => {
  const handleSelectAddress = async ({ value, values, setValues }) => {
    setValues({
      ...values,
      homeAddress: GoogleAddressHelper.transformFields(value),
    });
  };

  const handleCommunicationPreferences = async ({
    name,
    value,
    setFieldValue,
    validateForm,
  }) => {
    if (value?.includes(COMMUNICATION_TYPES.TEXT_SMS)) {
      await setFieldValue("canPhoneReceiveTexts", true);
    }

    if (value?.includes(COMMUNICATION_TYPES.PHONE_CALL)) {
      await setFieldValue("canPhoneReceiveCalls", true);
    }

    await setFieldValue(name, value);
    await validateForm();
  };

  const handleChange = async ({ name, value, setFieldValue, validateForm }) => {
    await setFieldValue(name, value);
    await validateForm();
  };

  const handleCheckBoxChange = async ({
    name,
    checked,
    setFieldValue,
    validateForm,
  }) => {
    await setFieldValue(name, checked);
    //await validateForm();
  };

  const handleChangeDate = async ({
    name,
    value,
    dateString,
    setFieldValue,
    validateForm,
  }) => {
    if (values?.firstName && values?.lastName && handleCancelUseExistingRider) {
      handleCancelUseExistingRider();
    }
    if (!value) {
      await setFieldValue(name, "");
    } else if (dateString && value)
      await setFieldValue(
        name,
        DateHelper.format(new Date(dateString), "yyyy/MM/dd")
      );

    await validateForm();
  };

  const handleChangeAddress = async ({ value, values, setValues }) => {
    if (!value) {
      await setValues({
        ...values,
        homeAddress: undefined,
      });
    } else
      await setValues({
        ...values,
        homeAddress: {
          formattedAddress: value,
        },
      });
  };

  const dobConvertedValue = useMemo(
    () =>
      values?.dob &&
      new Date(DateHelper.format(new Date(values?.dob), "MM/dd/yyyy")),
    [values?.dob]
  );

  const disableFutureDatesForDatePicker = useCallback(
    (current) => current && current.valueOf() > Date.now(),
    []
  );

  const isDatePickerDisabled = disableRiderInfo || riderIsPopulated;

  useCheckForUniqueRider(values, allowUniqueCheck);

  return (
    <>
      <div className={styles.row}>
        <label className={styles.personalInfo}>
          <span className={styles.required}>Rider's First Name</span>
          <Input
            fastField={fastField}
            name={fieldNames.firstName}
            onChange={handleChange}
            value={values?.firstName || ""}
            type="text"
            placeholder="First Name"
            suffix={false}
            disabled={disableRiderInfo || riderIsPopulated}
            whenFieldDisabledShowTooltip={Boolean(disabledValues?.riderInfo)}
          />
        </label>

        <label className={styles.personalInfo}>
          <span className={styles.required}>Rider's Last Name</span>
          <Input
            fastField={fastField}
            name={fieldNames.lastName}
            onChange={handleChange}
            value={renderRecordValue(values?.lastName, "")}
            type="text"
            placeholder="Last Name"
            suffix={false}
            disabled={disableRiderInfo || riderIsPopulated}
            whenFieldDisabledShowTooltip={Boolean(disabledValues?.riderInfo)}
          />
        </label>
      </div>

      {!riderIsUnique && (
        <div className={styles.snackbarField}>
          <Snackbar
            type="warning"
            title="Such Rider already exists"
            content="You are trying to create Rider that already exists"
          />
        </div>
      )}
      {hasMatchedRiders &&
      values?.firstName &&
      values?.lastName &&
      values?.dob ? (
        <MatchedExactRiderSnackBar handleUseExactRider={handleUseExactRider} />
      ) : (
        hasMatchedRiders && (
          <MatchedRidersSnackBar
            riders={riders}
            handleSubmitUseExistingRider={handleSubmitUseExistingRider}
            handleCancelUseExistingRider={handleCancelUseExistingRider}
          />
        )
      )}
      {
        <div className={styles.row}>
          <label className={styles.communicationInfo}>
            <span>Communications Preferences</span>
            <Select
              fastField={fastField}
              onChange={handleCommunicationPreferences}
              mode="multiple"
              name={fieldNames.communicationPreferences}
              value={values?.communicationPreferences || []}
              options={communicationsOptions}
              placeholder="Communications Preferences"
              disabled={disableRiderInfo || riderIsPopulated}
              whenFieldDisabledShowTooltip={Boolean(disabledValues?.riderInfo)}
            />
          </label>

          <label className={styles.communicationInfo}>
            <span>Preferred Language</span>
            <Select
              fastField={fastField}
              onChange={handleChange}
              showSearch
              name={fieldNames.preferredLanguage}
              value={values?.preferredLanguage}
              options={languages}
              placeholder="Preferred Language"
              disabled={disableRiderInfo || riderIsPopulated}
              whenFieldDisabledShowTooltip={Boolean(disabledValues?.riderInfo)}
            />
          </label>
        </div>
      }
      <label className={styles.phoneInfo}>
        <span className={styles.required}>Mobile Phone Number</span>
        <MaskInput
          fastField={fastField}
          onChange={handleChange}
          name={fieldNames.phoneNumber}
          value={values?.phoneNumber}
          format={DEFAULT_PHONE_NUMBER_FORMAT}
          placeholder={"###-###-####"}
          myClassName={styles.phoneInput}
          disabled={disableRiderInfo}
          whenFieldDisabledShowTooltip={Boolean(disabledValues?.riderInfo)}
        />
      </label>
      <div className={styles.phoneCheckboxes}>
        <label className={styles.textCheckbox}>
          <Checkbox
            fastField={fastField}
            onChange={handleCheckBoxChange}
            name={fieldNames.canPhoneReceiveTexts}
            checked={values?.canPhoneReceiveTexts}
            disabled={disableRiderInfo}
            whenFieldDisabledShowTooltip={Boolean(disabledValues?.riderInfo)}
          />
          <span className={styles.checkboxLabel}>
            This phone can receive texts and recipient consents to this form of
            communication
          </span>
        </label>
        <label className={styles.callsCheckbox}>
          <Checkbox
            name={fieldNames.canPhoneReceiveCalls}
            onChange={handleCheckBoxChange}
            checked={values?.canPhoneReceiveCalls}
            disabled={disableRiderInfo}
            whenFieldDisabledShowTooltip={Boolean(disabledValues?.riderInfo)}
          />
          <span className={styles.checkboxLabel}>
            This phone can receive calls and recipient consents to this form of
            communication
          </span>
        </label>
      </div>
      <div className={styles.birthDate}>
        <label className={styles.birthInfo}>
          <span className={styles.required}>Rider's Date of Birth</span>
          <DatePicker
            fastField={fastField}
            name={fieldNames.dob}
            format="MM/DD/Y"
            placeholder="Select date (MMDDYYYY)"
            value={dobConvertedValue}
            onChange={handleChangeDate}
            disabledDate={disableFutureDatesForDatePicker}
            disabled={isDatePickerDisabled}
            inputMaxLength={10}
            whenFieldDisabledShowTooltip={Boolean(disabledValues?.riderInfo)}
            allowClear
            getPopupContainer={(trigger) => trigger}
          />
        </label>

        <span>Rider's Unique Identifier - Example: Medical Record Number</span>
        <label className={styles.patientUniqueIdLabel}>
          <Input
            fastField={fastField}
            name={fieldNames.patientUniqueId}
            onChange={handleChange}
            value={values?.patientUniqueId || ""}
            type="text"
            placeholder="Rider's Unique Identifier"
            suffix={false}
            disabled={disableRiderInfo || riderIsPopulated}
            whenFieldDisabledShowTooltip={Boolean(disabledValues?.riderInfo)}
          />
        </label>
      </div>

      {showFullForm && (
        <>
          <label className={styles.phoneInfo}>
            <span>Secondary Phone Number</span>
            <MaskInput
              fastField={fastField}
              onChange={handleChange}
              name={fieldNames.homePhoneNumber}
              value={values?.homePhoneNumber}
              format={DEFAULT_PHONE_NUMBER_FORMAT}
              placeholder={"###-###-####"}
              myClassName={styles.phoneInput}
              disabled={disableRiderInfo}
              whenFieldDisabledShowTooltip={Boolean(disabledValues?.riderInfo)}
            />
          </label>
          <label className={styles.emailInfo}>
            <span
              className={classNames({
                [styles.required]: values?.communicationPreferences?.includes(
                  COMMUNICATION_TYPES.EMAIL
                ),
              })}
            >
              Rider's Email
            </span>
            <Input
              fastField={fastField}
              onChange={handleChange}
              name={fieldNames.email}
              value={values?.email || ""}
              type="text"
              placeholder="Email"
              suffix={false}
              disabled={disableRiderInfo}
              whenFieldDisabledShowTooltip={Boolean(disabledValues?.riderInfo)}
            />
          </label>
          <div className={styles.impairments}>
            <span
              className={classNames({
                [styles.impairmentHeader]: true,
              })}
            >
              Rider's Impairments
            </span>
            <div className={styles.checkboxesRow}>
              <FieldArray
                name={fieldNames.impairments}
                render={renderCheckboxGroup(
                  values,
                  disableRiderInfo,
                  disabledValues?.impairments,
                  fastField
                )}
              />
            </div>

            <label className={styles.otherImpairment}>
              <span>Other Impairment</span>
              <Input
                fastField={fastField}
                name={fieldNames.otherImpairments}
                value={values?.otherImpairments || ""}
                type="text"
                placeholder="Please, specify here"
                disabled={disableRiderInfo}
                whenFieldDisabledShowTooltip={Boolean(
                  disabledValues?.riderInfo
                )}
              />
            </label>
          </div>
          <label className={styles.homeAddress}>
            <span>Home Address</span>
            <GooglePlacesAutoComplete
              name={fieldNames.homeAddress}
              value={values?.homeAddress?.formattedAddress || ""}
              handleSelectAddress={handleSelectAddress}
              handleChangeAddress={handleChangeAddress}
              disabled={disableRiderInfo}
              whenFieldDisabledShowTooltip={Boolean(disabledValues?.riderInfo)}
            />
            {touched &&
              touched["homeAddress.formattedAddress"] &&
              errors &&
              errors.homeAddress && (
                <div className={styles.errorMessage}>
                  {INVALID_ADDRESS_MESSAGE}
                </div>
              )}
          </label>
          <label className={styles.genderInfo}>
            <span>Gender</span>
            <Select
              fastField={fastField}
              value={values?.gender || ""}
              name={fieldNames.gender}
              options={genderOptions}
              placeholder="Gender"
              disabled={disableRiderInfo}
              whenFieldDisabledShowTooltip={Boolean(disabledValues?.riderInfo)}
            />
          </label>
          <div className={styles.weight_height}>
            <label className={styles.numberField}>
              <span>Weight, lb(s)</span>

              <label>
                <Input
                  fastField={fastField}
                  name={fieldNames.weight}
                  value={values?.weight || ""}
                  type="number"
                  min={0}
                  placeholder="Weight"
                  disabled={disableRiderInfo}
                  whenFieldDisabledShowTooltip={Boolean(
                    disabledValues?.riderInfo
                  )}
                />
              </label>
            </label>

            <div className={styles.ride_height}>
              <RenderHeightInputFields
                requiredClass={false}
                fieldName={fieldNames.height}
                values={values?.height || ""}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};
