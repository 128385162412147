import React, { useRef } from 'react';
import { Layout as AntdLayout } from 'antd';
import classnames from 'classnames';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as workerTimers from 'worker-timers';

import { Header, Sidebar, Loader, ErrorBoundary, IdleTimer } from '../../';
import { getLoading } from '../../../store/selectors/common';
import {
  MODAL_AUTO_CLOSE_AFTER_SEC,
  MODAL_WILL_APPEAR_AFTER_MS,
  MODAL_WILL_AUTO_CLOSE_AFTER_MS,
  USER_ROLES,
} from '../../../constants/constants';

import styles from './index.module.css';
import { Outlet } from 'react-router-dom';

const Layout = ({  role, user, loading }) => {
  const idleTimerRef = useRef();
  const logoutTimerRef = useRef();
  return (
    <AntdLayout className={styles.layout}>
      {role !== USER_ROLES.DRIVER && user?.firstName && (
        <IdleTimer
          workerTimers={workerTimers}
          idleTimerRef={idleTimerRef}
          logoutTimerRef={logoutTimerRef}
          MODAL_WILL_APPEAR_AFTER_MS={MODAL_WILL_APPEAR_AFTER_MS}
          MODAL_WILL_AUTO_CLOSE_AFTER_MS={MODAL_WILL_AUTO_CLOSE_AFTER_MS}
          MODAL_AUTO_CLOSE_AFTER_SEC={MODAL_AUTO_CLOSE_AFTER_SEC}
        />
      )}
      <Header role={role} user={user} />
      <div className={styles.main}>
        {role !== USER_ROLES.DRIVER && <Sidebar role={role} />}
        <div
          className={classnames({
            [styles.pageContent]: role !== USER_ROLES.DRIVER,
            [styles.driverContent]: role === USER_ROLES.DRIVER,
          })}>
          <ErrorBoundary> <Outlet /></ErrorBoundary>
        </div>
      </div>
      <Loader loading={loading} />
    </AntdLayout>
  );
};

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  role: PropTypes.string,
  user: PropTypes.object,
  loading: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  loading: getLoading(state),
});

export default connect(mapStateToProps, null)(Layout);
