import { CloseOutlined } from '@ant-design/icons';
import { message } from 'antd';
import { v4 as uuidv4 } from 'uuid';

import styles from './index.module.css';

export const showNotificationMessage = ({ type, content, duration, onClose }) => {
  const key = uuidv4();
  const handleClose = () => message.destroy(key);

  return message[type]({
    key,
    content: (
      <div className={styles.notification}>
        {content}
        <CloseOutlined className={styles.closeIcon} onClick={handleClose} />
      </div>
    ),
    duration: duration || 3,
    onClose,
    className: styles.message,
  });
};
