import React, { useCallback, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Rides } from "../..";
import { renderColumns } from "./renderColumns";
import { renderTableHead } from "./renderTableHead";
import {
  setMyFilter,
  setFilteredStatus,
  applyMyFilter,
  getCustomUserFilter,
  saveCustomUserFilter,
} from "../../../store/rides";
import { RIDE_STATUSES } from "../../../constants/constants";
import { fetchTOAdminPreferences } from "../../../store/transport_organizations";
import { prepareAdditionalOptions } from "../../../utils/helpers";

import styles from "./index.module.css";

const TC_Rides = ({
  fetchTOAdminPreferences,
  TCPreferences,
  setMyFilter,
  setFilteredStatus,
  applyMyFilter,
  getCustomUserFilter,
  savedFilterParams,
  hasSavedFilterParams,
}) => {
  const setNewRequestOnly = useCallback(() => {
    setMyFilter({ status: [RIDE_STATUSES.NEW_REQUEST] });
    setFilteredStatus(true);
    applyMyFilter(true);
  }, [applyMyFilter, setFilteredStatus, setMyFilter]);

  useEffect(
    () => () => setFilteredStatus(false),

    [setFilteredStatus]
  );

  useEffect(() => {
    fetchTOAdminPreferences();
    return;
  }, [fetchTOAdminPreferences]);

  const tableColumns = useMemo(() => renderColumns(), []);

  const tableHead = useMemo(
    () =>
      renderTableHead({
        rideOptions: prepareAdditionalOptions(TCPreferences?.rideOptions),
      }),
    [TCPreferences?.rideOptions]
  );

  const ridesTable = useMemo(
    () => (
      <div className={styles.table}>
        <Rides
          columns={tableColumns}
          savedFilterParams={savedFilterParams}
          hasSavedFilterParams={hasSavedFilterParams}
          setNewRequestOnly={setNewRequestOnly}
          renderTableHead={tableHead}
        />
      </div>
    ),
    [
      hasSavedFilterParams,
      tableHead,
      tableColumns,
      setNewRequestOnly,
      savedFilterParams,
    ]
  );

  useEffect(() => {
    getCustomUserFilter();
    return;
  }, [getCustomUserFilter]);

  return ridesTable;
};

const mapStateToProps = (state) => ({
  TCPreferences: state.transportOrganizations.TCPreferences,
  savedFilterParams: state.rides?.savedFilterParams,
  hasSavedFilterParams:
    state.rides?.savedFilterParams &&
    Boolean(Object.keys(state.rides?.savedFilterParams)?.length),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchTOAdminPreferences,
      setMyFilter,
      applyMyFilter,
      setFilteredStatus,
      getCustomUserFilter,
      saveCustomUserFilter,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(TC_Rides);
