import React from "react";
import { useNavigate, useLocation } from "react-router";

export const Navigation = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const getParam = (name) => document.get(name);
  const push = React.useCallback(
    (to, state) => navigate(to, { state }),
    [navigate]
  );

  const replace = React.useCallback(
    (to, state) => navigate(to, { replace: true, state }),
    [navigate]
  );

  const go = React.useCallback((delta) => navigate(delta), [navigate]);

  const back = React.useCallback(() => navigate(-1), [navigate]);

  const forward = React.useCallback(() => navigate(1), [navigate]);
  const reset = React.useCallback(() =>
    navigate.reset({ index: 0, routes: [{ name: "login" }] }),[navigate]
  );

  return {
    back,
    forward,
    go,
    push,
    replace,
    reset,
    location,
    getParam,
  };
};
