import { getDate, startOfDay } from "date-fns";

import { LodashHelper } from ".";

import {
  CREATE_RIDE_REQUEST_STEPS,
  DATE_RANGE_FILTER,
  defaultRangeOptions,
  MONTHL_TYPE,
  NOT_SELECTED,
  RECURRING_FREQUENCY,
  RIDE_STATUSES,
  RECURRING_RIDE_EDIT_MODE,
  USA_COUNTRY_CODE,
  VEHICLE_TYPES_BARIATRIC,
  VEHICLE_TYPES_BARIATRIC_HEIGHT,
  BILL_STATUS,
} from "../constants/constants";

import DateHelper from "./DateHelper";

export const transformDraftToRide = (draft) => {

  return LodashHelper.removeEmpty({
    riderInfo: draft?.step1?.riderInfo,
    pickupPointAdditionalInfo: {
      covid19Relation: draft?.step1?.covid19Relation,
      willBeCaregiverOnScene: draft?.step1?.willBeCaregiverOnScene,
      caregivers: draft?.step1?.caregivers,
      hasStairs: draft?.step2?.hasStairs,
      needsAdditionalCarSeat: draft?.step2?.needsAdditionalCarSeat,
      needsCarSeat: draft?.step2?.needsCarSeat,
      needsBoosterSeat: draft?.step2?.needsBoosterSeat,
      hasMotorizedWheelchair: draft?.step2?.hasMotorizedWheelchair,
      isWheelchairNeeded: draft?.step2?.isWheelchairNeeded,
      dischargeFromFacility: draft?.step2?.dischargeFromFacility,
    },
    vehicleType: draft?.step2?.vehicleType,
    rideOptions: draft?.step2?.rideOptions,
    pickUpSecurityGateDetails: draft?.step3?.pickUpSecurityGateDetails,
    pickupAddressDetails: {
      formattedAddress: draft?.step3?.pickupAddressDetails?.formattedAddress,
      facilityName: draft?.step3?.pickupAddressDetails?.facilityName,
      streetNumber: draft?.step3?.pickupAddressDetails?.streetNumber,
      buildingNumber: draft?.step3?.pickupAddressDetails?.buildingNumber,
      apartmentNum: draft?.step3?.pickupAddressDetails?.apartmentNum,
      isPublicPlace: draft?.step3?.pickupAddressDetails?.isPublicPlace,
      locationDescription:
        draft?.step3?.pickupAddressDetails?.locationDescription,
      state: draft?.step3?.pickupAddressDetails?.state,
      county: draft?.step3?.pickupAddressDetails?.county,
      postalCode: draft?.step3?.pickupAddressDetails?.postalCode,
      locality: draft?.step3?.pickupAddressDetails?.locality,
      political: draft?.step3?.pickupAddressDetails?.political,
      placeId: draft?.step3?.pickupAddressDetails?.placeId,
      route: draft?.step3?.pickupAddressDetails?.route,
      coordinates: draft?.step3?.pickupAddressDetails?.coordinates,
    },
    estDuration: draft?.step3?.estDuration,
    estMileage: draft?.step3?.estMileage,
    destinationAddress: draft?.step3?.destinationAddress,
    destinationSecurityGateDetail: draft?.step3?.destinationSecurityGateDetail,
    pickUpDate: {
      datetime: draft?.step3?.pickUpDate?.datetime,
      timezone: draft?.step3?.pickUpDate?.timezone,
    },
    airportBufferTime: draft?.step3?.airportBufferTime,
    appointmentDate: {
      datetime: draft?.step3?.appointmentDate?.datetime,
      timezone: draft?.step3?.appointmentDate?.timezone,
    },
    pickUpDateLowerLimit: {
      datetime: draft?.step3?.pickUpDateLowerLimit?.datetime,
      timezone: draft?.step3?.pickUpDateLowerLimit?.timezone,
    },
    isRoundtrip: draft?.step3?.isRoundtrip,
    isReturnDateFlexible: draft?.step3?.isReturnDateFlexible,
    isPickUpDateFlexible: draft?.step3?.isPickUpDateFlexible,
    returnTime: {
      datetime: draft?.step3?.returnTime?.datetime,
      timezone: draft?.step3?.returnTime?.timezone,
    },
    returnTimeLowerLimit: {
      datetime: draft?.step3?.returnTimeLowerLimit?.datetime,
      timezone: draft?.step3?.returnTimeLowerLimit?.timezone,
    },
    riderDescription: draft?.step3?.riderDescription,
    recurrenceRule: draft?.step3?.recurrenceRule,
    isRecurring: draft?.step3?.isRecurring,
    rideNotes: draft?.step3?.rideNotes,
    flightInfo: draft?.step3?.flightInfo,
    connectedLegId: draft?.connectedLegId,
    additionalInformation: {
      costCenter: draft?.step4?.costCenter,
      additionalFundingSource: draft?.step4?.additionalFundingSource,
      documentReasonForRide: draft?.step4?.documentReasonForRide,
      otherReason: draft?.step4?.otherReason,
    },
  });
};
export const transformRideToDraft = (ride) => {

  return ride
    ? LodashHelper.removeEmpty({
      step1: {
        covid19Relation: renderRecordValue(
          ride?.pickupPointAdditionalInfo?.covid19Relation,
          ""
        ),
        riderInfo: ride?.riderInfo,
        caregivers: ride?.pickupPointAdditionalInfo?.caregivers,
        willBeCaregiverOnScene:
          ride?.pickupPointAdditionalInfo?.willBeCaregiverOnScene,
      },
      step2: {
        vehicleType: renderRecordValue(ride?.vehicleType, ""),
        hasStairs: ride?.pickupPointAdditionalInfo?.hasStairs,
        needsAdditionalCarSeat:
          ride?.pickupPointAdditionalInfo?.needsAdditionalCarSeat,
        needsCarSeat: ride?.pickupPointAdditionalInfo?.needsCarSeat,
        needsBoosterSeat: ride?.pickupPointAdditionalInfo?.needsBoosterSeat,
        hasMotorizedWheelchair:
          ride?.pickupPointAdditionalInfo?.hasMotorizedWheelchair,
        isWheelchairNeeded:
          ride?.pickupPointAdditionalInfo?.isWheelchairNeeded,
        dischargeFromFacility:
          ride?.pickupPointAdditionalInfo?.dischargeFromFacility,
        rideOptions: ride?.rideOptions,
        weight: ride?.riderInfo?.weight,
        height: ride?.riderInfo?.height,
      },
      step3: {
        pickupAddressDetails: ride?.pickupAddressDetails,
        riderDescription: renderRecordValue(ride?.riderDescription, ""),
        destinationAddress: ride?.destinationAddress,
        pickUpSecurityGateDetails: ride?.pickUpSecurityGateDetails,
        destinationSecurityGateDetail: ride?.destinationSecurityGateDetail,
        pickUpDate: ride?.pickUpDate,
        airportBufferTime: ride?.airportBufferTime,
        isRoundtrip: ride?.isRoundtrip,
        returnTime: null,
        isRecurring: ride?.isRecurring,
        recurrenceRule: ride?.recurrenceRule,
        isReturnDateFlexible: ride?.isReturnDateFlexible,
        isPickUpDateFlexible: ride?.isPickUpDateFlexible,
        pickUpDateLowerLimit: ride?.pickUpDateLowerLimit,
        rideNotes: ride?.rideNotes,
        flightInfo: ride?.flightInfo,
        returnTimeLowerLimit: ride?.returnTimeLowerLimit,
        appointmentDate: ride?.appointmentDate,
        estDuration: ride?.estDuration,
        estMileage: ride?.estMileage,
      },
      step4: ride?.additionalInformation,
    })
    : null;
};

export const convertInchesToFeet = (Inches) => {
  if (LodashHelper.isNumber(Inches)) {
    const feet = Math.floor(Inches / 12);
    const remainingInches = Inches % 12;
    return `${feet}' ${remainingInches}"`;
  }
  return "";
};

export const plainDraft = (draft) =>
  draft
    ? { ...draft?.step1, ...draft?.step2, ...draft?.step3, ...draft?.step4 }
    : null;

export const plainedTransformedDraft = (ride) =>
  plainDraft(transformRideToDraft(ride));

export const renderRecordValue = (val, defaultVal = "-") =>
  ((LodashHelper.isString(val) || LodashHelper.isNumber(val)) && `${val}`) ||
  defaultVal;

  export const renderOrgStatus = (val) => !val ? 'Active' : 'Inactive';

export const getRiderActualWeightForBariaticVehicleType = (
  vehicleType,
  record
) => {
  if (VEHICLE_TYPES_BARIATRIC.includes(vehicleType) && record?.weight) {
    return `Actual weight: ${record?.weight} lbs`;
  }
  return "";
};

export const getRiderActualHeightForBariaticVehicleType = (
  vehicleType,
  record
) => {
  if (VEHICLE_TYPES_BARIATRIC_HEIGHT.includes(vehicleType) && record?.height) {
    const value = convertInchesToFeet(record?.height);
    return `Actual height (Feet/Inches):  ${value}`;
  }
  return "";
};

export const copyToClipboard = (ref) => {
  if (window.getSelection) {
    if (window.getSelection().empty) {
      window.getSelection().empty();
    } else if (window.getSelection().removeAllRanges) {
      window.getSelection().removeAllRanges();
    }
  } else if (document.selection) {
    document.selection.empty();
  }

  if (document.selection) {
    let range = document.body.createTextRange();
    range.moveToElementText(ref);
    range.select().createTextRange();
    document.execCommand("copy");
  } else if (window.getSelection) {
    let range = document.createRange();
    range.selectNode(ref);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    document.execCommand("copy");
    window.getSelection().removeAllRanges();
  }
};

export const isUUID = (s) => {
  return s.match("^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$");
}

export const getRidesRequestParams = (values) => {
  let queryParams = {};

  queryParams.healthOrganizations = values?.healthOrganizations?.map(
    (ho) => ho
  );

  queryParams.transportCompanies = values?.transportCompanies?.map((to) => to);

  queryParams.pickUpDateFrom = values?.pickUpDate?.from;
  queryParams.pickUpDateTo = values?.pickUpDate?.to;
  queryParams.createdAtFrom = values?.createdAt?.from;
  queryParams.createdAtTo = values?.createdAt?.to;
  queryParams.seqId = values?.seqId;
  queryParams.vehicleType = values?.vehicleType;
  queryParams.rider = values?.rider;
  queryParams.driver = values?.driver;
  queryParams.createdBy = values?.createdBy;
  if (values?.status?.includes(BILL_STATUS.value)) {
    queryParams.billed = BILL_STATUS.value;
  }
  queryParams.status = values?.status?.filter((pmt) => pmt !== BILL_STATUS.value).map((s) => {
    if (s === RIDE_STATUSES.NEW_REQUEST) return RIDE_STATUSES.PENDING;
    return s;
  });
  queryParams.includeWithoutOptions = !!values?.rideOptions?.find(
    (option) => option.id === NOT_SELECTED
  );
  queryParams.rideOptions = values?.rideOptions
    ?.filter((option) => option.id !== NOT_SELECTED)
    .map((option) => option.id);

  queryParams.sortColumn = values?.sortColumn;
  queryParams.sortOrder = values?.sortOrder;

  return LodashHelper.removeEmptyAndTrim(queryParams, true);
};

const normalizeSavedDateOption = (savedDate) => {
  if (
    [
      DATE_RANGE_FILTER.TODAY,
      DATE_RANGE_FILTER.THIS_WEEK,
      DATE_RANGE_FILTER.THIS_MONTH,
    ].includes(savedDate?.range)
  ) {
    const savedOption = defaultRangeOptions.find(
      (option) => option.date.range === savedDate.range
    );
    return savedOption.date;
  }
  return savedDate;
};

export const normalizeSavedFilters = (response) => {
  if (response?.data?.pickUpDate) {
    response.data.pickUpDate = normalizeSavedDateOption(
      response.data.pickUpDate
    );
  }
  if (response?.data?.createdAt) {
    response.data.createdAt = normalizeSavedDateOption(response.data.createdAt);
  }
  if (response?.data?.rider?.id) {
    response.data.formattedRiderName = renderRecordValue(
      response?.data?.rider?.firstName &&
      response?.data?.rider?.firstName &&
      `${response?.data?.rider?.firstName} ${response?.data?.rider?.lastName}`
    );
    response.data.rider = response?.data?.rider?.id;
  }
};

export const generateRideRequestSteps = () => [
  {
    title: CREATE_RIDE_REQUEST_STEPS.FIRST,
  },
  {
    title: CREATE_RIDE_REQUEST_STEPS.SECOND,
  },
  {
    title: CREATE_RIDE_REQUEST_STEPS.THIRD,
  },
  {
    title: CREATE_RIDE_REQUEST_STEPS.FOURTH,
  },
  {
    title: CREATE_RIDE_REQUEST_STEPS.FIFTH,
  },
];

export const transformRideRequestFields = (options) =>
  options
    ? Object.keys(options).map((key) => {
      return { name: key, value: options[key] };
    })
    : [];

const sortOptionsByTitle = (current, next) => {
  const currentTitle = current.title.toLowerCase();
  const nextTitle = next.title.toLowerCase();
  return currentTitle > nextTitle ? 1 : nextTitle > currentTitle ? -1 : 0;
};

export const prepareAdditionalOptions = (additionalOptions = []) => {
  const options = additionalOptions
    .filter((option) => !option.disabled)
    .map((option) => ({
      key: option.id,
      title: option.value,
      value: { id: option.id, value: option.value },
    }))
    .sort(sortOptionsByTitle);
  options?.unshift({
    key: NOT_SELECTED,
    title: NOT_SELECTED,
    value: { id: NOT_SELECTED, value: NOT_SELECTED },
  });
  return options;
};

export const isAddressInvalid = (addressErrors) => {
  console.log("address error",addressErrors);

  return addressErrors &&
    !!Object.keys(addressErrors).filter(
      (key) => !["apartmentNum", "buildingNumber"].includes(key)
    ).length;
}

export const getMonthPickerOptions = (pickUpDate) => {
  return [
    {
      title:
        getDate(startOfDay(pickUpDate)) === 31
          ? `Monthly on last day of month`
          : `Monthly on day ${new Date(pickUpDate).getDate()}`,
      value: MONTHL_TYPE.BY_DAY_OF_MONTH,
    },
    {
      title: `Monthly on the ${digitsToWords(
        DateHelper.getNumberOfDayOccurrencesInWeek(pickUpDate)
      )} ${DateHelper.format(new Date(pickUpDate), "EEEE")}`,
      value: MONTHL_TYPE.BY_WEEK_INDEX,
    },
  ];
};

export const getWeeksNumber = (weeks) =>
  Array.from(Array(weeks + 1).keys()).splice(1);

export const getRecurrenceRule = (values) =>
  values?.recurrenceRule?.frequency &&
    values?.recurrenceRule?.frequency === RECURRING_FREQUENCY.MONTHLY
    ? {
      frequency: "",
    }
    : values.recurrenceRule;

export const digitsToWords = (index) => {
  switch (index) {
    case 0:
      return "first";
    case 1:
      return "second";
    case 2:
      return "third";
    case 3:
      return "fourth";
    case 4:
      return "last";

    default:
      return "last";
  }
};

export const getOtherLegSeqIdOfRoundtrip = (id, leg) => {
  if (leg && id) {
    return leg.toLowerCase() === "a"
      ? id.replace(/.$/, "B")
      : id.replace(/.$/, "A");
  }
};

export const getRideDisabledFieldsForEdit = (status) => {
  const notPending = status !== RIDE_STATUSES.PENDING;
  const notPendingAndAccepted =
    status !== RIDE_STATUSES.PENDING && status !== RIDE_STATUSES.ACCEPTED;
  const isCaregiverCheckboxEditable =
    status !== RIDE_STATUSES.PENDING &&
    status !== RIDE_STATUSES.ACCEPTED &&
    status !== RIDE_STATUSES.IN_PROGRESS;

  return [
    {
      covid19Relation: true,
      riderAutoComplete: true,
      riderInfo: false,
      caregiverWillBeOntheScene: isCaregiverCheckboxEditable,
      caregiverInfo: false,
    },

    {
      vehicleInformation: notPending,
    },

    {
      riderInfo: {
        riderDescription: notPendingAndAccepted,
      },
      pickupAddressDetails: {
        formattedAddress: notPending,
        isPublicPlace: notPendingAndAccepted,
        apartmentNum: notPendingAndAccepted,
        buildingNumber: notPendingAndAccepted,
        locationDescription: notPendingAndAccepted,
      },
      destinationAddress: {
        formattedAddress: notPending,
        isPublicPlace: notPendingAndAccepted,
        apartmentNum: notPendingAndAccepted,
        buildingNumber: notPendingAndAccepted,
      },
      pickUpDate: notPendingAndAccepted,
      recurrenceRule: notPending,
      isRoundTrip: true,
      isRecurring: true,
      returnTime: true,
      isPickUpDateFlexible: true,
      isReturnDateFlexible: true,
      rideNotes: true,
      flightInfo: true,
      pickUpSecurityGateDetails: {
        securityGateInstruction: true,
        hasSecurityGate: true,
      },
      destinationSecurityGateDetail: {
        securityGateInstruction: true,
        hasSecurityGate: true,
      },
    },

    {
      costCenter: false,
      additionalFundingSource: false,
      documentReasonForRide: false,
      otherReason: false,
    },
  ];
};

export const getRecurringRideDisabledFieldsForEdit = (status, editMode) => {
  const isAllRides = RECURRING_RIDE_EDIT_MODE.ALL_IN_SERIES === editMode;
  const isThisAndFollowing =
    RECURRING_RIDE_EDIT_MODE.THIS_AND_FOLLOWING === editMode;
  const isThisRideOnly = RECURRING_RIDE_EDIT_MODE.THIS_ONLY === editMode;

  return [
    {
      covid19Relation:
        getRideDisabledFieldsForEdit(status)[0]?.covid19Relation || true,
      riderInfo:
        getRideDisabledFieldsForEdit(status)[0]?.riderInfo ||
        isThisRideOnly ||
        isThisAndFollowing,
      riderAutoComplete: true,
      caregiverWillBeOntheScene:
        getRideDisabledFieldsForEdit(status)[0]?.caregiverWillBeOntheScene ||
        false,
      caregiverInfo:
        getRideDisabledFieldsForEdit(status)[0]?.caregiverInfo || false,
    },
    {
      vehicleInformation:
        getRideDisabledFieldsForEdit(status)[1]?.vehicleInformation || false,
    },
    {
      riderInfo: {
        riderDescription:
          getRideDisabledFieldsForEdit(status)[2]?.riderDescription || false,
      },
      pickupAddressDetails: {
        formattedAddress:
          getRideDisabledFieldsForEdit(status)[2]?.pickupAddressDetails
            ?.formattedAddress || false,
        isPublicPlace:
          getRideDisabledFieldsForEdit(status)[2]?.pickupAddressDetails
            ?.isPublicPlace || false,
        apartmentNum:
          getRideDisabledFieldsForEdit(status)[2]?.pickupAddressDetails
            ?.apartmentNum || false,
        buildingNumber:
          getRideDisabledFieldsForEdit(status)[2]?.pickupAddressDetails
            ?.buildingNumber || false,
        locationDescription:
          getRideDisabledFieldsForEdit(status)[2]?.pickupAddressDetails
            ?.locationDescription || false,
      },
      destinationAddress: {
        formattedAddress:
          getRideDisabledFieldsForEdit(status)[2]?.destinationAddress
            ?.formattedAddress || false,
        isPublicPlace:
          getRideDisabledFieldsForEdit(status)[2]?.destinationAddress
            ?.isPublicPlace || false,
        apartmentNum:
          getRideDisabledFieldsForEdit(status)[2]?.destinationAddress
            ?.apartmentNum || false,
        buildingNumber:
          getRideDisabledFieldsForEdit(status)[2]?.destinationAddress
            ?.buildingNumber || false,
      },
      pickUpDate:
        getRideDisabledFieldsForEdit(status)[2]?.pickUpDate ||
        isThisAndFollowing ||
        isAllRides,
      pickUpDateLowerLimit:
        getRideDisabledFieldsForEdit(status)[2]?.pickUpDate ||
        isThisAndFollowing ||
        isAllRides,
      recurrenceRule:
        getRideDisabledFieldsForEdit(status)[2]?.recurrenceRule ||
        isThisRideOnly ||
        isThisAndFollowing,
      isRoundTrip: getRideDisabledFieldsForEdit(status)[2]?.isRoundTrip || true,
      isRecurring: getRideDisabledFieldsForEdit(status)[2]?.isRecurring || true,
      isPickUpDateFlexible: true,
      isReturnDateFlexible: true,
      returnTime: getRideDisabledFieldsForEdit(status)[2]?.returnTime || true,
      returnTimeLowerLimit:
        getRideDisabledFieldsForEdit(status)[2]?.returnTime || true,
    },
    {
      costCenter: getRideDisabledFieldsForEdit(status)[3]?.costCenter || false,
      additionalFundingSource:
        getRideDisabledFieldsForEdit(status)[3]?.additionalFundingSource ||
        false,
      documentReasonForRide:
        getRideDisabledFieldsForEdit(status)[3]?.documentReasonForRide || false,
      otherReason:
        getRideDisabledFieldsForEdit(status)[3]?.otherReason || false,
    },
  ];
};

export const checkFormIsValid = (formIsValid) => {
  // delete formIsValid?.step1?.formattedRiderName;
  return (
    formIsValid &&
    Object.values(formIsValid).every((value) => {
      return !value ? false : true;
    })
  );
};

export const datePickerFieldsNames = {
  pickUpDate: {
    title: "pickUpDate",
    datetime: "pickUpDate.datetime",
    timezone: "pickUpDate.timezone",
  },
  returnTime: {
    title: "returnTime",
    datetime: "returnTime.datetime",
    timezone: "returnTime.timezone",
  },

  startPoint: {
    title: "recurrenceRule.startPoint",
    datetime: "recurrenceRule.startPoint.datetime",
    timezone: "recurrenceRule.startPoint.timezone",
  },
  returnStartPoint: {
    title: "recurrenceRule.returnStartPoint",
    datetime: "recurrenceRule.returnStartPoint.datetime",
    timezone: "recurrenceRule.returnStartPoint.timezone",
  },
  pickUpDateLowerLimit: {
    title: "pickUpDateLowerLimit",
    datetime: "pickUpDateLowerLimit.datetime",
    timezone: "pickUpDateLowerLimit.timezone",
  },
  returnTimeLowerLimit: {
    title: "returnTimeLowerLimit",
    datetime: "returnTimeLowerLimit.datetime",
    timezone: "returnTimeLowerLimit.timezone",
  },
  appointmentDate: {
    title: "appointmentDate",
    datetime: "appointmentDate.datetime",
    timezone: "appointmentDate.timezone",
  },
};

export const renderPhoneNumber = (value) => {
  return value
    ? `${USA_COUNTRY_CODE} (${value.substring(0, 3)})-${value.substring(
      3,
      6
    )}-${value.substring(6, 10)}`
    : "-";
};

export const convertConstantsToDictionary = (consts) =>
  consts.reduce((a, v) => ({ ...a, [v.value]: v.title }), {});

export const renderPickUpDate = ({ dateFrom, dateTo }) => {
  const isSameDay = DateHelper.isTheSameDay(
    DateHelper.utcToZonedTime(dateFrom.datetime, dateFrom.timezone),
    DateHelper.utcToZonedTime(dateTo.datetime, dateTo.timezone)
  );

  if (isSameDay) {
    return `${DateHelper.renderDateTimeWithTZ({
      dateTime: dateFrom.datetime,
      timeZone: dateFrom.timezone,
      showTimezoneString: false,
    })} - ${DateHelper.renderDateTimeWithTZ({
      dateTime: dateTo.datetime,
      timeZone: dateTo.timezone,
      useFullFormattedDate: false,
    })}`;
  } else
    return `${DateHelper.renderDateTimeWithTZ({
      dateTime: dateFrom.datetime,
      timeZone: dateFrom.timezone,
    })} - ${DateHelper.renderDateTimeWithTZ({
      dateTime: dateTo.datetime,
      timeZone: dateTo.timezone,
    })}`;
};

export const getPage = () => {
  return Number(new URLSearchParams(window?.location?.search).get("page")) || 1;
};

export const redirectToPage = (mainPath, page, Navigation) => {
  if (Navigation) {
    const { replace } = Navigation;

    replace(`${mainPath}?page=${page}`, { state: { page: page } });
  }
};

export const convertRiderPreferences = (dictionary, preferences) => {
  return preferences.map((preference) => dictionary[preference]).join(", ");
};

export const checkStepIsDisabled = ({
  steps,
  step,
  index,
  formIsValid,
  draftIsValid,
  showThirdStep,
}) => {
  let stepIsDisabled;

  const isCurrentStepSameAsIterated = step === index;

  const isFullDraftValidAndCurrentStepInvalid =
    draftIsValid?.every((valid) => valid) &&
    step !== 4 &&
    !formIsValid?.[`step${step + 1}`];

  const isCurrentStepInDraftValid = draftIsValid?.[index];

  const isCurrentStepInFormValid = formIsValid?.[`step${step + 1}`];
  const isDraftInvalidAndCurrentStepInFormValid =
    !draftIsValid?.[index] &&
    formIsValid?.[`step${step + 1}`] &&
    draftIsValid?.[index - 1];

  const isThirdStepOptionalAndIteratedStepIsReview =
    !showThirdStep && index === 4 && formIsValid[`step3`];

  const isThirdStepOptionalAndCurrentStepIsOneBefore =
    !showThirdStep && index === 3;

  if (isCurrentStepSameAsIterated) {
    stepIsDisabled = false;
  } else if (isThirdStepOptionalAndCurrentStepIsOneBefore) {
    stepIsDisabled = true;
  } else if (isThirdStepOptionalAndIteratedStepIsReview) {
    stepIsDisabled = false;
  } else if (isFullDraftValidAndCurrentStepInvalid) {
    stepIsDisabled = true;
  } else if (
    isCurrentStepInDraftValid ||
    isDraftInvalidAndCurrentStepInFormValid
  ) {
    stepIsDisabled = false;
  } else if (isCurrentStepInFormValid && index - step === 1) {
    steps[index].disabled = false;
  } else if (isCurrentStepInFormValid && isCurrentStepInDraftValid) {
    steps[index].disabled = false;
  } else if (step !== index && isCurrentStepInDraftValid) {
    stepIsDisabled = false;
  } else {
    stepIsDisabled = true;
  }

  return stepIsDisabled;
};

export const checkStepStatus = ({
  steps,
  step,
  index,
  draftIsValid,
  formIsValid,
  showThirdStep,
}) => {
  let stepStatus;
  if (draftIsValid?.[index]) stepStatus = "finish";
  if (!showThirdStep && index === 3) stepStatus = "wait";
  if (step === index) stepStatus = "process";

  return stepStatus;
};

export const clearAuthStorageInfo = () => {
  const localstorage_access_token = localStorage.getItem("access_token");
  const localstorage_refresh_token = localStorage.getItem("refresh_token");
  const sessionstorage_access_token = sessionStorage.getItem("access_token");
  const sessionstorage_refresh_token = sessionStorage.getItem("refresh_token");
  const rememberMe = localStorage.getItem("credentials");
  const idleModalVisible = localStorage.getItem("idleModalVisible");

  if (idleModalVisible) localStorage.removeItem("idleModalVisible");

  if (rememberMe) localStorage.removeItem("credentials");

  if (localstorage_access_token || localstorage_refresh_token) {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
  }
  if (sessionstorage_access_token || sessionstorage_refresh_token) {
    sessionStorage.removeItem("access_token");
    sessionStorage.removeItem("refresh_token");
  }
};

export const isTheSameUser = (firstUser, secondUser) => {
  const copiedFirstUser = { ...firstUser };
  delete copiedFirstUser?.role;
  return LodashHelper.isEqual(copiedFirstUser, secondUser);
};

export const formatDuration = (durationInSeconds) => {
  const hours = Math.floor(durationInSeconds / 3600);
  const minutes = Math.floor((durationInSeconds % 3600) / 60);

  const hoursText =
    hours > 0 ? `${hours} ${hours === 1 ? "hour" : "hours"}` : "";
  const minutesText =
    minutes > 0 ? `${minutes} ${minutes === 1 ? "min" : "mins"}` : "";

  if (hoursText && minutesText) {
    return `${hoursText} ${minutesText}`;
  } else if (hoursText) {
    return hoursText;
  } else if (minutesText) {
    return minutesText;
  } else {
    return "0 mins";
  }
};

export const distanceInMetersToMiles = (distanceInMeters) => {
  const meterToMiles = 1609.34;
  const distanceInKilometers = (distanceInMeters / meterToMiles).toFixed(2); // Convert meters to miles
  return `${distanceInKilometers} miles`;
};
