import React from "react";
import { CreateUser } from "../..";
import { USER_ROLES } from "../../../constants/constants";
export const HO_CreateUser_admin = (props) => (
  <CreateUser
    title="Add New Admin"
    headText="Provide information for the new Admin"
    managedRole={USER_ROLES.HO_ADMIN}
    {...props}
  />
);
