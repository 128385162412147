import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Formik } from 'formik';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames';

import { Input, Button, Logo, Loader } from '../../../components';
import { signInSchema } from '../../../utils';
import { loginUser } from '../../../store/authorization';
import { getLoading } from '../../../store/selectors/common';
import { URLS } from '../../../constants/constants';

import styles from './index.module.css';
import { Navigation } from '../../../utils/navigation';

const Login = ({ loginUser, loading, captchaLoaded }) => {
  
 
const navigate = Navigation();
  const login = (values) => {
    loginUser({ ...values,Navigation:navigate});
  };
return (  <div className={styles.loginWrapper}>
    <Logo lg />

    <h1 className={styles.pageHeader}>User Login</h1>
    <Formik
      initialValues={{
        email: '',
        password: '',
        
      }}
      validationSchema={signInSchema}
      onSubmit={login}>
      {({ values, handleSubmit, ...rest }) => (
        <form onSubmit={handleSubmit} className={styles.loginForm}>
          <label className={styles.loginInputControl}>
            <span className={styles.required}>Email Address</span>
            <Input
              type='text'
              name='email'
              myClassName={styles.input}
              showAuthIcons
              value={values.email}
              data-testid='email'
            />
          </label>
          <label
            className={classnames({
              [styles.loginInputControl]: styles.loginInputControl,
              [styles.passwordInput]: styles.passwordInput,
            })}>
            <span className={styles.required}>Password</span>
            <Input
              type='password'
              name='password'
              myClassName={styles.input}
              value={values.password}
              showAuthIcons
              data-testid='password'
            />
          </label>
          <NavLink to={URLS.FORGOT} className={styles.forgotLink}>
            Forgot password?
          </NavLink>
          <div className={styles.submitting}>
            <div className={styles.submit}>
              <Button type='submit' onClick={handleSubmit} disabled={!(rest.isValid && captchaLoaded)}>
                Login
              </Button>
            </div>
          </div>
        </form>
      )}
    </Formik>
    <Loader loading={loading} />
  </div>
);
   }

const mapStateToProps = (state) => ({
  loading: getLoading(state),
  captchaLoaded: state.common.captchaLoaded,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ loginUser }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Login);
