import React, { useEffect, useRef, useState } from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  HoPreferencesOrganizationInfo,
  Button,
  SecurityPreferencesEmail,
  SecurityPreferencesPassword,
  PersonalInfoPreferences,
  useTwoFactorVerificationModal,
} from "../../../components";
import {
  GoogleAddressHelper,
  updatePasswordSchema,
  changeEmailSchema,
  personalInformationSchema,
  LodashHelper,
  DateHelper,
} from "../../../utils";
import {
  fetchHOPreferences,
  fetchHealthOrganizationPricing,
  postHealthOrganizationPricing,
  HOAdminUpdateOwnOrganization,
  updateHO
} from "../../../store/health_organizations";
import {
  changePassword,
  changePasswordVerification,
  changeEmail,
  updatePersonalInformation,
} from "../../../store/authorization";
import { useCurrentRole } from "../../../hooks/useCurrentRole";
import { updateOrganizationSchema } from "../../../utils/validations";

import { BillingAndInvoicing } from "../../../components/common/BillingAndInvoicing/BillingAndInvoicing";
import { ConfirmPricingTypeModal } from "../../../components/modals/ConfirmPricingActionModals/ConfirmPricingType";
import { ConfirmChangePricingValuesModal } from "../../../components/modals/ConfirmPricingActionModals/ConfirmChangePricingValuesModal";
import { BILLING_TABS_OBJECT } from "../../../constants/constants";
import styles from "./index.module.css";

const getPersonalInfoInitialValues = (values) => {
  return {
    firstName: values?.firstName || "",
    lastName: values?.lastName || "",
    phoneNumber: values?.phoneNumber || "",
    jobTitle: values?.jobTitle || "",
    preferredTimezone: values?.preferredTimezone || null,
  };
};

const HO_Preferences = ({
  fetchHOPreferences,
  HOAdminUpdateOwnOrganization,
  postHealthOrganizationPricing,
  updateHO,
  fetchHealthOrganizationPricing,
  HOPreferences,
  changePassword,
  changeEmail,
  updatePersonalInformation,
  role,
  user,
  isViewDashboardMode,
  changePasswordVerification,
  HOPricing,
  pricingType,
}) => {

  const [tab, setTab] = useState(BILLING_TABS_OBJECT[HOPricing?.pricingType] || BILLING_TABS_OBJECT.Transaction);

  const [currentValue, setCurrentValue] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [openPricingValuesModal, setOpenPricingValuesModal] = useState(false);
  const securityRef = useRef(null);
  const {
    TwoFactorAuthenticationModal,
    twoFactorModalVisible,
    setTwoFactorModalVisible,
  } = useTwoFactorVerificationModal({
    handleConfirm: (token, confirmCallback) =>
      changePasswordVerification({
        token,
        newPassword: securityRef?.current?.values?.newPassword,
      }).then((res) => {
        if (res) {
          confirmCallback();
          securityRef?.current?.resetForm();
        }
      }),
    email: user?.email,
  });
  const { isEmployee, isHOWorker } = useCurrentRole(role);

  useEffect(() => {
    fetchHOPreferences(); return () => { };
  }, [fetchHOPreferences]);

  // useEffect(() => {
  //   (async function () {
  //     if (HOPreferences?.hoPricingId) {
  //       const payload = {
  //         orgId: user?.organizationId,
  //         pricingId: HOPreferences?.hoPricingId
  //       }
  //       await fetchHealthOrganizationPricing(payload);

  //       console.log("pricing ----->",HOPreferences)
  //      if(HOPreferences?.hoPricing[0]?.pricingType)
  //         setTab(BILLING_TABS_OBJECT[HOPreferences?.hoPricing[0]?.pricingType])
  //     }
  //   })();
  //   return;
  // }, [fetchHealthOrganizationPricing, HOPreferences, user?.organizationId]);

  // useEffect(() => {
  //   if (HOPricing) {
  //     if (HOPricing?.data) {
  //       setCurrentValue(HOPricing?.data[0]);
  //     } else {
  //       setCurrentValue(HOPricing);
  //     }

  //     if (HOPricing.pricingType) {
  //       setTab(BILLING_TABS_OBJECT[HOPricing?.pricingType])
  //     }
  //   }
  // }, [HOPricing]);

  useEffect(() => {

    if (HOPricing) {
      setCurrentValue(HOPricing);
      if (HOPricing.pricingType) {
        setTab(BILLING_TABS_OBJECT[HOPricing?.pricingType])
      }
    }

    return;
  }, [HOPricing]);

  const handleSelectAddress = async ({ value, values, setValues }) => {
    setValues({
      ...values,
      location: GoogleAddressHelper.transformFields(value),
    });
  };

  const handleChangeAddress = ({ value, values, setValues }) => {
    setValues({
      ...values,
      location: {
        formattedAddress: value,
      },
    });
  };

  const onChangeTab = async (key) => {
    const pricingType = BILLING_TABS_OBJECT[key] || BILLING_TABS_OBJECT.Transaction;

    const payload = {
      orgId: user?.organizationId,
      pricingType: pricingType,
    };

    await setTab(BILLING_TABS_OBJECT[key]);
    await fetchHealthOrganizationPricing(payload);
  };

  const onConfirm = (values) => {
    postHealthOrganizationPricing({ ...values, id: HOPricing?.id, organizationId: user?.organizationId });
    setOpenModal(false);
    setOpenPricingValuesModal(false);
  }

  const onSubmitPricing = (values) => {

    const isPriceTypeChanged = pricingType !== tab;
    // HOPreferences?.hoPricing?.length > 0
    //   ? !(HOPreferences?.hoPricing[0]?.id === currentValue.id)
    //   : false;
    const isValuesChanged = !LodashHelper.isEqual(values, HOPricing);

    if (!HOPricing) {
      onConfirm(values)
    }
    else {
      setCurrentValue(values);
      if (isPriceTypeChanged) {
        setOpenModal(true);
      } else if (isValuesChanged && !isPriceTypeChanged) {
        setOpenPricingValuesModal(true);
      } else {
        setOpenModal(false);
        onConfirm(values)
      }
    }
  };

  return (
    <div className={styles.pageContent}>
      <div className={styles.header}>
        <h1 className={styles.pageTitle}>Preferences</h1>
      </div>
      <div className={styles.pageSection}>
        <h2> Organization Information</h2>
        <Formik
          onSubmit={(values) =>
            HOAdminUpdateOwnOrganization({
              ...values,
              airportBufferTime: DateHelper.convertTimeToMinutes(
                values?.airportBufferTime
              ),
            })
          }
          enableReinitialize
          validateOnMount
          validateOnChange={false}
          validationSchema={updateOrganizationSchema}
          initialValues={{
            name: HOPreferences?.name || "",
            location: { formattedAddress: "", ...HOPreferences?.location },
            airportBufferTime: DateHelper.convertMinutesToTime(
              HOPreferences?.airportBufferTime || 0
            ),
            rideRequestFields: HOPreferences?.rideRequestFields
          }}
        >
          {({ handleSubmit, ...rest }) => {
            return (
              <form className={styles.form} onSubmit={handleSubmit}>
                <HoPreferencesOrganizationInfo
                  handleSelectAddress={handleSelectAddress}
                  handleChangeAddress={handleChangeAddress}
                  isViewDashboardMode={isViewDashboardMode}
                  rest={rest}
                  addressFieldIsDisabled={
                    (isEmployee && isHOWorker)
                  }
                />


                {role &&
                  !((isEmployee && isHOWorker)) && (
                    <div className={styles.btnPanel}>
                      <div className={styles.btnControl}>
                        <Button disabled={!rest.isValid} type="submit">
                          Save Changes
                        </Button>
                      </div>
                    </div>
                  )}
              </form>
            );
          }}
        </Formik>
      </div>
      {isViewDashboardMode &&
      <div className={styles.pageSection}>
        <BillingAndInvoicing
          tab={tab}
          isViewDashboardMode={isViewDashboardMode}
          onChange={onChangeTab}
          HOPricing={currentValue}
          onSubmitPricing={onSubmitPricing}
        />
        {openModal &&
          ConfirmPricingTypeModal({
            handleConfirm: () => onConfirm(currentValue),
            handleCancel: () => setOpenModal(false)
          })}
        {openPricingValuesModal &&
          ConfirmChangePricingValuesModal({
            type: 'healthcare organization',
            handleConfirm: () => onConfirm(currentValue),
            handleCancel: () => setOpenPricingValuesModal(false)
          })}
      </div>
}

      <div className={styles.pageSection}>
        <Formik
          enableReinitialize
          validateOnMount
          initialValues={getPersonalInfoInitialValues(
            isViewDashboardMode ? HOPreferences?.admin : user
          )}
          validationSchema={personalInformationSchema}
          onSubmit={({
            firstName,
            lastName,
            preferredTimezone,
            phoneNumber,
            jobTitle,
          }) =>
            updatePersonalInformation({
              firstName,
              lastName,
              preferredTimezone,
              phoneNumber,
              jobTitle,
            })
          }
        >
          {({ values, handleSubmit, ...rest }) => {
            return (
              <form className={styles.form} onSubmit={handleSubmit}>
                <PersonalInfoPreferences
                  values={values}
                  disableAllFields={isViewDashboardMode}
                  allowScrollToPersonsalInfo={
                    !LodashHelper.isEmpty(HOPreferences) &&
                    !LodashHelper.isEmpty(user)
                  }
                />
                {!isViewDashboardMode && (
                  <div className={styles.btnPanel}>
                    <div className={styles.btnControl}>
                      <Button
                        disabled={!rest.isValid || isViewDashboardMode}
                        type="submit"
                      >
                        Save Changes
                      </Button>
                    </div>
                  </div>
                )}
              </form>
            );
          }}
        </Formik>
      </div>


      <div>
        <h2>Password Information</h2>
        <Formik
          enableReinitialize
          validateOnMount
          initialValues={
            HOPreferences && !(isEmployee && isHOWorker)
              ? {
                newEmail: HOPreferences?.admin?.email,
                password: "",
              }
              : { newEmail: user?.email || "", password: "" }
          }
          onSubmit={(values) => changeEmail(values)}
          validationSchema={changeEmailSchema(
            isEmployee && isHOWorker ? user?.email : HOPreferences?.admin?.email
          )}
        >
          {({ values, ...rest }) => {
            return (
              <form className={styles.form} onSubmit={rest.handleSubmit}>
                {HOPreferences?.admin && user && (
                  <SecurityPreferencesEmail
                    values={values}
                    initialEmail={
                      isEmployee && isHOWorker
                        ? user?.email
                        : HOPreferences?.admin?.email
                    }
                    disableAllFields={isViewDashboardMode}
                    {...rest}
                  />
                )}
              </form>
            );
          }}
        </Formik>
        <Formik
          innerRef={securityRef}
          validateOnMount
          initialValues={{
            newPassword: "",
            oldPassword: "",
          }}
          validationSchema={updatePasswordSchema}
          onSubmit={(values) => {
            changePassword(values).then((res) => setTwoFactorModalVisible(res));
          }}
        >
          {({ values, ...rest }) => {
            return (
              <form className={styles.form} onSubmit={rest.handleSubmit}>
                <SecurityPreferencesPassword
                  values={values}
                  disableAllFields={isViewDashboardMode}
                  {...rest}
                />
              </form>
            );
          }}
        </Formik>
      </div>
      {twoFactorModalVisible && TwoFactorAuthenticationModal}
    </div>
  );
};

const mapStateToProps = (state) => ({
  HOPreferences: state.healthOrganizations.HOPreferences,
  HOPricing: state.healthOrganizations.HOPricing,
  pricingType: state.healthOrganizations.pricingType,
  user: state.authorization.user,
  isViewDashboardMode: state?.authorization?.viewDashboardMode?.active,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateHO,
      fetchHOPreferences,
      HOAdminUpdateOwnOrganization,
      changePassword,
      changeEmail,
      updatePersonalInformation,
      changePasswordVerification,
      fetchHealthOrganizationPricing,
      postHealthOrganizationPricing,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(HO_Preferences);
