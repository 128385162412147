import { notification } from 'antd';

import styles from './index.module.css';

export const destroyNotification = () => notification.destroy();

export const showConfirmPopUp = ({ description, duration, refreshButton, refreshButtonAction }) => {
  const handleRefresh = () => {
    notification.destroy();
    if (refreshButtonAction) refreshButtonAction();
    window.location.reload();
  };
  notification.open({
    description: (
      <div className={styles.descriptionBody}>
        <span className={styles.message}>{description}</span>
        {refreshButton ? (
          <button className={styles.dismissBtn} onClick={handleRefresh}>
            Refresh page
          </button>
        ) : (
          <button className={styles.dismissBtn} onClick={destroyNotification}>
            Dismiss
          </button>
        )}
      </div>
    ),
    duration,
    placement: 'bottomRight',
    bottom: 50,
    className: styles.confirmPopUp,
    closeIcon: <div></div>,
  });
};
