import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Logo } from '../../../components';
import { fetchUserData, logOut } from '../../../store/authorization';
import Login from '../Login/Login';

import styles from './index.module.css';
import { Navigation } from '../../../utils/navigation';

export const VerifyUserPage = () => {
  const navigate = Navigation();
  const dispatch = useDispatch(); 
  const user = JSON.parse(localStorage.getItem('user'));
  const isViewDashboardExpired = user?.onBehalfOfRoleId && !sessionStorage.getItem('viewDashboardMode');

  const allowVerifyUser =
    (user && !user?.onBehalfOfRoleId) || (user?.onBehalfOfRoleId && sessionStorage.getItem('viewDashboardMode'));

  useEffect(() => {
    isViewDashboardExpired && dispatch(logOut())

    return () => {};
  }, [dispatch, isViewDashboardExpired]);

  useEffect(() => {
    allowVerifyUser && dispatch(fetchUserData(navigate))
    
    return () => {};
  }, [dispatch, allowVerifyUser]);

  if (!user) return <Login />;
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.logo}>
          <Logo xl width={350} height={102} />
        </div>
        <div className={styles.loading}>
          <span />
          <span />
          <span />
        </div>
      </div>
    </div>
  );
};
