import { Navigate  } from 'react-router-dom';

import { USER_ROLES, URLS } from '../constants/constants';

import {
  MH_Rides,
  MH_Preferences,
  Admins,
  CreateAdmin,
  UpdateAdmin,
  Health_Organizations,
  Create_Health_Organization,
  Update_Health_Organization,
  Transport_Companies,
  Create_Transport_Company,
  Update_Transport_Company,
  HO_Admin_Users,
  HO_CreateUser,
  HO_UpdateUser,
  HO_Preferences,
  HO_Manager_Users,
  CreateRide,
  TC_Preferences,
  TC_Admin_Users,
  TC_CreateUser,
  TC_UpdateUser,
  TC_Manager_Users,
  Driver_Dashboard,
  Driver_Preferences,
  TC_Admin_Drivers,
  CreateDriver,
  UpdateDriver,
  DraftInfo,
  HO_Rides,
  TC_Rides,
  TC_RideDetails,
  HO_RideDetails,
  MH_RideDetails,
  EditRide,
  DriverRideDetails,
  DriverCancelRide,
  HO_Drafts,
  EditDraft,
  Riders,
  Create_Rider,
  UpdateRider,
  HO_CreateUser_admin,
  TC_CreateUser_admin,
  TC_Invoices
} from '../pages';
import RescheduleRide from '../pages/HO_Admin/RescheduleRide/RescheduleRide';

export const routesList = {
  [USER_ROLES.SUPER_ADMIN]: [
    { id: 0, path: URLS.RIDES, exact: true, component: MH_Rides },
    { id: 1, path: URLS.RIDE_DETAILS, exact: true, component: MH_RideDetails },
    {
      id: 3,
      path: URLS.HEALTH_ORGANIZATIONS,
      exact: true,
      component: Health_Organizations,
    },
    {
      id: 4,
      path: URLS.CREATE_HEALTH_ORGANIZATION,
      exact: true,
      component: Create_Health_Organization,
    },
    {
      id: 5,
      path: URLS.EDIT_HEALTH_ORGANIZATION,
      exact: true,
      component: Update_Health_Organization,
    },
    {
      id: 6,
      path: URLS.TRANSPORT_ORGANIZATIONS,
      exact: true,
      component: Transport_Companies,
    },
    {
      id: 7,
      path: URLS.CREATE_TRANSPORT_ORGANIZATION,
      exact: true,
      component: Create_Transport_Company,
    },
    {
      id: 8,
      path: URLS.EDIT_TRANSPORT_ORGANIZATION,
      exact: true,
      component: Update_Transport_Company,
    },
    { id: 9, path: URLS.ADMINS, exact: true, component: Admins },
    { id: 10, path: URLS.CREATE_ADMIN, exact: true, component: CreateAdmin },
    { id: 11, path: URLS.EDIT_ADMIN, exact: true, component: UpdateAdmin },
    { id: 12, path: URLS.RIDERS, exact: true, component: Riders },
    { id: 13, path: URLS.EDIT_RIDER, exact: true, component: UpdateRider },
    { id: 14, path: URLS.PREFERENCES, component: MH_Preferences },
    {
      id: 15,
      path: URLS.HOME,
      exact: true,
      component: () => <Navigate  to={URLS.RIDES} />,
    },
    { id: 16, component: () => <Navigate  to={URLS.NOT_FOUND} /> },
    { id: 17, path:'*',  component: () => <Navigate  to={URLS.RIDES} />  },
  ],
  [USER_ROLES.DRIVER]: [
    { id: 0, path: URLS.RIDES, exact: true, component: Driver_Dashboard },
    {
      id: 1,
      path: URLS.RIDE_DETAILS,
      exact: true,
      component: DriverRideDetails,
    },
    { id: 2, path: URLS.CANCEL_RIDE, exact: true, component: DriverCancelRide },
    { id: 3, path: URLS.PREFERENCES, component: Driver_Preferences },
    {
      id: 4,
      path: URLS.HOME,
      exact: true,
      component: () => <Navigate  to={URLS.RIDES} />,
    },
    { id: 5, component: () => <Navigate  to={URLS.NOT_FOUND} /> },
    { id: 17, path:'*',  component: () => <Navigate  to={URLS.RIDES} />  },
  ],
  [USER_ROLES.HO_ADMIN]: [
    { id: 0, path: URLS.DRAFTS, exact: true, component: HO_Drafts },
    { id: 1, path: URLS.EDIT_DRAFT, exact: true, component: EditDraft },
    { id: 2, path: URLS.DRAFT_DETAILS, exact: true, component: DraftInfo },
    { id: 3, path: URLS.RIDES, exact: true, component: HO_Rides },
    { id: 4, path: URLS.CREATE_RIDE, exact: true, component: CreateRide },
    { id: 5, path: URLS.RIDE_DETAILS, exact: true, component: HO_RideDetails },
    { id: 6, path: URLS.EDIT_RIDE, exact: true, component: EditRide },
    // {/* ToDo Rescheudleing */} { id: 18, path: URLS.RESCHEDULE_RIDE, exact: true, component: RescheduleRide },
    { id: 7, path: URLS.USERS, exact: true, component: HO_Admin_Users },
    { id: 8, path: URLS.CREATE_USER, exact: true, component: HO_CreateUser },
    { id: 9, path: URLS.EDIT_USER, exact: true, component: HO_UpdateUser },
    { id: 10, path: URLS.RIDERS, exact: true, component: Riders },
    { id: 11, path: URLS.CREATE_RIDER, exact: true, component: Create_Rider },
    { id: 12, path: URLS.EDIT_RIDER, exact: true, component: UpdateRider },
    { id: 13, path: URLS.PREFERENCES, component: HO_Preferences },
    {
      id: 14,
      path: URLS.CREATE_ADMIN_USER,
      exact: true,
      component: HO_CreateUser_admin,
    },
    {
      id: 15,
      path: URLS.HOME,
      exact: true,
      component: () => <Navigate  to={URLS.RIDES} />,
    },
    { id: 16, component: () => <Navigate  to={URLS.NOT_FOUND} /> },
    { id: 17, path:'*',  component: () => <Navigate  to={URLS.RIDES} />  },
  ],
  [USER_ROLES.HO_EMPLOYEE]: [
    { id: 0, path: URLS.DRAFTS, exact: true, component: HO_Drafts },
    { id: 1, path: URLS.EDIT_DRAFT, exact: true, component: EditDraft },
    { id: 2, path: URLS.DRAFT_DETAILS, exact: true, component: DraftInfo },
    { id: 3, path: URLS.RIDES, exact: true, component: HO_Rides },
    { id: 4, path: URLS.CREATE_RIDE, exact: true, component: CreateRide },
    { id: 5, path: URLS.RIDE_DETAILS, exact: true, component: HO_RideDetails },
    { id: 6, path: URLS.EDIT_RIDE, exact: true, component: EditRide },
    // {/* ToDo Rescheudleing */} { id: 19, path: URLS.RESCHEDULE_RIDE, exact: true, component: RescheduleRide },
    //  { id: 18, path: URLS.RESCHEDULE_RIDE, exact: true, component: EditRide },
    { id: 7, path: URLS.USERS, exact: true, component: HO_Manager_Users },
    { id: 8, path: URLS.RIDERS, exact: true, component: Riders },
    { id: 9, path: URLS.CREATE_RIDER, exact: true, component: Create_Rider },
    { id: 8, path: URLS.EDIT_RIDER, exact: true, component: UpdateRider },
    { id: 10, path: URLS.PREFERENCES, component: HO_Preferences },
    {
      id: 11,
      path: URLS.HOME,
      exact: true,
      component: () => <Navigate  to={URLS.RIDES} />,
    },
    { id: 12, component: () => <Navigate  to={URLS.NOT_FOUND} /> },
    { id: 17, path:'*',  component: () => <Navigate  to={URLS.RIDES} />  },
  ],

  [USER_ROLES.TC_ADMIN]: [
    { id: 0, path: URLS.RIDES, exact: true, component: TC_Rides },
    { id: 1, path: URLS.RIDE_DETAILS, exact: true, component: TC_RideDetails },
    { id: 2, path: URLS.USERS, exact: true, component: TC_Admin_Users },
    { id: 3, path: URLS.CREATE_USER, exact: true, component: TC_CreateUser },
    { id: 4, path: URLS.EDIT_USER, exact: true, component: TC_UpdateUser },
    { id: 5, path: URLS.DRIVERS, exact: true, component: TC_Admin_Drivers },
    { id: 6, path: URLS.CREATE_DRIVER, exact: true, component: CreateDriver },
    { id: 7, path: URLS.EDIT_DRIVER, exact: true, component: UpdateDriver },
    { id: 8, path: URLS.BILLING, component: TC_Invoices },
    { id: 9, path: URLS.PREFERENCES, component: TC_Preferences },
    {
      id: 10,
      path: URLS.HOME,
      exact: true,
      component: () => <Navigate  to={URLS.RIDES} />,
    },
    {
      id: 11,
      path: URLS.CREATE_ADMIN_USER,
      exact: true,
      component: TC_CreateUser_admin,
    },
    { id: 12, component: () => <Navigate  to={URLS.NOT_FOUND} /> },
    { id: 17, path:'*',  component: () => <Navigate  to={URLS.RIDES} />  },
  ],
  [USER_ROLES.TC_EMPLOYEE]: [
    { id: 0, path: URLS.RIDES, exact: true, component: TC_Rides },
    { id: 1, path: URLS.RIDE_DETAILS, exact: true, component: TC_RideDetails },
    { id: 2, path: URLS.USERS, exact: true, component: TC_Manager_Users },
    { id: 3, path: URLS.DRIVERS, exact: true, component: TC_Admin_Drivers },
    { id: 4, path: URLS.CREATE_DRIVER, exact: true, component: CreateDriver },
    { id: 5, path: URLS.EDIT_DRIVER, exact: true, component: UpdateDriver },
    { id: 6, path: URLS.PREFERENCES, component: TC_Preferences },
    {
      id: 7,
      path: URLS.HOME,
      exact: true,
      component: () => <Navigate  to={URLS.RIDES} />,
    },
    { id: 8, component: () => <Navigate  to={URLS.NOT_FOUND} /> },
    { id: 17, path:'*',  component: () => <Navigate  to={URLS.RIDES} />  },
  ],
};
