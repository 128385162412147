import React, { useCallback } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { createRider } from '../../../store/riders';
import { BackIcon } from '../../../assets';
import { usePreventLeave } from '../../../hooks/usePreventLeave';
import { createRiderSchema, LodashHelper } from '../../../utils';
import { CreateRiderForm } from '../../../components/forms/CreateRider/CreateRider';
import { Button, CancelCreateModal } from '../../../components';
import { stepperInfo } from '../../../components/forms/CreateRide/stepperInfo';
import { getUniqueUserFieldValues } from '../../../hooks/useCheckForUniqueRider';

import styles from './index.module.css';
import { Navigation } from '../../../utils/navigation';

const initialValues = {
  firstName: '',
  lastName: '',
  communicationPreferences: [],
  preferredLanguage: '',
  phoneNumber: '',
  email: '',
  dob: '',
  patientUniqueId:'',
  canPhoneReceiveTexts: false,
  canPhoneReceiveCalls: false,
  homePhoneNumber: '',
};

const CreateRider = ({createRider, riderIsUnique }) => {
  const navigate = Navigation();
  
  const formik = useFormik({
    initialValues,
    validateOnMount: true,
    validateOnChange: false,
    validationSchema: createRiderSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
       
      unblockHistory?.current && unblockHistory.current();
 
      createRider(LodashHelper.removeEmpty(values),navigate);
    },
  });

  const [visibleModal, setModalVisible, unblockHistory, location] = usePreventLeave(initialValues, formik.values);

  const onCancel = useCallback(() => setModalVisible(false), [setModalVisible]);

  const onLeave = useCallback(() => {
    if (unblockHistory?.current) {
      unblockHistory.current();
      navigate.push(location?.location);
    }
  }, [ navigate,  location, unblockHistory]);

  const handleBack = useCallback(() => {
    navigate.back()
  }, [navigate,location]);

  return (
    <div className={styles.pageContent}>
      <div className={styles.header}>
        <div className={styles.backBtn} onClick={handleBack}>
          <BackIcon width='18px' height='18px' fill='#BDBDBD' />
        </div>
        <h1 className={styles.pageTitle}>Add New Rider</h1>
      </div>
      <div>
        <h2>Provide information for the new Rider</h2>
        <FormikProvider value={formik}>
          <form className={styles.form} onSubmit={formik.handleSubmit}>
            <CreateRiderForm
              values={formik?.values}
              touched={formik?.touched}
              errors={formik?.errors}
              riderIsUnique={riderIsUnique}
              allowUniqueCheck={
                !LodashHelper.isEqual(
                  getUniqueUserFieldValues(stepperInfo[0].values.riderInfo),
                  getUniqueUserFieldValues(formik?.values),
                )
              }
            />
            <div className={styles.btnPanel}>
              <div className={styles.btnControl}>
                <Button myClassName={styles.navigationBtn} onClick={handleBack}>
                  Cancel
                </Button>
              </div>

              <div className={styles.btnControl}>
                <Button disabled={!formik.isValid || !riderIsUnique} type='submit'>
                  Create Rider
                </Button>
              </div>
            </div>
          </form>
        </FormikProvider>
      </div>

      <CancelCreateModal
        visible={visibleModal}
        onCancel={onCancel}
        onLeave={onLeave}
        width={670}
        title={`Canceling New Rider`}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  riderIsUnique: state.riders.riderIsUnique,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ createRider }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CreateRider);
