import React, { useEffect, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { fetchTransportOrganizations, disableTO, clearTCInfo } from '../../../store/transport_organizations';
import { resendInvitationForUser, sendNewPassword, loginAsDifferentRole } from '../../../store/authorization';
import { EntitiesTableList } from '../../../components';
import { renderColumns, transformData } from './renderColumns';
import { URLS } from '../../../constants/constants';
import { redirectToPage } from '../../../utils/helpers';
import { usePagination } from '../../../hooks/usePagination';
import { useSearch } from '../../../hooks/useSearch';
import { Navigation } from '../../../utils/navigation';

const Transport_Companies = ({
  fetchTransportOrganizations,
  disableTO,
  transportOrganizations, 
  limit,
  currentPage,
  total,
  totalPages,
  sendNewPassword,
  clearTCInfo,
  resendInvitationForUser,
  loginAsDifferentRole,
}) => {
  const navigate = Navigation();
  usePagination(navigate);
  const { searchValue, highlighted, handleChangeSearch } = useSearch(fetchTransportOrganizations);
  
  useEffect(() => {fetchTransportOrganizations({Navigation:navigate}); return;}, [fetchTransportOrganizations]);

  useEffect(() => () => clearTCInfo() , [clearTCInfo]);

  const handleManageOrganization = useCallback(
    (id) => () => navigate.push(`/transport_companies/${id}/edit`),
    [navigate],
  );

  const switchOrganizationStatus = useCallback((status) => () => disableTO({...status,Navigation:navigate}), [disableTO]);

  const handleSendPassword = useCallback((id) => () => sendNewPassword(id), [sendNewPassword]);

  const handleResendInvitation = useCallback((id) => () => resendInvitationForUser(id), [resendInvitationForUser]);

  const handleChangePage = (page) => {
    redirectToPage(URLS.TRANSPORT_ORGANIZATIONS, page,navigate);
    fetchTransportOrganizations({value:searchValue,filtered:false,Navigation:navigate});
  };

  const handleViewDashboard = useCallback(
    (viewDashboardParams) => () => loginAsDifferentRole({...viewDashboardParams,Navigation:navigate}),
    [loginAsDifferentRole],
  );

  const tableData = useMemo(
    () =>
      transformData({
        data: transportOrganizations,
        handleManageOrganization,
        switchOrganizationStatus,
        handleSendPassword,
        handleResendInvitation,
        handleViewDashboard,
      }),

    [
      switchOrganizationStatus,
      handleManageOrganization,
      transportOrganizations,
      handleSendPassword,
      handleViewDashboard,
      handleResendInvitation,
    ],
  );

  const columns = useMemo(() => {
    return transportOrganizations?.length > 0 ? renderColumns(searchValue, highlighted) : null;
  }, [transportOrganizations, highlighted, searchValue]);

  return (
    <EntitiesTableList
      title='Transport Companies'
      addNewBtnTitle='Add New Company'
      Navigation={navigate}
      columns={columns}
      data={tableData}
      handleChangePage={handleChangePage}
      showAddNewButton
      handleChangeSearch={handleChangeSearch}
      searchValue={searchValue}
      limit={limit}
      currentPage={currentPage}
      total={total}
      totalPages={totalPages}
      searchPlaceholder='Search by name, email, state or county'
    />
  );
};

const mapStateToProps = (state) => ({
  transportOrganizations: state.transportOrganizations.transportOrganizations,
  limit: state.transportOrganizations.limit,
  currentPage: state.transportOrganizations.currentPage || 1,
  total: state.transportOrganizations.total,
  totalPages: state.transportOrganizations.totalPages,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchTransportOrganizations,
      disableTO,
      sendNewPassword,
      clearTCInfo,
      resendInvitationForUser,
      loginAsDifferentRole,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Transport_Companies);
