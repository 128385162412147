import React, { useCallback, useEffect } from 'react';
import classNames from 'classnames';
import { Formik } from 'formik';
import { useParams } from 'react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { ArrowIcon, BackIcon } from '../../../assets';
import { Button, Select } from '../../../components';
import { CANCEL_RIDE_OPTIONS, URLS, REASONS_TO_EDIT_RIDE, CANCEL_ROUNDTRIP_HEADING } from '../../../constants/constants';
import { driverCancelRideSchema, renderRecordValue } from '../../../utils';
import { getRide, tcCancelRide } from '../../../store/rides';

import styles from './index.module.css';
import { Navigation } from '../../../utils/navigation';
import { RideDestinationInfo } from '../../../components/common/RideDetails/RideDetailsPartials/RideDestinationInfo';
import { RidePickUpInfo } from '../../../components/common/RideDetails/RideDetailsPartials/RidePickUpInfo';

const DriverCancelRide = ({ seqId, role, ride, getRide, tcCancelRide }) => {
  const navigate = Navigation();
  const { id } = useParams();

  useEffect(() => { getRide(id, navigate); return; }, [id, getRide]);

  const handleBack = useCallback(() => navigate.back(), [navigate]);

  const handleFormSubmit = useCallback(
    (values) => async () => {
      try {
        // if (values?.reason === REASONS_TO_EDIT_RIDE.CUSTOM_REASON ) {
        //   // values.reason = values?.additionalComments;
        // }
        const reasonObjArr = CANCEL_RIDE_OPTIONS[role].filter(r => r.value === values?.reason);
        const cancelationType = Array.isArray(reasonObjArr) && reasonObjArr.length > 0 ? reasonObjArr[0]?.cancellationType : null;
        const payload = { reason: values?.reason, additionalComments: values?.additionalComments, cancelationType: cancelationType, connectedLegId: ride?.connectedLegId };
        await tcCancelRide({ rideId: id, payload: payload });
        navigate.push(URLS.RIDES);
      } catch (e) { }
    },
    [id, tcCancelRide, navigate],
  );
  const handleReasonForUpdate = useCallback(
    async ({ name, value, setFieldValue }) => {
      if (value !== REASONS_TO_EDIT_RIDE.CUSTOM_REASON)
        await setFieldValue("additionalComments", "");
      await setFieldValue(name, value);
    },
    []
  );
  return (
    <div className={styles.pageContent}>
      <div className={styles.header}>
        <div className={styles.backBtn} onClick={handleBack}>
          <BackIcon width="18px" height="18px" fill="#BDBDBD" />
        </div>
        <h1 className={styles.pageTitle}>
          Cancel Ride - ID {renderRecordValue(seqId)}
        </h1>
      </div>
      <Formik
        initialValues={{ reason: "" }}
        validationSchema={driverCancelRideSchema}
        validateOnMount
      >
        {({ isValid, values, handleSubmit }) => (
          <>


            {ride?.connectedLeg && <div className={styles.routes}>
            <h3 className={styles.roundTripHeading}>{CANCEL_ROUNDTRIP_HEADING}</h3>
                 
            <h3 className={styles.roundTripHeadingText}>Roundtrip Ride</h3>
              <RidePickUpInfo
                pickupAddressDetails={ride?.pickupAddressDetails}
                destinationAddress={ride?.destinationAddress}
                pickUpDate={ride?.pickUpDate}
                riderDescription={ride?.riderDescription}
                pickUpDateLowerLimit={ride?.pickUpDateLowerLimit}
                isPickUpDateFlexible={ride?.isPickUpDateFlexible}
                tcEstimatedPickUpDate={ride?.tcEstimatedPickUpDate}
                securityGate={ride?.pickUpSecurityGateDetails}
                flightInfo={ride?.flightInfo}
                appointmentDate={ride?.appointmentDate}
                isRecurringRide={ride?.isRecurring}
                leg={ride?.leg}
                seqId={ride?.seqId}
              />

              <div className={styles.destionationRouteInfo}>
                <div className={styles.arrows}>
                  {!(ride?.isRoundtrip && ride?.leg) && 
                    (
                    <ArrowIcon fill="#bdbdbd" className={styles.arrowDown} />
                  )}
                </div>

                <RideDestinationInfo
                  destinationAddress={ride?.destinationAddress}
                  originAddress={ride?.pickupAddressDetails}
                  showRoundtripInfo={
                    ride?.isRoundtrip && ride?.leg
                  }
                  securityGate={ride?.destinationSecurityGateDetail}
                  returnTime={ride?.connectedLeg?.pickUpDate}
                  returnTimeLowerLimit={ride?.returnTimeLowerLimit}
                  isReturnDateFlexible={ride?.isReturnDateFlexible}
                  leg={ride?.connectedLeg?.leg}
                  seqId={ride?.connectedLeg?.seqId}
                />
              </div>


            </div>
            }

            <form className={styles.form} onSubmit={handleSubmit}>
              <label className={styles.cancelRideLabel}>
                <span className={styles.required}>
                  Why are you unable to fulfill this ride?
                </span>
                <Select
                  name="reason"
                  options={CANCEL_RIDE_OPTIONS[role]}
                  placeholder="Pick an option"
                  onChange={handleReasonForUpdate}
                />
              </label>


              {/* <label className={styles.commentLabel}>
                <span  >
                  Additional Comments for ride Cancelation
                </span>

                <InputTextArea
                  name="additionalComments"
                  placeholder="Please fill in reason for ride Cancelation"
                  type="text"
                  autosize={{ minRows: 1, maxRows: 5 }}
                />
              </label> */}

            </form>

            <div
              className={classNames({
                [styles.cancelBtns]: true,
              })}
            >
              <Button myClassName={styles.navigationBtn} onClick={handleBack}>
              {ride?.connectedLeg? `Keep Roundtrip`: `Don’t Cancel`}  
              </Button>
              <Button
                type="submit"
                myClassName={styles.cancelRideBtn}
                disabled={!isValid}
                onClick={handleFormSubmit(values)}
              >
                {ride?.connectedLeg? `Cancel Roundtrip`: `Cancel Ride Request`}
              </Button>
            </div>
          </>
        )}
      </Formik>
    </div>
  );
};

const mapStateToProps = (state) => ({
  seqId: state.rides.ride?.seqId,
  role: state.authorization.role,
  ride: state.rides.ride
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ getRide, tcCancelRide }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DriverCancelRide);
