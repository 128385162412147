import { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { CANCEL_RIDE_PREFIX_BY_ROLES, USER_ROLES } from "../../../../constants/constants";

export const CancellationRideUserInfo = ({cancellationInfo, riderInfo, role}) => {
    const [fullName, setFullName] = useState('');
    const [prefix, setPrefix] = useState('');

    useEffect(() => {
        let orgPrefix = '', firstName = '', lastName= '';
        if(cancellationInfo?.user){
            const suffix = CANCEL_RIDE_PREFIX_BY_ROLES[role][cancellationInfo?.user?.role];
            const user = cancellationInfo?.user;
            
            if(role === USER_ROLES.SUPER_ADMIN) {
                firstName = user?.firstName;  lastName  = user?.lastName;
                if (user?.role === USER_ROLES.HO_EMPLOYEE || user?.role === USER_ROLES.HO_ADMIN) {
                    orgPrefix = cancellationInfo?.organization?.name;
                }
            }
            else if (role === USER_ROLES.HO_ADMIN ) {
                if (user?.role === USER_ROLES.HO_EMPLOYEE || user?.role === USER_ROLES.HO_ADMIN) {
                    firstName = user?.firstName;  lastName  = user?.lastName;
                    orgPrefix = cancellationInfo?.organization?.name;
                }
            }
            else if ([USER_ROLES.TC_ADMIN, USER_ROLES.TC_ADMIN, USER_ROLES.DRIVER].includes(role)) {
                if ([USER_ROLES.TC_ADMIN, USER_ROLES.TC_ADMIN, USER_ROLES.DRIVER].includes(user?.role)) {
                    firstName = user?.firstName;  lastName  = user?.lastName;
                }
            }
            
            setPrefix((orgPrefix+' '+ suffix));
            setFullName(firstName + ' ' + lastName);
        }
        else if(riderInfo) {
            setPrefix(CANCEL_RIDE_PREFIX_BY_ROLES[role]["RIDER"]);
            setFullName(riderInfo?.firstName + ' ' +riderInfo?.lastName);
        }
        
    },[cancellationInfo, riderInfo, role]);

    return (`${prefix}${fullName?.trim() && ', '}${fullName}`)
}

CancellationRideUserInfo.propTypes = {
    cancellationInfo: PropTypes.object, 
    riderInfo: PropTypes.object, 
    role: PropTypes.string.isRequired, 
}