import React, { useEffect } from "react";
import { FormikProvider, useFormik } from "formik";
import { renderNumericFields } from "../../forms/partials/renderNumericFields";

import styles from "../DriverWaitTime/index.module.css";

const RideLoadTime = ({ setfieldValue, loadUnloadValues,DEFAULT_LOAD_TIME, validationSchema, title, LoadUnloadFieldsArray }) => {
  const formik = useFormik({
    initialValues: loadUnloadValues || DEFAULT_LOAD_TIME,
    validationSchema,
    enableReinitialize: true,
    validateOnMount: true,
  });

  const { values } = formik;

  useEffect(() => {
    setfieldValue(values);
    return ()=>{};
  }, [values,  setfieldValue]);

  return (
    <div className={styles.waitTimeContainer}>
      <FormikProvider value={formik}>
        {title &&
         <label className={styles.waitTimeLabel}>
          <span className={styles.waitTimeTitle}>{title}</span>
        </label>}
        <form>
          <div className={styles.timeInputFieldsContainer}>
            {renderNumericFields(LoadUnloadFieldsArray)}
          </div>
        </form>
      </FormikProvider>
    </div>
  );
};
export default RideLoadTime;
