export const localStore = {
  get(key) {
    const data = localStorage.getItem(key);
    if (data) {
      return data;
    } else return null;
  },
  set(key, value) {
    localStorage.setItem(key, value);
  },
  remove(key) {
    localStorage.removeItem(key);
  },
};
