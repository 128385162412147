import { FormikProvider, getIn, useFormik } from 'formik';
import { useState } from 'react';

import { usePreventLeave } from '../../../../hooks/usePreventLeave';
import { DateHelper, platformSettingsSchema } from '../../../../utils';
import { renderPreventLeaveModal } from './partials/renderPreventLeaveModal';
import { RideExpirations } from './partials/RideExpirations';
import { NotificationSettings } from './NotificationsSettings';
import { useSwticherNotificationsModal } from '../../../';

import styles from './index.module.css';

export const RideExpirationSettings = ({
  platformPreferences,
  handleSaveChanges,
  defaultValues,
  initialValues,
  Navigation,
  updatePlatformSettings,
  updatePlatformValues,
}) => {
  const formik = useFormik({
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema: platformSettingsSchema,
    initialValues: platformPreferences ? platformPreferences : defaultValues,
  });

  const [swticherName, setSwitcherName] = useState('');

  const {
    Modal: SwitcherModal,
    setConfirmlModalVisible: setSwitcherModalVisible,
    confirmModalVisible: switcherModalVisible,
    resetForm,
    validateForm,
  } = useSwticherNotificationsModal({
    onConfirm: ({ updateReason }) => {
      updatePlatformValues({
        fieldName: swticherName,
        values: formik?.values,
        setFieldValue: formik?.setFieldValue,
        updateReason,
        onSuccessCallback: async () => {
          await setSwitcherModalVisible(false);
          await resetForm({});
          await validateForm();
        },
      });
    },
  });

  const [visibleModal, setModalVisible, unblockHistory, location] = usePreventLeave(initialValues, formik.values);

  const onCancel = () => setModalVisible(false);

  const onLeave = () => {
    if (unblockHistory.current) {
      unblockHistory.current();
      Navigation.push(location.location);
    }
  };

  const saveAndLeave = () => {
    onCancel();
    onLeave();
  };

  const handleSaveChangesAndLeave = () => {
    const { newOption, ...values } = formik?.values;
    if (newOption) values.rideOptions.push(newOption);
    updatePlatformSettings({
      payload: { platformSettings: DateHelper.transformTime(values, DateHelper.convertTimeToMinutes) },
      onSuccessCallback: saveAndLeave,
    });
  };

  const handleSaveChangesNotificationSwitchers =
    ({ values, fieldName }) =>
    () => {
      if (!getIn(values, fieldName)) {
        setSwitcherModalVisible(true);
        setSwitcherName(fieldName);
      } else {
        updatePlatformValues({ setFieldValue: formik?.setFieldValue, fieldName, values });
      }
    };
  return (
    <>
      <FormikProvider value={formik}>
        <form className={styles.form} onSubmit={formik.handleSubmit}>
          <RideExpirations
            {...formik}
            handleSaveChanges={handleSaveChanges(formik.setFieldValue)}
            initialValues={initialValues}
          />
        </form>
        <NotificationSettings
          {...formik}
          handleSaveChanges={handleSaveChanges(formik.setFieldValue)}
          handleSaveChangesNotificationSwitchers={handleSaveChangesNotificationSwitchers}
          initialValues={initialValues}
        />
      </FormikProvider>
      {visibleModal && renderPreventLeaveModal({ visibleModal, onCancel, onLeave, handleSaveChangesAndLeave })}
      {switcherModalVisible && SwitcherModal}
    </>
  );
};
