import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { unstable_HistoryRouter as Router } from "react-router-dom";
import { store } from './store';
import { MDRoutes } from './routes';
import reportWebVitals from './reportWebVitals';
import 'antd/dist/reset.css';
import './index.css'; 
import { history } from './utils';  

const App = () => (
  <Provider store={store}>
    <Router  history={history}  >
      <MDRoutes     />
    </Router>
    
  </Provider>
);

//createRoot(<App />, document.getElementById('root'));
ReactDOM.createRoot(
  document.getElementById("root"),
)
.render(
  <React.StrictMode>
    <App />
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
