import React from 'react';
import { Input as AntInput } from 'antd';

import { FastField, getIn } from 'formik';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './index.module.css';

const { TextArea } = AntInput;

export const CustomTextArea = ({
  field,
  form,
  type,
  value,
  onChange,
  disabled,
  myclassname,
  name,
  autosize,
  placeholder,
  handleBlurField,
  rows,
  ...rest
}) => {
  const handleChange = (event) => {
    if (onChange) {
      onChange(event);
    } else field.onChange(event);
  };

  const handleBlur = () => {
    if (handleBlurField) {
      handleBlurField();
    } else form.setTouched({ ...form.touched, [field.name]: true });
  };

  return (
    <TextArea
      {...rest}
      autosize={autosize}
      name={name || field?.name}
      type={type}
      rows={rows}
      value={value || field?.value}
      onChange={handleChange}
      onBlur={handleBlur}
      disabled={disabled}
      placeholder={placeholder}
      className={classnames({
        [styles.customInput]: true,
        [styles.input]: true,
        [styles.error]: !disabled && !getIn(form?.values, field?.name) && getIn(form?.errors, field?.name),
        [styles.error]:
          !disabled &&
          (getIn(form?.touched, field?.name) || form?.touched[field?.name]) &&
          getIn(form?.errors, field?.name),
        [myclassname]: myclassname,
      })}
      role='input'
    />
  );
};

export const InputTextArea = ({
  type,
  name,
  autosize,
  myClassName,
  value,
  onChange,
  disabled,
  placeholder,
  handleBlurField,
  rows,
  ...rest
}) => {
  return (
    <FastField
      name={name}
      type={type}
      disabled={disabled}
      autosize={autosize}
      value={value}
      handleBlurField={handleBlurField}
      placeholder={placeholder}
      myclassname={myClassName}>
      {({ field, form }) => {
        return (
          <>
            <CustomTextArea
              form={form}
              field={field}
              name={name}
              type={type}
              disabled={disabled}
              autosize={autosize}
              onChange={onChange}
              value={value}
              placeholder={placeholder}
              myclassname={myClassName}
              handleBlurField={handleBlurField}
              rows={rows}
              {...rest}
            />
            {!disabled &&
              (getIn(form.touched, field.name) || form.touched[field.name]) &&
              getIn(form.errors, field.name) && (
                <div className={styles.errorMessage} role='alert'>
                  {getIn(form.errors, field.name)}
                </div>
              )}
          </>
        );
      }}
    </FastField>
  );
};

TextArea.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  autosize: PropTypes.object,
  myClassName: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  handleBlurField: PropTypes.func,
   
};
