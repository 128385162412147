import React, { useEffect } from "react";
import { FormikProvider, useFormik } from "formik";
import { renderTimeInputFields } from "../../../components/forms/partials/renderTimeInputFields";
 
import { driverWaitTimeSchema } from "../../../utils/validations.js";

import styles from "./index.module.css";
import { DEFAULT_TIME } from "../../../constants/constants";
import { DateHelper } from "../../../utils";
import { InfoOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import classNames from "classnames";

const DriverWaitTime = ({ fieldName,  setWaitTime ,waitTime,isRoundTrip}) => {
  const timelineItemTail = "#de7f39";
  const formik = useFormik({
    initialValues: {
      [fieldName]:DateHelper.convertMinutesToTime(waitTime) || DEFAULT_TIME,
    },
    validationSchema: driverWaitTimeSchema,
    enableReinitialize: true,
    validateOnMount: true,
  });

  const { values } = formik;

  useEffect(() => {
    setWaitTime(values?.[fieldName]);
    return;
  }, [values,fieldName, setWaitTime]);

  return (
    <div className={styles.waitTimeContainer}>
      <FormikProvider value={formik}>
        <label className={styles.waitTimeLabel}>
          <span className={styles.waitTimeTitle}>{"Total Wait Time:"}</span>
        </label>
        <form>
          <div className={styles.timeInputFieldsContainer}>
            {renderTimeInputFields(fieldName)}
            {isRoundTrip && <Tooltip

            color={timelineItemTail}
            title={
              <span>
               Driver’s onsite wait time for roundtrip rides can be added here
              </span>
            }
          >
            <span
              className={classNames({
                [styles.tooltipInfo]: true,
               
              })}
            >
              <InfoOutlined className={styles.infoIcon} />
            </span>
          </Tooltip>
}
          </div>
        </form>
      </FormikProvider>
    </div>
  );
};
export default DriverWaitTime;
