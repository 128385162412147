import React from 'react';

import { Formik } from 'formik';
import classnames from 'classnames';

import { Input, Button } from '../../../';
import { resetPasswordSchema } from '../../../../utils';

import styles from './index.module.css';

export const PasswordStep = ({ values, handleBack, handleSubmit }) => {
  return (
    <Formik
      enableReinitialize
      validateOnMount
      initialValues={values}
      validationSchema={resetPasswordSchema}
      onSubmit={(values) => {
        handleSubmit(values);
      }}>
      {({ values, handleSubmit, ...rest }) => {
        return (
          <form onSubmit={handleSubmit} className={styles.resetForm}>
            <label
              className={classnames({
                [styles.inputControl]: styles.loginInputControl,
                [styles.passwordInput]: styles.passwordInput,
              })}>
              <span className={styles.required}>Create Password</span>
              <Input type='password' name='password' myClassName={styles.input} placeholder='Password' />
            </label>

            <div className={styles.btnsPanel}>
              <div
                className={classnames({
                  [styles.button]: true,
                  [styles.alignedButton]: true,
                })}>
                <Button myClassName={styles.navigationBtn} onClick={handleBack(values.password)}>
                  Previous Step
                </Button>
              </div>
              <div
                className={classnames({
                  [styles.button]: true,
                  [styles.alignedButton]: true,
                })}>
                <Button type='submit' disabled={!rest.isValid}>
                  Finish
                </Button>
              </div>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};
