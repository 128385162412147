import React from 'react';
import { Modal, Button } from '../..';

import styles from './index.module.css';

export const renderConfirmFlightInfoModal = ({ handleConfirm, handleCancel}) => (
      <Modal visible={true} onCancel={handleCancel} closable width={500} title="Please confirm this flight information" >
        <div className={styles.modalContent}>
          <p className={styles.modalDescription}>
            The driver will be following this flight (and 
            possible delays) to pick-up the rider(s).
            <span className={styles.modalSecondLine}>
              By clicking OK, you confirm this flight info.
            </span>
          </p>
          <div className={styles.modalBtns}>
            <div>
              <Button myClassName={styles.navigationBtn} onClick={handleConfirm}>
                OK
              </Button>
            </div>
            <div>
              <Button myClassName={styles.navigationBtn} onClick={handleCancel}>
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    );
