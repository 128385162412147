import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { CheckOutlined, CopyOutlined, PhoneOutlined } from "@ant-design/icons";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Input as AntInput } from "antd";
import { ArrowIcon } from "../../../assets";
import { DateHelper, renderRecordValue } from "../../../utils";
import { STATUSES_STYLING } from "../../../constants/styles/statuses";
import { Button } from "../../formControls/Button/Button";
import { Record, renderShowFlightInfoModal } from "../../";
import {
  RIDE_STATUSES,
  USA_COUNTRY_CODE,
  DEFAULT_LOAD_TIME,
  LOAD_UNLOAD_FIELD_ARRAY
} from "../../../constants/constants";
import { renderBuildingDetails } from "../RideDetails/RideDetailsPartials/BuildingDetails";

import styles from "./index.module.css";
import DriverWaitTime from "../DriverWaitTime/DriverWaitTime";
import { convertInchesToFeet } from "../../../utils/helpers";

import RideLoadTime from "../RideLoadTime/RideLoadTime";
import { rideLoadTimeSchema } from "../../../utils/validations";

const AddressComponent = ({ label, value, handleCopy, showFullInfo }) => {
  const [addressIsCopied, setAddressIsCopied] = useState(false);

  const copyTimer = useRef();

  const addressRef = useRef();

  const copyAddress = useCallback(
    (ref) => () => {
      setAddressIsCopied(true);
      handleCopy(ref);
    },
    [handleCopy]
  );

  useEffect(() => {
    copyTimer.current = setTimeout(() => {
      setAddressIsCopied(false);
    }, 1000);
    return () => clearTimeout(copyTimer.current);
  }, [addressIsCopied]);
  return (
    <>
      <p
        className={classNames({
          [styles.cardTitle]: true,
          [styles.defaultCardTitle]: showFullInfo,
        })}
      >
        {label}
      </p>
      <p
        className={classNames({
          [styles.cardValue]: true,
          [styles.fullInfoCardValue]: showFullInfo,
        })}
      >
        <span ref={addressRef}>{renderRecordValue(value)}</span>

        {showFullInfo &&
          (addressIsCopied ? (
            <Button
              myClassName={classNames({
                [styles.actionBtn]: true,
                [styles.copiedBtn]: addressIsCopied,
              })}
            >
              <CheckOutlined />
            </Button>
          ) : (
            <Button
              myClassName={styles.actionBtn}
              onClick={copyAddress(addressRef?.current)}
            >
              <CopyOutlined />
            </Button>
          ))}
      </p>
    </>
  );
};

export const DriverRideCard = ({
  showFullInfo,
  managePanelVisible,
  showRideStatus,
  ride,
  handleOpenRideDetails,
  handleCopy,
  setWaitTime,
  setLoadUnloadTime,
}) => {
  const [flightInfo, setFlightInfo] = useState(ride?.flightInfo || "");
  const [showFlightInfo, setShowFlightInfo] = useState(false);
  const pickUpDateTime = useMemo(() => {
    let dateTime;
    if (ride?.isPickUpDateFlexible && ride?.tcEstimatedPickUpDate?.datetime) {
      dateTime = DateHelper.renderDateTimeWithTZ({
        dateTime: ride?.tcEstimatedPickUpDate.datetime,
        timeZone: ride?.tcEstimatedPickUpDate.timezone,
      });
    } else if (showFullInfo && ride?.pickUpDate?.datetime) {
      dateTime = DateHelper.renderDateTimeWithTZ({
        dateTime: ride?.pickUpDate?.datetime,
        timeZone: ride?.pickUpDate?.timezone,
      });
    } else {
      dateTime = renderRecordValue(ride?.pickUpDate);
    }

    return renderRecordValue(dateTime);
  }, [ride, showFullInfo]);

  const requiresCarSeat =
    ride?.pickupPointAdditionalInfo?.needsAdditionalCarSeat;
  const requiresBoosterSeat = ride?.pickupPointAdditionalInfo?.needsBoosterSeat;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    setFlightInfo(ride?.flightInfo);
    return;
  }, [ride]);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const appointmentDateTime = useMemo(() => {
    let dateTime;
    if (showFullInfo && ride?.appointmentDate?.datetime) {
      dateTime = DateHelper.renderDateTimeWithTZ({
        dateTime: ride?.appointmentDate?.datetime,
        timeZone: ride?.appointmentDate?.timezone,
      });
    } else {
      dateTime = renderRecordValue(ride?.appointmentDate);
    }
    return renderRecordValue(dateTime);
  }, [ride, showFullInfo]);

  return (
    <div
      className={classNames({
        [styles.driverCard]: true,
        [styles.driverCardFullInfo]: showFullInfo,
        [styles.driverManagePanelVisible]: managePanelVisible,
      })}
      onClick={handleOpenRideDetails}
    >
      <AddressComponent
        label="Origin Address:"
        value={
          showFullInfo
            ? ride?.pickupAddressDetails?.formattedAddress
            : ride?.pickupAddress
        }
        showFullInfo={showFullInfo}
        handleCopy={handleCopy}
      />
      {ride?.pickupAddressDetails?.facilityName && (
        <AddressComponent
          label="Facility Name:"
          value={
            showFullInfo
              ? ride?.pickupAddressDetails?.facilityName
              : ride?.facilityName
          }
          showFullInfo={showFullInfo}
          handleCopy={handleCopy}
        />
      )}
      {
        <>
          {requiresCarSeat && (
            <>
              <p className={styles.cardValue}>Driver Provides Car Seat</p>
            </>
          )}

          {requiresBoosterSeat && (
            <>
              <p className={styles.cardValue}>Driver Provides Booster Seat</p>
            </>
          )}
        </>
      }
      {showFullInfo && (
        <>
          {renderBuildingDetails(ride?.pickupAddressDetails)}
          {ride?.pickupAddressDetails?.locationDescription && (
            <>
              <p className={styles.cardTitle}>Location Description:</p>
              <p className={styles.cardValue}>
                {renderRecordValue(
                  ride?.pickupAddressDetails?.locationDescription
                )}
              </p>
            </>
          )}
          {ride?.riderDescription && (
            <>
              <p
                className={classNames({
                  [styles.cardTitle]: true,
                  [styles.riderDescriptionTitle]: true,
                })}
              >
                Rider Description:
              </p>
              <p className={styles.cardValue}>
                {renderRecordValue(ride?.riderDescription)}
              </p>
            </>
          )}
          {showRideStatus && (
            <span
              className={classNames({
                [styles.status]: true,
                [styles.fullInfoStatus]: showFullInfo,
                [STATUSES_STYLING[ride?.readableStatus]]: true,
              })}
            >
              {renderRecordValue(ride?.readableStatus, "")}
            </span>
          )}
          {ride?.status === RIDE_STATUSES.UNABLE_TO_FULFILL && (
            <>
              <p className={styles.cardTitle}>Cancelation info:</p>
              <p className={styles.cardValue}>
                {renderRecordValue(ride?.cancelationInfo)}
              </p>
            </>
          )}
        </>
      )}
      <p className={styles.cardTitle}>{flightInfo ? "Airport Pickup Scheduled for": "Pickup Time"}</p>
      <p className={styles.cardValue}>{pickUpDateTime}</p>
      {ride?.appointmentDate && (
        <>
          <p className={styles.cardTitle}>Appointment Date Time:</p>
          <p className={styles.cardValue}>{appointmentDateTime}</p>
        </>
      )}
      {ride?.startDate && showFullInfo && (
        <>
          <p className={styles.cardTitle}>Ride Started:</p>
          <p className={styles.cardValue}>
            {DateHelper.renderDateTimeWithTZ({
              dateTime: ride?.startDate,
              timeZone: ride?.pickUpDate.timezone,
            })}
          </p>
        </>
      )}
      <div>
        <ArrowIcon fill="#bdbdbd" className={styles.arrowStatus} />
        {showRideStatus && !showFullInfo && (
          <span
            className={classNames({
              [styles.status]: true,
              [STATUSES_STYLING[ride?.readableStatus]]: true,
            })}
          >
            {renderRecordValue(ride?.readableStatus, "")}
          </span>
        )}
      </div>
      <AddressComponent
        value={
          showFullInfo
            ? ride?.destinationAddress.formattedAddress
            : ride?.destinationAddress
        }
        showFullInfo={showFullInfo}
        handleCopy={handleCopy}
        label="Destination Address:"
      />
      {ride?.destinationAddress?.facilityName && (
        <AddressComponent
          label="Facility Name:"
          value={
            showFullInfo
              ? ride?.destinationAddress?.facilityName
              : ride?.facilityName
          }
          showFullInfo={showFullInfo}
          handleCopy={handleCopy}
        />
      )}
      {showFullInfo && renderBuildingDetails(ride?.destinationAddress)}
      {ride?.endDate && showFullInfo && (
        <>
          <p className={styles.cardTitle}>Ride Ended:</p>
          <p className={styles.cardValue}>
            {ride?.endDate &&
              DateHelper.renderDateTimeWithTZ({
                dateTime: ride?.endDate,
                timeZone: ride?.pickUpDate.timezone,
              })}
          </p>
          {ride?.endRideComment && (
            <div>
              <p className={classNames({ [styles.cardTitle]: true })}>
                Driver comment:
              </p>
              <p className={styles.cardValue}>{ride?.endRideComment}</p>
            </div>
          )}
        </>
      )}
      {ride?.pickUpSecurityGateDetails?.hasSecurityGate && (
        <div>
          <p className={classNames({ [styles.cardTitle]: true })}>
            Pickup Gate Entry Instructions:
          </p>
          <p className={styles.cardValue}>
            {ride?.pickUpSecurityGateDetails?.securityGateInstruction}
          </p>
        </div>
      )}
      {ride?.destinationSecurityGateDetail?.hasSecurityGate && (
        <div>
          <p className={classNames({ [styles.cardTitle]: true })}>
            Dropoff Gate Entry Instructions:
          </p>
          <p className={styles.cardValue}>
            {ride?.destinationSecurityGateDetail?.securityGateInstruction}
          </p>
        </div>
      )}
      {showFullInfo && (
        <>
          <p
            className={classNames({
              [styles.cardTitle]: true,
              [styles.defaultCardTitle]: showFullInfo,
            })}
          >
            Rider:
          </p>
          <p className={styles.cardValue}>
            {`${renderRecordValue(
              ride?.riderInfo.firstName
            )} ${renderRecordValue(ride?.riderInfo.lastName)}`}
            {ride?.riderInfo?.canPhoneReceiveCalls && (
              <Button
                myClassName={classNames({
                  [styles.actionBtn]: true,
                  [styles.phoneBtn]: true,
                })}
              >
                <a
                  className={styles.phoneLink}
                  href={`tel:${USA_COUNTRY_CODE}${ride?.riderInfo?.phoneNumber}`}
                >
                  <PhoneOutlined className={styles.phoneIcon} />
                </a>
              </Button>
            )}
          </p>
          {showFullInfo && ride?.pickupPointAdditionalInfo?.caregiver && (
            <>
              <p
                className={classNames({
                  [styles.cardTitle]: true,
                  [styles.defaultCardTitle]: showFullInfo,
                })}
              >
                Caregiver:
              </p>
              <p className={styles.cardValue}>
                {`${renderRecordValue(
                  ride?.pickupPointAdditionalInfo?.caregiver?.firstName
                )} ${renderRecordValue(
                  ride?.pickupPointAdditionalInfo?.caregiver?.lastName
                )}`}
                {ride?.pickupPointAdditionalInfo?.caregiver
                  ?.canPhoneReceiveCalls && (
                  <Button
                    myClassName={classNames({
                      [styles.actionBtn]: true,
                      [styles.phoneBtn]: true,
                    })}
                  >
                    <a
                      className={styles.phoneLink}
                      href={`tel:${USA_COUNTRY_CODE}${ride?.pickupPointAdditionalInfo?.caregiver?.phoneNumber}`}
                    >
                      <PhoneOutlined className={styles.phoneIcon} />
                    </a>
                  </Button>
                )}
              </p>
            </>
          )}
          {ride?.riderInfo?.riderDescription && (
            <Record
              title="Rider description"
              value={renderRecordValue(ride?.riderInfo?.riderDescription)}
            />
          )}
          {ride?.riderInfo?.weight && (
            <Record
              title="Weight, lb(s)"
              value={renderRecordValue(ride?.riderInfo?.weight)}
            />
          )}
          {ride?.riderInfo?.height && (
            <Record
              title="Height (Feet/Inches)"
              value={convertInchesToFeet(ride?.riderInfo?.height)}
            />
          )}
        </>
      )}
      <div className={styles.rideId}>
        <p className={styles.cardTitle}>Ride ID:</p>
        <p
          className={classNames({
            [styles.cardValue]: true,
            [styles.rideIdValue]: true,
          })}
        >
          {renderRecordValue(ride?.seqId)}
        </p>
      </div>

      {showFullInfo && ride?.flightInfo && (
        <>
          <div className={styles.rideId}>
            <p className={styles.cardTitle}>Flight Information:</p>
            <AntInput
              className={styles.infoInput}
              type="text"
              placeholder=""
              defaultValue={ride?.flightInfo}
              value={flightInfo}
              onChange={(event) => setFlightInfo(event.target.value)}
            />
          </div>
          {flightInfo !== "" && (
            <p
              onClick={() => setShowFlightInfo(true)}
              className={styles.linkText}
            >
              Check Update Flight Info From Google
            </p>
          )}
        </>
      )}

      {showFlightInfo &&
        renderShowFlightInfoModal({
          flightInfo,
          handleCancel: () => setShowFlightInfo(false),
        })}
      {ride?.rideNotes && (
        <>
          <p className={styles.cardTitle}>Other information /Notes:</p>
          <p className={styles.cardValue}>{ride?.rideNotes}</p>
        </>
      )}
      {showFullInfo && (
        <DriverWaitTime fieldName="waitTime" setWaitTime={setWaitTime} waitTime= {ride?.waitTime} isRoundTrip={ride?.leg === 'B'} />
      )}

      {showFullInfo && (
        <RideLoadTime
          title={"Rider Load Minutes:"}
          setfieldValue={setLoadUnloadTime}
          DEFAULT_LOAD_TIME={DEFAULT_LOAD_TIME}
          validationSchema={rideLoadTimeSchema}
          LoadUnloadFieldsArray={LOAD_UNLOAD_FIELD_ARRAY}
          loadUnloadValues = {{loadTime: ride?.loadTime,unloadTime: ride?.unloadTime,  }}
        />
      )}
    </div>
  );
};

DriverRideCard.propTypes = {
  showFullInfo: PropTypes.bool,
  managePanelVisible: PropTypes.bool,
  showRideStatus: PropTypes.bool,
  ride: PropTypes.object,
  handleOpenRideDetails: PropTypes.func,
  handleCopy: PropTypes.func,
  setWaitTime: PropTypes.oneOfType([ PropTypes.func, PropTypes.any]),
  setLoadUnloadTime: PropTypes.oneOfType([ PropTypes.func, PropTypes.any]),
};
