import React from "react";
import PropTypes from "prop-types";

import {
  CloseCircleOutlined,
  InfoCircleOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";

import styles from "./index.module.css";
import { Button } from "../../formControls/Button/Button";

const renderIcon = (type) => {
  switch (type) {
    case "error":
      return <CloseCircleOutlined className={styles.closeIcon} />;

    case "warning":
      return <InfoCircleOutlined className={styles.closeIcon} />;

    case "success":
      return <CheckCircleOutlined className={styles.closeIcon} />;

    default:
      break;
  }
};

export const Snackbar = ({ type, title, content, actionBtn, handleActionBtn }) => {
  return (
    <div className={styles.notificationSection} role="alert">
      <div className={styles.notification}>
        <div>{renderIcon(type)}</div>
        <div className={styles.notificationMessage}>
          <h3 className={styles.notificationTitle}>{title}</h3>
          <div className={styles.notificationContent}>{content}</div>
        </div>
      </div>
      {actionBtn && <div className={styles.actionBtn}>
        <Button myClassName={styles.customBtn} onClick={handleActionBtn}> {actionBtn} </Button>
      </div>}
    </div>
  );
};

Snackbar.propTypes = {
  type: PropTypes.string,
  title: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]),
  content: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]),
};
