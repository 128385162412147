import classNames from 'classnames';

import { Button, DatePicker, Select } from '../../../components';
import { DEFAULT_TIME_FORMAT, RIDE_STATUSES, TIMEZONES } from '../../../constants/constants';
import { DatePickerToolTip } from './TooltipMessages';

import styles from './index.module.css';
import { getIn } from 'formik';

export const checkIsFieldRequired = (status) => classNames({ [styles.required]: status === RIDE_STATUSES.COMPLETED});
export const checkIsCancelStatusFieldRequired = (status) => classNames({ [styles.required]: status === RIDE_STATUSES.COMPLETED || status === RIDE_STATUSES.CANCELED });
export const checkIsCancelFieldRequired = (status) => classNames({ [styles.required]: status === RIDE_STATUSES.CANCELED});

const getPopupContainer = (trigger) => trigger;

export const DatePickerPanel = ({
  datepickerFieldName,
  timezoneFieldName,
  datePickerValue,
  values,
  handleChangeTZ,
  handleChangeDatePicker,
  datePickerLabel,
  showTime,
  disabled,
  disabledTime,
  disabledDate,
  defaultPickerValue,
  showTooltip,
  tooltipMessage,
}) => (
  <div className={styles.dateAndTime}>
    <label className={styles.timezone}>
      <span className={checkIsFieldRequired(values?.status)}>Timezone</span>
      <Select
        name={timezoneFieldName}
        onChange={handleChangeTZ}
        options={TIMEZONES}
        placeholder={'Pick one'}
        allowClear
        value={getIn(values, timezoneFieldName)}
      />
    </label>

    <div className={styles.range}>
      <label className={styles.startDate}>
        <span className={checkIsFieldRequired(values?.status)}>{datePickerLabel}</span>
        <DatePicker
          defaultPickerValue={defaultPickerValue || null}
          name={datepickerFieldName}
          allowClear
          onChange={handleChangeDatePicker}
          format={DEFAULT_TIME_FORMAT}
          value={datePickerValue}
          showTime={showTime}
          showNow={false}
          use12Hours
          disabledDate={disabledDate && disabledDate}
          disabledTime={disabledTime && disabledTime}
          disabled={disabled}
          inputReadOnly
          getPopupContainer={getPopupContainer}
        />
      </label>

      {showTooltip && <DatePickerToolTip message={tooltipMessage} />}
    </div>
  </div>
);

export const PickUpDateTimeRange = ({
  showTime,
  handleChangeTZ,
  ride,
  values,
  earliestDateValue,
  latestDateValue,
  handleChangeEarliestDateTime,
  handleChangeLatestDateTime,
  disabledLatestPicker,
  showTooltip,
  tooltipMessage,
}) => (
  <div className={styles.dateAndTime}>
    <label className={styles.timezone}>
      <span className={styles.required}>Timezone</span>
      <Select
        name='pickUpDate.timezone'
        onChange={handleChangeTZ}
        options={TIMEZONES}
        placeholder={'Pick one'}
        allowClear
        value={values?.pickUpDate.timezone}
      />
    </label>

    <div className={styles.range}>
      {ride?.isPickUpDateFlexible && (
        <label className={styles.startDate}>
          <span className={styles.required}>Earliest Requested Pickup Date</span>
          <DatePicker
            name='pickUpDateLowerLimit.datetime'
            allowClear
            format={DEFAULT_TIME_FORMAT}
            value={earliestDateValue}
            onChange={handleChangeEarliestDateTime}
            showTime={showTime}
            showNow={false}
            use12Hours
            disabled={!values?.pickUpDate?.timezone}
            inputReadOnly
            getPopupContainer={getPopupContainer}
          />
        </label>
      )}

      <label className={styles.startDate}>
        <span className={styles.required}>{ride?.isPickUpDateFlexible && 'Latest'} Requested Pickup Date</span>
        <DatePicker
          defaultPickerValue={earliestDateValue && new Date(earliestDateValue)}
          name='pickUpDate.datetime'
          allowClear
          format={DEFAULT_TIME_FORMAT}
          value={latestDateValue}
          onChange={handleChangeLatestDateTime}
          showTime={showTime}
          showNow={false}
          use12Hours
          disabledDate={disabledLatestPicker.disabledDate}
          disabledTime={disabledLatestPicker.disabledTime}
          disabled={!values?.pickUpDate?.timezone}
          inputReadOnly
          getPopupContainer={getPopupContainer}
        />
      </label>
      {showTooltip && <DatePickerToolTip message={tooltipMessage} />}
    </div>
  </div>
);

export const Buttons = ({ ride, handleBack, handleSubmit,handleRequestChange, saveChangesButtonIsDisabled }) => (
  <>
    <p className={styles.sendDescription}>
      Please make changes only to those fields that need to be changed in Ride Request
    </p>
    <div
      className={classNames({
        [styles.btns]: true,
        [styles.btnsWithFlexibleHours]: ride?.isPickUpDateFlexible,
      })}>
      <div className={styles.buttonControl}>
        <Button myClassName={styles.navigationBtn} onClick={handleBack}>
          Cancel Editing
        </Button>
      </div>
      <div className={styles.buttonControl} onClick={handleSubmit}>
        <Button type='submit' >
          Save Changes
        </Button>
      </div>

      {/* { <div className={styles.buttonControl}>
        <Button  disabled={!ride?.changeReqId} onClick={handleRequestChange}  >
         Update Change Request
        </Button>

      </div>} */}
    </div>
  </>
);
 

export const ChangeRequestButton = ({ ride, handleSubmit }) => (
  <>
    {/* <p className={styles.sendDescription}>
      If you have dispute click be
    </p> */}
    <div
      className={classNames({
        [styles.btns]: true,
      })}>

      {/* <div className={styles.buttonControl} onClick={handleSubmit}>
         <Button type='submit' disabled={ride?.changeReqId} >
          Change Request
        </Button>   
      </div> */}
    </div>
  </>
);
