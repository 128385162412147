import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { DateHelper, renderRecordValue } from '../../../../utils';
import { Record } from '../RideDetails';
import { convertInchesToFeet, convertRiderPreferences } from '../../../../utils/helpers';

import {
  communicationTypesDictionary,
  genderDictionary,
  impairmentsTypesDictionary,
  prefferedLanguageDictionary,
} from '../../../../dictionaries';
import { RiderPhoneCommunicationTypes, RiderPhoneInfo } from './RiderPhoneInfo';

import styles from '../index.module.css';

export const RiderInfo = ({ rider, showFullInfo, isMHAdmin,isHOWorker }) => (
  <>
    <div className={styles.ridersInfo}>
      <Record
        myClassName={styles.riderNameGap}
        title={'Rider'}
        value={`${renderRecordValue(rider?.firstName)} ${renderRecordValue(rider?.lastName)} `}
      />
      {showFullInfo && (
        <>
          <span
            className={classNames({
              [styles.separator]: true,
            })}
          />
          <RiderPhoneInfo phoneNumber={rider?.phoneNumber} />
        </>
      )}
    </div>
    <RiderPhoneCommunicationTypes data={rider} />
    {showFullInfo && (
      <>
        {rider?.homePhoneNumber && (
          <div className={styles.secondaryPhone}>
            <RiderPhoneInfo title="Rider's Secondary Phone Number:" phoneNumber={rider?.homePhoneNumber} />
          </div>
        )}
        {rider?.email && (
          <Record
            title="Rider's Email"
            value={
              <a className={styles.link} href={`mailto: ${rider?.email}`}>
                {renderRecordValue(rider?.email)}
              </a>
            }
            myClassName={styles.recordSection}
          />
        )}
        <Record
          title="Rider's Date of Birth"
          value={renderRecordValue(rider?.dob && DateHelper.formatDOBString(rider.dob))}
          myClassName={styles.recordSection}
        />
        <div className={styles.genderInfo}>
          {rider?.gender && (
            <>
              <Record title='Gender' value={renderRecordValue(genderDictionary[rider?.gender])} />
              {(rider?.preferredLanguage || rider?.weight || rider?.height) && <span className={styles.separator} />}
            </>
          )}
          {rider?.preferredLanguage && (
            <>
              <Record
                title='Preferred Language'
                value={renderRecordValue(prefferedLanguageDictionary[rider?.preferredLanguage])}
              />
             {(rider?.weight || rider?.height) && <span className={styles.separator} />}
            </>
          )}
          

          {rider?.weight && (
            <>
              <Record title='Weight, lb(s)' value={renderRecordValue(rider?.weight)} />
              {rider?.height && <span className={styles.separator} />}
            </>
          )}
          {rider?.height && <Record title='Height (Feet/Inches)' value={convertInchesToFeet(rider?.height)} />}
        </div>
        {((isMHAdmin || isHOWorker) && rider?.patientUniqueId) && (
            <>
              <Record
                title="Rider's Unique Identifier"
                value={renderRecordValue(rider?.patientUniqueId)}
              />
             
            </>
          )}
        <Record
          title='Communication Type(s)'
          value={
            rider?.communicationPreferences?.length > 0 &&
            renderRecordValue(convertRiderPreferences(communicationTypesDictionary, rider?.communicationPreferences))
          }
          myClassName={styles.seperateSection}
        />
        {rider?.impairments?.length > 0 && (
          <Record
            title='Impairment(s)'
            value={renderRecordValue(convertRiderPreferences(impairmentsTypesDictionary, rider?.impairments))}
            myClassName={styles.seperateSection}
          />
        )}
        {rider?.otherImpairments && (
          <ul className={styles.additionalOptions}>
            <li>{rider?.otherImpairments}</li>
          </ul>
        )}
        {rider?.homeAddress?.formattedAddress && (
          <Record
            title='Home Address'
            value={renderRecordValue(rider?.homeAddress?.formattedAddress)}
            myClassName={styles.homeAddress}
          />
        )}
      </>
    )}
  </>
);

RiderInfo.propTypes = {
  riderInfo: PropTypes.object,
  title: PropTypes.string,
  showFullInfo: PropTypes.bool,
};
