import React, { useEffect, useState } from 'react';
import { Button, Popconfirm, Rate, Input as AntInput } from "antd";
import { PopConfirm } from '../../../hocs/PopConfirm/PopConfirm';
import styles from "./index.module.css";
import { USER_STATUSES } from '../../../constants/constants';
 

export const MarkFavoriteGoogleAddess = React.memo(({
    form,
    field, 
    name,
    orgId,
    id,
    currentAddressObj,
    insertFavoriteAddress,
    handleRemoveAddress,
     isFavoriteAddress = false,
   
}) => {
     

   
    // console.log('currentAddressObj -->', currentAddressObj);
    let textInput = React.createRef();
    const [open,setOpen] = useState(false);

   const openPopUp=()=>
    {
        setOpen(true); 
    }
    const popConfirmProps = isFavoriteAddress ? {
        title: "Confirmation",
        description: "Are you sure you want to delete this address?",
        onConfirm: () => handleRemoveAddress(id),
    } : {
        title: "Location Name",
        description: (<AntInput placeholder="Location Name" maxLength={12} ref={textInput} />),
        onConfirm: ({user}) => {
            
          insertFavoriteAddress({ location: currentAddressObj, title: textInput?.current?.input?.value });
           

          if (textInput.current) {
            textInput.current.value = ''; // Reset the value to an empty string
          }

          setOpen(false); 
        },
        onCancel: () => {setOpen(false); }
    };
    return (
        <PopConfirm  {...popConfirmProps} open={open} >
            <Button type="ghost" onClick={openPopUp}>
                <Rate   count={1} value={open ? 1 : 0} />
            </Button>
        </PopConfirm>
    );
});