import React from 'react';
import classnames from 'classnames';
import { Formik, FieldArray } from 'formik';

import { Modal, Button, Checkbox } from '../../../components';
import { DECLINE_RIDE_OPTIONS } from '../../../constants/constants';

import styles from './index.module.css';

const renderCheckboxGroup = (values, name) => (arrayHelpers) => {
  const handleCheckbox =
    (option) =>
    ({ checked }) => {
      if (checked) arrayHelpers.push(option);
      else {
        const idx = values[name].indexOf(option);
        arrayHelpers.remove(idx);
      }
    };

  return DECLINE_RIDE_OPTIONS.map((opt) => (
    <label className={styles.checkbox} key={opt}>
      <Checkbox
        name='rideOptions'
        checked={values?.declineOptions?.includes(opt)}
        value={opt}
        onChange={handleCheckbox(opt)}
      />
      <span className={styles.checkboxLabel}>{opt}</span>
    </label>
  ));
};

export const DeclineRideModal = ({ id, visibleModal, onCancel, onConfirm }) => {
  return (
    <Modal visible={visibleModal} onCancel={onCancel} closable width={670} title={`Decline Ride Request – ID ${id}`}>
      <div className={styles.modalContent}>
        <p className={styles.modalDescription}>
          Do you want to decline this ride request? If so, please state the reason for declining the request.
          <br />
          <span className={styles.boldWeight}>
            If you decline this ride request, you will not be able to accept the request at a later date/time.
          </span>
        </p>

        <Formik
          initialValues={{
            declineOptions: [],
          }}>
          {({ values }) => {
            return (
              <>
                <form className={styles.declineForm}>
                  <div className={styles.checkboxesColumn}>
                    <FieldArray name='declineOptions' render={renderCheckboxGroup(values, 'declineOptions')} />
                  </div>
                </form>
                <div
                  className={classnames({
                    [styles.modalBtns]: true,
                  })}>
                  <div className={styles.archiveModalBtn}>
                    <Button myClassName={styles.navigationBtn} onClick={onCancel}>
                      Don’t Decline
                    </Button>
                  </div>
                  <div className={styles.archiveModalBtn}>
                    <Button myClassName={styles.navigationBtn} onClick={onConfirm(values.declineOptions)}>
                      Decline Ride
                    </Button>
                  </div>
                </div>
              </>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};
