import { jwtDecode } from "jwt-decode";

 

export const decodeToken = (token) => {
  try {
    return jwtDecode(token);
  } catch (e) {
    return e.message;
  }
};
