import React, { useState, useEffect, useCallback } from 'react';
import { Layout, Menu } from 'antd';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames';
 import { MaskOverlay, Burger } from '../../';
import { SIDEBAR_DRAWER_SIZE, USER_ROLES } from '../../../constants/constants';
import { sidebarTabs } from './sidebarMock';
import { useWindowSize } from '../../../hooks/useWindowSIze';

import styles from './index.module.css';
import { Navigation } from '../../../utils/navigation';
import { connect } from 'react-redux';

const { Sider } = Layout;

const renderTabs = ({ role, location, handleCloseMask,isViewDashboardMode }) => {
  //todo: have to remove this code in future
  if (USER_ROLES[role]) {
    return sidebarTabs[role].map((tab) => (
     // !isViewDashboardMode && tab.onlyMHAdmin ?'':
      <Menu.Item
        key={tab.id}
        icon={
          <span>
            <tab.Icon />
          </span>
        }
        className={classnames({
          'ant-menu-item-selected':  location?.pathname.includes(tab.path),
          [styles.customMenuItem]: true,
        })}>
        <NavLink to={tab.path} className={({isActive}) => (isActive ? styles.active : null)}    onClick={handleCloseMask}>
          {tab.title}
        </NavLink>
      </Menu.Item>
    ));
  } else return [];
};

const Sidebar = ({ role,isViewDashboardMode }) => {
  const [collapsedWidth, setCollapsedWidth] = useState('');
  const [expandWidth, setExpandWidth] = useState('');
  const [isMobileView, setIsMobileView] = useState(false);
  const [collapsed, setCollapsed] = useState(true);
  const size = useWindowSize();
  const navigate = Navigation(); 

  useEffect(() => {
    if (size[0] > SIDEBAR_DRAWER_SIZE.BREAKPOINT) {
      setCollapsedWidth(SIDEBAR_DRAWER_SIZE.COLLAPSED_WIDTH);
      setExpandWidth(SIDEBAR_DRAWER_SIZE.DESKTOP_EXPAND_WIDTH);
      return setIsMobileView(false);
    }
    setCollapsedWidth(0);
    setExpandWidth(SIDEBAR_DRAWER_SIZE.MOBILE_EXPAND_WIDTH);
    setIsMobileView(true);

    return;
  }, [size]);

  useEffect(() => {
    if (size[0] < SIDEBAR_DRAWER_SIZE.BREAKPOINT + 1) {
      return setIsMobileView(true);
    }
    setIsMobileView(false);
    return;
  }, [size]);

  useEffect(() => {setCollapsed(true); return;}, [navigate?.location]);

  const handleOpenBurgerMenu = useCallback(() => setCollapsed(!collapsed), [collapsed]);

  const handleCloseSidebar = () => {
    if (isMobileView) return;
    setCollapsed(true);
  };

  const handleOpenSidebar = () => {
    if (isMobileView) return;
    setCollapsed(false);
  };

  const handleCloseMask = useCallback(() => {
    setCollapsed(true);
  }, [setCollapsed]);

  return (
    <>
      <Sider
        collapsible
        collapsed={collapsed}
        className={styles.sidebar}
        onMouseEnter={handleOpenSidebar}
        onMouseLeave={handleCloseSidebar}
        width={expandWidth}
        collapsedWidth={collapsedWidth}
        trigger={null}>
        <Menu theme='dark' mode='inline' multiple={false} selectable={false} inlineIndent={isMobileView ? 12 : 24}>
          
          {renderTabs({ role, location:navigate?.location , handleCloseMask,isViewDashboardMode })}
          
        </Menu>
      </Sider>
      <Burger onClick={handleOpenBurgerMenu} collapsed={collapsed} />
      <MaskOverlay onClick={handleCloseMask} drawerIsOpen={isMobileView && !collapsed} />
    </>
  );
};

const mapStateToProps = (state) => ({
 
  isViewDashboardMode: state?.authorization?.viewDashboardMode?.active,
 
});

 
 

export default connect(mapStateToProps, null)(Sidebar);

 
