import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  getPlatformSettings,
  updatePlatformSettings,
  getAdditionalRideOptions,
  createAdditionalRideOption,
  changeAdditionalRideOptionStatus,
} from '../../../store/preferences';
import { changePassword, changeEmail, updatePersonalInformation } from '../../../store/authorization';

import { getLoading } from '../../../store/selectors/common';
import {
  RideExpirationSettings,
  AdditionalRideOptionsSettings,
  PasswordSettings,
  EmailSettings,
  PersonalInfoSettings,
  renderArchiveModal,
  renderUnarchiveModal,
  MedHaulPreferencesDefaultValues as defaultValues,
} from '../../../components';
import { usePreferencesHandlers } from './usePreferencesHandlers';

import styles from './index.module.css';
import { Navigation } from '../../../utils/navigation';

const MH_Preferences = ({
  platformPreferences,
  updatePlatformSettings,
  user,
  history, 
  changePassword,
  changeEmail,
  updatePersonalInformation,
  rideOptions,
}) => {

  
  const {
    allowScrollToPersonalInfo,
    handleCancelUnarchive,
    handleCancelArchive,
    handleSaveChanges,
    handleChangeRideOptionStatus,
    handleUnarchiveRideOption,
    handleArchiveRideOption,
    handleCreateOption,
    archivedOptions,
    activeOptions,
    unarchiveModalVisible,
    archiveModalVisible,
    TwoFactorAuthenticationModal,
    twoFactorModalVisible,
    setTwoFactorModalVisible,
    initialValues,
    securityRef,
    selectedRideOption,
    updatePlatformValues,
  } = usePreferencesHandlers({ platformPreferences, rideOptions, user });

  return (
    <div className={styles.pageContent}>
      <div className={styles.header}>
        <h1 className={styles.pageTitle}>Preferences</h1>
      </div>
      <div className={styles.pageSection}>
        <h2>MedHaul Settings</h2>

        <RideExpirationSettings
          defaultValues={defaultValues}
          initialValues={initialValues}
          handleSaveChanges={handleSaveChanges}
          updatePlatformValues={updatePlatformValues}
          Navigation={Navigation()}
          platformPreferences={platformPreferences}
          updatePlatformSettings={updatePlatformSettings}
        />

        <AdditionalRideOptionsSettings
          activeOptions={activeOptions}
          archivedOptions={archivedOptions}
          allOptions={rideOptions}
          handleCreateOption={handleCreateOption}
          handleArchiveRideOption={handleArchiveRideOption}
          handleUnarchiveRideOption={handleUnarchiveRideOption}
        />
      </div>

      <div className={styles.pageSection}>
        <PersonalInfoSettings
          user={user}
          updatePersonalInformation={updatePersonalInformation}
          rideOptions={rideOptions}
          allowScrollToPersonalInfo={allowScrollToPersonalInfo}
        />
      </div>

      <PasswordSettings user={user} changeEmail={changeEmail} />

      <EmailSettings
        securityRef={securityRef}
        changePassword={changePassword}
        setTwoFactorModalVisible={setTwoFactorModalVisible}
      />

      {renderArchiveModal({
        option: selectedRideOption,
        visibleModal: archiveModalVisible,
        onCancel: handleCancelArchive,
        handleChangeRideOptionStatus: handleChangeRideOptionStatus,
      })}
      {renderUnarchiveModal({
        option: selectedRideOption,
        visibleModal: unarchiveModalVisible,
        onCancel: handleCancelUnarchive,
        handleChangeRideOptionStatus: handleChangeRideOptionStatus,
      })}
      {twoFactorModalVisible && TwoFactorAuthenticationModal}
    </div>
  );
};

const mapStateToProps = (state) => ({
  platformPreferences: state.preferences.platformPreferences,
  rideOptions: state.preferences.rideOptions,
  user: state?.authorization?.user,
  loading: getLoading(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getPlatformSettings,
      updatePlatformSettings,
      changePassword,
      changeEmail,
      updatePersonalInformation,
      getAdditionalRideOptions,
      createAdditionalRideOption,
      changeAdditionalRideOptionStatus,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(MH_Preferences);
