import React from 'react';
import { DownOutlined } from '@ant-design/icons';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { CheckboxDropDown } from '../../FilterComponents/CheckboxDropdownOverlay/CheckboxDropdownOverlay';

import styles from './index.module.css';
import { getIn } from 'formik';

const renderDropDownComponent =
  ({ myClassName, hasValues, value, title }) =>
  ({ visible }) =>
    (
      <div
        className={classnames({
          [styles.headRow]: true,
          [myClassName]: myClassName,
          [styles.headRowActive]: visible,
          [styles.headRowDisabled]: !visible,
        })}>
        <span
          className={classnames({
            [styles.cellTitle]: true,
            [myClassName]: myClassName,
          })}>
          {hasValues && !visible ? value : title}
        </span>
        <DownOutlined
          className={classnames({
            [styles.icon]: true,
            [styles.disabled]: !visible,
          })}
        />
      </div>
    );

export const FilterCheckboxListCell = ({ value, name, title, values, myClassName, ...rest }) => (
  <CheckboxDropDown
    {...rest}
    values={values}
    name={name}
    title={title}
    Component={renderDropDownComponent({
      myClassName,
      value,
      hasValues: getIn(values, name)?.length > 0,
      title,
    })}
  />
);

FilterCheckboxListCell.propTypes = {
  value: PropTypes.string,
  options: PropTypes.array,
  name: PropTypes.string,
  title: PropTypes.string,
  values: PropTypes.object,
  myClassName: PropTypes.string,
};
