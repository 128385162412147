import React from 'react';
import { Popover } from 'antd';
import classNames from 'classnames';
import PropTypes from 'prop-types';

 

import styles from './index.module.css';
import { OverNightIcon } from '../../../assets/svgs/OverNightIcon';

export const OverNight = ({ record }) => {
   
  return record?.isOvernightRide ? (
    <Popover
          content={`Overnight Ride`}
          placement='topLeft'
          overlayClassName={classNames({
            [styles.overlay]: true
          })}
          getPopupContainer={(trigger) => trigger}>
          <div >
          <OverNightIcon width="20px" height="20px"></OverNightIcon>
          </div>
        </Popover>)
 
    : null;
};

OverNight.propTypes = {
  record: PropTypes.object,
};
