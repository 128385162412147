import React, { useState } from 'react';
import classnames from 'classnames';
import { FieldArray } from 'formik';
import { CaretDownOutlined } from '@ant-design/icons';

import { Input, Button } from '../../../../';

import styles from '../index.module.css';

export const AdditionalRideOptionsForm = ({
  activeOptions,
  archivedOptions,
  values,
  handleCreateOption,
  handleArchiveRideOption,
  handleUnarchiveRideOption,
}) => {
  let [showInput, setShowInput] = useState(false);
  const [showArchived, setShowArchived] = useState(false);

  return (
    <div className={styles.additionalRequestOptions}>
      <span className={styles.minimumTimeToRequest}>Additional Ride Options:</span>

      <div className={styles.optionsSection}>
        <span className={styles.activeTitle}>Active Options:</span>
        <FieldArray
          name='rideOptions'
          render={() => (
            <div className={styles.optionsRow}>
              {activeOptions.map((option, index) => (
                <div className={styles.addRideOptionField} key={index}>
                  <div className={styles.addtionalRequestOption}>
                    <p
                      data-testid='rideOption'
                      className={classnames({
                        [styles.rideOption]: true,
                        [styles.optionDisabled]: option.disabled,
                      })}>
                      {option.value}
                    </p>
                  </div>

                  <div
                    className={classnames({
                      [styles.buttonWrapper]: true,
                      [styles.tagPanel]: option.disabled,
                    })}>
                    <Button
                      myClassName={classnames({
                        [styles.formControlBtn]: true,
                      })}
                      onClick={(e) => {
                        e.preventDefault();
                        handleArchiveRideOption({ ...option, valueIndex: index });
                      }}>
                      Archive
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          )}
        />
      </div>

      {showInput ? (
        <div className={styles.addRideOptionField}>
          <div className={styles.addtionalRequestInput}>
            <Input
              type='text'
              name='newOption'
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  setShowInput(false);
                  handleCreateOption(values);
                }
              }}
            />
          </div>
          <div className={styles.btnsPanel}>
            <Button
              myClassName={classnames({
                [styles.formControlBtn]: true,
                [styles.saveBtn]: true,
              })}
              onClick={(e) => {
                e.preventDefault();
                setShowInput(false);
                handleCreateOption(values);
              }}
              disabled={!values.newOption}>
              Save
            </Button>
            <Button
              myClassName={styles.formControlBtn}
              onClick={(e) => {
                e.preventDefault();
                setShowInput(false);
              }}>
              Cancel
            </Button>
          </div>
        </div>
      ) : (
        <Button
          myClassName={classnames({
            [styles.formControlBtn]: true,
            [styles.addNewOptionBtn]: true,
          })}
          onClick={(e) => {
            e.preventDefault();
            setShowInput(true);
          }}>
          Add New
        </Button>
      )}

      <div className={styles.optionsSection}>
        <div className={styles.groupTitle}>
          <span className={styles.archivedSubTitle}>Archived Options:</span>
          <span
            className={classnames({
              [styles.showOptions]: true,
              [styles.hideOptions]: showArchived,
            })}
            onClick={() => setShowArchived(!showArchived)}>
            <span>Show Archived Options</span>
            <CaretDownOutlined className={styles.showOptionsArrow} />
          </span>
        </div>
        {showArchived && (
          <div className={styles.optionsRow}>
            {archivedOptions.map((option, index) => (
              <div className={styles.addRideOptionField} key={option.id}>
                <div className={styles.addtionalRequestOption}>
                  <p
                    className={classnames({
                      [styles.rideOption]: true,
                      [styles.optionDisabled]: option.disabled,
                    })}>
                    {option.value}
                  </p>
                </div>

                <div
                  className={classnames({
                    [styles.buttonWrapper]: true,
                    [styles.tagPanel]: option.disabled,
                  })}>
                  <Button
                    myClassName={classnames({
                      [styles.formControlBtn]: true,
                    })}
                    onClick={(e) => {
                      e.preventDefault();
                      handleUnarchiveRideOption({ ...option, valueIndex: index });
                    }}>
                    Unarchive
                  </Button>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
