import { baseRequest, DateHelper } from '../utils';
import { types as commonTypes } from './common';

const types = {
  GET_PLATFORM_SETTINGS_REQUEST: 'GET_PLATFORM_SETTINGS_REQUEST',
  GET_PLATFORM_SETTINGS_SUCCESS: 'GET_PLATFORM_SETTINGS_SUCCESS',
  GET_PLATFORM_SETTINGS_ERROR: 'GET_PLATFORM_SETTINGS_ERROR',

  UPDATE_PLATFORM_SETTINGS_REQUEST: 'UPDATE_PLATFORM_SETTINGS_REQUEST',
  UPDATE_PLATFORM_SETTINGS_SUCCESS: 'UPDATE_PLATFORM_SETTINGS_SUCCESS',
  UPDATE_PLATFORM_SETTINGS_ERROR: 'UPDATE_PLATFORM_SETTINGS_ERROR',

  GET_ADDITIONAL_RIDE_OPTIONS_REQUEST: 'GET_ADDITIONAL_RIDE_OPTIONS_REQUEST',
  GET_ADDITIONAL_RIDE_OPTIONS_SUCCESS: 'GET_ADDITIONAL_RIDE_OPTIONS_SUCCESS',
  GET_ADDITIONAL_RIDE_OPTIONS_ERROR: 'GET_ADDITIONAL_RIDE_OPTIONS_ERROR',

  CREATE_ADDITIONAL_RIDE_OPTION_REQUEST: 'CREATE_ADDITIONAL_RIDE_OPTION_REQUEST',
  CREATE_ADDITIONAL_RIDE_OPTION_SUCCESS: 'CREATE_ADDITIONAL_RIDE_OPTION_SUCCESS',
  CREATE_ADDITIONAL_RIDE_OPTION_ERROR: 'CREATE_ADDITIONAL_RIDE_OPTION_ERROR',

  CHANGE_ADDITIONAL_RIDE_OPTION_STATUS_REQUEST: 'CHANGE_ADDITIONAL_RIDE_OPTION_STATUS_REQUEST',
  CHANGE_ADDITIONAL_RIDE_OPTION_STATUS_SUCCESS: 'CHANGE_ADDITIONAL_RIDE_OPTION_STATUS_SUCCESS',
  CHANGE_ADDITIONAL_RIDE_OPTION_STATUS_ERROR: 'CHANGE_ADDITIONAL_RIDE_OPTION_STATUS_ERROR',
};

const initialState = {
  platformPreferences: null,
  rideOptions: [],
};

const getPlatformSettingsRequest = () => ({
  type: types.GET_PLATFORM_SETTINGS_REQUEST,
});

const updatePlatformSettingsRequest = () => ({
  type: types.UPDATE_PLATFORM_SETTINGS_REQUEST,
});

const getAdditionalRideOptionsRequest = () => ({
  type: types.GET_ADDITIONAL_RIDE_OPTIONS_REQUEST,
});

const createAdditionalRideOptionRequest = () => ({
  type: types.CREATE_ADDITIONAL_RIDE_OPTION_REQUEST,
});

const changeAdditionalRideOptionStatusRequest = () => ({
  type: types.CHANGE_ADDITIONAL_RIDE_OPTION_STATUS_REQUEST,
});

export const getPlatformSettings = () => {
  const success = (response) => {
    return {
      type: types.GET_PLATFORM_SETTINGS_SUCCESS,
      payload: response,
    };
  };

  const failure = (error) => ({
    type: types.GET_PLATFORM_SETTINGS_ERROR,
    payload: error,
  });

  return (dispatch) => {
    dispatch(getPlatformSettingsRequest());
    return baseRequest('GET', '/platform-settings')
      .then((response) => dispatch(success(DateHelper.transformTime(response.data, DateHelper.convertMinutesToTime))))
      .catch((error) => {
        if (error) dispatch(failure(error?.response?.data));
      });
  };
};

export const updatePlatformSettings = ({ payload, onSuccessCallback }) => {
  const success = (response) => {
    return {
      type: types.UPDATE_PLATFORM_SETTINGS_SUCCESS,
      payload: response,
    };
  };

  const failure = (error) => ({
    type: types.UPDATE_PLATFORM_SETTINGS_ERROR,
    payload: error,
  });

  return (dispatch) => {
    dispatch(updatePlatformSettingsRequest());
    return baseRequest('PUT', '/platform-settings', payload)
      .then((response) => {
        dispatch(success(response.data));
        if (onSuccessCallback) onSuccessCallback();
      })
      .catch((error) => {
        if (error) dispatch(failure(error?.response?.data));
      });
  };
};

export const getAdditionalRideOptions = () => {
  const success = (response) => {
    return {
      type: types.GET_ADDITIONAL_RIDE_OPTIONS_SUCCESS,
      payload: response,
    };
  };

  const failure = (error) => ({
    type: types.GET_ADDITIONAL_RIDE_OPTIONS_ERROR,
    payload: error,
  });

  return (dispatch) => {
    dispatch(getAdditionalRideOptionsRequest());
    return baseRequest('GET', '/ride-options')
      .then((response) => {
        dispatch(success(response.data));
      })
      .catch((error) => {
        if (error) dispatch(failure(error?.response?.data));
      });
  };
};

export const createAdditionalRideOption = (payload) => {
  const success = (response) => {
    return {
      type: types.CREATE_ADDITIONAL_RIDE_OPTION_SUCCESS,
      payload: response,
    };
  };

  const failure = (error) => ({
    type: types.CREATE_ADDITIONAL_RIDE_OPTION_ERROR,
    payload: error,
  });

  return (dispatch) => {
    dispatch(createAdditionalRideOptionRequest());
    return baseRequest('POST', '/ride-options', payload)
      .then((response) => {
        dispatch(success(response.data));
      })
      .catch((error) => {
        if (error) dispatch(failure(error?.response?.data));
      });
  };
};

export const changeAdditionalRideOptionStatus = ({ status, id }) => {
  const success = (response) => {
    return {
      type: types.CHANGE_ADDITIONAL_RIDE_OPTION_STATUS_SUCCESS,
      payload: response,
    };
  };

  const failure = (error) => ({
    type: types.CHANGE_ADDITIONAL_RIDE_OPTION_STATUS_ERROR,
    payload: error,
  });

  return (dispatch) => {
    dispatch(changeAdditionalRideOptionStatusRequest());
    return baseRequest('PATCH', `/ride-options/${id}`, status)
      .then((response) => {
        dispatch(success(response.data));
      })
      .catch((error) => {
        if (error) dispatch(failure(error?.response?.data));
      });
  };
};

export default function preferences(state = initialState, action) {
  switch (action.type) {
    case types.GET_PLATFORM_SETTINGS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.GET_PLATFORM_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        platformPreferences: action.payload,
      };

    case types.GET_PLATFORM_SETTINGS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.UPDATE_PLATFORM_SETTINGS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.UPDATE_PLATFORM_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case types.UPDATE_PLATFORM_SETTINGS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.GET_ADDITIONAL_RIDE_OPTIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.GET_ADDITIONAL_RIDE_OPTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        rideOptions: action.payload.sort((a, b) => b.disabled - a.disabled),
      };

    case types.GET_ADDITIONAL_RIDE_OPTIONS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.CREATE_ADDITIONAL_RIDE_OPTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.CREATE_ADDITIONAL_RIDE_OPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        rideOptions: [...state.rideOptions, action.payload],
      };

    case types.CREATE_ADDITIONAL_RIDE_OPTION_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.CHANGE_ADDITIONAL_RIDE_OPTION_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.CHANGE_ADDITIONAL_RIDE_OPTION_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        rideOptions: [...state.rideOptions.filter((option) => option.id !== action.payload.id), action.payload].sort(
          (a, b) => b.disabled - a.disabled,
        ),
      };

    case types.CHANGE_ADDITIONAL_RIDE_OPTION_STATUS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case commonTypes.CLEAR_STORE_INFO:
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
