import React from 'react';

export const ArrowIcon = ({ fill, className }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={36}
      height={44}
      className={className}
      viewBox='0 0 36 44'
      fill={fill}>
      <path
        d='M30.6949 20.9602L24.9293 13.6477C24.8242 13.5142 24.6901 13.4062 24.5373 13.3319C24.3845 13.2577 24.2168 13.219 24.0469 13.2188H21.7688C21.5332 13.2188 21.4031 13.4895 21.5473 13.6758L26.6203 20.1094H5.34375C5.18906 20.1094 5.0625 20.2359 5.0625 20.3906V22.5C5.0625 22.6547 5.18906 22.7812 5.34375 22.7812H29.809C30.7512 22.7812 31.275 21.6984 30.6949 20.9602Z'
        fill='#BDBDBD'
      />
      <path
        d='M30.6949 22.3396L24.9293 29.6521C24.8242 29.7856 24.6901 29.8936 24.5373 29.9679C24.3845 30.0422 24.2168 30.0808 24.0469 30.0811H21.7688C21.5332 30.0811 21.4031 29.8104 21.5473 29.624L26.6203 23.1904H5.34375C5.18906 23.1904 5.0625 23.0639 5.0625 22.9092V20.7998C5.0625 20.6451 5.18906 20.5186 5.34375 20.5186H29.809C30.7512 20.5186 31.275 21.6014 30.6949 22.3396Z'
        fill={fill}
      />
    </svg>
  );
};
