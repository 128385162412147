import React, { useCallback, useEffect } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { InfoOutlined } from '@ant-design/icons';

import { Button, CancelCreateModal, CreateDriver as CreateDriverForm, Tooltip } from '../../../components';
import { createDriver } from '../../../store/drivers';
import { fetchTOAdminPreferences } from '../../../store/transport_organizations';
import { BackIcon, PreferencesIcon } from '../../../assets';
import { createDriverSchema, LodashHelper } from '../../../utils';
import { usePreventLeave } from '../../../hooks/usePreventLeave';
import { vehicleOptionsDictionary } from '../../../dictionaries';
import { URLS } from '../../../constants/constants';

import styles from './index.module.css';
import { Navigation } from '../../../utils/navigation';

const initialValues = {
  email: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  jobTitle: '',
  rideOptions: [],
  vehicleTypes: [],
  hasAccount: true,
  canReceiveSms: false,
};

const CreateDriver = ({  createDriver, rideOptions, vehicleTypes, fetchTOAdminPreferences }) => {
  const navigate = Navigation();
  const formik = useFormik({
    initialValues,
    validateOnMount: true,
    validationSchema: createDriverSchema,
    onSubmit: (values) => {
      unblockHistory?.current && unblockHistory.current();
      createDriver(LodashHelper.trimValues(values),navigate);
    },
  });
  const [visibleModal, setModalVisible, unblockHistory, location] = usePreventLeave(initialValues, formik?.values);

  useEffect(() => {fetchTOAdminPreferences(); return;}, [fetchTOAdminPreferences]);

  const handleBack = useCallback(() => navigate.push(URLS.DRIVERS), [navigate]);

  const onCancel = useCallback(() => setModalVisible(false), [setModalVisible]);

  const onLeave = () => {
    if (unblockHistory.current) {
      unblockHistory.current();
      navigate.push(location?.location?.pathname);
    }
  };
  return (
    <div className={styles.pageContent}>
      <div className={styles.header}>
        <div className={styles.backBtn} onClick={handleBack}>
          <BackIcon width='18px' height='18px' fill='#BDBDBD' />
        </div>
        <h1 className={styles.pageTitle}>Add New Driver</h1>
      </div>
      <div>
        <h2>Driver Information</h2>
        <FormikProvider value={formik}>
          <form className={styles.form} onSubmit={formik.handleSubmit}>
            <CreateDriverForm
              values={formik.values}
              rideOptions={rideOptions || []}
              vehicleTypes={vehicleTypes || []}
            />
            <div className={styles.btnPanel}>
              <div className={styles.btnControl}>
                <Button myClassName={styles.navigationBtn} onClick={handleBack}>
                  Cancel
                </Button>
              </div>
              <div className={styles.inviteBtnCtrl}>
                <div className={styles.btnControl}>
                  <Button disabled={!formik.isValid} type='submit'>
                    Add Driver
                  </Button>
                </div>
                <Tooltip
                  title={
                    <span className={styles.tooltipContent}>
                      The <span className={styles.italicText}>Vehicle Type(s)</span> fields are
                      <span
                        className={classNames({
                          [styles.boldText]: true,
                          [styles.required]: true,
                        })}>
                        required
                      </span>
                      for the driver. Please fill in this information in the Organization
                      <span className={styles.italicText}> Preferences </span>
                      tab {<PreferencesIcon fill='#fff' />}
                    </span>
                  }>
                  <span
                    className={classNames({
                      [styles.tooltipInfo]: true,
                      [styles.tooltipDisabled]: !rideOptions?.length && !vehicleTypes?.length,
                    })}>
                    <InfoOutlined className={styles.infoIcon} />
                  </span>
                </Tooltip>
              </div>
            </div>
          </form>
        </FormikProvider>
      </div>
      <CancelCreateModal title={`Canceling New Driver`} visible={visibleModal} onCancel={onCancel} onLeave={onLeave} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  rideOptions: state?.transportOrganizations?.TCPreferences?.rideOptions,
  vehicleTypes: state?.transportOrganizations?.TCPreferences?.vehicleTypes?.map((tt, idx) => ({
    id: idx,
    title: vehicleOptionsDictionary[tt],
    value: tt,
  })),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ createDriver, fetchTOAdminPreferences }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CreateDriver);
