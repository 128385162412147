import { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { getIn } from 'formik';
import { DatePicker, Select } from '../../../../../index';
import { TIMEZONES } from '../../../../../../constants/constants';
import { DateHelper, LodashHelper } from '../../../../../../utils';
import { renderSaveChangesButton } from '../../partials/SaveChangesButton';

import { INTERNAL_REPORT_NOTIFICATIONS_FIELDS } from '../../../../mock';
import styles from './index.module.css';

const customTimePickerPanel = (originalPanel) => (
  <>
    <div className={styles.panelRender}>
      <div>Hours</div>
      <div>Daylight</div>
    </div>
    {originalPanel}
  </>
);

const reminderDays = {
  DAY_AHEAD: 'DAY_AHEAD',
  SAME_DAY: 'SAME_DAY',
};

const reminderDaysOptions = [
  {
    title: 'On the previous day',
    value: reminderDays.DAY_AHEAD,
  },
  {
    title: 'On the day of the ride',
    value: reminderDays.SAME_DAY,
  },
];

export const InternalReportNotificationForm = ({ values, initialValues, errors, disabled = false, handleSaveChanges }) => {
  const parentFieldName = INTERNAL_REPORT_NOTIFICATIONS_FIELDS[0].parentFieldName;
  const hasValidationErrors = errors?.[parentFieldName];
  const [timePickerDate, setTimePickerDate] = useState(null);

  useEffect(() => {
    values?.internalReportSettings?.reminderHour &&
      setTimePickerDate(new Date(new Date().setHours(values?.internalReportSettings?.reminderHour)));

      return;
  }, [values?.internalReportSettings?.reminderHour]);

  const disabledTime = useCallback(() => {
    if (values?.internalReportSettings?.reminderDay === reminderDays.DAY_AHEAD)
      return {
        disabledHours: () => DateHelper.getRange(0, 24).splice(0, 16),
      };
    else if (values?.internalReportSettings?.reminderDay === reminderDays.SAME_DAY)
      return { disabledHours: () => DateHelper.getRange(0, 24).splice(10, 24) };
  }, [values?.internalReportSettings?.reminderDay]);

  const handleChangeTimePicker = async ({ setFieldValue, name, value, validateForm }) => {
    const minDateForDayAhead = new Date(new Date().setHours(16));

    const isSelectedDateAllowed = DateHelper.compareDates(value, new Date(new Date().setHours(16)));
    if (values?.internalReportSettings?.reminderDay === reminderDays.DAY_AHEAD && isSelectedDateAllowed) {
      await setFieldValue(name, new Date(value).getHours());
      await setTimePickerDate(value);
    } else if (values?.internalReportSettings?.reminderDay === reminderDays.DAY_AHEAD) {
      await setFieldValue(name, minDateForDayAhead.getHours());
      await setTimePickerDate(minDateForDayAhead);
    } else {
      await setFieldValue(name, value.getHours());
      await setTimePickerDate(value);
    }

    await validateForm();
  };

  const handleChangeInternalReportsReminderDay = async ({ name, value, setFieldValue, validateForm }) => {
    await setFieldValue(name, value);
    await setFieldValue(INTERNAL_REPORT_NOTIFICATIONS_FIELDS[2].name, null);
    await setTimePickerDate(null);
    await validateForm();
  };

  const isFormUpdated = () =>{
    return initialValues?.[parentFieldName] && !LodashHelper.isEqual(getIn(initialValues, parentFieldName), getIn(values, parentFieldName));
  }

  return (
    <>
      <span className={styles.internalReportsHead}>Internal Report Notification Preferences</span>
      <span
        className={classNames({
          [styles.internalReportsTitle]: true,
          [styles.required]: true,
        })}>
        Unaccepted Rides For Tomorrow (select time for the email notification)
      </span>
      <div className={classNames({[styles.internalReportsForm]: true})}>
        <label className={classNames({
            [styles.internalReportsLabel]: true,
            [styles.mr_20]: true,
          })}>
          <span className={styles.required}>{INTERNAL_REPORT_NOTIFICATIONS_FIELDS[0].title}</span>
          <Select name={INTERNAL_REPORT_NOTIFICATIONS_FIELDS[0].name} options={TIMEZONES} placeholder={'Pick one'} disabled={disabled} />
        </label>
        <label className={classNames({
            [styles.internalReportsLabel]: true,
            [styles.mr_20]: true,
          })}>
          <span className={styles.required}>{INTERNAL_REPORT_NOTIFICATIONS_FIELDS[1].title}</span>
          <Select
            name={INTERNAL_REPORT_NOTIFICATIONS_FIELDS[1].name}
            options={reminderDaysOptions}
            onChange={handleChangeInternalReportsReminderDay}
            myClassName={styles.scheduledRidesReminderDay}
            placeholder='Pick one'
            disabled={disabled}
          />
        </label>
        <label className={classNames({
            [styles.internalReportsLabel]: true,
            [styles.mr_20]: true,
          })}>
          <span className={styles.required}>{INTERNAL_REPORT_NOTIFICATIONS_FIELDS[2].title}</span>
          <DatePicker
            name={INTERNAL_REPORT_NOTIFICATIONS_FIELDS[2].name}
            format={'hh aaa'}
            myClassName={styles.customTimepicker}
            onChange={handleChangeTimePicker}
            picker={'time'}
            value={timePickerDate}
            use12Hours
            showNow={false}
            disabledTime={disabledTime}
            inputReadOnly
            placeholder='Pick time'
            panelRender={customTimePickerPanel}
            disabled={!values?.internalReportSettings?.reminderDay || disabled}
          />
        </label>
        { isFormUpdated() &&
          <label className={classNames({
            [styles.internalReportsLabel]: true,
            [styles.pt_20]: true,
          })}>
            {renderSaveChangesButton(parentFieldName, handleSaveChanges, values, hasValidationErrors) }
          </label>}
      </div>
    </>
  );
};
