import { Pagination as AntdPagination } from 'antd';

import PropTypes from 'prop-types';

import styles from './index.module.css';

export const Pagination = ({ defaultCurrent, total, totalPages, onChange, pageSize }) => {
  return (
    totalPages > 1 && (
      <AntdPagination
        className={styles.pagination}
        current={defaultCurrent}
        total={total}
        pageSize={pageSize}
        onChange={onChange}
      />
    )
  );
};

Pagination.propTypes = {
  defaultCurrent: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pageSize: PropTypes.number,
  total: PropTypes.number,
  totalPages: PropTypes.number,
  onChange: PropTypes.func,
};
