import React from 'react';

import styles from './index.module.css';

export const Redirect = ({ title = 'Redirecting' }) => (
  <div>
    <div className={styles.pageContent}>
      <h1 className={styles.pageTitle}>{title}</h1>
    </div>
  </div>
);
