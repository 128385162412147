import React, { useCallback, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { fetchDrafts, setDraftId, deleteDraft, setMyFilter, clearDraftsInfo } from '../../../store/drafts';
import { BaseTable, Pagination } from '../../../components';
import { URLS } from '../../../constants/constants';
import { usePagination } from '../../../hooks/usePagination';
import { redirectToPage } from '../../../utils/helpers';
import styles from './index.module.css';

const transformData = (data) => data.map((d) => ({ ...d, key: d.seqId }));

const Drafts = ({
  fetchDrafts,
  drafts,
  limit,
  currentPage,
  total,
  totalPages,
  Navigation,
  dataLoaded,
  renderColumns,
  renderTableHead,
  setMyFilter,
  clearDraftsInfo,
}) => {
  const navigate=Navigation;
  usePagination(Navigation);
  useEffect(() => {
    fetchDrafts({Navigation:navigate});
    return;
  }, [fetchDrafts]);

  useEffect(() => () => {clearDraftsInfo()
  return;
  }, [clearDraftsInfo]);

  useEffect(
    () => () => {
      if (!navigate?.location.pathname.includes(URLS.DRAFTS)) {
        setMyFilter({});
      }
     
    },
    [setMyFilter, navigate?.location.pathname],
  );

  const handleChangePage = useCallback(
    (page) => {
      redirectToPage(URLS.DRAFTS, page,navigate);
      fetchDrafts({Navigation:navigate});
    },
    [fetchDrafts],
  );
  const handleRowClick = useCallback((record) => () => navigate.push(`/drafts/${record.id}`), [navigate]);

  const components = useMemo(
    () => ({
      header: {
        wrapper: dataLoaded && renderTableHead,
      },
    }),
    [dataLoaded, renderTableHead],
  );

  return (
    <>
      <div className={styles.pageContent}>
        <div>
          <h1 className={styles.pageTitle}>Drafts</h1>
        </div>

        <div>
          <BaseTable
            components={components}
            data={transformData(drafts)}
            columns={dataLoaded && renderColumns}
            renderTableHead={renderTableHead}
            onRowClick={handleRowClick}
            addHoverEffectToRowTable
          />
        </div>
      </div>
      <div className={styles.pagination}>
        <Pagination
          defaultCurrent={currentPage}
          total={total}
          totalPages={totalPages}
          onChange={handleChangePage}
          pageSize={limit}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  drafts: state.drafts.drafts,
  limit: state.drafts.limit,
  currentPage: state.drafts.currentPage || 1,
  total: state.drafts.total,
  totalPages: state.drafts.totalPages,
  dataLoaded: state.drafts.dataLoaded,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchDrafts, setDraftId, deleteDraft, setMyFilter, clearDraftsInfo }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Drafts);
