import React, { Fragment, useCallback, useEffect, useRef } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { fetchRides, setFilteredStatus, setMyFilter } from '../../../store/rides';
import { renderHeadCells } from './renderHeadCells';
import { LodashHelper } from '../../../utils';
import { useFilterStatusHandler } from '../../../hooks/useFilterStatusHandler';
import { Navigation } from '../../../utils/navigation';

export const renderTableHead = () => () => {
  const filtered = useSelector((state) => state.rides.filtered);
  const myFilterParams = useSelector((state) => state.rides.myFilterParams);
const navigate = Navigation();
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: myFilterParams,
    enableReinitialize: true,
  });

  const { onClose } = useFilterStatusHandler(myFilterParams, formik?.values, setFilteredStatus);

  const fetchRidesData = useCallback(() => dispatch(fetchRides(true,navigate)), [dispatch]);
  const debounceLoadData = useRef(LodashHelper.debounce((values) => fetchRidesData(values), 1000)).current;

  useEffect(() => {
    if (!LodashHelper.isEqual(myFilterParams, formik.values)) {
    dispatch(setMyFilter(formik.values));
    }
    return;
  }, [dispatch, formik.values]);

  useEffect(() => {
    if (filtered) {
      debounceLoadData();
    }
    return;
  }, [formik.values, filtered, debounceLoadData]);

  return (
    <FormikProvider value={formik}>
      <thead className='ant-table-thead'>
        <tr>
          {renderHeadCells({
            values: formik.values,
            setFieldValue: formik.setFieldValue,
            onClose,
          }).map((c) => (
            <Fragment key={c.title}>{c.Component}</Fragment>
          ))}
        </tr>
      </thead>
    </FormikProvider>
  );
};
