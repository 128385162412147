import React, { useCallback, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { EntitiesTableList } from '../../../components';
import { fetchUsers, clearUsersInfo, changeUserStatus } from '../../../store/users';
import { resendInvitationForUser, sendNewPassword } from '../../../store/authorization';
import { useCurrentRole } from '../../../hooks/useCurrentRole';
import { useSearch } from '../../../hooks/useSearch';
import { renderColumns, transformData } from './renderColumns';
import { usePagination } from '../../../hooks/usePagination';
import { redirectToPage } from '../../../utils/helpers';
import {  Navigation } from '../../../utils/navigation';
 

const Users = ({
  title,
  addNewBtnTitle,
  managedRole,
  users,
  myEmail,
  limit,
  currentPage,
  total,
  totalPages,
  fetchUsers,
  sendNewPassword,
  changeUserStatus, 
  role,
  clearUsersInfo,
  resendInvitationForUser,
}) => {
   
  const  navigate = Navigation();
  usePagination(navigate);
  const { searchValue, highlighted, handleChangeSearch } = useSearch(fetchUsers);
  const { isAdmin,isHOAdmin,isTCAdmin} = useCurrentRole(role);

  useEffect(() => { 
    fetchUsers({Navigation:navigate});
    
    return;
  }, [fetchUsers]);

  useEffect(() => () => clearUsersInfo() , [clearUsersInfo]);

  const handleManageUser = useCallback(
    (id,Navigation) => () =>  navigate.push(`${Navigation.location.pathname}/${id}/edit`),
    [navigate],
  );

  const handleSendPassword = useCallback((id) => () => sendNewPassword(id), [sendNewPassword]);

  const handleResendInvitation = useCallback((id) => () => resendInvitationForUser(id), [resendInvitationForUser]);

  const switchUserStatus = useCallback((payload) => () => changeUserStatus(payload), [changeUserStatus]);

  const handleChangePage = (page) => {
    
    redirectToPage(navigate?.location.pathname, page,navigate);
    fetchUsers({value:searchValue,filtered:null,Navigation:navigate});
  };
  const tableData = useMemo(() => {


const data = transformData({
      data: users,
      switchUserStatus,
      handleManageUser,
      myEmail,
      managedRole,
      isAdmin,
      handleSendPassword,
      handleResendInvitation,
      Navigation:navigate
    });

    
    return data;
  }, [users, handleManageUser, isAdmin, myEmail, handleSendPassword, managedRole, switchUserStatus, handleResendInvitation,navigate]);

  const columns = useMemo(() => {
    return role && users?.length > 0 ? renderColumns(isAdmin, searchValue, highlighted) : null;
  }, [role, users, isAdmin, highlighted, searchValue]);

  return (
    <EntitiesTableList
      title={title}
      addNewBtnTitle={addNewBtnTitle}
      Navigation={navigate}
      columns={columns}
      data={tableData}
      handleChangePage={handleChangePage}
      showAddNewButton={isAdmin}
      showAddNewAdminButton={isHOAdmin || isTCAdmin}
      handleChangeSearch={handleChangeSearch}
      searchValue={searchValue}
      limit={limit}
      currentPage={currentPage}
      total={total}
      totalPages={totalPages}
    />
  );
};

const mapStateToProps = (state) => ({
  users: state.users.users,
  myEmail: state.authorization?.user?.email,
  limit: state.users.limit,
  currentPage: state.users.currentPage,
  total: state.users.total,
  totalPages: state.users.totalPages,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchUsers, sendNewPassword, resendInvitationForUser, clearUsersInfo, changeUserStatus }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Users);
