import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import styles from "./index.module.css";
import { Loader, Logo } from "../../../components";
import {
  setExternalDriverAcount,
  checkInvitationKey,
} from "../../../store/authorization";
import { getLoading } from "../../../store/selectors/common";

const SetExternalDriver = ({
  setExternalDriverAcount,
  captchaLoaded,
  loading,
  checkInvitationKey,
}) => {
  const [queryKey, setQueryKey] = useState("");

  useEffect(() => {
    if (window.location.search) {
      const urlParams = new URLSearchParams(window.location.search);
      const queryKey = urlParams.get("key");
      setQueryKey(queryKey);
    }
    return;
  }, []);

  useEffect(() => {
    queryKey && captchaLoaded && checkInvitationKey({ key: queryKey });
    queryKey && captchaLoaded && setExternalDriverAcount({ key: queryKey });

    return;
  }, [queryKey, captchaLoaded, checkInvitationKey]);

  const email = "support@gomedhaul.com";
  return (
    <div className={styles.loginWrapper}>
      <Loader loading={loading} />
      <Logo lg />
      <div className={styles.pageDescription}>
        <p>
          The email verification process for your account with MedHaul has been
          completed successfully.
        </p>
        <p>
          If you have any questions or concerns, please do not hesitate to
          contact our support team at
          <br />
          <a className={styles.link} href={`mailto: ${email}`}>
            {email}{" "}
          </a>
          . We greatly value your partnership, and we look forward to serving
          you in the future.
        </p>
        <p>Thank you, </p>
        <p>MedHaul Support Team</p>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: getLoading(state),
  captchaLoaded: state.common.captchaLoaded,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setExternalDriverAcount, checkInvitationKey }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SetExternalDriver);
