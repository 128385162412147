import React from "react";
import classnames from "classnames";
import { FlightInformation, RountTrip } from "../../../components";
import { renderRecordValue } from "../../../utils";
import { STATUSES_STYLING } from "../../../constants/styles/statuses";
import { getRiderActualHeightForBariaticVehicleType, getRiderActualWeightForBariaticVehicleType } from "../../../utils/helpers";

import styles from "./index.module.css";
import { vehicleOptionsDictionary } from "../../../dictionaries";
import { Popover } from "antd";
import { OverNight } from "../../../components/common/OverNight/OverNight";
import { RIDE_STATUSES } from "../../../constants/constants";
import { RescheduleRideIcon } from "../../../components/common/RescheduleRideIcon/RescheduleRideIcon";

export const renderColumns = () => {
  return [
    {
      title: "Organization",
      dataIndex: "healthOrganizationName",
      key: "healthOrganizationName",
      width: 180,
      render: (text) => (
        <div
          className={classnames({
            [styles.organizationCellValue]: true,
            [styles.cellValue]: true,
          })}
        >
          {renderRecordValue(text?.split("_").join(" "))}
        </div>
      ),
    },

    {
      title: "Transport Co.",
      dataIndex: "transportCompanyName",
      key: "transportCompanyName",
      width: 180,
      render: (text) => (
        <div
          className={classnames({
            [styles.organizationCellValue]: true,
            [styles.cellValue]: true,
          })}
        >
          {renderRecordValue(text?.split("_").join(" "))}
        </div>
      ),
    },
    {
      title: "Ride ID",
      dataIndex: "seqId",
      key: "seqId",
      width: 130,
      render: (text, record) => (
        <div
          className={classnames({
            [styles.draftIdCellValue]: true,
            [styles.cellValue]: true,
          })}
        >   
      
      {record?.flightInfo ? (
            <Popover
              content={<FlightInformation flightInfo={record?.flightInfo} />}
              placement="topLeft"
              getPopupContainer={() => document.querySelector(".ant-table")}
            >
              <span style={{ color: "blue", textDecorationLine: "underline" }}>
                {renderRecordValue(text)}
              </span>
            </Popover>
          ) : (
            renderRecordValue(text)
          )}
          
          <br />
          <div className={styles.dflex}>
           
           <OverNight record={record}/>
          <RountTrip record={record} />

{/* ToDo Rescheudleing */}
          {/* {(record?.status == RIDE_STATUSES.RESCHEDULED || record?.rescheduledFrom )&&   <RescheduleRideIcon record={record}/>} */}
          </div>
        </div>
      ),
    },

    {
      title: "Rider Name",
      dataIndex: "rider",
      key: "rider",
      width: 150,
      render: (text) => (
        <div
          className={classnames({
            [styles.riderCellValue]: true,
            [styles.cellValue]: true,
          })}
        >
          {renderRecordValue(text)}
        </div>
      ),
    },
    {
      title: "Vehicle Type",
      dataIndex: "vehicleType",
      key: "vehicleType",
      width: 250,
      render: (text, record) => (
        <>
          <div
            className={classnames({
              [styles.vehicleCellValue]: true,
              [styles.cellValue]: true,
            })}
          >
            {renderRecordValue(vehicleOptionsDictionary[text])}
          </div>
          <div
            className={classnames({
              [styles.vehicleCellValue]: true,
              [styles.cellValue]: true,
              [styles.actualWeight]: true,
            })}
          >
           <div> {getRiderActualWeightForBariaticVehicleType(text, record)}</div>
           <div> {getRiderActualHeightForBariaticVehicleType(text, record)}</div>
          </div>
        </>
      ),
    },

    {
      title: "Pickup Date",
      dataIndex: "pickUpDate",
      key: "pickUpDate",
      width: 200,
      render: (value) => (
        <div
          className={classnames({
            [styles.pickUpDateCellValue]: true,
            [styles.cellValue]: true,
          })}
        >
          {renderRecordValue(value)}
        </div>
      ),
    },

    {
      title: "Ride Status",
      dataIndex: "readableStatus",
      key: "readableStatus",
      width: 150,
      render: (text) => (
        <div
          className={classnames({
            [styles.statusCellValue]: true,
            [styles.cellValue]: true,
            [STATUSES_STYLING[text]]: true,
          })}
        >
          {renderRecordValue(text)}
        </div>
      ),
    },
    {
      title: "Recurring",
      dataIndex: "recurringRideIndex",
      key: "recurringRideIndex",
      width: 120,
      render: (text, record) => {
        return (
          <div
            className={classnames({
              [styles.recurringInfo]: true,
              [styles.recurringCellValue]: true,
              [styles.lastRide]: record.isLastRecurring,
            })}
          >
            {renderRecordValue(record.recurringRideIndex)}
          </div>
        );
      },
    },
  ];
};
