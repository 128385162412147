import { getIn } from 'formik';
import { Switcher } from '../../../../formControls/Switcher/Switcher';
import { renderSaveChangesButton } from '../partials/SaveChangesButton';

import styles from '../index.module.css';

const NoticationSwitcher = ({ name, value, onChange, label, initialValues, values, errors, handleSaveChanges }) => {
  return (
    <div className={styles.switcherField}>
      <label className={styles.switcherContact}>
        <span className={styles.switcher}>{label}</span>
        <Switcher name={name} value={value} onChange={onChange} />
      </label>
      {getIn(initialValues, name) !== undefined &&
        getIn(initialValues, name) !== getIn(values, name) &&
        renderSaveChangesButton(name, handleSaveChanges, values, Boolean(errors?.notificationsSwitcher?.[name]))}
    </div>
  );
};

export const NotificationsSwitchers = ({ values, errors, initialValues, handleSaveChangesNotificationSwitchers }) => {
  const renderNotificationSwitcherLabel = (switcherEnabled) => (switcherEnabled ? 'On' : 'Off');

  const onChange = async ({ name, value, setFieldValue }) => {
    await setFieldValue(name, value);
  };

  return (
    <div className={styles.supportContactBlock}>
      <NoticationSwitcher
        label={`Email Notifications are ${renderNotificationSwitcherLabel(values?.notificationsSwitcher?.emails)}`}
        name='notificationsSwitcher.emails'
        initialValues={initialValues}
        values={values}
        value={values?.notificationsSwitcher?.emails}
        onChange={onChange}
        handleSaveChanges={handleSaveChangesNotificationSwitchers}
        errors={errors}
      />
      <NoticationSwitcher
        label={`SMS Notifications are ${renderNotificationSwitcherLabel(values?.notificationsSwitcher?.sms)}`}
        name='notificationsSwitcher.sms'
        initialValues={initialValues}
        values={values}
        value={values?.notificationsSwitcher?.sms}
        onChange={onChange}
        handleSaveChanges={handleSaveChangesNotificationSwitchers}
        errors={errors}
      />
      <NoticationSwitcher
        label={`Push Notifications are ${renderNotificationSwitcherLabel(
          values?.notificationsSwitcher?.pushNotifications,
        )}`}
        name='notificationsSwitcher.pushNotifications'
        initialValues={initialValues}
        value={values?.notificationsSwitcher?.pushNotifications}
        values={values}
        onChange={onChange}
        handleSaveChanges={handleSaveChangesNotificationSwitchers}
        errors={errors}
      />
    </div>
  );
};
