import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { fetchUserData, leaveViewDashboardMode } from '../../../store/authorization';
import { Button, Logo, PopOver } from '../../';
import { resetDraft, saveUnSavedDraftAndLogOut } from '../../../store/drafts';
import { useCurrentRole } from '../../../hooks/useCurrentRole';
import { URLS } from '../../../constants/constants';
import { LodashHelper } from '../../../utils';

import styles from './index.module.css';
import { Navigation } from '../../../utils/navigation';

const renderHeadersElements = ({ isMHAdmin, isHOWorker, location, navigateToCreateRide }) => {
  if (isMHAdmin) {
    return <p className={styles.userRole}>Administrator</p>;
  } else
    return (
      isHOWorker && (
          <div
            className={classnames({
              [styles.requestRide]: isHOWorker,
              [styles.requestRide]: true,
            })}>
            <Button
              myClassName={classnames({
                [styles.requestRideBtn]: isHOWorker,
                [styles.active]: location?.pathname === URLS.CREATE_RIDE,
              })}
              disabled={location?.pathname === URLS.CREATE_RIDE}>
              <NavLink to={URLS.CREATE_RIDE} onClick={navigateToCreateRide}>
                Request a Ride
              </NavLink>
            </Button>
          </div>
      )
    );
};

export const Header = ({ user, role }) => {
 
  const navigate = Navigation();
  const [visible, setVisible] = useState(false);
  const { isMHAdmin, isHOWorker, isDriver } = useCurrentRole(role);
  const viewDashboardMode = useSelector((state) => state.authorization.viewDashboardMode);
  const dispatch = useDispatch();
 
  const handleLogout = useCallback(() => dispatch(saveUnSavedDraftAndLogOut(navigate)), [dispatch]);

  const handleLeaveViewDashboardMode = useCallback(() => { dispatch(leaveViewDashboardMode(navigate))}, [dispatch]);

  const handleRedirection = useCallback(() => navigate.push(URLS.RIDES), [navigate]);

  useEffect(() =>{ (LodashHelper.isEmpty(user) || !role) && dispatch(fetchUserData(navigate))
  
  return;
  }, [  user, role]);

  const toggleVisibility = useCallback(() => setVisible(!visible), [visible]);

  const navigateToCreateRide = (e) => {
    e.preventDefault();
    dispatch(resetDraft());
    navigate.push(URLS.CREATE_RIDE, { from: navigate?.location.pathname });
  };

  return (
    <header
      className={classnames({
        [styles.header]: true,
        [styles.driverHeader]: isDriver,
      })}>
      <Logo
        md
        className={classNames({
          [styles.logoView]: viewDashboardMode?.active,
        })}
        onClick={handleRedirection}
      />
      {viewDashboardMode?.active && <h2 className={styles.viewDashboardTitle}>{viewDashboardMode.organizationName}</h2>}
      {renderHeadersElements({ isMHAdmin, isHOWorker, location:navigate?.location, navigateToCreateRide })}
      <PopOver
        role={role}
        user={user}
        visible={visible}
        viewDashboardMode={viewDashboardMode?.active}
        handleLeaveViewDashboardMode={handleLeaveViewDashboardMode}
        handleLogout={handleLogout}
        toggleVisibility={toggleVisibility}
      />
    </header>
  );
};

Header.propTypes = {
  user: PropTypes.object,
  role: PropTypes.string,
};
