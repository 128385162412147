import React, { useCallback } from "react";
import { useSelector } from 'react-redux';
import { FormikProvider, useFormik } from "formik";
import { Input } from "../../formControls/Input/Input";
import { Button } from "../../formControls/Button/Button";
import { renderInputField, renderInputFieldRideCost } from "./renderInputField";

import {
  renderSJCRHFields,
  renderTimeInputSubFields,
} from "./renderTimeInputSubFields";
import { BILLING_TABS_OBJECT } from "../../../constants/constants";
import { DateHelper, LodashHelper, renderRecordValue } from "../../../utils";
import styles from "./index.module.css";
import { Record } from "../RideDetails/RideDetails";
import { AlertModal, AlertPricingSurchargeConfigurationModal } from "../../modals/AlertModal/AlertModal";

export const BillSegment = ({
  tab,
  billingSchema,
  Default_Value,
  onSubmitPricing,
}) => {
  const defaultPricingType = useSelector((state) => state?.healthOrganizations?.HOPreferences?.hoPricing[0]?.pricingType);
  const pricing = useSelector((state) => state?.healthOrganizations?.HOPricing);
  const onAlertClose = useCallback(async () => {
     
    formik.setFieldValue("additionalRate.milesSurcharge90Plus.enabled",Default_Value?.additionalRate?.milesSurcharge90Plus?.enabled);
    formik.setFieldValue("additionalRate.milesSurcharge35_90.enabled",Default_Value?.additionalRate?.milesSurcharge35_90?.enabled);
    formik.setFieldValue("additionalRate.milesSurcharge25_90.enabled",Default_Value?.additionalRate?.milesSurcharge25_90?.enabled);
    
    });

  const { alertModalVisible, setAlertModalVisible, Modal } =
  AlertModal({header:'Warning',message:'Mileage surcharge setup for both 25-90 and 90+ miles is required for receipt generation',onclose:onAlertClose});
 
  const formik = useFormik({
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: Default_Value,
    validationSchema: billingSchema,
    onSubmit: (values) => {
  

     
      if(values?.additionalRate?.milesSurcharge90Plus?.enabled == (tab ==BILLING_TABS_OBJECT.SJCRH? values?.additionalRate?.milesSurcharge35_90?.enabled : values?.additionalRate?.milesSurcharge25_90?.enabled ))
     {
       onSubmitPricing(values);
     }else
     {
      setAlertModalVisible(true);
     }
    },
  });

  
  const { values } = formik;
  
  const onChange = async ({ name, value, setFieldValue }) => {
    await setFieldValue(name, value);
  };

  return (
 
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
        {alertModalVisible && Modal}
          {tab === BILLING_TABS_OBJECT.Subscription && (
            <>
              <div className={styles.renderField}>
                <label className={styles.labelSubcsription}>
                  Subscription Fee
                </label>
                <div >
                  <div className={styles.inputfield} >
                    <Input
                      prefix={'$'}
                      name="subscriptionFee"
                      type="number"
                      min={0}
                      precision={2}
                      step={0.01}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.renderField}>
                <label className={styles.labelSubcsription} >
                  Rides Per Month
                </label>
                <div >
                  <div className={styles.inputfield} >
                    <Input
                      name="ridesPerMonth"
                      type="number"
                      min={0}
                      precision={0}
                      step={1}
                    />
                  </div>
                </div>
              </div>
           
          </>
        )}
        <label className={styles.labelBilling} >
          Ride Cost
        </label>

        {tab === BILLING_TABS_OBJECT.Transaction && (
          <div className={styles.fieldData}>
            {renderInputFieldRideCost(
              "flatRate.ambulatory",
              "Ambulatory", '0-25 Miles'
            )}
            {renderInputFieldRideCost(
              "flatRate.stretcher",
              "Stretcher"
            )}
            {renderInputFieldRideCost(
              "flatRate.wheelchair",
              "Wheelchair"
            )}
          </div>
        )}
        {tab === BILLING_TABS_OBJECT.Subscription && (
          <div className={styles.fieldData}>
            {renderTimeInputSubFields("flatRate.ambulatory", "Ambulatory", true)}
            {renderTimeInputSubFields("flatRate.stretcher", "Stretcher")}
            {renderTimeInputSubFields("flatRate.wheelchair", "Wheelchair")}
          </div>
        )}

        {tab === BILLING_TABS_OBJECT.SJCRH && (
          <>
            <div className={styles.fieldData}>
              {renderSJCRHFields("flatRate.isolation", "Isolation", true)}
              {renderSJCRHFields("flatRate.nonIsolation", "Reg/Non Isolation")}
            </div>

            <div className={styles.fieldData}>
              <label className={styles.labelBilling}>
                Mileage Surcharge
              </label>
              {renderInputField(
                "additionalRate.milesSurcharge35_90.fee",
                "additionalRate.milesSurcharge35_90",
                "35+ Miles",
                values?.additionalRate?.milesSurcharge35_90?.enabled,
                onChange
              )}

              {renderInputField(
                "additionalRate.milesSurcharge90Plus.fee",
                "additionalRate.milesSurcharge90Plus",
                "90+ Miles",
                values?.additionalRate?.milesSurcharge90Plus?.enabled,
                onChange
              )}
            </div>
          </>

        )}
        {tab !== BILLING_TABS_OBJECT.SJCRH && (
          <div className={styles.fieldData}>
            <label className={styles.labelBilling} >
              Mileage Surcharge
            </label>
            {renderInputField(
              "additionalRate.milesSurcharge25_90.fee",
              "additionalRate.milesSurcharge25_90",
              "25-90 Miles",
              values?.additionalRate?.milesSurcharge25_90?.enabled,
              onChange
            )}

            {renderInputField(
              "additionalRate.milesSurcharge90Plus.fee",
              "additionalRate.milesSurcharge90Plus",
              "90+ Miles",
              values?.additionalRate?.milesSurcharge90Plus?.enabled,
              onChange
            )}
          </div>
        )}
        <label className={styles.labelBilling} >
          Add-on Fee
        </label>
        <div className={styles.timeInputFieldsContainer}>
          {renderInputField(
            "holiday.fee",
            "holiday",
            "Holiday",
            values?.holiday.enabled,
            onChange
          )}

          {renderInputField(
            "inclementWeather.fee",
            "inclementWeather",
            "Inclement Weather",
            false,
            onChange
          )}

          {renderInputField(
            "overnightRide.fee",
            "overnightRide",
            "Overnight Ride",
            values?.overnightRide?.enabled,
            onChange
          )}

          {renderInputField(
            "rideNoShow.fee",
            "rideNoShow",
            "Rider No Show",
            values?.rideNoShow?.enabled,
            onChange
          )}

          {renderInputField(
            "sameDayCancellation.fee",
            "sameDayCancellation",
            "Same-Day Cancellation",
            values?.sameDayCancellation?.enabled,
            onChange
          )}

          {renderInputField(
            "waitTime.fee",
            "waitTime",
            "Wait Time (15 min increments)",
            values?.waitTime?.enabled,
            onChange
          )}
        </div>
        
        
         {pricing?.updatedBy && <Record
            myClassName={styles.riderNameGap}
            title={'Last Updated By'}
            value={` (${DateHelper.renderDateTime({dateTime:pricing?.updatedAt})}) ${renderRecordValue(pricing?.updatedBy?.firstName)} ${renderRecordValue(pricing?.updatedBy?.lastName)}`}
          />}
        <div className={styles.btnPanel}>
         
          <div className={styles.btnControl}>
            <Button disabled={LodashHelper.isEqual(values, Default_Value) && LodashHelper.isEqual(tab, defaultPricingType)}
              type="submit">Save Changes</Button>
          </div> 
        </div>
      </form>
    </FormikProvider>
   
  );
};
