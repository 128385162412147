import React from "react";

import { Navigate, useLocation } from "react-router-dom";

import { URLS } from "../constants/constants";
import LodashHelper from "./LodashHelper";

export const PrivateRoute = ({ children, user }) => {
  const location = useLocation();
  if (!LodashHelper.isEmpty(user)) {
    return children;
  } else {
    return (
      <Navigate
        to={URLS.LOGIN}
        state={{ from: location }}
      />
    );
  }
};
