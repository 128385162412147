import axios from 'axios';

import { store } from '../store';
import { logOut, resetAuthorization } from '../store/authorization';

import { showNotificationMessage } from '../components';
import { renderRecordValue } from './index';
import { ERROR_TYPES, TIMEOUT } from '../constants/constants';
import { clearAuthStorageInfo } from './helpers';

//todo: (wasim) change the timeout from 15 sec to 2 minute due to some ride queries taking more then 15 sec
const axiosInstance = axios.create({
  timeout: TIMEOUT,
  withCredentials: true,
});

const shouldIntercept = (error) => {
  try {
    const shouldBeIntercepted = error.response.status === 401 && !localStorage.getItem('access_token');
    if (error.response.status === 401 && localStorage.getItem('access_token')) {
      store.dispatch(resetAuthorization());
      clearAuthStorageInfo();
    }
    return shouldBeIntercepted;
  } catch (e) {
    return false;
  }
};

const setTokenData = (tokenData = {}, axiosClient) => {
  store.dispatch({ type: 'REFRESH_TOKEN_SUCCESS', payload: tokenData });
};

const handleTokenRefresh = () => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(`${process.env.REACT_APP_API_URL}/auth/refresh-token`, null)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const attachTokenToRequest = (request, token) => {
  request.headers['Authorization'] = 'Bearer ' + token;
};

export const authInterceptor = (axiosClient, customOptions = {}) => {
  let isRefreshing = false;
  let failedQueue = [];

  const options = {
    attachTokenToRequest,
    handleTokenRefresh,
    setTokenData,
    shouldIntercept,
    ...customOptions,
  };
  const processQueue = (error, token = null) => {
    failedQueue.forEach((prom) => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(token);
      }
    });

    failedQueue = [];
  };

  const interceptor = (error) => {
    if (!options.shouldIntercept(error)) {
      return Promise.reject(error);
    }

    if (error.config._retry || error.config._queued) {
      return Promise.reject(error);
    }

    const originalRequest = error.config;
    if (isRefreshing) {
      return new Promise(function (resolve, reject) {
        failedQueue.push({ resolve, reject });
      })
        .then((token) => {
          originalRequest._queued = true;
          options.attachTokenToRequest(originalRequest, token);
          return axiosClient.request(originalRequest);
        })
        .catch((err) => {
          return Promise.reject(error);
        });
    }

    originalRequest._retry = true;
    isRefreshing = true;
    return new Promise((resolve, reject) => {
      options.handleTokenRefresh
        .call(options.handleTokenRefresh)
        .then((tokenData) => {
          options.setTokenData(tokenData, axiosClient);
          options.attachTokenToRequest(originalRequest, tokenData.accessToken);
          processQueue(null, tokenData.accessToken);
          resolve(axiosClient.request(originalRequest));
        })
        .catch((err) => {
          processQueue(err, null);
          store.dispatch({ type: 'REFRESH_TOKEN_ERROR' });
          store.dispatch(logOut());
          showNotificationMessage({
            type: 'error',
            content: renderRecordValue(ERROR_TYPES.REFRESH_TOKEN, ERROR_TYPES.REFRESH_TOKEN),
          });
          // canceling throwing refresh token error through other error interceptors
          reject(err);
        })
        .finally(() => {
          isRefreshing = false;
        });
    });
  };

  axiosClient.interceptors.response.use(undefined, interceptor);
};
