class GoogleAddressHelper {
  transformFields = (location) => {
    let updatedValues = {};

    if (location.facilityName) {
      updatedValues.facilityName = location.facilityName;
    }


    if( location.address_components)
    {
    location.address_components.forEach((component) => {
      location[component.types[0]] = component.long_name;
    });
  }

    updatedValues.formattedAddress = location.formatted_address;
    updatedValues.coordinates = {
      lat: location.geometry?.location?.lat(),
      lng: location.geometry?.location?.lng(),
    };
    updatedValues.placeId = location.place_id;
    updatedValues.state = location.administrative_area_level_1 || Location.state;
    updatedValues.county = location.administrative_area_level_2 || Location.county;
    updatedValues.formattedAddress = location.formatted_address;
    updatedValues.locality = location.locality;
    updatedValues.political = location.political;
    updatedValues.route = location.route;
    updatedValues.postalCode = location.postal_code;
    updatedValues.streetNumber = location.street_number;

    return updatedValues;
  };
}

const GoogleAddressHelperInstance = new GoogleAddressHelper();
export default GoogleAddressHelperInstance;
