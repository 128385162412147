import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { PhoneOutlined } from '@ant-design/icons';

import { DateHelper, renderRecordValue } from '../../../../utils';
import { Button } from '../../../formControls/Button/Button';
import { STATUSES_STYLING } from '../../../../constants/styles/statuses';
import { RIDE_STATUSES } from '../../../../constants/constants';
import { statusesDictionary } from '../../../../dictionaries';
import { Record } from '../RideDetails';
import { RIDE_STATUS_MODALS_TYPES } from '../../../../pages/TC_Admin/TC_RideDetails/RideStatusModal';
import { renderPhoneNumber } from '../../../../utils/helpers';
import { USER_ROLES } from '../../../../constants/constants';
import styles from '../index.module.css';
import { CancellationRideUserInfo } from './CancellationRideUserInfo';

export const RideStatusInfo = ({
  riderInfo,
  driver,
  readableStatus,
  showFullInfo,
  handleRideStatusModal,
  startDate,
  endDate,
  driverArrivalTime,
  endRideComment,
  isTCWorker,
  isHOWorker,
  isMHAdmin,
  cancellationInfo,
  unableToFulfillInfo,
  declineInfo,
  timezone,
  role,
}) => {
  const showRideStartedInfo =
    readableStatus === statusesDictionary[RIDE_STATUSES.IN_PROGRESS] ||
    readableStatus === statusesDictionary[RIDE_STATUSES.COMPLETED];

  // const [showArrivalButton, setShowArrivalButton] = useState(false);
  // const [showStartButton, setShowStartButton] = useState(false);  

  
  //   if (
  //     readableStatus === statusesDictionary[RIDE_STATUSES.ACCEPTED] &&
  //     isTCWorker
  //   ) {
  //     console.log('Check for accepted ride button selection');
  //     if (!driverArrivalTime) {
  //       setShowStartButton(false);
  //       setShowArrivalButton(true);
  //     } else {
  //       setShowStartButton(true);
  //       setShowArrivalButton(false);
  //     }
  //   }
  

  const showEditStartButton =
    readableStatus === statusesDictionary[RIDE_STATUSES.IN_PROGRESS] &&
    readableStatus !== statusesDictionary[RIDE_STATUSES.COMPLETED] &&
    isTCWorker;

  const showRideEndedInfo = readableStatus === statusesDictionary[RIDE_STATUSES.COMPLETED];

  const showRideEndedButton = readableStatus === statusesDictionary[RIDE_STATUSES.IN_PROGRESS] && isTCWorker;

  const showAssignedDriver = isTCWorker && driver;

  const showHOMatchedDriverInfo = (isHOWorker || isMHAdmin) && driver;

  const showHOMatchedDriverEmail = isMHAdmin && driver;
  
  const showArrivalButton = readableStatus === statusesDictionary[RIDE_STATUSES.ACCEPTED] && isTCWorker && !driverArrivalTime;
  
  const showStartButton = readableStatus === statusesDictionary[RIDE_STATUSES.ACCEPTED] && isTCWorker && !!driverArrivalTime;

  const showCancellationInfo = cancellationInfo && readableStatus === statusesDictionary[RIDE_STATUSES.CANCELED];
  
  const showUnableToFulfillInfo =
    unableToFulfillInfo && (role === USER_ROLES.SUPER_ADMIN || readableStatus === statusesDictionary[RIDE_STATUSES.UNABLE_TO_FULFILL]);
  const showDeclineInfo = declineInfo && readableStatus === statusesDictionary[RIDE_STATUSES.DECLINED];

  let rideStatus = readableStatus;

  if (showCancellationInfo) {
    rideStatus = `Cancelled`;
  }
  // console.log('showFullInfo', showFullInfo);
  // console.log('showAssignedDriver', showAssignedDriver);
  // console.log('isTCWorker', isTCWorker);'
 
  
  return (
    
    <div className={classNames({
            [styles.rideStatusInfoSpace] : !isTCWorker
          })}>
      {showFullInfo && showAssignedDriver ? (
        <p>
          Driver
          <span className={STATUSES_STYLING[readableStatus]}>{` ${driver?.firstName} ${driver?.lastName} `}</span>
          assigned
        </p>
      ) : (
        showFullInfo && isTCWorker && <p className={styles.noDriver}>No Driver was assigned to this Ride yet </p>
      )}

      {showHOMatchedDriverInfo && (
        <p className={styles.driverMatched}>
          Matched with driver
          <span className={STATUSES_STYLING[readableStatus]}>{` ${driver?.firstName} ${driver?.lastName} `}</span>
          {showHOMatchedDriverEmail && (
            <a href={`mailto:${driver?.email}`} className={styles.link}>
              {driver?.email}
            </a>
          )}
          {driver?.phoneNumber && (
            <span className={STATUSES_STYLING[readableStatus]}>
              <a
                className={classNames({
                  [styles.phoneLink]: true,
                  [styles.driverPhone]: true,
                })}
                href={`tel:${renderPhoneNumber(driver?.phoneNumber)}`}>
                {renderPhoneNumber(driver?.phoneNumber)}
                <PhoneOutlined className={styles.phoneIcon} />
              </a>
            </span>
          )}
        </p>
      )}
      <Record
        title="Status"
        value={renderRecordValue(rideStatus)}
        valueClassName={STATUSES_STYLING[readableStatus]}
        myClassName={classNames({
          [styles.status]: !showFullInfo,
          [styles.statusFullInfo]: readableStatus === statusesDictionary[RIDE_STATUSES.CANCELED],
        })}
      />
      { showRideStartedInfo && !isHOWorker && driverArrivalTime && (
        <div className={styles.startDateInfo}>
          <Record
            title="Driver Arrived"
            myClassName={styles.startDateText}
            value={renderRecordValue(
              driverArrivalTime?.datetime &&
                driverArrivalTime?.timezone &&
                DateHelper.renderDateTimeWithTZ({
                  dateTime: driverArrivalTime?.datetime,
                  timeZone: driverArrivalTime?.timezone,
                })
            )}
          />

          {showEditStartButton && (
            <div>
              <Button
                myClassName={styles.editTimeBtn}
                onClick={handleRideStatusModal(true, RIDE_STATUS_MODALS_TYPES.DRIVER_ARRIVED)}>
                Edit Driver Arrived Time
              </Button>
            </div>
          )}
        </div>
        )
      }

      {showRideStartedInfo && (
        <div className={styles.startDateInfo}>
          <Record
            title="Ride started"
            myClassName={styles.startDateText}
            value={renderRecordValue(
              startDate.datetime &&
                startDate.timezone &&
                DateHelper.renderDateTimeWithTZ({
                  dateTime: startDate.datetime,
                  timeZone: startDate.timezone,
                })
            )}
          />

          {showEditStartButton && (
            <div>
              <Button
                myClassName={styles.editTimeBtn}
                onClick={handleRideStatusModal(true, RIDE_STATUS_MODALS_TYPES.EDIT_RIDE)}>
                Edit start time
              </Button>
            </div>
          )}
        </div>
      )}

      {showFullInfo &&
        (showRideEndedButton || showRideEndedInfo || showStartButton || showArrivalButton) && (
          <div className={styles.statusBtns}>
            {showRideEndedButton ? (
              <div>
                <Button
                  onClick={handleRideStatusModal(
                    true,
                    RIDE_STATUS_MODALS_TYPES.END_RIDE
                  )}
                >
                  Ride Ended
                </Button>
              </div>
            ) : (
              <div>
                {showRideEndedInfo ? (
                  <>
                    <Record
                      title="Ride ended"
                      myClassName={styles.startDateText}
                      value={renderRecordValue(
                        endDate.datetime &&
                          endDate.timezone &&
                          DateHelper.renderDateTimeWithTZ({
                            dateTime: endDate.datetime,
                            timeZone: endDate.timezone,
                          })
                      )}
                    />
                    {endRideComment && (
                      <div className={styles.driverComment}>
                        <span className={styles.recordTitle}>
                          Driver's comment:
                        </span>
                        <span>{endRideComment}</span>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {showArrivalButton ? (
                      <Button myClassName={styles.arrivalBtn}
                        onClick={handleRideStatusModal(
                          true,
                          RIDE_STATUS_MODALS_TYPES.DRIVER_ARRIVED
                        )}
                      >
                        Driver Check-In
                      </Button>
                    ) : (
                      showStartButton && (
                        <Button
                          onClick={handleRideStatusModal(
                            true,
                            RIDE_STATUS_MODALS_TYPES.START_RIDE
                          )}
                        >
                          Ride Started
                        </Button>
                      )
                    )}
                  </>
                )}
              </div>
            )}
          </div>
        )}

      {showCancellationInfo && cancellationInfo.organizationType !== 'Admin'  && (
        <div className={styles.cancellationInfoContainer}>
          {cancellationInfo?.createdAt && (
            <Record
              title="Date/Time of Cancellation"
              myClassName={styles.cancelation}
              value={renderRecordValue(
                cancellationInfo?.createdAt &&
                  DateHelper.renderDateTimeWithTZ({
                    dateTime: cancellationInfo.createdAt,
                    timeZone: timezone,
                  })
              )}
            />
          )}
          <Record
            title="Cancelled By"
            myClassName={styles.cancelation}
            value={CancellationRideUserInfo({
              cancellationInfo,
              riderInfo,
              role,
            })}
          />
          {cancellationInfo?.comment && (
            <Record
              title="Reason of Cancellation"
              myClassName={styles.cancelation}
              value={renderRecordValue(cancellationInfo?.comment)}
            />
          )}
        </div>
      )}

      {showUnableToFulfillInfo && (
        <div className={styles.cancellationInfoContainer}>
          <Record
            title='Date/Time ride request status changed to "Unable to Fulfill"'
            myClassName={styles.startDateText}
            value={renderRecordValue(
              unableToFulfillInfo?.createdAt &&
                DateHelper.renderDateTimeWithTZ({
                  dateTime: unableToFulfillInfo.createdAt,
                  timeZone: timezone,
                })
            )}
          />
          {unableToFulfillInfo.user && (
            <Record
              title='User name that changed status to "Unable to Fulfill"'
              value={renderRecordValue(
                `${unableToFulfillInfo.user.firstName} ${unableToFulfillInfo.user.lastName}`
              )}
              myClassName={styles.recordSection}
            />
          )}
          {unableToFulfillInfo.organization && !isTCWorker && (
            <Record
              title="Transportation Company associated with ride request"
              value={renderRecordValue(
                `${unableToFulfillInfo.organization.name}`
              )}
              myClassName={styles.recordSection}
            />
          )}
          {unableToFulfillInfo.comment && (
            <Record
              title="Unable to fulfill reason"
              value={renderRecordValue(unableToFulfillInfo.comment)}
              myClassName={styles.recordSection}
            />
          )}
        </div>
      )}

      {showDeclineInfo &&
        declineInfo.map((declineRecord) => (
          <div
            className={styles.cancellationInfoContainer}
            key={declineRecord?.createdAt}
          >
            <Record
              title="Date/Time ride was declined"
              myClassName={styles.startDateText}
              value={renderRecordValue(
                declineRecord.createdAt &&
                  DateHelper.renderDateTimeWithTZ({
                    dateTime: declineRecord.createdAt,
                    timeZone: timezone,
                  })
              )}
            />
            {declineRecord.user && (
              <Record
                title="User name that declined the ride"
                value={renderRecordValue(
                  `${declineRecord.user.firstName} ${declineRecord.user.lastName}`
                )}
                myClassName={styles.recordSection}
              />
            )}
            {declineRecord.comment && (
              <Record
                title="Reason ride was declined"
                value={renderRecordValue(declineRecord.comment)}
                myClassName={styles.recordSection}
              />
            )}
          </div>
        ))}
    </div>
  );
};

RideStatusInfo.propTypes = {
  riderInfo: PropTypes.object,
  driver: PropTypes.object,
  readableStatus: PropTypes.string,
  showFullInfo: PropTypes.bool,
  handleRideStatusModal: PropTypes.func,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  driverArrivalTime: PropTypes.object,
  isTCWorker: PropTypes.bool,
  isHOWorker: PropTypes.bool,
  isMHAdmin: PropTypes.bool,
};
