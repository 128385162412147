import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Formik } from 'formik';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { CreateHO, Button, DisableOrganizationModal, SwitchAdminForm, InviteUserForm } from '../../../components';
import {
  fetchHealthOrganization,
  updateHO,
  disableHO,
  switchHOAdmin,
  inviteNewUserForHo,
} from '../../../store/health_organizations';
import { fetchUsersByOrganization } from '../../../store/users';
import { BackIcon } from '../../../assets';
import { createHealthOrganizationSchema, GoogleAddressHelper, LodashHelper } from '../../../utils';
import { getSortedEmployees } from '../../../store/selectors/users';

import styles from './index.module.css';
import { Navigation } from '../../../utils/navigation';

const Update_Health_Organization = ({
  currentHealthOrganization,
  fetchHealthOrganization,
  updateHO,
  users,
  history,
  disableHO,
  switchHOAdmin,
  inviteNewUserForHo,
  fetchUsersByOrganization,
}) => {
  const navigate = Navigation();
  const [inviteUserModalVisible, setInviteUserModalVisible] = useState(false);
  const [disableOrganizationModalVisible, setDisableOrganizationModalVisible] = useState(false);
  const [updateAdminModalVisible, setUpdateAdminModalVisible] = useState(false);

  const formRef = useRef();

  const { id } = useParams();

  useEffect(() => {
    formRef?.current?.validateForm();
    return;
  });

  useEffect(() => {
    fetchHealthOrganization(id);
    return;
  }, [fetchHealthOrganization, id]);

  const handleBack = useCallback(() => navigate.back(), [navigate]);

  const onCancelDisableModal = useCallback(
    () => setDisableOrganizationModalVisible(false),
    [setDisableOrganizationModalVisible],
  );

  const handleDisableOrganization = useCallback(
    () => setDisableOrganizationModalVisible(true),
    [setDisableOrganizationModalVisible],
  );

  const onConfirmDisable = useCallback(() => disableHO({ id, disabled: true,Navigation:navigate }), [disableHO, id,navigate]);

  const handleSelectAddress = ({ value, values, setValues }) => {
    setValues({ ...values, location: GoogleAddressHelper.transformFields(value) });
  };

  const handleChangeAddress = ({ value, values, setValues }) => {
    setValues({
      ...values,
      location: {
        formattedAddress: value,
      },
    });
  };

  const onConfirmSwitchAdmin = ({ organizationId, userId }) =>
    switchHOAdmin({ organizationId, userId, closeModal: () => setUpdateAdminModalVisible(false) });

  const onConfirmInviteUser = ({ organizationId, values }) =>
    inviteNewUserForHo({ organizationId, values, closeModal: () => setInviteUserModalVisible(false) });

  const handleInviteUser = useCallback(() => setInviteUserModalVisible(true), [setInviteUserModalVisible]);

  const handleChangeAdmin = () => {
    fetchUsersByOrganization({ id });
    setUpdateAdminModalVisible(true);
  };

  const onCancelInviteUser = (resetForm) => () => {
    setInviteUserModalVisible(false);
    resetForm({ email: '' });
  };

  const onCancelSwitchAdmin = (resetForm) => () => {
    setUpdateAdminModalVisible(false);
    resetForm();
  };

  return (
    <div className={styles.pageContent}>
      <div className={styles.header}>
        <div className={styles.backBtn} onClick={handleBack}>
          <BackIcon width='18px' height='18px' fill='#BDBDBD' />
        </div>
        <h1 className={styles.pageTitle}>Organization Details</h1>
      </div>
      <div>
        <h2>Information on the Organization</h2>
        <Formik
          enableReinitialize
          validationSchema={createHealthOrganizationSchema}
          validateOnMount
          validateOnChange={false}
          initialValues={currentHealthOrganization || {}}
          onSubmit={({ admin, ...values }) => updateHO({ values: LodashHelper.trimValues(values), id ,Navigation:navigate})}>
          {({ values, ...rest }) => {
            return (
              <form className={styles.form} onSubmit={rest.handleSubmit}>
                <CreateHO
                  values={values}
                  handleSelectAddress={handleSelectAddress}
                  handleChangeAddress={handleChangeAddress}
                  switchAdmin
                  handleChangeAdmin={handleChangeAdmin}
                  handleInviteUser={handleInviteUser}
                  personalInfoFieldsDisabled
                  {...rest}
                />
                <div className={styles.btnPanel}>
                  <div className={styles.btnControl}>
                    <Button myClassName={styles.navigationBtn} onClick={handleDisableOrganization}>
                      Deactivate Organization
                    </Button>
                  </div>
                  <div className={styles.btnControl}>
                    <Button type='submit' disabled={!rest.isValid}>
                      Save Changes
                    </Button>
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      </div>

      <DisableOrganizationModal
        name={currentHealthOrganization?.name}
        visible={disableOrganizationModalVisible}
        onCancel={onCancelDisableModal}
        onConfirm={onConfirmDisable}
      />

      <SwitchAdminForm
        users={users}
        id={id}
        formRef={formRef}
        updateAdminModalVisible={updateAdminModalVisible}
        onCancel={onCancelSwitchAdmin}
        onConfirmSwitchAdmin={onConfirmSwitchAdmin}
      />

      <InviteUserForm
        id={id}
        formRef={formRef}
        inviteUserModalVisible={inviteUserModalVisible}
        onCancelInviteUser={onCancelInviteUser}
        onConfirmInviteUser={onConfirmInviteUser}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentHealthOrganization: state.healthOrganizations.currentHealthOrganization,
  users: getSortedEmployees(state, styles),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchHealthOrganization,
      updateHO,
      disableHO,
      fetchUsersByOrganization,
      switchHOAdmin,
      inviteNewUserForHo,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Update_Health_Organization);
