import classnames from 'classnames';
import { FieldArray, getIn } from 'formik';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Checkbox } from '../../../..';
import { FilterDropDown } from '../../FilterDropdown/FilterDropdown';

import styles from './index.module.css';

const renderOptions =
  ({ renderedValues, values, name }) =>
  (arrayHelpers) => {
    const handleCheckbox =
      (value) =>
      ({ checked }) => {
        if (checked) arrayHelpers.push(value);
        else {
          let idx = getIn(values, name).indexOf(value);
          if (idx < 0) {
            idx = values[name].findIndex((v) => v.id === value.id);
          }
          arrayHelpers.remove(idx);
        }
      };

    return renderedValues.map(({ value, disabled, title }) => {
      const isChecked = Boolean(
        values?.[name] && values[name]?.some((o) => (o.id ? o?.id === value?.id : o === value)),
      );

      return (
        <label
          className={classnames({
            [styles.checkbox]: true,
            [styles.statusCheckbox]: name === 'status',
          })}
          key={value.id || value}>
          <Checkbox
            name={name}
            checked={isChecked}
            value={value}
            onChange={handleCheckbox(value)}
            disabled={disabled}
          />
          <span className={styles.checkboxLabel}>{title}</span>
        </label>
      );
    });
  };

export const CheckboxDropDown = ({ Component, name, options, values, setFieldValue, shouldRenderSorting }) => {
  const [visible, setVisible] = useState(false);

  return (
    <th className='ant-table-cell'>
      <FilterDropDown
        visible={visible}
        content={
          <div className={styles.dropdownContent}>
            <FieldArray
              name={name}
              render={renderOptions({
                renderedValues: options,
                values,

                name,
              })}
            />
          </div>
        }
        name={name}
        values={values}
        setFieldValue={setFieldValue}
        shouldRenderSorting={shouldRenderSorting}
        onVisibleChange={setVisible}>
        <Component visible={visible} />
      </FilterDropDown>
    </th>
  );
};

CheckboxDropDown.propTypes = {
  Component: PropTypes.elementType,
  name: PropTypes.string,
  options: PropTypes.array,
  values: PropTypes.object,
};
