import { useState, useEffect } from "react";
import { USER_ROLES } from "../constants/constants";
//ToDo: isAdmin was used for admin account we need create different admin props for different type of admins
export const useCurrentRole = (role) => {
  const [isTCWorker, setIsTCWorker] = useState(false);
  const [isTCAdmin, setIsTCAdmin] = useState(false);
  const [isMHAdmin, setIsMHAdmin] = useState(false);
  const [isHOWorker, setIsHOWorker] = useState(false);
  const [isHOAdmin, setIsHOAdmin] = useState(false);
  const [isEmployee, setIsEmployee] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isDriver, setIsDriver] = useState(false);

  useEffect(() => {
    if (USER_ROLES[role] === USER_ROLES.TC_ADMIN) {
      setIsTCAdmin(true);
      return setIsTCWorker(true);
    } else if (USER_ROLES[role] === USER_ROLES.TC_EMPLOYEE) {
      return setIsTCWorker(true);
    } else if (USER_ROLES[role] === USER_ROLES.SUPER_ADMIN) {
      return setIsMHAdmin(true);
    } else if (USER_ROLES[role] === USER_ROLES.HO_ADMIN) {
      setIsHOAdmin(true);
      return setIsHOWorker(true);
    } else if (USER_ROLES[role] === USER_ROLES.HO_EMPLOYEE) {
      setIsHOWorker(true);
    }
    return;
  }, [role]);
  //Todo:(wasim) This code need refactoring for admin selection
  useEffect(() => {
    if (
      USER_ROLES[role] === USER_ROLES.TC_EMPLOYEE ||
      USER_ROLES[role] === USER_ROLES.HO_EMPLOYEE
    ) {
      setIsEmployee(true);
    } else if (
      USER_ROLES[role] === USER_ROLES.SUPER_ADMIN ||
      USER_ROLES[role] === USER_ROLES.HO_ADMIN ||
      USER_ROLES[role] === USER_ROLES.TC_ADMIN
    ) {
      setIsAdmin(true);
      if (USER_ROLES[role] === USER_ROLES.HO_ADMIN) {
        setIsHOAdmin(true);
      } else if (USER_ROLES[role] === USER_ROLES.TC_ADMIN) {
        setIsTCAdmin(true);
      }
    } else if (USER_ROLES[role] === USER_ROLES.DRIVER) {
      setIsDriver(true);
    }
    return;
  }, [role, isTCWorker, isHOWorker, isDriver, isHOAdmin, isTCAdmin, isAdmin]);

  return {
    isMHAdmin,
    isAdmin,
    isEmployee,
    isTCWorker,
    isHOAdmin,
    isHOWorker,
    isDriver,
    isTCAdmin,
  };
};
