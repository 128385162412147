import React, { useCallback, useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import classnames from 'classnames';
import { Button, Modal, Select } from '../..';
import { CANCEL_RIDE_OPTIONS, CANCEL_ROUNDTRIP_HEADING, CANCEL_ROUNDTRIP_POPUP_TITLE } from '../../../constants/constants';
import { roundTripCancelRideSchema } from '../../../utils/validations';

import styles from './index.module.css';
import { RideDestinationInfo } from '../../common/RideDetails/RideDetailsPartials/RideDestinationInfo';
import { RidePickUpInfo } from '../../common/RideDetails/RideDetailsPartials/RidePickUpInfo';

const initialValues = { reason: '', additionalComments: '' };

export const useCancelRideByHOModal = ({ id,role,ride, onConfirm }) => {
  const [visibleModal, setModalVisible] = useState(false);
 
  const handleConfirm = useCallback(
    (values) => {
     
      const reasonObjArr = CANCEL_RIDE_OPTIONS[role].filter(r => r.value === values?.reason);

      

      const cancelationType = Array.isArray(reasonObjArr) && reasonObjArr.length > 0 ? reasonObjArr[0]?.cancellationType : null;

      onConfirm({ reason: values?.reason, additionalComments: values?.additionalComments, cancelationType: cancelationType, connectedLegId: ride?.connectedLegId  });

 


    },
    [onConfirm],
  );

  const formik = useFormik({
    initialValues,
    validationSchema: roundTripCancelRideSchema,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: handleConfirm,
  });

  const { resetForm, validateForm, isValid, values, handleSubmit } = formik;

  const onCancel = useCallback(async() => {
    await resetForm(initialValues);
    await validateForm();
    await setModalVisible(false);
  }, [setModalVisible, validateForm, resetForm]);
  
  const handleReasonForUpdate = useCallback(async ({ name, value, setFieldValue }) => {
   // if (value !== REASONS_TO_EDIT_RIDE.CUSTOM_REASON) await setFieldValue('additionalComments', '');
    await setFieldValue(name, value);
  }, []);

  
  return {
    Modal: (
      <Modal
        visible={visibleModal}
        onCancel={onCancel}
        closable
        width='auto'
        bodyStyle={{ minHeight: '25vh' }}
        title={ ride?.connectedLeg ? CANCEL_ROUNDTRIP_POPUP_TITLE:`Canceling Ride Request – ID ${id}`}>
        <div className={ride?.connectedLeg && styles.modalContent}>
          <FormikProvider value={formik}>
            <>
           
              {ride?.connectedLeg &&  <div className={styles.routes}>
              <h3 className={styles.roundTripHeading}>{CANCEL_ROUNDTRIP_HEADING}</h3>
                 
                 <h3 className={styles.roundTripHeadingText}>Roundtrip Ride cancellation</h3>
                        <RidePickUpInfo
                            pickupAddressDetails={ride?.pickupAddressDetails}
                            destinationAddress={ride?.destinationAddress}
                            pickUpDate={ride?.pickUpDate}
                            riderDescription={ride?.riderDescription}
                            pickUpDateLowerLimit={ride?.pickUpDateLowerLimit}
                            isPickUpDateFlexible={ride?.isPickUpDateFlexible}
                            tcEstimatedPickUpDate={ride?.tcEstimatedPickUpDate}
                            securityGate={ride?.pickUpSecurityGateDetails}
                            flightInfo={ride?.flightInfo}
                            appointmentDate={ride?.appointmentDate}
                            isRecurringRide={ride?.isRecurring}
                            leg={ride?.leg}
                            seqId={ride?.seqId}
                        />

                        <div className={styles.destionationRouteInfo}>
                            <RideDestinationInfo
                                destinationAddress={ride?.destinationAddress}
                                originAddress={ride?.pickupAddressDetails}
                                showRoundtripInfo={
                                    ride?.isRoundtrip  && ride?.leg
                                }
                                securityGate={ride?.destinationSecurityGateDetail}
                                returnTime={ride?.connectedLeg?.pickUpDate}
                                returnTimeLowerLimit={ride?.returnTimeLowerLimit}
                                isReturnDateFlexible={ride?.isReturnDateFlexible}
                                leg={ride?.connectedLeg?.leg}
                                seqId={ride?.connectedLeg?.seqId}
                            />
                        </div>
                    </div>
  }

              <form >
                <label className={styles.cancelRideLabel}>
                  {isValid}
                  <span className={styles.required}>Reason you want to cancel this ride request?</span>
                  <Select name='reason' onChange={handleReasonForUpdate} options={CANCEL_RIDE_OPTIONS[role]} placeholder='Pick an option' />
                </label>
                {/* {(
                  <label className={styles.cancelRideLabel}>
                    <span  >Additional Comments for ride Cancelation</span>
                    <InputTextArea
                      name='additionalComments'
                      placeholder='Please fill in reason for ride Cancelation'
                      type='text'
                      autosize={{ minRows: 1, maxRows: 5 }}
                    />
                  </label>
                )} */}
              </form>

              <div className={classnames({
                        [styles.modalBtns]: true,
                        [styles.cancelBtns]: true,
                    })}>
              <div className={styles.ml6}>
                  <Button myClassName={styles.navigationBtn} onClick={handleSubmit} disabled={!isValid}>
                  {ride?.connectedLeg? `Cancel Roundtrip`: `Cancel Ride Request`}
                  </Button>
                </div>  
                
                <div className={styles.mr6}>
                  <Button myClassName={styles.navigationBtn} onClick={onCancel}>
                  {ride?.connectedLeg? `Keep Roundtrip`: `Don’t Cancel`}  
                  </Button>
                
                </div>
                
              </div>
            </>
          </FormikProvider>
        </div>
      </Modal>
    ),
    setCancelModalVisible: setModalVisible,
  };
};
