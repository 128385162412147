import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SetEmail from '../../common/SetEmail/SetEmail';

import { resendInvite } from '../../../store/authorization';
import { Navigation } from '../../../utils/navigation';

const ResendInvite = ({ resendInvite }) => {
  const navigate = Navigation();
  return (
    <SetEmail
    navigate={navigate}
      handleSubmit={resendInvite}
      pageHeader='Resend Invite'
      pageDescription='Enter the email address you used when you joined and we’ll resend invitation'
      submitButtonText='Resend invitation to this address'
      showBackBtn={false}
    />
  );
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ resendInvite }, dispatch);

export default connect(null, mapDispatchToProps)(ResendInvite);
