import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { FormikProvider, useFormik } from 'formik';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { stepperInfo } from '../stepperInfo';
import { ButtonsPanel } from '../ButtonsPanel/ButtonsPanel';
import { StepThreeFormFields } from './StepThreeFormFields';
import { renderConfirmFlightInfoModal } from '../../../../components';
import { DateHelper, transformDraftToRide, LodashHelper } from '../../../../utils';
import { useCheckForMatchedTc } from '../../../../hooks/useCheckForMatchedTc';
import { ADDITIONAL_DELAY_FOR_RIDE_CREATION, MINIMUM_DELAY_FOR_ROUNDTRIP_RIDE, URLS } from '../../../../constants/constants';

import styles from './index.module.css';
import { useLocation } from 'react-router-dom';
import { defaultTimeValues } from '../../Preferences/MHPreferences/partials/defaultValues';

export const StepThree = ({
  step,
  draft,
  handleSaveAndClose,
  handleSubmit,
  handlePreviousStep,
  defaultTimeZone,
  platformPreferences,
  setFormikValues,
  showSubInformation = true,
  disabledValues,
  airportBufferTime,
  validateReturnTime = true,
  onlyShowTimeSection =false
}) => {
  const [visibleFlightInfoModal, setFlightInfoModalVisible] = useState(false);
  const [updateStepthreeDraft, setUpdatedStepthreeDraft] = useState(draft?.step3);
 
  const location = useLocation(); 
  const matchedErrorRef = useRef();
  const hasMatchedTC = useSelector((state) => state.rides.hasMatchedTC);

  const windowOfTimeIsValid = useSelector((state) => state.rides.windowOfTimeIsValid);


  useEffect(()=>{
    if (draft?.step3?.flightInfo && draft?.step3?.airportBufferTime && draft?.step3?.pickUpDate?.datetime) {
      const subtractedDate = DateHelper?.subtractMinutesInDateWithoutFormat(draft?.step3?.pickUpDate?.datetime, draft?.step3?.airportBufferTime);
      setUpdatedStepthreeDraft({...draft?.step3, pickUpDate: {...draft?.step3?.pickUpDate, datetime: subtractedDate} });
    }

  },[draft?.step3?.flightInfo, draft?.step3?.airportBufferTime, draft?.step3?.pickUpDate?.datetime]);

  const thirdStepInitialValues = useMemo(() => {  
    
    const initialValuesWithDefaultTZ = {
      ...stepperInfo[2].values,
      ...draft?.step3,
      pickUpDate: draft?.step3?.pickUpDate || {
        timezone: defaultTimeZone,
      },
    };

    if (draft?.step3?.isRecurring && draft?.step3?.isRoundtrip) {
      return {
        ...initialValuesWithDefaultTZ,
        returnTime: draft?.step3?.returnTime || {
          timezone: defaultTimeZone,
        },
        recurrenceRule: {
          ...initialValuesWithDefaultTZ?.recurrenceRule,
          startPoint: draft?.step3?.recurrenceRule?.startPoint ||
            draft?.step3?.pickUpDate || {
              timezone: defaultTimeZone,
            },
          returnStartPoint: draft?.step3?.recurrenceRule?.returnStartPoint ||
            draft?.step3?.returnTime || {
              timezone: defaultTimeZone,
            },
        },
      };
    } else if (draft?.step3?.isRecurring) {
      return {
        ...initialValuesWithDefaultTZ,
        recurrenceRule: {
          ...initialValuesWithDefaultTZ?.recurrenceRule,
          startPoint: draft?.step3?.recurrenceRule?.startPoint ||
            draft?.step3?.pickUpDate || {
              datetime: draft?.step3?.pickUpDate?.datetime || '',
              timezone: defaultTimeZone,
            },
        },
      };
    } else if (draft?.step3?.isRoundtrip) {
      return {
        ...initialValuesWithDefaultTZ,
        returnTime: draft?.step3?.returnTime || {
          timezone: defaultTimeZone,
        },
      };
    } else {
      return initialValuesWithDefaultTZ;
    }
  }, [draft, defaultTimeZone]);


  const isChangedFlightInfo = () =>{
    return values?.flightInfo && !LodashHelper.isEqual(values?.flightInfo, thirdStepInitialValues?.flightInfo);
  }

  const handleSubmitWithConfirm = (currentValues, stepNumber)=>{
    if (currentValues?.flightInfo && currentValues?.pickUpDate && airportBufferTime) {
      const updatePickupDate = DateHelper.addMinutesInDateWithoutFormat(currentValues?.pickUpDate?.datetime, airportBufferTime);
      Object.assign(currentValues, { pickUpDate: {...currentValues?.pickUpDate, datetime: updatePickupDate}, airportBufferTime: airportBufferTime });
    }
    if (isChangedFlightInfo()) {
      setFlightInfoModalVisible(true);
    }
    else 
      handleSubmit(currentValues, stepNumber);
  }
 
  const formik = useFormik({
    enableReinitialize: true,
    validateOnMount: true,
    validateOnChange: true,
    validateOnBlur: true, 
    initialValues: thirdStepInitialValues,
    validationSchema: stepperInfo[2].schema(validateReturnTime),
    onSubmit: handleSubmitWithConfirm,
  });

  const { values, touched, errors, validateForm, setFieldTouched, setFieldValue, isValid } = formik;

  useEffect(() => {
    setFormikValues && setFormikValues(values, isValid, 'step3');
    return;
  }, [setFormikValues, values, isValid]);

  useEffect(() => {
    !hasMatchedTC && matchedErrorRef?.current?.scrollIntoView({ behavior: 'smooth' });
    return;
  }, [hasMatchedTC]);

  const valuesForMatching = useMemo(() => {
    return (Number(step) === 2 && location.pathname === URLS.CREATE_RIDE) ||
      location.pathname === URLS.EDIT_DRAFT
      ? transformDraftToRide({
          ...draft,
          step3: values,
        })
      : null;
  }, [draft, step, values,location]);

  useCheckForMatchedTc(valuesForMatching, Boolean(valuesForMatching));

  return (
    <>
    <h2
        className={classNames({
          [styles.headTitle]: showSubInformation,
          [styles.editingHeadTitle]: !showSubInformation,
        })}
        ref={matchedErrorRef}>
        {showSubInformation && `Step 3:`} Location and Time
      </h2>
 
      <FormikProvider value={formik}>
        <form onSubmit={formik?.handleSubmit} className={styles.form}>
          <StepThreeFormFields
            hasMatchedTC={hasMatchedTC}
            defaultValues={updateStepthreeDraft}
            defaultTimeZone={defaultTimeZone} 
            minimumRoundTrip = {platformPreferences?.minimumRoundTrip || MINIMUM_DELAY_FOR_ROUNDTRIP_RIDE}
            defaultHomeAddress={draft?.step1?.riderInfo?.homeAddress}
            minDelayToStartRide={
              platformPreferences?.minTimeFromRideRequestToPickup[draft?.step2?.vehicleType] &&
              DateHelper.addHours(
                platformPreferences?.minTimeFromRideRequestToPickup[draft?.step2?.vehicleType],
                ADDITIONAL_DELAY_FOR_RIDE_CREATION,
                draft?.step2?.needsCarSeat ? platformPreferences?.carBoosterSeatBufferTime : defaultTimeValues
              )
            }
            maxPickUpWindowOfTime={platformPreferences?.maxPickUpWindowOfTime}
            vehicleType={draft?.step2?.vehicleType}
            values={values}
            touched={touched}
            errors={errors}
            validateForm={validateForm}
            setFieldTouched={setFieldTouched}
            setFieldValue={setFieldValue}
            disabledValues={disabledValues}
            windowOfTimeIsValid={windowOfTimeIsValid}
            airportBufferTime={airportBufferTime}
           
         
          />
          { visibleFlightInfoModal && 
            renderConfirmFlightInfoModal({
              handleConfirm: ()=> handleSubmit(formik.values, step),
              handleCancel: ()=> setFlightInfoModalVisible(false)
            }) }
          {showSubInformation && (
            <ButtonsPanel
              disabled={!isValid || !hasMatchedTC || !windowOfTimeIsValid}
              step={step}
              handlePreviousStep={handlePreviousStep}
              handleSaveAndClose={handleSaveAndClose}
              handleSubmit={handleSubmit}
            />
          )}
        </form>
      </FormikProvider>
    </>
  );
};

StepThree.propTypes = {
  step: PropTypes.number,
  draft: PropTypes.object,
  handleSaveAndClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  handlePreviousStep: PropTypes.func,
  platformPreferences: PropTypes.object,
  airportBufferTime: PropTypes.oneOfType([PropTypes.number, PropTypes.any]),
};
