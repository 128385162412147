import { useState, useEffect, memo } from "react";
import { Input } from "../..";
import styles from "./index.module.css";
import { LodashHelper } from "../../../utils";
import classNames from "classnames";

const RenderHeightInputFields = memo(
  ({ fieldName, values, requiredClass = true }) => {
    const [feet, setFeet] = useState(0);
    const [inches, setInches] = useState(0);

    const convertFeetToInches = (feet, inches) => {
      return Number(feet * 12) + Number(inches);
    };

    const convertInchesToFeet = (Inches) => {
      LodashHelper.isNumber(Inches) && setFeet(Math.floor(Inches / 12));
      setInches(Inches % 12);
    };

    useEffect(() => {
      if (values) convertInchesToFeet(values);
    }, [values]);

    const handleChangeHeight = ({
      value,
      name,
      setFieldValue,
      validateForm,
    }) => {
      let height = 0;

      if (name === `${fieldName}.feet`) {
        if (value === "" || (Number(value) <= 12 && value.length <= 2)) {
          setFeet(value);
          height = convertFeetToInches(value, inches);
        }
      } else {
        if (value === "" || (Number(value) <= 11 && value.length <= 2)) {
          setInches(value);
          height = convertFeetToInches(feet, value);
        }
      }

      setFieldValue("height", height);
      validateForm();
    };

    return (
      <div className={styles.input_height}>
        <span
          className={classNames({
            [styles.required]: requiredClass,
          })}
        >
          Height, (Feet/Inches)
        </span>
        <div className={styles.height}>
          <label className={styles.timeControl}>
            <span className={styles.measurement}>Feet</span>
            <label className={styles.timeInput}>
              <Input
                value={feet}
                name={`${fieldName}.feet`}
                onChange={(e) => handleChangeHeight(e)}
                type="number"
                min={0}
                max={12}
              />
            </label>
          </label>
          <label className={styles.timeControl}>
            <span className={styles.measurement}>Inches</span>
            <label className={styles.timeInput}>
              <Input
                value={inches}
                name={`${fieldName}.inches`}
                onChange={(e) => handleChangeHeight(e)}
                type="number"
                min={0}
                max={11}
              />
            </label>
          </label>
        </div>
      </div>
    );
  }
);

export default RenderHeightInputFields;
