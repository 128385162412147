import { useState } from 'react';
 

import { renderEditRecurringModal } from '../components/modals/EditRecurringModal.js/renderEditRecurringModal';

import { Navigation } from '../utils/navigation';

export const useEditReccuringModal = () => {
  const [visible, setVisible] = useState(false);
  const [isReScheduling,setisReScheduling]= useState(false);
  const navigate = Navigation();
  const [id, setId] = useState(null);

  const handleEditRide = (id, isRecurring) => {

    console.log("Handle Edit")
    if (isRecurring && id) {
      setId(id);
      setVisible(true);
    } else if (id) navigate.push(`/rides/${id}/edit`);
  };

   
  const handleRescheduleRide = (id, isRecurring) => {
    // if (isRecurring && id) {
    //   setId(id);
    //   setVisible(true);
    //   setisReScheduling(true);
    // } 
    // else 
    console.log("Reschedule");
    navigate.push(`/rides/${id}/reschedule`);
  };
  const onEditConfirm = ({ editSeriesOption }) => {
    setVisible(false);
    if(isReScheduling)
    {
      navigate.push(`/rides/${id}/reschedule?mode=${encodeURIComponent(editSeriesOption)}`);
    }else{
    navigate.push(`/rides/${id}/edit?mode=${encodeURIComponent(editSeriesOption)}`);
    }
  };

  const onEditCancel = () => {
    setVisible(false);
  };

  return {
    EditRecurringModal: renderEditRecurringModal({
      visibleEditRecurringModal: visible,
      handleEditRecurringSubmit: onEditConfirm,
      handleCancel: onEditCancel,
      isReScheduling:isReScheduling,
    }),
    handleEditRide,
    handleRescheduleRide,
  };
};
